import { useEffect, useState } from 'react';
import { isTizen, isWebOS } from '@/helpers/device-system.helper';

const getTizenModel = (): Promise<string | null> => {
    return new Promise((resolve) => {
        try {
            (window as any).tizen.systeminfo.getPropertyValue(
                'BUILD',
                (data: any) => {
                    resolve(data.model);
                },
                () => {
                    resolve(null);
                },
            );
        } catch (err) {
            resolve(null);
        }
    });
};

const getLGModel = (): Promise<string | null> => {
    return new Promise((resolve) => {
        try {
            webOS.deviceInfo((device) => {
                resolve(device.modelName || null);
            });
        } catch (err) {
            resolve(null);
        }
    });
};

export const useGetModel = () => {
    const [model, setModel] = useState<string | null>(null);

    useEffect(() => {
        const getModel = async () => {
            let modelName;

            switch (true) {
                case isTizen():
                    modelName = await getTizenModel();
                    break;
                case isWebOS():
                    modelName = await getLGModel();
                    break;
                default:
                    modelName = null;
                    break;
            }

            setModel(modelName);
        };

        getModel();
    }, []);

    return { model };
};
