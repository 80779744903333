import styled, { css } from 'styled-components';
import { Nav } from 'nav-tree';
import { ActiveStyledProps } from './types';
import { fillIcon } from '@/helpers/fill-icon.helper';

export const PageContainer = styled(Nav)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
`;

export const MessageContainer = styled.div`
    text-align: center;
    font-weight: 400;
    max-width: 100%;
    margin-bottom: 4.2rem;
`;
export const IconWrapper = styled.div`
    width: 10rem;
    height: 10rem;
    margin: auto;
    ${({ theme }) => fillIcon(theme.message.iconColor)};
`;

export const CardIconWrapper = styled(Nav)<ActiveStyledProps>`
    width: 20.8rem;
    height: 12.1rem;
    border-radius: 1rem;
    ${(props) =>
        props.active &&
        css`
            border: 0.6rem solid ${(props) => props.theme.button.activeBorderColor};
        `}
`;

export const Title = styled.div`
    margin: 2rem 0;
    font-size: 4.6rem;
    color: ${(props) => props.theme.message.titleColor};
    white-space: pre-line;
`;

export const CardContainer = styled(Nav)`
    margin-bottom: 5.2rem;
    margin-right: 3.5rem;
    margin-left: 3.5rem;
    font-size: 2.2rem;
`;

export const CardsWrapper = styled(Nav)`
    display: flex;
`;

export const CardTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.9rem;
    font-size: 2rem;
    line-height: 3.3rem;
    color:: ${(props) => props.theme.text.color.primary};
    font-weight: 400;
    text-align: center;
    margin-top: 2.7rem;
    align-items: center;
`;

export const ButtonWrapper = styled.div`
    width: 44.9rem;
    margin-bottom: 0.4rem;
`;
