import { useGetAvatars } from '@/api/system/use-get-avatars';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetProfilesOut } from '@/interfaces/from-schemas/auth/getProfilesOut';
import { ProfileInterface } from '@/interfaces/profile-interface';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useMemo } from 'react';

interface IUseGetProfile {
    id?: string;
    name?: string;
}

export const useGetProfile = (
    params: IUseGetProfile,
): IApiResponse<ProfileInterface | undefined> => {
    const userModule = useUserModule();

    const profilesEnabled = process.env.user.profilesEnabled && userModule.isLogged();

    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.GET_PROFILES,
        profilesEnabled ? {} : undefined,
    );

    const {
        data: profilesData,
        error: profilesError,
        isFetching: isFetchingProfiles,
    } = useGetMediaApi(keyHook);

    const {
        data: avatarsData,
        error: avatarsError,
        isFetching: isFetchingAvatars,
    } = useGetAvatars();

    const profileData: GetProfilesOut[number] = profilesData?.find((profile: GetProfilesOut[0]) =>
        params.id ? profile.id.toString() === params.id : profile.name === params.name,
    );

    const profile = useMemo(
        () =>
            !avatarsData || !profileData
                ? undefined
                : ({
                      profileId: profileData.id.toString(),
                      name: profileData.name,
                      avatarId: profileData.avatarId,
                      avatarUrl: avatarsData
                          ?.find((avatar) => avatar.getId() === profileData?.avatarId)
                          ?.getSrc(),
                  } as ProfileInterface),
        [avatarsData, profileData],
    );

    return {
        data: profile,
        error: avatarsError || profilesError,
        isFetching: isFetchingAvatars || isFetchingProfiles,
    };
};

export default useGetProfile;
