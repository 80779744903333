import useGetProfile from '@/api/auth/use-get-profile';
import useUpdateProfile from '@/api/auth/use-update-profile';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { ProfileIconGrid } from '@/components/views/Profile/ProfileIconGrid';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';

const ChangeProfileIcon = () => {
    const { profileId } = useProfilePageInitialProps();
    const [sessionProfile, setSessionProfile] = useSessionProfile();
    const { data: profile, error: profileError } = useGetProfile({ id: profileId });
    const [updateProfile, { error }] = useUpdateProfile();

    const errorToDisplay = profileError?.getUserMessage() || error?.getUserMessage();

    const goToProfile = () => Router.back();

    const onIconSelect = async (avatarId: string, avatarUrl: string) => {
        if (profile) {
            const result = await updateProfile({
                name: profile.name,
                avatarId: avatarId,
                profileId,
            });

            if (result.ok) {
                if (sessionProfile && sessionProfile.profileId === profileId) {
                    setSessionProfile({
                        ...sessionProfile,
                        avatarId,
                        avatarUrl,
                    });
                }
                return goToProfile();
            }
        }
    };

    const body = (
        <>
            <MainText
                text={t('pages.profile.change-icon.header')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />
            <ProfileIconGrid onSelect={onIconSelect} />

            <FormContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={goToProfile}
                    data-testing="cancel-button-label"
                />
            </FormContainer>

            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} fullBody={true} />
        </BaseLayout>
    );
};

export default ChangeProfileIcon;
