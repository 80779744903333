import React from 'react';
import t from '@/lib/i18n';
import { Dot } from '@/components/shared/Metadata';
import { StyledDot, StyledDate } from './styles';
import { CollectionLivesInterface, LiveCollectionTitleOwnProps } from './types';
import { MainText, MAINTEXT_KIND, MAINTEXT_RESPONSIVE_KIND } from '@/components/shared/MainText';
import { GUTTER } from '@/types/gutter.type';

export const LiveCollectionTitle = ({
    live,
    responsive,
    gutter = GUTTER.NONE,
}: LiveCollectionTitleOwnProps) => {
    const buildTitle = (text: string, date?: string) => {
        return (
            <>
                {t(`date.${text}`)}
                {date && (
                    <>
                        <StyledDot>
                            <Dot />
                        </StyledDot>
                        <StyledDate>{date}</StyledDate>
                    </>
                )}
            </>
        );
    };

    const handlePrefixDate = (live: CollectionLivesInterface) => {
        const { date, isToday, isTomorrow, prefixDate } = live;
        if (isToday) return buildTitle('today');
        if (isTomorrow) return buildTitle('tomorrow');
        if (!isToday || !isTomorrow) return buildTitle(prefixDate, date);
        return <StyledDate>{date}</StyledDate>;
    };

    const mode = responsive ? MAINTEXT_RESPONSIVE_KIND.COLLECTION_TITLE : MAINTEXT_KIND.SUBTITLE;

    return (
        <MainText mode={mode} gutter={gutter} responsive={responsive}>
            {handlePrefixDate(live)}
        </MainText>
    );
};
