import { StyledDotsGroup } from './style';
import { DotsProps } from './types';

export const Dots = ({ dots }: DotsProps) => {
    const buildDots = (number: number) => {
        const dotsArray = [];
        for (let i = 0; i < number; i++) {
            dotsArray.push(<div key={i} />);
        }

        return dotsArray;
    };

    return <StyledDotsGroup>{buildDots(dots)}</StyledDotsGroup>;
};
