import { Icon, ICONS } from '@/components/shared/Icon';
import { useInterval } from '@/hooks/use-interval.hook';
import { ButtonLeft, ButtonRight, SliderButtonsStyled } from './styles';
import React, { useEffect, useState } from 'react';
import { SliderButtonsProps } from './types';

export const SliderButtons = ({
    isVisible,
    sliderHeight,
    isScrollableRight,
    isScrollableLeft,
    onScrollLeft,
    onScrollRight,
}: SliderButtonsProps) => {
    const [isMouseDownLeftArrow, setMouseDownLeftArrow] = useState(false);
    const [isMouseDownRightArrow, setMouseDownRightArrow] = useState(false);

    useInterval(onScrollLeft, isMouseDownLeftArrow ? 500 : null);
    useInterval(onScrollRight, isMouseDownRightArrow ? 500 : null);

    useEffect(() => {
        if (!isScrollableLeft && isMouseDownLeftArrow) {
            setMouseDownLeftArrow(false);
        }

        if (!isScrollableRight && isMouseDownRightArrow) {
            setMouseDownRightArrow(false);
        }
    }, [isScrollableLeft, isScrollableRight, isMouseDownLeftArrow, isMouseDownRightArrow]);

    return isVisible ? (
        <SliderButtonsStyled sliderHeight={sliderHeight}>
            {isScrollableLeft && (
                <ButtonLeft
                    onClick={onScrollLeft}
                    onMouseDown={() => setMouseDownLeftArrow(true)}
                    onMouseUp={() => setMouseDownLeftArrow(false)}
                >
                    <Icon name={ICONS.ARROW_RIGHT} />
                </ButtonLeft>
            )}
            {isScrollableRight && (
                <ButtonRight
                    onClick={onScrollRight}
                    onMouseDown={() => setMouseDownRightArrow(true)}
                    onMouseUp={() => setMouseDownRightArrow(false)}
                >
                    <Icon name={ICONS.ARROW_RIGHT} />
                </ButtonRight>
            )}
        </SliderButtonsStyled>
    ) : null;
};
