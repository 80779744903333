import { Nav, NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE, navVertical } from 'nav-tree';
import React, { useEffect, useRef } from 'react';

import { SingleCell } from '@/components/shared/EPGTable/ChannelColumn/Cells/ProgramList/SingleCell';
import { formatTime } from '@/components/shared/EPGTable/helpers/epg-table.helpers';
import {
    getCombinedCellHeight,
    getFirstProgramStartTime,
    getLastProgramEndTime,
} from '@/components/shared/EPGTable/helpers/get-combined-cell-height.helper';
import { useFocusedEPGContext } from '@/contexts/epg-table/focused-epg.context';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { useScrollPosition } from '@/hooks/epg-table/use-scroll-position.hook';

import * as Styled from './styles';
import { CombinedCellProps } from './types';
import { INDICATOR_VARIANT } from '@/types/epg-table.type';
import { ScrollIndicator } from './ScrollIndicator';

const CombinedCellRaw = ({
    channelName,
    isFirstRow,
    isLastRow,
    programs,
    onGoToWatch,
    timeShiftingDuration,
}: CombinedCellProps) => {
    const { fullHour, oneHourFromNow, twoHoursFromNow } = useHourRangeContext();
    const { focusedEPG } = useFocusedEPGContext();
    const focusedEPGId = focusedEPG?.getId();
    const navRef = useRef<Nav | null>(null);
    const cellsInCell = programs.length;

    const firstProgramStartTime = getFirstProgramStartTime(programs);
    const lastProgramEndTime = getLastProgramEndTime(programs);
    const startTimeId = `${formatTime(firstProgramStartTime)}`;
    const endTimeId = `${formatTime(lastProgramEndTime)}`;
    const isScrollableCell = programs.length >= 3;

    const { combinedHeight, showIndicator } = getCombinedCellHeight(
        programs,
        fullHour,
        oneHourFromNow,
        twoHoursFromNow,
        isFirstRow,
        isLastRow,
    );

    const { handleNav, scrollPosition } = useScrollPosition(navRef, isScrollableCell);

    useEffect(() => {
        handleNav();

        //* handleNav powinien być wywołany przy pierwszym renderze, by ustawić opacity komórkom
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedEPGId]);

    return (
        <>
            {showIndicator && (
                <ScrollIndicator variant={INDICATOR_VARIANT.TOP} scrollPosition={scrollPosition} />
            )}

            <Styled.CombinedCell
                data-end-time={endTimeId}
                data-is-combined="true"
                data-start-time={startTimeId}
                func={navVertical}
                height={combinedHeight}
                ref={navRef}
                scrollOptions={{
                    isScrollable: true,
                    direction: NAV_SCROLL_DIRECTION.VERTICAL,
                    mode: NAV_SCROLL_MODE.CENTER,
                }}
            >
                {programs.map((program) => {
                    return (
                        <SingleCell
                            cellsInCell={cellsInCell}
                            channelName={channelName}
                            height={program.height}
                            key={program.program.getId()}
                            program={program.program}
                            onGoToWatch={onGoToWatch}
                            timeShiftingDuration={timeShiftingDuration}
                        />
                    );
                })}
            </Styled.CombinedCell>

            {showIndicator && (
                <ScrollIndicator
                    variant={INDICATOR_VARIANT.BOTTOM}
                    scrollPosition={scrollPosition}
                />
            )}
        </>
    );
};

export const CombinedCell = React.memo(CombinedCellRaw);
