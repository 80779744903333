import { redirectToFirstPaymentStep, redirectToNextPaymentStep } from '@/helpers/payment.helper';
import { redirectToProductDetails } from '@/helpers/product.helper';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import React, { useEffect, useState } from 'react';
import { PaymentsLayout } from '../PaymentsLayout';
import { CheckoutStep } from './CheckoutStep';
import { OfferStep } from './OfferStep';
import { PaymentMethodStep } from './PaymentMethodStep';
import { TransactionStep } from './TransactionStep';
import { PaymentsPathProps } from './types';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { PaymentsContextProvider } from '@/contexts/payments/payments.context';

export const PaymentsPath = ({
    currentStep,
    product,
    baseProductId,
    prePurchaseData,
    media,
}: PaymentsPathProps) => {
    const [orderId, setOrderId] = useState<string>();
    const [hasError, setError] = useState(false);
    const [errorDescription, setErrorDescription] = useState<string | undefined>();
    const [isStepsVisible, setStepsVisibility] = useState(true);
    const [isProductCardVisible, setProductCardVisibility] = useState(true);

    const productId = product.getProductId();
    const userModule = useUserModule();

    useEffect(() => {
        setError(false);
        setErrorDescription(undefined);
        setStepsVisibility(true);
        setProductCardVisibility(true);
    }, [currentStep]);

    useEffect(() => {
        if (!userModule.hasEmail()) {
            setError(true);
        }
    }, [userModule]);

    const onNextStep = () => {
        redirectToNextPaymentStep(currentStep, productId);
    };

    const onPaymentError = (description?: string) => {
        setErrorDescription(description);
        setError(true);
    };

    const onBuy = (orderId: string) => {
        setOrderId(orderId);

        redirectToNextPaymentStep(currentStep, productId);
    };

    const onSetStepsVisibility = (isStepsVisible: boolean) => {
        setStepsVisibility(isStepsVisible);
    };

    const onSetProductCardVisibility = (isProductCardVisible: boolean) => {
        setProductCardVisibility(isProductCardVisible);
    };

    const onExitPaymentPath = () => {
        redirectToProductDetails(productId, media, true);
    };

    const buildOfferStep = () => {
        const relatedMinPriceProducts = prePurchaseData?.getRelatedMinPriceProducts() || [];

        return (
            <OfferStep
                product={product}
                relatedProducts={relatedMinPriceProducts}
                onSelectRelatedProduct={(productId) => redirectToFirstPaymentStep({ productId })}
                onSetStepsVisibility={onSetStepsVisibility}
                onExitPaymentPath={onExitPaymentPath}
                onNextStep={onNextStep}
            />
        );
    };

    const buildPaymentMethodStep = () => {
        return (
            <PaymentMethodStep
                product={product}
                onSetStepsVisibility={onSetStepsVisibility}
                onSetProductCardVisibility={onSetProductCardVisibility}
                onNextStep={onNextStep}
            />
        );
    };

    const buildCheckoutStep = () => {
        return (
            <CheckoutStep
                product={product}
                onBuy={onBuy}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
                onExitPaymentPath={onExitPaymentPath}
            />
        );
    };

    const buildTransactionStep = () => {
        return (
            <TransactionStep
                product={product}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
                onSetProductCardVisibility={onSetProductCardVisibility}
                onExitPaymentPath={onExitPaymentPath}
                baseProductId={baseProductId}
            />
        );
    };

    const buildStepContent = () => {
        switch (currentStep) {
            case PAYMENT_STEPS.STEP_1:
                return buildOfferStep();

            case PAYMENT_STEPS.STEP_2:
                return buildPaymentMethodStep();

            case PAYMENT_STEPS.STEP_3:
                return buildCheckoutStep();

            case PAYMENT_STEPS.STEP_4:
                return buildTransactionStep();

            default:
                // error
                return null;
        }
    };

    return (
        <PaymentsContextProvider>
            <PaymentsLayout
                product={product}
                currentStep={currentStep}
                hasError={hasError}
                errorDescription={errorDescription}
                isStepsVisible={isStepsVisible}
                isProductCardVisible={isProductCardVisible}
                onExitPaymentPath={onExitPaymentPath}
            >
                {buildStepContent()}
            </PaymentsLayout>
        </PaymentsContextProvider>
    );
};
