import { GUTTER } from '@/types/gutter.type';
import TruncatedText from './TruncatedText';
import { StyledMainText } from './style';
import { MainTextProps } from './types';
import React from 'react';

export const MainText = ({
    text,
    mode,
    as = 'div',
    lines = 2,
    fullText = false,
    gutter = GUTTER.NORMAL,
    responsive,
    children,
    renderChildrenAfterText = false,
    className,
    centered,
}: MainTextProps) => {
    return (
        <StyledMainText
            $mode={mode}
            as={as}
            gutter={gutter}
            responsive={responsive}
            className={className}
            centered={centered}
        >
            {!renderChildrenAfterText && children}

            {fullText && text}

            {!fullText && text && <TruncatedText text={text} lines={lines} />}

            {renderChildrenAfterText && children}
        </StyledMainText>
    );
};
