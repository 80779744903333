import React, { useCallback } from 'react';
import { Message } from '@/components/shared/Message';
import { GUTTER } from '@/types/gutter.type';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { PlayableButton } from '@/components/shared/Buttons';
import { ErrorArticlesProps } from './types';
import { ErrorMessageWrapper, ButtonContainer, StyledMainText, HelpItemsWrapper } from './styles';
import { ErrorMessageShowMore } from '../ErrorMessageShowMore/ErrorMessageShowMore';
import { Router } from '@/routing';
import { MAINTEXT_KIND } from '@/components/shared/MainText';
import { ContactLink, HelpItem } from '@/components/shared/customer-care';
import { ROUTES } from '@/routing/types';
import { createHashHistory } from 'history';
const ErrorArticles = ({
    articles,
    errorCode,
    isAvailabeErrorCode,
    errorCodeMessageToDisplay,
    contextValue,
    contextType,
}: ErrorArticlesProps) => {
    const handleMore = useCallback((articleId: string) => {
        Router.pushRoute(ROUTES.CUSTOMER_CARE_HELP_ARTICLE, { articleId: articleId });
    }, []);

    const history = createHashHistory();
    const renderBody = () => {
        if (!isAvailabeErrorCode) {
            return (
                <ErrorMessageWrapper>
                    <Message
                        title={errorCodeMessageToDisplay}
                        gutter={GUTTER.BIG}
                        icon={ICONS.INFO_CIRCLE}
                    />
                    <ButtonContainer>
                        <PlayableButton
                            text={t('ok-button-label')}
                            onButtonAction={() => history.go(-2)}
                            defaultFocused
                            data-testing="ok-button-label"
                            gutter={GUTTER.NONE}
                        />
                    </ButtonContainer>
                    <ContactLink
                        errorCode={errorCode}
                        contextValue={contextValue}
                        contextType={contextType}
                    />
                </ErrorMessageWrapper>
            );
        }
        if (articles.length === 1) {
            return (
                <ErrorMessageWrapper>
                    <Message
                        title={errorCodeMessageToDisplay}
                        gutter={GUTTER.BIG}
                        icon={ICONS.INFO_CIRCLE}
                    />
                    <ErrorMessageShowMore
                        description={articles[0].description}
                        errorCode={errorCode}
                        articleId={articles[0].id}
                    />
                    <ButtonContainer>
                        <PlayableButton
                            text={t('ok-button-label')}
                            onButtonAction={() => history.go(-2)}
                            defaultFocused
                            data-testing="ok-button-label"
                            gutter={GUTTER.NONE}
                        />
                    </ButtonContainer>
                    <ContactLink
                        errorCode={errorCode}
                        contextValue={contextValue}
                        contextType={contextType}
                    />
                </ErrorMessageWrapper>
            );
        }
        if (articles.length > 1) {
            return (
                <>
                    <ErrorMessageWrapper>
                        <Message
                            title={errorCodeMessageToDisplay}
                            gutter={GUTTER.BIG}
                            icon={ICONS.INFO_CIRCLE}
                        />
                        <ButtonContainer>
                            <PlayableButton
                                text={t('ok-button-label')}
                                onButtonAction={() => history.go(-2)}
                                defaultFocused
                                data-testing="ok-button-label"
                                gutter={GUTTER.NONE}
                            />
                        </ButtonContainer>
                        {/* drzewko artykulow */}
                        <StyledMainText
                            text={t('pages.customer-care.help')}
                            mode={MAINTEXT_KIND.SUBTITLE}
                            gutter={GUTTER.MEDIUM}
                        />

                        <HelpItemsWrapper>
                            {articles.map((el: any) => {
                                return (
                                    <HelpItem
                                        key={el.id}
                                        id={el.id}
                                        onMore={() => handleMore(el.id)}
                                        title={el.title}
                                    />
                                );
                            })}
                        </HelpItemsWrapper>
                        <ContactLink
                            errorCode={errorCode}
                            contextValue={contextValue}
                            contextType={contextType}
                        />
                    </ErrorMessageWrapper>
                </>
            );
        }
    };
    return <>{renderBody()}</>;
};

export default ErrorArticles;
