import { useGetMediaMutation } from '@/api/navigation/use-get-media';
import { redirectToWatch } from '@/helpers/url.helper';
import { GetMediaIn } from '@/interfaces/from-schemas/navigation/getMediaIn';
import { MediaListItemModel } from '@/models/media-list-item.model';

export const useRedirectToWatch = () => {
    const [getMedia] = useGetMediaMutation();

    const redirectToWatchByMediaId = async (mediaId: string, cpid: number) => {
        const media = await getMedia({ mediaId, cpid: cpid as GetMediaIn['cpid'] });

        if (media.ok && media.data) {
            const mediaModel = new MediaListItemModel(media.data as any);
            redirectToWatch(mediaModel, { withNext: true, replaceRoute: true });
        }
    };

    return {
        redirectToWatchByMediaId,
    };
};
