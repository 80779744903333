import { Avatar } from '@/components/shared/Avatar/Avatar';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { Nav } from 'nav-tree';
import React, { useState } from 'react';
import { EditProfileButton } from '../EditProfileButton';
import { IconGridElementContainer } from './styles';
import { IconGridElementProps } from './types';
import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';

export const IconGridElement = ({
    url,
    size,
    text,
    func,
    onNav,
    onSelectAction,
    defaultFocused,
    onSelectProfile,
    'data-testing': dataTesting,
}: IconGridElementProps) => {
    const { route } = useRouter();
    const [active, setActive] = useState<boolean>(false);

    const isAccountPage = route === ROUTES.ACCOUNT_CHANGE_PROFILE;

    const onAvatarNav = (isActive: boolean) => {
        setActive(isActive);

        if (onNav) {
            onNav(isActive);
        }
    };

    return (
        <IconGridElementContainer active={active} isAccountPage={isAccountPage}>
            <Nav func={func}>
                <Nav
                    defaultFocused={defaultFocused}
                    onEnterDown={onSelectProfile ?? onSelectAction}
                    onClick={onSelectProfile ?? onSelectAction}
                    onNav={onAvatarNav}
                    autofocusOn={['mouseenter', 'focus']}
                    onMouseEnter={() => setActive(true)}
                    onMouseLeave={() => setActive(false)}
                    data-testing={dataTesting}
                >
                    <Avatar text={text} url={protectURL(url)} $size={size} active={active} />
                </Nav>
                {isAccountPage && <EditProfileButton onEditProfile={onSelectAction} />}
            </Nav>
        </IconGridElementContainer>
    );
};
