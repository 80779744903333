import { Router, useRouter } from '@/routing';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { useCallback, useEffect } from 'react';
import { PATHS, ROUTES, ZONES } from '@/routing/types';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { useCheckProfile } from '@/contexts/profile/profile.hooks';
import { buildNextUrl } from '@/helpers/url.helper';
import { findZoneByRouteName } from '@/helpers/router/find-pattern-by-route-name.helper';
import config from 'target/config';
import { botDetector } from '@/helpers/seo/bot-detector.helper';
import { useCookieService } from '@/contexts/cookies/cookies.hooks';
import {
    COOKIE_GOODBYE_FALLBACK_PATH,
    COOKIE_GOODBYE_GET_GIFT_REDIRECT,
    COOKIE_GOODBYE_NEW_PORTAL_REDIRECT,
} from '@/contexts/cookies/cookies.context';
import { buildUrl } from '@/helpers/goodbye.helper';

const profilesEnabled = process.env.user.profilesEnabled;

export const useRedirectManager = () => {
    const router = useRouter();
    const { data: sessionData } = useGetCurrentAuthSession();
    const userModule = useUserModule();
    const statsModule = useStatsModule();
    const isLogged = userModule.isLogged();
    const hasProfile = useCheckProfile();
    const { isBot } = botDetector();
    const cookieService = useCookieService();

    const conditionalRedirect = useCallback(
        (path: PATHS | ROUTES) => {
            const zone = findZoneByRouteName(path);

            if (profilesEnabled && !hasProfile && zone !== ZONES.LOGIN_WITHOUT_PROFILE) {
                if (!pathsWithoutProfileNeeded.includes(path) && !config.isTargetClosed) {
                    const params = {
                        ...router.query,
                        next: buildNextUrl(),
                    };

                    Router.pushRoute(ROUTES.PROFILE_WATCH_AS, params);
                }
            } else if (path === PATHS.LOGIN_NATIVE) {
                Router.pushRoute(ROUTES.LOGIN_RULES_OBLIGATORY);
            }
        },
        [hasProfile],
    );

    const targetClosedRedirect = useCallback(() => {
        const cookieValue = JSON.stringify({ asPath: router.asPath, ...router.query }, null, 0);
        cookieService.set(COOKIE_GOODBYE_FALLBACK_PATH, cookieValue);

        const newPortalRedirectCookie = cookieService.get(COOKIE_GOODBYE_NEW_PORTAL_REDIRECT);
        const getGiftRedirectCookie = cookieService.get(COOKIE_GOODBYE_GET_GIFT_REDIRECT);

        if (newPortalRedirectCookie || (getGiftRedirectCookie && isLogged)) {
            window.location.href = buildUrl(cookieService.get(COOKIE_GOODBYE_FALLBACK_PATH));
            return;
        }

        return Router.pushRoute(ROUTES.GOODBYE);
    }, [isLogged, cookieService, router.asPath, router.query]);

    useEffect(() => {
        const route = router.route.replace(/^\//g, '');

        statsModule.hitAppUserNavigated({
            place: {
                type: route,
                value: router.query ? JSON.stringify(router.query) : '',
            },
        });
    }, [router.route, router.query, statsModule]);

    useEffect(() => {
        const route = router.route.replace(/^\//g, '') as PATHS | ROUTES;

        if (config.isTargetClosed && !isTargetClosedWhiteList.includes(route) && !isBot) {
            targetClosedRedirect();
        }

        if (isLogged && sessionData && !whiteList.includes(route)) {
            conditionalRedirect(route);
        }
    }, [sessionData, isLogged, conditionalRedirect, router.route, isBot, cookieService]);
};

const isTargetClosedWhiteList = [
    ROUTES.LOGIN,
    ROUTES.ERROR404,
    ROUTES.GOODBYE,
    ROUTES.LOGIN_RULES_OBLIGATORY,
    ROUTES.LOGIN_RULES,
    ROUTES.LOGIN,
    ROUTES.LOGIN_NATIVE,
    ROUTES.LOGIN_IPOLSATBOX,
    ROUTES.LOGIN_BY_CODE,
    ROUTES.LOGIN_BY_REMOTE,
    ROUTES.LOGIN_FACEBOOK,
    ROUTES.PROMOTION_TAKE_GIFT,
    ROUTES.LOGIN_TO_CONTINUE,

    PATHS.LOGIN_RULES_OBLIGATORY,
    PATHS.LOGIN_RULES,
    PATHS.LOGIN_NATIVE,
    PATHS.LOGIN_IPOLSATBOX,
    PATHS.LOGIN_BY_CODE,
    PATHS.LOGIN_BY_REMOTE,
    PATHS.LOGIN_FACEBOOK,
    PATHS.PROMOTION_TAKE_GIFT,
];

const whiteList = [
    ROUTES.WELCOME,
    ROUTES.LOGIN_RULES_OBLIGATORY,
    ROUTES.LOGIN_RULES,
    ROUTES.LOGIN,
    ROUTES.LOGIN_NATIVE,
    ROUTES.LOGIN_IPOLSATBOX,
    ROUTES.LOGIN_BY_CODE,
    ROUTES.LOGIN_BY_REMOTE,
    ROUTES.LOGIN_FACEBOOK,
    ROUTES.EMAIL_ACTIVATION,
    ROUTES.ACCOUNT_ACTIVATION,
    ROUTES.CONTACT,
    ROUTES.EMAIL_SET,

    PATHS.WELCOME,
    PATHS.LOGIN_RULES_OBLIGATORY,
    PATHS.LOGIN_RULES,
    PATHS.LOGIN_NATIVE,
    PATHS.LOGIN_IPOLSATBOX,
    PATHS.LOGIN_BY_CODE,
    PATHS.LOGIN_BY_REMOTE,
    PATHS.LOGIN_FACEBOOK,
    PATHS.EMAIL_ACTIVATION,
    PATHS.CONTACT,
];

const pathsWithoutProfileNeeded = [
    ROUTES.PROFILE,
    ROUTES.PROFILE_LOGIN_PROCESS,
    ROUTES.PROFILE_ADD,
    ROUTES.PROFILE_ADD_LOGIN_PROCESS,
    ROUTES.PROFILE_REMOVE,
    ROUTES.PROFILE_REMOVE_SUCCESS,
    ROUTES.PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS,
    ROUTES.PROFILE_SELECT_TO_EDIT,
    ROUTES.PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS,
    ROUTES.PROFILE_CHANGE_NAME,
    ROUTES.PROFILE_CHANGE_ICON,
    ROUTES.PROFILE_WATCH_AS,
    ROUTES.REGISTRATION_CONFIGURATION,
    ROUTES.ADD_ACCOUNT_EMAIL,
    ROUTES.EMAIL_ACTIVATION,
    ROUTES.EXIT_APP_CONFIRMATION,
    ROUTES.TV_CODE,
    PATHS.PROFILE,
    PATHS.PROFILE_LOGIN_PROCESS,
    PATHS.PROFILE_ADD,
    PATHS.PROFILE_ADD_LOGIN_PROCESS,
    PATHS.PROFILE_SELECT_TO_EDIT,
    PATHS.PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS,
    PATHS.PROFILE_WATCH_AS,
    PATHS.PROFILE_REMOVE,
    PATHS.PROFILE_REMOVE_SUCCESS,
    PATHS.PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS,
    PATHS.PROFILE_CHANGE_NAME,
    PATHS.PROFILE_CHANGE_ICON,
    PATHS.REGISTRATION_CONFIGURATION,

    PATHS.ADD_ACCOUNT_EMAIL,
    PATHS.EMAIL_ACTIVATION,
];

export const RedirectManagerProvider = (props: { children: React.ReactNode }) => {
    useRedirectManager();
    return <>{props.children}</>;
};
