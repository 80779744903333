import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { navVertical } from 'nav-tree';
import React from 'react';

const PlusZoneRemovePhoneSuccess = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.PLUS_ZONE} />
            <PageContainer func={navVertical}>
                <Message icon={ICONS.INFO_CIRCLE} title={t('pages.plus-zone.remove.success')} />
                <NavWrapper func={navVertical}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => Router.pushRoute(ROUTES.PLUS_ZONE)}
                        defaultFocused
                    />
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default PlusZoneRemovePhoneSuccess;
