import { AssetInterface } from '@/interfaces/asset.interface';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { useRouter } from '@/routing';

export const usePaginatedGrid = ({ data }: { data: AssetInterface[] }) => {
    const { initialLimit: limit } = process.env.pages.catalog;
    const { query } = useRouter();

    const currPageIndex = +(query.page || 1) - 1;

    return data
        .map((el) => new ListElementModel(el))
        .slice(currPageIndex * limit, currPageIndex * limit + limit);
};
