import { fillIcon } from '@/helpers/fill-icon.helper';
import { Z_INDEX } from '@/targets/default/theme';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const CheckoutContainer = styled(Nav)`
    position: relative;
    flex: 1;
`;

export const ScrollableContainer = styled(Nav)`
    position: absolute;
    top: 0;
    bottom: 20rem;
    left: -3rem;
    right: -3rem;
    padding-top: 9rem;
    overflow: hidden;
`;

export const CheckoutContent = styled.div`
    position: relative;
    padding: 0 3rem;
`;

export const CheckoutFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 9rem;
    left: 0;
    right: 0;
    z-index: ${Z_INDEX.PAYMENTS_FOOTER};
`;

export const PriceContainer = styled.div`
    font-size: 2.8rem;
    line-height: 3.8rem;
    color: ${({ theme }) => theme.paymentCheckoutStep.labelColor};
    white-space: pre-line;
`;

export const PriceButtonContainer = styled.div`
    width: 31rem;
    white-space: nowrap;
`;

export const ButtonContainer = styled(Nav)`
    width: 40rem;
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ArrowContainer = styled.div`
    position: absolute;
    bottom: 18rem;
    left: 50%;
    z-index: ${Z_INDEX.PAYMENTS_FOOTER};
    width: 2.4rem;
    height: 1.4rem;
    transform: translate(-50%, -50%);
    ${({ theme }) => fillIcon(theme.paymentCheckoutStep.arrowColor)};
`;

export const Shade = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${Z_INDEX.PAYMENTS_SHADE};
    height: 35.6rem;
    background-image: ${({ theme }) => theme.paymentCheckoutStep.shadeBg};
`;
