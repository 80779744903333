import { useContext, useState, useRef, useEffect, useCallback } from 'react';

import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { useColumnContext } from '@/contexts/epg-table/column.context';

import { useCatalogRightPanel } from '@/hooks/nav-tree/use-catalog-right-panel.hook';
import { useFetchEPGChannels } from '@/hooks/epg-table/use-fetch-epg-channels.hook';
import { focusProgramInColumn } from '@/components/shared/EPGTable/nav-helpers';
import { Nav } from 'nav-tree';
import { usePageRefocus } from '@/hooks/nav-tree/use-page-refocus.hook';

export const useEPGTableState = () => {
    const focusRef = useRef<Nav | null>(null);
    const [filtersChanged, setFiltersChanged] = useState(false);
    const { handleFilterChangeReset } = useIncrementColumnContext();
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const { filtersOpen, handleFiltersOpen } = useCatalogRightPanel({ focusRef });
    const { firstColumn } = useColumnContext();
    const { allChannels, isFetching, filterLists } = useFetchEPGChannels();
    const handleFilterChange = useCallback(() => setFiltersChanged(true), []);
    const handleOnFilterAction = useCallback(() => {
        handleFilterChange();
        handleFilterChangeReset();
    }, [handleFilterChange, handleFilterChangeReset]);

    usePageRefocus({ focusRef });

    useEffect(() => {
        if (filtersChanged && !filtersOpen) {
            const tree = focusRef.current?.getChildContext().tree;
            tree && focusProgramInColumn(tree, firstColumn.id);
            setFiltersChanged(false);
        }
    }, [filtersChanged, filtersOpen, firstColumn.id]);

    return {
        allChannels,
        filterLists,
        filtersOpen,
        focusRef,
        handleFiltersOpen,
        handleOnFilterAction,
        isFetching,
        isMagicMouseVisible,
    };
};
