import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import React from 'react';
import { TokenInfo } from '@/components/shared/TokenInfo';
import { PlayableDynamicButton } from '@/components/shared/Buttons/PlayableButton/PlayableDynamicButton';
import { Router } from '@/routing';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { BackButtonWrapper } from '@/components/shared/Buttons/BackButton/styles';
import { ROUTES } from '@/routing/types';

const LoginByCode = () => {
    const body = (
        <>
            <BackButtonWrapper>
                <PlayableDynamicButton
                    defaultFocused
                    icon={ICONS.ARROW_LEFT}
                    text={t('back-button-label')}
                    onButtonAction={() => Router.pushRoute(ROUTES.WELCOME)}
                    data-testing="back-button-label"
                />
            </BackButtonWrapper>
            <TokenInfo />
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer body={body} fullBody />
        </BaseLayout>
    );
};

export default LoginByCode;
