import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetPartnersIn } from '@/interfaces/from-schemas/system/getPartnersIn';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { GetPartnersOut } from '@/interfaces/from-schemas/system/getPartnersOut';

export const useGetPartners = (params: GetPartnersIn): IApiResponse<GetPartnersOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.SYSTEM,
        GET_MEDIA_METHODS.FETCH_PARTNERS,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: data,
        error,
        isFetching,
    };
};
