import { Nav } from 'nav-tree';
import { ReactNode } from 'react';

export enum ScrollPosition {
    TOP,
    BOTTOM,
    BETWEEN,
}

export interface IScrollableContainerProps {
    onScroll?: (position: ScrollPosition) => void;
    showBack?: boolean;
    onBack?: () => void;
    onBackToCC?: () => void;
    onExitCC?: () => void;
    showBackCC?: boolean;
    showExitCC?: boolean;
    children: ReactNode;
    withBottomButtons?: boolean;
    fullWidth?: boolean;
    shouldIncludeCCContactLink?: boolean;
    containerRef?: React.RefObject<Nav>;
}
