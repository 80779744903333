import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { NavWrapper } from '@/components/views/Account/styles';
import { PlayableButton } from '@/components/shared/Buttons';
import React from 'react';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

const TitlePage = () => {
    return (
        <>
            <Message
                icon={ICONS.PLUS}
                title={t('pages.plus-zone.title')}
                description={[
                    t('pages.plus-zone.description-1'),
                    t('pages.plus-zone.description-2'),
                ]}
            />
            <NavWrapper>
                <PlayableButton
                    icon={ICONS.PLUS}
                    onButtonAction={() => Router.pushRoute(ROUTES.PLUS_ZONE_ADD_PHONE)}
                    text={t('pages.plus-zone.add-phone-number')}
                    defaultFocused
                    data-testing="pages.plus-zone.add-phone-number"
                />
            </NavWrapper>
        </>
    );
};

export default TitlePage;
