import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import defualtKeyHook from '@/hooks/default-key';
import { IApiResponse, GetMediaPromiseFunc } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import SubcategoriesModel from '@/models/category/subcategories.model';
import { GetSubCategoriesIn } from '@/interfaces/from-schemas/navigation/getSubCategoriesIn';
import { GetSubCategoriesOut } from '@/interfaces/from-schemas/navigation/getSubCategoriesOut';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_SUBCATEGORIES;

const getSubcategoriesPromise = (
    params: GetSubCategoriesIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetSubCategoriesOut> => {
    const keyHook = defualtKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getSubcategoriesPromise };

const useGetSubcategories = (params?: GetSubCategoriesIn): IApiResponse<SubcategoriesModel> => {
    const keyHook = defualtKeyHook(namespace, method, params);
    const { data, isFetching, error } = useGetMediaApi(keyHook, { appendAuthData: true });

    return {
        data: useMemo(() => new SubcategoriesModel(data), [data]),
        isFetching,
        error,
    };
};

export default useGetSubcategories;
