import { useSingleRef } from '@/hooks/use-single-ref.hook';
import CookiesService from '@/services/cookies/cookies.service';
import React, { createContext } from 'react';
import { CookiesProviderProps } from './types';

export const COOKIE_RODO_MODAL_NAME = 'rodo-modal-displayed';
export const COOKIE_RODO_REMINDER_NAME = 'rodo-reminder-displayed';
export const COOKIE_GOODBYE_FALLBACK_PATH = 'goodbye-fallback-path';

export const COOKIE_GOODBYE_NEW_PORTAL_REDIRECT = 'goodbye-new-portal-redirect';
export const COOKIE_GOODBYE_GET_GIFT_REDIRECT = 'goodbye-get-gift-redirect';
export const COOKIE_GOODBYE_GET_GIFT_REDIRECT_EXPIRE_DAYS = 7;

export const COOKIE_CLIENT_CONTEXT_TOKEN_NAME = 'client-context-token';
export const COOKIE_CLIENT_CONTEXT_TOKEN_EXPIRE_MINUTES = 30;

// Oszukujemy tutaj co do typu stanu początkowego, bo i tak zostanie on podmieniony podczas montowania providera
export const CookieContext = createContext<CookiesService>({} as CookiesService);

const CookieContextProvider = ({ children, cookieString }: CookiesProviderProps) => {
    const cookieService = useSingleRef(() => new CookiesService(cookieString));
    return <CookieContext.Provider value={cookieService}>{children}</CookieContext.Provider>;
};

export default CookieContextProvider;
