import React, { useMemo } from 'react';
import chunk from 'lodash/chunk';
import { GridElementsProps } from './types';
import { HorizontalRowWrapper } from './HorizontalRowWrapper';

const GridElementsRaw = ({
    data,
    onActiveElement,
    cols,
    defaultFocusedList,
    isSearch,
    isChronological,
    rowCount,
}: GridElementsProps) => {
    const dataLists = useMemo(() => chunk(data, cols), [data, cols]);

    return (
        <>
            {dataLists.map((dataList, indexList) => (
                <HorizontalRowWrapper
                    key={indexList}
                    dataList={dataList}
                    rowIndex={indexList}
                    rowCount={rowCount}
                    onActiveElement={onActiveElement}
                    cols={cols}
                    defaultFocusedList={defaultFocusedList}
                    isSearch={isSearch}
                    isChronological={isChronological}
                />
            ))}
        </>
    );
};

export const GridElements = React.memo(GridElementsRaw);
