import React from 'react';

import { Icon, ICONS } from '@/components/shared/Icon';
import { Image } from '@/components/shared/Image';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGroup, Metadata, METADATA_TYPES } from '@/components/shared/Metadata';
import { getIconName } from '@/helpers/get-icon-name.helper';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';

import { RatingIconWrapper } from '../RatingIconWrapper/styles';
import {
    ProductAccessContainer,
    ProductCardStyled,
    ProductDescriptionContainer,
    ProductMetadataIconsContainer,
} from './styles';
import { ProductCardProps } from './types';

export const ProductCard = ({ product }: ProductCardProps) => {
    const licenseLocation = product.getLicenseLocationText();
    const licenseEndDate = product.getLicenseEndDate()
        ? `${t('models.media-details.date-info')}: ${product.getLicenseEndDate()}`
        : '';

    const displayLicense = licenseLocation || licenseEndDate;
    const hasAgeGroup = product.getAgeGroup() > 0;

    return (
        <ProductCardStyled>
            <Image src={product.getImage().src} />

            <ProductDescriptionContainer>
                <MainText
                    text={product.getMetadata()}
                    mode={MAINTEXT_KIND.PRODUCT_TYPE}
                    gutter={GUTTER.NONE}
                />

                <MainText
                    text={product.getTitle()}
                    fullText={true}
                    mode={MAINTEXT_KIND.PRODUCT_NAME}
                    gutter={GUTTER.NORMAL}
                />
            </ProductDescriptionContainer>

            {displayLicense && (
                <ProductAccessContainer>
                    <Metadata
                        data={[licenseLocation, licenseEndDate].filter((item) => item !== '')}
                        type={METADATA_TYPES.TERTIARY}
                    />
                </ProductAccessContainer>
            )}

            <ProductMetadataIconsContainer>
                {!hasAgeGroup && (
                    <RatingIconWrapper ageGroup={16} $height={2.9}>
                        <Icon name={getIconName(16)} />
                    </RatingIconWrapper>
                )}

                {product.metadataIcons.map((icon: ICONS, index: number) => (
                    <IconGroup key={index} icon={icon} iconSize={2.9} noMargin />
                ))}

                {product.platformsIcons.map((icon: ICONS, index: number) => {
                    const isStbIcon = icon === ICONS.STB;
                    const iconSize = isStbIcon ? 1.8 : 2.9;

                    return <IconGroup key={index} icon={icon} iconSize={iconSize} noMargin />;
                })}
            </ProductMetadataIconsContainer>
        </ProductCardStyled>
    );
};
