import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

const container = css`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-right: 14rem;
    text-align: center;
`;

export const PageContainer = styled(Nav)`
    ${container}
`;

export const PageContainerRaw = styled.div`
    ${container}
`;

export const AccountRootContainer = styled(Nav)`
    display: flex;
    flex-direction: column;
    padding: 5rem 14rem 5rem 0;
    height: 100vh;
    overflow: hidden;
`;

export const NavWrapper = styled(Nav)`
    width: 44rem;
    padding: 4rem 0;
`;
