import { formatTime } from '@/components/shared/EPGTable/helpers/epg-table.helpers';
import { getCellHeight } from '@/components/shared/EPGTable/helpers/get-cell-height.helper';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { EPGTableProgramRaw, CtxHours, HourRange, ProgramEntry } from '@/types/epg-table.type';

export const filterPrograms = (
    programs: ChannelProgramTvModel[],
    hourRange: HourRange,
    twoHoursFromNow: Date,
    date: Date,
) => {
    const fullHour = date;
    fullHour.setHours(hourRange.startTime);
    fullHour.setSeconds(0);
    fullHour.setMilliseconds(0);

    const filteredPrograms = programs.filter((program, index) => {
        const programEndTime = calculateProgramEndTime(program, programs, index);
        const programStartTime = program.getStartTimeTimestamp();
        const fullHourTime = fullHour.getTime();
        const twoHoursFromNowTime = twoHoursFromNow.getTime();

        const isProgramWithinTimeRange =
            programStartTime < twoHoursFromNowTime && programEndTime > fullHourTime;

        return isProgramWithinTimeRange;
    });

    return filteredPrograms;
};

export const groupPrograms = (
    programs: ChannelProgramTvModel[],
    fullHour: Date,
    oneHourFromNow: Date,
    twoHoursFromNow: Date,
) => {
    const groupedPrograms = programs.reduce(
        (acc: { [key: string]: EPGTableProgramRaw[] }, program, index, arr) => {
            const isFirst = index === 0;
            const isLast = index === arr.length - 1;

            const { time, startTime, endTime } = getGroupTimes(program, fullHour, isFirst, isLast);

            const title = program.getTitle();

            const programObj = {
                title,
                startTime: formatTime(startTime.toISOString()),
                endTime: formatTime(endTime.toISOString()),
                program,
            };

            if (time in acc) {
                acc[time].push(programObj);
            } else {
                acc[time] = [programObj];
            }

            return acc;
        },
        {},
    );

    const ctxHours = { fullHour, oneHourFromNow, twoHoursFromNow };

    addCellHeightToProgram(groupedPrograms, ctxHours);

    const sortedGroupedPrograms = Object.entries(groupedPrograms).sort(
        ([, valueA], [, valueB]) =>
            valueA[0].program.getStartTimeTimestamp() - valueB[0].program.getStartTimeTimestamp(),
    );

    return sortedGroupedPrograms as ProgramEntry[];
};

const addCellHeightToProgram = (
    groupedPrograms: { [key: string]: EPGTableProgramRaw[] },
    ctxHours: CtxHours,
) => {
    Object.entries(groupedPrograms).forEach(([key, value], index) => {
        const isFirstGroup = index === 0;

        const valueWithHeight = value.map((program, i, programs) => {
            const isFirstRow = isFirstGroup && i === 0;
            const programModel = program.program;
            const length = programs.length;

            const height = getCellHeight(programModel, length, ctxHours, isFirstRow) + 'rem';

            return {
                ...program,
                height,
            };
        });

        groupedPrograms[key] = valueWithHeight;
    });
};

const getGroupTimes = (
    program: ChannelProgramTvModel,
    fullHour: Date,
    isFirst: boolean,
    isLast: boolean,
) => {
    const programEndTimeTimestamp = program.getEndTimeTimestamp();
    const programStartTime = program.getStartTimeTimestamp();

    const programStartTimeDate = new Date(programStartTime);
    const fullHourClone = new Date(fullHour.getTime());
    fullHourClone.setMinutes(0, 0, 0);

    const adjustedProgramStartTime = new Date(programStartTimeDate);
    adjustedProgramStartTime.setHours(adjustedProgramStartTime.getHours() + 1);

    const programEndTime = programEndTimeTimestamp
        ? new Date(programEndTimeTimestamp)
        : adjustedProgramStartTime;

    const timeRaw = isFirst ? fullHourClone : programStartTimeDate;
    const time = calculateTime(isLast, timeRaw, fullHourClone);

    return { time, startTime: programStartTimeDate, endTime: programEndTime };
};

const calculateProgramEndTime = (
    program: ChannelProgramTvModel,
    programs: ChannelProgramTvModel[],
    index: number,
) => {
    let programEndTime = program.getEndTimeTimestamp();
    if (!programEndTime) {
        programEndTime = program.getStartTimeTimestamp() + 18000;
        const nextProgram = programs[index + 1];
        if (nextProgram) {
            programEndTime = nextProgram.getStartTimeTimestamp();
        } else {
            programEndTime = program.getStartTimeTimestamp() + 18000;
        }
    }
    return programEndTime;
};

const calculateTime = (isLast: boolean, timeRaw: Date, fullHourClone: Date) => {
    const rewriteToNextHour =
        isLast && timeRaw.getHours() === fullHourClone.getHours() && timeRaw.getMinutes() >= 50;
    return rewriteToNextHour ? timeRaw.getHours() + 1 + '00' : timeRaw.getHours() + '00';
};
