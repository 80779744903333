import { facebookKeyHook } from '@/hooks/default-key';
import { FACEBOOK_ENDPOINT } from '@/types/facebook-endpoint.type';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { fbAxiosInstance } from './fb-axios-instance';
import {
    DeviceStatusErrorSubcode,
    IFacebookDeviceLoginStatusError,
    IFacebookDeviceLoginStatusOut,
} from './types';

type FBError = IFacebookDeviceLoginStatusError['error'];

interface IUseFacebookDeviceLoginStatus {
    appId: string;
    clientToken: string;
    code?: string;
    interval?: number;
    onSuccess?: (data: IFacebookDeviceLoginStatusOut) => void;
    onAwaitingLogin?: (error: FBError) => void;
    onExpired?: (error: FBError) => void;
    onPollingTooFrequently?: (error: FBError) => void;
    onUnknownError?: (error: AxiosError<IFacebookDeviceLoginStatusError>) => void;
}

export const useFacebookDeviceLoginStatus = (params: IUseFacebookDeviceLoginStatus) => {
    const access_token = `${params.appId}|${params.clientToken}`;

    const key = facebookKeyHook(FACEBOOK_ENDPOINT.DEVICE_LOGIN_STATUS, null);

    return useQuery<IFacebookDeviceLoginStatusOut, AxiosError<IFacebookDeviceLoginStatusError>>(
        key,
        {
            enabled: !!params.code && !!params.interval,
            refetchOnWindowFocus: false,
            retry: false,
            refetchInterval: params.interval,
            onError: (err) => {
                const error = err.response?.data.error;
                switch (error?.error_subcode) {
                    case DeviceStatusErrorSubcode.NOT_AUTHORIZED_YET:
                        params.onAwaitingLogin?.(error);
                        break;
                    case DeviceStatusErrorSubcode.POLLING_TOO_FREQUENTLY:
                        params.onPollingTooFrequently?.(error);
                        break;
                    case DeviceStatusErrorSubcode.TOKEN_EXPIRED:
                        params.onExpired?.(error);
                        break;
                    default:
                        params.onUnknownError?.(err);
                }
            },
            onSuccess: params.onSuccess,
            queryFn: () =>
                fbAxiosInstance
                    .post<IFacebookDeviceLoginStatusOut>(FACEBOOK_ENDPOINT.DEVICE_LOGIN_STATUS, {
                        access_token,
                        code: params.code,
                    })
                    .then(({ data }) => data),
        },
    );
};
