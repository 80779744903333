import { formatCurrency } from '@/helpers/currency.helper';
import { AcquireOptionUpgradeSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';

export class OptionUpgradeModel {
    private currentAccessPriceText: string;
    private nextAccessPriceText: string;
    private allAccessPriceText: string;

    constructor(definition: AcquireOptionUpgradeSchema) {
        const { currentAccessPrice, nextAccessPrice } = definition;

        this.currentAccessPriceText = currentAccessPrice.valueText;
        this.nextAccessPriceText =
            nextAccessPrice.valueTextIncludingPricingPlans || nextAccessPrice.valueText;

        const currentAccessPriceValue = currentAccessPrice.value;
        const nextAccessPriceValue =
            nextAccessPrice.valueIncludingPricingPlans || nextAccessPrice.value;
        const allAccessPriceValue = (currentAccessPriceValue + nextAccessPriceValue) / 100;

        this.allAccessPriceText = formatCurrency(allAccessPriceValue, currentAccessPrice.currency);
    }

    public getCurrentAccessPriceText(): string {
        return this.currentAccessPriceText;
    }

    public getNextAccessPriceText(): string {
        return this.nextAccessPriceText;
    }

    public getAllAccessPriceText(): string {
        return this.allAccessPriceText;
    }
}
