import { useBuy } from '@/api/payments/use-buy';
import { LoadingWrapper } from '@/components/shared/Loader';
import { useCheckOrderStatus } from '@/hooks/use-check-order-status';
import { BlikProps } from './types';
import t from '@/lib/i18n';
import React, { useEffect, useState } from 'react';
import { BlikContainer } from './styles';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { BlikForm } from './BlikForm';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { ORDER_STATUS, PAYMENT_STATUS } from '@/interfaces/payments.interface';
import { buildBlikBuyParams } from '@/helpers/payments/request-params-builder.helper';

export const Blik = ({
    product,
    offer,
    option,
    orderId,
    onPaymentSuccess,
    onPaymentError,
    onSetStepsVisibility,
}: BlikProps) => {
    const [blikCode, setBlikCode] = useState<string>();
    const [hasError, setError] = useState(false);

    const buyParams = buildBlikBuyParams(product, offer, option, orderId, blikCode);
    const { data: buy, isFetching: isBuyFetching } = useBuy(buyParams);
    const isBuySuccess = buy?.status === 1;

    const orderStatusParams = isBuySuccess ? orderId : undefined;
    const { orderStatus, paymentStatus, possessionId } = useCheckOrderStatus(orderStatusParams);

    const isFetching = isBuyFetching || paymentStatus === PAYMENT_STATUS.PENDING;
    const loaderText =
        orderStatus === ORDER_STATUS.IN_PROGRESS
            ? t('payment-steps.blik.confirm-payment')
            : t('payment-steps.blik.verification');
    const isPaymentNotAvailable = !isBuyFetching && !isBuySuccess && blikCode;

    useEffect(() => {
        if (isPaymentNotAvailable) {
            onPaymentError();
        }
    }, [isPaymentNotAvailable]);

    useEffect(() => {
        if (paymentStatus === PAYMENT_STATUS.SUCCESS && possessionId) {
            onPaymentSuccess(possessionId);
        }

        if (paymentStatus === PAYMENT_STATUS.ERROR) {
            onPaymentError();
        }
    }, [paymentStatus, possessionId]);

    useEffect(() => {
        if (isFetching) {
            onSetStepsVisibility(false);
        }
    }, [isFetching]);

    const onSubmit = (blikCode: string) => {
        setBlikCode(blikCode);
        setError(false);
    };

    const onError = () => {
        setError(true);
    };

    return (
        <BlikContainer>
            <LoadingWrapper isFetching={isFetching} text={loaderText} calculateBottom={false}>
                <MainText
                    text={t('payment-steps.blik.form-title')}
                    mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                />

                <BlikForm onSubmit={onSubmit} onError={onError} />

                {hasError && (
                    <AlertMessage
                        userMessage={t('payment-steps.blik.incorrect-code')}
                        type={MESSAGE_TYPE.ERROR}
                    />
                )}
            </LoadingWrapper>
        </BlikContainer>
    );
};
