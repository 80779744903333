import useAcceptRules from '@/api/auth/use-accept-rules';
import useUnacceptRules from '@/api/auth/use-unaccept-rules';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useFadingState } from '@/hooks/use-fading-state.hook';
import { useCallback, useEffect } from 'react';

export const useLoginRulesActions = (
    ruleId: number | undefined,
    onProcessingAction: (status: boolean) => void,
    onNextRule: () => void,
) => {
    const userModule = useUserModule();

    const [unacceptRules, { isFetching: isUnaccepting, error: unacceptError }] = useUnacceptRules();
    const [acceptRules, { isFetching: isAccepting, error: acceptError }] = useAcceptRules();
    const [errorUserMessage] = useFadingState(
        unacceptError?.getUserMessage() || acceptError?.getUserMessage(),
    );
    const isLoading = isUnaccepting || isAccepting;

    useEffect(() => {
        onProcessingAction(isLoading);
    }, [isLoading, onProcessingAction]);

    const setRuleStatus = useCallback(
        async (accept: boolean) => {
            const authData = userModule.getAuthData();
            const deviceId = userModule.getDeviceIdIdent();

            const params = { rulesIds: [ruleId as number] };
            let result;

            if (accept) {
                if (authData) {
                    Object.assign(params, { authData: { ...authData, deviceId } });
                }
                result = await acceptRules(params);
            } else {
                result = await unacceptRules(params);
            }

            if (result.ok) {
                onProcessingAction(isLoading);
                onNextRule();
            }
        },
        [ruleId, userModule, isLoading, onNextRule, acceptRules, unacceptRules, onProcessingAction],
    );

    return {
        setRuleStatus,
        errorMessage: errorUserMessage,
        isLoading,
    };
};
