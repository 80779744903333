import React, { memo } from 'react';
import { Icon, ICONS } from '@/components/shared/Icon';
import ProgressBar from '@/components/shared/ProgressBar';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import {
    EPGDate,
    EPGDateContainer,
    EPGDescription,
    EPGGenre,
    EPGHour,
    EPGLive,
    EPGTileAccent,
    EPGOnAir,
    EPGTileProgressBarContainer,
    EPGTimeContainer,
    EPGTitle,
} from './styles';
import { EPGTileProps } from './types';

export const EPGTileRaw = ({
    program,
    nowPlayed = false,
    playable = false,
    locked = false,
}: EPGTileProps) => {
    return (
        <>
            <div>
                <EPGDateContainer>
                    <EPGDate>
                        {program.getStartTime(FORMAT_DATE_MODE.LIVE_ARABIC_WITH_MONTH)}
                    </EPGDate>
                    {program.getIsLive() && <EPGLive>Na żywo</EPGLive>}
                </EPGDateContainer>

                <EPGTimeContainer>
                    {program.getIsOnAir() && <EPGOnAir>Trwa</EPGOnAir>}
                    <EPGHour>
                        {program.getStartTime(FORMAT_DATE_MODE.HOURS)}
                        {program.getEndTime() && ` - ${program.getEndTime(FORMAT_DATE_MODE.HOURS)}`}
                        {locked ? (
                            <EPGTileAccent lock>
                                <Icon name={ICONS.LOCK_FILLED} />
                            </EPGTileAccent>
                        ) : (
                            (nowPlayed || playable) && (
                                <EPGTileAccent nowPlaying={nowPlayed}>
                                    <Icon name={ICONS.SIMPLE_PLAY} />
                                </EPGTileAccent>
                            )
                        )}
                    </EPGHour>
                </EPGTimeContainer>

                <EPGGenre>{program.getGenre()}</EPGGenre>

                <EPGTitle>{program.getTitle()}</EPGTitle>

                <EPGDescription>{program.getDescription()}</EPGDescription>
            </div>
            {program.getIsOnAir() && (
                <EPGTileProgressBarContainer>
                    <ProgressBar progress={program.getProgress() * 100} />
                </EPGTileProgressBarContainer>
            )}
        </>
    );
};

export const EPGTile = memo(EPGTileRaw);
