import { formatDate, getTodayLabel, getTomorrowLabel, weekdayName } from '@/helpers/date.helper';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import {
    CategorySchema,
    ImageSourceSchema,
    LiveChannelListItemSchema,
} from '@/interfaces/from-schemas/navigation/getLiveChannelsOut';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { MEDIA_TYPES, METADATA_TYPE } from '@/types/media.type';
import { backgroundUtil } from '@/utils/background.utils';
import { coverUtil } from '@/utils/cover.util';
import format from 'date-fns/format';
import t from '@/lib/i18n';

export class LiveModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;
    private readonly cpid: number = 0;
    readonly id: string = '';
    private readonly mediaType: MEDIA_TYPES = MEDIA_TYPES.LIVE;
    private readonly title: string = '';
    private readonly description: string = '';
    private readonly genres: string[] = [];
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly posters?: ImageSourceSchema[] = [];
    private readonly ageGroup: number = 0;
    readonly publicationDate: string = '';
    private readonly backgroundSrc?: string;
    private readonly gallery: LiveChannelListItemSchema['gallery'];
    private progress = 0;
    private readonly category: CategorySchema = {
        cpid: 7,
        id: 0,
        name: '',
        description: '',
        genres: [],
        categoryPath: [],
        categoryNamesPath: [],
    };

    constructor(definition: LiveChannelListItemSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
        this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;
    }

    public getAgeGroup(): number {
        return this.ageGroup;
    }

    public getCpid(): number {
        return this.cpid;
    }

    public getDescription(): string {
        return this.description;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }

    public getId(): string {
        return this.id;
    }

    public getMediaType(): MEDIA_TYPES {
        return this.mediaType;
    }

    public getPosters(): ImageSourceSchema[] | undefined {
        return this.posters;
    }

    public getPublicationDate() {
        return this.publicationDate;
    }

    public getPublicationDatewithDays(): string {
        return format(new Date(this.publicationDate), 'cccc', {}).toLowerCase();
    }

    public getTitle(): string {
        return this.title;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getImage(): ListElementImageInterface {
        const images = this.getThumbnails();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public getDetailsRoute(): ROUTES {
        return ROUTES.LIVE_DETAILS;
    }
    public getRouteParams(): any {
        return {
            eventTitle: this.title,
            eventId: this.id,
        };
    }
    public goToDetails(options?: goToDetailsOptionsType): void {
        const { replaceRoute } = options || {};
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public getIsOnAir(): boolean {
        const start = new Date(this.publicationDate).getTime();
        const now = Date.now();

        // Zmienic obliczania trwajacego materiału gdy dane będą dostepne w get-media
        return start <= now;
    }

    public getIsLive(): boolean {
        // Dodac poprawny status gdy bedzie dodany w get-media
        return false;
    }

    public getMetadata(type: METADATA_TYPE): string[] {
        const metadata = [];
        const hours = format(new Date(this.publicationDate), 'dd.MM HH:mm', {});

        const todayOrTomorrowLabel = this.getTodayOrTomorrowLabel();
        const dayTag = this.getDayTag();
        const dateLabel = `${todayOrTomorrowLabel || dayTag} • ${hours}`;

        switch (type) {
            case METADATA_TYPE.MAIN:
                metadata.push(this.getDetailedMetadata());
                break;
            case METADATA_TYPE.LIST_ELEMENT:
                metadata.push(dateLabel);
                break;
        }

        return metadata;
    }

    public getDetailedMetadata(): string {
        const dayTag = this.getDayTag();
        const date =
            this.getTodayOrTomorrowLabel() ||
            `${dayTag} ${formatDate(this.publicationDate, FORMAT_DATE_MODE.DATE)}`;
        const time = formatDate(this.publicationDate, FORMAT_DATE_MODE.HOURS);

        const dateLabel = `${date} • ${time}`;

        return [this.getGenres(), dateLabel].filter(Boolean).join(' • ');
    }

    public setProgress(progress: number): void {
        this.progress = progress;
    }

    private getDayTag() {
        return t(`date.${weekdayName(new Date(this.publicationDate).getDay())}`);
    }

    private getTodayOrTomorrowLabel() {
        return getTodayLabel(this.publicationDate) || getTomorrowLabel(this.publicationDate);
    }

    public getGallery() {
        return this.gallery;
    }
}
