import { ProcessingLoaderWrapper } from '@/components/shared/FullscreenNavContainer';
import React, { useEffect, useState } from 'react';
import { useRequestLogin } from '@/api/auth/use-request-login';
import { ProcessingLoader } from '@/components/shared/Loader';
import QRCode from 'react-qr-code';
import {
    TokenInfoContainer,
    TokenInfoSection,
    TokenInfoSectionTitle,
    InstructionStepLabel,
    InstructionStepList,
    InstructionStepValue,
    InstructionStepWrapper,
    NativeLoginButtonLabel,
    NativeLoginButtonWrapper,
    QRCodeContainer,
    QR_CODE_SIZE,
    ButtonEnlargeWrapper,
    SeparatorContainer,
    Separator,
    SeparatorText,
    Text,
    TokenWrapper,
} from '@/components/shared/TokenInfo/styles';
import { useCheckLoginStatus } from '@/hooks/use-check-login-status';
import { INITIAL_REQUEST_DELAY, isLoginStatusSuccess } from '@/helpers/login.helper';
import { PlayableButton } from '@/components/shared/Buttons';
import { GUTTER } from '@/types/gutter.type';
import t from '@/lib/i18n';
import { useByCodeLogin } from '@/hooks/auth/use-by-code-login.hook';
import useLogin from '@/api/auth/use-login';
import { ROUTES } from '@/routing/types';
import { Router } from '@/routing';

const loginRoute = () => {
    if (process.env.authProviders.length > 1) {
        return Router.pushRoute(ROUTES.LOGIN_BY_REMOTE);
    }

    Router.pushRoute(ROUTES.LOGIN_NATIVE);
};

export const TokenInfo = () => {
    const { data, isFetching } = useRequestLogin();
    const [loginRequestId, setLoginRequestId] = useState<string>();
    const { loginStatus, authData, error } = useCheckLoginStatus(loginRequestId);
    const [login] = useLogin();
    const byCodeLogin = useByCodeLogin(login);

    useEffect(() => {
        let timeoutHandle: NodeJS.Timeout | null = null;

        if (!isFetching && data) {
            timeoutHandle = setTimeout(
                () => setLoginRequestId(data.loginRequestId),
                INITIAL_REQUEST_DELAY,
            );
        }

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    }, [data, isFetching]);

    useEffect(() => {
        if (isLoginStatusSuccess(loginStatus)) {
            const authToken = authData.authToken;
            byCodeLogin(authToken);
            return;
        }
    }, [loginStatus]);

    useEffect(() => {
        if (error) {
            Router.pushRoute(ROUTES.WELCOME);
            return;
        }
    }, [error]);

    if (isFetching && !data) {
        return (
            <ProcessingLoaderWrapper>
                <ProcessingLoader isFetching noDelay />
            </ProcessingLoaderWrapper>
        );
    }

    if (data.status !== 0) {
        return null;
    }

    const { code, url } = data.acceptanceData;
    const urlWithCode = url && `${url}?qrCode=${code}`;

    const steps = [
        {
            value: 1,
            text: (
                <>
                    <p>{t('pages.login.by-code.step-1')}</p>
                    <p>
                        <strong style={{ wordBreak: 'break-all' }}>{url}</strong>
                    </p>
                </>
            ),
        },
        {
            value: 2,
            text: (
                <>
                    <p>{t('pages.login.by-code.step-2')}</p>
                    <p>
                        <strong>{code}</strong>
                    </p>
                </>
            ),
        },
    ];

    return (
        <TokenInfoContainer>
            <TokenInfoSection>
                <TokenInfoSectionTitle>{t('pages.login.by-code.title')}</TokenInfoSectionTitle>
            </TokenInfoSection>

            <TokenInfoSection>
                <TokenWrapper>
                    {urlWithCode && (
                        <div>
                            <QRCodeContainer>
                                <QRCode value={urlWithCode} size={QR_CODE_SIZE} />
                            </QRCodeContainer>
                            <Text>{t('pages.login.by-code.qr-image-description')}</Text>
                        </div>
                    )}

                    <SeparatorContainer>
                        <Separator />
                        <SeparatorText>LUB</SeparatorText>
                        <Separator />
                    </SeparatorContainer>
                    <InstructionStepList>
                        {steps.map((step, index) => (
                            <InstructionStepWrapper key={index}>
                                <InstructionStepValue>{step.value}</InstructionStepValue>
                                <InstructionStepLabel>{step.text}</InstructionStepLabel>
                            </InstructionStepWrapper>
                        ))}
                    </InstructionStepList>
                </TokenWrapper>
            </TokenInfoSection>

            <TokenInfoSection separator={true}>
                <NativeLoginButtonWrapper>
                    <NativeLoginButtonLabel>
                        {t('pages.login.by-code.native-login-label')}
                    </NativeLoginButtonLabel>

                    <ButtonEnlargeWrapper>
                        <PlayableButton
                            defaultFocused
                            text={t('pages.login.by-code.native-login-button')}
                            onButtonAction={loginRoute}
                            data-testing="login-standard"
                            gutter={GUTTER.NONE}
                        />
                    </ButtonEnlargeWrapper>
                </NativeLoginButtonWrapper>
            </TokenInfoSection>
        </TokenInfoContainer>
    );
};
