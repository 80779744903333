import { Nav } from 'nav-tree';
import styled from 'styled-components';
import { PaymentsLayoutContainerProps } from './types';

export const PaymentsLayoutContainer = styled.div<PaymentsLayoutContainerProps>`
    display: flex;
    justify-content: ${({ isAlignCenter }) => (isAlignCenter ? 'center' : 'space-between')};
    margin: 0 14rem;
    height: 100%;
`;

export const PaymentsLayoutCenterContainer = styled(Nav)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 14rem;
    height: 100%;
`;

export const ProductContainer = styled.div`
    width: 40rem;
    padding-top: 20rem;
`;

export const ContentContainer = styled(Nav)`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 109rem;
`;

export const StepsContainer = styled.div`
    width: 100%;
    padding-top: 5rem;
`;

export const ButtonContainer = styled(Nav)`
    width: 40rem;
    margin-bottom: 73px;
`;
