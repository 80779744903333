import { navHorizontal } from 'nav-tree';
import React from 'react';
import { PlayableButton } from '../Buttons';
import { ICONS } from '../Icon';
import { paymentStepsData } from './payment-steps-data';
import { Step } from './Step';
import { BackButtonContainer, PaymentStepsContainer, StepsContainer } from './styles';
import { PaymentStepsProps } from './types';
import { GUTTER } from '@/types/gutter.type';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';

export const PaymentSteps = ({ currentStep, onChangeStep, onExitAction }: PaymentStepsProps) => {
    const isSelected = (step: PAYMENT_STEPS) => currentStep === step;
    const currentStepIndex = paymentStepsData.findIndex(({ step }) => step === currentStep);
    const isFocusAvailable = (index: number) => currentStepIndex >= index;

    return (
        <PaymentStepsContainer func={navHorizontal}>
            <BackButtonContainer>
                <PlayableButton
                    icon={ICONS.EXIT_DOOR}
                    gutter={GUTTER.NONE}
                    onButtonAction={onExitAction}
                />
            </BackButtonContainer>

            <StepsContainer>
                {paymentStepsData.map(({ label, step }, index) => (
                    <Step
                        key={index}
                        label={label}
                        selected={isSelected(step)}
                        isFocusAvailable={isFocusAvailable(index)}
                        onAction={() => onChangeStep(step)}
                    />
                ))}
            </StepsContainer>
        </PaymentStepsContainer>
    );
};
