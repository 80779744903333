import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { redirectToLogin } from '@/helpers/redirect-to-login.helper';

const ResetPasswordCheckEmail = () => {
    const body = (
        <>
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={t('pages.reset-password.check-email.header')}
                description={[
                    t('pages.reset-password.check-email.description-1'),
                    t('pages.reset-password.check-email.description-2'),
                ]}
            />
            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={redirectToLogin}
                    defaultFocused
                />
            </FormContainer>
        </>
    );
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default ResetPasswordCheckEmail;
