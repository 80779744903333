import { useContext, useEffect } from 'react';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { BackgroundContext } from '@/contexts/background/background.context';
import { ChronologicalNonChronologicalModel } from '@/models/category/chronologicalNonChronological.model';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { PacketDetailsModel } from '@/models/packets/packet-details.model';

export const useMediaBackground = (
    model: ChronologicalNonChronologicalModel | MediaDetailsModel | PacketDetailsModel,
    shadeMode = SHADE_MODE.BIG,
    enabled = true,
) => {
    const { setShadeMode, setBcgSrc } = useContext(BackgroundContext);

    useEffect(() => {
        if (!enabled) return;

        setBcgSrc(model.getBackgroundSrc());
        setShadeMode(shadeMode);
    }, [enabled]);
};
