import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { Router, useRouter, routes } from '@/routing';
import React, { useEffect, useState } from 'react';
import { shouldRedirectToRoute } from './redirect-matchers';
import { checkWatchWhitelist } from '@/helpers/check-watch-whitelist.helper';
import { useCheckProfile } from '@/contexts/profile/profile.hooks';
import { buildRedirectParams } from '@/helpers/router/build-redirect-params.helper';
import { useRodoContext } from '@/contexts/rodo/rodo.context';
import { translateRoute } from './helpers';
import config from 'target/config';
import { isStandalone } from '@/constants/portal-recognition';

interface IZonedRedirectProps {
    children: React.ReactNode;
}

const ZonedRedirectProvider = ({ children }: IZonedRedirectProps) => {
    const [renderChildren, setRenderChildren] = useState<boolean>(false);
    const { asPath, query } = useRouter();
    const userModule = useUserModule();
    const hasProfile = useCheckProfile();
    const isLoggedOrDuringAutoLogin =
        userModule.isLogged() || userModule.isDuringAutoLogin(hasProfile);
    const isDuringLogout = userModule.isDuringLogout;
    const rodoContext = useRodoContext();
    useEffect(() => {
        if (checkWatchWhitelist()) {
            setRenderChildren(true);
            return;
        }

        if (isDuringLogout || (isStandalone && config.isTargetClosed)) return;

        const redirectPath = shouldRedirectToRoute({
            url: asPath,
            isLogged: isLoggedOrDuringAutoLogin,
            redirectRouteZones: process.env.redirectRouteZones,
            routes,
        });

        if (redirectPath) {
            const params = buildRedirectParams(query);
            Router.replaceRoute(redirectPath, params);
        } else {
            setRenderChildren(true);
        }
    }, [asPath, isLoggedOrDuringAutoLogin]);

    if (!renderChildren) return null;
    if (query.target) {
        const codeTV = query.target.includes(translateRoute('tvcode'));
        if (codeTV) {
            rodoContext?.setShowModal?.(false);
        }
    }

    return <>{children}</>;
};

export default ZonedRedirectProvider;
