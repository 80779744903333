import { DEFAULT_SUBTITLES_OPTIONS } from '@/configs/player.config';
import { createContext, useState } from 'react';
import { SubtitlesOptionsContextInterface, SubtitlesOptionsContextProviderProps } from './types';

export const PlayerSubtitlesOptionsContext = createContext<SubtitlesOptionsContextInterface>({
    subtitlesOptions: DEFAULT_SUBTITLES_OPTIONS,
    setSubtitlesOptions: () => null,
});

export const PlayerSubtitlesOptionsContextProvider = ({
    children,
}: SubtitlesOptionsContextProviderProps) => {
    const [subtitlesOptions, setSubtitlesOptions] = useState(DEFAULT_SUBTITLES_OPTIONS);

    return (
        <PlayerSubtitlesOptionsContext.Provider value={{ subtitlesOptions, setSubtitlesOptions }}>
            {children}
        </PlayerSubtitlesOptionsContext.Provider>
    );
};
