const getPathFromUrl = (url: string) => url.split('?')[0];

const getStepFromPaymentPath = (url: string) => {
    const stepRegexp = /step-(.*)/;
    const path = getPathFromUrl(url);
    const match = stepRegexp.exec(path);

    if (!match) return;

    return match[1];
};

export const isAuthorizedOnlyPaymentStep = (url: string) => {
    const step = getStepFromPaymentPath(url);

    if (step && parseInt(step) > 1) return true;

    return false;
};
