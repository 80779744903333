import { CheckSlowDevice } from '../../../helpers/check-slow-device.helper';
import { Z_INDEX } from '@/targets/default/theme';
import styled from 'styled-components';

export const Switch = styled.div`
    padding: 1.2rem;
`;

export const SwitchLabel = styled.label`
    display: inline-block;
    position: relative;
    width: 6.5rem;
    height: 2.8rem;
    border-radius: 1.4rem;
    background: ${(props) => props.theme.switchCheckbox.switchLabelBgColorBeforeChecked};
    vertical-align: middle;
    filter: ${CheckSlowDevice('drop-shadow(0px 0.3rem 0.15rem rgba(0, 0, 0, 0.26))')};
    transition: ${(props) => CheckSlowDevice(props.theme.switchCheckbox.transition)};
    cursor: pointer;
    z-index: ${Z_INDEX.STANDARD_COMPONENT};
    &:before {
        content: '';
        position: absolute;
        top: -0.7rem;
        width: 4rem;
        height: 4rem;
        border-radius: 2rem;
        background: ${(props) => props.theme.switchCheckbox.dotBgColorBeforeChecked};
        border-radius: 100%;
        filter: ${CheckSlowDevice('drop-shadow(0px 3px 1.5px rgba(0, 0, 0, 0.26))')};
        transition: ${(props) =>
            CheckSlowDevice(props.theme.switchCheckbox.transitionBeforeChecked)};
    }
`;
export const Input = styled.input`
    display: none;
    &:checked + ${SwitchLabel} {
        background: ${(props) => props.theme.switchCheckbox.switchLabelBgColorAfterChecked};
        &:before {
            left: 2.5rem;
            background: ${(props) => props.theme.switchCheckbox.dotBgColorAfterChecked};
        }
    }
`;
