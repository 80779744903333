import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { ParamsInterface, updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { buildFiltersForApi } from '@/helpers/Filters/build-filters-for-api.helper';
import { useCatalogQueryParams } from './page-catalog/use-catalog-query-params.hooks';
import { getFiltersCount } from '@/helpers/Filters/get-filters-count.helper';

const { initialLimit } = process.env.pages.catalog;

export const useCatalogParams = ({
    filterLists = [],
    resetParams,
    withPagination = false,
}: {
    filterLists?: FlatFilterListSchema[];
    resetParams?: unknown;
    withPagination?: boolean;
}) => {
    const { grid, setGrid } = useContext(CatalogContext);
    const { filters: filtersCtx } = grid;

    const { total, page, filtersUrl } = !process.env.spatialNavigation
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useCatalogQueryParams()
        : { total: 0, page: 0, filtersUrl: [] };

    const filtersUrlArray = useMemo(() => buildFiltersForApi(filterLists, filtersUrl || []), [
        filterLists,
        filtersUrl,
    ]);

    const filters = useMemo(() => [...filtersCtx, ...filtersUrlArray], [
        filtersCtx,
        filtersUrlArray,
    ]);

    const updatedParams = useMemo(
        () =>
            updateCatalogParams({
                withPagination,
                params: { offset: 0, limit: initialLimit, filters },
                total: total || 0,
                page,
            }),
        [withPagination, filters, total, page],
    );

    const [params, setParams] = useState<ParamsInterface>(() => updatedParams);
    const [clearGrid, setClearGrid] = useState<boolean>(false);

    const filtersCount = useMemo(() => getFiltersCount(filters), [filters]);

    useEffect(() => {
        if (page !== undefined && total !== undefined) {
            setParams(updatedParams);
        }
    }, [page]);

    useEffect(() => {
        setClearGrid(true);
        setParams({ ...updatedParams, filters });
    }, [filtersCount]);

    useEffect(() => {
        setClearGrid(true);
        setParams(updatedParams);
    }, [resetParams]);

    const clearGridFilters = useCallback(() => {
        setGrid((prev) => ({ ...prev, filters: [] }));
    }, []);

    return { params, setParams, clearGrid, setClearGrid, clearGridFilters };
};
