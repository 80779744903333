import BaseLayout from '../../components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ROUTES } from '@/routing/types';
import { PageContainer } from '@/components/views/Account/styles';
import { navVertical } from 'nav-tree';
import React from 'react';
import useVerify from '@/api/auth/use-verify';
import useConfirmVerification from '@/api/auth/use-confirm-verification';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import VerifyPhoneNoForm from '@/components/views/VerifyPhoneNumber/VerifyPhoneNoForm';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';

const EUAccess = () => {
    const [mutateVerify, { error: verifyError, isFetching: verifyIsFetching }] = useVerify();
    const [
        mutateConfirm,
        { error: confirmError, isFetching: confirmIsFetching },
    ] = useConfirmVerification();

    const verifyPhoneNumber = async (phoneNo: string) => {
        return await mutateVerify({ msisdn: phoneNo });
    };

    const confirmVerification = async (verificationCode: string) => {
        return await mutateConfirm({ verificationCode: verificationCode });
    };

    const handleSuccess = async () => {
        await Router.pushRoute(ROUTES.EU_VERIFICATION_SUCCESS);
    };

    const handleCancel = () => {
        Router.pushRoute(ROUTES.ACCOUNT);
    };

    const renderFormErrors = () => {
        const errorToDisplay = verifyError?.getUserMessage() || confirmError?.getUserMessage();

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer func={navVertical}>
                <VerifyPhoneNoForm
                    pageTitle={t('pages.account.eu-access.page-title')}
                    isLoading={verifyIsFetching || confirmIsFetching}
                    handleVerifyPhoneNo={verifyPhoneNumber}
                    handleConfirmPhoneNo={confirmVerification}
                    handleSuccess={handleSuccess}
                    handleCancel={handleCancel}
                />
                {renderFormErrors()}
            </PageContainer>
        </BaseLayout>
    );
};

export default EUAccess;
