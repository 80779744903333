import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, StrippedInParams } from '@/hooks/use-get-media-api.type';
import { GetOptionDataIn } from '@/interfaces/from-schemas/payments/getOptionDataIn';
import { OptionDataModel } from '@/models/payments/option-data.model';

export const useGetOptionData = (
    params?: StrippedInParams<GetOptionDataIn>,
): IApiResponse<OptionDataModel | undefined> => {
    const keyhook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_OPTION_DATA,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyhook, { appendAuthData: true });

    return {
        data: data && new OptionDataModel(data),
        error,
        isFetching,
    };
};
