import { TableMemoizedDataContextProvider } from '@/contexts/epg-table/table-memoized-data.context';
import { TablePageContextProvider } from '@/contexts/epg-table/table-page.context';
import { ColumnContextProvider } from '@/contexts/epg-table/column.context';
import { IncrementColumnContextProvider } from '@/contexts/epg-table/increment-column.context';
import { HourRangeContextProvider } from '@/contexts/epg-table/hour-range.context';
import { FocusedEPGContextProvider } from '@/contexts/epg-table/focused-epg.context';

const EPGTableContextProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <TableMemoizedDataContextProvider>
            <TablePageContextProvider>
                <ColumnContextProvider>
                    <IncrementColumnContextProvider>
                        <FocusedEPGContextProvider>
                            <HourRangeContextProvider>{children}</HourRangeContextProvider>
                        </FocusedEPGContextProvider>
                    </IncrementColumnContextProvider>
                </ColumnContextProvider>
            </TablePageContextProvider>
        </TableMemoizedDataContextProvider>
    );
};

export default EPGTableContextProvider;
