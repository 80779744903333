import { layouts } from '@/components/shared/VirtualKeyboard/layout';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useMemo, useState } from 'react';
import Keyboard from 'react-simple-keyboard';

import { KeyboardKey, KeyPosition, LAYOUTS, SpecialKeysDefinition } from './types';

export const useHoverKey = (
    keyboard: Keyboard | undefined,
    layoutName: LAYOUTS,
    defaultKey = ' 1',
    hoverClass = 'hovered-button',
) => {
    const [hoveredKey, setHoveredKey] = useState<KeyboardKey>(defaultKey);
    const [hoveredPosition, setHoveredPosition] = useState<KeyPosition>(
        getKeyPosition(defaultKey, layoutName),
    );
    const [previousPosition, setPreviousPosition] = useState<KeyPosition>([0, 0]);

    const getButtonAt = (row: number, col: number) => {
        return keyboard?.keyboardDOM?.querySelector(
            `.hg-button[data-skbtnuid="${layoutName}-r${row}b${col}"]`,
        );
    };

    const setHoverClass = (row: number, col: number) => {
        getButtonAt(row, col)?.classList.add(hoverClass);
    };

    const removeHoverClass = (row: number, col: number) => {
        getButtonAt(row, col)?.classList.remove(hoverClass);
    };

    const hover = (key: KeyboardKey, position?: KeyPosition) => {
        if (key === SpecialKeysDefinition.SUBMIT) {
            setHoveredKey(SpecialKeysDefinition.SUBMIT);
            removeHoverClass(...hoveredPosition);
            return;
        }

        batchedUpdates(() => {
            removeHoverClass(...hoveredPosition);

            if (position) {
                setHoverClass(...position);
                setHoveredPosition(position);
            }

            setPreviousPosition(hoveredPosition);
            setHoveredKey(key);
        });
    };

    useMemo(() => {
        // set initial hover key after keyboard is rendered for the first time
        if (keyboard?.keyboardDOM) {
            hover(hoveredKey, hoveredPosition);
        }
    }, [keyboard?.keyboardDOM]);

    return {
        hoveredKey,
        hoveredPosition,
        previousPosition,
        hover,
    };
};

const getKeyPosition = (key: string, layoutMode: LAYOUTS): [number, number] => {
    let row = 0;
    let column = 0;

    const currentLayout = layouts[layoutMode] || layouts.default;

    currentLayout.forEach((singleRow, index) => {
        const charIndex = singleRow.indexOf(key);

        if (charIndex === -1) {
            return false;
        }

        row = index;
        column = charIndex;
    });

    return [row, column];
};
