import {
    MinPriceSchema,
    PrePurchaseOfferCyclicSchema,
    PrePurchaseOfferSchema,
    PrePurchaseOfferTrialSchema,
} from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { OfferIdInterface } from '@/interfaces/product-id-interface';
import { OfferPricingPlanModel } from '../products/offer-pricing-plan.model';
import { RulesModel } from '../products/rules.model';
import { OfferUpgradeModel } from './offer-upgrade.model';
import { OptionUpgradeModel } from './option-upgrade.model';
import { PrePurchaseOptionModel } from './pre-purchase-option.model';

export class PrePurchaseOfferModel {
    private id: string;
    private type: OfferIdInterface['type'];
    private name?: string;
    private cyclic?: PrePurchaseOfferCyclicSchema;
    private offerPricingPlanRequired?: boolean;
    private offerPricingPlans: OfferPricingPlanModel[] = [];
    private options: PrePurchaseOptionModel[] = [];
    private upgrade?: OfferUpgradeModel;
    private minPrice?: MinPriceSchema;
    private accessText?: string;
    private longAccessText?: string;
    private trial?: PrePurchaseOfferTrialSchema;
    private rules: RulesModel[] = [];

    constructor(definition: PrePurchaseOfferSchema) {
        this.id = definition.id;
        this.type = definition.type;
        this.name = definition.name;
        this.cyclic = definition.cyclic;
        this.offerPricingPlanRequired = definition.offerPricingPlanRequired;
        this.minPrice = definition.minPrice;
        this.accessText = definition.accessText;
        this.longAccessText = definition.longAccessText;
        this.trial = definition.trial;

        if (definition.upgrade) {
            this.upgrade = new OfferUpgradeModel(definition.upgrade);
        }

        if (definition.offerPricingPlans) {
            const allOfferPricingPlans = definition.offerPricingPlans.map(
                (offerPricingPlan) => new OfferPricingPlanModel(offerPricingPlan),
            );
            this.offerPricingPlans = allOfferPricingPlans.filter((offerPricingPlan) =>
                offerPricingPlan.getPricingPlan().isSupported(),
            );
        }

        if (definition.options) {
            definition.options.forEach((option) => {
                if (option.type !== 'appstore' && option.type !== 'googleplay') {
                    const optionModel = new PrePurchaseOptionModel(option);

                    if (optionModel.isSupported()) {
                        this.options.push(optionModel);
                    }
                }
            });
        }

        if (definition.rules) {
            this.rules = definition.rules.map((rules) => new RulesModel(rules));
        }
    }

    public getId(): string {
        return this.id;
    }

    public getOfferId(): OfferIdInterface {
        return {
            id: this.id,
            type: this.type,
        };
    }

    public getName(): string {
        return this.name || '';
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }

    public isRulesExists(): boolean {
        return this.rules.length > 0;
    }

    public isOfferOrOptionsRulesExists(): boolean {
        let isRulesExists = this.isRulesExists();

        this.getOptions().forEach((option) => {
            if (option.isRulesExists()) {
                isRulesExists = true;
            }
        });

        return isRulesExists;
    }

    public isCyclic(): boolean {
        return Boolean(this.cyclic);
    }

    public isUpgradeAvailable(): boolean {
        return Boolean(this.upgrade);
    }

    public getOfferUpgrade(): OfferUpgradeModel | undefined {
        return this.upgrade;
    }

    public isOfferPricingPlanRequired(): boolean {
        return Boolean(this.offerPricingPlanRequired);
    }

    public isPricingPlansExists(): boolean {
        return this.offerPricingPlans.length > 0;
    }

    public getOfferPricingPlans(): OfferPricingPlanModel[] {
        return this.offerPricingPlans;
    }

    public findOfferPricingPlan(offerPricingPlanId?: string): OfferPricingPlanModel | undefined {
        return this.offerPricingPlans.find(
            (offerPricingPlan) => offerPricingPlan.getId() === offerPricingPlanId,
        );
    }

    public getOptions(): PrePurchaseOptionModel[] {
        return this.options;
    }

    public findOption(optionId?: string): PrePurchaseOptionModel | undefined {
        return this.options.find((option) => option.getId() === optionId);
    }

    public getFirstOptionUpgrade(): OptionUpgradeModel | undefined {
        const firstOptionWithUpgrade = this.options.find((option) => option.isUpgradeAvailable());
        return firstOptionWithUpgrade?.getOptionUpgrade();
    }

    public getMinPriceText(): string | undefined {
        return this.minPrice?.valueText;
    }

    public getMinPriceValue(): number | undefined {
        return this.minPrice?.value;
    }

    public getAccessText(): string | undefined {
        return this.accessText;
    }

    public getLongAccessText(): string | undefined {
        return this.longAccessText;
    }

    public getTrialDuration(): string | undefined {
        return this.trial?.durationText;
    }
}
