import { navHorizontal } from 'nav-tree';
import React, { useCallback } from 'react';

import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { HOUR_RANGE } from '@/constants/epg-table';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';

import * as Styled from './styles';
import { EPGTableArrowsProps } from './types';

const EPGTableArrowsRaw = ({ position }: EPGTableArrowsProps) => {
    const { setStartFullHour, canScrollBackward, canScrollForward } = useHourRangeContext();

    const onEnterDown = useCallback(
        (direction: 'up' | 'down') => () => {
            if (direction === 'up') {
                setStartFullHour((prev) => prev - HOUR_RANGE);
            }
            if (direction === 'down') {
                setStartFullHour((prev) => prev + HOUR_RANGE);
            }
        },
        [setStartFullHour],
    );

    return (
        <Styled.ArrowsWrapper func={navHorizontal} position={position}>
            <PlayableButton
                icon={ICONS.ARROW_DOWN}
                onButtonAction={onEnterDown('down')}
                style={{ opacity: canScrollForward ? 1 : 0.5 }}
            />
            <PlayableButton
                icon={ICONS.ARROW_UP}
                onButtonAction={onEnterDown('up')}
                style={{ opacity: canScrollBackward ? 1 : 0.5 }}
            />
        </Styled.ArrowsWrapper>
    );
};

export const EPGTableArrows = React.memo(EPGTableArrowsRaw);
