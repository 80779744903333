import { BUTTON_ID } from '@/types/button-id.type';
import { useRouter } from '@/routing';
import { RefObject, useEffect } from 'react';
import { handleNavFocus } from '@/helpers/nav-tree/focus-nav-id.helper';
import { Nav } from 'nav-tree';

export const useFocusButton = (focusRef?: RefObject<Nav>) => {
    const { prevRoute } = useRouter();

    useEffect(() => {
        if (!prevRoute?.includes('details')) return;

        handleNavFocus(BUTTON_ID.INFO, focusRef);
    }, [prevRoute, focusRef]);
};
