import clamp from 'lodash/clamp';

export const formatPhoneNumber = (value: string, cursor: number): [string, number] => {
    if (!value) return [value, cursor];

    const currentValue = value.replace(/\+48?|[^\d]/g, '');
    const cvLength = currentValue.length;

    let formatted;
    if (cvLength < 4) {
        formatted = currentValue;
    } else if (cvLength < 7) {
        formatted = `${currentValue.slice(0, 3)} ${currentValue.slice(3)}`;
    } else {
        formatted = `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)} ${currentValue.slice(
            6,
            9,
        )}`;
    }
    formatted = `+48 ${formatted}`;
    const formattedCursor =
        cursor === value.length
            ? formatted.length
            : clamp(cursor + formatted.length - value.length, 0, formatted.length);

    return [formatted, formattedCursor];
};

export const normalizePhoneNumber = (value: string, cursor: number): [string, number] => {
    const normalized = value.replace(/\+48|[^\d]/g, '');
    const normalizedCursor = clamp(cursor + normalized.length - value.length, 0, normalized.length);
    return [normalized, normalizedCursor];
};
