import Cookies, { CookieChangeListener } from 'universal-cookie';
import { CookiesOptionsInterface, CookiesServiceInterface } from './types';
import { getCookieDomain } from '@/helpers/environment.helper';

class CookiesService implements CookiesServiceInterface {
    protected cookies: Cookies;
    constructor(cookie?: string) {
        this.cookies = new Cookies(cookie);
    }

    public get(name: string): any {
        return this.cookies.get(name);
    }

    public getAll(): any {
        return this.cookies.getAll();
    }

    public set(name: string, value: any, options?: CookiesOptionsInterface): void {
        const domain = getCookieDomain();
        const defaultOptions: CookiesOptionsInterface = {
            path: '/',
            domain,
        };

        // Przed zapisem ciastka usuwamy wszystkie inne ciastka o tej samej nazwie
        if (this.get(name)) {
            this.remove(name);
        }

        this.cookies.set(name, value, { ...defaultOptions, ...options });
    }

    public remove(name: string) {
        const domain = getCookieDomain();

        this.cookies.remove(name, { path: '/', domain });

        // Jeśli ciastko nadal istnieje - próbujemy usunąć ciastko z domyślną domeną
        if (this.get(name)) {
            this.cookies.remove(name, { path: '/' });
        }
    }

    public listen(
        namePattern: RegExp | string,
        listener: (value: any, name: string) => void,
    ): () => void {
        const internalListener: CookieChangeListener = (cookie) => {
            if (typeof namePattern === 'string') {
                if (cookie.name !== namePattern) {
                    return;
                }
            } else if (!namePattern.test(cookie.name)) {
                return;
            }
            listener(cookie.value, cookie.name);
        };
        this.cookies.addChangeListener(internalListener);
        return () => {
            this.cookies.removeChangeListener(internalListener);
        };
    }
}

export default CookiesService;
