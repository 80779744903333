import styled from 'styled-components';
import { BUTTON_TYPE } from '../Buttons/Button/types';
import { IProgressOverlayProps } from './types';

export const ProgressOverlay = styled.div<IProgressOverlayProps>`
    position: absolute;
    left: 0;
    top: 0;
    width: ${({ progressInPercent }) => `${progressInPercent}%`};
    height: 100%;
    background-color: ${({ theme, btnType }) =>
        btnType === BUTTON_TYPE.TIMER
            ? theme.button.timerProgressOverlayColor
            : theme.button.progressOverlayColor};
    transition: width 1s linear;
`;
