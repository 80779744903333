import {
    GetPrePurchaseDataOut,
    PrePurchaseProductSchema,
    ProductIdSchema,
} from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { StatusDataInterface } from '@/interfaces/status-data.interface';
import { PrePurchaseProductModel } from './pre-purchase-product.model';
import { PURCHASE_AVAILABILITY_STATUS } from '@/interfaces/pre-purchase-data.interface';

export class PrePurchaseDataModel {
    private clientStatus: StatusDataInterface;
    private minPriceProduct?: PrePurchaseProductSchema;
    private minPricePurchaseText: string;
    private product: ProductIdSchema | PrePurchaseProductSchema;
    private prePurchaseProduct?: PrePurchaseProductModel;
    private productAccessText: string;
    private upgradeAvailability: StatusDataInterface;
    private purchaseAvailability: StatusDataInterface;
    private purchaseAvailabilityText: string;
    private purchaseCodeAvailability: StatusDataInterface;
    private relatedMinPriceProducts: PrePurchaseProductModel[];

    constructor(definition: GetPrePurchaseDataOut[number]) {
        this.clientStatus = definition.clientStatus;
        this.minPriceProduct = definition.minPriceProduct;
        this.minPricePurchaseText = definition.minPricePurchaseText || '';
        this.product = definition.product;
        this.productAccessText = definition.productAccessText || '';
        this.upgradeAvailability = definition.upgradeAvailability;
        this.purchaseAvailability = definition.purchaseAvailability;
        this.purchaseAvailabilityText = definition.purchaseAvailabilityText || '';
        this.purchaseCodeAvailability = definition.purchaseCodeAvailability;
        this.relatedMinPriceProducts = [];

        // Sprawdzenie czy pole `product` zwraca PrePurchaseProductSchema
        if ('name' in definition.product) {
            this.prePurchaseProduct = new PrePurchaseProductModel(
                definition.product,
                this.isPurchaseAvailable(),
                this.isUpgradeAvailable(),
            );
        }

        if (definition.relatedMinPriceProducts) {
            this.relatedMinPriceProducts = definition.relatedMinPriceProducts.map(
                (relatedMinPriceProduct) => new PrePurchaseProductModel(relatedMinPriceProduct),
            );
        }
    }

    public getProduct(): ProductIdSchema | PrePurchaseProductSchema {
        return this.product;
    }

    public getPrePurchaseProduct(): PrePurchaseProductModel | undefined {
        return this.prePurchaseProduct;
    }

    public getClientStatus(): StatusDataInterface {
        return this.clientStatus;
    }

    public getPurchaseAvailability(): StatusDataInterface {
        return this.purchaseAvailability;
    }

    public getPurchaseAvailabilityUserMessage(): string | undefined {
        return this.purchaseAvailability.statusUserMessage;
    }

    public getPurchaseCodeAvailability(): StatusDataInterface {
        return this.purchaseCodeAvailability;
    }

    public getPurchaseAvailabilityText(): string {
        return this.purchaseAvailabilityText;
    }

    public getMinPriceProduct(): PrePurchaseProductSchema | undefined {
        return this.minPriceProduct;
    }

    public getRelatedMinPriceProducts(): PrePurchaseProductModel[] {
        return this.relatedMinPriceProducts;
    }

    public getMinPricePurchaseText(): string {
        return this.minPricePurchaseText;
    }

    public isPurchaseAvailable(): boolean {
        return this.purchaseAvailability.status === PURCHASE_AVAILABILITY_STATUS.PURCHASE_AVAILABLE;
    }

    public getProductAccessText(): string | undefined {
        return this.productAccessText;
    }

    public getUpgradeAvailabilityUserMessage(): string | undefined {
        return this.upgradeAvailability.statusUserMessage;
    }

    public isUpgradeAvailable(): boolean {
        return this.upgradeAvailability.status === 0;
    }
}
