import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { AccountExistsMessage } from '@/components/views/login/AccountExistsMessage';

const AccountExists = () => {
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer body={<AccountExistsMessage />} />
        </BaseLayout>
    );
};

export default AccountExists;
