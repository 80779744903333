import { useMemo } from 'react';
import { useGetLive } from '@/api/live/use-get-event-list';
import { GetLiveChannelsIn } from '@/interfaces/from-schemas/navigation/getLiveChannelsIn';
import { prepareLives, buildCollectionLives } from './helpers';
import { GetCollectionLivesInterface } from '@/components/views/Live/types';

export const useGetCollectionLives = (
    liveParams: GetLiveChannelsIn | false,
): GetCollectionLivesInterface => {
    const { data, isFetching } = useGetLive(liveParams);

    const livesCollection = useMemo(() => {
        if (isFetching) {
            return null;
        }

        const lives = data?.getResults() || [];
        const collectionRaw = prepareLives(lives);
        return (collectionRaw && buildCollectionLives(collectionRaw)) || null;
    }, [isFetching]);

    return { livesCollection, isFetching };
};
