export enum FORMAT_DATE_MODE {
    DATE = 'date',
    DATE_WITH_YEAR = 'dateWithYear',
    HOURS = 'hours',
    FULL_DATE = 'fullDate',
    LIVE = 'live',
    LIVE_ARABIC_FORMAT = 'liveArabicFormat',
    LIVE_ARABIC_WITH_MONTH = 'liveArabicWithMonth',
    MINUTES_SECONDS = 'minutesSeconds',
    HOURS_MINUTES_SECONDS = 'hoursMinutesSeconds',
    ISO_8601 = 'iso8601',
}

export enum LOCALE {
    PL = 'pl',
    EN = 'enUS',
}

export enum TARGET_LOCALE {
    PL = 'pl-PL',
    EN = 'en-EN',
}

export interface TimeLeftTypes {
    totalSeconds: number;
    hours: number;
    minutes: number;
}
