import { useState } from 'react';
import * as Styled from './styles';
import { IconGroup } from '@/components/shared/Metadata';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';

export const ChannelFallback = () => {
    const [active, setActive] = useState(false);

    return (
        <Styled.ChannelFallback $isActive={active} onNav={setActive}>
            <IconGroup icon={ICONS.INFO_CIRCLE} iconSize={4.2} targetColor />
            <Styled.Text>{t('pages.epg-table.no-channel-data')}</Styled.Text>
        </Styled.ChannelFallback>
    );
};
