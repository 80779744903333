import defaultKeyHook from '../../hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '../../types/get-media-method.type';
import { useGetMediaApiMutation } from '../../hooks/use-get-media-api.hook';
import { DeleteUserOut } from '../../interfaces/from-schemas/auth/deleteUserOut';

interface IDeleteUser {
    password: string;
}

const useDeleteUser = () => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.DELETE_USER, null);

    return useGetMediaApiMutation<IDeleteUser, DeleteUserOut>(keyHook, {
        appendAuthData: true,
    });
};

export default useDeleteUser;
