import { StyledIconWrapper, StyledIconGroup } from './style';
import { IconGroupProps } from './types';
import { Icon } from '../Icon';
import { getIconName } from '@/helpers/get-icon-name.helper';

export const IconGroup = ({
    icon,
    iconSize,
    iconWidth,
    noMargin,
    gutter,
    targetColor,
}: IconGroupProps) => {
    return (
        <StyledIconGroup>
            <StyledIconWrapper
                $size={iconSize}
                $width={iconWidth}
                noMargin={noMargin}
                gutter={gutter}
                targetColor={targetColor}
            >
                <Icon name={getIconName(icon)} />
            </StyledIconWrapper>
        </StyledIconGroup>
    );
};
