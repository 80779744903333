import { ScrollPosition } from '@/components/shared/ScrollableContainer/types';
import { createContext, useState } from 'react';
import { ScrollContextProviderProps, ScrollContextProps } from './types';

export const ScrollContext = createContext<ScrollContextProps>({
    position: ScrollPosition.TOP,
    setPosition: () => null,
});

const ScrollContextProvider = ({ children }: ScrollContextProviderProps) => {
    const [position, setPosition] = useState<ScrollPosition>();

    return (
        <ScrollContext.Provider value={{ position, setPosition }}>
            {children}
        </ScrollContext.Provider>
    );
};

export default ScrollContextProvider;
