import styled from 'styled-components';
import { Nav } from 'nav-tree';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';
import { ElementsContainerInterface, GridRootInterface } from './types';

export const GridRoot = styled(Nav)<GridRootInterface>`
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    opacity: ${({ $hide, theme }) => ($hide ? theme.loadingWrapper.childOpacity : 1)};
    transition: ${CheckSlowDevice(`opacity 0.4s linear 0.2s`)};

    &:nth-child(1) > :last-child {
        margin-bottom: 100%;
    }
`;

export const ElementsContainer = styled(Nav).attrs<ElementsContainerInterface>(({ height }) => ({
    style: {
        height: height ? `${convertPxToRem(height)}` : 'auto',
    },
}))`
    display: flex;
`;
