import {
    IPlayerStatus,
    IPlayerTimeMarkers,
    SubtitlesOptionsInterface,
} from '@/interfaces/player.interface';

export const DEFAULT_PLAYER_TIME_MARKERS: IPlayerTimeMarkers = {
    adTimeLeft: 0,
    bufferedTime: 0,
    currentTime: 0,
    skipRange: {
        start: 0,
        end: 0,
    },
};

export const DEFAULT_PLAYER_STATUS: IPlayerStatus = {
    buffering: false,
    playback: {
        adBreaks: [],
        duration: 0,
    },
    playbackOptions: null,
    recomendations: null,
    areRecomendationsShown: false,
    state: 'NONE',
    phase: 'NONE',
    isReadyToPlayNext: false,
    isAllowedToSkipZone: false,
};

export const DEFAULT_SUBTITLES_OPTIONS: SubtitlesOptionsInterface = {
    size: [
        {
            label: '50%',
            value: 50,
            active: false,
        },
        {
            label: '75%',
            value: 75,
            active: false,
        },
        {
            label: '100%',
            value: 100,
            active: true,
        },
        {
            label: '150%',
            value: 150,
            active: false,
        },
        {
            label: '200%',
            value: 200,
            active: false,
        },
    ],
};

export const SECONDS_TO_SEEK = 10;

export const DEFAULT_PLAYER_LOADER_URL =
    'https://redirector.redefine.pl/versions/loader.js?target=old&autoload=false';
