import React, { useContext } from 'react';
import { RecoContext } from '@/contexts/reco/reco.context';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import { StyledCollectionTitle, StyledTitleInner } from './styles';
import { CollectionTitleOwnProps, TitleWrapperOwnProps } from './types';
import { SliderTitle } from '../Slider';

export const TitleWrapper = ({ children }: TitleWrapperOwnProps) => (
    <StyledCollectionTitle>
        <StyledTitleInner>{children}</StyledTitleInner>
    </StyledCollectionTitle>
);

const CollectionTitleRaw = ({ list, rowIndex }: CollectionTitleOwnProps) => {
    const isPromoboxType = () => list.getLayout().value === LIST_TYPE.PROMOBOX;
    const { activeRow } = useContext(RecoContext);

    const showTitle = activeRow !== rowIndex || !isPromoboxType();

    return showTitle ? (
        <TitleWrapper>
            <SliderTitle text={list.getName()} customData={list.getLayout().customData} />
        </TitleWrapper>
    ) : null;
};

export const CollectionTitle = React.memo(CollectionTitleRaw);
