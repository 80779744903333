import { useEffect, useState } from 'react';
import useAddToFavorites from '@/api/user-content/use-add-to-favorites';
import useDeleteFromFavorites from '@/api/user-content/use-delete-from-favorites';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { useDebouncedEffect } from '../use-debounced-effect.hook';
import { useRefetchChannelList } from './use-refetch-channel-list.hook';
import { useIsUserLogged } from '../user/use-is-user-logged.hook';

const SHOW_ALERT_TIMEOUT = 3000;

export const useMediaDetailsFavorities = (
    media: MediaDetailsModel,
    handleShowModal?: (show: boolean) => void,
) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const isLogged = useIsUserLogged();

    const [addToFavorites, { status: addStatus, isFetching: isFetchingAdd }] = useAddToFavorites();
    const [
        deleteFromFavorites,
        { status: deleteStatus, isFetching: isFetchingDelete },
    ] = useDeleteFromFavorites();

    const noFetching = !isFetchingAdd && !isFetchingDelete;

    useRefetchChannelList(media, showInfo);

    useDebouncedEffect(
        () => {
            if (noFetching) {
                setShowInfo(false);
            }
        },
        { timeout: SHOW_ALERT_TIMEOUT },
        [noFetching],
    );

    useEffect(() => {
        if (noFetching)
            if (addStatus === 'success' || deleteStatus === 'success') {
                setShowInfo(true);
            }
    }, [noFetching, addStatus, deleteStatus]);

    const handleButtonAction = (button: MediaButtonInterface) => {
        if (!isLogged && button.isFavorites) {
            return handleShowModal?.(true);
        }

        if (media.isInFavorites) {
            button.onButtonAction(deleteFromFavorites);
        } else {
            button.onButtonAction(addToFavorites);
        }
    };

    return {
        showInfo,
        handleButtonAction,
    };
};
