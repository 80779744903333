import { GM_APPLICATION, GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import AdbToken from '@/types/stb/AdbToken';
import { DeviceSTBModelName } from '@/types/stb/StbProperties';
import { Platform } from '@/services/platform-recognition';
import { clientAdb } from './InternalAdbClient';
import { STBDRMType } from '@/types/stb/StbProperties';

const DEVICE_MODEL_NAME_PREFIX = 'CP-';
const STB_CP_HTML_PREFIX = 'stb_cp_html_';

const getDeviceModelName = async (): Promise<DeviceSTBModelName | undefined> => {
    const deviceModelName = (await clientAdb?.getTokenValue(
        AdbToken.VAR_MODEL_NAME,
    )) as DeviceSTBModelName;
    if (!deviceModelName) return;
    return deviceModelName;
};

export const setStbDRMType = async (deviceType: string | undefined) => {
    const nagra2DeviceUniqueId = await getNagra2DeviceUniqueId();
    if (deviceType === GM_DEVICE_TYPE.STB_D30_EVOBOX_PVR) {
        window.stb.stbDRMType = STBDRMType.NAGRA;
    } else if (nagra2DeviceUniqueId) {
        window.stb.stbDRMType = STBDRMType.NAGRA2;
    } else {
        window.stb.stbDRMType = STBDRMType.IRDETO;
    }
    console.log(`stbDRMType: ${window.stb.stbDRMType}`);
};

export const setStbDecoderId = async (platform?: Platform) => {
    const { decoderId } = process.env;
    if (platform?.deviceType === GM_DEVICE_TYPE.STB_D30_EVOBOX_PVR) {
        const nagraDecoderId = await clientAdb?.getTokenValue(AdbToken.CONST_NAGRA_CAID);
        window.stb.deviceId = nagraDecoderId || (decoderId as string);
    } else {
        const cpChipId = await clientAdb?.getTokenValue(AdbToken.VAR_CP_CHIP_ID);
        if (!cpChipId) {
            const irdetoDecoderId = await getStbIrdetoSerialNumber();
            window.stb.deviceId = irdetoDecoderId || (decoderId as string);
        } else {
            window.stb.deviceId = cpChipId;
        }
    }
    console.log(`Device id: ${window.stb.deviceId}`);
};

export const getStbIrdetoSerialNumber = async (): Promise<string> => {
    const { decoderId } = process.env;
    const token = await clientAdb?.getTokenValue(AdbToken.CONST_IRDETOCCA_CLIENT_ID);
    const splittedToken = token?.split('(');
    let irdetoDecoderId;
    if (splittedToken && splittedToken.length > 1) {
        irdetoDecoderId = splittedToken[0];
    } else if (token) {
        irdetoDecoderId = token;
    }
    if (!irdetoDecoderId) {
        irdetoDecoderId = decoderId as string;
        return irdetoDecoderId;
    } else {
        return irdetoDecoderId;
    }
};

// nagra2 unique identifier
export const getNagra2DeviceUniqueId = async (): Promise<string | undefined> => {
    const nagra2DeviceUniqueId = await clientAdb?.getNagra2DeviceUniqueId();
    return nagra2DeviceUniqueId;
};

// nagra unique identifier
export const getDeviceNUID = async (): Promise<string | undefined> => {
    let deviceNUID = await clientAdb?.getTokenValue(AdbToken.CONST_NAGRA_NUID);
    if (!deviceNUID) return;
    deviceNUID = deviceNUID.replace(/\s+/g, '').slice(0, -2);
    return deviceNUID;
};

export const getDeviceCTS = async (): Promise<string | undefined> => {
    const deviceCTS = await clientAdb?.getTokenValue(AdbToken.CONST_NAGRA_DVL_CHIPSET_TYPE);
    if (!deviceCTS) return;
    return deviceCTS;
};

export const recognizeStbPlatform = async (): Promise<Platform> => {
    const browserUserAgent =
        typeof window !== 'undefined' ? (window as any)?.navigator?.userAgent : '';

    let deviceType = process.env.defaultUserData?.userAgentData?.deviceType as GM_DEVICE_TYPE;

    try {
        const deviceModelName = await getDeviceModelName();
        if (!deviceModelName) {
            throw new Error('PC');
        }
        if (deviceModelName === DeviceSTBModelName.STB_D30_EVOBOX_PVR) {
            deviceType = GM_DEVICE_TYPE.STB_D30_EVOBOX_PVR;
        } else if (deviceModelName.indexOf(DEVICE_MODEL_NAME_PREFIX) === 0) {
            deviceType = `${STB_CP_HTML_PREFIX}${deviceModelName.replace(
                DEVICE_MODEL_NAME_PREFIX,
                '',
            )}` as GM_DEVICE_TYPE;
        } else {
            console.log("Unknown device based on stb.infoSvc.getTokenValue('var.cp.model_name');");
        }
        console.log(`Device type: ${deviceType}`);
    } catch (e) {
        console.log("Unknown device based on stb.infoSvc.getTokenValue('var.cp.model_name');");
    }

    try {
        await setStbDRMType(deviceType);
    } catch (e) {
        console.log('Failed to recognize DRM type.');
    }

    return {
        application: GM_APPLICATION.Native,
        browserUserAgent,
        deviceType,
        os: GM_OS.Linux,
        model: 'unknown',
    };
};

export const isStb = (platform?: Platform) =>
    platform?.deviceType ? platform.deviceType.indexOf(STB_CP_HTML_PREFIX) === 0 : false;
