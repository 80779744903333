import { InputRefObject } from '@/contexts/keyboard/types';
import { VirtualKeyboardContextContext } from '@/contexts/keyboard/virtual.keyboard.context';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { disableNativeKeyboard } from '@/helpers/virtual-keyboard.helper';
import { useCallback, useContext, useEffect } from 'react';

export const useVirtualKeyboard = (
    inputRef: InputRefObject,
    updateValue: (value: string) => void,
    format?: (value: string, cursor: number) => [string, number],
    normalize?: (value: string, cursor: number) => [string, number],
) => {
    const { enabled, showKeyboard: showKeyboardCallback } = useContext(
        VirtualKeyboardContextContext,
    );
    const elem = inputRef.current;
    const { platform } = usePlatform();
    const { deviceType } = platform;

    useEffect(() => {
        if (enabled && deviceType) {
            disableNativeKeyboard(deviceType);
        }
    }, [enabled, deviceType]);

    const updateInputState = (value: string, cursorPosition: number) => {
        updateValue(value);

        // Defer setting input cursor to valid position and enable input scrolling
        setTimeout(() => {
            if (elem && elem.setSelectionRange) {
                elem.focus();
                elem.setSelectionRange(cursorPosition, cursorPosition);
                elem.scrollLeft = elem.scrollWidth;
            }
        }, 0);
    };

    const showKeyboard = useCallback(() => {
        showKeyboardCallback(inputRef, updateInputState, format, normalize);
    }, [inputRef, showKeyboardCallback, updateInputState]);

    return {
        enabled,
        showKeyboard,
    };
};
