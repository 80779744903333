import { IUseChangeEmail } from '@/api/auth/use-change-email';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { checkAccountExistsException } from '@/helpers/auth/check-account-exists-exception.helper';
import { ChangeEmailOut } from '@/interfaces/from-schemas/auth/changeEmailOut';
import { RegisterIn } from '@/interfaces/from-schemas/auth/registerIn';
import { RegisterOut } from '@/interfaces/from-schemas/auth/registerOut';
import { useRouter } from '@/routing';
import { UserAuthData } from '@/types/user-auth-data.type';
import { IGetMediaMutateFn } from '../use-get-media-api.type';
import { isStbTargets } from '@/constants/portal-recognition';

export const useChangeEmailOrRegister = (
    changeEmail: IGetMediaMutateFn<IUseChangeEmail, ChangeEmailOut>,
    registerUser: IGetMediaMutateFn<RegisterIn, RegisterOut>,
) => {
    const userModule = useUserModule();
    const authData = userModule.getAuthData();
    const { query } = useRouter();
    const { authProvider, changeEmail: changeEmailQuery } = query;
    const deviceId = userModule.getDeviceIdIdent();

    const handleChangeEmail = async (email: string, authData?: UserAuthData) => {
        const params = { email };
        if (authData) {
            Object.assign(params, { authData });
        }

        const result = await changeEmail(params);

        return Promise.resolve(result);
    };

    const handleRegisterUser = async (email: string, userAuthData: UserAuthData) => {
        const params = { registrationData: { ...userAuthData, email } } as RegisterIn;
        if (isStbTargets && params.registrationData) {
            Object.assign(params.registrationData, { deviceId });
        }
        const result = await registerUser(params);

        checkAccountExistsException(result, authProvider);

        return Promise.resolve(result);
    };

    const mutation = (email: string) => {
        if (!authData && !changeEmailQuery) return handleChangeEmail(email, authData);

        // migracja konta
        if (authData) {
            if (changeEmailQuery) {
                return handleChangeEmail(email, authData);
            } else {
                return handleRegisterUser(email, authData);
            }
        }

        return Promise.resolve({ ok: false });
    };

    return mutation;
};
