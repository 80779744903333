import styled, { css } from 'styled-components';
import { ActivityProps } from './types';
import { Nav } from 'nav-tree';
import { Z_INDEX } from '@/targets/default/theme';
import { fillIcon } from '@/helpers/fill-icon.helper';

export const AccountMenuContainerStyled = styled.div`
    width: 55.5rem;
    margin-left: 15rem;
    z-index: ${Z_INDEX.MENU_CONTAINER};
    float: left;
`;

export const NavContainerStyled = styled(Nav)`
    width: 100%;
    overflow: hidden;
    padding-top: 13rem;
`;

export const MenuItemIconStyled = styled.div`
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 2.5rem;
    color: ${(props) => props.theme.menu.activeColor};
    ${({ theme }) => fillIcon(theme.menu.activeColor)};
`;

export const AccountMenuItemStyled = styled.div<ActivityProps>`
    display: flex;
    align-items: center;
    padding: 1.9rem 0 1.9rem 6rem;
    color: ${(props) => props.theme.menu.color};
    cursor: default;

    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.theme.menu.activeColor};
            ${({ theme }) => fillIcon(theme.menu.activeColor)};
        `}

    &:hover {
        cursor: pointer;
    }
`;

export const MenuItemLabelStyled = styled.div<ActivityProps>`
    font-size: ${(props) => props.theme.text.size.subtitle};

    ${(props) =>
        props.active &&
        css`
            transform: scale(1.1);
        `}
`;
