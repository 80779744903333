import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { LoginOut } from '@/interfaces/from-schemas/auth/loginOut';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { UserAuthData } from '@/types/user-auth-data.type';
import { DeviceIdInterface } from '@/interfaces/device-id-interface';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { EVENT, EventParams } from '@/stats';
import { prepareParamsUserLoggedActivityEvents } from '@/helpers/stats.helper';
import { useEffect } from 'react';
import { LoginIn } from '@/interfaces/from-schemas/auth/loginIn';
import { isAutoLoginSupported } from '@/helpers/auto-login.helper';

interface IUseLogin {
    authData: UserAuthData & { deviceId: DeviceIdInterface };
}

const useLogin = (captcha?: string | null) => {
    const statsModule = useStatsModule();
    const userModule = useUserModule();

    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.LOGIN, null);
    const deviceId = userModule.getDeviceIdIdent();

    const [mutate, state] = useGetMediaApiMutation<IUseLogin, LoginOut>(keyHook, {});
    const loginError = state.error;

    useEffect(() => {
        if (loginError?.getCode() === ERROR_CODES_GET_MEDIA.B2C_UNACCEPTED_RULES_ERROR) {
            return Router.pushRoute(ROUTES.LOGIN_RULES_OBLIGATORY);
        }
    }, [loginError]);

    const mutateWithDeviceId: (
        params: UserAuthData,
        additionalParams?: Partial<LoginIn>,
    ) => ReturnType<typeof mutate> = async (authData, additionalParams) => {
        const params = { authData: { ...authData, deviceId } };
        if (captcha) {
            Object.assign(params, { captcha: { type: 'recaptcha', value: captcha } });
        }

        if (additionalParams) {
            Object.assign(params, additionalParams);
        }

        const result = await mutate(params);

        const hitParams: EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED> = prepareParamsUserLoggedActivityEvents(
            result,
            params?.authData?.authProvider,
        );

        if (result.ok) {
            userModule.setAuthSession(result.data);

            if (isAutoLoginSupported(authData)) {
                userModule.saveCredentials(authData);
            }
        }

        statsModule.hitAppUserLogged(hitParams);

        return result;
    };

    return [mutateWithDeviceId, state] as [typeof mutateWithDeviceId, typeof state];
};

export default useLogin;
