import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { RecommendationView } from '@/components/views/common';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import { useRedirectToDeepLink } from '@/hooks/use-redirect-to-deep-link';

const Home = () => {
    const home = process.env.pages.home;
    const { placeType } = home;
    const listsParams = { place: { type: placeType, value: '' } };
    const collectionsConfig = useMemo(() => buildCollectionsListConfig(home), [home]);

    useRedirectToDeepLink();

    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <RecommendationView config={collectionsConfig} params={listsParams} />
        </BaseLayout>
    );
};

export default Home;
