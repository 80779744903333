/*! v1.0.3 */
var init = function () {
    if ("undefined" == typeof stb) { var stb = {}; }

    stb.JSONExceptionHandler = function (exception) {
        const errorEvent = new CustomEvent('ADBException', {
            detail: {
                exception: exception,
                message: exception.toString()
            }
        })
        window.dispatchEvent(errorEvent)
        console.error("ADB_JS_JSON_EXCEPTION: " + exception.toString());
    }

    if (typeof stb == "undefined") {
        stb = {};
    }

    var USE_AJAX = (typeof stb.JSONRequest !== "function");

    var USE_V8_CAPTURE_STACK_TRACE = !!((typeof Error === "function") && Error.captureStackTrace);

    var _parseJSON = function (data) {
        var obj;
        eval('obj = ' + data);
        return obj;
    };

    var _toJSON = function (obj) {
        var escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
        var meta = { '\b': '\\b', '\t': '\\t', '\n': '\\n', '\f': '\\f', '\r': '\\r', '"': '\\"', '\\': '\\\\' };

        var escapeStringInternal = function (str) {
            var c = meta[str];
            return (typeof c === 'string') ? c : '\\u' + ('0000' + str.charCodeAt(0).toString(16)).slice(-4);
        }

        var escapeString = function (str) {
            escapable.lastIndex = 0;
            return '"' + (escapable.test(str) ? str.replace(escapable, escapeStringInternal) : str) + '"';
        }

        var toJSONInternal = function (o) {
            var t = typeof o;
            switch (t) {
                case 'string':
                    return escapeString(o);
                case 'boolean':
                    return new String(o);
                case 'number':
                    return new String(o);
                case 'object':
                    if (o) {
                        var a = [];
                        if (o.constructor == Array) {
                            for (var i = 0; i < o.length; i++) {
                                a[i] = toJSONInternal(o[i]);
                            }
                            return '[' + a.join(',') + ']';
                        }
                        else {
                            for (var p in o) {
                                a[a.length] = escapeString(p) + ':' + toJSONInternal(o[p]);
                            }
                            return '{' + a.join(',') + '}';
                        }
                    }
                    else if (null === o) {
                        return 'null';
                    }
                default:
                    throw new adbException(adbException.GENERAL_ERROR, "Invalid JSON string (" + t + ")");
            }
        }
        return toJSONInternal(obj);
    };

    if (typeof JSON !== "undefined") {
        if (typeof JSON.parse === "function") {
            _parseJSON = function (data) {
                return JSON.parse(data);
            }
        }
        if (typeof JSON.stringify === "function") {
            _toJSON = function (obj) {
                return JSON.stringify(obj);
            }
        }
    }

    var adbException = function (code, message) {
        this.code = code ? code : -1;
        this.message = message ? message : "";
        if (USE_V8_CAPTURE_STACK_TRACE) {
            Error.captureStackTrace(this, adbException);
        } else {
            this.stack = (new Error()).stack;
        }
    };
    adbException.prototype = new Error();

    adbException.prototype.toString = function () {
        return "[adbException code:" + this.code + ", message:\"" + this.message + "\"]";
    };

    adbException.GENERAL_ERROR = -1;
    adbException.INVALID_PARAMETER = 1;
    adbException.NOT_ENOUGH_SYSTEM_RESOURCES = 2;
    adbException.NOT_POSSIBLE = 3;
    adbException.STORAGE_ERROR = 4;
    adbException.INVALID_SERVICE = 5;
    adbException.PERMISSION_DENIED = 6;
    adbException.NOT_IMPLEMENTED = 7;

    adbException.prototype.GENERAL_ERROR = adbException.GENERAL_ERROR;
    adbException.prototype.INVALID_PARAMETER = adbException.INVALID_PARAMETER;
    adbException.prototype.NOT_ENOUGH_SYSTEM_RESOURCES = adbException.NOT_ENOUGH_SYSTEM_RESOURCES;
    adbException.prototype.NOT_POSSIBLE = adbException.NOT_POSSIBLE;
    adbException.prototype.STORAGE_ERROR = adbException.STORAGE_ERROR;
    adbException.prototype.INVALID_SERVICE = adbException.INVALID_SERVICE;
    adbException.prototype.PERMISSION_DENIED = adbException.PERMISSION_DENIED;
    adbException.prototype.NOT_IMPLEMENTED = adbException.NOT_IMPLEMENTED;

    window.adbException = adbException;

    var jsonclient = new function () {
        this.serverURL = null;
        if ("undefined" == typeof ADB_API_URL) {
            this.serverURL = "http://127.0.0.1:8080/JSON-RPC/";
        }
        else if (null !== ADB_API_URL) {
            this.serverURL = ADB_API_URL;
        }

        if ("function" !== typeof stb.JSONExceptionHandler) {
            alert("ERROR: Generic exception handler undefined.\nPlease define own stb.JSONExceptionHandler(exception) implementation!");
            return;
        }
        var throwJSONException = function (e) {
            if ("function" === typeof stb.JSONExceptionHandler) {
                stb.JSONExceptionHandler(e);
            }
        }

        var maxNumOfActiveReq = 2; /* max number of active requests, at least one for call and one for event */
        var requestId = 1;
        var asyncRequests = [];
        var asyncResponses = [];
        var asyncTimeout = null;
        var activeReqCnt = 0;

        var _async_handler = function () {
            var res, req;
            asyncTimeout = null;

            while (asyncResponses.length > 0) {
                res = asyncResponses.shift();
                try {
                    res.cb(res.result, res.ex);
                }
                catch (e) {
                    throwJSONException(e);
                }
            }

            while (asyncRequests.length > 0) {
                if (maxNumOfActiveReq > activeReqCnt) {
                    req = asyncRequests.shift();
                    try {
                        _sendRequest(req.client, req);
                    }
                    catch (e) { }
                }
                else {
                    break;
                }
            }
        }

        var kick_async = function () {
            if (!asyncTimeout) {
                asyncTimeout = setTimeout(_async_handler, 0);
            }
        }

        var _makeRequest = function (client, methodName, args) {
            var req = {};
            req.client = client;
            req.requestId = requestId++;

            var obj = "{\"id\":" + req.requestId + ",\"method\":";

            obj += "\"" + methodName + "\"";

            if (client.vcid) {
                obj += ",\"vci\":" + client.vcid;
            }

            obj += ",\"params\":" + _toJSON(args);

            req.data = obj + "}";
            return req;
        }

        var _sendRequest;
        if (USE_AJAX) {
            _sendRequest = function (client, req, internal) {
                var http = poolGetHTTPRequest();
                http.open("POST", client.serverURL, !!req.cb);
                http.setRequestHeader("Content-Type", "text/plain");
                if (req.cb) {
                    http.onreadystatechange = function () {
                        var res;
                        if (http.readyState == 4) {
                            http.onreadystatechange = function () {
                            }
                            res = { cb: req.cb, result: null, ex: null };
                            try {
                                res.result = client.handleResponse(http).result;
                            }
                            catch (e) {
                                res.ex = e;
                            }
                            asyncResponses.push(res);
                            kick_async();
                        }
                    }
                }

                try {
                    http.send(req.data);
                }
                catch (e) {
                    poolReturnHTTPRequest(http);
                    throw new adbException(adbException.GENERAL_ERROR, "Connection failed");
                }

                if (!req.cb) {
                    if (!internal) {
                        return client.handleResponse(http).result;
                    }
                    else {
                        return client.handleResponse(http);
                    }
                }
                return null;
            }
        }
        else {
            _sendRequest = function (client, req, internal) {
                var request = stb.JSONRequest(req.data);
                if (!internal) {
                    return client.handleResponse(request).result;
                }
                else {
                    return client.handleResponse(request);
                }
            }
        }

        var httpAll = [];
        var httpIdx = 0;
        var httpUnused = [];
        var httpMaxCnt = 8;

        var poolGetHTTPRequest = function () {
            var http = httpUnused.pop();
            if (!http) {
                try {
                    http = new XMLHttpRequest();
                    var idx = httpIdx++;
                    httpAll[idx] = http;
                    http._idx = idx;
                }
                catch (e) {
                    throw new adbException(adbException.GENERAL_ERROR, "Can't create XMLHttpRequest object");
                }
            }
            ++activeReqCnt;
            return http;
        }

        var poolReturnHTTPRequest = function (http) {
            --activeReqCnt;
            if (httpUnused.length >= httpMaxCnt) {
                httpAll.splice(http._idx, 1);
            }
            else {
                httpUnused.push(http);
            }
        }

        this.call = function (hash, params, obj) {
            var request,
                callback,
                args = Array.prototype.slice.call(params);

            if (typeof args[0] == "function") {
                callback = args.shift();
            }

            if (obj) {
                if (obj._jso_hash) {
                    request = _makeRequest(this, "cm", [obj._jso_hash, hash, args]);
                }
                else {
                    throw new adbException(adbException.GENERAL_ERROR, "Invalid hash (probably broken object implementation)");
                }
            }
            else {
                request = _makeRequest(this, "cf", [hash, args]);
            }

            if (callback) {
                //async
                request.cb = callback;
                asyncRequests.push(request);
                kick_async();
            }
            else {
                //sync
                return _sendRequest(this, request);
            }
        }

        this.create = function (hash, params) {
            var args = Array.prototype.slice.call(params || []);
            var request = _makeRequest(this, "new", [hash, args]);
            var res = _sendRequest(this, request, true);
            return { hash: res.hash, result: res.result };
        }

        this.destroy = function () {
            var args = Array.prototype.slice.call(arguments);
            var callback = null;

            if (typeof args[0] == "function") {
                callback = args.shift();
            }

            var object = args.shift();
            var request = _makeRequest(this, "del", [object._jso_hash]);

            if (callback) {
                request.cb = callback;
            }
            else {
                request.cb = function (result, ex) {
                }
            }
            asyncRequests.push(request);
            kick_async();
            delete refs[object._jso_hash];
        }

        this.addListener = function (obj, type, listener, ctx) {
            if (!obj._et_internal) {
                _addListener(this, obj, type);
            }
            var cb, fn;
            if (typeof listener == "function") {
                if (!ctx) {
                    ctx = fake_ctx;
                }
                fn = listener;
                cb = { ctx: ctx, fn: fn };
            }
            else {
                ctx = listener;
                fn = listener.handleEvent;
                cb = { ctx: ctx, fn: fn };
            }
            // no check if the registration in middleware was successful to allow listeners for other types
            var ref = refs[obj._jso_hash];
            if (ref) {
                var types = ref["cb"];
                var list = types[type];
                if (list) {
                    var i = 0;
                    for (; list.length > i; ++i) {
                        if ((list[i].ctx === ctx) && (list[i].fn === fn)) {
                            break;
                        }
                    }
                    if (list.length == i) {
                        // event handlers are stored in reversed order so that one could
                        // remove a callback from the view in the callback itself
                        list.unshift(cb);
                    }
                }
                else {
                    types[type] = [cb];
                    ref["cnt"]++;
                }
            }
            else {
                var o = { cnt: 1, cb: {}, obj: obj };
                o["cb"][type] = [cb];
                refs[obj._jso_hash] = o;
            }
        }

        this.removeListener = function (obj, type, listener, ctx) {
            if (!obj._et_internal) {
                _removeListener(this, obj, type);
            }
            var fn;
            if (typeof listener == "function") {
                if (!ctx) {
                    ctx = fake_ctx;
                }
                fn = listener;
            }
            else {
                ctx = listener;
                fn = listener.handleEvent;
            }
            var ref = refs[obj._jso_hash];
            if (ref) {
                var types = ref["cb"];
                var list = types[type];
                if (list) {
                    if (list.length == 1) {
                        if (ref["cnt"] > 1) {
                            delete types[type];
                            ref["cnt"]--;
                        }
                        else {
                            delete refs[obj._jso_hash];
                        }
                    }
                    else {
                        for (var i = 0; list.length > i; ++i) {
                            if ((list[i].ctx === ctx) && (list[i].fn === fn)) {
                                list.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
            }
        }

        this.dispatchEvent = function (obj, event, type) {
            var ref = refs[obj._jso_hash];
            if (ref) {
                _dispatchEvent(ref, event, type);
            }
            return false;
        }

        this.hasAnyListeners = function (obj, type) {
            var hasListeners = false;
            var ref = refs[obj._jso_hash];
            if (ref) {
                hasListeners = _hasAnyListeners(ref, type);
            }
            return hasListeners;
        }

        this.removeEventListenersByEvent = function (obj, type) {
            var ref = refs[obj._jso_hash];
            if (ref) {
                var listeners = ref["cb"] && ref["cb"][type];
                if (listeners) {
                    if (ref["cnt"] > 1) {
                        delete ref["cb"][type];
                        ref["cnt"]--;
                    }
                    else {
                        delete refs[obj._jso_hash];
                    }
                }
            }
        }

        // internals
        var refs = {}; // list of remote reference hashes, each one is an array (type) of lists of listeners
        var fake_ctx = {}; // fake listener context for listeners being plain functions, not EventListeners

        var _addListener = function (client, obj, type) {
            var request = _makeRequest(client, "cb", [obj._jso_hash, type]);
            return _sendRequest(client, request);
        }

        var _removeListener = function (client, obj, type) {
            var request = _makeRequest(client, "cbu", [obj._jso_hash, type]);
            return _sendRequest(client, request);
        }

        var _dispatchEvent = function (ref, event, type) {
            if (!type) {
                type = event.type;
            }
            var list = ref["cb"][type];
            if (list) {
                // event handlers are stored in reversed order so that one could
                // remove a callback from the view in the callback itself
                for (var i = list.length - 1; i >= 0; --i) {
                    var cb = list[i],
                        ctx = cb.ctx,
                        fn = cb.fn;
                    if (ctx === fake_ctx) {
                        ctx = window;
                    }
                    try {
                        fn.call(ctx, event);
                    }
                    catch (e) {
                        var ex = new adbException(adbException.GENERAL_ERROR, "Application listener threw an exception.");
                        ex.app_exception = e;
                        throwJSONException(ex);
                    }
                }
            }
        }

        var _hasAnyListeners = function (ref, type) {
            // check if the list of listeners exists and return bool
            return !!(ref["cb"] && ref["cb"][type]);
        }

        var getEvents = function (callback, client) {
            try {
                var request = _makeRequest(client, "ev", []);
                request.cb = callback;
                asyncRequests.push(request);
                kick_async();
            }
            catch (e) {
                throwJSONException(new adbException(adbException.GENERAL_ERROR, "Gathering events failed."));
            }
        }

        var _evaluate = function (data) {
            var obj;
            try {
                obj = _parseJSON(data);
            }
            catch (e) {
                throw new adbException(adbException.NOT_POSSIBLE, "Error parsing result");
            }
            if (obj.error) {
                throw new adbException(obj.error.code, obj.error.message);
            }
            return obj;
        }

        if (USE_AJAX) {
            this.handleResponse = function (http) {
                var status = http.status, data = http.responseText;
                poolReturnHTTPRequest(http);
                if (status == 200) {
                    return _evaluate(data);
                }
                else {
                    kick_async();
                    throw new adbException(adbException.GENERAL_ERROR, "AJAX error");
                }
            }
        }
        else {
            this.handleResponse = function (response) {
                return _evaluate(response);
            }
        }

        if (this.serverURL) {
            this.init = function () {
                var response, ex;
                if (USE_AJAX) {
                    for (var testIdx = 0; 10 > testIdx; testIdx++) {
                        var regExp = /:808./;
                        try {
                            this.serverURL = this.serverURL.replace(regExp, ":808" + testIdx);
                            response = _sendRequest(this, _makeRequest(this, "api", []));
                            break;
                        }
                        catch (e) {
                            ex = e;
                            if (e.code != adbException.GENERAL_ERROR) {
                                break;
                            }
                        }
                    }
                }
                else {
                    try {
                        response = _sendRequest(this, _makeRequest(this, "api", []));
                    }
                    catch (e) {
                        ex = e;
                    }
                }

                if (response == undefined) {
                    throwJSONException(ex);
                    return;
                }

                this.vcid = response.vci;
                try {
                    eval(response.api + " \n//@ sourceURL=adb_js_api.js");
                }
                catch (e) {
                    var ex = new adbException(adbException.GENERAL_ERROR, "Unable to parse ADB JS API");
                    ex.parse_exception = e;
                    throwJSONException(ex);
                    return;
                }
                var self = this;

                if (USE_AJAX) {
                    var pollEvents = function () {
                        var _handleEvents = function (events, ex) {
                            if (!ex) {
                                for (var i = 0; events.length > i; ++i) {
                                    var ref = refs[events[i].obj];
                                    if (ref) {
                                        var params = events[i].params;
                                        params.target = ref.obj;
                                        _dispatchEvent(ref, params);
                                    }
                                }
                                setTimeout(pollEvents, 100);
                            }
                            else {
                                throwJSONException(ex);
                            }
                        }
                        getEvents(_handleEvents, self);
                    }
                    setTimeout(pollEvents, 100);
                }
                else {
                    var _handleEvents = function (string) {
                        var events;
                        var ex = null;

                        try {
                            events = self.handleResponse(string).result;
                        }
                        catch (e) {
                            ex = e;
                        }

                        if (!ex) {
                            for (var i = 0; events.length > i; ++i) {
                                var ref = refs[events[i].obj];
                                if (ref) {
                                    var params = events[i].params;
                                    params.target = ref.obj;
                                    _dispatchEvent(ref, params);
                                }
                            }
                        }
                        else {
                            throwJSONException(ex);
                        }
                    }
                    stb.JSONCallback = _handleEvents;
                }
            }
        }
        else {
            this.init = function () { };
        }
    };

    var JSONRPCObject = function (hash) {
        this._jso_hash = hash;
    };

    JSONRPCObject.prototype =
    {
        _jso_hash: null,
    };

    // EventTarget class
    var EventTarget = function (hash, internal) {
        JSONRPCObject.call(this, hash);
        if (internal) {
            this._et_internal = true;
        }
    };

    EventTarget.prototype =
    {
        _et_internal: false,
    };

    EventTarget.prototype = new JSONRPCObject(null);

    EventTarget.prototype.constructor = EventTarget;

    // ctx is optional
    EventTarget.prototype.addEventListener = function (type, listener, ctx) {
        jsonclient.addListener(this, type, listener, ctx);
    };

    // ctx is optional
    EventTarget.prototype.removeEventListener = function (type, listener, ctx) {
        jsonclient.removeListener(this, type, listener, ctx);
    };

    EventTarget.prototype.dispatchEvent = function (event, type) {
        jsonclient.dispatchEvent(this, event, type);
    };

    EventTarget.prototype.hasAnyListeners = function (type) {
        return jsonclient.hasAnyListeners(this, type);
    };

    EventTarget.prototype.removeEventListenersByEvent = function (type) {
        return jsonclient.removeEventListenersByEvent(this, type);
    };

    jsonclient.init();
};

module.exports = {
    init
};
