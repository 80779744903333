import { KEYS } from './keys';

export function buildStbKeysList(): { [key in KEYS]?: number } {
    return {
        PLAY_PAUSE: 135,
        FORWARD: 129,
        REWIND: 126,
        STOP: 19,
    };
}
