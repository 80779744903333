import { KEYS } from './keys';

export const PanasonicKeys = {
    [KEYS.BACK]: 461,
    [KEYS.REWIND]: 412,
    [KEYS.PAUSE]: 19,
    [KEYS.FORWARD]: 417,
    [KEYS.PLAY]: 415,
    [KEYS.STOP]: 413,
};
