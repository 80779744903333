export const INITIAL_LOGIN_STATUS_REFETCH_DELAY = 1000;
export const STATIC_LOGIN_STATUS_REFETCH_DELAY = 5000;
export const MAX_LOGIN_STATUS_REFETCH_DELAY = 60000;
export const INITIAL_REQUEST_DELAY = 10000;

export enum LOGIN_STATUS {
    SUCCESS = 'success',
    IN_PROGRESS = 'in-progress',
    IDLE = 'idle',
}

export function calculateLoginStatusRefetchDelay(prevDelay: number): number {
    return prevDelay < MAX_LOGIN_STATUS_REFETCH_DELAY ? prevDelay * 2 : prevDelay;
}

export function isAvailableToRefetchLoginStatus(loginStatus?: LOGIN_STATUS): boolean {
    return loginStatus === LOGIN_STATUS.IDLE;
}

export function statusToLoginStatus(status?: number): LOGIN_STATUS | undefined {
    switch (status) {
        case 0:
            return LOGIN_STATUS.SUCCESS;

        case 1:
            return LOGIN_STATUS.IN_PROGRESS;

        case -1:
        default:
            return LOGIN_STATUS.IDLE;
    }
}

export function isLoginStatusSuccess(loginStatus?: LOGIN_STATUS): boolean {
    return loginStatus === LOGIN_STATUS.SUCCESS;
}
