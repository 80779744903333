import { useGetMediaApi, useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetRulesStatusOut } from '@/interfaces/from-schemas/auth/getRulesStatusOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import defaultKeyHook from '../../hooks/default-key';

interface IUseGetRulesStatus {
    rulesIds: number[];
}

const useGetRulesStatus = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.FETCH_RULES_STATUS,
        null,
    );

    return useGetMediaApiMutation<IUseGetRulesStatus, GetRulesStatusOut>(keyHook, {
        appendAuthData: true,
    });
};

export const useGetRulesStatusQuery = ({
    rulesIds,
}: Partial<IUseGetRulesStatus>): IApiResponse<GetRulesStatusOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.FETCH_RULES_STATUS,
        rulesIds && { rulesIds },
    );

    return useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
};

export default useGetRulesStatus;
