import { IGutterProps } from '@/types/gutter.type';

export enum MAINTEXT_RESPONSIVE_KIND {
    ACCOUNT_OPTION_DESCRIPTION = 'account-option-description',
    ACCOUNT_OPTION_TITLE = 'account-option-title',
    COLLECTION_TITLE = 'collection-title',
    DESCRIPTION = 'description',
    FULL_DETAILS_METADATA_TITLE = 'full-details-metadata-title',
    LIST_ELEMENT_METADATA = 'list-element-metadata',
    LIST_ELEMENT_TITLE = 'list-element-title',
    METADATA_SEASON_EPISODE_TITLE = 'full-details-metadata-title',
    METADATA_TITLE = 'metadata-title',
    PAYMENT_TITLE = 'payment-title',
    PRODUCT_NAME = 'product-name',
    PRODUCT_TYPE = 'product-type',
    PROMOBOX_DESCRIPTION = 'promobox-description',
    PROMOBOX_TITLE = 'promobox-title',
    SEARCH_SUBTITLE = 'search-subtitle',
    CHECK_CODE_SUBTITLE = 'check-code-subtitle',
    TITLE = 'title',
}

export enum MAINTEXT_KIND {
    COPYRIGHT = 'copyright',
    DESCRIPTION = 'description',
    GDPR = 'gdpr',
    GDPR_MAGIC_LINK = 'gdpr_magic_link',
    HEADER = 'HEADER',
    LIST_ELEMENT = 'list_element',
    METADATA_SEASON_EPISODE_TITLE = 'full-details-metadata-title',
    PAGE_DESCRIPTION = 'page_description',
    PAGE_SUBTITLE = 'page_subtitle',
    PAGE_TITLE = 'page_title',
    PAGE_CUSTOMER_CARE_TITLE = 'page_customer_care_title',
    PAYMENT_STEP_TITLE = 'payment-step-title',
    PRODUCT_NAME = 'product_name',
    PRODUCT_TYPE = 'product_type',
    SUBTITLE = 'subtitle',
    TITLE = 'title',
}

export interface MainTextProps extends IGutterProps {
    text?: string;
    mode?: MAINTEXT_KIND | MAINTEXT_RESPONSIVE_KIND;
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    lines?: number;
    fullText?: boolean;
    responsive?: boolean;
    children?: React.ReactNode;
    renderChildrenAfterText?: boolean;
    className?: string;
    centered?: boolean;
}

export interface StyledMainTextProps {
    $mode: MAINTEXT_KIND | MAINTEXT_RESPONSIVE_KIND;
    responsive?: boolean;
    centered?: boolean;
}

export interface StyledTruncatedTextProps {
    lines: number;
}

export interface TruncatedTextProps {
    text: string;
    lines: number;
}
