import { useClearBackgroundContext } from '@/hooks/use-clear-background-context.hook';
import { useClearContext } from '@/hooks/use-clear-context.hook';
import { useSmartHistory } from '@/hooks/use-smart-history.hook';

export const ClearContextManager = () => {
    const clearContext = useClearContext();
    useSmartHistory(clearContext);

    useClearBackgroundContext();

    return null;
};
