import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ROUTES } from '@/routing/types';
import { PageContainer } from '@/components/views/Account/styles';
import { navVertical } from 'nav-tree';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import React, { useState } from 'react';
import EnterCodeForm from '@/components/views/Account/Code/EnterCodeForm';
import ActivateCode from '@/components/views/Account/Code/activate-code';
import { CheckCode } from '@/components/views/Account/Code/check-code';

enum InsertCodeState {
    EnterCode,
    CheckCode,
    ActivateCode,
}

const InsertCode = () => {
    const [purchaseCode, setPurchaseCode] = useState<string>();
    const [pageState, setPageState] = useState<InsertCodeState>(InsertCodeState.EnterCode);

    const openEnterCodePage = () => {
        setPageState(InsertCodeState.EnterCode);
    };

    const checkCode = (purchaseCode: string) => {
        setPurchaseCode(purchaseCode);
        setPageState(InsertCodeState.CheckCode);
    };

    const activateCode = (purchaseCode: string) => {
        setPurchaseCode(purchaseCode);
        setPageState(InsertCodeState.ActivateCode);
    };

    const enterCodePage = () => (
        <PageContainer func={navVertical}>
            <MainText text={t('pages.have-code.title')} mode={MAINTEXT_KIND.PAGE_TITLE} />
            <Message
                description={[
                    t('pages.have-code.description-1'),
                    t('pages.have-code.description-2'),
                ]}
            />
            <EnterCodeForm
                defaultCode={purchaseCode}
                handleCheckCode={checkCode}
                handleActivateCode={activateCode}
            />
        </PageContainer>
    );

    const renderPage = () => {
        switch (pageState) {
            case InsertCodeState.CheckCode:
                return (
                    <CheckCode
                        activateCode={activateCode}
                        goBack={openEnterCodePage}
                        purchaseCode={purchaseCode ?? ''}
                    />
                );
            case InsertCodeState.EnterCode:
                return enterCodePage();
            case InsertCodeState.ActivateCode:
                return (
                    <ActivateCode purchaseCode={purchaseCode ?? ''} goBack={openEnterCodePage} />
                );
        }
    };

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.HAVE_CODE} />
            {renderPage()}
        </BaseLayout>
    );
};

export default InsertCode;
