import BaseLayout from '@/components/shared/layouts/BaseLayout';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import { PlayableButton } from '@/components/shared/Buttons';
import React from 'react';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { IconGroup } from '@/components/shared/Metadata';
import { GUTTER } from '@/types/gutter.type';

const NotFound = () => {
    const body = (
        <>
            <IconGroup
                iconSize={5.6}
                iconWidth={12.4}
                icon={ICONS.ERROR404}
                gutter={GUTTER.NORMAL}
                targetColor
            />
            <Message title={t('pages.errors.page-not-found')} gutter={GUTTER.BIG} />
            <FormContainer>
                <PlayableButton
                    text={t('pages.errors.go-to-homepage')}
                    onButtonAction={() => Router.pushRoute(ROUTES.HOME)}
                    defaultFocused
                />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default NotFound;
