import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const AgeRestrictionGridRootContainer = styled(Nav)<{ width: number }>`
    width: ${(props) => `${props.width}rem`};
    display: flex;
    flex-flow: row wrap;
    align-content: baseline;
    padding-bottom: 5rem;
    margin: auto;
`;

export const AgeRestrictionGridElementWrapper = styled(Nav)<{ width: number }>`
    width: ${(props) => `${props.width}%`};
`;
