import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IGetOrderForPurchaseCode } from '@/api/payments/types';
import { IApiResponse, IUseGetMediaApiOptions } from '@/hooks/use-get-media-api.type';
import { GetOrderIdOut } from '@/interfaces/from-schemas/payments/getOrderIdOut';

const useGetOrderId = (
    params?: IGetOrderForPurchaseCode,
    disableCache = true,
): IApiResponse<GetOrderIdOut> => {
    const keyhook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_ORDER_ID,
        params,
    );
    const options: IUseGetMediaApiOptions = { disableCache };

    return useGetMediaApi(keyhook, options);
};

export default useGetOrderId;
