import { Method } from 'axios';

export const appConfig: IAppConfig = {
    // format treści zapytania
    requestContentType: 'application/json',
    // ilość ponowień nieudanych zapytań
    requestMaxRetry: 3,
    // metoda zapytań
    requestMethod: 'POST',
    // zapytania o poniższych statusach nie będą ponawiane
    requestRetryExcludedStatusCode: [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511],
    // [ms] maksymalny czas oczekiwania na zapytanie
    requestTimeout: 15000,
    // [ms] opóźnienie pomiędzy ponawianiami zapytań
    requestRetryDelay: 1500,
    // Yag staging - subdomena usługi obsługującej statystyki
    yagStaging: 'yag-staging.redefine.pl',
};

interface IAppConfig {
    requestContentType: string;
    requestMaxRetry: number;
    requestMethod: Method;
    requestRetryDelay: number;
    requestRetryExcludedStatusCode: number[];
    requestTimeout: number;
    yagStaging: string;
}
