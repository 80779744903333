import { Nav } from 'nav-tree';
import styled from 'styled-components';

const OFFER_OFFSET = 1;

export const OfferContainer = styled(Nav)`
    margin: 0 -${OFFER_OFFSET}rem;
    overflow: hidden;
    padding-top: 9rem;
    padding: 9rem ${OFFER_OFFSET}rem 0;
`;

export const ButtonsGroup = styled.div`
    margin-bottom: 9rem;
`;

export const ButtonContainer = styled(Nav)`
    width: 40rem;
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
