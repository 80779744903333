export enum KEYS {
    KEY_0 = 'KEY_0',
    KEY_1 = 'KEY_1',
    KEY_2 = 'KEY_2',
    KEY_3 = 'KEY_3',
    KEY_4 = 'KEY_4',
    KEY_5 = 'KEY_5',
    KEY_6 = 'KEY_6',
    KEY_7 = 'KEY_7',
    KEY_8 = 'KEY_8',
    KEY_9 = 'KEY_9',

    UP = 'UP',
    RIGHT = 'RIGHT',
    DOWN = 'DOWN',
    LEFT = 'LEFT',
    ENTER = 'ENTER',
    BACK = 'BACK',
    DONE = 'DONE',
    CANCEL = 'CANCEL',
    BACKSPACE = 'BACKSPACE',
    SPACE = 'SPACE',

    F = 'F',
    M = 'M',
    S = 'S',

    RED = 'RED',
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    BLUE = 'BLUE',

    REWIND = 'REWIND',
    STOP = 'STOP',
    PAUSE = 'PAUSE',
    PLAY = 'PLAY',
    PLAY_PAUSE = 'PLAY_PAUSE',
    FORWARD = 'FORWARD',

    VOLUME_UP = 'VOLUME_UP',
    VOLUME_DOWN = 'VOLUME_DOWN',
    VOLUME_MUTE = 'VOLUME_MUTE',

    CURSOR_SHOW = 'CURSOR_SHOW',
    CURSOR_HIDE = 'CURSOR_HIDE',

    UNHANDLED = 'UNHANDLED',
}
