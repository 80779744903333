import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';

type TableDictionaryType = {
    [key: string]: { startIndex: number; endIndex: number };
};

const TableMemoizedDataContext = createContext<{
    dictionary: TableDictionaryType;
    setDictionary: Dispatch<SetStateAction<TableDictionaryType>>;
}>({
    dictionary: { 1: { startIndex: 0, endIndex: 0 } },
    setDictionary: () => null,
});

export const TableMemoizedDataContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [dictionary, setDictionary] = useState<TableDictionaryType>({});

    return (
        <TableMemoizedDataContext.Provider value={{ dictionary, setDictionary }}>
            {children}
        </TableMemoizedDataContext.Provider>
    );
};

export function useTableMemoizedDataContext() {
    const context = useContext(TableMemoizedDataContext);
    if (context === undefined) {
        throw new Error(
            'useTableMemoizedDataContext must be used within a TableMemoizedDataContextProvider',
        );
    }
    return context;
}
