import styled, { css, keyframes } from 'styled-components';
import { ImgElementProps, RadialGradientProps } from './types';
import { PlaceholderStyledProps } from '@/components/browser/shared/CollectionsList/types';

export const ImageStyled = styled.img<ImgElementProps>`
    overflow: hidden;
    max-width: 100%;
    ${({ $width, $height, responsive }) =>
        responsive
            ? css`
                  height: 100%;
                  width: auto;
              `
            : css`
                  width: ${$width || 'auto'};
                  height: ${$height || 'auto'};
                  display: block;
              `}
`;

export const PlaceholderStyled = styled.div<PlaceholderStyledProps>`
    opacity: ${({ isBot }) => (isBot ? '0' : '1')};
    position: relative;
    background-color: ${({ theme }) => theme.placeholder.backgroundColor};
    width: 100%;
    overflow: hidden;
    padding-top: ${({ imageDisplayMode, theme }) =>
        theme.listElement.imageHeight[imageDisplayMode]};
`;

export const TriangleStyled = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 3rem solid transparent;
    border-left: ${({ theme }) => `6rem solid ${theme.placeholder.arrowColor}`};
    border-bottom: 3rem solid transparent;
`;

const pulse = keyframes`
    from {
        width: 50%;
        height: 50%;
    }
    to {
        width: 150%;
        height: 150%;
    }
`;

const buildAnimation = () => css`
    animation: ${pulse} 0.75s linear infinite alternate;
`;

export const RadialGradient = styled.div<RadialGradientProps>`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: radial-gradient(circle at center, #16181a 0%, #0d0e0f 56.25%);
    ${({ withAnimation }) => withAnimation && buildAnimation()};
`;
