import useGetProfile from '@/api/auth/use-get-profile';
import useUpdateProfile from '@/api/auth/use-update-profile';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { ProfileNameForm } from '@/components/views/Profile/ProfileNameForm';
import { ProfileNameFormData } from '@/components/views/Profile/ProfileNameForm/types';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';

const ChangeProfileName = () => {
    const { profileId } = useProfilePageInitialProps();
    const [sessionProfile, setSessionProfile] = useSessionProfile();
    const { data: profile, error: profileError } = useGetProfile({ id: profileId });
    const [updateProfile, { error }] = useUpdateProfile();

    const errorToDisplay = profileError?.getUserMessage() || error?.getUserMessage();

    const goToProfile = () => Router.back();

    const onFormSubmit = async (data: ProfileNameFormData) => {
        if (profile) {
            const result = await updateProfile({
                name: data.name,
                avatarId: profile.avatarId,
                profileId,
            });

            if (result.ok) {
                if (sessionProfile && sessionProfile.profileId === profileId) {
                    setSessionProfile({ ...sessionProfile, name: data.name });
                }
                return goToProfile();
            }
        }
    };

    const body = (
        <>
            <MainText
                text={t('pages.profile.change-name.header')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />

            <ProfileNameForm onFormSubmit={onFormSubmit} onCancel={goToProfile} />

            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default ChangeProfileName;
