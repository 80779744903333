import { useMemo } from 'react';
import useGetChannelsCurrentProgram from '@/api/navigation/channels/use-get-channel-list-current-program';
import { getProgramParams } from '@/helpers/get-program-params.helper';
import { IUseGetChannelsFromPacketCollection } from './types';
import { buildElementsList } from '@/helpers/build-elements-list.helper';

export const useGetChannelsFromPacketContent = ({
    activeCategory,
    packetContent,
}: IUseGetChannelsFromPacketCollection) => {
    const currentProgramParams = useMemo(() => {
        if (packetContent && activeCategory === 'tv') {
            return getProgramParams(packetContent);
        }

        return false;
    }, [packetContent, activeCategory]);

    const {
        data: fetchedProgram,
        isFetching: isFetchingProgram,
        error,
    } = useGetChannelsCurrentProgram(currentProgramParams);

    const channelList = useMemo(() => {
        const results = fetchedProgram.getResults();

        return results.length
            ? buildElementsList(packetContent, results, { showMetadata: true })
            : [];
    }, [packetContent, fetchedProgram]);

    return { channelList, isFetchingProgram, error };
};
