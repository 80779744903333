import { MediaDetailsModel } from '@/models/media/media-details.model';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { CheckProductAccessModel } from '@/models/payments/check-product-access.model';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import t from '@/lib/i18n';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import { formatDate } from '@/helpers/date.helper';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { BUTTON_ID } from '@/types/button-id.type';
import { ICONS } from '@/components/shared/Icon';
import { PrePurchaseDataModel } from '@/models/payments/pre-purchase-data.model';
import slug from 'slug';
import { Router } from '@/routing';

export class TvProgramItemDetailsModel extends MediaDetailsModel {
    public isInFavorites = false;
    public isChronological = false;
    public isTvProgramItem = true;

    constructor(
        mediaListItemModel: MediaListItemModel,
        private tvProgramItemModel: ChannelProgramTvModel,
        checkProductAccessModel?: CheckProductAccessModel,
        prePurchaseDataModel?: PrePurchaseDataModel,
    ) {
        super(mediaListItemModel, checkProductAccessModel, prePurchaseDataModel);

        if (checkProductAccessModel) {
            this.accessType = this.checkAccessType();
        }
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        return (
            this.tvProgramItemModel?.getBackgroundSrc() ??
            this.mediaListItemModel.getBackgroundSrc(imgWidth, imgHeight)
        );
    }

    public get title(): string {
        return this.tvProgramItemModel?.getTitle() ?? this.mediaListItemModel.getTitle();
    }

    public get isLive(): boolean {
        return this.tvProgramItemModel?.getIsLive() || false;
    }

    public get description(): string {
        return this.tvProgramItemModel.getDescription() ?? this.mediaListItemModel.getDescription();
    }

    public get subTitleData(): string[] {
        const data: string[] = [];

        const { HOURS } = FORMAT_DATE_MODE;
        const channel = this.mediaListItemModel.getTitle();
        const genre = this.tvProgramItemModel.getGenre();
        const programStartTime = this.tvProgramItemModel.getStartTime(HOURS);
        const programEndTime = this.tvProgramItemModel.getEndTime(HOURS)
            ? this.tvProgramItemModel.getEndTime(HOURS)
            : this?.nextProgramTvModel?.getStartTime(HOURS)
            ? this.nextProgramTvModel.getStartTime(HOURS)
            : false;
        const isLive = this.tvProgramItemModel.getIsLive();
        const programTime = programEndTime
            ? `${programStartTime} - ${programEndTime}`
            : programStartTime;

        data.push(channel, genre, programTime);

        if (isLive) {
            data.push(
                t('models.media-details.live-highlighted', buildHighlightSpan('text-danger')),
            );
        }

        return data.filter((item) => item.length);
    }

    public getPublicationDate(format?: FORMAT_DATE_MODE): string {
        return format
            ? formatDate(this.tvProgramItemModel.getStartTime(), format)
            : this.tvProgramItemModel.getStartTime();
    }

    public buildPremiereBannerText(hours: number, minutes: number): string {
        return `${
            hours > 0 ? t(`components.tv-program-item.premiere-hours-left`, { hours }) : ''
        } ${
            minutes === 0
                ? '<1' + t(`components.tv-program-item.premiere-minutes-left`, { minutes: '' })
                : t(`components.tv-program-item.premiere-minutes-left`, { minutes })
        }`;
    }

    public get isStartLiveInfoVisible(): boolean {
        return new Date(this.tvProgramItemModel.getStartTime()).getTime() > new Date().getTime();
    }

    public get startLiveInfo(): string {
        const publicationDate = this.tvProgramItemModel.getStartTime();

        const date = formatDate(publicationDate, FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT);
        const time = formatDate(publicationDate, FORMAT_DATE_MODE.HOURS);

        return `${date}, ${time}`;
    }
    public goToFullDetails(): void {
        const route = this.mediaListItemModel.getFullDetailsRoute(true);
        const params = {
            tvProgramTitle: slug(this.tvProgramItemModel.getTitle()),
            tvProgramId: this.tvProgramItemModel.getId(),
        };
        Router.pushRoute(route, params);
    }

    protected get playButton(): MediaButtonInterface {
        return {
            id: BUTTON_ID.PLAY,
            icon: ICONS.PLAY,
            text: t('models.media-details.watch-tv-program', {
                channel: this.mediaListItemModel.getTitle(),
            }),
            onButtonAction: () => this.goToWatch(),
            'data-testing': 'models.media-details.watch',
            isPlayButton: true,
        };
    }

    public get playOrBuyButton(): MediaButtonInterface | undefined {
        if (this.hasAccess()) {
            if (this.isStartLiveInfoVisible) {
                return undefined;
            }

            return this.playButton;
        }
        if (this.isPurchasable) {
            return this.buyButton;
        }

        return undefined;
    }
}
