import {
    KeyboardKey,
    KeyboardLayout,
    KeyPosition,
    SpecialKeysDefinition,
} from '@/components/shared/VirtualKeyboard/types';
import { InputManager } from '@/input';
import { useCallback, useEffect } from 'react';

export const useKeyboardNavigationHook = (
    keyboardLayout: KeyboardLayout,
    hoveredKey: KeyboardKey,
    hoveredPosition: KeyPosition,
    previousPosition: KeyPosition,
    hover: (key: KeyboardKey, position?: KeyPosition) => void,
    enterKeyHandler: () => void,
    backKeyHandler: () => void,
) => {
    const keydownHandler = useCallback(
        (event: KeyboardEvent) => {
            event.preventDefault();

            const { DOWN, UP, LEFT, RIGHT, ENTER, BACK, BACKSPACE } = InputManager.KEY;
            const [row, column] = hoveredPosition;

            let columnModifier = 0;
            let rowModifier = 0;

            const isOKHovered = hoveredKey === SpecialKeysDefinition.SUBMIT;

            switch (event.keyCode) {
                case DOWN:
                    if (!isOKHovered) {
                        rowModifier = 1;
                    }
                    break;

                case UP:
                    if (!isOKHovered) {
                        rowModifier = -1;
                    }
                    break;

                case LEFT:
                    columnModifier = -1;
                    break;

                case RIGHT:
                    if (!isOKHovered) {
                        columnModifier = 1;
                    }
                    break;

                case ENTER:
                    return enterKeyHandler();

                case BACK:
                case BACKSPACE:
                    return backKeyHandler();
            }

            if (rowModifier || columnModifier) {
                const newKeyProperties = getNewKeyProperties(
                    row + rowModifier,
                    column + (isOKHovered ? 0 : columnModifier),
                    keyboardLayout,
                    [row, column],
                    previousPosition,
                );

                if (!newKeyProperties) {
                    return false;
                }

                const [newHoveredKey, newPosition] = newKeyProperties;

                if (newHoveredKey !== hoveredKey) {
                    hover(newHoveredKey, newPosition);
                } else if (event.keyCode === RIGHT) {
                    hover(SpecialKeysDefinition.SUBMIT);
                }
            }
        },
        [hoveredPosition, keyboardLayout, hoveredKey, enterKeyHandler],
    );

    useEffect(() => {
        window.addEventListener('keydown', keydownHandler);

        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [keydownHandler]);
};

const getNewKeyProperties = (
    row: number,
    column: number,
    layout: KeyboardLayout,
    currentPosition: KeyPosition,
    previousPosition: KeyPosition,
): [KeyboardKey, KeyPosition] | null => {
    const selectedRow = layout[row];

    if (!selectedRow) {
        return null;
    }

    const spaceSize = 7;
    const rowLastIndex = selectedRow.length - 1;

    if (column < 0) {
        column = 0;
    } else if (column > rowLastIndex) {
        column = rowLastIndex;
    }

    const [currentRow, currentCol] = currentPosition;
    const previousRow = previousPosition[0];

    const spaceIndex = selectedRow.indexOf('{space}');
    const spaceInCurrentRow = layout[currentRow].indexOf('{space}');

    if (spaceIndex !== -1) {
        if (currentCol > spaceIndex) {
            if (currentCol < spaceIndex + spaceSize) {
                if (row !== currentRow) {
                    column = spaceIndex;
                }
            } else {
                if (currentCol > spaceIndex) {
                    column = currentCol - spaceSize + 1;
                }
            }
        }
    }

    if (spaceInCurrentRow !== -1) {
        if (currentRow !== previousRow) {
            if (row !== currentRow) {
                column = previousPosition[1];
            }
        } else {
            if (row !== currentRow && column > spaceInCurrentRow) {
                column = column + spaceSize - 1;
            }
        }
    }

    const key = selectedRow[column];

    return [key, [row, column]];
};
