import styled from 'styled-components';
import { Nav } from 'nav-tree';
import { MediaRelatedTileContainerProps } from '@/components/shared/MediaRelatedList/types';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';

export const MediaRelatedListWrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100%;
`;

export const MediaRelatedSlider = styled.div`
    display: flex;
    flex-direction: column;
`;

export const MediaRelatedNav = styled(Nav)`
    display: flex;
    height: 100%;
    align-items: center;
`;

export const MediaRelatedSliderTitle = styled.div`
    font-size: 4rem;
    padding-left: 7.7rem;
`;

export const MediaRelatedTileStyled = styled(Nav)`
    flex-shrink: 0;
    padding: 2.5rem 1rem;

    & > div:first-child {
        border-width: 0.5rem;
        border-style: solid;
        border-color: transparent;
        border-radius: 0;
        padding: 0.5rem;
        font-size: 2.6rem;
        overflow: hidden;
        background-size: cover;
        position: relative;
    }

    &.nav-focused > div:first-child {
        border: ${({ theme }) => theme.listElement.activeBorder};
        border-radius: 0.5rem;
        transform: scale(1.2);
    }

    &:first-child {
        padding-left: 7.7rem;
    }

    &:last-child {
        padding-right: 7.7rem;
    }
`;

export const MediaRelatedTileContainer = styled.div<MediaRelatedTileContainerProps>`
    width: ${(props) => convertPxToRem(props.width)};
    height: ${(props) => convertPxToRem(props.height)};
`;

export const MediaRelatedTileTitle = styled.div`
    margin-bottom: 1.8rem;
    color: ${({ theme }) => theme.text.color.primary};
`;

export const MediaRelatedTileImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
`;
