/*
Helper powstał jako zamiennik metody Object.fromEntries
do wyciągnięcia params, które służą na dekoderach do
przekierowań z deep linków.
Wymagane było stworzenie zamiennika, ponieważ dla
smartów występował w środowisku deweloperskim błąd
spowodowany wersją node'a, która nie miała metody
Object.fromEntries
 */

export const deepLinkParams = (parsed: URLSearchParams) => {
    const object: { [k: string]: string } = {};

    for (const pair of parsed.entries()) {
        const key = pair[0];
        object[key] = pair[1];
    }

    return object;
};
