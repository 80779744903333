import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';

export const useGetClientContextToken = (enabled = true) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.SYSTEM,
        GET_MEDIA_METHODS.GET_CLIENT_CONTEXT_TOKEN,
        enabled ? {} : null,
    );

    return useGetMediaApi(keyHook, {
        appendAuthData: true,
        disableCache: true,
        queryOptions: {
            cacheTime: 0,
        },
    });
};
