import config from 'target/config';
import { Z_INDEX } from '@/targets/default/theme';
import { ONE_TRUST_MODE } from '@/types/onetrust.type';
import { recognizeOS } from '@/services/platform-recognition/recognize-os';

const NAV_DISABLE = 'navdisable';
const NAV_ENABLE = 'navenable';
const disableNav = () => {
    window.dispatchEvent(new CustomEvent(NAV_DISABLE));
};
const enableNav = () => {
    window.dispatchEvent(new CustomEvent(NAV_ENABLE));
};

const ONE_TRUST_NODE_ID = 'privacy-root';
const STORE_KEY = 'ONETRUST_CTV_KEYS';

const getOneTrustConfig = (os = '') => {
    const oneTrustKeys = window.localStorage.getItem(STORE_KEY);
    if (!oneTrustKeys) return config.onetrust[os];
    return JSON.parse(oneTrustKeys);
};

const getOneTrustNode = () => {
    const found = document.getElementById(ONE_TRUST_NODE_ID);
    if (found) {
        return found;
    }
    const newNode = document.createElement('div');
    newNode.id = ONE_TRUST_NODE_ID;
    newNode.style.cssText = `z-index: ${Z_INDEX.MODAL} !important;overflow: auto`;
    document.body.appendChild(newNode);
    return document.getElementById(ONE_TRUST_NODE_ID);
};

const removeOneTrustNode = () => {
    const found = document.getElementById(ONE_TRUST_NODE_ID);
    if (!found) {
        return;
    }
    for (const child of found.children) {
        found.removeChild(child);
    }
};

export const showOneTrust = (type = ONE_TRUST_MODE.BANNER, isReConsent = null) => {
    try {
        const oneTrustAPI = window?.oneTrustTV;
        const os = recognizeOS(window.navigator.userAgent);
        const config = getOneTrustConfig(os);
        if (typeof config === 'undefined' || !oneTrustAPI) return;

        oneTrustAPI.Settings(config);
        const oneTrustNode = getOneTrustNode();

        disableNav();
        if (isReConsent) oneTrustAPI.clear();
        oneTrustAPI.setupUI(oneTrustNode, type, isReConsent, () => {
            removeOneTrustNode();
            enableNav();
        });
    } catch (e) {
        console.log(e);
    }
};
