import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useAppMainRoutes } from './menu/use-app-main-routes.hook';

export const useSmartHistory = (effect: (route: ROUTES) => void, timeout = 0) => {
    const history = useHistory();
    const router = useRouter();
    const route = router.route as ROUTES;
    const { prevRoute } = router;

    const appMainRoutes = useAppMainRoutes();

    const filteredAppRoutes = appMainRoutes
        .map((r) => r?.name)
        .filter((r) => r !== ROUTES.PACKET_DETAILS);

    useEffect(() => {
        if (history.action !== 'POP') {
            effect(route);
        }
        if (filteredAppRoutes.includes(prevRoute)) {
            effect(route);
        }
    }, [router, history]);
};
