import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { CreateProfileOut } from '@/interfaces/from-schemas/auth/createProfileOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseCreateProfile {
    name: string;
    avatarId: string;
}

const useCreateProfile = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.CREATE_PROFILE,
        null,
    );

    return useGetMediaApiMutation<IUseCreateProfile, CreateProfileOut>(keyHook, {
        appendAuthData: true,
        invalidate: [[GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.GET_PROFILES]],
    });
};

export default useCreateProfile;
