import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { HelpContainer } from '@/components/views/Account/Help';
import { ROUTES } from '@/routing/types';
import { PageContainerRaw } from '@/components/views/Account/styles';

const Help = () => {
    return (
        <BaseLayout defaultBg topShade={false} bottomShade={false}>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT_HELP} />
            <PageContainerRaw>
                <HelpContainer />
            </PageContainerRaw>
        </BaseLayout>
    );
};

export default Help;
