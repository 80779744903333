import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import React from 'react';

const ConfigurationBody = () => (
    <>
        <Message
            icon={ICONS.INFO_CIRCLE}
            title={t('pages.register.configuration-title')}
            description={t('pages.register.configuration-description')}
        />
        <FormContainer>
            <PlayableButton
                text={t('ok-button-label')}
                onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_RULES)}
                defaultFocused
            />
        </FormContainer>
    </>
);

const RegistrationConfiguration = () => {
    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={<ConfigurationBody />} />
        </BaseLayout>
    );
};

export default RegistrationConfiguration;
