import { ChangeProfileContainer } from '@/components/views/Profile/ChangeProfile';
import { useWatchAs } from '@/hooks/use-watch-as.hook';

export const ChangeProfile = () => {
    const { profiles, selectProfileById } = useWatchAs();

    return (
        <ChangeProfileContainer
            profiles={profiles}
            selectProfileById={selectProfileById}
            isAccountPage
        />
    );
};
