import { restoreFocusHorizontal } from '@/helpers/nav-tree/helpers';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { DATA_POSITION } from '@/types/list-element-metadata.type';
import React, { useContext, useMemo } from 'react';
import { ListElement } from '../ListElement';
import { ElementsContainer } from './styles';
import { GridContext, HorizontalRowProps } from './types';
import { useListElementClicked } from '@/hooks/use-list-element-clicked';

const HorizontalRowRaw = ({
    dataList,
    rowIndex,
    onActiveElement,
    cols,
    defaultFocusedList,
    isChronological,
}: HorizontalRowProps) => {
    const { height } = process.env.grid;
    const imageDisplayMode = dataList[0].getImage().displayMode;
    const dataPosition = dataList[0]?.getDataPosition();
    const { loadRow, activeIndex, rowIndex: rowIndexCtx, colIndex } = useContext(GridContext);
    const { onListElementClickedHandler } = useListElementClicked();

    const rowElements = useMemo(() => {
        return dataList.map((itemData: ListElementInterface, index: number) => {
            const listElementIndex = rowIndex * cols + index;
            const defaultFocusedElement = defaultFocusedList && listElementIndex === activeIndex;

            return (
                <ListElement
                    key={index}
                    defaultFocused={defaultFocusedElement}
                    itemData={itemData}
                    setActiveElement={onActiveElement}
                    focusedRow={rowIndexCtx === rowIndex}
                    index={listElementIndex}
                    onItemClickedHandler={onListElementClickedHandler}
                    isChronological={isChronological}
                />
            );
        });
    }, [dataList, activeIndex, defaultFocusedList, rowIndexCtx, cols, isChronological]);

    const mode = dataPosition === DATA_POSITION.UNDER ? 'withMeta' : 'simple';
    const rowHeight = height?.[imageDisplayMode][mode];

    return useMemo(
        () => (
            <ElementsContainer
                func={(navKeys, navTree) =>
                    restoreFocusHorizontal(navKeys, navTree, `${colIndex + 1}`)
                }
                height={rowHeight}
            >
                {loadRow ? rowElements : null}
            </ElementsContainer>
        ),
        [loadRow, colIndex, rowElements],
    );
};

export const HorizontalRow = React.memo(HorizontalRowRaw);
