import styled from 'styled-components';

export const StyledDot = styled.div`
    display: inline-block;
`;

export const StyledDate = styled.div`
    color: ${({ theme }) => theme.text.color.secondary};
    display: inline-block;
`;
