import { SwitchCheckbox } from '@/components/shared/SwitchCheckbox';
import React, { useState } from 'react';
import { Label, SingleRuleContainer } from './styles';
import { SingleRuleProps } from './types';

export const SingleRule = ({
    id,
    label,
    checked,
    error,
    highlight,
    defaultFocused,
    onToggleRule,
}: SingleRuleProps) => {
    const [active, setActive] = useState(false);

    return (
        <SingleRuleContainer
            onNav={setActive}
            $active={active}
            $error={error}
            onEnterDown={() => onToggleRule(id)}
            onMouseDown={() => onToggleRule(id)}
            autofocusOn={['mouseenter', 'focus']}
            defaultFocused={defaultFocused}
        >
            <Label highlight={highlight}>{label}</Label>
            <SwitchCheckbox id={`payment-rule-${id}`} checked={checked} />
        </SingleRuleContainer>
    );
};
