import { SubcategoryModel } from './subcategory.model';
import {
    GetSubCategoriesOut,
    CategorySchema,
} from '@/interfaces/from-schemas/navigation/getSubCategoriesOut';
import t from '@/lib/i18n';
import slug from 'slug';

class SubcategoriesModel {
    private readonly results: SubcategoryModel[] = [];

    constructor(definition: GetSubCategoriesOut) {
        if (definition) {
            this.results = [];
            definition.forEach((result: CategorySchema) => {
                this.results.push(new SubcategoryModel(result));
            });
        }
    }

    public getResults(): SubcategoryModel[] {
        return this.results;
    }

    public getSubcategoryName(id: string | undefined, lowercase = true): string | undefined {
        const title = this.getResults()
            .find((subcategory) => String(subcategory.getId()) === id)
            ?.getTitle();

        return lowercase ? title?.toLowerCase() : title;
    }

    public getCategoryName(categoryName: string): SubcategoryModel | undefined {
        return this.getResults().find((result) => {
            const titleSlug = slug(result.getTitle());
            const subcategoryModel = t(`subcategory-model.${titleSlug}`);

            return subcategoryModel === categoryName.toLowerCase();
        });
    }
}

export default SubcategoriesModel;
