import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { Message } from '@/components/shared//Message';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared//Buttons';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { navHorizontal, navVertical } from 'nav-tree';
import { GUTTER } from '@/types/gutter.type';
import { clearLocalStorage } from '@/helpers/clear-local-storage.helper';
import React from 'react';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useAppLogout } from '@/hooks/user/use-app-logout.hook';

const RestoreSettings = () => {
    const userModule = useUserModule();

    const { logout } = useAppLogout(ROUTES.WELCOME, () => {
        clearLocalStorage();
        userModule.clearRecognition();
    });

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT_RESTORE_SETTINGS} />
            <PageContainer func={navVertical}>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('pages.account.restore-settings.header')}
                    description={t('pages.account.restore-settings.description')}
                    gutter={GUTTER.NONE}
                />
                <NavWrapper func={navHorizontal}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={logout}
                        defaultFocused
                        data-testing="ok-button-label"
                    />
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default RestoreSettings;
