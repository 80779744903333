import { css } from 'styled-components';
import { isStbPBG } from '@/constants/portal-recognition';

export const fillIcon = (color: string) => {
    if (!isStbPBG) {
        return css`
            fill: ${color};
            svg {
                fill: ${color};
                width: auto;
                max-width: 100%;
            }
        `;
    } else {
        return css`
            [class^='icon-'],
            [class*=' icon-'] {
                color: ${color};
            }
        `;
    }
};
