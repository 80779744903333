import { RefObject, useCallback } from 'react';
import { Nav } from 'nav-tree';
import useGetPacketTreeNavigation from '@/api/packets/use-get-packet-tree-navigation';
import { PacketDetailsModel } from '@/models/packets/packet-details.model';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { useBackAction } from '@/hooks/use-back-action';
import { handleNavFocus } from '@/helpers/nav-tree/focus-nav-id.helper';
import { BUTTON_ID } from '@/types/button-id.type';
import { useFocusButton } from '../media/use-focus-button.hook';

export const usePacketCategories = (packet: PacketDetailsModel, focusRef: RefObject<Nav>) => {
    const { rightPanelOpen, setRightPanelOpen } = useRightPanel();

    const packetId = packet.id;
    const packetTreeParams = { packetCode: packetId, query: '' };
    const { data } = useGetPacketTreeNavigation(packetTreeParams);

    useFocusButton(focusRef);

    const handler = useCallback(() => {
        handleNavFocus(BUTTON_ID.CATALOG_BTN, focusRef);
    }, [focusRef]);

    useBackAction(handler, rightPanelOpen);

    return {
        data,
        rightPanelOpen,
        setRightPanelOpen,
    };
};
