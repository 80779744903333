import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { findPatternByRouteName } from './router/find-pattern-by-route-name.helper';
import { ROUTES } from '@/routing/types';

export const isSessionExpired = (statusCode?: number) => {
    const { B2C_UNAUTHORIZED_ACCESS } = ERROR_CODES_GET_MEDIA;
    return statusCode === B2C_UNAUTHORIZED_ACCESS;
};

export const sessionExpiredHandler = (statusCode?: number) => {
    if (isSessionExpired(statusCode)) {
        const destination = findPatternByRouteName(ROUTES.LOGIN_NATIVE);

        return {
            redirect: {
                permanent: false,
                destination: `${destination}?sessionExpired=true`,
            },
        };
    }
};
