import { Nav, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import React, { useCallback, useEffect } from 'react';

import { PlayableButton } from '@/components/shared/Buttons';
import { usePlaybackOptions } from '@/hooks/player/use-playback-options';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { useSubtitlesOptions } from '@/hooks/player/use-subtitles-options';
import { InputManager } from '@/input';
import t from '@/lib/i18n';
import { useBlockBackAction } from '@/standalone/src/routing';
import { BUTTON_ID } from '@/types/button-id.type';

import { PlaybackOption } from './PlaybackOption';
import { OptionGroupHeader } from './styles';
import { ISettingsPanelProps } from './types';

const { BACK, BACKSPACE } = InputManager.KEY;

export const SettingsPanel = ({ onClose }: ISettingsPanelProps) => {
    const player = usePlayerInstance();
    const { hasVideoOptions, playbackOptions, hasTextOptions } = usePlaybackOptions();
    const { subtitlesOptions, updateSubtitleSize } = useSubtitlesOptions();
    const isOpenRef = React.useRef(true);
    useBlockBackAction(isOpenRef.current);

    const closeAction = useCallback(
        (buttonId: BUTTON_ID) => {
            isOpenRef.current = false;
            onClose(buttonId);
        },
        [onClose],
    );

    useEffect(() => {
        const keydownHandler = (evt: KeyboardEvent) => {
            if (evt.keyCode === BACK || evt.keyCode === BACKSPACE) {
                closeAction(BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN);
            }
        };

        window.addEventListener('keydown', keydownHandler);

        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, [closeAction]);

    const setPlaybackOptions = useCallback(
        (options: IPlayer.PlaybackSelectedOptions) => {
            player.user.setPlaybackOptions(options);
        },
        [player],
    );

    const handleFunc = useCallback(
        (key: NAVIGATION_KEYS, navTree: NavTree) => {
            if (key === NAVIGATION_KEYS.LEFT) {
                closeAction(BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN);
            }

            return navVertical(key, navTree);
        },
        [closeAction],
    );

    return (
        <Nav func={handleFunc} style={{ position: 'relative' }}>
            <>
                {hasVideoOptions && (
                    <>
                        <OptionGroupHeader>
                            {t('player.playback-options.video-header')}
                        </OptionGroupHeader>
                        {playbackOptions?.video.map((opt, index) => (
                            <PlaybackOption
                                key={opt.label}
                                defaultFocused={index === 0}
                                active={!!opt.value.active}
                                label={opt.label}
                                onSelect={() =>
                                    setPlaybackOptions({
                                        video: opt.value,
                                    })
                                }
                            />
                        ))}
                    </>
                )}
                {hasTextOptions && (
                    <>
                        <OptionGroupHeader>
                            {t('player.playback-options.text-header')}
                        </OptionGroupHeader>
                        {subtitlesOptions?.size.map(({ label, value, active }, index) => (
                            <PlaybackOption
                                key={label}
                                defaultFocused={!hasVideoOptions && index === 0}
                                active={!!active}
                                label={label}
                                onSelect={() => updateSubtitleSize(value)}
                            />
                        ))}
                    </>
                )}
            </>
            <PlayableButton
                style={{ position: 'absolute', bottom: 0, width: '100%' }}
                text={'OK'}
                onButtonAction={() => closeAction(BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN)}
            />
        </Nav>
    );
};
