import { useEffect, useRef } from 'react';

type IntervalCallback = () => void;
type IntervalDelay = number | null;

export const useInterval = (callback: IntervalCallback, delay: IntervalDelay, enabled = true) => {
    const savedCallback = useRef<IntervalCallback>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!enabled) return;

        const tick = () => {
            savedCallback.current?.();
        };
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [delay, enabled]);
};
