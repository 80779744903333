import useGetOrderId from '@/api/payments/use-get-order-id';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Rules } from './Rules';
import React, { useEffect, useState } from 'react';
import { Checkout } from './Checkout';
import { CheckoutStepProps } from './types';
import { PlayableButton } from '@/components/shared/Buttons';
import {
    ArrowContainer,
    ButtonContainer,
    CenterContainer,
    CheckoutContainer,
    CheckoutContent,
    CheckoutFooter,
    PriceButtonContainer,
    PriceContainer,
    ScrollableContainer,
    Shade,
} from './styles';
import { GUTTER } from '@/types/gutter.type';
import {
    NavScrollDataInterface,
    navVertical,
    NAV_SCROLL_DIRECTION,
    NAV_SCROLL_MODE,
} from 'nav-tree';
import { RuleDataInterface } from '@/interfaces/rule-data.interface';
import { extractRules, isRuleNotValid } from '@/helpers/rules.helper';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { Icon, ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { useIsTrialAvailable } from '@/api/payments/use-is-trial-available';
import { Message } from '@/components/shared/Message';
import { usePaymentsData } from '@/hooks/payments/use-payments-data';
import { useSelectedPaymentCard } from '@/hooks/payments/use-selected-payment-card';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';

export const CheckoutStep = ({
    product,
    onBuy,
    onPaymentError,
    onSetStepsVisibility,
    onExitPaymentPath,
}: CheckoutStepProps) => {
    const {
        selectedOfferId,
        selectedOfferPricingPlanId,
        selectedOptionId,
        updatePaymentsData,
        clearPaymentsData,
    } = usePaymentsData();

    const [rulesData, setRulesData] = useState<RuleDataInterface[]>([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isDownArrowVisible, setDownArrowVisible] = useState(false);
    const [isTrialMessageVisible, setTrialMessageVisibility] = useState(false);
    const [priceLabel, setPriceLabel] = useState<string>();
    const [accessLabel, setAccessLabel] = useState<string>();
    const [accessDescription, setAccessDescription] = useState<string>();
    const [paymentMethodLabel, setPaymentMethodLabel] = useState<string>();
    const [trialDuration, setTrialDuration] = useState<string>();
    const [accessDuration, setAccessDuration] = useState<string>();

    const hasError = rulesData.some(isRuleNotValid);

    const selectedOffer = product.findOffer(selectedOfferId);
    const selectedOption = selectedOffer?.findOption(selectedOptionId);
    const selectedOfferPricingPlan = selectedOffer?.findOfferPricingPlan(
        selectedOfferPricingPlanId,
    );
    const selectedPricingPlan = selectedOfferPricingPlan?.getPricingPlan();
    const selectedPaymentCard = useSelectedPaymentCard();

    const buildIsTrialAvailableParams = () => {
        return (
            selectedOffer &&
            selectedOption &&
            selectedPaymentCard && {
                product: product.getProductId(),
                offer: selectedOffer.getOfferId(),
                option: selectedOption.getPaymentCardOptionType(selectedPaymentCard),
            }
        );
    };

    const buildOrderIdParams = () => {
        return (
            selectedOffer &&
            selectedOption && {
                product: product.getProductId(),
                offer: selectedOffer.getOfferId(),
                option: selectedOption.getOptionId(),
                offerPricingPlanId: selectedOfferPricingPlanId,
            }
        );
    };

    const { data: trialAvailableData } = useIsTrialAvailable(buildIsTrialAvailableParams() as any);
    const { data: orderIdData, error: orderIdError } = useGetOrderId(buildOrderIdParams());

    useEffect(() => {
        clearPaymentsData(PAYMENT_STEPS.STEP_3);
    }, []);

    useEffect(() => {
        if (orderIdError || !orderIdData) {
            onPaymentError();
        }
    }, [orderIdError, orderIdData]);

    useEffect(() => {
        if (selectedOffer && selectedOption) {
            setPriceLabel(
                selectedPricingPlan
                    ? selectedOption.getMinPriceTextForPricingPlan()
                    : selectedOption.getMinPriceText(),
            );
            setAccessLabel(selectedPricingPlan?.getName() || selectedOffer.getName());
            setAccessDescription(selectedPricingPlan && selectedOffer.getName());
            setPaymentMethodLabel(selectedOption.getName());
            setTrialDuration(selectedOffer.getTrialDuration());
            setAccessDuration(selectedOffer.getAccessText());

            const rules = extractRules({
                product,
                offer: selectedOffer,
                option: selectedOption,
                offerPricingPlan: selectedOfferPricingPlan,
            });

            setRulesData(
                rules.map((rule) => ({
                    id: rule.getId(),
                    label: rule.getAgreeLabel(),
                    checked: false,
                    required: rule.isRequired(),
                    error: false,
                })),
            );
        }
    }, [selectedOffer, selectedOption, selectedPricingPlan]);

    useEffect(() => {
        if (trialAvailableData && trialAvailableData.status !== 0 && trialDuration) {
            setTrialMessageVisibility(true);
            onSetStepsVisibility(false);
        }
    }, [trialAvailableData, trialDuration]);

    useEffect(() => {
        if (orderIdData?.orderId) {
            updatePaymentsData({ orderId: orderIdData.orderId });
        }
    }, [orderIdData]);

    const buildPriceLabel = () => {
        if (!priceLabel) {
            return '';
        }

        if (trialAvailableData?.status === 0 && trialDuration && accessDuration) {
            return t('payment-steps.price-label-with-trial', {
                trialDuration,
                accessDuration,
                priceLabel,
            });
        }

        return `${t('payment-steps.to-pay')} ${priceLabel}`;
    };

    const onToggleRule = (ruleId: number) => {
        setRulesData((prevRules) =>
            prevRules.map((prevRule) => {
                if (prevRule.id === ruleId) {
                    const updatedRule = {
                        ...prevRule,
                        checked: !prevRule.checked,
                    };
                    const error = isSubmitted && isRuleNotValid(updatedRule);

                    return {
                        ...updatedRule,
                        error,
                    };
                }

                return prevRule;
            }),
        );
    };

    const onToggleAllRules = (isAllRulesSelected: boolean) => {
        setRulesData((prevRules) =>
            prevRules.map((prevRule) => {
                const updatedRule = {
                    ...prevRule,
                    checked: !isAllRulesSelected,
                };
                const error = isSubmitted && isRuleNotValid(updatedRule);

                return {
                    ...updatedRule,
                    error,
                };
            }),
        );
    };

    const onSubmit = () => {
        if (!orderIdData) {
            onPaymentError();
            return;
        }

        if (!hasError) {
            onBuy(orderIdData.orderId);
            return;
        }

        setSubmitted(true);
        setRulesData((prevRules) =>
            prevRules.map((prevRule) => ({
                ...prevRule,
                error: isRuleNotValid(prevRule),
            })),
        );
    };

    const onRulesScroll = ({ isScrollableDown }: NavScrollDataInterface) => {
        setDownArrowVisible(isScrollableDown);
    };

    if (isTrialMessageVisible) {
        return (
            <CenterContainer>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('payment-steps.trial-message-title')}
                    description={t('payment-steps.trial-message-description')}
                />
                <ButtonContainer func={navVertical}>
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={onExitPaymentPath}
                    />
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            setTrialMessageVisibility(false);
                            onSetStepsVisibility(true);
                        }}
                        defaultFocused
                    />
                </ButtonContainer>
            </CenterContainer>
        );
    }

    return (
        <CheckoutContainer func={navVertical}>
            <ScrollableContainer
                func={navVertical}
                onNavScroll={onRulesScroll}
                scrollOptions={{
                    isScrollable: true,
                    direction: NAV_SCROLL_DIRECTION.VERTICAL,
                    mode: NAV_SCROLL_MODE.CENTER,
                }}
            >
                <CheckoutContent>
                    <MainText
                        mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                        text={t('payment-steps.purchase-summary')}
                        fullText
                    />

                    {accessLabel && paymentMethodLabel && (
                        <Checkout
                            accessLabel={accessLabel}
                            accessDescription={accessDescription}
                            paymentMethodLabel={paymentMethodLabel}
                        />
                    )}

                    <Rules
                        rules={rulesData}
                        onToggleRule={onToggleRule}
                        onToggleAllRules={onToggleAllRules}
                        defaultFocused
                    />
                </CheckoutContent>
            </ScrollableContainer>

            <CheckoutFooter>
                <PriceContainer>{buildPriceLabel()}</PriceContainer>
                <PriceButtonContainer>
                    <PlayableButton
                        text={t('payment-steps.pay')}
                        onButtonAction={onSubmit}
                        gutter={GUTTER.NONE}
                    />
                </PriceButtonContainer>
            </CheckoutFooter>

            {isDownArrowVisible && (
                <>
                    <ArrowContainer>
                        <Icon name={ICONS.ARROW_DOWN} />
                    </ArrowContainer>
                    <Shade />
                </>
            )}

            {isSubmitted && hasError && (
                <AlertMessage
                    userMessage={t('payment-steps.agreement-required')}
                    type={MESSAGE_TYPE.ERROR}
                />
            )}
        </CheckoutContainer>
    );
};
