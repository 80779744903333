import { INDICATOR_VARIANT } from '@/types/epg-table.type';
import styled from 'styled-components';

export const Indicator = styled.div<{ variant: INDICATOR_VARIANT }>`
    position: relative;
    ${({ variant }) => (variant === INDICATOR_VARIANT.BOTTOM ? `top: -2.5rem` : 'bottom: -1rem')};
    height: 0rem;
    left: 90%;
    z-index: 1;
`;

export const IconWrapper = styled.span`
    display: inline-block;
    fill: ${({ theme }) => theme.epgTable.indicatorFill};
    height: 1.1rem;
    line-height: 1;
    vertical-align: middle;
    width: 1.9rem;
`;
