import useDisconnect from '@/api/auth/use-disconnect';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { LoadingWrapper } from '@/components/shared/Loader';
import { Message } from '@/components/shared/Message';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { navVertical } from 'nav-tree';
import React from 'react';

const PlusZoneRemovePhoneContainer = () => {
    const { error: sessionError } = useGetCurrentAuthSession();
    const [disconnect, { error: disconnectError, isFetching }] = useDisconnect();

    const errorToDisplay = sessionError?.getUserMessage() || disconnectError?.getUserMessage();

    const disconnectHandler = async () => {
        const connectionData = { authProvider: AUTH_PROVIDERS.PLUS };
        const result = await disconnect({ connectionData });

        if (result.ok) {
            Router.pushRoute(ROUTES.PLUS_ZONE_REMOVE_PHONE_SUCCESS);
        }
    };

    return (
        <>
            <LoadingWrapper isFetching={isFetching} text={t('pages.plus-zone.remove.loading')}>
                <Message
                    icon={ICONS.WARNING_CIRCLE}
                    style={{ width: '80rem' }}
                    title={t('pages.plus-zone.remove.header')}
                    description={[
                        t('pages.plus-zone.remove.description-1'),
                        t('pages.plus-zone.remove.description-2'),
                    ]}
                />
                <NavWrapper>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={disconnectHandler}
                        defaultFocused
                    />
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={() => Router.pushRoute(ROUTES.PLUS_ZONE)}
                    />
                </NavWrapper>
            </LoadingWrapper>

            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );
};

const PlusZoneRemovePhone = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.PLUS_ZONE} />
            <PageContainer func={navVertical}>
                <PlusZoneRemovePhoneContainer />
            </PageContainer>
        </BaseLayout>
    );
};

export default PlusZoneRemovePhone;
