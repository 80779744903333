import { RefObject, useContext, useEffect, useState } from 'react';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { useRefetchFmc } from './use-refetch-fmc';
import { isServer } from '@/helpers/environment.helper';
import { IPlayerStatus, IPlayerTimeMarkers } from '@/interfaces/player.interface';
import { createPlugins } from '@/helpers/player.helper';
import { usePlayerStateManager } from './use-player-state-manager';
import { GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { useAuth } from '@/contexts/auth/auth.hooks';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { isStbTargets } from '@/constants/portal-recognition';
import { checkWatchWhitelist } from '@/helpers/check-watch-whitelist.helper';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { compareMedia } from '@/helpers/media.helper';
import { PlayerLoaderContext } from '@/contexts/player/player-loader.context';

interface IUsePlayerParams {
    playerContainerRef: RefObject<HTMLDivElement>;
    adsContainerRef?: RefObject<HTMLDivElement>;
    load?: {
        media: IPlayer.MediaInputData;
        config?: IPlayer.LoadConfig;
    };
    customOptions?: IPlayer.PlayerConfigOptions;
    catid?: number;
}

export const usePlayer = ({
    playerContainerRef,
    adsContainerRef,
    load,
    customOptions,
    catid,
}: IUsePlayerParams): [IPlayerStatus, IPlayerTimeMarkers, IPlayer.PlayerAPI | null] => {
    const [playerInstance, setPlayerInstance] = useState<IPlayer.PlayerAPI | null>(null);
    const { playerScriptLoaded } = useContext(PlayerLoaderContext);

    const { playerStatus, timeMarkers, bindings } = usePlayerStateManager(load?.media);
    const { data: authSessionData } = useGetCurrentAuthSession();
    const { refetchFmc } = useRefetchFmc(catid);

    const { platform } = usePlatform();
    const auth = useAuth();
    const userModule = useUserModule();
    const configuration = useConfiguration();

    useEffect(() => {
        const canWatch = checkWatchWhitelist();

        if (!isServer && (canWatch || authSessionData) && playerScriptLoaded) {
            let options: IPlayer.PlayerConfigOptions = {
                forceCac: true,
            };

            if (isStbTargets) {
                options = {
                    ...options,
                    stbDRMType: window.stb.stbDRMType,
                };
            }

            if (
                platform.os === GM_OS.Tizen ||
                platform.os === GM_OS.WebOS ||
                platform.os === GM_OS.VIDAA ||
                platform.os === GM_OS.PANASONIC
            ) {
                options = {
                    ...options,
                    forceAdsSystem: 'cp',
                };
            } else {
                options = {
                    ...options,
                    forceAdsSystem: 'ima',
                };
            }

            if (process.env.player.chromecastAppID) {
                options = {
                    ...options,
                    chromecastAppID: process.env.player.chromecastAppID,
                };
            }

            if (customOptions) {
                options = {
                    ...options,
                    ...customOptions,
                };
            }

            let playerUad = {
                application: auth.userAgentData.application,
                portal: auth.userAgentData.portal,
                player: auth.userAgentData.player,
            };

            // Hack - przekazywanie pełnego UAD do playera dla wybranych systemów
            // Usunąć hack po realizacji zadania SWN-2003
            if (
                isStbTargets ||
                platform.os === GM_OS.PANASONIC ||
                platform.os === GM_OS.VIDAA ||
                platform.os === GM_OS.WHALE ||
                platform.os === GM_OS.SAPHI ||
                platform.deviceType === GM_DEVICE_TYPE.XBOX
            ) {
                playerUad = auth.userAgentData;
            }

            const adsContainer = adsContainerRef?.current || undefined;

            const player = new CyfrowyPlayer.Player({
                ipData: configuration?.ipData,
                clientId: userModule.getClientId() || process.env.defaultUserData.clientId,
                container: playerContainerRef.current,
                adsContainer,
                parentContainer: adsContainer,
                ua: auth.ua,
                userAgentData: playerUad,
                options,
                // stats: {
                //     playerevents: {
                //         service: 'https://yag-staging.redefine.pl/events/player',
                //     },
                // },
                user: authSessionData?.toPlayerAuthObject(),
                ads: {
                    descriptionUrl: window.location.href,
                },

                plugins: createPlugins(platform, userModule),
            });

            player.ready.then(() => {
                setPlayerInstance(player);
            });

            return () => {
                player.destroy().catch(bindings.onError);
                refetchFmc?.();
            };
        }
    }, [authSessionData, customOptions, playerScriptLoaded]);

    useEffect(() => {
        if (!playerInstance) {
            return;
        }

        playerInstance.onError.attach(bindings.onError);
        playerInstance.onBuffering.attach(bindings.onBuffering);
        playerInstance.onTimeUpdate.attach((data: IPlayer.TimeUpdateData) =>
            bindings.onTimeUpdate(data, playerInstance),
        );
        playerInstance.onPlaybackOptionsUpdated.attach(bindings.onPlaybackOptionsUpdated);
        playerInstance.onLinearAdStarted.attach(bindings.onLinearAdStarted);
        playerInstance.onLinearAdUpdate.attach(bindings.onLinearAdUpdate);
        playerInstance.onLinearAdPodEnded.attach(bindings.onLinearAdPodEnded);
        playerInstance.onStateChange.attach(() => bindings.updateStatus(playerInstance));
        playerInstance.onPhaseChange.attach(() => bindings.updateStatus(playerInstance));
        playerInstance.onCastStatusChange.attach(bindings.onCastStatus);
        playerInstance.onCastSessionState.attach(bindings.onCastSessionState);
        playerInstance.onPlayNext.attach(bindings.onPlayNext);
        playerInstance.onReadyToPlayNext.attach(bindings.onReadyToPlayNext);
        playerInstance.onSkipZoneStarted.attach(bindings.onSkipZoneStarted);
        playerInstance.onSkipZoneEnded.attach(bindings.onSkipZoneEnded);
        playerInstance.onPlaybackReady.attach(bindings.onPlaybackReady);

        bindings.updateStatus(playerInstance);

        return () => {
            playerInstance.onTimeUpdate.detach();
            playerInstance.onPlaybackOptionsUpdated.detach();
            playerInstance.onLinearAdStarted.detach();
            playerInstance.onLinearAdUpdate.detach();
            playerInstance.onLinearAdPodEnded.detach();
            playerInstance.onStateChange.detach();
            playerInstance.onPhaseChange.detach();
            playerInstance.onCastStatusChange.detach();
            playerInstance.onCastSessionState.detach();
            playerInstance.onPlayNext.detach();
            playerInstance.onReadyToPlayNext.detach();
            playerInstance.onSkipZoneStarted.detach();
            playerInstance.onSkipZoneEnded.detach();
            playerInstance.onPlaybackReady.detach();
        };
    }, [playerInstance, bindings]);

    useEffect(() => {
        if (!playerInstance || !load) {
            return;
        }

        const status = playerInstance.getStatus();
        const currentMedia = status.media.id?.gmID;
        const loadMedia = load.media.gmID;

        // Zabezpieczenie przed próbą ponownego załadowania tego samego materiału
        if (compareMedia(currentMedia, loadMedia)) {
            return;
        }

        playerInstance.load(load.media, { autoplay: true, ...load.config });
    }, [playerInstance, load]);

    useEffect(() => {
        const { bodyClass } = process.env.player;

        if (bodyClass && playerScriptLoaded) {
            document.body.classList.add(bodyClass);

            return () => {
                document.body.classList.remove(bodyClass);
            };
        }
    }, [playerScriptLoaded]);

    return [playerStatus, timeMarkers, playerInstance];
};
