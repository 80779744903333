import styled from 'styled-components';

export const OfferContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Description = styled.text`
    font-size: ${(props) => props.theme.text.size.description};
    font-weight: lighter;
`;
