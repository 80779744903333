import styled from 'styled-components';
import { SubtitlesSelectedOptions } from '@/interfaces/player.interface';

export const PlayerWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const PlayerContainer = styled.div<{ subtitlesOptions?: SubtitlesSelectedOptions }>`
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;

    video::cue {
        font-size: ${({ subtitlesOptions }) => subtitlesOptions?.size || 100}%;
    }
`;
