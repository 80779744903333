import { MEDIA_TYPES } from '@/types/media.type';
import { useEffect } from 'react';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { useQueryClient } from 'react-query';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

export const useRefetchChannelList = (media: MediaDetailsModel, showInfo: boolean) => {
    const isChannel = media.mediaTypeForRelatedList === MEDIA_TYPES.TV;
    const shouldFetch = showInfo && isChannel;
    const queryClient = useQueryClient();

    useEffect(() => {
        if (shouldFetch) {
            queryClient.invalidateQueries(
                [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST],
                {
                    refetchInactive: true,
                },
            );
        }
    }, [shouldFetch, queryClient]);
};
