import { HomeMenuContext } from '@/contexts/menu/home.menu.context';
import { prepareMenuUrl } from '@/helpers/home-menu/prepare-menu-url.helper';
import { PlaceSchema } from '@/interfaces/from-schemas/navigation/getHomeMenuOut';
import { RouteInterface } from '@/routing/types';
import { useContext } from 'react';

export const usePrepareMenuUrl = (place?: PlaceSchema): RouteInterface | undefined => {
    const { subcategories } = useContext(HomeMenuContext);

    return prepareMenuUrl(place, subcategories);
};
