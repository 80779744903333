import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ROUTES } from '@/routing/types';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import React from 'react';
import { MyPurchasesContainer } from '@/components/views/Account/MyPurchases';

const MyAccess = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.MY_ACCESS} />
            <MyPurchasesContainer />
        </BaseLayout>
    );
};

export default MyAccess;
