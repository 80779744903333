import { StaffRecommendationListsItemModel } from '../recommendations/staff-recommendation-lists-item.model';
import { ListElementModel } from './list-element.model';

export class RecommendationListElementModel extends ListElementModel {
    private recommendationItem: StaffRecommendationListsItemModel;

    constructor(definition: StaffRecommendationListsItemModel) {
        const asset = definition.isTypeUrl() ? definition : definition.getObject();
        const title = definition.getTitle();
        const description = definition.getDescription();
        const image = definition.getImage();

        if (!asset) {
            throw new Error(
                'RecommendationListElementModel - Nieobsługiwany typ obiektu (getObject)',
            );
        }

        super(asset);
        this.recommendationItem = definition;

        if (title) {
            this.setTitle(title);
        }

        if (description) {
            this.setDescription(description);
        }

        if (image.src) {
            this.setImage(image);
        }
    }

    public getRecommendationItem(): StaffRecommendationListsItemModel {
        return this.recommendationItem;
    }
}
