import { rulesSourceKeyHook } from '@/hooks/default-key';
import { useQuery } from 'react-query';
import { rulesSourceAxiosInstance } from './rules-source-axios-instance';
import { protectURL } from '@/helpers/rewrite-protocol.helper';

interface IUseGetRulesSource {
    sourceUrl: string;
}

export const useGetRulesSource = (params?: IUseGetRulesSource) => {
    const protectedUrl = protectURL(params?.sourceUrl || '');
    const keyHook = rulesSourceKeyHook(protectedUrl);

    return useQuery(keyHook, () => rulesSourceAxiosInstance.get<string>(params!.sourceUrl), {
        enabled: !!params,
    });
};
