import { useState } from 'react';
import { TimeLeftTypes } from '@/types/date.type';
import { SECONDS_IN_12H } from '@/helpers/date.helper';
import { calculateTimeDifference } from '@/helpers/date.helper';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { useInterval } from './use-interval.hook';

interface CountdownTimerProps {
    media: MediaDetailsModel;
}

export const useCountdownTimer = ({ media }: CountdownTimerProps): TimeLeftTypes => {
    const [timeLeft, setTimeLeft] = useState<TimeLeftTypes>(
        calculateTimeDifference(media.getPublicationDate()),
    );

    const isEnabled = timeLeft.totalSeconds > 0 && timeLeft.totalSeconds < SECONDS_IN_12H;

    useInterval(
        () => {
            setTimeLeft(calculateTimeDifference(media.getPublicationDate()));
        },
        1000,
        isEnabled,
    );

    return timeLeft;
};
