import React, { useContext, useState, useEffect, useMemo } from 'react';
import { PacketContext } from '@/contexts/packet/packet.context';
import { RecoContext } from '@/contexts/reco/reco.context';
import { PageContext } from '@/contexts/page/page.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { SliderRaw } from './Slider';
import { useDynamicMountSliderElements } from './use-dynamic-mount-slider-elements';
import { useLoadCollection } from './use-load-collection.hook';
import { SliderContext, SliderOwnProps } from './types';

export const SliderWrapper = (props: SliderOwnProps) => {
    const { collectionElements, config } = props;
    const { list, rowIndex, withPackets } = config;

    const CTX = withPackets ? PacketContext : RecoContext;

    const {
        activeRow: activeRowCtx,
        setActiveRow,
        collectionActiveIndex: collectionActiveIndexCtx,
        setCollectionActiveIndex: setCollectionActiveIndexCtx,
        emptyListsArr,
    } = useContext(CTX);
    const { refocusPage } = useContext(PageContext);

    const [collectionActiveIndex, setCollectionActiveIndex] = useState<number>(
        collectionActiveIndexCtx,
    );

    const loadRow = useLoadCollection({ emptyListsArr, activeRowCtx, rowIndex });

    const {
        slicedCollectionElements,
        shouldRenderMoreElements,
        loadNextSlicePage,
    } = useDynamicMountSliderElements({
        collectionElements,
        collectionActiveIndexCtx,
        list,
    });

    const activeRow = useMemo(() => {
        if (loadRow) {
            return activeRowCtx;
        }

        return null;
    }, [loadRow, activeRowCtx]);

    useEffect(() => {
        if (loadRow && activeRowCtx === rowIndex) {
            setCollectionActiveIndex(collectionActiveIndexCtx);
        }
    }, [loadRow, collectionActiveIndexCtx]);

    useEffect(() => {
        if (refocusPage) setCollectionActiveIndex(0);
    }, [refocusPage]);

    useEffect(() => {
        batchedUpdates(() => {
            shouldRenderMoreElements(collectionActiveIndex);
            setCollectionActiveIndexCtx(collectionActiveIndex);
        });
    }, [collectionActiveIndex]);

    return useMemo(() => {
        const newProps = {
            ...props,
            collectionElements: slicedCollectionElements,
            loadNextSlicePage,
        };
        return (
            <SliderContext.Provider
                value={{
                    loadRow,
                    activeRow,
                    setActiveRow,
                    collectionActiveIndex,
                    setCollectionActiveIndex,
                }}
            >
                <SliderRaw {...newProps} />
            </SliderContext.Provider>
        );
    }, [loadRow, activeRow, collectionActiveIndex, props, slicedCollectionElements]);
};

export const Slider = React.memo(SliderWrapper);
