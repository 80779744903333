import { PATHS, ROUTES, RoutesConfigInterface, ZONES } from './types';
import { translateRoute } from './helpers';
import { isWeb } from '../constants/portal-recognition';

export const ROUTES_CONFIG: RoutesConfigInterface = [
    {
        name: ROUTES.HOME,
        pattern: translateRoute('/home'),
        page: 'home',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.SEARCH,
        pattern: translateRoute('/search'),
        page: 'search',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CONTACT,
        pattern: translateRoute('/contact'),
        page: 'contact',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CONTACT_IOD,
        pattern: translateRoute('/contact-iod'),
        page: 'contact-iod',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.MENU_MY_LIST,
        pattern: translateRoute('/menu-my-list'),
        page: 'menu-my-list',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.RULES,
        pattern: translateRoute('/account/rules'),
        page: 'rules',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.COOKIES_PREFERENCES,
        pattern: translateRoute('/cookies-preferences'),
        page: 'cookies-preferences',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PRIVACY_POLICY,
        pattern: translateRoute('/privacy-policy'),
        page: 'privacy-policy',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.OFFER,
        pattern: translateRoute('/offer'),
        page: 'offer',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PACKETS,
        pattern: translateRoute('/packets'),
        page: 'packets',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PACKET_CONTENT,
        pattern: translateRoute('/packets/:packetId/:categoryId/content'),
        page: 'packets/content',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PACKET_DETAILS,
        pattern: translateRoute('/packets/:packetId'),
        page: 'packets/details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PACKET_EXTERNAL_ACTIVATION_FAIL,
        pattern: translateRoute('/packets/:packetId/disney-activation-fail'),
        page: 'packets/disney-activation-fail',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.PACKET_FULL_DETAILS,
        pattern: translateRoute('/packets/:packetId/details'),
        page: 'packets/full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.HAVE_CODE,
        pattern: translateRoute('/account/have-code'),
        page: 'have-code',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.VOD,
        pattern: translateRoute('/vod/:category'),
        page: 'vod/category',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.SUBCATEGORY,
        pattern: translateRoute('/vod/:categoryId/:subcategoryId'),
        page: 'vod/subcategory',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_MOVIE_DETAILS,
        pattern: translateRoute('/vod/movie/:title/:id'),
        page: 'vod/movie-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_MOVIE_FULL_DETAILS,
        pattern: translateRoute('/vod/movie/:title/:id/details'),
        page: 'vod/movie-full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_MOVIE_WATCH,
        pattern: translateRoute('/vod/movie/:title/:id/watch'),
        page: 'vod/movie-watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.VOD_EPISODE_DETAILS,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:vodTitle/:vodId',
        ),
        page: 'vod/episode-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_DETAILS_LASTEST,
        pattern: translateRoute('/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:episodeType'),
        page: 'vod/episode-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY,
        pattern: translateRoute('/vod/:vodCategoryTitle/:categoryId/autoplay'),
        page: 'vod/chronological-non-chronological',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_SEASON_DETAILS,
        pattern: translateRoute('/vod/:vodCategoryTitle/:categoryId/:seasonName/:seasonId'),
        page: 'vod/chronological-non-chronological',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS,
        pattern: translateRoute('/vod/:vodCategoryTitle/:categoryId/autoplay/details'),
        page: 'vod/episode-full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_FULL_DETAILS,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:vodTitle/:vodId/details',
        ),
        page: 'vod/episode-full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_WITH_SEASON_WATCH,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:seasonName/:seasonId/:vodTitle/:vodId/watch',
        ),
        page: 'vod/episode-watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.VOD_EPISODE_WATCH,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:vodTitle/:vodId/watch',
        ),
        page: 'vod/episode-watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.VOD_WATCH,
        pattern: translateRoute('/vod/:vodTitle/:vodId/watch'),
        page: 'vod/episode-watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:seasonName/:seasonId/:vodTitle/:vodId',
        ),
        page: 'vod/episode-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.VOD_EPISODE_WITH_SEASON_FULL_DETAILS,
        pattern: translateRoute(
            '/vod/:mainCategory/:vodCategoryTitle/:vodCategoryId/:seasonName/:seasonId/:vodTitle/:vodId/details',
        ),
        page: 'vod/episode-full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.LIVE,
        pattern: translateRoute('/live'),
        page: 'live',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.LIVE_DETAILS,
        pattern: translateRoute('/live/:eventTitle/:eventId'),
        page: 'live/details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.LIVE_WATCH,
        pattern: translateRoute('/live/:eventTitle/:eventId/watch'),
        page: 'live/watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.LIVE_FULL_DETAILS,
        pattern: translateRoute('/live/:eventTitle/:eventId/details'),
        page: 'live/full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CHANNELS_TV,
        pattern: translateRoute('/channels'),
        page: 'channels',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CHANNEL_TV_DETAILS,
        pattern: translateRoute('/channels/:channelTitle/:channelId'),
        page: 'channels/details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CHANNEL_TV_FULL_DETAILS,
        pattern: translateRoute('/channels/:channelTitle/:channelId/details'),
        page: 'channels/full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CHANNEL_TV_WATCH,
        pattern: translateRoute('/channels/:channelTitle/:channelId/watch'),
        page: 'channels/watch',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.FUTURE_EPG,
        pattern: translateRoute('/program-tv/:tvProgramTitle/:tvProgramId'),
        page: 'program-tv/future-epg',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.FUTURE_EPG_FULL_DETAILS,
        pattern: translateRoute('/program-tv/:tvProgramTitle/:tvProgramId/details'),
        page: 'program-tv/full-details',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.REGISTRATION_QUICK_CHECK_EMAIL,
        pattern: translateRoute('/registration-quick/check-email'),
        page: PATHS.REGISTRATION_QUICK_CHECK_EMAIL,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.REGISTRATION_QUICK_SET_PASSWORD,
        pattern: translateRoute('/registration-quick/set-password'),
        page: PATHS.REGISTRATION_QUICK_SET_PASSWORD,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.REGISTRATION_QUICK_MAGIC_LINK,
        pattern: translateRoute('/registration-quick-magic-link'),
        page: PATHS.REGISTRATION_QUICK_MAGIC_LINK,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.REGISTRATION,
        pattern: translateRoute('/registration'),
        page: 'registration',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.REGISTRATION_ACCOUNT_EXISTS,
        pattern: translateRoute('/registration/account-exists'),
        page: 'registration/account-exists',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.REGISTRATION_CONFIGURATION,
        pattern: translateRoute('/registration/configuration'),
        page: 'registration/configuration',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.WELCOME,
        pattern: translateRoute('/welcome'),
        page: PATHS.WELCOME,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN,
        pattern: translateRoute('/user/login'),
        page: PATHS.LOGIN,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_NATIVE,
        pattern: translateRoute('/user/login/native'),
        page: PATHS.LOGIN_NATIVE,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_BY_CODE,
        pattern: translateRoute('/user/login/by-code'),
        page: PATHS.LOGIN_BY_CODE,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_BY_REMOTE,
        pattern: translateRoute('/user/login/by-remote'),
        page: PATHS.LOGIN_BY_REMOTE,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_FACEBOOK,
        pattern: translateRoute('/user/login/facebook'),
        page: PATHS.LOGIN_FACEBOOK,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_IPOLSATBOX,
        pattern: translateRoute('/user/login/ipolsatbox'),
        page: PATHS.LOGIN_IPOLSATBOX,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_BY_SMS,
        pattern: translateRoute('/user/login/by-sms'),
        page: PATHS.LOGIN_BY_SMS,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.LOGIN_RULES_OBLIGATORY,
        pattern: translateRoute('/user/login/rules-obligatory'),
        page: PATHS.LOGIN_RULES_OBLIGATORY,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.LOGIN_RULES,
        pattern: translateRoute('/user/login/login-rules'),
        page: PATHS.LOGIN_RULES,
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.RESET_PASSWORD,
        pattern: translateRoute('/reset-password/:token'),
        page: 'reset-password',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.RESET_PASSWORD_SUCCESS,
        pattern: translateRoute('/reset-password-success'),
        page: 'reset-password/success',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.RESET_PASSWORD_ERROR,
        pattern: translateRoute('/reset-password-error'),
        page: 'reset-password/error',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.RESET_PASSWORD_REQUEST,
        pattern: translateRoute('/reset-password-request'),
        page: 'reset-password/request',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.RESET_PASSWORD_CHECK_EMAIL,
        pattern: translateRoute('/reset-password-check-email'),
        page: 'reset-password/check-email',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.ACCOUNT_ACTIVATION,
        pattern: translateRoute('/account-activation/:token'),
        page: 'account-activation',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.EMAIL_ACTIVATION,
        pattern: translateRoute('/email-activation/:token'),
        page: 'email-activation',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.EMAIL_SET,
        pattern: translateRoute('/email-set/:token'),
        page: 'email-set',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.EMAIL_ACTIVATION_CHECK_EMAIL,
        pattern: translateRoute('/email-activation-check-email'),
        page: 'email-activation/check-email',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.EMAIL_ACTIVATION_REVERT_CHANGE_EMAIL,
        pattern: translateRoute('/email-activation-revert-change-email/:token'),
        page: 'email-activation/revert-email-change',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.ADD_ACCOUNT_EMAIL,
        pattern: translateRoute('/add-account-email'),
        page: 'add-account-email/request',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.ADD_ACCOUNT_EMAIL_STATUS,
        pattern: translateRoute('/add-account-email/status'),
        page: 'add-account-email/status',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.PLUS_ZONE,
        pattern: translateRoute('/account/plus-zone'),
        page: 'plus-zone',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PLUS_ZONE_REMOVE_PHONE,
        pattern: translateRoute('/account/plus-zone/remove-phone'),
        page: 'plus-zone/remove-phone',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PLUS_ZONE_REMOVE_PHONE_SUCCESS,
        pattern: translateRoute('/account/plus-zone/remove-phone/success'),
        page: 'plus-zone/remove-phone-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PLUS_ZONE_ADD_PHONE,
        pattern: translateRoute('/account/plus-zone/connect-with-plus'),
        page: 'plus-zone/add-phone',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.PURCHASE_PLUS_ZONE_ADD_PHONE,
        pattern: translateRoute('/purchase/:id/:type/:subType/plus-zone/connect-with-plus'),
        page: 'plus-zone/purchase-add-phone',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.PLUS_ZONE_ADD_PHONE_SUCCESS,
        pattern: translateRoute('/account/plus-zone/connect-with-plus/success'),
        page: 'plus-zone/add-phone-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PURCHASE_PLUS_ZONE_ADD_PHONE_SUCCESS,
        pattern: translateRoute('/purchase/:id/:type/:subType/plus-zone/connect-with-plus/success'),
        page: 'plus-zone/purchase-add-phone-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PLUS_ZONE_CHANGE_PHONE,
        pattern: translateRoute('/plus-zone/change-phone'),
        page: 'plus-zone/change-phone',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PLUS_ZONE_CHANGE_PHONE_SUCCESS,
        pattern: translateRoute('/plus-zone/change-phone/success'),
        page: 'plus-zone/change-phone-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.SETTINGS,
        pattern: translateRoute('/account/settings'),
        page: 'settings',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.GOODBYE,
        pattern: translateRoute('/goodbye'),
        page: 'goodbye',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CONSENTS_LIST,
        pattern: translateRoute('/account/consents-list'),
        page: 'account/consents-list',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.CONSENT,
        pattern: translateRoute('/account/consent/:ruleId'),
        page: 'account/consent',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.REGISTER_PAYMENT_CARD,
        pattern: translateRoute('/register-payment-card'),
        page: 'register-payment-card',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.REGISTER_PAYMENT_CARD_STATUS,
        pattern: translateRoute('/register-payment-card/status/:paymentCardId'),
        page: 'register-payment-card/status',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.DELETE_PAYMENT_CARD_CONFIRM,
        pattern: translateRoute('/account/my-payments/delete-payment-card-confirm/:paymentCardId'),
        page: 'account/my-payments-delete-card-confirm',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.DELETE_PAYMENT_CARD_SUCCESS,
        pattern: translateRoute('/account/my-payments/delete-payment-card-success/:paymentCardId'),
        page: 'account/my-payments-delete-card-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PURCHASE,
        pattern: translateRoute('/purchase/:id/:type/:subtype/:step(step-1|step-2|step-3|step-4)'),
        page: 'purchase',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PURCHASE_OFFER_RULES,
        pattern: translateRoute('/purchase/:id/:type/:subType/:offerId/rules'),
        page: 'purchase/rules',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PURCHASE_OFFER_RULE,
        pattern: translateRoute('/purchase/:id/:type/:subType/:offerId/rules/:ruleId'),
        page: 'purchase/rule',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PURCHASE_PRICING_PLAN_RULES,
        pattern: translateRoute('/purchase/:id/:type/:subType/:offerId/:offerPricingPlanId/rules'),
        page: 'purchase/rules',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PURCHASE_PRICING_PLAN_RULE,
        pattern: translateRoute(
            '/purchase/:id/:type/:subType/:offerId/:offerPricingPlanId/rules/:ruleId',
        ),
        page: 'purchase/rule',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PURCHASE_STATUS,
        pattern: translateRoute(
            '/purchase-status/:id/:type/:subType/:offerId/:optionId/:possessionId',
        ),
        page: 'purchase-status',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.ORDER_STATUS,
        pattern: translateRoute('/order-status/:id/:type/:subType/:offerId/:optionId/:orderId'),
        page: 'order-status',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.PAYMENT_CARD_STATUS,
        pattern: translateRoute('/payment-card-status/:paymentCardRegistrationId'),
        page: 'payment-card-status',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT,
        pattern: translateRoute('/account/my-account'),
        page: 'account',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.ACCOUNT_CHANGE_PROFILE,
        pattern: translateRoute('/account/change-profile'),
        page: 'account/change-profile',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_EXISTS,
        pattern: translateRoute('/account/exists'),
        page: 'account/exists',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.ACCOUNT_CHANGE_EMAIL,
        pattern: translateRoute('/account/settings/change-email'),
        page: 'account/change-email',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.ACCOUNT_CHANGE_EMAIL_SUCCESS,
        pattern: translateRoute('/account/settings/change-email/success'),
        page: 'account/change-email-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_CHANGE_PASSWORD,
        pattern: translateRoute('/account/settings/change-password'),
        page: 'account/change-password',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.ACCOUNT_CHANGE_PASSWORD_SUCCESS,
        pattern: translateRoute('/account/settings/change-password/success'),
        page: 'account/change-password-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_SET_PASSWORD,
        pattern: translateRoute('/account/settings/set-password'),
        page: 'account/set-password',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.ACCOUNT_SET_PASSWORD_SUCCESS,
        pattern: translateRoute('/account/settings/set-password/success'),
        page: 'account/set-password-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_CHECK_EMAIL,
        pattern: translateRoute('/account/check-email'),
        page: 'account/check-email',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_LOGOUT,
        pattern: translateRoute('/account/logout'),
        page: 'account/logout',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_DELETE,
        pattern: translateRoute('/account/settings/account-delete'),
        page: 'account/delete-user',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.ACCOUNT_DELETE_SUCCESS,
        pattern: translateRoute('/account/settings/account-delete/success'),
        page: 'account/delete-user-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_HELP,
        pattern: translateRoute('/account/help'),
        page: 'account/help',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_RESTORE_SETTINGS,
        pattern: translateRoute('/account/settings/restore-settings'),
        page: 'account/restore-settings',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.EU_ACCESS,
        pattern: translateRoute('/account/eu-access'),
        page: 'account/eu-access',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.EU_VERIFICATION_SUCCESS,
        pattern: translateRoute('/account/eu-access/success'),
        page: 'account/eu-verification-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE,
        pattern: translateRoute('/profile/:profileId'),
        page: PATHS.PROFILE,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_LOGIN_PROCESS,
        pattern: translateRoute('/profile-login-process/:profileId'),
        page: PATHS.PROFILE_LOGIN_PROCESS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_ADD,
        pattern: translateRoute('/profile-add'),
        page: PATHS.PROFILE_ADD,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_ADD_LOGIN_PROCESS,
        pattern: translateRoute('/profile-add-login-process'),
        page: PATHS.PROFILE_ADD_LOGIN_PROCESS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_WATCH_AS,
        pattern: translateRoute('/profile-watch-as'),
        page: PATHS.PROFILE_WATCH_AS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_SELECT_TO_EDIT,
        pattern: translateRoute('/profile-select-to-edit'),
        page: PATHS.PROFILE_SELECT_TO_EDIT,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS,
        pattern: translateRoute('/profile-select-to-edit-login-process'),
        page: PATHS.PROFILE_SELECT_TO_EDIT_LOGIN_PROCESS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_ACCESS,
        pattern: translateRoute('/account/my-access'),
        page: 'account/my-access',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.MY_ACCESS_RESIGNATION_CONFIRM,
        pattern: translateRoute(
            '/account/my-access-resignation-confirm/:subscriptionId/:possessionName',
        ),
        page: 'account/my-access-resignation-confirm',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_ACCESS_RESIGNATION_SUCCESS,
        pattern: translateRoute('/account/my-access-resignation-success/:possessionName'),
        page: 'account/my-access-resignation-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_ACCESS_RENEW_CONFIRM,
        pattern: translateRoute('/account/my-access-renew-confirm/:subscriptionId/:possessionName'),
        page: 'account/my-access-renew-confirm',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_ACCESS_RENEW_SUCCESS,
        pattern: translateRoute('/account/my-access-renew-success/:possessionName'),
        page: 'account/my-access-renew-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_ACCESS_DISNEY_ACTIVATION_FAIL,
        pattern: translateRoute('/account/my-access-disney-activation-fail'),
        page: 'account/disney-activation-fail',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.MY_PAYMENTS,
        pattern: translateRoute('/account/my-payments'),
        page: 'account/my-payments',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.PROFILE_CHANGE_ICON,
        pattern: translateRoute('/profile-change-icon/:profileId'),
        page: PATHS.PROFILE_CHANGE_ICON,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_CHANGE_NAME,
        pattern: translateRoute('/profile-change-name/:profileId'),
        page: PATHS.PROFILE_CHANGE_NAME,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_REMOVE,
        pattern: translateRoute('/profile-remove/:profileId'),
        page: PATHS.PROFILE_REMOVE,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_REMOVE_DEFAULT,
        pattern: translateRoute('/profile-remove-default/:profileId'),
        page: 'profile/remove-default',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS,
        pattern: translateRoute('/profile-remove-default-login-process/:profileId'),
        page: PATHS.PROFILE_REMOVE_DEFAULT_LOGIN_PROCESS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_REMOVE_SUCCESS,
        pattern: translateRoute('/profile-remove-success'),
        page: PATHS.PROFILE_REMOVE_SUCCESS,
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_PARENT_CONTROL_PASSWORD,
        pattern: translateRoute('/profile-parent-control-password/:profileId'),
        page: 'profile/parent-control-password',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.PROFILE_PARENT_CONTROL_AGE,
        pattern: translateRoute('/profile-parent-control-age/:profileId'),
        page: 'profile/parent-control-age',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.ACCOUNT_MY_PURCHASES,
        pattern: translateRoute('/account/purchases'),
        page: 'account/my-purchases',
        zone: ZONES.ACCOUNT,
    },

    {
        name: ROUTES.FACEBOOK_DISCONNECT,
        pattern: translateRoute('/account/facebook/disconnect'),
        page: 'account/facebook-disconnect',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.FACEBOOK_DISCONNECT_SUCCESS,
        pattern: translateRoute('/account/facebook/disconnect/success'),
        page: 'account/facebook-disconnect-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.FACEBOOK_CONNECT,
        pattern: translateRoute('/account/facebook/connect'),
        page: 'account/facebook-connect',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.FACEBOOK_CONNECT_SUCCESS,
        pattern: translateRoute('/account/facebook/connect/success'),
        page: 'account/facebook-connect-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.IPOLSATBOX_DISCONNECT,
        pattern: translateRoute('/account/ipolsatbox/disconnect'),
        page: 'account/ipolsatbox-disconnect',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.IPOLSATBOX_DISCONNECT_SUCCESS,
        pattern: translateRoute('/account/ipolsatbox/disconnect/success'),
        page: 'account/ipolsatbox-disconnect-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.IPOLSATBOX_CONNECT,
        pattern: translateRoute('/account/ipolsatbox/connect'),
        page: 'account/ipolsatbox-connect',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.IPOLSATBOX_CONNECT_SUCCESS,
        pattern: translateRoute('/account/ipolsatbox/connect/success'),
        page: 'account/ipolsatbox-connect-success',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.SET_PASSWORD_INFO,
        pattern: translateRoute('/account/set-password-info'),
        page: 'account/set-password-info',
        zone: ZONES.ACCOUNT,
    },
    {
        name: ROUTES.EXIT_APP_CONFIRMATION,
        pattern: translateRoute('/exit-app-confirmation'),
        page: 'exit-app-confirmation',
        zone: ZONES.NOT_FOUND,
    },
    {
        name: ROUTES.NOT_FOUND,
        pattern: translateRoute('/not-found'),
        page: 'not-found',
        zone: ZONES.NOT_FOUND,
    },
    {
        name: ROUTES.LOGIN_TO_CONTINUE,
        pattern: translateRoute('/login-to-continue'),
        page: 'login-to-continue',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.TV_CODE,
        pattern: translateRoute('/tvcode'),
        page: PATHS.TV_CODE,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.TV_CODE_LOGGED_USER,
        pattern: translateRoute('/account/tvcode-logged-user'),
        page: PATHS.TV_CODE_LOGGED_USER,
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.RESOLVE_MEDIA,
        pattern: translateRoute('/resolve-media/:cpid/:id'),
        page: 'resolve-media',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.RESOLVE_CATEGORY,
        pattern: translateRoute('/resolve-category/:catid'),
        page: 'resolve-category',
        zone: ZONES.AUTH,
    },
    {
        name: ROUTES.EVENTS,
        pattern: translateRoute('/events/:categoryId'),
        page: 'events',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.TYRZADZISZ,
        pattern: translateRoute('/tyrzadzisz'),
        page: 'tyrzadzisz',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.POLONIA,
        pattern: translateRoute('/polonia'),
        page: 'polonia',
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CUSTOMER_CARE_HELP,
        pattern: translateRoute('/customer-care-help'),
        page: PATHS.CUSTOMER_CARE_HELP,
        zone: ZONES.CUSTOMER_CARE,
    },
    {
        name: ROUTES.CONTACT_WITH_US,
        pattern: translateRoute('/customer-care-help/contact-with-us'),
        page: 'customer-care/contact-with-us',
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.CUSTOMER_CARE_FORM_SUCCESS_PAGE,
        pattern: translateRoute('/customer-care-help/form-success-page'),
        page: PATHS.CUSTOMER_CARE_FORM_SUCCESS_PAGE,
        zone: ZONES.CONTENT,
    },
    {
        name: ROUTES.CUSTOMER_CARE_HELP_ARTICLE,
        pattern: translateRoute('/customer-care-help/:articleId'),
        page: PATHS.CUSTOMER_CARE_HELP_ARTICLE,
        zone: ZONES.CUSTOMER_CARE,
    },
    {
        name: ROUTES.CUSTOMER_CARE_ERROR_PAGE,
        pattern: translateRoute('/customer-care-help/:errorCode/error-page'),
        page: PATHS.CUSTOMER_CARE_ERROR_PAGE,
        zone: ZONES.CUSTOMER_CARE,
    },
    {
        name: ROUTES.CUSTOMER_CARE_FULL_ERROR_PAGE,
        pattern: translateRoute('/customer-care-help/:articleId/:errorCode/full-error-page'),
        page: PATHS.CUSTOMER_CARE_FULL_ERROR_PAGE,
        zone: ZONES.CUSTOMER_CARE,
    },
    {
        name: ROUTES.PROMOTION,
        pattern: translateRoute('/promotion'),
        page: 'magic-link/promotion',
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PROMOTION_STATUS,
        pattern: translateRoute('/promotion/promotion-status'),
        page: PATHS.PROMOTION_STATUS,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PROMOTION_TAKE_GIFT,
        pattern: translateRoute('/promotion/promotion-take-gift'),
        page: PATHS.PROMOTION_TAKE_GIFT,
        zone: ZONES.LOGIN_TO_CONTINUE,
    },
    {
        name: ROUTES.PARTNERSHIP_MODEL_CHECK_CODE,
        pattern: translateRoute('/partnership-model/check-code/:code'),
        page: PATHS.PARTNERSHIP_MODEL_CHECK_CODE,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PARTNERSHIP_MODEL_CHECK_CODE_ADD_PHONE,
        pattern: translateRoute('/partnership-model/check-code/:code/add-phone'),
        page: PATHS.PARTNERSHIP_MODEL_CHECK_CODE_ADD_PHONE,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PARTNERSHIP_MODEL_REGISTER_CODE,
        pattern: translateRoute('/partnership-model/register-code/:code'),
        page: PATHS.PARTNERSHIP_MODEL_REGISTER_CODE,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
    {
        name: ROUTES.PARTNERSHIP_MODEL_REGISTER_CODE_STATUS,
        pattern: translateRoute('/partnership-model/register-code-status/:code'),
        page: PATHS.PARTNERSHIP_MODEL_REGISTER_CODE_STATUS,
        zone: ZONES.LOGIN_WITHOUT_PROFILE,
    },
];

export const ZONES_FALLBACK: Record<
    Exclude<ZONES, 'not-found'>,
    { logged?: ROUTES; notLogged?: ROUTES }
> = {
    [ZONES.AUTH]: {
        logged: ROUTES.HOME,
    },
    [ZONES.ACCOUNT]: {
        notLogged: ROUTES.WELCOME,
    },
    [ZONES.CUSTOMER_CARE]: {
        notLogged: ROUTES.CUSTOMER_CARE_HELP,
    },
    [ZONES.CONTENT]: {
        notLogged: ROUTES.WELCOME,
    },
    [ZONES.LOGIN_TO_CONTINUE]: {
        notLogged: isWeb ? ROUTES.LOGIN_TO_CONTINUE : ROUTES.WELCOME,
    },
    [ZONES.LOGIN_WITHOUT_PROFILE]: {
        notLogged: isWeb ? ROUTES.LOGIN_TO_CONTINUE : ROUTES.WELCOME,
    },
};
