import { ProfileInterface } from '@/interfaces/profile-interface';
import { ReactNode } from 'react';

export interface ProfileContextProviderProps {
    children: ReactNode;
}

export interface ProfileContextProps {
    sessionProfile?: ProfileInterface;
    setSessionProfile: (profile: ProfileInterface) => void;
    profileUpdateHours?: number;
    profileIdCookie?: string;
}

export enum PROFILE_COOKIES {
    PROFILE_SELECTED_AT = 'profile-selected-at',
    PROFILE_ID = 'profile-id',
}
