import { useGetCategory } from '@/api/navigation/category/get-category';
import useGetSubcategories from '@/api/navigation/category/get-subcategories';
import useGetPacketTreeNavigation from '@/api/packets/use-get-packet-tree-navigation';
import { NavigationButtonsVariant, SubcategoryParams } from '@/components/views/common/types';
import t from '@/lib/i18n';
import { useParams } from 'react-router';

export const useCatalogSubcategories = (
    withSubcategory?: SubcategoryParams,
    withPacketCategories?: boolean,
) => {
    let getSubcategoryParams;
    if (withSubcategory) {
        getSubcategoryParams = { catid: withSubcategory.keyCategoryId };
    }

    const { data: subcategories } = useGetSubcategories(getSubcategoryParams);
    const { data: categoryData } = useGetCategory(getSubcategoryParams);
    const subcategoriesMenuTitle = categoryData?.getSubCategoriesLabel() ?? t('seasons');

    const { packetId } = useParams<{ packetId: string }>();

    const packetTreeParams = !!withPacketCategories && { packetCode: packetId, query: '' };
    const { data: packetFilters } = useGetPacketTreeNavigation(packetTreeParams);

    const getButtonVariant = () => {
        if (withPacketCategories) {
            return NavigationButtonsVariant.PACKET_SUBCATEGORY;
        }
        if (withSubcategory) {
            return NavigationButtonsVariant.SUBCATEGORY;
        }
        return NavigationButtonsVariant.CATALOG;
    };

    const buttonVariant = getButtonVariant();

    return {
        subcategories,
        subcategoriesMenuTitle,
        buttonVariant,
        packetFilters,
    };
};
