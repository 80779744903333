import { EPGSliderProps } from './types';
import { isNowPlaying, isPlayable } from './helpers';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { useThrottledNumerical } from '@/hooks/use-throttled-numerical.hook';
import { useCallback, useEffect, useMemo } from 'react';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';
import { useWalkingEPG } from '@/hooks/player/use-walking-epg';
import { usePlayerJump } from '@/hooks/player/use-player-jump';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';
import React from 'react';
import { EPGSliderPresentation } from './EPGSliderPresentation';

export const EPGSlider = ({
    channelId,
    navId = 'epgSlider',
    onNav,
    timeshiftingDuration,
    onIsProgramTv,
}: EPGSliderProps) => {
    const { skipRange, currentTime } = usePlayerTimeMarkers();
    const isTimeshiftingSupported = useTimeshiftingSupported();
    const refTime = useMemo(() => {
        if (!isTimeshiftingSupported) {
            return undefined;
        }
        if (skipRange.start > 0) {
            return skipRange.start * 1000;
        }
        if (!timeshiftingDuration) {
            return undefined;
        }
        return new Date().getTime() - timeshiftingDuration * 1000;
    }, [skipRange, currentTime, timeshiftingDuration, isTimeshiftingSupported]);

    const { programs, isFetching } = useWalkingEPG({
        refTime,
        channelId,
        count: Infinity,
    });
    const jump = usePlayerJump();
    const currentTimestamp = useThrottledNumerical(currentTime * 1000);

    const loading = (!skipRange.start && !currentTime) || isFetching;

    useEffect(() => {
        !isFetching && onIsProgramTv(!!programs.length);
    }, [programs, isFetching]);

    const onIsPlayable = useCallback(
        (program: ChannelProgramTvModel) => isPlayable(program, timeshiftingDuration),
        [timeshiftingDuration],
    );

    const onIsNowPlaying = useCallback(
        (program: ChannelProgramTvModel, index: number) =>
            isNowPlaying(program, index, currentTimestamp, isTimeshiftingSupported),
        [currentTimestamp, isTimeshiftingSupported],
    );

    const onJump = useCallback(
        (program: ChannelProgramTvModel) => () => {
            const timestamp = program.getStartTimeTimestamp() / 1000;
            if (isTimeshiftingSupported) jump(timestamp);
        },
        [isTimeshiftingSupported, jump],
    );

    return (
        <EPGSliderPresentation
            programs={programs}
            loading={loading}
            navId={navId}
            onNav={onNav}
            onJump={onJump}
            onIsPlayable={onIsPlayable}
            onIsNowPlaying={onIsNowPlaying}
        />
    );
};
