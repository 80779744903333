import { CpWalletAcquireOptionDataSchema } from '@/interfaces/from-schemas/payments/getOptionDataOut';

export class CpwalletModel {
    private contractId: string;
    private contractName?: string;
    private credit: number;
    private creditText: string;
    private default?: boolean;
    private alreadyPurchased?: boolean;

    constructor(definition: CpWalletAcquireOptionDataSchema['cpWallets'][number]) {
        this.contractId = definition.contractId;
        this.contractName = definition.contractName;
        this.credit = definition.credit;
        this.creditText = definition.creditText;
        this.default = definition.default;
        this.alreadyPurchased = definition.alreadyPurchased;
    }

    public getContractId(): string {
        return this.contractId;
    }

    public getContractName(): string | undefined {
        return this.contractName;
    }

    public getCredit(): number {
        return this.credit;
    }

    public getCreditText(): string {
        return this.creditText;
    }

    public isDefault(): boolean {
        return this.default || false;
    }

    public isAlreadyPurchased(): boolean {
        return this.alreadyPurchased || false;
    }
}
