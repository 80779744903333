import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetCategoryTreeListIn } from '@/interfaces/from-schemas/help/getCategoryTreeListIn';
import { GetCategoryTreeListOut } from '@/interfaces/from-schemas/help/getCategoryTreeListOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

export const getCategoryTreeListPromise = (
    params: GetCategoryTreeListIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetCategoryTreeListOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.HELP,
        GET_MEDIA_METHODS.GET_CATEGORY_TREE_LIST,
        params,
    );

    return getMediaApiPromise(keyHook, { appendAuthData: true }).then((res) => res.data);
};

const useGetCategoryTreeList = (
    params: GetCategoryTreeListIn,
): IApiResponse<GetCategoryTreeListOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.HELP,
        GET_MEDIA_METHODS.GET_CATEGORY_TREE_LIST,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
    return { data, isFetching, error };
};

export default useGetCategoryTreeList;
