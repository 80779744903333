import { sortByConsecutiveNumbers } from './sort-by-consecutive-numbers.helper';

export const buildEmptyListsSafeBufor = (emptyListsArr: number[], activeRow: number) => {
    // Utwórz tablicę tablic z konsekutywnymi wartościami
    // [1, 3, 5, 6, 7] => [[1], [3], [5, 6, 7]]
    const consecutiveArrays = sortByConsecutiveNumbers(emptyListsArr);

    // Zwróć tylko te tablice, które znajdują się w bezpośrednim sąsiedztwie activeRow
    // [[1], [3], [5, 6, 7]], activeRow === 4 => [[3], [5, 6, 7]]
    const closeEmptyArrays = consecutiveArrays.filter((arr) =>
        arr.some((el) => el + 1 === activeRow || el - 1 === activeRow),
    );

    if (!closeEmptyArrays.length) return 0;

    // Zwróć długość największej tablicy
    // [[3], [5, 6, 7]] => 3
    return Math.max(...closeEmptyArrays.map((arr) => arr.length));
};
