import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { Message } from '@/components/shared/Message';
import useChangeEmail from '@/api/auth/use-change-email';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import React from 'react';
import useRegister from '@/api/auth/register';
import { useChangeEmailOrRegister } from '@/hooks/auth/use-change-email-or-register.hook';
import { useChangeEmailOrRegisterForm } from '@/hooks/auth/use-change-email-or-register-form.hook';

const AddAccountEmailRequest = () => {
    const { register, handleSubmit, errors } = useChangeEmailOrRegisterForm();

    const [registerUser] = useRegister();
    const [changeEmail, { error }] = useChangeEmail();

    const mutation = useChangeEmailOrRegister(changeEmail, registerUser);

    const onSubmit = handleSubmit(async ({ email }) => {
        await mutation(email).then((response) => {
            if (response.ok) {
                Router.pushRoute(ROUTES.EMAIL_ACTIVATION_CHECK_EMAIL);
            }
        });
    });

    const displayError = Object.values(errors).shift()?.message || error?.getUserMessage();

    const body = (
        <>
            <Message
                description={t('pages.add-account-email.request.description', {
                    portalName: t('domain'),
                })}
            />
            <FormContainer onSubmit={onSubmit}>
                <TextInput
                    name="email"
                    placeholder={t('form.placeholder.e-mail')}
                    type={INPUT_TYPE.TEXT}
                    icon={ICONS.EMAIL}
                    error={!!displayError}
                    ref={register()}
                    defaultFocused
                />
                <PlayableButton text={t('next-button-label')} onButtonAction={onSubmit} />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer
                header={t('pages.add-account-email.request.title')}
                body={body}
                error={displayError}
                footerLines={4}
                footer={t('GDPR').concat(t('GDPR-suffix'))}
            />
        </BaseLayout>
    );
};

export default AddAccountEmailRequest;
