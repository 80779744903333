import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { IncrementContextProps, IncrementContextProviderProps, IncrementRowData } from './types';

export const IncrementContext = createContext<IncrementContextProps>({
    incrementRow: { fetchedIndex: 0, loadedIndex: 0, loadedData: false },
    setIncrementRow: () => null,
    clearContext: () => null,
    incrementLoadedIndex: () => null,
    incrementFetchedIndex: () => null,
    handleLoadedData: () => null,
});

const IncrementContextProvider = ({ children }: IncrementContextProviderProps) => {
    const { initialLimit, increaseBy } = process.env.pages.collections;
    const incrementRowInit = { fetchedIndex: initialLimit, loadedIndex: 0, loadedData: false };

    const [incrementRow, setIncrementRow] = useState<IncrementRowData>(incrementRowInit);

    useEffect(() => {
        incrementLoadedIndex();
    }, [incrementRow.fetchedIndex, incrementRow.loadedData]);

    const clearContext = useCallback(() => {
        setIncrementRow(incrementRowInit);
    }, []);

    const incrementLoadedIndex = () => {
        if (incrementRow.loadedIndex < incrementRow.fetchedIndex && incrementRow.loadedData) {
            setIncrementRow((prev) => ({
                ...prev,
                loadedIndex: prev.loadedIndex + 1,
                loadedData: false,
            }));
        }
    };

    const incrementFetchedIndex = (activeRow: number | null) => {
        setIncrementRow((prev) => {
            if (activeRow !== null && activeRow >= prev.fetchedIndex && prev.loadedData) {
                return {
                    ...prev,
                    fetchedIndex: prev.fetchedIndex + increaseBy,
                };
            }
            return prev;
        });
    };

    const handleLoadedData = useCallback((isLoaded: boolean) => {
        setIncrementRow((prev) => {
            const increment = {
                loadedIndex: prev.loadedIndex + 1,
                fetchedIndex: prev.fetchedIndex + 1,
            };
            return isLoaded
                ? { ...prev, loadedData: isLoaded }
                : { loadedData: isLoaded, ...increment };
        });
    }, []);

    return useMemo(
        () => (
            <IncrementContext.Provider
                value={{
                    incrementRow,
                    setIncrementRow,
                    clearContext,
                    incrementLoadedIndex,
                    incrementFetchedIndex,
                    handleLoadedData,
                }}
            >
                {children}
            </IncrementContext.Provider>
        ),
        [incrementRow],
    );
};

export default IncrementContextProvider;
