import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { AccountOptionList } from '@/components/views/Account/AccountOption/AccountOptionList';
import { AccountRootContainer } from '@/components/views/Account/styles';
import { ROUTES } from '@/routing/types';
import React from 'react';

const Account = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <AccountRootContainer autofocusOn={['mouseenter']} restoreLastFocus>
                <AccountOptionList />
            </AccountRootContainer>
        </BaseLayout>
    );
};

export default Account;
