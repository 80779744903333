import { ICONS } from '../Icon';
import { ThemeProps } from 'styled-components';
import { GUTTER } from '@/types/gutter.type';

export interface MetadataComponentProps {
    data: string | string[];
    type: METADATA_TYPES | METADATA_RESPONSIVE_TYPES;
    responsive?: boolean;
    isSafeHTML?: boolean;
    separationType?: METADATA_SEPARATION_TYPES;
}

export interface BadgeOwnProps {
    mode?: METADATA_RESPONSIVE_KIND | METADATA_TYPES;
    responsive?: boolean;
}

export interface IconGroupProps {
    icon: ICONS | number;
    iconSize: number;
    noMargin?: boolean;
    gutter?: GUTTER;
    targetColor?: boolean;
    iconWidth?: number;
}

export interface StyledSingleDataWrapperProps {
    $type: METADATA_TYPES | METADATA_RESPONSIVE_TYPES;
    responsive?: boolean;
    hasSeparator?: boolean;
}

export interface StyledIconWrapperProps {
    $size?: number;
    mainColor?: boolean;
    noMargin?: boolean;
    gutter?: GUTTER;
    targetColor?: boolean;
    $width?: number;
}

export enum METADATA_RESPONSIVE_TYPES {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    DATE = 'date',
    METADATA_PRIMARY = 'metadata-primary',
    FULL_DETAILS_METADATA_PRIMARY = 'full-details-metadata-primary',
}

export enum METADATA_TYPES {
    COPYRIGHT = 'copyright',
    EPG_PREVIEW = 'epg-preview',
    METADATA_ACCESS = 'metadata-access',
    PACKET = 'packet',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
    TERTIARY_CONTRAST = 'tertiary-contrast',
    EPG_OVERLAY = 'epg-overlay',
}

export enum METADATA_SEPARATION_TYPES {
    DOT = 'dot',
    DOT_SMALL = 'dot-small',
    LINE = 'line',
    NONE = 'none',
}

export enum METADATA_RESPONSIVE_KIND {
    LIST_ELEMENT_METADATA = 'list-element-metadata',
}

export interface VerticalLineProps {
    responsive?: boolean;
}

export interface DotProps {
    small?: boolean;
}

export type StyledVerticalLineProps = VerticalLineProps & ThemeProps<any>;
