import { useEffect, useMemo, useState } from 'react';
import AuthSessionModel from '@/models/auth-session.model';
import ResponseErrorModel from '@/models/response-error.model';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';

export const useGetCurrentAuthSession = () => {
    const userModule = useUserModule();
    const [session, setSession] = useState(userModule.getSession());
    const [error, setError] = useState<ResponseErrorModel>();

    useEffect(() => {
        const unlisten = userModule.listen(setSession);
        return () => unlisten();
    }, [userModule]);

    const data = useMemo(() => {
        setError(userModule.getAuthSessionErrors());
        if (userModule.getAuthSession()) {
            return new AuthSessionModel(userModule.getAuthSession());
        } else {
            return undefined;
        }
    }, [session]);

    return { data, error, isFetching: Boolean(userModule) };
};
