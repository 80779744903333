import { useCallback, useContext, useEffect, useState } from 'react';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { getBgMargin } from '@/components/shared/BackgroundImage/get-bg-margin';
import { CatalogConfig } from '@/components/views/common/types';
import { BackgroundContext } from '@/contexts/background/background.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { getBackgroundImage } from '@/helpers/get-background-image.helper';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { LIST_TYPE } from '@/types/recommendation-list.type';

export const useCatalogActiveItem = (config: CatalogConfig, noContent: boolean) => {
    const { withSubcategory, disableBg } = config;

    const { setBcgSrc, setShadeMode, setBcgMargin } = useContext(BackgroundContext);
    const [activeItem, setActiveItem] = useState<ListElementInterface>();
    const [gridTitle, setGridTitle] = useState<string>();

    useEffect(() => {
        setBcgMargin(getBgMargin(LIST_TYPE.SIMPLE));
    }, []);

    useEffect(() => {
        if (noContent) {
            setBcgSrc('');
        }
    }, [noContent]);

    const handleActiveItem = useCallback((item: ListElementInterface) => {
        if (item) {
            batchedUpdates(() => {
                setActiveItem(item);

                const backgroundSrc = getBackgroundImage(item, disableBg);
                setBcgSrc(backgroundSrc);
                setShadeMode(SHADE_MODE.BIG);

                if (withSubcategory) {
                    const subcategoryName = item.getAsset().getSubcategoryName?.();
                    setGridTitle(subcategoryName);
                }
            });
        }
    }, []);

    return {
        activeItem,
        gridTitle,
        handleActiveItem,
    };
};
