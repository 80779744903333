import { navVertical } from 'nav-tree';
import React from 'react';

import { useFocusCell } from '@/hooks/epg-table/use-focus-cell.hook';

import { SingleCell } from './ProgramList/SingleCell';
import * as Styled from './styles';
import { CellsPresentationProps, CellsProps } from './types';
import { ChannelFallback } from './ChannelFallback';
import { COL_WIDTH } from '@/constants/epg-table';
import { ProgramList } from './ProgramList';

const CellsRaw = ({
    channelId,
    channelName,
    colIndex,
    onGoToWatch,
    programs,
    timeShiftingDuration,
    hasNoData,
}: CellsProps) => {
    //* Tu odbywa się fokusowanie odpowiednich komórek
    const { navRef } = useFocusCell(colIndex, channelId);

    return (
        <CellsPresentation
            channelId={channelId}
            channelName={channelName}
            hasNoData={hasNoData}
            navRef={navRef}
            onGoToWatch={onGoToWatch}
            programs={programs}
            timeShiftingDuration={timeShiftingDuration}
        />
    );
};

const CellsPresentationRaw = ({
    channelId,
    channelName,
    hasNoData,
    navRef,
    onGoToWatch,
    programs,
    timeShiftingDuration,
}: CellsPresentationProps) => {
    const renderContent = () => {
        if (hasNoData) {
            return <ChannelFallback />;
        }

        if (!programs.length) {
            return (
                <SingleCell
                    cellsInCell={0}
                    channelName={channelName}
                    onGoToWatch={onGoToWatch}
                    height={`${COL_WIDTH}rem`}
                    timeShiftingDuration={timeShiftingDuration}
                />
            );
        }

        return (
            <ProgramList
                programs={programs}
                channelName={channelName}
                onGoToWatch={onGoToWatch}
                timeShiftingDuration={timeShiftingDuration}
            />
        );
    };

    return (
        <Styled.Column func={navVertical} id={channelId} navId={channelId} ref={navRef}>
            {renderContent()}
        </Styled.Column>
    );
};

const CellsPresentation = React.memo(CellsPresentationRaw);

export const Cells = React.memo(CellsRaw);
