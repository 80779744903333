import styled from 'styled-components';
import { PlayableDynamicButton } from '../PlayableButton/PlayableDynamicButton';
import { Z_INDEX } from '@/targets/default/theme';

export const CloseDynamicButton = styled(PlayableDynamicButton)<{
    fixed?: boolean;
}>`
    position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
    right: 5rem;
    z-index: ${Z_INDEX.CLOSE_DYNAMIC_BUTTON};
    top: 5rem;
`;
