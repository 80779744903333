import json from '@/public/assets/shared/pg_to_pbg_flat.json';

const MIGRATION_DOMAIN_URL = 'https://polsatboxgo.pl';

export interface GoodbyeRouteParams {
    asPath: string;
    categoryId: string;
    eventTitle: string;
    eventId: string;
    channelTitle: string;
    channelId: string;
    vodCategoryTitle: string;
    mainCategory: string;
    seasonId: string;
    seasonName: string;
    vodCategoryId: string;
    vodId: string;
    vodTitle: string;
    title: string;
}

export function rewriteCategory(categoryId: string) {
    // [pb_cat_name, pb_cat_id, pb_cat_path, pb_cat_path_name, pbg_cat_id, pbg_cat_path, pbg_cat_path_name]
    // ["2XL", 5024687, [5024023, 5024024, 5024687], ["PG", "Seriale", "2XL"], 5028684, [5023974, 5024044, 5028684], ["PBG", "Seriale", "2XL"]]
    const entry = json.find((entry) => {
        return String(entry[1]) === String(categoryId);
    });

    return entry ? entry[4] : categoryId;
}

export function buildVodUrl(routeParams: GoodbyeRouteParams): string {
    const id = routeParams.vodId;
    const mainCategory = routeParams.mainCategory;
    const categoryTitle = routeParams.vodCategoryTitle;
    const categoryId = rewriteCategory(routeParams.vodCategoryId);
    const title = routeParams.vodTitle;
    const seasonName = routeParams.seasonName;
    const seasonId = routeParams.seasonId;

    return seasonName && seasonId
        ? `${MIGRATION_DOMAIN_URL}/wideo/${mainCategory}/${categoryTitle}/${categoryId}/${seasonName}/${seasonId}/${title}/${id}`
        : `${MIGRATION_DOMAIN_URL}/wideo/${mainCategory}/${categoryTitle}/${categoryId}/${title}/${id}`;
}

export function buildCategoryUrl(routeParams: GoodbyeRouteParams): string {
    const matched = routeParams.asPath.match(/(\/autoplay[?/]?.*)/i);
    const autoplaySuffix = matched && matched[1];
    return `${MIGRATION_DOMAIN_URL}/wideo/${routeParams.vodCategoryTitle}/${rewriteCategory(
        routeParams.categoryId,
    )}${autoplaySuffix}`;
}

export function buildUrl(routeParams: GoodbyeRouteParams): string {
    if (routeParams.vodCategoryId) {
        return buildVodUrl(routeParams);
    }

    if (routeParams.categoryId) {
        return buildCategoryUrl(routeParams);
    }

    return `${MIGRATION_DOMAIN_URL}${routeParams.asPath}`;
}
