import useLogin from '@/api/auth/use-login';
import { isWeb } from '@/constants/portal-recognition';
import { useAppContext } from '@/contexts/app/app.context';
import { PageContext } from '@/contexts/page/page.context';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useCallback, useContext, useEffect, useState } from 'react';

export const useTriggerAutoLogin = () => {
    const { clearContext } = useContext(PageContext);
    const { setAutoLogin } = useAppContext();
    const userModule = useUserModule();

    useEffect(() => {
        if (isWeb) return;

        const isLogged = userModule.isLogged();
        const isCredentialsExists = userModule.isCredentialsExists();

        if (!isLogged && isCredentialsExists) {
            setAutoLogin(true);
            clearContext();
        }
    }, [userModule, clearContext]);
};

export const useAutoLoginRequest = () => {
    const [autoLogin, setAutoLogin] = useState<boolean>(false);
    const userModule = useUserModule();

    const [login] = useLogin();

    const handleAutoLogin = useCallback(async () => {
        if (!autoLogin) {
            return;
        }

        const credentials = userModule.getCredentials();

        if (credentials) {
            const result = await login(credentials);

            if (!result.ok) {
                userModule.clearAuthSession();
                userModule.clearCredentials();
            }
        }

        setAutoLogin(false);
    }, [userModule, autoLogin]);

    useEffect(() => {
        handleAutoLogin();
    }, [handleAutoLogin]);

    return { autoLogin, setAutoLogin };
};
