import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { StyledCenteredContainer } from './styles';

export const MyPurchasesNoContent = () => {
    return (
        <StyledCenteredContainer>
            <Message
                title={t('pages.account.my-purchases.no-data.title')}
                description={t('pages.account.my-purchases.no-data.description')}
                gutter={GUTTER.NONE}
                data-testing="pages.account.my-purchases.no-data.title"
            />
        </StyledCenteredContainer>
    );
};
