import React, { useState } from 'react';
import { PlayableDynamicButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { IEditProfileButton } from './types';
import { EditProfileButtonWrapper } from './styles';

export const EditProfileButton = ({ onEditProfile }: IEditProfileButton) => {
    const [active, setActive] = useState<boolean>(false);

    return (
        <EditProfileButtonWrapper active={active}>
            <PlayableDynamicButton
                icon={ICONS.PENCIL}
                text={t('button.profile.edit-profile')}
                onButtonAction={onEditProfile}
                onNav={setActive}
                style={{ display: 'inline-block' }}
            />
        </EditProfileButtonWrapper>
    );
};
