import { BUTTON_ID } from '@/types/button-id.type';
import { Nav, NavTree } from 'nav-tree';
import { RefObject } from 'react';

const findAndFocusNode = (node: NavTree, focusId: BUTTON_ID) => {
    for (const key in node.nodes) {
        const childNode = node.nodes[key];

        if (childNode.id === focusId) {
            childNode.focus();
            return;
        }

        findAndFocusNode(childNode, focusId);
    }
};

export const focusNavId = (navTree: NavTree, focusId: BUTTON_ID) => {
    if (navTree.parent) {
        focusNavId(navTree.parent, focusId);
        return;
    }

    findAndFocusNode(navTree, focusId);
};

export const handleNavFocus = (id: BUTTON_ID, focusRef?: RefObject<Nav>) => {
    const tree: NavTree | undefined = focusRef?.current?.getChildContext().tree;
    tree && focusNavId(tree, id);
};
