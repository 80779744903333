import useGetAllRules from '@/api/auth/use-get-all-rules';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetAvatarsOut } from '@/interfaces/from-schemas/system/getAvatarsOut';
import { AvatarModel } from '@/models/avatar.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.SYSTEM, GET_MEDIA_METHODS.FETCH_AVATARS, {});

export const useGetAvatars = (): IApiResponse<AvatarModel[]> => {
    const { data, error, isFetching } = useGetMediaApi(keyHook);

    return {
        data:
            data &&
            data.map((avatar: GetAvatarsOut) => {
                return new AvatarModel(avatar);
            }),
        error,
        isFetching,
    };
};

export const getAvatarsPromise = (getMediaApiPromise: GetMediaPromiseFunc) => {
    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export default useGetAllRules;
