import styled from 'styled-components';

export const Separator = styled.div`
    background-color: ${({ theme }) => theme.text.color.metadata};
    width: 1px;
    height: 17rem;
    display: flex;
`;

export const SeparatorText = styled.div`
    color: ${({ theme }) => theme.text.color.metadata};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-indent: 1rem;
    letter-spacing: 1rem;
    width: 9rem;
    height: 12rem;
`;

export const SeparatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
