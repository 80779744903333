import { InputFilterSchema } from '@/interfaces/filters-interface';

export interface EPGParamsInterface {
    offset: number;
    filters: InputFilterSchema[];
}

export const updateEPGParams = ({
    params,
    total,
    page,
}: {
    params: EPGParamsInterface;
    total: number;
    page?: number;
}) => {
    const { offset } = params;
    let newOffset = 0;

    if (page) {
        newOffset = (page - 1) * total;
    } else {
        newOffset = offset;
    }

    return {
        ...params,
        offset: newOffset,
    };
};
