import { useWalkingEPG } from '@/hooks/player/use-walking-epg';
import { EPGCandidateSliderProps } from './types';
import { isNowPlaying, isPlayable } from './helpers';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import React, { useCallback, useEffect } from 'react';
import { useThrottledNumerical } from '@/hooks/use-throttled-numerical.hook';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';
import { EPGSliderPresentation } from './EPGSliderPresentation';

export const EPGCandidateSlider = ({
    channel,
    navId = 'epgCandidateSlider',
    onNav,
    onBuyChannel,
    setIsProgramTv,
}: EPGCandidateSliderProps) => {
    const isTimeshiftingSupported = useTimeshiftingSupported();
    const currentTimestamp = useThrottledNumerical(Math.round(new Date().getTime()));
    const timeshiftingDuration = channel.getTimeShiftingDuration();

    const { programs, isFetching } = useWalkingEPG({
        channelId: channel.getId(),
        count: Infinity,
        refTime: isTimeshiftingSupported
            ? currentTimestamp - channel.getTimeShiftingDuration() * 1000
            : undefined,
    });

    useEffect(() => {
        !isFetching && setIsProgramTv(!!programs.length);
    }, [programs, isFetching]);

    const onJump = useCallback(
        (program: ChannelProgramTvModel) => () => {
            const playable = isPlayable(program, timeshiftingDuration);

            if (!playable) return;

            const timestamp = program.getStartTimeTimestamp() / 1000;
            if (channel.getIsLocked()) {
                onBuyChannel(channel);
            } else {
                channel.goToWatch(true, timestamp);
            }
        },
        [channel, onBuyChannel, timeshiftingDuration],
    );

    const onIsPlayable = useCallback(
        (program: ChannelProgramTvModel) => isPlayable(program, timeshiftingDuration),
        [timeshiftingDuration],
    );

    const onIsNowPlaying = useCallback(
        (program: ChannelProgramTvModel, index: number) =>
            !channel.getIsLocked() &&
            isNowPlaying(program, index, currentTimestamp, isTimeshiftingSupported),
        [channel, currentTimestamp, isTimeshiftingSupported],
    );

    return (
        <EPGSliderPresentation
            programs={programs}
            loading={isFetching}
            navId={navId}
            onNav={onNav}
            onJump={onJump}
            onIsPlayable={onIsPlayable}
            onIsNowPlaying={onIsNowPlaying}
        />
    );
};
