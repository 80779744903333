import { EPGTable } from '@/components/shared/EPGTable';
import { DayPickerSideMenu } from '@/components/shared/EPGTable/DayPickerSideMenu';
import { FilterList } from '@/components/shared/Filter/FilterList';
import { NavigationButtons, NoDataMessage } from '@/components/views/common';
import { NavigationButtonsWrapper } from '@/components/views/common/NavigationButtonsWrapper';
import { StyledMainNavigation } from '@/components/views/common/styles';
import { useEPGTableState } from '@/hooks/epg-table/use-epg-table.hook';
import { ChannelModel } from '@/models/channels/channel.model';
import { navVertical } from 'nav-tree';
import React from 'react';

interface EPGTableViewOwnprops {
    withoutButton?: boolean;
    onCategoryView?: () => void;
}

const EPGTableViewRaw = ({ onCategoryView, withoutButton }: EPGTableViewOwnprops) => {
    const {
        allChannels,
        filterLists,
        filtersOpen,
        focusRef,
        handleFiltersOpen,
        handleOnFilterAction,
        isFetching,
        isMagicMouseVisible,
    } = useEPGTableState();

    const noData = allChannels?.length === 0 && !isFetching;
    const shouldRenderTable = allChannels && allChannels.length !== 0;

    return (
        <>
            <StyledMainNavigation
                func={navVertical}
                autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
                restoreLastFocus
            >
                {noData && <NoDataMessage />}

                <NavigationButtonsWrapper>
                    <NavigationButtons
                        onCategoryView={onCategoryView}
                        onShowFilters={handleFiltersOpen}
                        withFilters={!!filterLists.length}
                        withoutButton={withoutButton}
                    />
                </NavigationButtonsWrapper>

                {shouldRenderTable && (
                    <EPGTable
                        allChannels={allChannels as ChannelModel[]}
                        focusRef={focusRef}
                        isFetching={!!isFetching}
                    />
                )}
            </StyledMainNavigation>
            <DayPickerSideMenu focusRef={focusRef} />
            {filtersOpen && (
                <FilterList
                    data={filterLists}
                    onAction={handleOnFilterAction}
                    onShowFilters={handleFiltersOpen}
                />
            )}
        </>
    );
};

export const EPGTableView = React.memo(EPGTableViewRaw);
