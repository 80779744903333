import React, { createContext, useEffect, useState } from 'react';
import { AuthContextProviderProps } from './types';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useGetSession } from '@/api/auth/use-get-session';
import { GetSessionOut } from '@/interfaces/from-schemas/auth/getSessionOut';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { getUserAgentData } from '@/services/user-agent-data.service';
import { getUserAgent } from '@/services/user-agent.service';
import { AuthContainer } from '@/services/get-media/types';
import { useDeviceId } from './auth.hooks';

// Ten auth context jest używany m.in. do wygenerowania statytcznie optymalizowanych stron
export const RPCAuthContext = createContext({} as AuthContainer);
export const SessionAuthContext = createContext<GetSessionOut | undefined>(undefined);

const SessionAuthProvider = ({ children }: { children: React.ReactNode }) => {
    const userModule = useUserModule();
    const configuration = useConfiguration();
    const { data: session, error } = useGetSession(!!configuration);

    useEffect(() => {
        if (session) {
            userModule.setAuthSession(session);
            userModule.setAuthSessionErrors(undefined);
        }
        if (error) {
            userModule.setAuthSession(undefined);
            userModule.setAuthSessionErrors(error);
        }
    }, [session, error]);

    return <SessionAuthContext.Provider value={session}>{children}</SessionAuthContext.Provider>;
};

const RPCAuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const userModule = useUserModule();
    const { platform } = usePlatform();
    const configuration = useConfiguration();
    const { deviceId: fingerprintDeviceId } = useDeviceId();

    const [auth, setAuth] = useState<AuthContainer>(() => {
        return {
            ua: getUserAgent(configuration),
            clientId: userModule.getClientId(),
            deviceId: userModule.getDeviceIdIdent(),
            rpcSessionData: userModule.getSession(),
            userAgentData: getUserAgentData(platform),
        };
    });

    useEffect(() => {
        setAuth((prev) => {
            const ua = getUserAgent(configuration);
            const userAgentData = getUserAgentData(platform);
            const deviceId = userModule.getDeviceIdIdent();

            // optymalizacja, żeby nie nadpisywało obiektu jeśli ua lub os się nie zmieniło
            const rewrite =
                prev.ua !== ua ||
                prev.userAgentData.os !== userAgentData.os ||
                !prev.deviceId.value;

            return rewrite ? { ...prev, ua, userAgentData, deviceId } : prev;
        });
    }, [platform, configuration, setAuth, fingerprintDeviceId, userModule]);

    useEffect(() => {
        const unlisten = userModule.listen((data) => {
            setAuth((auth) => ({
                ...auth,
                rpcSessionData: data,
                deviceId: userModule.getDeviceIdIdent(),
                userAgentData: getUserAgentData(platform),
            }));
        });
        return unlisten;
    }, [userModule, platform, setAuth]);

    return (
        <RPCAuthContext.Provider value={auth}>
            <SessionAuthProvider>{children}</SessionAuthProvider>
        </RPCAuthContext.Provider>
    );
};

export default RPCAuthContextProvider;
