export enum DATA_POSITION {
    UNDER = 'under',
    INSIDE = 'inside',
    TOP_CENTER = 'top-center',
    HIDDEN = 'hidden',
}

export enum DISPLAY_DATA {
    ONLY_TITLE = 'only-title',
    ONLY_METADATA = 'only-metadata',
    BOTH = 'both',
}

export enum TITLE_TYPE {
    OWN = 'own',
    SHORTENED = 'shortened',
    FULL = 'full',
}
