import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { DeleteProfileOut } from '@/interfaces/from-schemas/auth/deleteProfileOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseDeleteProfile {
    profileId: string;
}

const useDeleteProfile = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.DELETE_PROFILE,
        null,
    );

    return useGetMediaApiMutation<IUseDeleteProfile, DeleteProfileOut>(keyHook, {
        appendAuthData: true,
        invalidate: [[GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.GET_PROFILES]],
    });
};

export default useDeleteProfile;
