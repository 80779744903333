import styled from 'styled-components';

import { FillIconProps } from './types';

const fillIcon = (props: FillIconProps) => {
    switch (props.ageGroup) {
        case 0:
            return props.theme.tvRating.ageGroup0;
        case 7:
            return props.theme.tvRating.ageGroup7;
        case 12:
            return props.theme.tvRating.ageGroup12;
        case 16:
            return props.theme.tvRating.ageGroup16;
        case 18:
            return props.theme.tvRating.ageGroup18;
    }
};

export const RatingIconWrapper = styled.span<FillIconProps>`
    fill: ${(props) => props && fillIcon(props)};
    font-size: 0;
    svg {
        width: auto;
        height: ${({ $height }) => ($height ? `${$height}rem` : '1.8rem')};
    }
`;
