import { useSessionStorage } from '@/hooks/use-session-storage.hook';
import { SESSION_STORAGE_KEYS } from '@/types/storage.type';
import { createContext } from 'react';
import { PaymentsContextProps, PaymentsContextProviderProps } from './types';

export const PaymentsDataContext = createContext<PaymentsContextProps>({
    paymentsData: undefined,
    setPaymentsData: () => undefined,
});

export const PaymentsContextProvider = ({ children }: PaymentsContextProviderProps) => {
    const [paymentsData, setPaymentsData] = useSessionStorage(SESSION_STORAGE_KEYS.PAYMENTS_DATA);

    return (
        <PaymentsDataContext.Provider value={{ paymentsData, setPaymentsData }}>
            {children}
        </PaymentsDataContext.Provider>
    );
};
