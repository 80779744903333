import { ActivityEventsConfig, ActivityEventsUpdateHandlerParams, StatsConfig } from '../types';
import HandlersManager from '../modules/handlers-manager';
import { initActivityEvents } from './activity-events';
import { AsyncEvent } from 'ts-events';

export default class Clients {
    constructor(
        handlersManager: HandlersManager,
        config: StatsConfig,
        onParamsUpdate: AsyncEvent<ActivityEventsUpdateHandlerParams>,
    ) {
        if (config.activityevents) {
            initActivityEvents(handlersManager, config as ActivityEventsConfig, onParamsUpdate);
        }
    }
}
