import { GetCategoryContentWithFlatNavigationOut } from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationOut';
import CategoryContentModel from './category-content.model';

class CategoryContentWithFlatNavigationModel extends CategoryContentModel {
    private readonly flatNavigation: GetCategoryContentWithFlatNavigationOut['flatNavigation'] = {};

    constructor(definition: GetCategoryContentWithFlatNavigationOut) {
        super(definition);
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getFlatNavigation(): GetCategoryContentWithFlatNavigationOut['flatNavigation'] {
        return this.flatNavigation;
    }
}

export default CategoryContentWithFlatNavigationModel;
