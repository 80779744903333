import { ICONS } from '@/components/shared/Icon';
import { ProcessingLoader } from '@/components/shared/Loader';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { AbsolutePositionDynamicButton } from '@/components/shared/ScrollableContainer/styles';
import { PageContainer } from '@/components/views/Account/styles';
import { PurchaseOptionRuleListItem } from '@/components/views/Purchase/Rules/PurchaseOptionRuleListItem';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { BUTTON_ID } from '@/types/button-id.type';
import { GUTTER } from '@/types/gutter.type';
import { NAV_SCROLL_DIRECTION, navVertical } from 'nav-tree';
import React from 'react';
import { PurchaseOptionRuleHeader, PurchaseOptionRuleListNav } from './styles';
import { PurchaseOptionRuleListProps } from './types';

export const PurchaseOptionRuleList = ({ rules, onSelect }: PurchaseOptionRuleListProps) => {
    return (
        <PageContainer func={navVertical}>
            <AbsolutePositionDynamicButton
                navId={BUTTON_ID.BACK}
                icon={ICONS.ARROW_LEFT}
                style={{ top: '5rem' }}
                text={t('back-button-label')}
                onButtonAction={() => {
                    Router.back();
                }}
            />
            <PurchaseOptionRuleListNav
                func={navVertical}
                scrollOptions={{
                    isScrollable: true,
                    direction: NAV_SCROLL_DIRECTION.VERTICAL,
                }}
            >
                <PurchaseOptionRuleHeader>
                    <MainText
                        text={t('pages.purchase.rules.header')}
                        mode={MAINTEXT_KIND.PAGE_SUBTITLE}
                        gutter={GUTTER.NONE}
                    />
                </PurchaseOptionRuleHeader>

                {rules?.map((rule, index) => (
                    <PurchaseOptionRuleListItem
                        key={rule.getId()}
                        rule={rule}
                        onSelect={onSelect}
                        defaultFocused={index === 0}
                    />
                ))}
            </PurchaseOptionRuleListNav>
            <ProcessingLoader isFetching={!rules} />
        </PageContainer>
    );
};
