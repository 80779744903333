import React from 'react';
import { PageContainer } from '@/components/shared/FullscreenNavContainer';
import { NavWrapper } from '@/components/views/Account/styles';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { navVertical } from 'nav-tree';
import { PlayableButton } from '@/components/shared/Buttons';
import { LoadingWrapper } from '@/components/shared/Loader';
import useActivateCode from '@/api/payments/use-activate-code';
import { IActivateCodeProps, ICodeDetails } from '@/components/views/Account/Code/types';
import useCheckCode from '@/api/payments/use-check-code';
import useGetMedia from '@/api/navigation/use-get-media';
import { redirectToProductDetails } from '@/helpers/product.helper';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';

const ActivateCode = ({ purchaseCode, goBack }: IActivateCodeProps) => {
    const { error, isFetching: isFetchingActivateCode } = useActivateCode({ purchaseCode });

    const { data: data, isFetching: isFetchingCheckCode } = useCheckCode({
        purchaseCode,
    });

    const mediaParams = data?.content
        ? {
              cpid: data.content.cpid as any,
              mediaId: data.content.id,
          }
        : undefined;

    const { data: media, isFetching: isMediaFetching } = useGetMedia(mediaParams);

    const isFetching = !!isFetchingActivateCode || !!isMediaFetching || !!isFetchingCheckCode;

    const onButtonAction = () => {
        if (data) {
            return redirectToProductDetails(
                {
                    id: data.id,
                    subType: data.subType as PRODUCT_SUBTYPES,
                    type: data.type as PRODUCT_TYPES,
                },
                media,
            );
        }
    };

    const renderSuccessPage = (offer?: ICodeDetails) => {
        if (offer) {
            return (
                <NavWrapper func={navVertical}>
                    <Message
                        icon={ICONS.INFO_CIRCLE}
                        title={t('pages.activate-code.title-success')}
                        description={t('pages.activate-code.success-description-1')}
                    />
                    <PlayableButton
                        onButtonAction={onButtonAction}
                        text={t('ok-button-label')}
                        defaultFocused
                    />
                    <Message description={error?.getUserMessage()} />
                </NavWrapper>
            );
        }
    };

    const renderError = (title?: string) => (
        <PageContainer>
            <Message icon={ICONS.INFO_CIRCLE} title={title} />
            <NavWrapper func={navVertical}>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={goBack}
                    defaultFocused
                />
            </NavWrapper>
        </PageContainer>
    );

    return (
        <PageContainer>
            <LoadingWrapper
                isFetching={isFetching}
                text={t('pages.have-code.verification')}
                calculateBottom={false}
            >
                {isFetching
                    ? null
                    : error
                    ? renderError(error.getUserMessage())
                    : renderSuccessPage(data)}
            </LoadingWrapper>
        </PageContainer>
    );
};

export default ActivateCode;
