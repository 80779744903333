import { filterOffer } from '@/helpers/payment.helper';
import { PrePurchaseProductSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { RulesModel } from '../products/rules.model';
import { PrePurchaseOfferModel } from './pre-purchase-offer.model';

export class PrePurchaseProductModel {
    private id: string;
    private type: PRODUCT_TYPES;
    private subType: PRODUCT_SUBTYPES;
    private name: string;
    private offers: PrePurchaseOfferModel[] = [];
    private minPrice: PrePurchaseProductSchema['minPrice'];
    private minPriceIncludingPricingPlans: PrePurchaseProductSchema['minPriceIncludingPricingPlans'];
    private accessText?: string;
    private rules: RulesModel[] = [];

    constructor(
        definition: PrePurchaseProductSchema,
        isPurchaseAvailable?: boolean,
        isUpgradeAvailable?: boolean,
    ) {
        this.id = definition.id;
        this.type = definition.type as PRODUCT_TYPES;
        this.subType = definition.subType as PRODUCT_SUBTYPES;
        this.name = definition.name;
        this.minPrice = definition.minPrice;
        this.minPriceIncludingPricingPlans = definition.minPriceIncludingPricingPlans;

        if (definition.offers) {
            const allOffers = definition.offers.map((offer) => new PrePurchaseOfferModel(offer));
            this.offers = allOffers.filter((offer) =>
                filterOffer(offer, isPurchaseAvailable, isUpgradeAvailable),
            );
        }

        if (definition.minPriceOffers && definition.offers) {
            const { id: minPriceOfferId, type: minPriceOfferType } = definition.minPriceOffers[0];
            const minPriceOffer = definition.offers.find(
                ({ id, type }) => id === minPriceOfferId && type === minPriceOfferType,
            );
            this.accessText = minPriceOffer?.accessText;
        }

        if (definition.rules) {
            this.rules = definition.rules.map((rules) => new RulesModel(rules));
        }
    }

    public getId(): string {
        return this.id;
    }

    public getProductId(): ProductIdInterface {
        return {
            id: this.id,
            type: this.type,
            subType: this.subType,
        };
    }

    public getName(): string {
        return this.name;
    }

    public getOffers(): PrePurchaseOfferModel[] {
        return this.offers;
    }

    public findOffer(offerId?: string): PrePurchaseOfferModel | undefined {
        return this.offers.find((offer) => offer.getId() === offerId);
    }

    public getMinPriceText(): string | undefined {
        return this.minPrice?.valueText;
    }
    public getHigherPricesExist(): boolean | undefined {
        return this.minPrice?.higherPricesExist;
    }

    public getRegularPriceText(): string | undefined {
        return this.minPriceIncludingPricingPlans?.valueText;
    }

    public getMinPriceTextIncludingPricingPlans(): string | undefined {
        return this.minPriceIncludingPricingPlans?.valueTextIncludingPricingPlans;
    }

    public getPriceLabel(): string | undefined {
        const pricingPlanLabel = this.getMinPriceTextIncludingPricingPlans();
        const defaultLabel = this.getMinPriceText();

        return pricingPlanLabel || defaultLabel;
    }

    public getOldPriceLabel(): string | undefined {
        const pricingPlanLabel = this.getMinPriceTextIncludingPricingPlans();
        const defaultLabel = this.getMinPriceText();

        return pricingPlanLabel ? defaultLabel : undefined;
    }

    public getAccessText(): string | undefined {
        return this.accessText;
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }
}
