export enum DeviceSTBModelName {
    STB_D30_EVOBOX_PVR = 'CP-39',
    STB_D46_EVOBOX_IP = 'CP-41',
    STB_D47_EVOBOX_HD = 'CP-42',
    STB_D56_EVOBOX_LITE = 'CP-43',
    STB_D46BU_EVOBOX_STREAM = 'CP-45',
    STB_D46CU_EVOBOX_STREAM = 'CP-46',
    STB_D46BP_EVOBOX_NETIA = 'CP-47',
    STB_D61AW_IRDETO_EVOBOX_4K = 'CP-48',
    STB_D61AW_NAGRA_EVOBOX_4K = 'CP-49',
    STB_D46BU_WO_DVBT_EVOBOX_STREAM = 'CP-50',
    STB_D46DU_EVOBOX_STREAM = 'CP-51',
    STB_D61BW_IRDETO_EVOBOX_4K = 'CP-52',
    STB_D61BW_NAGRA_EVOBOX_4K = 'CP-53',
}

export enum GMDeviceUserAgent {
    STB_DEFAULT = 'cpgo_stb/1',
    STB_D30_EVOBOX_PVR = 'cpgo_stb_html_cp38/1',
    STB_D46_EVOBOX_IP = 'cpgo_stb_html_cp41/1',
    STB_D47_EVOBOX_HD = 'cpgo_stb_html_cp42/1',
    STB_D56_EVOBOX_LITE = 'cpgo_stb_html_cp43/1',
    STB_D46BU_EVOBOX_STREAM = 'cpgo_stb_html_cp45/1',
    STB_D46CU_EVOBOX_STREAM = 'cpgo_stb_html_cp46/1',
    STB_D46BP_EVOBOX_NETIA = 'cpgo_stb_html_cp47/1',
    STB_D61AW_IRDETO_EVOBOX_4K = 'cpgo_stb_html_cp48/1',
    STB_D61AW_NAGRA_EVOBOX_4K = 'cpgo_stb_html_cp49/1',
    STB_D46BU_WO_DVBT_EVOBOX_STREAM = 'cpgo_stb_html_cp50/1',
    STB_D46DU_EVOBOX_STREAM = 'cpgo_stb_html_cp51/1',
    STB_D61BW_IRDETO_EVOBOX_4K = 'cpgo_stb_html_cp52/1',
    STB_D61BW_NAGRA_EVOBOX_4K = 'cpgo_stb_html_cp53/1',
}

export enum STBDRMType {
    IRDETO = 'irdeto',
    NAGRA = 'nagra',
    NAGRA2 = 'nagra2',
}
