import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { UpdateProfile_2_0Out } from '@/interfaces/from-schemas/auth/updateProfile_2_0Out';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseUpdateProfile {
    profileId: string;
    name: string;
    avatarId: string;
}

const useUpdateProfile = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH_2_0,
        GET_MEDIA_METHODS.UPDATE_PROFILE,
        null,
    );

    return useGetMediaApiMutation<IUseUpdateProfile, UpdateProfile_2_0Out>(keyHook, {
        appendAuthData: true,
        invalidate: [[GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.GET_PROFILES]],
    });
};

export default useUpdateProfile;
