import { ICONS } from '@/components/shared/Icon';
import { TimerButton } from '@/components/shared/TimerButton';
import t from '@/lib/i18n';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlayNextButtonProps } from './types';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import React from 'react';
import { PlayNextButtonContainer } from './styles';
import { CloseButton } from './CloseButton';
import ReactDOM from 'react-dom';

const DEFAULT_TIME = 10; // wartość w sekundach

export const PlayNextButton = ({
    time = DEFAULT_TIME,
    isBottomBarVisible,
}: PlayNextButtonProps) => {
    const [userClose, setUserClose] = useState(false);
    const instance = usePlayerInstance();
    const { isReadyToPlayNext } = usePlayerStatus();

    useEffect(() => {
        if (isReadyToPlayNext) {
            setUserClose(false);
        }
    }, [isReadyToPlayNext]);

    const action = useCallback(() => {
        if (!userClose) {
            instance.user.playNext();
        }
    }, [instance, userClose]);

    const TimerButtonMemo = useMemo(
        () =>
            ReactDOM.createPortal(
                <PlayNextButtonContainer isBottomBarVisible={isBottomBarVisible}>
                    <CloseButton onButtonAction={() => setUserClose(true)} />
                    <TimerButton
                        time={time}
                        icon={ICONS.FORWARD}
                        permamentText={t('player.play-next')}
                        actionOnTimeout={action}
                        onClick={action}
                    />
                </PlayNextButtonContainer>,
                document.getElementById('root') as HTMLElement,
            ),
        [time, action, isBottomBarVisible],
    );

    return isReadyToPlayNext && !userClose ? TimerButtonMemo : null;
};
