import React from 'react';
import { useState, useEffect } from 'react';
import { StyledLoader, StyledText, StyledDescription, ContentLayoutShiftPreventer } from './style';
import { IProcessingLoaderProps } from './types';
import { Dots } from './Dots';
import { GUTTER } from '@/types/gutter.type';

const ProcessingLoaderRaw = ({
    isFetching,
    text,
    highlight,
    description,
    noDelay,
    gutter = GUTTER.NONE,
    inline,
    fullScreen,
}: IProcessingLoaderProps) => {
    const { dots, delay } = process.env.loader.processing;

    const [waited, setWaited] = useState<boolean>(noDelay || false);
    const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
    const renderLoader = waited && isFetching;
    const visible = noDelay ? true : isLoaderVisible;

    useEffect(() => {
        if (noDelay) return setWaited(true);

        if (isFetching && !noDelay) {
            const timer = setTimeout(() => {
                setWaited(true);
            }, delay);

            return () => {
                clearTimeout(timer);
                setWaited(false);
            };
        }
    }, [isFetching, delay]);

    useEffect(() => {
        if (renderLoader) {
            setIsLoaderVisible(true);
        } else {
            setIsLoaderVisible(false);
        }
    }, [renderLoader]);

    if (renderLoader) {
        return (
            <>
                {fullScreen && <ContentLayoutShiftPreventer />}
                <StyledLoader
                    gutter={gutter}
                    data-testing="processing-loader"
                    visible={visible}
                    noDelay={noDelay}
                    inline={inline}
                >
                    <Dots dots={dots} />
                    {text && <StyledText highlight={highlight}>{text}</StyledText>}
                    {description && <StyledDescription>{description}</StyledDescription>}
                </StyledLoader>
            </>
        );
    }

    return null;
};

export const ProcessingLoader = React.memo(ProcessingLoaderRaw);
