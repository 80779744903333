import styled from 'styled-components';
import { Z_INDEX } from '@/targets/default/theme';
export const FBContainer = styled.div`
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    z-index: ${Z_INDEX.SIGN_IN_CONTAINER};
    top: 50%;
    left: 50%;
    width: 70%;
    height: 70%;
    background-color: ${({ theme }) => theme.facebook.background.container};
    padding: 0 0 4.8rem;
    color: ${({ theme }) => theme.facebook.textColor};
    border-radius: 0.8rem;
`;

export const FBCodeContainer = styled.div`
    height: 20rem;
    background-color: ${({ theme }) => theme.facebook.background.code};
    color: ${({ theme }) => theme.facebook.primaryColor};
    text-align: center;
    font-size: 10rem;
    padding: 5.6rem 0;
    display: block;
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
`;

export const FBInstructionContainer = styled.div`
    display: flex;
    margin-top: 4rem;
    padding: 0 8rem;
    flex-flow: column;
    justify-content: center;
    font-size: 3.2rem;
`;

export const FBCancelButton = styled.button`
    padding: 2rem;
    width: 30rem;
    border: none;
    background-color: ${({ theme }) => theme.facebook.background.cancelButton};
    text-transform: uppercase;
    font-size: 3.2rem;
`;

export const FBIconContainer = styled.i`
    position: absolute;
    fill: ${({ theme }) => theme.facebook.primaryColor};
    left: 2.4rem;
    top: 2.4rem;
    width: 3.2rem;
    height: 3.2rem;
`;
