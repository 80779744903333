import React, { useContext, useMemo } from 'react';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { buildFocusedIndex } from '@/helpers/nav-tree/build-focused-index.helper';
import { useLoadHorizontalRow } from './use-load-horizontal-row.hook';
import { HorizontalRow } from './HorizontalRow';
import { GridContext, HorizontalRowProps } from './types';
import { useRouter } from '@/routing';

const HorizontalRowWrapperRaw = (props: HorizontalRowProps) => {
    const { grid } = useContext(CatalogContext);
    const { asPath } = useRouter();
    const { colIndex: colIndexCtx, rowIndex: activeGridRowIndexCtx, focusMap } = grid;
    const currFocusMap = focusMap[asPath] || ['1', '1'];
    const activeIndex = buildFocusedIndex(currFocusMap, props.cols);
    const loadRow = useLoadHorizontalRow({
        focusedRowIndex: parseInt(currFocusMap[0]) - 1,
        rowIndex: props.rowIndex,
        rowCount: props.rowCount,
        isSearch: props.isSearch,
    });

    const colIndex = useMemo(() => {
        if (loadRow) {
            return colIndexCtx;
        }

        return 0;
    }, [loadRow, colIndexCtx]);

    const rowIndex = useMemo(() => {
        if (loadRow) {
            return activeGridRowIndexCtx;
        }

        return 0;
    }, [loadRow, activeGridRowIndexCtx]);

    return useMemo(
        () => (
            <GridContext.Provider value={{ loadRow, colIndex, rowIndex, activeIndex }}>
                <HorizontalRow {...props} />
            </GridContext.Provider>
        ),
        [loadRow, activeIndex, colIndex, rowIndex, props],
    );
};

export const HorizontalRowWrapper = React.memo(HorizontalRowWrapperRaw);
