import { ImageInterface } from '@/types/cover-utils.type';

const PREFERRED_ASPECT_WIDTH = 16;
const PREFERRED_ASPECT_HEIGHT = 9;
const PREFERRED_RATIO = PREFERRED_ASPECT_WIDTH / PREFERRED_ASPECT_HEIGHT;
const IMAGE_MAX_WIDTH = 1920;
const HEIGHT_TOLERANCE = 0.3;
const WIDTH_TOLERANCE = 0.1;

const getWidestImage = (collection: ImageInterface[]) =>
    collection.sort((a, b) => b.size.width - a.size.width)[0];

export function getBestImageMatch(
    collection: ImageInterface[],
    targetWidth: number,
    targetHeight = 0,
): ImageInterface {
    if (targetWidth === 0 || collection.length === 0) {
        return { type: 'jpg', src: '', size: { width: 0, height: 0 } };
    }

    const toleratedWidth = targetWidth * (1 - WIDTH_TOLERANCE);
    const toleratedHeight = targetHeight * (1 - HEIGHT_TOLERANCE);

    // Jeśli nie ma dokładnego rozmiaru – odcinamy wartości większe niż zadefiniowany MAX
    const filteredBySize = collection
        .filter((image) => image.size.width <= IMAGE_MAX_WIDTH)
        .filter((image) => image.size.height >= toleratedHeight);

    if (filteredBySize.length === 0) {
        // TODO(ksyrytczyk): Powinniśmy tutaj zaraportować błąd braku grafiki
        return getWidestImage(collection);
    }

    // W pierwszej kolejności szukamy miniatury w aspekcie 16:9
    const filteredByAspect = filteredBySize.filter((image) => {
        return (image.size.width / image.size.height).toFixed(2) === PREFERRED_RATIO.toFixed(2);
    });

    const preciseSizeImage = filteredByAspect.find((image) => image.size.width === targetWidth);

    // Z wybranych miniatur szukamy potrzebnego rozmiaru miniatury
    if (preciseSizeImage) {
        return preciseSizeImage;
    }

    // Sortujemy z grafik wg szerokości
    filteredBySize.sort((a, b) => b.size.width - a.size.width);

    // wybieramy najbliższą zadanej szerokości
    const closestImage = filteredBySize.reduce(function (prev, curr) {
        return Math.abs(curr.size.width - toleratedWidth) <
            Math.abs(prev.size.width - toleratedWidth)
            ? curr
            : prev;
    });
    const closesImageIndex = filteredBySize.findIndex(
        (image) => image.size.width === closestImage.size.width,
    );

    // najbliższa większa od zadanej
    if (closestImage.size.width >= toleratedWidth) {
        return closestImage;
    }

    return filteredBySize[closesImageIndex - 1] || closestImage;
}
