import { isStandalone } from '@/constants/portal-recognition';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import {
    AppleAuthData,
    ByCodeAuthData,
    FbAuthData,
    IPolsatBoxAuthData,
    NetiaAuthData,
    PlusAuthData,
    StbAuthData,
    UserAuthData,
} from '@/types/user-auth-data.type';

const isAppleAuthData = (data: any): data is AppleAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper = data.authProvider === AUTH_PROVIDERS.APPLE;
    const isAuthToken = typeof data.authToken === 'string';
    return isAuthProviderProper && isAuthToken;
};

const isFbAuthData = (data: any): data is FbAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper =
        !data.authProvider || data.authProvider === AUTH_PROVIDERS.FACEBOOK;

    const isAuthToken = typeof data.authToken === 'string';
    return isAuthProviderProper && isAuthToken;
};

const isIPolsatBoxAuthData = (data: any): data is IPolsatBoxAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper =
        !data.authProvider ||
        data.authProvider === AUTH_PROVIDERS.NATIVE ||
        data.authProvider === AUTH_PROVIDERS.IPOLSATBOX;

    const isLoginAndPasswordProper =
        typeof data.login === 'string' && typeof data.password === 'string';
    return isAuthProviderProper && isLoginAndPasswordProper;
};

const isNetiaAuthData = (data: any): data is NetiaAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderNetia = data.authProvider === AUTH_PROVIDERS.NETIA;
    const isLoginAndPasswordProper =
        typeof data.login === 'string' && typeof data.password === 'string';
    return isAuthProviderNetia && isLoginAndPasswordProper;
};

const isPlusAuthData = (data: any): data is PlusAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper = !data.authProvider || data.authProvider === AUTH_PROVIDERS.PLUS;

    const isMsisdnAndPasswordProper =
        typeof data.msisdn === 'string' && typeof data.password === 'string';
    return isAuthProviderProper && isMsisdnAndPasswordProper;
};

const isStbAuthData = (data: any): data is StbAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper =
        !data.authProvider ||
        data.authProvider === AUTH_PROVIDERS.NETIA ||
        data.authProvider === AUTH_PROVIDERS.IPOLSATBOX;

    const isAuthProtocolVersionProper = typeof data.authProtocolVersion === 'string';
    const isHmacProper = typeof data.hmac === 'string';
    const isTimestampProper = typeof data.timestamp === 'number';
    return isAuthProviderProper && isHmacProper && isAuthProtocolVersionProper && isTimestampProper;
};

const isByCodeAuthData = (data: any): data is ByCodeAuthData => {
    if (!data) {
        return false;
    }
    const isAuthProviderProper = !data.authProvider || data.authProvider === AUTH_PROVIDERS.NATIVE;

    const isAuthToken = typeof data.authToken === 'string';
    return isAuthProviderProper && isAuthToken;
};

export const isUserAuthData = (data: UserAuthData) =>
    isIPolsatBoxAuthData(data) ||
    isNetiaAuthData(data) ||
    isAppleAuthData(data) ||
    isFbAuthData(data) ||
    isStbAuthData(data) ||
    isPlusAuthData(data) ||
    isByCodeAuthData(data);

export const isAutoLoginSupported = (data: UserAuthData) => {
    if (!data || !isStandalone) {
        return false;
    }
    return (
        isIPolsatBoxAuthData(data) ||
        isNetiaAuthData(data) ||
        isStbAuthData(data) ||
        isPlusAuthData(data)
    );
};
