import { CheckProductAccessIn } from '@/interfaces/from-schemas/drm/checkProductAccessIn';
import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { CheckProductAccessModel } from '@/models/payments/check-product-access.model';

const namespace = GET_MEDIA_NAMESPACES.DRM;
const method = GET_MEDIA_METHODS.CHECK_PRODUCT_ACCESS;

const useCheckProductAccess = (
    params?: CheckProductAccessIn,
): IApiResponse<CheckProductAccessModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
        queryOptions: { refetchOnMount: 'always' },
    });

    return {
        data: data && new CheckProductAccessModel(data),
        isFetching,
        error,
    };
};

export default useCheckProductAccess;
