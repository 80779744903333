import styled from 'styled-components';
import { Nav } from 'nav-tree';
import { fillIcon } from '@/helpers/fill-icon.helper';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';

export const EPGTileProgressBarContainer = styled.div`
    position: relative;
    bottom: 1.2rem;
    padding: 0 3.5rem;
`;

export const EPGTimeContainer = styled.div`
    margin-bottom: 2.2rem;
`;

export const EPGDateContainer = styled.div`
    margin-bottom: 0.5rem;
`;

export const TileContainer = styled(Nav)`
    flex-shrink: 0;

    & > div:first-child {
        border-width: 0.5rem;
        border-radius: 0.5rem;
        border-style: solid;
        border-color: transparent;
        border-radius: 0.5rem;
        width: 42rem;
        height: 42rem;
        padding: 3rem;
        font-size: 2.6rem;
        overflow: hidden;
    }

    &.nav-focused > div:first-child {
        border: ${({ theme }) => theme.listElement.activeBorder};
    }
    &.nav-focused > ${EPGTileProgressBarContainer} {
        padding: 0 0.5rem;
        bottom: 0;
    }

    &:first-child {
        padding-left: 7.7rem;
    }

    &:last-child {
        padding-right: 7.7rem;
    }
`;

export const EPGDate = styled.span`
    color: ${({ theme }) => theme.text.color.metadata};
    font-size: 2rem;
`;

export const EPGGenre = styled.div`
    color: ${({ theme }) => theme.text.color.metadata};
    font-size: 2rem;
    margin-bottom: 1rem;
`;

export const EPGLive = styled.span`
    color: ${({ theme }) => theme.text.color.primary};
    font-size: 2rem;
    margin-left: 1rem;
`;

export const EPGOnAir = styled.span`
    color: ${({ theme }) => theme.text.color.onAir};
    margin-right: 0.5rem;
`;

export const EPGTitle = styled.div`
    margin-bottom: 1.8rem;
    color: ${({ theme }) => theme.text.color.primary};
`;

export const EPGDescription = styled.div`
    overflow: hidden;
    display: -webkit-box;
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: ${({ theme }) => theme.text.color.metadata};
`;

export const EPGTileAccent = styled.span<{ nowPlaying?: boolean; lock?: boolean }>`
    display: inline-block;
    width: 2rem;
    height: 2rem;
    ${({ theme, nowPlaying, lock }) =>
        fillIcon(
            lock
                ? theme.text.color.secondary
                : nowPlaying
                ? theme.mainColor
                : theme.text.color.secondary,
        )};
    margin-left: 0.5rem;
`;

export const EPGSliderAccent = styled.span<{ nowPlaying?: boolean; lock?: boolean }>`
    width: 2rem;
    height: 2rem;
    ${({ theme, nowPlaying, lock }) =>
        fillIcon(
            lock
                ? theme.player.lockIcon.color
                : nowPlaying
                ? theme.mainColor
                : theme.text.color.secondary,
        )};
    float: right;
`;

export const EPGHour = styled.span``;

export const ChannelTileContainer = styled(Nav)`
    flex-shrink: 0;
    padding: 1rem;

    & > div:first-child {
        border-width: 0.5rem;
        border-style: solid;
        border-color: transparent;
        border-radius: 0;
        width: 31.2rem;
        height: 17.8rem;
        padding: 0.5rem;
        font-size: 2.6rem;
        overflow: hidden;
        background-size: cover;
        position: relative;
    }

    &.nav-focused > div:first-child {
        border: ${({ theme }) => theme.listElement.activeBorder};
        border-radius: 0.5rem;
        transform: scale(1.2);
    }

    &:first-child {
        padding-left: 7.7rem;
    }

    &:last-child {
        padding-right: 7.7rem;
    }
`;

export const EPGChannelImage = styled.img`
    transition: ${CheckSlowDevice('opacity 0.3s ease-in-out')};
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
`;
