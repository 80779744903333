import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { GetEnvironmentInfoInterface } from '@/interfaces/get-environment-info-interface';
import { getBuildInfo } from '@/services/build-info.service';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { useUserAgentData } from '@/services/user-agent-data.service';
import { useWidevineConfiguration } from '@/hooks/use-widevine-configuration';
import { useGetIpData } from '@/api/system/use-get-ip-data';
import { useGetModel } from '@/hooks/use-get-model.hook';

function safeGetter<T>(f: () => T): T | null {
    try {
        return f.apply(f);
    } catch (err) {
        return null;
    }
}

export const useGetEnvironmentInfo = (): IApiResponse<GetEnvironmentInfoInterface> => {
    const buildInfo = getBuildInfo();
    const uad = useUserAgentData();
    const { data: userData } = useGetCurrentAuthSession();
    const { data: widevine } = useWidevineConfiguration();
    const { data: ipData, isFetching: isFetchingIpData } = useGetIpData();
    const { model } = useGetModel();

    // Nazwa urządzenia
    const deviceName = safeGetter(() => uad.deviceType);
    const systemVersion = safeGetter(() => uad.os);
    const appVersion = safeGetter(() => buildInfo.APP_VERSION);
    const userLogin = safeGetter(() => {
        const user = userData?.getUser();
        return user ? user.getLogin() : '';
    });
    const userId = safeGetter(() => {
        const user = userData?.getUser();
        return user ? user.getId() : '';
    });
    // UserAgent klienta
    const userAgent = safeGetter(() => uad.osInfo);
    // IP użytkownika
    const ip = safeGetter(() => ipData.ip);
    const currentTime = safeGetter(() => {
        const now = new Date();
        return formatDate(now.toISOString(), FORMAT_DATE_MODE.FULL_DATE);
    });
    const screenSize = safeGetter(() => {
        const { width, height } = window.screen;
        return `${width}x${height}`;
    });
    const playerVersion = safeGetter(() => CyfrowyPlayer.VERSION);
    // Czy wspierany jest Widevine (tak/nie)
    const widevineSupported = safeGetter(() => Boolean(widevine?.videoCapabilities));

    return {
        isFetching: isFetchingIpData,
        data: {
            deviceName,
            systemVersion,
            appVersion,
            userLogin,
            userId,
            userAgent,
            ip,
            currentTime,
            screenSize,
            playerVersion,
            widevineSupported,
            model,
        },
    };
};
