import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import {
    ProfileNameFormData,
    ProfileNameFormProps,
} from '@/components/views/Profile/ProfileNameForm/types';
import t from '@/lib/i18n';
import { yupResolver } from '@/lib/yupResolver';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    name: Yup.string().required(t('form.validate.required')),
});

const maxProfileNameLength = process.env.user.maxProfileNameLength;

export const ProfileNameForm = ({ onFormSubmit, onCancel }: ProfileNameFormProps) => {
    const { register, handleSubmit, errors } = useForm<ProfileNameFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit(({ name }) => {
        const profileData = { name: name.substring(0, maxProfileNameLength) };
        onFormSubmit(profileData);
    });

    const errorToDisplay = Object.values(errors).shift()?.message;

    return (
        <>
            <FormContainer onSubmit={onSubmit}>
                <TextInput
                    icon={ICONS.PERSON}
                    placeholder={t('form.placeholder.profile-name')}
                    defaultFocused
                    error={!!errorToDisplay}
                    name="name"
                    maxLength={maxProfileNameLength}
                    ref={register()}
                    data-testing="form.placeholder.profile-name"
                />
                <PlayableButton
                    text={t('save-button-label')}
                    onButtonAction={onSubmit}
                    data-testing="save-button-label"
                />
                {onCancel && (
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={onCancel}
                        data-testing="cancel-button-label"
                    />
                )}
            </FormContainer>
            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );
};
