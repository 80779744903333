import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { useGetConfiguration } from '@/api/system/use-get-configuration';
import { useLocalStorage } from '@/hooks/use-local-storage.hook';
import { GetConfigurationOut } from '@/interfaces/from-schemas/system/getConfigurationOut';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';
import { ConfigurationProviderProps } from './types';
import { useUserModule } from '../user-module/user-module.hooks';
import { useGetClientId } from '@/api/system/use-get-client-id';
import { useForeignUserInvalidation } from '@/hooks/user/use-foreign-user-invalidation.hook';
import { isWeb } from '@/constants/portal-recognition';

export const ConfigurationContext = createContext<{
    configuration: GetConfigurationOut | undefined;
    setConfiguration: (value: GetConfigurationOut) => void;
}>({
    configuration: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setConfiguration: () => {},
});

const ConfigurationContextProvider = ({
    children,
    initialConfiguration,
}: ConfigurationProviderProps) => {
    const [configuration, setConfiguration] = useLocalStorage(LOCAL_STORAGE_KEYS.CONFIGURATION);
    const isPl = configuration?.ipData.country === 'PL';

    const providerValue = useMemo(
        () => ({
            // initial configuration zapisuje konfigurację do local storage, żeby mieć szybki render jeśli wchodzimy drugi raz
            configuration: configuration ?? initialConfiguration ?? undefined,
            setConfiguration,
        }),
        [configuration, initialConfiguration, setConfiguration],
    );

    if (isWeb) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useForeignUserInvalidation(isPl);
    }

    return (
        <ConfigurationContext.Provider value={providerValue}>
            {children}
        </ConfigurationContext.Provider>
    );
};

export const ConfigurationUpdater = ({ children }: { children: ReactNode }) => {
    const { data } = useGetConfiguration();
    const { configuration, setConfiguration } = useContext(ConfigurationContext);

    useEffect(() => {
        if (data) setConfiguration(data);
    }, [data, setConfiguration]);

    const userModule = useUserModule();
    const clientIdExists = !!userModule.getClientId();

    const { data: clientId } = useGetClientId(!clientIdExists);

    useEffect(() => {
        if (!clientIdExists) {
            userModule.setClientId(clientId);
        }
    }, [clientId, clientIdExists, userModule]);

    if (!configuration) {
        return null;
    }
    return <>{children}</>;
};

export default ConfigurationContextProvider;
