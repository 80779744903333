import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import {
    GetMediaPromiseFunc,
    IApiResponse,
    IApiServerResponse,
} from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { GetMediaRelatedContentIn } from '@/interfaces/from-schemas/navigation/getMediaRelatedContentIn';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { useMemo } from 'react';
import CategoryContentModel from '@/models/category/category-content.model';
import { buildCategoryCatalogCollection } from '@/helpers/pages/build-category-catalog-collection.helper';
import { GetMediaRelatedContentOut } from '@/interfaces/from-schemas/navigation/getMediaRelatedContentOut';

const { seasonsList: dataPosition } = process.env.metadataSettings.position;
const { seasonsList: titleType } = process.env.metadataSettings.titleType;

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_MEDIA_RELATED_CONTENT;

export const getMediaRelatedContentPromise = (
    params: GetMediaRelatedContentIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<IApiServerResponse<GetMediaRelatedContentOut>> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {});
};

const useGetMediaRelatedContent = (
    params?: GetMediaRelatedContentIn,
): IApiResponse<ListElementModel[]> => {
    const keyHook = defaultKeyHook(namespace, method, params);
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    const mediaRelatedContent = useMemo(() => {
        const relatedContent = new CategoryContentModel(data);

        const { newCollection } = buildCategoryCatalogCollection(
            relatedContent,
            dataPosition,
            undefined,
            titleType,
        );

        return newCollection || [];
    }, [data]);

    return {
        data: mediaRelatedContent,
        isFetching,
        error,
    };
};

export default useGetMediaRelatedContent;
