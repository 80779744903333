import { buildNextUrl, getNextUrl } from '@/helpers/url.helper';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

export function redirectToWatchUrl(
    route: ROUTES,
    params: { [key: string]: any },
    withNext?: boolean,
): void {
    const nextUrl = withNext ? getNextUrl() || buildNextUrl() : undefined;
    const paramsExtended = nextUrl ? { ...params, next: nextUrl } : params;
    Router.pushRoute(route, paramsExtended);
}
