import { BACKEND_SERVICE_PROVIDER } from '@/types/backend-service-provider.type';
import { FACEBOOK_ENDPOINT } from '@/types/facebook-endpoint.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '../types/get-media-method.type';

const getMediaKeyHook = (
    namespace: GET_MEDIA_NAMESPACES,
    method: GET_MEDIA_METHODS,
    params: any,
) => ({
    service: BACKEND_SERVICE_PROVIDER.GET_MEDIA,
    namespace,
    method,
    params,
});

export const facebookKeyHook = (endpoint: FACEBOOK_ENDPOINT, params: any) => [
    {
        service: BACKEND_SERVICE_PROVIDER.FACEBOOK,
        endpoint,
        params,
    },
];

export const rulesSourceKeyHook = (url?: string) => [
    {
        service: BACKEND_SERVICE_PROVIDER.RULES_SOURCES,
        url,
    },
];

export default getMediaKeyHook;
