import { isServer } from '@/helpers/environment.helper';
import throttle from 'lodash/throttle';
import React, { useRef, useState } from 'react';
import { KEYS } from '@/input/keys';
import { InputManager } from '@/input';

const EVENT_TYPES = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress', 'keydown'];

export const useIdle = (duration = 3000) => {
    const [isIdle, setIsIdle] = useState(false);

    const durationRef = useRef<number>(duration);
    if (duration !== durationRef.current) {
        durationRef.current = duration;
    }

    React.useEffect(() => {
        if (isServer) {
            return;
        }

        let timeoutHandle: NodeJS.Timeout | null = null;

        const schedule = () => {
            timeoutHandle = setTimeout(() => {
                setIsIdle(true);
            }, durationRef.current);
        };

        const cleanUp = () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
                timeoutHandle = null;
            }
        };

        const handleEvent = throttle(
            (key) => {
                switch (key.keyCode) {
                    case InputManager.KEY[KEYS.RED]:
                    case InputManager.KEY[KEYS.BLUE]:
                    case InputManager.KEY[KEYS.GREEN]:
                    case InputManager.KEY[KEYS.YELLOW]:
                        return;
                }

                cleanUp();
                setIsIdle(false);
                schedule();
            },
            200,
            { trailing: false },
        );

        EVENT_TYPES.forEach((eventType) => {
            window.addEventListener(eventType, handleEvent);
        });

        cleanUp();
        schedule();
        return () => {
            EVENT_TYPES.forEach((eventType) => {
                window.removeEventListener(eventType, handleEvent);
            });
            cleanUp();
        };
    }, [setIsIdle]);

    React.useEffect(() => {
        if (isIdle) {
            document.body.classList.add('-player-hide-cursor');

            return () => {
                document.body.classList.remove('-player-hide-cursor');
            };
        }
    }, [isIdle]);

    return isIdle;
};
