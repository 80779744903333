import { NAVIGATION_KEYS, NavTree } from 'nav-tree';

export const focusProgramInColumn = (node: NavTree, columnId: string): string | null | false => {
    let isFocusTriggered = false;

    Object.values(node.nodes).some((node) => {
        return Object.values(node.nodes).some((innerNode) => {
            const shouldBeFocused = innerNode.id === columnId;

            if (shouldBeFocused) {
                const firstInnerNode = Object.values(innerNode.nodes)[0];

                if (firstInnerNode) {
                    if (firstInnerNode.nodesId.length > 0) {
                        const firstInnerInnerNode = Object.values(firstInnerNode.nodes)[0];
                        requestAnimationFrame(() => {
                            firstInnerInnerNode.focus();
                        });
                        isFocusTriggered = true;
                    } else {
                        requestAnimationFrame(() => {
                            firstInnerNode.focus();
                        });
                        isFocusTriggered = true;
                    }
                }

                return isFocusTriggered;
            }

            return false;
        });
    });

    return isFocusTriggered || false;
};

export const isTopButtonFocused = (navKeys: NAVIGATION_KEYS, node: NavTree | null) =>
    navKeys === NAVIGATION_KEYS.DOWN && node?.id === 'topButtonsNav';
