import { LiveChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getLiveChannelsOut';
import {
    CategorySchema,
    MediaListItemSchema,
} from '@/interfaces/from-schemas/navigation/getMediaListOut';
import { TvChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getTvChannelsOut';
import { CategoryModel } from '@/models/category/category.model';
import { ChannelModel } from '@/models/channels/channel.model';
import { LiveModel } from '@/models/live/live.model';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { ContentOutType } from '@/types/content-out.type';
import { MEDIA_TYPES } from '@/types/media.type';

export const setModelByContentOutType = (definition: ContentOutType['results'][number]) => {
    if (Object.prototype.hasOwnProperty.call(definition, 'mediaType')) {
        if ((definition as TvChannelListItemSchema).mediaType === MEDIA_TYPES.TV) {
            return new ChannelModel(definition as TvChannelListItemSchema);
        }
        if ((definition as LiveChannelListItemSchema).mediaType === MEDIA_TYPES.LIVE) {
            return new LiveModel(definition as LiveChannelListItemSchema);
        }
        return new MediaListItemModel(definition as MediaListItemSchema);
    } else {
        return new CategoryModel(definition as CategorySchema);
    }
};
