import { TVControls } from './controls';
import { ITVPlayerProps } from './types';
import { PlayerBase } from './PlayerBase';
import { CPID } from '@/types/media.type';
import { useMemo } from 'react';

export const TVPlayer = ({ channelTitle, channelId, mediaDetails, startTime }: ITVPlayerProps) => {
    const load = useMemo(
        () => ({
            media: {
                gmID: {
                    cpid: CPID.EVENT_OR_CHANNEL,
                    id: channelId,
                },
            },
            config: {
                startPosition: startTime,
            },
        }),
        [channelId, startTime],
    );
    const customOptions: IPlayer.PlayerConfigOptions = useMemo(
        () => ({
            forceAdaptiveStreaming: false,
        }),
        [],
    );

    return (
        <PlayerBase load={load} mediaDetails={mediaDetails} customOptions={customOptions}>
            <TVControls channelId={channelId} channelTitle={channelTitle} startTime={startTime} />
        </PlayerBase>
    );
};
