import React, { useMemo } from 'react';
import { PRODUCT_SUBTYPES } from '@/types/media.type';
import { TableDetailsInfo, TableDetailsTitle, TableWrapper } from './styles';
import { MediaFullDetailsProps } from './types';
import { MediaFullDetailsContent } from './MediaFullDetailsContent';
import { FullDetailsWrapper } from '@/components/shared/FullDetailsWrapper';
import { useMediaBackground } from '@/hooks/media/use-media-background.hook';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';

export const MediaFullDetails = ({ media, cnc, isMainCategory, fmc }: MediaFullDetailsProps) => {
    useMediaBackground(cnc || media, SHADE_MODE.FULL);

    const content = (
        <MediaFullDetailsContent
            media={media}
            cnc={cnc}
            fmc={fmc}
            isMainCategory={isMainCategory}
        />
    );

    const table = useMemo(() => {
        const isMovie = media.subType === PRODUCT_SUBTYPES.MOVIE;

        if (!isMovie) {
            return null;
        }

        return (
            <TableWrapper>
                <table>
                    {media.metadata.map((el, index) => (
                        <tr key={index}>
                            <TableDetailsTitle>{el.split(':')[0]}:</TableDetailsTitle>
                            <TableDetailsInfo>{el.split(':')[1]}</TableDetailsInfo>
                        </tr>
                    ))}
                </table>
            </TableWrapper>
        );
    }, [media]);

    return <FullDetailsWrapper table={table}>{content}</FullDetailsWrapper>;
};
