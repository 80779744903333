import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const TVBottomBarContainer = styled(Nav)<{ showEPG: boolean; showChannels: boolean }>`
    position: relative;
    transform: translate(
        0,
        ${({ showEPG, showChannels }) => (showChannels ? '-3rem' : showEPG ? '14rem' : '54rem')}
    );
    transition: ${CheckSlowDevice('transform ease-out 0.2s')};
`;
