import { SubcategoriesMenuItem } from '@/components/shared/Filter/SubcategoriesMenu/SubcategoriesMenuItem';
import { SubcategoriesMenuProps } from '@/components/shared/Filter/SubcategoriesMenu/types';
import React from 'react';
import { RightPanel } from './RightPanel';

const SubcategoriesMenuRaw = ({
    name,
    subcategories,
    isVisible,
    onAction,
    selectedCategoryId,
    onShowMenu,
}: SubcategoriesMenuProps) => {
    const menuItems = subcategories.map((subcategoryItem) => {
        return (
            <SubcategoriesMenuItem
                key={subcategoryItem.getId()}
                subcategory={subcategoryItem}
                onAction={onAction}
                selected={selectedCategoryId === subcategoryItem.getId()}
            />
        );
    });

    if (isVisible) {
        const focusedElement = selectedCategoryId ? selectedCategoryId : subcategories[0]?.getId();

        return (
            <RightPanel
                selectedId={selectedCategoryId}
                focused={`${focusedElement}`}
                menuItems={menuItems}
                onShowMenu={onShowMenu}
                name={name}
            />
        );
    }

    return null;
};

export const SubcategoriesMenu = React.memo(SubcategoriesMenuRaw);
