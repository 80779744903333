import { ICONS } from '@/components/shared/Icon';
import { StaticMenuItemInterface } from '@/components/shared/Menu/types';
import { prepareMenuUrl } from '@/helpers/home-menu/prepare-menu-url.helper';
import { GetHomeMenuOut, PlaceSchema } from '@/interfaces/from-schemas/navigation/getHomeMenuOut';
import { ROUTES } from '@/routing/types';

const ICON_TYPE = 'svg';

export class HomeMenuItemModel implements StaticMenuItemInterface {
    private name: string;
    private place?: PlaceSchema;
    private iconUrl?: string;

    constructor(definition: GetHomeMenuOut[number]) {
        this.name = definition.name;
        this.place = definition.place;

        if (definition.images) {
            definition.images.forEach((image) => {
                const source = image.sources.find((source) => source.type === ICON_TYPE);
                this.iconUrl = source?.src;
            });
        }
    }

    public getName(): string {
        return this.name;
    }

    public getPlace(): PlaceSchema | undefined {
        return this.place;
    }

    public getIconUrl(): string | undefined {
        return this.iconUrl;
    }

    // Wyrównianie do interfejsu StaticMenuItemInterface
    public getUrl(): ROUTES | undefined {
        return;
    }

    // Wyrównianie do interfejsu StaticMenuItemInterface
    public getIcon(): ICONS | undefined {
        return;
    }

    // TODO: zaimplementowanie data-testing
    public getDataTesting(): string | undefined {
        const route = prepareMenuUrl(this.getPlace())?.route;

        if (route) {
            return `menu.${route}`;
        }
    }
}
