import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, IUseGetMediaApiOptions } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { GetPrePurchaseDataIn } from '@/interfaces/from-schemas/payments/getPrePurchaseDataIn';
import { PrePurchaseDataModel } from '@/models/payments/pre-purchase-data.model';
import { GetPrePurchaseDataOut } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { GetPrePurchaseDataParams } from '@/interfaces/pre-purchase-data.interface';

export const buildPrePurchaseDataParams = (
    params?: GetPrePurchaseDataParams,
): GetPrePurchaseDataIn | undefined => {
    if (params) {
        const { product, withoutMinPriceProducts, withoutUserPaymentsData } = params;

        return { products: [product], withoutMinPriceProducts, withoutUserPaymentsData };
    }
};

export const useGetPrePurchaseDataList = (
    params?: GetPrePurchaseDataIn,
): IApiResponse<PrePurchaseDataModel[] | undefined> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_PRE_PURCHASE_DATA,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    const prePurchaseDataList = data?.map(
        (prePurchaseDataItem: GetPrePurchaseDataOut[number]) =>
            new PrePurchaseDataModel(prePurchaseDataItem),
    );

    return {
        data: prePurchaseDataList,
        error,
        isFetching,
    };
};

export const useGetPrePurchaseData = (
    params?: GetPrePurchaseDataParams,
    isAlwaysRefetchOnMount = false,
): IApiResponse<PrePurchaseDataModel | undefined> => {
    const prePurchaseDataParams = buildPrePurchaseDataParams(params);

    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_PRE_PURCHASE_DATA,
        prePurchaseDataParams,
    );
    const options: IUseGetMediaApiOptions = { appendAuthData: true };

    if (isAlwaysRefetchOnMount) {
        options.queryOptions = { refetchOnMount: 'always' };
    }

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    const prePurchaseDataList = data?.map(
        (prePurchaseDataItem: GetPrePurchaseDataOut[number]) =>
            new PrePurchaseDataModel(prePurchaseDataItem),
    );

    return {
        data: prePurchaseDataList?.[0],
        error,
        isFetching,
    };
};
