import { useContext, useEffect } from 'react';
import { useColumnContext } from '@/contexts/epg-table/column.context';
import { COLUMNS_PER_PAGE } from '@/constants/epg-table';
import { ChannelModel } from '@/models/channels/channel.model';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';

export const useSetTableColumns = (channels: ChannelModel[]) => {
    const { isLastPage } = useIncrementColumnContext();
    const { setLastColumn, setFirstColumn } = useColumnContext();

    useEffect(() => {
        if (!channels.length) return;
        const firstChannelId = channels[0].getId();

        batchedUpdates(() => {
            setFirstColumn(() => ({ index: 0, id: firstChannelId }));
        });
    }, [channels]);

    useEffect(() => {
        if (channels.length < 3) return;
        const lastChannelId = isLastPage
            ? channels[channels.length - 2].getId()
            : channels[channels.length - 3].getId();

        setLastColumn({
            index: COLUMNS_PER_PAGE - 3,
            id: lastChannelId,
        });
    }, [channels, isLastPage]);
};
