import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';
import { roundToDigits } from '@/helpers/round-to-digits.helper';
import { ICalculateContainerAndColumnWidth } from './types';

export const calculateContainerAndColumnWidth = ({
    col: maxColsInGrid,
    itemsLength,
    showAddProfileCircle,
    size,
}: ICalculateContainerAndColumnWidth) => {
    // Ilość kolumn prezentowanych w gridzie. Jeśli wyświetlamy ikonę tworzenia nowego profilu, to zwiększamy liczbę kolumn o jeden.
    const colsInGrid = showAddProfileCircle ? itemsLength + 1 : itemsLength;
    // Liczba wszystkich kolumn
    const totalCols = colsInGrid > maxColsInGrid ? maxColsInGrid : colsInGrid;
    const iconWidth = size * 10 + 50;
    const containerWidth = convertPxToRem(iconWidth * totalCols);
    const columnWidth = roundToDigits(100 / totalCols, 2);

    return {
        containerWidth,
        columnWidth,
    };
};
