import { useState } from 'react';
import { NonInteractiveTransaction } from '../NonInteractiveTransaction';
import { PinCodeVerificationProps } from './types';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared/Buttons';
import t from '@/lib/i18n';
import { checkIsPinValid } from '@/helpers/stb/pin.helper';
import { Message } from '@/components/shared/Message';
import { ButtonContainer, CenterContainer, FooterTextContainer, InputContainer } from './styles';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { GUTTER } from '@/types/gutter.type';
import { LoadingWrapper } from '@/components/shared/Loader';

const PIN_LENGTH = 4;

export const PinCodeVerification = ({
    product,
    offer,
    option,
    orderId,
    onPaymentSuccess,
    onPaymentError,
    onSetStepsVisibility,
    onSetProductCardVisibility,
    onExitPaymentPath,
}: PinCodeVerificationProps) => {
    const [isPinValid, setIsPinValid] = useState(false);
    const [pinCode, setPinCode] = useState<string>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState(false);

    const onChange = (value: string) => {
        setPinCode(value);
        return value;
    };

    const onFormSubmit = () => {
        if (!pinCode || pinCode.length !== PIN_LENGTH) {
            setError(t('payment-steps.pin.incorrect-code'));
            return;
        }

        setLoading(true);

        checkIsPinValid(pinCode)
            .then(({ isValid }) => {
                setLoading(false);
                setIsPinValid(isValid);

                if (!isValid) {
                    setError(t('payment-steps.pin.incorrect-code'));
                }
            })
            .catch(() => {
                setLoading(false);
                setError(t('payment-steps.pin.error-message'));
            });
    };

    if (isPinValid) {
        return (
            <NonInteractiveTransaction
                product={product}
                offer={offer}
                option={option}
                orderId={orderId}
                isCpwallet={false}
                onPaymentSuccess={onPaymentSuccess}
                onPaymentError={onPaymentError}
                onSetStepsVisibility={onSetStepsVisibility}
                onSetProductCardVisibility={onSetProductCardVisibility}
                onExitPaymentPath={onExitPaymentPath}
            />
        );
    }

    if (error) {
        return (
            <CenterContainer>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={error}
                    description={t('payment-steps.try-again')}
                />
                <ButtonContainer>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => setError(undefined)}
                        defaultFocused
                    />
                </ButtonContainer>
            </CenterContainer>
        );
    }

    return (
        <LoadingWrapper
            isFetching={loading}
            text={t('payment-steps.pin.verification')}
            calculateBottom={false}
        >
            <CenterContainer>
                <MainText
                    text={t('payment-steps.pin.form-title')}
                    mode={MAINTEXT_KIND.PAYMENT_STEP_TITLE}
                />

                <InputContainer>
                    <TextInput
                        placeholder="****"
                        icon={ICONS.KEY}
                        type={INPUT_TYPE.TEL}
                        maxLength={PIN_LENGTH}
                        gutter={GUTTER.BIG}
                        onChange={onChange}
                        disableVirtualKeyboard
                        defaultFocused
                    />
                </InputContainer>

                <ButtonContainer>
                    <PlayableButton
                        text={t('confirm-button-label')}
                        onButtonAction={onFormSubmit}
                        gutter={GUTTER.BIG}
                    />
                </ButtonContainer>

                <FooterTextContainer>
                    <MainText
                        mode={MAINTEXT_KIND.DESCRIPTION}
                        text={t('payment-steps.pin.footer-text')}
                        fullText
                    />
                </FooterTextContainer>
            </CenterContainer>
        </LoadingWrapper>
    );
};
