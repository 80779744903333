import { ProcessingLoader } from '@/components/shared/Loader';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import {
    RulesContainerStyled,
    RulesHeader,
} from '@/components/shared/RulesScrollableContainer/styles';
import ScrollableContainer from '@/components/shared/ScrollableContainer';
import { LoginRulesWrapper } from '@/components/views/Rules/LoginRules/styles';
import { RuleContainerProps } from '@/components/views/Rules/LoginRules/types';
import { usePrepareRulesToDisplay } from '@/hooks/auth/use-prepare-rules-to-display.hook';
import { Nav, navHorizontal } from 'nav-tree';
import React, { useMemo, useState } from 'react';
import { RuleActions } from './RuleActions';

export const RuleContainer = ({ rule, onNextRule }: RuleContainerProps) => {
    const { ruleDisplayData, isFetching } = usePrepareRulesToDisplay(rule);
    const [processingAction, setProcessingAction] = useState<boolean>(false);

    const showLoader = isFetching;

    return useMemo(() => {
        if (processingAction) {
            return <ProcessingLoader isFetching={processingAction} />;
        }

        return (
            <Nav func={navHorizontal} restoreLastFocus>
                <RuleActions
                    rule={rule}
                    onNextRule={onNextRule}
                    onProcessingAction={setProcessingAction}
                />
                <LoginRulesWrapper>
                    <ScrollableContainer key={rule.getId()} withBottomButtons>
                        <RulesHeader>
                            <MainText mode={MAINTEXT_KIND.PAGE_TITLE} text={rule.getName()} />
                        </RulesHeader>
                        <RulesContainerStyled>
                            <div dangerouslySetInnerHTML={{ __html: ruleDisplayData }} />
                        </RulesContainerStyled>
                        <ProcessingLoader isFetching={showLoader} />
                    </ScrollableContainer>
                </LoginRulesWrapper>
            </Nav>
        );
    }, [rule, ruleDisplayData, onNextRule, showLoader, processingAction]);
};
