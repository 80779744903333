import { DeviceIdInterface } from '../interfaces/device-id-interface';
import { UserModel } from './user.model';
import { SessionInterface } from './../interfaces/session-interface';

class AuthSessionModel {
    private readonly accessGroups: string[];
    private readonly device: IDevice;
    private readonly externalAuthToken: string;
    private readonly parentalControls: IParentalControls;
    private readonly session: SessionInterface;
    private readonly status: number;
    private readonly statusDescription: string;
    private readonly user: UserModel;
    private readonly profileId?: string;

    constructor(definition: any) {
        this.accessGroups = definition['accessGroups'];
        this.device = definition['device'];
        this.externalAuthToken = definition['externalAuthToken'];
        this.parentalControls = definition['parentalControls'];
        this.session = definition['session'];
        this.status = definition['status'];
        this.statusDescription = definition['statusDescription'];
        this.user = new UserModel(definition['user']);
        this.profileId = definition['profileId'];
    }

    public getAccessGroups(): string[] {
        return this.accessGroups;
    }

    public getDevice(): IDevice {
        return this.device;
    }

    public getExternalAuthToken(): string {
        return this.externalAuthToken;
    }

    public getParentalControls(): IParentalControls {
        return this.parentalControls;
    }

    public getSession(): SessionInterface {
        return this.session;
    }

    public getStatus(): number {
        return this.status;
    }

    public getStatusDescription(): string {
        return this.statusDescription;
    }

    public getUser(): UserModel {
        return this.user;
    }

    public getCurrentProfileId(): string | undefined {
        return this.profileId;
    }

    public isUserVerified(): boolean {
        const isVerified = this.getAccessGroups().some(
            (accessGroup) => accessGroup === 'verified:true',
        );

        return !!isVerified;
    }

    public toPlayerAuthObject(): IPlayer.UserAuthObject {
        return {
            id: this.user.getId(),
            login: this.user.getLogin(),
            session: this.session,
            token: this.externalAuthToken,
            profileId: this.profileId,
        };
    }

    public getIPolsatBoxId(): string | undefined {
        return this.getUser().getSSOId();
    }
}

export default AuthSessionModel;

interface IDevice {
    id: DeviceIdInterface;
    pc: string;
}

interface IParentalControls {
    age?: number;
}
