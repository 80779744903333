import styled from 'styled-components';

export const SmsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 2rem;
`;

export const SmsContent = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    padding: 0 14rem;

    & span {
        color: ${({ theme }) => theme.paymentTransactionStep.highlight};
    }
`;

export const SmsFooter = styled.div`
    position: relative;
    padding: 0 3rem;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.paymentTransactionStep.descriptionColor};

    &:before {
        content: '*';
        position: absolute;
        left: 0;
        top: 0;
        width: 3rem;
        padding-right: 0.6rem;
        text-align: right;
    }
`;
