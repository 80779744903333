import { useGetPaymentCards } from '@/api/payments/use-get-payment-cards';
import { findPaymentCard } from '@/helpers/payment.helper';
import { useEffect, useState } from 'react';
import { usePaymentsData } from './use-payments-data';

export const useSelectedPaymentCard = () => {
    const { selectedPaymentCardId } = usePaymentsData();
    const { data: paymentCards } = useGetPaymentCards();
    const [selectedPaymentCard, setSelectedPaymentCard] = useState(
        findPaymentCard(paymentCards, selectedPaymentCardId),
    );

    useEffect(() => {
        const paymentCard = findPaymentCard(paymentCards, selectedPaymentCardId);
        setSelectedPaymentCard(paymentCard);
    }, [paymentCards, selectedPaymentCardId]);

    return selectedPaymentCard;
};
