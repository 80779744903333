import { useMemo } from 'react';
import { usePlayerStatus } from './use-player-status';

export const usePlaybackOptions = () => {
    const { playbackOptions } = usePlayerStatus();
    return useMemo(() => {
        const hasAudioOptions = playbackOptions?.audio && playbackOptions.audio.length > 1;
        const hasTextOptions = playbackOptions?.text && playbackOptions.text.length > 1;
        const hasVideoOptions = playbackOptions?.video && playbackOptions.video.length > 1;

        return {
            playbackOptions,
            hasBothOptions: hasAudioOptions && hasTextOptions,
            hasAnyOptions: hasAudioOptions || hasTextOptions,
            hasAudioOptions,
            hasTextOptions,
            hasVideoOptions,
        };
    }, [playbackOptions]);
};
