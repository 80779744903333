import { RefObject, useCallback } from 'react';
import { Nav } from 'nav-tree';
import { useRouter } from '@/routing';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useBackAction } from '../use-back-action';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { focusNavId } from '@/helpers/nav-tree/focus-nav-id.helper';
import { BUTTON_ID } from '@/types/button-id.type';
import { focusProgramInColumn } from '@/components/shared/EPGTable/nav-helpers';
import { useColumnContext } from '@/contexts/epg-table/column.context';

export const useEPGProgramRightPanel = ({ focusRef }: { focusRef: RefObject<Nav> }) => {
    const { route, query } = useRouter();
    const { mode, setMode, rightPanelOpen, setRightPanelOpen } = useRightPanel();
    const { firstColumn } = useColumnContext();

    const handler = useCallback(() => {
        setRightPanelOpen(false);

        const tree = focusRef?.current?.getChildContext().tree;

        if (!tree) return;

        Router.pushRoute(route as ROUTES, undefined, query);

        if (mode === 'epg') {
            focusNavId(tree, BUTTON_ID.DAY_PICKER_BTN);

            return;
        } else {
            focusProgramInColumn(tree, firstColumn.id);
        }
    }, [setRightPanelOpen, focusRef, route, query, mode, firstColumn.id]);

    const handlePanelOpen = useCallback(
        (open: boolean, mode: 'epg' | 'filters') => {
            setRightPanelOpen(open);
            setMode(mode);
        },
        [setRightPanelOpen, setMode],
    );

    useBackAction(handler, rightPanelOpen);

    return {
        filtersOpen: rightPanelOpen && mode === 'filters',
        handleFiltersOpen: (open: boolean) => handlePanelOpen(open, 'filters'),
        showSubcategoriesMenu: rightPanelOpen && mode === 'epg',
        handleSubcategoriesOpen: (open: boolean) => handlePanelOpen(open, 'epg'),
    };
};
