import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetPurchaseCodeDataOut } from '@/interfaces/from-schemas/payments/getPurchaseCodeDataOut';
import { IApiResponse } from '@/hooks/use-get-media-api.type';

interface IPurchaseCode {
    purchaseCode: string;
}

const useGetPurchaseCodeData = (params?: IPurchaseCode): IApiResponse<GetPurchaseCodeDataOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.GET_PURCHASE_CODE_DATA,
        params,
    );

    return useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
};

export default useGetPurchaseCodeData;
