import { JSONRpcErrorInterface } from '@/interfaces/jsonrpc-error-interface';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import PromiseQueue from '@/services/promise-queue.service';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { appConfig } from '@/configs/app.config';
import { isServer } from '@/helpers/environment.helper';

const RequestsPromiseQueue = new PromiseQueue();

const axiosRetryWrapper = (axios: AxiosInstance) => {
    if (isServer && process.env.NODE_ENV == 'development')
        axios.interceptors.request.use((request) => {
            return request;
        });
    axios.interceptors.response.use(
        (response: AxiosResponse<any>) => response,
        (error: AxiosError) => {
            const statusCode = error.response?.status;

            const dataError = {
                error,
                statusCode,
            };

            return Promise.reject(dataError);
        },
    );
};

axiosRetryWrapper(axios);

const httpResponse = (url: string, requestConfig: AxiosRequestConfig): Promise<any | never> => {
    const protectedUrl = isServer ? url : protectURL(url);

    return axios(protectedUrl, requestConfig)
        .then((response: AxiosResponse) => {
            return {
                ...response?.data,
                statusCode: response.status,
                headers: response?.headers,
            };
        })
        .catch((error) => {
            throw error;
        });
};

const httpRequest = async (
    urlRequest: string,
    data: any = {},
    method: Method = appConfig.requestMethod,
    headers?: Record<string, string>,
): Promise<IHttpResponse> => {
    const requestConfig: AxiosRequestConfig = {
        method,
        headers: {
            accept: appConfig.requestContentType,
            ...headers,
        },
        timeout: appConfig.requestTimeout,
    };

    if (method.toLowerCase() === 'get') {
        requestConfig.params = data;
    } else {
        requestConfig.data = data;
    }

    if (process.env.slowDevice) {
        return RequestsPromiseQueue.add(() => httpResponse(urlRequest, requestConfig));
    }
    return httpResponse(urlRequest, requestConfig);
};

export default httpRequest;

export interface IHttpResponse<D = any, E = JSONRpcErrorInterface> {
    result: D | null;
    error: E | null;
    statusCode?: number;
    headers?: Record<string, string>;
}

interface IAxiosRetryWrapperOptions {
    maxRetry: number;
    excludedStatusCode: number[];
}
