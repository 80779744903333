import { GUTTER } from '@/types/gutter.type';
import { Nav } from 'nav-tree';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { AlertMessage, MESSAGE_TYPE } from '../AlertMessage';
import { MainText, MAINTEXT_KIND } from '../MainText';

interface IFullscreenNavContainerProps {
    header?: string;
    description?: string;
    footer?: string;
    footerLines?: number;
    error?: string;
    body: ReactNode;
    fullBody?: boolean;
    bodyWidth?: number;
    errorCode?: number;
    errorCodeMessageToDisplay?: string;
    isErrorToDisplay?: boolean;
}

export const MediaButtonsWrapper = styled(Nav)`
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;

    > * {
        width: 30%;
        margin: 0 1rem;
    }
`;

export const PageContainer = styled(Nav)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-align: center;
`;

export const HeaderContainer = styled.div``;
export const BodyContainer = styled.div<{ fullBody?: boolean; bodyWidth?: number }>`
    width: ${({ fullBody, bodyWidth }) =>
        fullBody ? '100%' : bodyWidth ? `${bodyWidth}rem` : '73rem'};
    text-align: center;
`;
export const FooterContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
`;

export const FormContainer = styled.form<{ topGutter?: GUTTER }>`
    width: 44rem;
    display: inline-block;
    ${({ topGutter }) => topGutter && `margin-top: ${topGutter}rem`}
`;

export const ProcessingLoaderWrapper = styled.div`
    position: relative;
    margin-top: 5rem;
`;

export const Column = styled.div`
    padding: 0 5rem;
    flex-basis: 100%;
    text-align: center;
    min-width: 0;
`;

const FullscreenNavContainer = ({
    header,
    description,
    footer,
    footerLines,
    body,
    error,
    fullBody,
    bodyWidth,
    isErrorToDisplay,
}: IFullscreenNavContainerProps) => {
    return (
        <PageContainer>
            {header && (
                <HeaderContainer>
                    <MainText text={header} mode={MAINTEXT_KIND.HEADER} />
                </HeaderContainer>
            )}
            {description && (
                <HeaderContainer>
                    <MainText text={description} mode={MAINTEXT_KIND.DESCRIPTION} />
                </HeaderContainer>
            )}
            <BodyContainer fullBody={fullBody} bodyWidth={bodyWidth}>
                {body}
            </BodyContainer>
            {footer && (
                <FooterContainer>
                    <MainText text={footer} lines={footerLines} mode={MAINTEXT_KIND.COPYRIGHT} />
                </FooterContainer>
            )}
            {error && isErrorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={error} />
            )}
        </PageContainer>
    );
};

export default FullscreenNavContainer;
