import { useEffect } from 'react';

declare const webapis: any;

export const usePlayerVisibility = () => {
    useEffect(() => {
        const eventHandler = () => {
            if (document.hidden) {
                try {
                    webapis.avplay.suspend();
                } catch (e) {
                    console.error('SuspendAvplayError', e);
                }
            } else {
                try {
                    webapis.avplay.restore();
                } catch (e) {
                    console.error('RestoreAvplayError', e);
                }
            }
        };

        document.addEventListener('visibilitychange', eventHandler);
        return () => document.removeEventListener('visibilitychange', eventHandler);
    }, []);
};
