import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import { ProfileInterface } from '@/interfaces/profile-interface';
import { useContext, useMemo } from 'react';
import { ProfileContext } from './profile.context';
import useGetProfiles from '@/api/auth/use-get-profiles';

export const useSessionProfile = (): [
    ProfileInterface | undefined,
    (profile: ProfileInterface) => void,
] => {
    const { sessionProfile, setSessionProfile } = useContext(ProfileContext);
    return [sessionProfile, setSessionProfile];
};

export const useProfileSelectionExpired = () => {
    const { profileUpdateHours } = useContext(ProfileContext);
    return profileUpdateHours ? profileUpdateHours > process.env.user.askProfileInterval : false;
};

export const useProfileContext = () => useContext(ProfileContext);

export const useCheckProfile = () => {
    const { data: sessionData } = useGetCurrentAuthSession();
    const profileSelectionExpired = useProfileSelectionExpired();
    const { sessionProfile, profileIdCookie } = useProfileContext();

    const profileId =
        sessionData?.getCurrentProfileId() || sessionProfile?.profileId || profileIdCookie;

    const hasProfile = useMemo(() => !profileSelectionExpired && !!profileId, [
        profileSelectionExpired,
        profileId,
    ]);

    return hasProfile;
};

export const useProfileFormValidators = () => {
    const { data: profiles } = useGetProfiles();

    const checkProfileNameExists = (name: ProfileInterface['name']) =>
        profiles.some((profile) => profile.name.trim() === name.trim());

    return { checkProfileNameExists };
};
