import { ACTIVITY_EVENTS_ACCOUNT_TYPES, EVENT, IIpData } from '../../types';
import { UserAgentData } from '@/types/uad.type';

export type ActivityEventsConfig = {
    service: string;
    jwt: string;
    originator: string;
    clientId: string;
    profileId: string;
    appVersion: string;
    portalId: string;
    userAgentData: UserAgentData;
    deviceExtraData?: IDeviceExtraData;
    deviceId: IDeviceId;
    ipData?: IIpData;
};

export type ActivityEventsParams = {
    jwt: string;
    portalId: string;
    originator: string;
    version: string;
    eventDate: string;
    type: EVENT;
    eventId: string;
    traceId: string;
    data: ActivityEventsDataParams;
};

export type ActivityEventsPlaceParams = {
    type: string;
    value?: string;
};

export interface ActivityEventsContentItemParams {
    position?: number;
    type: string;
    value: string;
    source: string;
}

export interface ActivityEventsListParams {
    position?: number;
    type: string;
    value?: string;
}

export interface ActivityEventsAccountParams {
    provider: ACTIVITY_EVENTS_ACCOUNT_TYPES;
    login?: string;
    fbId?: string;
    msisdn?: string;
    userIdCP?: string;
}

export interface ActivityEventsLaunchData {
    firstLaunch: boolean;
    launchCount: number;
}

export interface IDeviceExtraData {
    manufacturer?: string;
    model?: string;
    screenSize?: IScreenSize;
}

export interface IScreenSize {
    height: number;
    width: number;
    diagonal: number;
}

export interface IDeviceId {
    type: string;
    value: string;
}

export interface ActivityEventsDataParams {
    status: EVENT_STATUS;
    place?: ActivityEventsPlaceParams;
    clientId: string;
    deviceId: IDeviceId;
    profileId?: string;
    errorCode?: number;
    account?: ActivityEventsAccountParams;
    launchData?: ActivityEventsLaunchData;
    deviceExtraData?: IDeviceExtraData;
    activeDuration?: number;
    ipData?: {
        ip: string;
        continent?: string;
        country?: string;
        isEu?: boolean;
        isVpn?: boolean;
        isp?: string;
    };
    userAgentData?: UserAgentData;
}

export enum EVENT_STATUS {
    SUCCESS = 'success',
    FAILED = 'failed',
}
