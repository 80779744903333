import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';
import { GetPacketContentWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getPacketContentWithFlatNavigationIn';
import { GetPacketContentWithFlatNavigationOut } from '@/interfaces/from-schemas/navigation/getPacketContentWithFlatNavigationOut';
import PacketContentWithFlatNavigationModel from '@/models/packets/packet-content-with-flat-navigation.model';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.PACKET_CONTENT_WITH_TREE_NAVIGATION;

const getPacketContentWithFlatNavigationPromise = (
    params: GetPacketContentWithFlatNavigationIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetPacketContentWithFlatNavigationOut> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getPacketContentWithFlatNavigationPromise };

const useGetPacketContentWithFlatNavigation = (
    params: GetPacketContentWithFlatNavigationIn | false,
): IApiResponse<PacketContentWithFlatNavigationModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);
    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => data && new PacketContentWithFlatNavigationModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetPacketContentWithFlatNavigation;
