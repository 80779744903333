import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { AccountRules } from '@/components/views/Rules/AccountRules';
import { ROUTES } from '@/routing/types';
import React from 'react';

const Rules = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.RULES} />
            <AccountRules />
        </BaseLayout>
    );
};

export default Rules;
