import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { useIsUserLogged } from '@/hooks/user/use-is-user-logged.hook';
import LatelyWatchedContentDataListModel from '@/models/user-content/lately-watched-content-data-list.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useMemo } from 'react';
import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';

export const useGetLatelyWatchedContentDataList = (
    catid?: number,
): IApiResponse<WatchedContentDataSchema[]> => {
    const isLogged = useIsUserLogged();

    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.USER_CONTENT,
        GET_MEDIA_METHODS.FETCH_USER_LATELY_WATCHED_CONTENT_DATA_LIST,
        // 1 zwraca filmy i seriale
        isLogged && catid ? { catid } : 1,
    );

    const options = {
        appendAuthData: true,
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, isFetching, refetch, error } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new LatelyWatchedContentDataListModel(data).getResults(), [data]),
        isFetching,
        refetch,
        error,
    };
};

export const getLatelyWatchedContentDataListPromise = (
    getMediaApiPromise: GetMediaPromiseFunc,
    catid?: number,
) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.USER_CONTENT,
        GET_MEDIA_METHODS.FETCH_USER_LATELY_WATCHED_CONTENT_DATA_LIST,
        catid ? { catid } : null,
    );
    return getMediaApiPromise(keyHook, {});
};
