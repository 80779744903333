import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';

export interface EPGTableProgram extends EPGTableProgramRaw {
    height: string;
}

export type ProgramEntry = [string, EPGTableProgram[]];

export type GroupedPrograms = [string, EPGTableProgram[]][];

export interface EPGTableProgramRaw {
    title: string;
    startTime: string;
    endTime: string | null;
    program: ChannelProgramTvModel;
}

export interface PageBoundary {
    startIndex: number;
    endIndex: number;
}
export interface HourRange {
    startTime: number;
    endTime: number;
}

export interface CtxHours {
    fullHour: Date;
    oneHourFromNow: Date;
    twoHoursFromNow: Date;
}

export interface GoToWatchParams {
    callback: () => void;
    timestamp: number;
}

export type HandleGoToWatch = (params: GoToWatchParams) => void;

export enum DATA_ATTR {
    START_TIME = 'data-start-time',
    END_TIME = 'data-end-time',
    IS_COMBINED = 'data-is-combined',
}

export enum SCROLL_POSITION {
    BETWEEN = 'between',
    BOTTOM = 'bottom',
    TOP = 'top',
}

export enum INDICATOR_VARIANT {
    BOTTOM = 'bottom',
    TOP = 'top',
}
