import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import FullscreenNavContainer, {
    FormContainer,
    ProcessingLoaderWrapper,
} from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import React, { useMemo } from 'react';
import { useIPolsatBoxLogin } from '@/hooks/auth/use-ipolsatbox-login.hook';
import { GUTTER } from '@/types/gutter.type';
import { buildLink } from '@/helpers/link-translation.helper';
import { ROUTES } from '@/routing/types';

const LoginIPolsatBox = () => {
    const { onSubmit, register, isFetching, loginError, formErrors } = useIPolsatBoxLogin();

    const errorToDisplayinAlert = formErrors.password?.message;
    const errorCodeMessageToDisplay = loginError?.getUserMessage();
    const errorCode = loginError?.getCode();
    const showError = useMemo(() => !isFetching, [isFetching]);
    const body = (
        <FormContainer topGutter={GUTTER.BIG} onSubmit={onSubmit}>
            <TextInput
                icon={ICONS.PERSON}
                placeholder={t('form.placeholder.e-mail')}
                defaultFocused
                error={showError && !!loginError}
                name="login"
                ref={register()}
            />
            <TextInput
                type={INPUT_TYPE.PASSWORD}
                icon={ICONS.PASSWORD}
                error={showError && !!loginError}
                placeholder={t('form.placeholder.password')}
                name="password"
                ref={register()}
            />
            <PlayableButton text={t('pages.login.native.sign-in')} onButtonAction={onSubmit} />
            <PlayableButton
                text={t('cancel-button-label')}
                onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_BY_REMOTE)}
            />
            <ProcessingLoaderWrapper>
                <ProcessingLoader isFetching={isFetching} noDelay />
            </ProcessingLoaderWrapper>
        </FormContainer>
    );

    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer
                error={errorToDisplayinAlert}
                description={t('pages.login.ipolsatbox.description', {
                    domain: t('domain-ipolsatbox-url'),
                    ...buildLink(t('domain-ipolsatbox-url')),
                })}
                body={body}
                errorCode={errorCode}
                errorCodeMessageToDisplay={errorCodeMessageToDisplay}
            />
        </BaseLayout>
    );
};

export default LoginIPolsatBox;
