import BaseLayout from '@/components/shared/layouts/BaseLayout';

import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import ResetPasswordRequestForm from '../../components/views/ResetPasswordRequestForm';

const ResetPasswordRequest = () => {
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.NONE}>
            <ResetPasswordRequestForm />
        </BaseLayout>
    );
};

export default ResetPasswordRequest;
