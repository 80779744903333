import { usePlayerInstance } from './use-player-instance';

export const useSkipRange = () => {
    const instance = usePlayerInstance();
    let skipRange = { start: 0, end: instance.getStatus().playback.duration };

    try {
        skipRange = instance.getSkipRange();
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.warn(e);
        }
    }

    return { ...skipRange };
};
