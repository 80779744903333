/* eslint:disable */
import { useAuth } from '@/contexts/auth/auth.hooks';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { isServer } from '@/helpers/environment.helper';
import { appConfig } from '@/configs/app.config';
import { JSONRpcErrorInterface } from '@/interfaces/jsonrpc-error-interface';
import { createCacheKey, getMediaFetcher } from '@/services/get-media/get-media.service';
import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useCallback, useMemo, useState } from 'react';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { IHttpResponse } from '@/services/http-request.service';
import ResponseErrorModel from '@/models/response-error.model';
import {
    IApiResponse,
    IGetMediaMutateFn,
    IGetMediaMutateResult,
    IKeyHook,
    IUseGetMediaApiOptions,
} from './use-get-media-api.type';
import { useAppContext } from '@/contexts/app/app.context';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useSessionExpired } from './auth/use-session-expired.hook';
import { useClientContextToken } from '@/contexts/client-context-token/client-context-token.hooks';
import { getServiceMethod } from '@/services/get-media/helpers';

const defaultOptions: IUseGetMediaApiOptions = {
    appendAuthData: true,
    appendClientId: true,
};

export const createQueryClient = () =>
    new QueryClient({
        defaultOptions: {
            queries: {
                retry: retryQuery,
                retryDelay: appConfig.requestRetryDelay,
                refetchOnWindowFocus: false,
            },
            mutations: {
                retry: retryQuery,
                retryDelay: appConfig.requestRetryDelay,
            },
        },
    });

const retryQuery = (failureCount: number, error: any) => {
    const statusCode: number = error?.statusCode;
    const { requestRetryExcludedStatusCode, requestMaxRetry } = appConfig;

    return (
        failureCount < requestMaxRetry &&
        !!statusCode &&
        !requestRetryExcludedStatusCode.includes(statusCode)
    );
};

export const useGetMediaApi = <T = any>(
    keyHook: IKeyHook,
    customOptions: IUseGetMediaApiOptions = {},
): IApiResponse<T> => {
    const { method, params } = keyHook;
    const options = { ...defaultOptions, ...customOptions };
    const { disabledQueries } = useAppContext();
    const { token: clientContextToken, setUpdateTime } = useClientContextToken();

    const { queryOptions, disableCache } = options;
    const auth = useAuth();
    const configuration = useConfiguration();
    const clientId = useUserModule().getClientId();

    const queryClient = useQueryClient();

    const queryKey = createCacheKey(keyHook, auth);

    const cachedQuery = queryClient.getQueryData(queryKey);

    const enabled = !!params && !disabledQueries.includes(method);

    const service = getServiceMethod(keyHook, configuration);
    const isGetRequest = service?.getUrl && clientContextToken;
    const cacheTime = isGetRequest || disableCache ? 0 : undefined;

    const { data: rpcResponseData, isLoading, isFetching, refetch } = useQuery(
        queryKey,
        () => {
            if (isServer) {
                console.error('Should not fetch in hook in SSR', queryKey);
            }
            return getMediaFetcher(
                keyHook,
                customOptions,
                { ...auth, clientId },
                configuration,
                undefined,
                clientContextToken,
            );
        },
        {
            cacheTime,
            enabled,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            ...queryOptions,
        },
    );

    const error = useMemo(
        () => rpcResponseData?.error && new ResponseErrorModel(rpcResponseData.error),
        [rpcResponseData],
    );

    useSessionExpired({ error });

    const tokenRefreshDate = rpcResponseData?.headers?.['x-session-update-date'];
    const isRequestForContextToken = method === GET_MEDIA_METHODS.GET_CLIENT_CONTEXT_TOKEN;
    if (!isRequestForContextToken && tokenRefreshDate) {
        // INFO(ksyrytczyk): Z GM przychodzi prawilny timestamp czyli w sekundach, a tutaj potrzebujemy milisekundy
        const refreshDateInMs = Number(tokenRefreshDate) * 1000;
        setUpdateTime(refreshDateInMs);
    }

    return {
        data: rpcResponseData?.result,
        error,
        isFetching: cachedQuery ? isFetching : isLoading,
        refetch,
    };
};

export function useGetMediaApiMutation<IIn extends {}, IOut>(
    keyHook: IKeyHook,
    customOptions: IUseGetMediaApiOptions = {},
): IGetMediaMutateResult<IIn, IOut> {
    const options = { ...defaultOptions, ...customOptions };
    const [isPending, setIsPending] = useState(false);
    const queryClient = useQueryClient();
    const auth = useAuth();
    const configuration = useConfiguration();
    const clientId = useUserModule().getClientId();
    const { token: clientContextToken } = useClientContextToken();

    const { mutateAsync, data: rpcData, reset, status } = useMutation<
        IHttpResponse<IOut>,
        JSONRpcErrorInterface,
        IIn,
        unknown
    >((inputParams) => {
        return getMediaFetcher(
            {
                namespace: keyHook.namespace,
                method: keyHook.method,
                params: {
                    ...keyHook.params,
                    ...inputParams,
                },
            },
            customOptions,
            { ...auth, clientId },
            configuration,
            undefined,
            clientContextToken,
        );
    });

    const getMediaMutate = useCallback<IGetMediaMutateFn<IIn, IOut>>(
        async (params: IIn) => {
            if (isServer) {
                throw new Error('Mutation hook usage on server side is forbidden!');
            }
            setIsPending(true);
            const response = await mutateAsync(params);
            const invalidationPromises: Promise<any>[] = [];

            if (options.updateAuthSession) {
                invalidationPromises.push(
                    queryClient.invalidateQueries({
                        predicate: (query) => query.queryKey[1] === GET_MEDIA_METHODS.GET_SESSION,
                    }),
                );
            }

            if (options.invalidate) {
                options.invalidate.forEach((invalidationKeys) => {
                    invalidationPromises.push(queryClient.invalidateQueries(invalidationKeys));
                });
            }

            await Promise.all(invalidationPromises);

            setIsPending(false);

            return response?.result
                ? { ok: true as const, data: response.result }
                : { ok: false as const, error: new ResponseErrorModel(response?.error) };
        },
        [auth, mutateAsync, options],
    );

    const error = useMemo(
        () => (rpcData?.error ? new ResponseErrorModel(rpcData.error) : undefined),
        [rpcData?.error],
    );

    return [
        getMediaMutate,
        {
            data: rpcData?.result ?? undefined,
            error: error,
            isFetching: isPending,
            reset,
            status,
        },
    ];
}
