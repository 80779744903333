import { KEYS } from './keys';

const XboxKeys = {
    [KEYS.BACK]: 196,
    [KEYS.ENTER]: 195,
    [KEYS.UP]: 203,
    [KEYS.DOWN]: 204,
    [KEYS.LEFT]: 205,
    [KEYS.RIGHT]: 206,
};

export function buildXboxKeysList() {
    //@ts-ignore
    window.navigator.gamepadInputEmulation = 'keyboard';
    return XboxKeys;
}
