import { useUserModule } from '@/contexts/user-module/user-module.hooks';

export const useIsUserLogged = () => {
    const userModule = useUserModule();

    if (typeof window !== 'undefined') {
        (window as any).userModule = userModule;
    }

    return userModule.isLogged();
};
