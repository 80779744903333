import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { useMemo } from 'react';
import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { GetLiveChannelsFlatNavigationOut } from '@/interfaces/from-schemas/navigation/getLiveChannelsFlatNavigationOut';

const useGetChannelListFlatNavigation = (
    params: Record<string, any>,
): IApiResponse<FlatFilterListSchema[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    const filterLists = useMemo(
        () =>
            (data as GetLiveChannelsFlatNavigationOut)?.filterLists?.filter(({ name, filters }) => {
                return { name, filters };
            }) || [],
        [data],
    );

    return {
        data: filterLists,
        error,
        isFetching,
    };
};

export default useGetChannelListFlatNavigation;
