import { formatDate } from '@/helpers/date.helper';
import { gmStatusToAccessStatus } from '@/helpers/product-access.helper';
import { CheckProductAccessOut } from '@/interfaces/from-schemas/drm/checkProductAccessOut';
import { CheckProductsAccessOut } from '@/interfaces/from-schemas/drm/checkProductsAccessOut';
import { ACCESS_STATUS } from '@/interfaces/product-access.interface';
import { FORMAT_DATE_MODE } from '@/types/date.type';

export class CheckProductAccessModel {
    private status: ACCESS_STATUS;
    private statusDescription: string;
    private statusUserMessage: string;
    private externalActivationType?: string;
    private id?: string;
    private validTo?: string;

    constructor(
        accessDefinition: CheckProductAccessOut,
        productDefinition?: CheckProductsAccessOut[number]['product'],
    ) {
        this.status = gmStatusToAccessStatus(accessDefinition.status);
        this.statusDescription = accessDefinition.statusDescription;
        this.externalActivationType = accessDefinition.externalActivationType;
        this.statusUserMessage = accessDefinition.statusUserMessage || '';
        this.validTo = accessDefinition.validTo;

        if (productDefinition) {
            this.id = productDefinition.id;
        }
    }

    public getId(): string | undefined {
        return this.id;
    }

    public hasAccess(): boolean {
        return this.status === ACCESS_STATUS.HAS_ACCESS;
    }

    public getStatusDescription(): string {
        return this.statusDescription;
    }

    public getStatusUserMessage(): string {
        return this.statusUserMessage;
    }

    public hasDisneyActivation(): boolean {
        return this.externalActivationType === 'disney';
    }

    public getValidTo(format?: FORMAT_DATE_MODE): string | undefined {
        return format && this.validTo ? formatDate(this.validTo, format) : this.validTo;
    }
}
