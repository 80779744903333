import { useContext } from 'react';
import { SubtitlesSelectedOptions } from '@/interfaces/player.interface';
import { PlayerSubtitlesOptionsContext } from '@/contexts/player/subtitles.context';

export const useSubtitlesOptions = () => {
    const { setSubtitlesOptions, subtitlesOptions } = useContext(PlayerSubtitlesOptionsContext);

    const updateSubtitleSize = (sizeValue: number) => {
        setSubtitlesOptions((previousOption) => {
            const size = previousOption.size.map((option) => {
                return {
                    ...option,
                    active: option.value === sizeValue,
                };
            });

            return { ...previousOption, size };
        });
    };
    const getActiveOptions = () => {
        return {
            size: subtitlesOptions.size.find((o) => o.active)?.value,
        } as SubtitlesSelectedOptions;
    };

    return { subtitlesOptions, updateSubtitleSize, getActiveOptions };
};
