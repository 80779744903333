enum AdbToken {
    CONST_SW_VERSION = 'const.sw.version',
    CONST_HW_VERSION = 'const.hw.version',
    CONST_HW_SERIALNUMBER = 'const.hw.serialnumber',
    CONST_DVR_FULL_SIZE_THRESHOLD_GB = 'const.dvr.full_size_threshold_GB',
    CONST_DVR_TIMESHIFT_SIZE_THRESHOLD_GB = 'const.dvr.timeshift_size_threshold_GB',
    CONST_DVR_PTV_SIZE_THRESHOLD_GB = 'const.dvr.ptv_size_threshold_GB',
    CONST_PARENTAL_RATING_DVB_RANGE = 'const.parental_rating.dvb_range',
    CONST_PARENTAL_RATING_DVB_MAX_VALUE = 'const.parental_rating.dvb_max_value',
    CAIVMXWEB_VCAS_BOOT_ADDRESS = 'caivmxweb.vcas_boot_address',
    VERIMATRIX_SERVER = 'verimatrix.server',
    VERIMATRIX_STATUS = 'verimatrix.status',
    VERIMATRIX_ERROR = 'verimatrix.error',
    CFG_SYSTEM_PIN = 'cfg.system.pin',
    CFG_FTI_DONE = 'cfg.fti_done',
    CFG_REMOTECONF_ENABLED_FEATURES = 'cfg.remoteconf.enabled_features',
    CFG_APP_OSD_RESOLUTION = 'cfg.app_osd_resolution',
    VAR_LOCAL_SUBSCRIBER_LOCKOUT_LOCKED = 'var.local_subscriber_lockout.locked',
    VAR_CONNECTION_LOCAL_STATUS = 'var.connection.local.status',
    VAR_CONNECTION_INTERNET_STATUS = 'var.connection.internet.status',
    VAR_PWRCTRL_REBOOT_REASON = 'var.pwrctrl.reboot.reason',
    VAR_MULTIROOM_STATUS = 'var.multiroom.status',
    VAR_MULTISCREEN_STATUS = 'var.multiscreen.status',
    VAR_PLAYER_SESSIONS = 'var.player.sessions',
    VAR_EPG_MAX_PAST_DAYS = 'var.epg.max_past_days',
    VAR_EPG_OVER_IP_RETRIEVAL_STATE = 'var.epg_over_ip.retrieval_state',
    VAR_NETIF_QOS_DSCP = 'var.netif.qos_dscp',
    CONST_NAGRA_CAID = 'const.nagra.caid',
    CONST_NAGRA_NUID = 'const.nagra.nuid',
    CONST_NAGRA_SC_NUMBER = 'const.nagra.sc.number',
    CONST_NAGRA_DVL_CHIPSET_TYPE = 'const.nagra.dvl.chipset.type',
    CONST_IRDETOCCA_LOCK_ID = 'const.irdetocca.lock_id',
    CONST_IRDETOCCA_CLIENT_ID = 'const.irdetocca.client_id',
    CONST_IRDETOCCA_CSSN = 'const.irdetocca.cssn',
    CONST_IRDETOCCA_SN = 'const.irdetocca.sn',
    VAR_IRDETOCCA_CA_SYSTEM_ID = 'var.irdetocca.ca_system_id',
    VAR_MODEL_NAME = 'var.cp.model_name',
    VAR_CP_CHIP_ID = 'var.cp.chip_id',
}

export default AdbToken;
