import { useEffect, useState, useCallback } from 'react';
import { usePlayerInstance } from './use-player-instance';
import { usePlayerStatus } from './use-player-status';
import { useShowBuffering } from './use-show-buffering';

const PLAYBACK_STOP_DELAY = 1500;

export const useConcurrentPlaybackError = () => {
    const showBuffering = useShowBuffering();
    const { error } = usePlayerStatus();
    const player = usePlayerInstance();

    const [playbacks, setPlaybacks] = useState<IPlayer.DrmUserPlayback[]>([]);
    const [stopPlayback, setStopPlayback] = useState<IPlayer.PlaybackConnection>();

    useEffect(() => {
        player.getUserPlaybacks().then((response) => {
            setPlaybacks(response);
        });
    }, []);

    const handleStopPlayback = useCallback((connection: IPlayer.PlaybackConnection) => {
        setStopPlayback(connection);

        setTimeout(() => {
            player.stopConcurrentPlayback(connection.id);
        }, PLAYBACK_STOP_DELAY);
    }, []);

    const isPlaybackPending = useCallback(
        (connectionId: string) => connectionId === stopPlayback?.id,
        [stopPlayback],
    );

    return {
        playbacks,
        showBuffering,
        error,
        isPlaybackPending,
        handleStopPlayback,
    };
};
