import {
    FormatNormalizeFunc,
    InputRefObject,
    SetInputValueCallback,
} from '@/contexts/keyboard/types';

export enum LAYOUTS {
    DEFAULT = 'default',
    DEFAULT_SHIFT = 'default_shift',
    SPECIAL = 'special',
    SPECIAL_SHIFT = 'special_shift',
}

export enum SpecialKeysDefinition {
    SUBMIT = '{OK}',
    BACKSPACE = '{backspace}',
    SPACE = '{space}',
    ARROW_LEFT = '{arrowleft}',
    ARROW_RIGHT = '{arrowright}',
    SHIFT = '{shift}',
    SPECIAL_CHAR_SWITCH = '{=/#}',
}

export type VirtualKeyboardProps = {
    defaultHoveredButton?: string;
    submitLabel?: string;
    setVisible: (value: boolean) => void;
    inputRef: InputRefObject | null;
    setInputValue: SetInputValueCallback;
    format?: FormatNormalizeFunc;
    normalize?: FormatNormalizeFunc;
};

export type KeyboardKey = SpecialKeysDefinition | string;
export type KeyboardLayout = KeyboardKey[][];
export type KeyPosition = [number, number];
