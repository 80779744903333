import { createGlobalStyle, ThemeProps } from 'styled-components';
import { highlightTranslationStyles } from './highlightTranslation.styles';

const GlobalStyle = createGlobalStyle<ThemeProps<any>>`
    html {
        height: 100%;
        font-size: 41.95%;

        @media (min-width: 1920px) {
            font-size: 62.5%;
        }
    }

    body {
        height: 100%;
        margin: 0;
        background: ${(props) => props.theme.background};
        color: ${(props) => props.theme.text.color.primary};
        display: block;

        /** Workaround na tizena, gdzie nieprzezroczyste tło przykrywa odtwarzany materiał */
        &.transparent {
            background: none;
        }
    }

    body, input, button {
        font-family: '${(props) => props.theme.text.fontFamily}';
    }

    ${highlightTranslationStyles}

    #__next {
        height: 100%;
    }
`;

export default GlobalStyle;
