import { CategoryModel } from './category.model';
import { MediaListItemModel } from '../media-list-item.model';
import {
    CategorySchema,
    MediaListItemSchema,
} from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationOut';
import { GetCategoryContentOut } from '@/interfaces/from-schemas/navigation/getCategoryContentOut';
import { CPID } from '@/types/media.type';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

class CategoryContentModel {
    private readonly count: number = 0;
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private readonly mediaList: MediaListItemModel[] = [];
    private readonly categoryList: CategoryModel[] = [];
    private readonly total: number = 0;

    constructor(definition: GetCategoryContentOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.mediaList = [];
            this.categoryList = [];

            definition.results.forEach((result: CategorySchema | MediaListItemSchema) => {
                if (result.cpid === CPID.CATEGORY_OR_PACK) {
                    this.categoryList.push(new CategoryModel(result as CategorySchema));
                } else {
                    this.mediaList.push(new MediaListItemModel(result as MediaListItemSchema));
                }
            });
        }
    }

    public getCount(): number {
        return this.count;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getMediaList(): MediaListItemModel[] {
        return this.mediaList;
    }

    public getCategoryList(): CategoryModel[] {
        return this.categoryList;
    }

    public getTotal(): number {
        return this.total;
    }

    public getImageDisplayMode(): IMAGE_DISPLAY_MODE {
        const potstersArr = this.getMediaList().filter((item) => item.getPosters().length);
        if (potstersArr.length) {
            return IMAGE_DISPLAY_MODE.POSTERS;
        } else {
            return IMAGE_DISPLAY_MODE.THUMBNAILS;
        }
    }
}

export default CategoryContentModel;
