import { IMAGE_DISPLAY_MODE, IDimensions, ImageInterface, Size } from '@/types/cover-utils.type';
import { size } from '@/targets/default/mediaQuery';
import { getScrollSliderConfig } from '@/components/browser/shared/Slider/use-get-scroll-slider-config';

const imageSize = process.env.imageSize;
const dimension: IDimensions = imageSize;
const tolerance = 0.05;
const ratio16x9 = 0.5625;
const minRatio16x9 = ratio16x9 * (1 - tolerance);
const maxRatio16x9 = ratio16x9 * (1 + tolerance);
const ratio7x10 = 0.7;
const minRatio7x10 = ratio7x10 * (1 - tolerance);
const maxRatio7x10 = ratio7x10 * (1 + tolerance);

const trim = {
    thumbnails: ({ size }: Size) => size.width <= imageSize.background.width,
    posters: ({ size }: Size) => size.height <= imageSize.background.height,
};
const range = (value: number, min: number, max: number) => value >= min && value <= max;
const aspect = {
    thumbnails: ({ size }: Size) => range(size.height / size.width, minRatio16x9, maxRatio16x9),
    posters: ({ size }: Size) => range(size.width / size.height, minRatio7x10, maxRatio7x10),
};

export const coverUtil = (source: ImageInterface[], imageDisplayMode: IMAGE_DISPLAY_MODE) => {
    const collection = source;

    try {
        const found = collection
            .filter((item) => trim[imageDisplayMode](item) && aspect[imageDisplayMode](item))
            .reduce((bestFit, candidate) => match(bestFit, candidate, imageDisplayMode));
        return found;
    } catch (e) {
        return undefined;
        // wyślij raport do firebase/app events
    }
};

const match = (
    bestFit: ImageInterface,
    candidate: ImageInterface,
    imageDisplayMode: IMAGE_DISPLAY_MODE,
): ImageInterface => {
    if (!bestFit || !bestFit.size || !candidate || !candidate.size) {
        throw new Error('Invalid arguments provided to match function');
    }

    const windowWidth = window?.innerWidth;

    const targetDimension = dimension[imageDisplayMode];
    const dimensionKey = imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS ? 'width' : 'height';

    const scale = calculateScale(windowWidth, imageDisplayMode) || imageSize?.scale || 1;

    const bestFitDiff = calculateDifference(
        bestFit.size[dimensionKey],
        targetDimension[dimensionKey],
        scale,
    );
    const candidateDiff = calculateDifference(
        candidate.size[dimensionKey],
        targetDimension[dimensionKey],
        scale,
    );

    return candidateDiff < bestFitDiff ? candidate : bestFit;
};

const calculateScale = (windowWidth: number, imageDisplayMode: IMAGE_DISPLAY_MODE) => {
    const config = getScrollSliderConfig(windowWidth, imageDisplayMode);
    const { visibleElements } = config;
    const sizeKeys = Object.keys(size);
    const dimensionKey = 'width';

    for (let i = 0; i < sizeKeys.length; i++) {
        const numberOfVisibleElements = visibleElements || 1;

        const scale =
            +size[sizeKeys[i]] /
            (numberOfVisibleElements + 1) /
            imageSize[imageDisplayMode][dimensionKey];

        if (windowWidth >= size[sizeKeys[i]] && visibleElements) {
            return scale;
        }
    }
};

const calculateDifference = (size: number, targetSize: number, scale: number): number =>
    Math.abs(size * scale - targetSize);
