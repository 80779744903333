import { ListElementInterface } from '@/interfaces/list-element.interface';
import { protectURL } from './rewrite-protocol.helper';

export const getBackgroundImage = (item: ListElementInterface, disableBg = false): string => {
    const backGroundSrc =
        item.getRecommendationItem?.().getBackgroundSrc() ||
        item.getAsset().getBackgroundSrc() ||
        '';

    return disableBg ? '' : protectURL(backGroundSrc);
};
