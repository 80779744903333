import { ICONS } from '@/components/shared/Icon';
import { NavigationButtonsVariant } from '@/components/views/common/types';
import t from '@/lib/i18n';

export const mainButtonText = (variant: NavigationButtonsVariant, subCategoriesLabel?: string) => {
    switch (variant) {
        case NavigationButtonsVariant.CATALOG:
            return t('pages.vod.recommended');
        case NavigationButtonsVariant.RECOMMENDED:
            return t('pages.vod.catalog');
        case NavigationButtonsVariant.SUBCATEGORY:
            return subCategoriesLabel ?? t('seasons');
        case NavigationButtonsVariant.PACKET_SUBCATEGORY:
            return t('categories');
    }
};

export const mainButtonIcon = (variant: NavigationButtonsVariant) => {
    switch (variant) {
        case NavigationButtonsVariant.CATALOG:
            return ICONS.HAMBURGER_MENU;
        case NavigationButtonsVariant.RECOMMENDED:
            return ICONS.CATALOG_BTN;
        case NavigationButtonsVariant.SUBCATEGORY:
        case NavigationButtonsVariant.PACKET_SUBCATEGORY:
            return ICONS.SERIES;
    }
};

export const mainButtonActiveWidth = (variant: NavigationButtonsVariant) => {
    switch (variant) {
        case NavigationButtonsVariant.CATALOG:
            return 29.0;
        case NavigationButtonsVariant.RECOMMENDED:
            return 26.9;
        case NavigationButtonsVariant.SUBCATEGORY:
            return 17;
        case NavigationButtonsVariant.PACKET_SUBCATEGORY:
            return 20;
    }
};
