import {
    CallToActionConnectPropertySchema,
    CallToActionRulesIdPropertySchema,
    CallToActionSchema,
} from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';

enum CTA_ACTION_TYPES {
    CONNECT = 'connect',
    ACCEPT_RULES = 'accept_rules',
}

enum CTA_ACCOUNT_TYPES {
    PLUS = 'plus',
}

type RequiredActionType = NonNullable<CallToActionSchema['requiredActions']>[number];
interface PropertiesI
    extends Partial<CallToActionConnectPropertySchema>,
        Partial<CallToActionRulesIdPropertySchema> {
    type: string;
}
export class RequiredActionModel {
    private description?: string;
    private connectProperty?: CallToActionConnectPropertySchema;
    private rulesIdProperty?: CallToActionRulesIdPropertySchema;

    constructor(definition: RequiredActionType) {
        this.description = definition.description;

        if (definition.properties?.type) {
            switch (definition.properties.type) {
                case CTA_ACTION_TYPES.CONNECT:
                    this.connectProperty = <CallToActionConnectPropertySchema>definition.properties;
                    break;

                case CTA_ACTION_TYPES.ACCEPT_RULES:
                    this.rulesIdProperty = <CallToActionRulesIdPropertySchema>definition.properties;
                    break;

                default:
                // noop
            }
        }
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public getConnectProperty(): CallToActionConnectPropertySchema | undefined {
        return this.connectProperty;
    }

    public getRulesIdProperty(): CallToActionRulesIdPropertySchema | undefined {
        return this.rulesIdProperty;
    }

    public isPlusAction(): boolean {
        return this.connectProperty?.accountType === CTA_ACCOUNT_TYPES.PLUS;
    }
}

export class CallToActionModel {
    private description?: string;
    private requiredActions: RequiredActionModel[] = [];

    constructor(definition: CallToActionSchema) {
        this.description = definition.description;

        if (definition.requiredActions) {
            this.requiredActions = definition.requiredActions.map(
                (requiredAction) => new RequiredActionModel(requiredAction),
            );
        }
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public getRequiredActions(): RequiredActionModel[] {
        return this.requiredActions;
    }

    public isPlusAction(): boolean {
        return this.requiredActions.some((requiredAction) => requiredAction.isPlusAction());
    }
}
