import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { Query } from 'react-query';
import { ActualQueryInterface, LOADER_QUERIES } from './types';

export const getQueryKeyAndStatus = (queryCache: Query[]): ActualQueryInterface[] => {
    const cacheValues = Object.values(queryCache);

    return cacheValues.map((value: Query) => {
        const getMediaMethod = value.queryKey[1] as GET_MEDIA_METHODS;
        const isFetching = value.state.isFetching;

        return { getMediaMethod, isFetching };
    });
};

export const checkIfImportantQueryIsFetching = (actualQueries: ActualQueryInterface[]): boolean =>
    actualQueries.some(
        (query) => LOADER_QUERIES.includes(query.getMediaMethod) && query.isFetching,
    );
