import { GM_OS } from '@/types/platform.type';

import { getMatch } from './helpers';

type MatchMap<T> = [RegExp | GM_OS, T][];

const osDetectionMap: MatchMap<GM_OS> = [
    [/vidaa/i, GM_OS.VIDAA],
    [/WhaleTV/i, GM_OS.WHALE],
    [/philips/i, GM_OS.SAPHI],
    [/googlebot/i, GM_OS.Other],
    [/web0s;/i, GM_OS.WebOS],
    [/tizen(.+)TV/i, GM_OS.Tizen],
    [/hzn(.([0-9]).*)*/i, GM_OS.UPC],
    [/linux ()([a-z._\d]+)/i, GM_OS.Linux],
    [/mac os x/i, GM_OS.MacOS],
    [/mac os x.*?([._\d]+)/i, GM_OS.MacOS],
    [/os ([._\d]+) like mac os/i, GM_OS.iOS],
    [/android/i, GM_OS.Android],
    [/windows\s*(?:nt)?\s*([._\d]+)/i, GM_OS.Windows],
    [/windows phone.*?([._\d]+)/i, GM_OS.WindowsPhone],
    [/panasonic/i, GM_OS.PANASONIC],
];

export const recognizeOS = (userAgent: string): GM_OS =>
    getMatch(osDetectionMap, userAgent) ?? GM_OS.Other;
