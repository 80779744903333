import { useGetAvatars } from '@/api/system/use-get-avatars';
import { IconGrid } from '@/components/views/Profile/IconGrid';
import { IconGridElementInterface } from '@/interfaces/icon-grid-element-interface';
import React from 'react';
import { ProfileIconGridProps } from './types';

const MAX_PROFILES = process.env.user.maxProfiles;

export const ProfileIconGrid = ({ onSelect }: ProfileIconGridProps) => {
    const { data: icons } = useGetAvatars();

    return (
        <>
            {icons && (
                <IconGrid
                    data={icons.map((icon) => {
                        return {
                            iconId: icon.getId(),
                            iconUrl: icon.getSrc(),
                        } as IconGridElementInterface;
                    })}
                    col={MAX_PROFILES}
                    onSelectAction={onSelect}
                />
            )}
        </>
    );
};
