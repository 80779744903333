import { PlayableButton } from '@/components/shared/Buttons';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { OptionLayout } from '@/components/views/Account/AccountOption/OptionLayout';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { Status, StatusWrapper } from './styles';
import { EuropeanUnionOptionProps } from './types';

export const EuropeanUnionOption = ({ confirmed }: EuropeanUnionOptionProps) => {
    const euAccessStatus = confirmed ? 'confirmed' : 'unconfirmed';
    const renderContent = () => {
        return (
            <React.Fragment>
                <MainText
                    text={t('pages.account.option.header.european-union')}
                    mode={MAINTEXT_KIND.PAGE_SUBTITLE}
                    lines={3}
                    gutter={GUTTER.SMALL}
                />
                <MainText
                    text={t(`pages.account.option.description.${euAccessStatus}`, {
                        portal: t('domain'),
                    })}
                    fullText
                    mode={MAINTEXT_KIND.PAGE_DESCRIPTION}
                />
                <StatusWrapper>
                    <MainText
                        text={t('pages.account.option.european-union-status')}
                        fullText
                        mode={MAINTEXT_KIND.PAGE_DESCRIPTION}
                        gutter={GUTTER.NONE}
                    />
                    <Status>
                        <MainText
                            text={t(`pages.account.option.${euAccessStatus}`)}
                            fullText
                            mode={MAINTEXT_KIND.PAGE_DESCRIPTION}
                            gutter={GUTTER.NONE}
                        />
                    </Status>
                </StatusWrapper>
            </React.Fragment>
        );
    };

    const renderButton = () => {
        if (!confirmed) {
            return (
                <PlayableButton
                    text={t('button.account.phone-number')}
                    onButtonAction={() => Router.pushRoute(ROUTES.EU_ACCESS)}
                    transparentOnInactive
                    gutter={GUTTER.NONE}
                    data-testing="button.account.phone-number"
                />
            );
        }
    };

    return <OptionLayout left={renderContent()} right={renderButton()} />;
};
