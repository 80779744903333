import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';

export const redirectToTargetRoute = (
    { target, next, promotionCode, qrCode, maskedMsisdn }: IParams,
    shouldReplace = false,
) => {
    const decoded = decodeURIComponent(target);
    const url = new URL(decoded);
    const route = url.href as ROUTES;

    if (next) {
        url.searchParams.set('next', next);
    }
    if (promotionCode) {
        url.searchParams.set('promotionCode', promotionCode);
    }
    if (qrCode) {
        url.searchParams.set('qrCode', qrCode);
    }
    if (maskedMsisdn) {
        url.searchParams.set('maskedMsisdn', maskedMsisdn);
    }
    url.searchParams.set('fromQuickRegister', 'true');

    if (shouldReplace) {
        Router.replaceRoute(route);
    } else {
        Router.pushRoute(route);
    }
};
