import { createContext, useState } from 'react';
import { VolumeContextInterface, VolumeContextProviderProps } from './types';

export const VolumeContext = createContext<VolumeContextInterface>({
    percentageVolume: undefined,
    setPercentageVolume: () => null,
    isMuted: undefined,
    setIsMuted: () => null,
});

export const PlayerVolumeContextProvider = ({ children }: VolumeContextProviderProps) => {
    const [percentageVolume, setPercentageVolume] = useState<number>();
    const [isMuted, setIsMuted] = useState<boolean>();

    return (
        <VolumeContext.Provider
            value={{ percentageVolume, setPercentageVolume, isMuted, setIsMuted }}
        >
            {children}
        </VolumeContext.Provider>
    );
};
