import { RulesSchema, RulesSourceSchema } from '@/interfaces/from-schemas/navigation/getProductOut';

export class RulesModel {
    private id: RulesSchema['id'];
    private type: RulesSchema['type'];
    private name: RulesSchema['name'];
    private description: RulesSchema['description'];
    private agreeDescription: RulesSchema['agreeDescription'];
    private version: RulesSchema['version'];
    private required: RulesSchema['required'];
    private requiredAnswer: RulesSchema['requiredAnswer'];
    private sources: RulesSchema['sources'];

    constructor(definition: RulesSchema) {
        this.id = definition.id;
        this.type = definition.type;
        this.name = definition.name;
        this.description = definition.description;
        this.agreeDescription = definition.agreeDescription;
        this.version = definition.version;
        this.required = definition.required;
        this.requiredAnswer = definition.requiredAnswer;
        this.sources = definition.sources;
    }

    public getId(): number {
        return this.id;
    }

    public getType(): string {
        return this.type;
    }

    public getName(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }

    public getAgreeDescription(): string {
        return this.agreeDescription;
    }

    public getAgreeLabel(): string {
        return `${this.agreeDescription} ${this.name}`;
    }

    public getVersion(): number {
        return this.version;
    }

    public isRequired(): boolean {
        return this.required;
    }

    public isRequiredAnswer(): boolean {
        return this.requiredAnswer;
    }

    public getSources(): RulesSourceSchema[] {
        return this.sources;
    }

    public getPdfSource(): string | undefined {
        const sources = this.getSources();
        if (!sources) {
            return;
        }

        return sources.find((el) => el.type === 'pdf')?.url;
    }

    public isRodoRule(): boolean {
        return this.type === 'rodo' && !this.sources.length;
    }
}
