import { PlayableButton } from '@/components/shared/Buttons';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { AdultContentAgreementProps } from './types';

const AdultContentAgreementBody = ({ handleConfirmation }: AdultContentAgreementProps) => {
    return (
        <>
            <BackButton />
            <Message
                icon={ICONS.WARNING_CIRCLE_FILLED}
                title={t('pages.media.adult-content-agreement')}
            />
            <FormContainer>
                <PlayableButton
                    text={t('back-button-label')}
                    onButtonAction={Router.back}
                    defaultFocused
                />
                <PlayableButton text={t('ok-button-label')} onButtonAction={handleConfirmation} />
            </FormContainer>
        </>
    );
};

export const AdultContentAgreement = ({ handleConfirmation }: AdultContentAgreementProps) => (
    <FullscreenNavContainer
        body={<AdultContentAgreementBody handleConfirmation={handleConfirmation} />}
    />
);
