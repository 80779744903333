import { FilterSchema, InputFilterSchema } from '@/interfaces/filters-interface';

export const finalFilterList = (allFilters: FilterSchema[], filteredList: string[]) => {
    const activeFilterList: FilterSchema[] = [];

    // allFilters- tablica filtrów z GM
    allFilters.forEach((filter) => {
        // filteredList- lista filtrów zwrócona z komponentu filtrów
        if (filteredList.indexOf(filter.value) != -1) {
            activeFilterList.push(filter);
        }
    });

    // finalResult - finalna werjsa tablicy
    const finalResult: InputFilterSchema[] = [];
    activeFilterList.forEach((element) => {
        let match = false;
        finalResult.forEach((filter) => {
            if (element.type == filter.type) {
                match = true;
            }
        });

        if (!match) {
            const obj: InputFilterSchema = {
                type: element.type,
                value: [element.value],
            };

            finalResult.push(obj);
        } else {
            finalResult.forEach((i) => {
                if (element.type == i.type && Array.isArray(i.value)) {
                    i.value.push(element.value);
                }
            });
        }
    });

    return finalResult;
};
