import { ListElementInterface } from '@/interfaces/list-element.interface';
import { getListTypeActivityEvents } from '@/helpers/stats.helper';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { useRouter } from '@/routing';
import { ELEMENT_CLICK_TYPE } from '@/interfaces/element-click-type.interface';
import { useCallback } from 'react';
import { Debug } from '@/utils/debug.util';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { CustomDataReportingSchema } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';

export const useListElementClicked = (
    type = ELEMENT_CLICK_TYPE.DEFAULT,
    config?: { list?: StaffRecommendationListModel; rowIndex?: number },
) => {
    const statsModule = useStatsModule();
    const router = useRouter();
    const debug = new Debug('ListElement');

    const onDefaultItemClick = (itemData: ListElementInterface, itemIndex?: number) => {
        const listType = getListTypeActivityEvents({
            routerPath: router.route,
        });

        statsModule.hitAppUserItemClicked({
            list: {
                type: listType,
            },
            contentItem: {
                position: itemIndex,
                type: itemData.getType(),
                value: itemData.getId(),
                source: '',
            },
        });
    };

    const onRecommendationItemClick = (itemData: ListElementInterface, itemIndex?: number) => {
        const recommendationItem = itemData.getRecommendationItem?.();

        if (recommendationItem) {
            const customData = recommendationItem.getCustomData();
            let sourceCustomData = '';
            if (customData && 'reporting' in customData) {
                const reporting = customData.reporting as CustomDataReportingSchema;
                sourceCustomData = reporting?.redevents?.source ?? '';
            }

            const listType = getListTypeActivityEvents({
                listModel: config?.list,
            });
            return statsModule.hitAppUserItemClicked({
                list: {
                    position: config?.rowIndex,
                    type: listType,
                    value: config?.list?.getId(),
                },
                contentItem: {
                    position: itemIndex,
                    type: recommendationItem.getType(),
                    value: recommendationItem.getValue(),
                    source: sourceCustomData,
                },
            });
        }

        return onDefaultItemClick(itemData, itemIndex);
    };

    const listElementClickedHandler = useCallback(
        async (itemData: ListElementInterface, itemIndex?: number) => {
            switch (type) {
                case ELEMENT_CLICK_TYPE.RECOMMENDATION:
                    return onRecommendationItemClick(itemData, itemIndex);
                default:
                    return onDefaultItemClick(itemData, itemIndex);
            }
        },
        [config?.list, config?.rowIndex, router.route, statsModule, type],
    );

    // interceptor służy do rozpozania, kiedy recommendationItem jest typu url, jest to potrzebne, ponieważ
    // listy typu url przekierowuja to zewnetrznych portali, co powoduje nie zapisanie się activityEvents
    // i z tego powodu musimu opóźnić przekierowanie
    const interceptor = async (itemData: ListElementInterface, itemIndex?: number) => {
        if (!itemData) return;

        const recommendationItem = itemData.getRecommendationItem?.();

        if (recommendationItem?.getType() === 'url') {
            try {
                await listElementClickedHandler(itemData, itemIndex);
            } catch (err) {
                debug.error('onItemClickedHandler error', err);
            }
        } else {
            listElementClickedHandler(itemData, itemIndex);
        }
    };

    return { onListElementClickedHandler: interceptor };
};
