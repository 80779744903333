import { ACCESS_STATUS } from '@/interfaces/product-access.interface';

export function gmStatusToAccessStatus(gmStatus: number): ACCESS_STATUS {
    switch (gmStatus) {
        case 0:
            return ACCESS_STATUS.HAS_ACCESS;

        case -1:
            return ACCESS_STATUS.NO_ACCESS;

        default:
            return ACCESS_STATUS.NO_ACCESS;
    }
}
