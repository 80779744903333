import { clearURLFromQueries } from '@/helpers/router/clear-url-from-queries.helper';
import { isStandalone } from '@/constants/portal-recognition';
import { useRouter } from '@/routing';
import { ROUTES_CONFIG } from '@/routing/routes';
import { IRouter } from '@/routing/types';

const isDynamicChunk = (chunk: string): boolean => chunk.charAt(0) === ':';

const clearQuery = (url: string) => {
    const index = url.indexOf('(');

    if (index > -1) {
        return url.slice(1, index);
    }
    return url.slice(1);
};

const chunkLength = (str: string) => str.split('/').length;

export const useRouterQuery = <T>() => {
    const { asPath, query, route } = useRouter();

    if (isStandalone) {
        return query;
    }

    const chunkList = clearURLFromQueries(asPath).split('/');

    const pattern = ROUTES_CONFIG.find(
        // porównaj wartość page i długość chunków w stringu (część routów dzieli tę samą page, np vod/episode-details)
        (r) => r.page === route.slice(1) && chunkLength(r.pattern) === chunkLength(asPath),
    )?.pattern;

    const routerQuery: IRouter['query'] = {};

    const routerQueries = pattern?.split('/').reduce((acc, curr, index) => {
        if (isDynamicChunk(curr)) {
            const chunk = chunkList[index];
            Object.assign(acc, { [clearQuery(curr)]: chunk });
        }

        return acc;
    }, {});

    if (routerQueries) {
        Object.assign(routerQuery, routerQueries);
    }

    if (query.next) {
        Object.assign(routerQuery, { next: query.next });
    }
    if (query.maskedMsisdn) {
        Object.assign(routerQuery, { maskedMsisdn: query.maskedMsisdn });
    }

    return routerQuery as T;
};
