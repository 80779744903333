import styled, { css } from 'styled-components';

export const Text = styled.p<{ type: 'bold' | 'normal' }>`
    font-weight: ${({ type }) => type};
    ${({ type }) =>
        type === 'bold' &&
        css`
            color: ${({ theme }) => theme.text.color.highlight};
        `};
`;
