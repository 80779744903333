import { ButtonStyled } from './styles';
import { ButtonProps } from './types';

export const Button = ({
    active,
    btnType,
    nativeType,
    fullWidth,
    'data-testing': dataTesting,
    children,
    className,
}: ButtonProps) => {
    return (
        <ButtonStyled
            active={active}
            btnType={btnType}
            nativeType={nativeType}
            fullWidth={fullWidth}
            data-testing={dataTesting}
            className={className}
        >
            {children}
        </ButtonStyled>
    );
};
