import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import {
    IApiResponse,
    GetMediaPromiseFunc,
    IApiServerResponse,
} from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { TvChannelProgramListItemSchema } from '@/interfaces/from-schemas/navigation/getChannelsCurrentProgramOut';
import { CPID } from '@/types/media.type';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_TV_CHANNEL_PROGRAM_ITEM;

//TODO(jduda) zmienic na interfejs jak bedzie z GM mozliwe wygenerowanie
interface IParams {
    tvChannelProgramItemId: string;
}

interface ITvChannelProgramItem extends TvChannelProgramListItemSchema {
    channelIds: Array<{ cpid: CPID; id: string }>;
}

const getTvChannelProgramItemPromise = (
    params: IParams,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<IApiServerResponse<ITvChannelProgramItem>> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {});
};

export { getTvChannelProgramItemPromise };

const useGetTvChannelProgramItem = (
    params: IParams | undefined,
): IApiResponse<ITvChannelProgramItem> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data,
        error,
        isFetching,
    };
};

export default useGetTvChannelProgramItem;
