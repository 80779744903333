import styled from 'styled-components';

export const HelpNoteWrapper = styled.div`
    color: ${({ theme }) => theme.message.descriptionColor};
    font-size: ${({ theme }) => theme.message.descriptionSize};
    line-height: ${({ theme }) => theme.message.descriptionLineHeight};
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    white-space: pre-line;
    text-align: center;
`;

export const HelpNoteLinkWrapper = styled.a`
    color: ${({ theme }) => theme.text.color.highlight};
    text-decoration: none;
`;
