import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { IApiResponse, GetMediaPromiseFunc } from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';
import { ChannelsCurrentProgramModel } from '@/models/channels/channel-list-current-program.model';
import { GetChannelsCurrentProgramIn } from '@/interfaces/from-schemas/navigation/getChannelsCurrentProgramIn';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_CURRENT_PROGRAM;

const getChannelsCurrentProgramPromise = (
    params: GetChannelsCurrentProgramIn,
    getMediaApiPromise: GetMediaPromiseFunc,
) => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {});
};

export { getChannelsCurrentProgramPromise };

const useGetChannelsCurrentProgram = (
    params: GetChannelsCurrentProgramIn | false,
    infiniteStale = true,
): IApiResponse<ChannelsCurrentProgramModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: infiniteStale ? Infinity : 0,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new ChannelsCurrentProgramModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetChannelsCurrentProgram;
