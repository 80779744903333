import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useCallback } from 'react';

export const useRedirectToNextUrl = (shouldReplace = false) => {
    const { query } = useRouter();

    const redirect = useCallback(() => {
        const redirectToHomeFallback = () => {
            if (shouldReplace) {
                Router.replaceRoute(ROUTES.HOME);
            } else {
                Router.pushRoute(ROUTES.HOME);
            }
        };
        const redirectToQueryUrl = (url: string) => {
            try {
                /**
                 * Router.pushRoute powoduje błąd opisany w tickecie SWN-2576
                 * Błąd występuje wtedy, gdy w urlu znajduje się znak %
                 * Zostało to naprawione dopiero w wersji Next v12.0.10
                 *
                 * @see https://github.com/vercel/next.js/issues/12145
                 */
                window.location.href = decodeURIComponent(url);
            } catch (e) {
                // W przypadku błędu przy przekierowaniu na `nextUrl` kierujemy na stronę główną (SWN-2523)
                redirectToHomeFallback();
            }
            return;
        };
        if (query.prev) {
            redirectToQueryUrl(query.prev);
        }
        if (query.next) {
            redirectToQueryUrl(query.next);
        }

        // Jak nie ma parametru `next` w urlu, to domyślnie kierujemy na stronę główną
        redirectToHomeFallback();
    }, [query]);
    return redirect;
};

/*
    Powyższe rozwiazanie czyści kontekst aplikacji przy każdym przekierowanie na next.
    Ze względu na to, że przy nawigacji po stronie błędu potrzebujemy mieć możliwość
    powrotu bez utraty stanu w kontekście, powstaje alternatywa. Warto też chyba powoli
    zacząć przechodzić na to rozwiązanie w przyszłości i powoli redukować użycie powyższego.
*/

export const usePushNextRoute = () => {
    const { query } = useRouter();
    return useCallback(() => {
        if (query.next) {
            try {
                Router.pushRoute(extractPathFromNextParameter(query.next) as ROUTES);
            } catch (e) {
                Router.pushRoute(ROUTES.HOME);
            }
            return;
        }
        Router.pushRoute(ROUTES.HOME);
    }, [query]);
};

function extractPathFromNextParameter(nextParam: string) {
    const decodedUrl = decodeURIComponent(nextParam);
    const parsedUrl = new URL(decodedUrl);
    return parsedUrl.pathname;
}
