import { IPurchaseCode } from '@/api/payments/types';
import useGetPurchaseCodeData from '@/api/payments/use-get-purchase-code-data';
import useGetPurchaseCodeProduct from '@/api/navigation/use-get-purchase-code-product';
import { GetPurchaseCodeDataOut } from '@/interfaces/from-schemas/payments/getPurchaseCodeDataOut';
import { GetPurchaseCodeProductOut } from '@/interfaces/from-schemas/navigation/getPurchaseCodeProductOut';
import { ICodeDetails } from '@/components/views/Account/Code/types';

const prepareCodeDetailsData = (
    codeData: GetPurchaseCodeDataOut,
    codeProduct: GetPurchaseCodeProductOut,
): ICodeDetails | undefined => {
    if (codeData && codeProduct) {
        return { ...codeData, ...codeProduct };
    }
};

const useCheckCode = (params: IPurchaseCode) => {
    //Get code data
    const getCodeDataHook = useGetPurchaseCodeData(params);

    //Get code product
    const getCodeProductHook = useGetPurchaseCodeProduct(params);

    const data = prepareCodeDetailsData(getCodeDataHook.data, getCodeProductHook.data);

    return {
        data,
        error: getCodeDataHook.error ?? getCodeProductHook.error,
        isFetching: !!getCodeDataHook.isFetching || !!getCodeProductHook.isFetching,
    };
};

export default useCheckCode;
