import defaultKeyHook from '@/hooks/default-key';
import { GetCategoryContentAutoplayMediaIdIn } from '@/interfaces/from-schemas/navigation/getCategoryContentAutoplayMediaIdIn';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetCategoryContentAutoplayMediaIdOut } from '@/interfaces/from-schemas/navigation/getCategoryContentAutoplayMediaIdOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';

const getCategoryAutoplayMediaIdPromise = (
    params: GetCategoryContentAutoplayMediaIdIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetCategoryContentAutoplayMediaIdOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.GET_CATEGORY_CONTENT_AUTOPLAY_MEDIA_ID,
        params,
    );

    return getMediaApiPromise(keyHook, { appendAuthData: true }).then((res) => res.data);
};

export { getCategoryAutoplayMediaIdPromise };

const useGetCategoryAutoplayMediaId = (
    params?: GetCategoryContentAutoplayMediaIdIn,
): IApiResponse<GetCategoryContentAutoplayMediaIdOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.GET_CATEGORY_CONTENT_AUTOPLAY_MEDIA_ID,
        params,
    );

    return useGetMediaApi(keyHook, { appendAuthData: true });
};

export default useGetCategoryAutoplayMediaId;
