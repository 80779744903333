import useChangeEmail from '@/api/auth/use-change-email';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { Message } from '@/components/shared/Message';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { yupResolver } from '@/lib/yupResolver';
import { navVertical } from 'nav-tree';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useError } from '@/contexts/error/error.context';

interface FormData {
    email: string;
}

const schema = Yup.object().shape({
    email: Yup.string()
        .email(t('form.validate.email-valid'))
        .required(t('form.validate.new-email-required')),
});

const AccountChangeEmail = () => {
    const { setError } = useError();
    const { register, handleSubmit, errors } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [changeEmail, { error }] = useChangeEmail();

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    const onSubmit = handleSubmit(async ({ email }) => {
        const result = await changeEmail({ email });

        if (result.ok) {
            Router.pushRoute(ROUTES.ACCOUNT_CHANGE_EMAIL_SUCCESS, { email });
        }
    });

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer func={navVertical}>
                <MainText
                    text={t('pages.account.change-email.header')}
                    mode={MAINTEXT_KIND.PAGE_TITLE}
                />
                <Message
                    style={{ width: '75rem' }}
                    description={[
                        t('pages.account.change-email.description-1'),
                        t('pages.account.change-email.description-2'),
                    ]}
                />
                <NavWrapper>
                    <form onSubmit={onSubmit}>
                        <TextInput
                            name="email"
                            placeholder={t('form.placeholder.new-email-address')}
                            type={INPUT_TYPE.TEXT}
                            icon={ICONS.EMAIL}
                            error={!!errors.email}
                            ref={register()}
                            defaultFocused
                            data-testing="form.placeholder.new-email-address"
                        />
                        <PlayableButton
                            text={t('save-button-label')}
                            onButtonAction={() => onSubmit()}
                            data-testing="save-button-label"
                        />
                        <PlayableButton
                            text={t('cancel-button-label')}
                            onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT)}
                            data-testing="cancel-button-label"
                        />
                    </form>

                    {renderFormErrors()}
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default AccountChangeEmail;
