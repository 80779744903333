import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import { ProductCard } from '@/components/shared/ProductCard';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import React from 'react';
import {
    ContentContainer,
    MessageContainer,
    PaymentSuccessContainer,
    ProductContainer,
} from './styles';
import { PaymentSuccessDefaultMessageProps } from './types';

export const PaymentSuccessDefaultMessage = ({
    possessionProduct,
}: PaymentSuccessDefaultMessageProps) => {
    return (
        <PaymentSuccessContainer>
            {possessionProduct && (
                <ProductContainer>
                    <ProductCard product={possessionProduct} />
                </ProductContainer>
            )}

            <ContentContainer fullWidth={!possessionProduct}>
                <MessageContainer>
                    <Message
                        icon={ICONS.SUCCESS_CIRCLE}
                        title={t('payment-steps.thank-you')}
                        innerHtmlDescription={t('payment-steps.payment-success')}
                    />
                </MessageContainer>

                <PlayableButton
                    text={t('ok-button-label')}
                    defaultFocused
                    onButtonAction={() => Router.pushRoute(ROUTES.HOME)}
                />
            </ContentContainer>
        </PaymentSuccessContainer>
    );
};
