import React, { useCallback } from 'react';

import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { useDayPicker } from '@/hooks/epg-table/use-day-picker';

import { DayPickerSideMenuItem } from '../Filter/SubcategoriesMenu/EPGDayPickerMenuItem';
import { RightPanel } from '../Filter/SubcategoriesMenu/RightPanel';
import { DayPickerSideMenuProps } from './types';

const DayPickerSideMenuRaw = ({ focusRef }: DayPickerSideMenuProps) => {
    const { handleDayChangeReset } = useIncrementColumnContext();
    const { setDate } = useHourRangeContext();
    const {
        filters,
        handleSubcategoriesOpen,
        selected,
        setSelected,
        showSubcategoriesMenu,
    } = useDayPicker(focusRef);

    const selectFilterAndResetContext = useCallback(
        (value: string) => () => {
            handleDayChangeReset();

            setDate(value);
            setSelected(value);
        },
        [handleDayChangeReset, setDate, setSelected],
    );

    const days = filters.map((filter) => (
        <DayPickerSideMenuItem
            key={filter.value}
            filter={filter}
            onAction={selectFilterAndResetContext(filter.value)}
            selected={selected === filter.value}
        />
    ));

    const focusedElement = selected ?? filters[0]?.value;
    const selectedFilterId = filters.find((filter) => filter.value === selected)?.value;

    if (!showSubcategoriesMenu) {
        return null;
    }

    return (
        <RightPanel
            selectedId={selectedFilterId}
            focused={focusedElement}
            menuItems={days}
            onShowMenu={handleSubcategoriesOpen}
        />
    );
};

export const DayPickerSideMenu = React.memo(DayPickerSideMenuRaw);
