import { Icon, ICONS } from '../../Icon';
import { Wrapper, Title, IconWrapper } from './styles';
import { SubCategoryTitleProps } from './types';

export const SubCategoryTitle = ({ icon, title }: SubCategoryTitleProps) => {
    return (
        <Wrapper>
            {icon && (
                <IconWrapper>
                    <Icon name={ICONS.DOT} />
                </IconWrapper>
            )}
            <Title>{title}</Title>
        </Wrapper>
    );
};
