import React from 'react';
import { StyledVerticalLine, StyledDotWrapper, StyledDot } from './style';
import { VerticalLineProps } from './types';
import { DotProps } from './types';

const VerticalLine = ({ responsive }: VerticalLineProps) => {
    return <StyledVerticalLine responsive={responsive} />;
};

const Dot = ({ small }: DotProps): JSX.Element => {
    return (
        <StyledDotWrapper small={!!small}>
            <StyledDot />
        </StyledDotWrapper>
    );
};

export { VerticalLine, Dot };
