import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { GetConfigurationOut } from '@/interfaces/from-schemas/system/getConfigurationOut';

export const getUserAgent = (configuration?: GetConfigurationOut) => {
    configuration?.userAgent ?? process.env.defaultUserData.ua;
    if (configuration?.userAgent) {
        return configuration.userAgent;
    }
    return process.env.defaultUserData.ua;
};

export const useUserAgent = () => {
    const configuration = useConfiguration();
    return getUserAgent(configuration);
};
