import React from 'react';
import t from '@/lib/i18n';
import { Dot } from './Separators';
import { StyledBadge, StyledBadgeDot } from './style';
import { BadgeOwnProps } from './types';

export const LiveBadge = ({ mode, responsive }: BadgeOwnProps) => {
    return (
        <>
            <StyledBadgeDot>
                <Dot />
            </StyledBadgeDot>

            <StyledBadge mode={mode} responsive={responsive}>
                {t('is-live')}
            </StyledBadge>
        </>
    );
};
