import format from 'date-fns/format';

import { ICONS } from '@/components/shared/Icon';
import { formatDate } from '@/helpers/date.helper';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import { redirectToFirstPaymentStep } from '@/helpers/payment.helper';
import { redirectToWatchUrl } from '@/helpers/watch.helpers';
import { AccessButtonInterface } from '@/interfaces/access-button.interface';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import t from '@/lib/i18n';
import { CheckProductAccessModel } from '@/models/payments/check-product-access.model';
import { ExternalUpgradeDataModel } from '@/models/payments/external-upgrade-data.model';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { BUTTON_ID } from '@/types/button-id.type';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { PRODUCT_SUBTYPES } from '@/types/media.type';

import { MultipleTypeProductModel } from '../products/multiple-type-product.model';
import { CommonlyAccessiblePacketModel } from './commonly-accessible-packet.model';
import { PartnerModel } from '../system/partner.model';
import { PossessionModel } from './possession.model';
import { PrePurchaseDataModel } from './pre-purchase-data.model';
import { ProductSubscriptionModel } from './product-subscription.model';

export class AccessProductModel {
    private _externalUpgrade?: ExternalUpgradeDataModel;
    private _checkProductAccess?: CheckProductAccessModel;

    constructor(
        readonly possession: PossessionModel | CommonlyAccessiblePacketModel,
        readonly product?: MultipleTypeProductModel,
        readonly packetsPrePurchase?: PrePurchaseDataModel,
        readonly subscription?: ProductSubscriptionModel,
        readonly partner?: PartnerModel,
    ) {}

    // * ACCESS
    public get checkProductAccess(): CheckProductAccessModel | undefined {
        return this._checkProductAccess;
    }

    public set checkProductAccess(checkProductAccess: CheckProductAccessModel | undefined) {
        this._checkProductAccess = checkProductAccess;
    }

    private get hasDisneyActivation(): boolean {
        return !!this.checkProductAccess?.hasDisneyActivation();
    }

    // * EXTERNAL UPGRADE

    public get externalUpgrade(): ExternalUpgradeDataModel | undefined {
        return this._externalUpgrade;
    }

    public set externalUpgrade(externalUpgrade: ExternalUpgradeDataModel | undefined) {
        this._externalUpgrade = externalUpgrade;
    }

    private get hasDisneyAccess(): boolean {
        return !!this.externalUpgrade?.showDisneyAccessInfo(this.hasDisneyActivation);
    }

    // * PRODUCT
    public getDetailsText(): string {
        const type = this.product?.getSubType() || PRODUCT_SUBTYPES.VOD;

        return `${t('product-access.go-to-media-page')} ${t(`product-access.media-types.${type}`)}`;
    }

    public getImage(): ListElementImageInterface | undefined {
        return this.product?.getImage();
    }

    public getMediaType(): ICONS {
        const type = this.product?.getSubType();

        switch (type) {
            case PRODUCT_SUBTYPES.PACKET:
                return ICONS.PACKET;
            case PRODUCT_SUBTYPES.LIVE:
                return ICONS.MEDIA_TYPE_LIVE;
            default:
                return ICONS.MEDIA_TYPE_VOD;
        }
    }

    // * PARTNER
    public getPartnerLogo(): string | undefined {
        return this.partner?.images && this.partner?.images[0].sources[0].src;
    }

    public getPartnerName(): string {
        return this.partner?.name || '';
    }

    public goToPaymentsPath(): void {
        const productId = this.product?.getProductId();

        if (productId) {
            redirectToFirstPaymentStep({ productId, withNext: true });
        }
    }

    // * POSSESSION
    public get id(): string {
        return this.possession.getId();
    }

    // * PREPURCHASE
    private get isPacketAvailableToBuy(): boolean {
        return !!this.packetsPrePurchase?.isPurchaseAvailable();
    }

    // * OTHERS
    public get title(): string {
        if (this.hasDisneyAccess) {
            const offerId =
                this.possession instanceof PossessionModel && this.possession.getOfferId();
            const offers = this.product?.getOffers();
            const offerName = offerId && offers?.find((el) => el.id === offerId)?.name;

            return offerName || this.product?.getTitle() || '';
        }
        return this.product?.getTitle() || '';
    }

    // * BUTTONS
    public get buttons(): AccessButtonInterface[] {
        const type = this.product?.getSubType();
        const possessionStatus = this.possession.getStatus();
        const subscriptionStatus = this.subscription?.getStatus();
        const upgradeData = this.externalUpgrade;
        const isUpgradeAvailable = this.packetsPrePurchase?.isUpgradeAvailable();
        const isAvailableToBuy = this.isPacketAvailableToBuy;

        if (type === 'packet') {
            const buttons: AccessButtonInterface[] = [];

            if (isAvailableToBuy) {
                if (subscriptionStatus === 'blocked') {
                    buttons.push(this.renewButton, this.resignButton);
                }
            }

            if (possessionStatus === 'active') {
                if (isUpgradeAvailable) {
                    buttons.push(this.upgradeButton);
                }

                if (isAvailableToBuy) {
                    if (
                        (!subscriptionStatus ||
                            subscriptionStatus === 'cancelled' ||
                            subscriptionStatus === 'deleted') &&
                        !isUpgradeAvailable
                    ) {
                        buttons.push(this.prelongButton);
                    }
                }

                if (subscriptionStatus === 'active') {
                    buttons.push(this.resignButton);
                }
            }
            if (possessionStatus === 'blocked') {
                if (subscriptionStatus === 'active') {
                    buttons.push(this.resignButton);
                }
            }

            if (upgradeData?.showActivateDisneyPlusButton(this.hasDisneyActivation)) {
                buttons.push(this.activateDisneyPlusButton);
            }

            return buttons;
        }

        if (type === 'movie') {
            if (possessionStatus === 'active') {
                return [this.watchButton];
            }
        }

        if (type === 'live') {
            if (possessionStatus === 'active') {
                return [this.watchButton];
            }
        }

        return [];
    }

    private get activateDisneyPlusButton(): AccessButtonInterface {
        const upgradeData = this.externalUpgrade;

        return {
            icon: ICONS.CHECK_WITH_DASH,
            text: t('product-access.activate-disney'),
            onButtonAction: () => upgradeData?.fetchActivationData(),
            id: BUTTON_ID.DISNEY_ACTIVATE,
            disableTimeout: 60 * 3, // in seconds
            disabledMessage: t('button.packet.disney-disabled'),
        };
    }

    private get prelongButton(): AccessButtonInterface {
        const text = t('button.packet.renew');

        return {
            icon: ICONS.PRELONG,
            text,
            onButtonAction: () => this.goToPaymentsPath(),
        };
    }

    private get renewButton(): AccessButtonInterface {
        const isDisneyPacket = this.hasDisneyAccess;

        return {
            icon: ICONS.RENEW,
            text: t('product-access.renew-button-text'),
            onButtonAction: () =>
                Router.pushRoute(ROUTES.MY_ACCESS_RENEW_CONFIRM, {
                    subscriptionId: this.subscription?.getId(),
                    possessionName: this.product?.getTitle(),
                    ...(isDisneyPacket && {
                        offerId: (this.possession as PossessionModel)?.getOfferId(),
                    }),
                }),
        };
    }

    private get resignButton(): AccessButtonInterface {
        const isPartnerPacket = !!this.possession.getPartnerId();
        const isDisneyPacket = this.hasDisneyAccess;

        return {
            icon: ICONS.CLOSE,
            text: t('product-access.resign-button-text'),
            onButtonAction: () => {
                Router.pushRoute(ROUTES.MY_ACCESS_RESIGNATION_CONFIRM, {
                    subscriptionId: this.subscription?.getId(),
                    possessionName: this.product?.getTitle(),
                    validTo: this.possession.getValidTo() || '',
                    ...((isPartnerPacket || isDisneyPacket) && {
                        productId: this.product?.getId(),
                        offerId: (this.possession as PossessionModel)?.getOfferId(),
                    }),
                });
            },
        };
    }

    private get upgradeButton(): AccessButtonInterface {
        const subscriptionStatus = this.subscription?.getStatus();
        let text = t('button.packet.renew');

        const externalUpgrade = this.externalUpgrade;

        if (externalUpgrade && this.possession instanceof PossessionModel) {
            text = externalUpgrade.getRenewButtonText(this.possession, subscriptionStatus);
        }

        return {
            icon: ICONS.PRELONG,
            text,
            onButtonAction: () => this.goToPaymentsPath(),
        };
    }

    private get watchButton(): AccessButtonInterface {
        const route = this.product?.getWatchRoute();
        const params = this.product?.getRouteParams();

        return {
            icon: ICONS.PLAY,
            text: t('product-access.watch-button-text'),
            onButtonAction: () => route && params && redirectToWatchUrl(route, params, true),
        };
    }

    // * ACCESS TEXTS
    private get accessTextVars() {
        const type = this.product?.getSubType();
        const possessionStatus = this.possession.getStatus();
        const partner = this.possession.getPartnerId();
        const dateTo = this.possession.getValidTo();
        const date = dateTo && format(new Date(dateTo), 'dd.MM.yyyy HH:mm');
        const subscriptionStatus = this.subscription?.getStatus();
        const isPacketAvailableToBuy = this.isPacketAvailableToBuy;

        const contactInfo = t(
            'product-access.cok-contact-info',
            buildHighlightSpan('highlight -target-highlight'),
        );
        const blockedStatus = t('product-access.status.blocked');
        const packet = this.hasDisneyAccess ? 'packet-extended' : 'packet';
        const partnerName = this.partner?.getName() && `${this.partner?.getName()}/`;
        const partnerInfo = t(`product-access.${packet}.partner.contact-info`, {
            partnerName,
            ...buildHighlightSpan('highlight -target-highlight'),
        });

        const prelong = t('product-access.prelong-info');
        const packetAccessBlockedInfo = t(`product-access.${packet}.access-info`, {
            status: blockedStatus,
            ...buildHighlightSpan('highlight -red'),
        });
        const nextPayment = this.subscription?.getNextPaymentDate();
        const nextPaymentDate = formatDate(nextPayment || '', FORMAT_DATE_MODE.DATE_WITH_YEAR);
        const renew = t('product-access.renew');
        const resignation = t('product-access.resignation');
        const activeInfo = t('product-access.packet.active');
        const notPrelongInfo = t('product-access.not-prelong-info');

        let path = 'product-access';

        if (type === 'movie') {
            path = path.concat('.', 'vod');
        } else if (this.hasDisneyAccess) {
            path = path.concat('.', packet);
        } else {
            if (type) {
                path = path.concat('.', type);
            }
        }

        return {
            type,
            possessionStatus,
            contactInfo,
            partner,
            date,
            subscriptionStatus,
            isPacketAvailableToBuy,
            blockedStatus,
            packet,
            partnerName,
            partnerInfo,
            prelong,
            packetAccessBlockedInfo,
            nextPayment,
            nextPaymentDate,
            renew,
            resignation,
            activeInfo,
            notPrelongInfo,
            path,
        };
    }

    private get subscriptionAccessText(): string {
        const {
            type,
            possessionStatus,
            partner,
            date,
            subscriptionStatus,
            isPacketAvailableToBuy,
            blockedStatus,
            partnerInfo,
            prelong,
            packetAccessBlockedInfo,
            nextPaymentDate,
            renew,
            resignation,
            activeInfo,
            notPrelongInfo,
            path,
        } = this.accessTextVars;

        let accessText = '';

        if (type === 'live' || type === 'movie') {
            if (partner) {
                switch (possessionStatus) {
                    case 'active':
                        accessText = t(`${path}.active`);

                        return accessText;
                    case 'blocked': {
                        const accessInfo = t(`${path}.access-info`, {
                            status: blockedStatus,
                            ...buildHighlightSpan('highlight -red'),
                        });

                        accessText = `${accessInfo} ${partnerInfo}`;

                        return accessText;
                    }
                }
            }
        }

        if (type === 'packet') {
            switch (possessionStatus) {
                case 'active':
                    switch (subscriptionStatus) {
                        case 'active':
                            if (partner) {
                                const accessInfo = t(`${path}.partner.active-subscription.active`, {
                                    nextPaymentDate,
                                });
                                accessText = `${activeInfo} ${accessInfo} ${resignation}`;
                            } else {
                                accessText = t(`${path}.active-subscription.active`, {
                                    date,
                                    nextPaymentDate,
                                });
                            }

                            return accessText;
                        case 'blocked':
                            if (partner) {
                                const accessInfo = t(
                                    `${path}.partner.active-subscription.blocked`,
                                    buildHighlightSpan('highlight -red'),
                                );

                                accessText = `${activeInfo} ${accessInfo} ${renew} ${resignation}`;
                            } else {
                                accessText = `${t(`${path}.active-subscription.blocked`, {
                                    date,
                                    ...buildHighlightSpan('highlight -red'),
                                })} ${renew} ${resignation}`;
                            }

                            return accessText;
                        case 'finished':
                            if (partner) {
                                accessText = t(`${path}.partner.active-subscription.finished`, {
                                    date,
                                });
                            } else {
                                accessText = t(`${path}.active-subscription.finished`, {
                                    date,
                                });
                            }

                            return accessText;
                        case 'cancelled':
                            if (partner) {
                                accessText = `${t(`${path}.partner.active-subscription.cancalled`, {
                                    date,
                                })}`;
                            } else {
                                accessText = `${t(`${path}.active-subscription.cancelled`, {
                                    date,
                                })}`;
                            }

                            if (isPacketAvailableToBuy) {
                                accessText += ` ${prelong}`;
                            }

                            return accessText;
                        case 'deleted':
                            if (partner) {
                                accessText = `${t(`${path}.partner.active-subscription.deleted`, {
                                    date,
                                })}`;
                            } else {
                                accessText = `${t(`${path}.active-subscription.deleted`, {
                                    date,
                                })}`;
                            }

                            if (isPacketAvailableToBuy) {
                                accessText += ` ${prelong}`;
                            }

                            return accessText;
                        default:
                            return accessText;
                    }
                case 'blocked': {
                    switch (subscriptionStatus) {
                        case 'active': {
                            if (partner) {
                                const dateInfo = t('product-access.continuation', {
                                    date: nextPaymentDate,
                                });
                                accessText = `${packetAccessBlockedInfo} ${dateInfo} ${partnerInfo} ${resignation}`;

                                return accessText;
                            }

                            const paymentInfo = t('product-access.next-payment-info', {
                                date,
                            });

                            const moreInfo = t(
                                `${path}.blocked-subscription.active-moreInfo`,
                                buildHighlightSpan('highlight -target-highlight'),
                            );

                            accessText = `${packetAccessBlockedInfo} ${paymentInfo} ${moreInfo} ${resignation}`;

                            return accessText;
                        }
                        case 'blocked': {
                            const paymentInfo = t(
                                `${path}.blocked-subscription.blocked`,
                                buildHighlightSpan('highlight -red'),
                            );

                            accessText = `${packetAccessBlockedInfo} ${paymentInfo} ${renew} ${resignation}`;

                            if (!isPacketAvailableToBuy && partner) {
                                accessText = `${packetAccessBlockedInfo}`;
                            }

                            return accessText;
                        }
                        case 'finished': {
                            if (!isPacketAvailableToBuy && partner) {
                                accessText = `${packetAccessBlockedInfo} ${notPrelongInfo} ${partnerInfo}`;

                                return accessText;
                            }

                            const paymentInfo = t(
                                `${path}.blocked-subscription.finished`,
                                buildHighlightSpan('highlight -target-highlight'),
                            );

                            accessText = `${packetAccessBlockedInfo} ${paymentInfo}`;

                            return accessText;
                        }
                        case 'cancelled': {
                            if (!isPacketAvailableToBuy && partner) {
                                accessText = `${packetAccessBlockedInfo} ${partnerInfo}`;

                                return accessText;
                            }

                            const paymentInfo = t(
                                `${path}.blocked-subscription.cancelled`,
                                buildHighlightSpan('highlight -target-highlight'),
                            );

                            accessText = `${packetAccessBlockedInfo} ${paymentInfo}`;

                            return accessText;
                        }
                        case 'deleted': {
                            if (!isPacketAvailableToBuy && partner) {
                                accessText = `${packetAccessBlockedInfo} ${partnerInfo}`;

                                return accessText;
                            }

                            const paymentInfo = t(
                                `${path}.blocked-subscription.deleted`,
                                buildHighlightSpan('highlight -target-highlight'),
                            );

                            accessText = `${packetAccessBlockedInfo} ${paymentInfo}`;

                            return accessText;
                        }
                    }
                }
            }
        }

        return accessText;
    }

    private get singleAccessText(): string {
        const {
            type,
            contactInfo,
            possessionStatus,
            partner,
            date,
            blockedStatus,
            partnerInfo,
            packetAccessBlockedInfo,
            path,
        } = this.accessTextVars;

        let accessText = '';

        switch (type) {
            case 'live':
                switch (possessionStatus) {
                    case 'active':
                        accessText = t(`${path}.active`);

                        return accessText;
                    case 'blocked': {
                        const liveAccessInfo = t(`${path}.access-info`, {
                            status: blockedStatus,
                            ...buildHighlightSpan('highlight -red'),
                        });

                        if (partner) {
                            accessText = `${liveAccessInfo} ${partnerInfo}`;
                        } else {
                            accessText = `${liveAccessInfo} ${contactInfo}`;
                        }

                        return accessText;
                    }
                }
                break;
            case 'movie':
                switch (possessionStatus) {
                    case 'active':
                        accessText = t(`${path}.active-with-date`, {
                            date,
                            ...buildHighlightSpan(),
                        });

                        return accessText;
                    case 'blocked': {
                        const vodAccessInfo = t(`${path}.access-info`, {
                            status: blockedStatus,
                            ...buildHighlightSpan('highlight -red'),
                        });

                        accessText = `${vodAccessInfo} ${contactInfo} `;

                        return accessText;
                    }
                }
                break;
            case 'packet':
                switch (possessionStatus) {
                    case 'active':
                        if (partner) {
                            accessText = `${t(`${path}.partner.active-info`, {
                                date,
                            })}`;

                            return accessText;
                        }
                        if (date) {
                            accessText = `${t(`${path}.active-long-info`, {
                                date,
                            })}`;

                            return accessText;
                        }

                        accessText = `${t(`${path}.active`)}`;

                        return accessText;
                    case 'blocked':
                        accessText = `${packetAccessBlockedInfo} ${contactInfo}`;

                        return accessText;
                }
        }

        return accessText;
    }

    public getAccessText(): string {
        const subscriptionStatus = this.subscription?.getStatus();

        if (subscriptionStatus) {
            return this.subscriptionAccessText;
        }

        return this.singleAccessText;
    }
}
