import { JSONRpcErrorInterface } from '@/interfaces/jsonrpc-error-interface';
import { AccountType } from '@/types/account.type';

class ResponseErrorModel {
    private readonly code?: number;
    private readonly message?: string;
    private readonly messageId?: string;
    private readonly userMessage?: string;
    private readonly existingAccounts?: AccountType[];
    private readonly email?: string;
    private readonly rulesType?: string;
    private readonly authToken?: string;
    private readonly verificationToken?: string;

    constructor(definition: JSONRpcErrorInterface | undefined | null) {
        const error = definition?.data;
        this.code = definition?.code;
        this.message = error?.message;
        this.messageId = error?.messageId;
        this.userMessage = error?.userMessage;
        this.existingAccounts = error?.existingAccounts;
        this.email = error?.email;
        this.rulesType = error?.rulesType;
        this.authToken = error?.authToken;
        this.verificationToken = error?.verificationToken;
    }

    public getCode(): number | undefined {
        return this.code;
    }

    public getMessage(): string | undefined {
        return this.message;
    }

    public getMessageId(): string | undefined {
        return this.messageId;
    }

    public getUserMessage(): string | undefined {
        return this.userMessage;
    }

    public getExistingAccounts(): AccountType[] | undefined {
        return this.existingAccounts;
    }

    public getEmail(): string | undefined {
        return this.email;
    }

    public getRulesType(): string | undefined {
        return this.rulesType;
    }

    public getAuthToken(): string | undefined {
        return this.authToken;
    }

    public getVerificationToken(): string | undefined {
        return this.verificationToken;
    }
}

export default ResponseErrorModel;
