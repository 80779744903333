import { device } from '@/targets/default/mediaQuery';
import styled, { css } from 'styled-components';
import { StyledAvatarInterface } from './types';

export const AvatarStyled = styled.img<StyledAvatarInterface>`
    width: ${({ $size }) => `${$size}rem`};
    height: ${({ $size }) => `${$size}rem`};
    border-radius: 50%;
    display: block;
    user-select: none;

    ${(props) =>
        props.active &&
        css`
            border-width: 0.5rem;
            border-style: solid;
            border-color: ${({ theme }) => theme.avatar.borderColor};
        `}
    ${(props) =>
        props.responsive &&
        css`
            width: 8.1rem;
            height: 8.1rem;

            @media ${device.tablet} {
                width: 10.2rem;
                height: 10.2rem;
            } ;
        `}
        
        ${(props) =>
        props.sliderStyle &&
        css`
            width: 6.7rem;
            height: 6.7rem;
            @media ${device.mobileM} {
                width: 8.1rem;
                height: 8.1rem;
            }

            @media ${device.tablet} {
                width: 10.2rem;
                height: 10.2rem;
            } ;
        `}
`;

export const AvatarWrapper = styled.div<{
    $size: number;
    responsive?: boolean;
    sliderStyle?: boolean;
}>`
    width: ${({ $size }) => $size}rem;
    ${(props) =>
        props.responsive &&
        css`
            width: 8.1rem;
            @media ${device.tablet} {
                width: 10.2rem;
            } ;
        `}
    ${(props) =>
        props.sliderStyle &&
        css`
            width: 6.7rem;
            @media ${device.mobileM} {
                width: 8.1rem;
            }
            @media ${device.tablet} {
                width: 10.2rem;
            } ;
        `}
`;

export const AvatarName = styled.div<{ responsive?: boolean }>`
    color: ${({ theme }) => theme.text.color.avatar};
    font-size: ${({ theme }) => theme.text.size.avatar};
    font-weight: 400;
    padding: 1.5rem 0 0 0;
    position: relative;
    text-align: center;
    word-break: break-all;
    ${(props) =>
        props.responsive &&
        css`
            font-size: 1.3rem;
            @media ${device.laptop} {
                font-size: 1.4rem;
            } ;
        `}
`;
