import { createContext, useState } from 'react';
import { GuiStateContextInterface, GuiStateContextProviderProps } from './types';

export const GuiStateContext = createContext<GuiStateContextInterface>({
    tmpPlaybackCurrentTime: 0,
    setTmpPlaybackCurrentTime: () => null,
});

export const GuiStateContextProvider = ({ children }: GuiStateContextProviderProps) => {
    const [tmpPlaybackCurrentTime, setTmpPlaybackCurrentTime] = useState(0);

    return (
        <GuiStateContext.Provider value={{ tmpPlaybackCurrentTime, setTmpPlaybackCurrentTime }}>
            {children}
        </GuiStateContext.Provider>
    );
};
