import { usePausablePlayable } from '@/hooks/player/use-pausable-playable';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { IconButton } from '../../../Buttons/PlayableButton/IconButton';
import { ICONS } from '../../../Icon';

interface IPlayPauseButtonProps {
    defaultFocused?: boolean;
}

export const PlayPauseButton = ({ defaultFocused }: IPlayPauseButtonProps) => {
    const instance = usePlayerInstance();
    const { playable, pausable } = usePausablePlayable();

    if (!playable && !pausable) {
        return null;
    }

    return (
        <IconButton
            defaultFocused={defaultFocused}
            icon={playable ? ICONS.PLAY : ICONS.PAUSE}
            onButtonAction={playable ? () => instance.user.play() : () => instance.user.pause()}
            data-testing="play.button"
        />
    );
};
