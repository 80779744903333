import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { PageContainer } from '@/components/views/Account/styles';
import { ChangeProfile } from '@/components/views/Profile/ChangeProfile';
import { ROUTES } from '@/routing/types';

const AccountChangeProfile = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT_CHANGE_PROFILE} />
            <PageContainer>
                <ChangeProfile />
            </PageContainer>
        </BaseLayout>
    );
};

export default AccountChangeProfile;
