import { formatDate, weekdayName } from '@/helpers/date.helper';
import t from '@/lib/i18n';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { Nav } from 'nav-tree';
import { RefObject, useEffect, useMemo, useState } from 'react';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import { useEPGProgramRightPanel } from '../nav-tree/use-epg-program-right-panel.hook';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';

export type DayFilter = {
    label: string;
    value: string;
};

const TOTAL_DAYS_TO_SHOW = 7;

const getDayFilters = (): DayFilter[] => {
    const today = new Date();
    const filters: DayFilter[] = [
        {
            label: t('date.yesterday'),
            value: formatDate(subDays(today, 1).toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
        {
            label: t('date.today'),
            value: formatDate(today.toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
        {
            label: t('date.tomorrow'),
            value: formatDate(addDays(today, 1).toISOString(), FORMAT_DATE_MODE.ISO_8601),
        },
    ];

    let nextDate = addDays(today, 2);
    for (let i = 2; i < TOTAL_DAYS_TO_SHOW; i++) {
        filters.push({
            label: `${t(`date.${weekdayName(nextDate.getDay())}`)} ${formatDate(
                nextDate.toISOString(),
                FORMAT_DATE_MODE.DATE,
            )}`,
            value: formatDate(nextDate.toISOString(), FORMAT_DATE_MODE.ISO_8601),
        });
        nextDate = addDays(nextDate, 1);
    }

    return filters;
};

export const getEarliestDay = (): string => getDayFilters()[0].value;

export const getLatestDay = (): string => getDayFilters()[TOTAL_DAYS_TO_SHOW].value;

export const useDayPicker = (focusRef: RefObject<Nav>) => {
    const { date } = useHourRangeContext();
    const filters = useMemo(getDayFilters, []);

    const selectedDay = formatDate(date.toISOString(), FORMAT_DATE_MODE.ISO_8601);

    const selectedDayValue = useMemo(() => {
        return filters.find((filter) => filter.value === selectedDay)?.value;
    }, [selectedDay, filters]);

    const [selected, setSelected] = useState<string>(selectedDayValue ?? filters[1].value);

    useEffect(() => {
        setSelected(selectedDayValue ?? filters[1].value);
    }, [selectedDayValue, filters]);

    const { showSubcategoriesMenu, handleSubcategoriesOpen } = useEPGProgramRightPanel({
        focusRef,
    });

    return {
        filters,
        handleSubcategoriesOpen,
        selected,
        setSelected,
        showSubcategoriesMenu,
    };
};
