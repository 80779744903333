import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { isStandalone, isWeb } from '@/constants/portal-recognition';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useEffect, useState } from 'react';
import { useError } from './error.context';
import { Props } from './types';
import { useGetAccurateParamsForHelpConfig } from '@/hooks/help/use-get-accuurate-params-for-help-config.hook';

//jesli bład jest na black liscie, zachowanie apki sie nie zmienia

const {
    B2C_USER_EXISTS_EXCEPTION, // 13450
    B2C_WRONG_CAPTCHA_VALUE, // 13452
    B2C_CAPTCHA_VALUE_REQUIRED, // 13458
    B2C_UNACCEPTED_RULES_ERROR, //13431
    B2C_EMAIL_CONFIRMATION_REQUIRED, // 13454
    B2C_EMAIL_REQUIRED, // 13456
} = ERROR_CODES_GET_MEDIA;

const errorArticleBlackList = [
    B2C_EMAIL_CONFIRMATION_REQUIRED,
    B2C_USER_EXISTS_EXCEPTION,
    B2C_WRONG_CAPTCHA_VALUE,
    B2C_CAPTCHA_VALUE_REQUIRED,
    B2C_UNACCEPTED_RULES_ERROR,
    B2C_EMAIL_REQUIRED,
];

export const checkIsErrorArticleExcluded = (error?: number) => {
    const isErrorArtileExcluded = errorArticleBlackList?.some((el) => el === error);
    return isErrorArtileExcluded;
};

function CustomerCareErrorPageProvider({ children }: Props) {
    const { contextType, contextValue } = useGetAccurateParamsForHelpConfig();
    const [renderChildren, setRenderChildren] = useState<boolean>(false);
    const isCustomerCare = process.env.isCustomerCare;
    const { error, setShowErrorComponent } = useError();

    useEffect(() => {
        if (checkIsErrorArticleExcluded(error?.getCode())) {
            setRenderChildren(true);
            return;
        } else {
            setRenderChildren(false);
        }
    }, [error]);

    useEffect(() => {
        if (isCustomerCare && !renderChildren && !checkIsErrorArticleExcluded(error?.getCode())) {
            if (isWeb && error && !sessionStorage.getItem('lastErrorPage')) {
                setShowErrorComponent(true);
            }
            if (isStandalone && error) {
                Router.pushRoute(
                    ROUTES.CUSTOMER_CARE_ERROR_PAGE,
                    { errorCode: String(error.getCode()) },
                    {
                        errorCodeMessageToDisplay: error.getUserMessage(),
                        contextType: contextType,
                        contextValue: contextValue,
                    },
                );
            }
        }
    }, [renderChildren, error]);

    return <>{children}</>;
}
export default CustomerCareErrorPageProvider;
