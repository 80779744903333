import { InputFilterSchema } from '@/interfaces/filters-interface';

export const getFlatFiltersValues = (filters: InputFilterSchema[]) => {
    let result: string[] = [];

    if (Array.isArray(filters) && filters.length) {
        const filtersValue = filters.map((filter) => filter.value);

        if (filtersValue) {
            result = filtersValue.flat();
        }
    }

    return result;
};
