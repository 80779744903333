import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { MenuItemData } from '@/types/account-menu-items.type';
import { showOneTrust } from '@/helpers/onetrust.helper';
import { ONE_TRUST_MODE } from '@/types/onetrust.type';

export const accountMenuItems: MenuItemData[] = [
    {
        label: t('account-menu.change-profile'),
        url: ROUTES.ACCOUNT_CHANGE_PROFILE,
        'data-testing': 'account-menu.change-profile',
    },
    {
        label: t('account-menu.account'),
        url: ROUTES.ACCOUNT,
        'data-testing': 'account-menu.account',
    },
    {
        label: t('account-menu.code'),
        url: ROUTES.HAVE_CODE,
        'data-testing': 'account-menu.code',
    },
    {
        label: t('account-menu.cookie-preferences'),
        url: ROUTES.COOKIE_PREFERENCES,
        'data-testing': 'account-menu.cookie-preferences',
        handleClickFn: () => showOneTrust(ONE_TRUST_MODE.PREFERENCE),
    },
    {
        label: t('account-menu.my-access'),
        url: ROUTES.MY_ACCESS,
        'data-testing': 'account-menu.my-access',
    },
    {
        label: t('account-menu.plus-zone'),
        url: ROUTES.PLUS_ZONE,
        'data-testing': 'account-menu.plus-zone',
    },
    {
        label: t('account-menu.consents'),
        url: ROUTES.CONSENTS_LIST,
        'data-testing': 'account-menu.consents',
    },
    {
        label: t('account-menu.rules'),
        url: ROUTES.RULES,
        'data-testing': 'account-menu.rules',
    },
    {
        label: t('account-menu.device'),
        'data-testing': 'account-menu.device',
        url: ROUTES.ACCOUNT_HELP,
    },
    {
        label: t('account-menu.logout'),
        url: ROUTES.ACCOUNT_LOGOUT,
        'data-testing': 'account-menu.logout',
    },
    {
        label: t('account-menu.restore-settings'),
        url: ROUTES.ACCOUNT_RESTORE_SETTINGS,
        'data-testing': 'account-menu.restore-settings',
    },
];
