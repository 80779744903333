import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { CatalogView } from '@/components/views/common';
import { useGetCategoryId } from '@/api/navigation/category/use-get-category-id';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';

const category = process.env.pages.category;
const { placeType } = category;

const CategoryIndex = () => {
    const { categoryId } = useGetCategoryId();
    useExitAppConfirmation(true);

    const catalogConfig = useMemo(() => buildCatalogConfig(placeType, { catid: categoryId }), [
        categoryId,
    ]);

    const updatedCatalogConfig = {
        ...catalogConfig,
        withFilters: false,
        withLists: false,
    };

    return (
        <BaseLayout>
            <CatalogView config={updatedCatalogConfig} />
        </BaseLayout>
    );
};

export default CategoryIndex;
