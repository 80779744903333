import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';

export const useGetHelpConfiguration = (enabled = true) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.HELP,
        GET_MEDIA_METHODS.GET_CONFIGURATION,
        enabled ? {} : undefined,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, { appendAuthData: true });
    return { data, isFetching, error };
};
