import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';
import SubcategoriesModel from '@/models/category/subcategories.model';
import { HomeMenuItemModel } from '@/models/home-menu-item.model';
import { FinalArrayType } from '@/components/shared/Menu/types';
import { Nav } from 'nav-tree';

export interface HomeMenuContextProps {
    dynamicMenuItems?: HomeMenuItemModel[];
    subcategories?: SubcategoriesModel;
    isFetching?: boolean;
    isSticky: boolean;
    scrollDirection: SCROLL_DIRECTION;
    menuFocused: boolean;
    setMenuFocused: Dispatch<SetStateAction<boolean>>;
    menuItemsCombined: FinalArrayType;
    currentCategory?: string;
    menuRef: RefObject<Nav> | null;
    shouldMaintainVisibility: boolean | null;
    setShouldMaintainVisibility: Dispatch<SetStateAction<boolean | null>>;
}

export interface HomeMenuContextProviderProps {
    children: ReactNode;
    currentCategory?: string;
}

export enum SCROLL_DIRECTION {
    TOP = 'top',
    DOWN = 'down',
    UP = 'up',
}
