import {
    GetChannelsCurrentProgramOut,
    TvChannelProgramListItemSchema,
} from '@/interfaces/from-schemas/navigation/getChannelsCurrentProgramOut';
import { ChannelProgramTvModel } from './channel-program-tv.model';
import { MEDIA_TYPES } from '@/types/media.type';

export class ChannelsCurrentProgramModel {
    private readonly results: { [key: string]: ChannelProgramTvModel[] } = {};

    constructor(definition: GetChannelsCurrentProgramOut) {
        if (definition) {
            this.results = {};

            Object.entries(definition).forEach(([key, values]) => {
                const sortedValues = values.sort(
                    (a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
                );

                return (this.results[key] = sortedValues.map(
                    (element, i) =>
                        new ChannelProgramTvModel(
                            { channelId: key, mediaType: MEDIA_TYPES.TV },
                            element as TvChannelProgramListItemSchema,
                            values[i + 1] as TvChannelProgramListItemSchema | undefined,
                        ),
                ));
            });
        }
    }

    public getResults(): { [key: string]: ChannelProgramTvModel[] } {
        return this.results;
    }
}
