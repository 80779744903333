import useCheckProductsAccess from '@/api/drm/use-check-products-access';
import { CheckProductsAccessIn } from '@/interfaces/from-schemas/drm/checkProductsAccessIn';
import { ChannelModel } from '@/models/channels/channel.model';
import { useMemo } from 'react';

export const useChannelsAccessMap = (channelList: ChannelModel[]) => {
    const checkProductsAccessParams = useMemo<CheckProductsAccessIn | false>(
        () =>
            channelList.length > 0
                ? {
                      products: channelList
                          .map((item) => item.getProductId()!)
                          .filter((productId) => typeof productId !== 'undefined'),
                  }
                : false,
        [channelList],
    );

    const { data: products, isFetching } = useCheckProductsAccess(checkProductsAccessParams);

    const channelsAccessMap = useMemo(() => {
        const accessMap: Record<string, boolean> = {};
        products?.forEach((product) => {
            const id = product.getId();
            if (id) {
                accessMap[id] = product.hasAccess();
            }
        });
        return accessMap;
    }, [products]);

    return { channelsAccessMap, isFetching };
};
