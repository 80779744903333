import { getDeviceScreenSize } from '@/helpers/device';
import { getStatsDate } from '@/helpers/date.helper';
import { AsyncEvent } from 'ts-events';
import CookiesService from '@/services/cookies/cookies.service';
import UuidUtil from '@/utils/uuid.util';
import {
    ActivityEventsRequiredHitParams,
    ActivityEventsUpdateHandlerParams,
    EVENT,
} from '../../types';
import {
    ActivityEventsConfig,
    ActivityEventsDataParams,
    ActivityEventsLaunchData,
    ActivityEventsParams,
    EVENT_STATUS,
    IDeviceExtraData,
} from './types';
import httpRequest, { IHttpResponse } from '@/services/http-request.service';

const STATS_APP_LAUNCH_COOKIE_NAME = 'stats-app-launch';

export default class ActivityEventsClient {
    private traceId: string;
    private cookieService: CookiesService;
    private isFirstTimeLaunch = false;
    private appLaunchCount = 0;
    private jwt = '';
    private profileId = '';
    private activityStartTime = 0; //sec
    private activityEndTime = 0; //sec
    private uuid = new UuidUtil();

    constructor(
        private config: ActivityEventsConfig,
        onParamsUpdate: AsyncEvent<ActivityEventsUpdateHandlerParams>,
    ) {
        this.traceId = this.uuid.getUuid();
        this.jwt = config.jwt;
        this.profileId = config.profileId;
        this.cookieService = new CookiesService();
        this.setAppLaunchData();

        onParamsUpdate.attach((data) => {
            if (data === null) return;

            if (data.jwt) {
                this.jwt = data.jwt;
            }

            if (data.profileId) {
                this.profileId = data.profileId;
            }
        });
    }

    public send(event: EVENT, params?: ActivityEventsRequiredHitParams): Promise<IHttpResponse> {
        switch (event) {
            case EVENT.ACTIVITY_EVENTS_APP_RESUMED:
                this.activityStartTime = this.getTimeInSeconds(Date.now());
                break;
            case EVENT.ACTIVITY_EVENTS_APP_PAUSED:
                this.activityEndTime = this.getTimeInSeconds(Date.now());
                break;
        }

        const data = this.getEventData(event, params);

        return httpRequest(this.config.service, data);
    }

    private setAppLaunchData() {
        const appLaunchCookie: ActivityEventsLaunchData = this.cookieService.get(
            STATS_APP_LAUNCH_COOKIE_NAME,
        );

        this.activityStartTime = this.getTimeInSeconds(Date.now());

        if (!appLaunchCookie) {
            this.isFirstTimeLaunch = true;
        } else {
            try {
                const launchCount = appLaunchCookie.launchCount;
                this.appLaunchCount = launchCount + 1;
                this.isFirstTimeLaunch = false;
            } catch (e) {
                console.error(` get ${STATS_APP_LAUNCH_COOKIE_NAME} error`, e);
            }
        }

        const cookieParams: ActivityEventsLaunchData = {
            firstLaunch: false,
            launchCount: this.appLaunchCount,
        };

        try {
            this.cookieService.set(STATS_APP_LAUNCH_COOKIE_NAME, cookieParams);
        } catch (e) {
            console.error(`set ${STATS_APP_LAUNCH_COOKIE_NAME} error`, e);
        }
    }

    private getEventData(
        event: EVENT,
        paramsData?: ActivityEventsRequiredHitParams,
    ): ActivityEventsParams {
        const deviceExtraData: IDeviceExtraData = Object.assign(
            {
                screenSize: getDeviceScreenSize(),
            },
            this.config.deviceExtraData,
        );

        let hitParamsData: ActivityEventsDataParams = {
            status: paramsData?.errorCode ? EVENT_STATUS.FAILED : EVENT_STATUS.SUCCESS,
            ...paramsData,
            profileId: this.profileId,
            userAgentData: this.config.userAgentData,
            deviceId: this.config.deviceId,
            clientId: this.config.clientId,
            ipData: this.config.ipData,
            deviceExtraData,
        };

        if (event === EVENT.ACTIVITY_EVENTS_APP_STARTED) {
            hitParamsData = {
                ...hitParamsData,
                launchData: {
                    firstLaunch: this.isFirstTimeLaunch,
                    launchCount: this.appLaunchCount,
                },
            };
        }

        if (event === EVENT.ACTIVITY_EVENTS_APP_PAUSED) {
            hitParamsData = {
                ...hitParamsData,
                activeDuration: this.activityEndTime - this.activityStartTime,
            };
        }

        const hitParams: ActivityEventsParams = {
            type: event,
            portalId: this.config.portalId,
            eventId: this.uuid.getUuid(),
            originator: this.config.originator,
            jwt: this.jwt,
            eventDate: getStatsDate(),
            traceId: this.traceId,
            version: this.config.appVersion,
            data: hitParamsData,
        };
        return hitParams;
    }

    private getTimeInSeconds(time: number): number {
        return Math.round(time / 1000);
    }
}
