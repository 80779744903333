import { IApiResponse } from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { CommonlyAccessiblePacketModel } from '@/models/payments/commonly-accessible-packet.model';
import { GetCommonlyAccessiblePacketsOut } from '@/interfaces/from-schemas/navigation/getCommonlyAccessiblePacketsOut';
import { useMemo } from 'react';

export const useGetCommonlyAccessiblePackets = (): IApiResponse<
    CommonlyAccessiblePacketModel[] | undefined
> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_COMMONLY_ACCESSIBLE_PACKETS,
        {},
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: useMemo(
            () =>
                data?.map(
                    (el: GetCommonlyAccessiblePacketsOut[number]) =>
                        new CommonlyAccessiblePacketModel(el),
                ),
            [data],
        ),
        isFetching,
        error,
    };
};
