import { useCallback, useContext, useEffect } from 'react';
import { RecoContext } from '@/contexts/reco/reco.context';
import { PacketContext } from '@/contexts/packet/packet.context';
import { IncrementContext } from '@/contexts/increment/increment.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { CollectionsListConfig } from './types';

export const useFetchMoreCollections = ({ withPackets }: CollectionsListConfig) => {
    const CTX = withPackets ? PacketContext : RecoContext;

    const { activeRow, setActiveRow, setEmptyListsArr } = useContext(CTX);
    const { incrementRow, incrementFetchedIndex, handleLoadedData } = useContext(IncrementContext);

    useEffect(() => {
        incrementFetchedIndex(activeRow);
    }, [activeRow]);

    const interceptLoadedData = useCallback((isLoaded: boolean, rowIndex: number) => {
        batchedUpdates(() => {
            handleLoadedData(isLoaded);

            if (!isLoaded) {
                setEmptyListsArr((prev) => [...prev, rowIndex]);
            }
        });
    }, []);

    return {
        loadedRowIndex: incrementRow.loadedIndex,
        handleLoadedData: interceptLoadedData,
        setActiveRow,
    };
};
