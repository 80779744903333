import useGetAllRules from '@/api/auth/use-get-all-rules';
import { RulesScrollableContainer } from '@/components/shared/RulesScrollableContainer';
import { getRuleSourceUrl } from '@/helpers/get-rule-source-url';
import React from 'react';

export const AccountRules = () => {
    const { data: rulesData } = useGetAllRules({ context: 'main_rules' });

    const rule = rulesData?.[0];
    const sourceUrl = getRuleSourceUrl(rule?.sources);

    return <RulesScrollableContainer sourceUrl={sourceUrl} />;
};
