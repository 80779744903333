import { HomeMenuContext } from '@/contexts/menu/home.menu.context';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { getItemIndex } from '@/helpers/home-menu/get-item-index.helper';
import { restoreFocusVertical } from '@/helpers/nav-tree/helpers';
import { useNavScroll } from '@/hooks/nav-tree/use-nav-scroll.hook';
import { IMPERATIVE_SCROLL_DIRECTION, NAV_SCROLL_DIRECTION } from 'nav-tree';
import React, { useContext, useEffect, useState } from 'react';
import { Icon, ICONS } from '../Icon';
import { MenuItems } from './MenuItems';
import { MenuLogo } from './MenuLogo';
import {
    LeftPanelStyled,
    MenuContainerIconStyled,
    MenuContainerStyled,
    NavContainerStyled,
} from './styles';
import { POSITION } from './types';

const MenuRaw = () => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const { menuFocused, setMenuFocused, menuItemsCombined, menuRef } = useContext(HomeMenuContext);

    const [focusPath, setFocusPath] = useState<string[]>();

    const {
        navRef,
        scrollOptions,
        autofocusOn,
        scrollState,
        onScrollCallback,
        imperativeScroll,
    } = useNavScroll(NAV_SCROLL_DIRECTION.VERTICAL, menuRef);

    useEffect(() => {
        return () => setMenuFocused(false);
    }, []);

    const focusId = focusPath && focusPath[0];
    useEffect(() => {
        const focusedIndex = getItemIndex(menuItemsCombined, focusId);

        navRef.current?.imperativeScroll(
            focusedIndex >= 8 ? IMPERATIVE_SCROLL_DIRECTION.DOWN : IMPERATIVE_SCROLL_DIRECTION.UP,
        );
    }, [focusId, navRef, menuItemsCombined]);

    return (
        <>
            <LeftPanelStyled active={menuFocused} />
            <MenuContainerStyled
                active={menuFocused}
                onMouseEnter={() => {
                    if (isMagicMouseVisible) setMenuFocused(true);
                }}
                onMouseLeave={() => {
                    if (isMagicMouseVisible) setMenuFocused(false);
                }}
            >
                <MenuLogo active={menuFocused} />

                {scrollState.isScrollableUp && (
                    <MenuContainerIconStyled
                        position={POSITION.TOP}
                        onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.UP)}
                    >
                        <Icon name={ICONS.ARROW_UP} />
                    </MenuContainerIconStyled>
                )}

                <NavContainerStyled
                    ref={navRef}
                    func={(key, navTree) => restoreFocusVertical(key, navTree, focusPath?.[0])}
                    onNav={setMenuFocused}
                    onNavScroll={onScrollCallback}
                    scrollOptions={scrollOptions}
                    autofocusOn={autofocusOn}
                    data-testing="home-menu"
                >
                    <MenuItems
                        menuActive={menuFocused}
                        onFocusPath={setFocusPath}
                        menuItemsCombined={menuItemsCombined}
                    />
                </NavContainerStyled>

                {scrollState.isScrollableDown && (
                    <MenuContainerIconStyled
                        position={POSITION.BOTTOM}
                        onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.DOWN)}
                    >
                        <Icon name={ICONS.ARROW_DOWN} />
                    </MenuContainerIconStyled>
                )}
            </MenuContainerStyled>
        </>
    );
};

export const Menu = React.memo(MenuRaw);
