import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const FullDetailsContainer = styled(Nav)`
    height: 100%;
    position: relative;
    width: 100%;
`;

export const FullDetailsContentContainer = styled.div`
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 14rem;
    left: 15rem;
    width: 166rem;
`;

export const MainMetadataContainer = styled.div<{ withScroll?: boolean }>`
    ${({ withScroll }) =>
        withScroll &&
        css`
            position: relative;
            top: 14rem;
        `}
`;

export const StyledBottomMargin = styled.div`
    height: 14rem;
`;
