import { Icon, ICONS } from '@/components/shared/Icon';
import { memo, useMemo } from 'react';
import { EPGChannelImage, EPGSliderAccent } from './styles';
import { EPGChannelTileProps } from './types';

export const EPGChannelTileRaw = ({ channel, nowPlayed, hasAccess }: EPGChannelTileProps) => {
    const thumbnail = useMemo(() => channel.getImage(), [channel]);
    return (
        <div>
            {nowPlayed ? (
                <EPGSliderAccent nowPlaying={nowPlayed}>
                    <Icon name={ICONS.SIMPLE_PLAY} />
                </EPGSliderAccent>
            ) : (
                !hasAccess && (
                    <EPGSliderAccent lock>
                        <Icon name={ICONS.LOCK_FILLED} />
                    </EPGSliderAccent>
                )
            )}
            <EPGChannelImage
                src={thumbnail?.src}
                onLoad={(e) => {
                    (e.target as HTMLImageElement).style.opacity = '1';
                }}
            />
        </div>
    );
};

export const EPGChannelTile = memo(EPGChannelTileRaw);
