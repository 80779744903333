import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const PurchaseOptionRuleListItemStyled = styled(Nav)`
    width: 100%;
    padding: 2rem 3rem;
    margin: 3rem 0;
    border-radius: 4rem;
    text-align: left;

    &:hover {
        cursor: pointer;
    }

    &.nav-focused {
        border: 0.4rem solid;
        border-color: ${({ theme }) => theme.button.activeBorderColor};
    }

    & p {
        margin: 0;
        font-size: ${({ theme }) => theme.text.size.subtitle};
        font-weight: 400;
    }
`;
