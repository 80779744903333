import CookiesService from '@/services/cookies/cookies.service';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import CryptoUtil from '@/utils/crypto.utils';
import { PROFILE_COOKIES } from '@/contexts/profile/types';

const crypto = new CryptoUtil();
const _cacheSessionToken = new Map<string, string>();

const urlSafeDecode = (input: string) => {
    try {
        return input.replace(/-/g, '+').replace(/_/g, '/');
    } catch (e) {
        console.error('Wprowadzono token o niewłaściwym formacie', e);
        return '';
    }
};

const urlSafeEncode = (input: string) => input.replace(/\+/g, '-').replace(/\//g, '_');

const generateSessionRpcKey = (
    sessionRpcMethodString: string,
    key: RpcSessionKeyInterface,
): string => {
    const rpcMethodDigest = crypto.hmacSHA256(sessionRpcMethodString, key);
    const rpcKey = crypto.base64.stringify(rpcMethodDigest);

    return urlSafeEncode(rpcKey);
};

const encodeSessionKey = (key: string): RpcSessionKeyInterface => {
    return crypto.base64.parse(urlSafeDecode(key));
};

export const createSessionToken = (
    method: string,
    namespace: string,
    rpcSessionData?: SessionInterface,
): string | null => {
    if (!rpcSessionData) {
        return null;
    }

    const sessionRpcMethodString = `${rpcSessionData.id}|${rpcSessionData.keyExpirationTime}|${namespace}|${method}`;
    let sessionToken = _cacheSessionToken.get(sessionRpcMethodString);
    if (!sessionToken) {
        const key = encodeSessionKey(rpcSessionData.key);
        const sessionRpcKey = generateSessionRpcKey(sessionRpcMethodString, key);
        const newSessionToken = `${sessionRpcMethodString}|${sessionRpcKey}`;
        _cacheSessionToken.set(sessionRpcMethodString, newSessionToken);
        sessionToken = newSessionToken;
    }

    return sessionToken;
};

const isSessionExpired = (session: SessionInterface) => {
    const keyExpirationTimeMillis = session.keyExpirationTime * 1000;
    return Date.now() > keyExpirationTimeMillis;
};

class SessionService {
    private sessionCookieName = 'user-session';
    private _cacheSessionToken = new Map<string, string>();
    constructor(private cookieService: CookiesService, private crypto: CryptoUtil) {}

    public getSession(): SessionInterface | undefined {
        const sessionCookie = this.cookieService.get(this.sessionCookieName);

        return sessionCookie && !isSessionExpired(sessionCookie) ? sessionCookie : undefined;
    }

    public getSessionToken(
        method: GET_MEDIA_METHODS,
        namespace: GET_MEDIA_NAMESPACES,
        sessionData: SessionInterface,
    ): string | null {
        const sessionToken = this.createSessionToken(method, namespace, sessionData);

        return sessionToken;
    }

    public setSession(sessionData: SessionInterface) {
        const { userSessionCookieExpirationTime } = process.env;
        let expires: Date | undefined;

        if (userSessionCookieExpirationTime) {
            expires = new Date(Date.now() + Number(userSessionCookieExpirationTime));
        }

        this.cookieService.set(this.sessionCookieName, sessionData, { path: '/', expires });
    }

    public clearSession(withAutologin?: boolean): void {
        this.cookieService.remove(this.sessionCookieName);

        if (!withAutologin) {
            this.cookieService.remove(PROFILE_COOKIES.PROFILE_ID);
        }
    }

    private createSessionToken(
        method: string,
        namespace: string,
        rpcSessionData: SessionInterface,
    ): string | null {
        if (!rpcSessionData) {
            return null;
        }

        const sessionRpcMethodString = `${rpcSessionData.id}|${rpcSessionData.keyExpirationTime}|${namespace}|${method}`;
        let sessionToken = this._cacheSessionToken.get(sessionRpcMethodString);
        if (!sessionToken) {
            const key = this.encodeSessionKey(rpcSessionData.key);
            const sessionRpcKey = this.generateSessionRpcKey(sessionRpcMethodString, key);
            const newSessionToken = `${sessionRpcMethodString}|${sessionRpcKey}`;
            this._cacheSessionToken.set(sessionRpcMethodString, newSessionToken);
            sessionToken = newSessionToken;
        }

        return sessionToken;
    }

    private encodeSessionKey(key: string): RpcSessionKeyInterface {
        return this.crypto.base64.parse(this.urlSafeDecode(key));
    }

    private urlSafeEncode(input: string): string {
        return input.replace(/\+/g, '-').replace(/\//g, '_');
    }

    private urlSafeDecode(input: string): string {
        return input.replace(/-/g, '+').replace(/_/g, '/');
    }

    private generateSessionRpcKey(
        sessionRpcMethodString: string,
        key: RpcSessionKeyInterface,
    ): string {
        const rpcMethodDigest = this.crypto.hmacSHA256(sessionRpcMethodString, key);
        const rpcKey = this.crypto.base64.stringify(rpcMethodDigest);

        return this.urlSafeEncode(rpcKey);
    }
}

export default SessionService;

interface SessionInterface {
    readonly id: string;
    readonly key: string;
    readonly keyExpirationTime: number;
}

interface RpcSessionKeyInterface {
    clamp: Function;
    clone: Function;
    concat: Function;
    toString: Function;
    readonly words: Array<number>;
}
