import { Z_INDEX } from '@/targets/default/theme';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const BackButtonWrapper = styled(Nav)`
    position: absolute;
    top: 8rem;
    left: 8rem;
    z-index: ${Z_INDEX.BACK_BUTTON};
`;
