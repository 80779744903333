import CookiesService from './../../../../services/cookies/cookies.service';
import UserRecognitionModel from '../../models/user-recognition.model';
import { DeviceIdInterface } from './../../../../interfaces/device-id-interface';
import { isStbTargets } from '@/constants/portal-recognition';

class UserRecognitionService {
    private userRecognitionCookieName = 'user-recognition';

    constructor(
        private cookieService: CookiesService,
        private userRecognitionModel: UserRecognitionModel,
    ) {}

    public getUserRecognition(): UserRecognitionModel {
        const userRecognitionData = this.cookieService.get(this.userRecognitionCookieName);

        this.userRecognitionModel.setData(userRecognitionData || {});

        return this.userRecognitionModel;
    }

    public setUserRecognition(userRecognitionModel: UserRecognitionModel): void {
        const userRecognitionData = userRecognitionModel.getAsObject();

        this.cookieService.set(this.userRecognitionCookieName, userRecognitionData);
    }

    public setDeviceId(deviceId: DeviceIdInterface['value']): void {
        const userRecognition = this.getUserRecognition();

        userRecognition.setDeviceId(deviceId);
        this.setUserRecognition(userRecognition);
    }

    public setUserId(userId: string): void {
        const userRecognition = this.getUserRecognition();

        userRecognition.setUserId(userId);
        this.setUserRecognition(userRecognition);
    }

    public getDeviceId() {
        return this.userRecognitionModel.getDeviceId();
    }

    public getDeviceIdIdent(): DeviceIdInterface {
        if (isStbTargets) {
            return {
                type: 'module',
                // @ts-ignore
                value: window.stb.deviceId,
            };
        }

        const deviceId = this.getUserRecognition().getDeviceId();
        return {
            type: 'other',
            value: deviceId,
        };
    }

    public setClientId(clientId: string): void {
        const userRecognition = this.getUserRecognition();

        userRecognition.setClientId(clientId);
        this.setUserRecognition(userRecognition);
    }

    public getClientId(): string {
        return this.getUserRecognition().getClientId();
    }

    public clearRecognition(): void {
        this.cookieService.remove(this.userRecognitionCookieName);
    }
}

export default UserRecognitionService;
