import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { AcceptRulesOut } from '@/interfaces/from-schemas/auth/acceptRulesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseUnacceptRules {
    rulesIds: number[];
}

const useUnacceptRules = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.SEND_UNACCEPTED_RULES,
        null,
    );

    return useGetMediaApiMutation<IUseUnacceptRules, AcceptRulesOut>(keyHook, {
        appendAuthData: true,
        invalidate: [[GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.FETCH_RULES_STATUS]],
    });
};

export default useUnacceptRules;
