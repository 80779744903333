import { AdditionalSigns } from '@/components/shared/AdditionalSigns/AdditionalSigns';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';
import { useWalkingEPG } from '@/hooks/player/use-walking-epg';
import {
    MediaTitleContainer,
    MediaTitleIconsContainer,
    MediaTitleInnerContainer,
    MediaTitleTextContainer,
} from '../shared/styles';
import { ITVProgramTitleProps } from './types';
import { ADDITIONAL_SIGNS_TYPES } from '@/types/additional-signs.type';

export const TVProgramTitle = ({ channelId }: ITVProgramTitleProps) => {
    const { currentTime } = usePlayerTimeMarkers();
    const isTimeshiftingSupported = useTimeshiftingSupported();
    const { programs } = useWalkingEPG({
        channelId,
        refTime: isTimeshiftingSupported && currentTime > 0 ? currentTime * 1000 : undefined,
        offset: 0,
        count: 1,
    });

    return (
        <MediaTitleContainer>
            <MediaTitleInnerContainer>
                <MediaTitleTextContainer>{programs[0]?.getTitle()}</MediaTitleTextContainer>

                <MediaTitleIconsContainer>
                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION}
                        signs={programs[0]?.getUnderageClassification()}
                        $iconSize={2.2}
                    />
                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES}
                        signs={programs[0]?.getAccessibilityFeatures()}
                        $iconSize={2.2}
                    />
                </MediaTitleIconsContainer>
            </MediaTitleInnerContainer>
        </MediaTitleContainer>
    );
};
