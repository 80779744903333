import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiServerResponse } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { GetConfigurationOut } from '@/interfaces/from-schemas/system/getConfigurationOut';

const namespace = GET_MEDIA_NAMESPACES.SYSTEM;
const method = GET_MEDIA_METHODS.GET_CONFIGURATION;

export const getConfigurationPromise = (
    appendAuthData = true,
    getMediaApiPromise: GetMediaPromiseFunc,
    params: { [key: string]: any } = {},
): Promise<IApiServerResponse<GetConfigurationOut>> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {
        appendAuthData,
        queryOptions: {
            cacheTime: 0,
        },
    });
};

export const useGetConfiguration = (enabled = true, appendAuthData = true) => {
    const keyHook = defaultKeyHook(namespace, method, enabled ? {} : undefined);

    const options = {
        appendAuthData,
        queryOptions: {
            staleTime: Infinity,
        },
    };

    return useGetMediaApi<GetConfigurationOut>(keyHook, options);
};
