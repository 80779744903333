export const normalizeUrl = (url: string, forcedProtocol = 'https'): string => {
    if (!/\/\//.test(url)) {
        url = `${forcedProtocol}://${url}`;
    }

    if (/^\/\//.test(url)) {
        url = `${forcedProtocol}:${url}`;
    }

    return url.replace(/^http(?!s)/, forcedProtocol);
};

export const replaceHostName = (url: string, newHostName: string): string => {
    const urlParser = new URL(url);
    urlParser.hostname = newHostName;
    return normalizeUrl(urlParser.href);
};
