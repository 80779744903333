import styled from 'styled-components';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: ${(props) => props.theme.filerListItem.fontSize};
    line-height: 100%;
    color: ${(props) => props.theme.filerListItem.color};
    font-weight: 400;
`;
