import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { Z_INDEX } from '@/targets/default/theme';
import styled, { css } from 'styled-components';

export const ProgressBarContainer = styled.div`
    background: ${(props) => props.theme.progressBar.rootBgColor};
    height: 0.1rem;
    margin-top: 0.2rem;
    position: relative;
    width: 100%;
`;

export const ProgramProgressContent = styled.div.attrs<{ progress: number }>(({ progress }) => ({
    style: {
        width: `${progress}%`,
    },
}))<{ progress: number }>`
    ${({ theme }) => css`
        background: ${theme.progressBar.contentBgColor};
        height: 100%;
        max-width: 100%;
        position: absolute;
        transition: ${CheckSlowDevice(theme.progressBar.transitionValue)};
        z-index: ${Z_INDEX.PROGRESS};
    `}
`;
