import { GetPrePurchaseDataIn } from './from-schemas/payments/getPrePurchaseDataIn';

export interface GetPrePurchaseDataParams {
    product: GetPrePurchaseDataIn['products'][number];
    withoutUserPaymentsData?: boolean;
    withoutMinPriceProducts?: boolean;
}
export enum PURCHASE_AVAILABILITY_STATUS {
    PURCHASE_AVAILABLE = 0,
    GENERAL_PURCHASE_UNAVAILABLE = -1,
    PURCHASE_UNAVAILABLE_DUE_TO_ACTIVE_POSSESSION = -2,
    PURCHASE_UNAVAILABLE_DUE_TO_ACTIVE_OR_BLOCKED_SUBSCRIPTION = -3,
}
