import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import LiveAll from '@/models/live/live-all.model';
import defaultKeyHook from '@/hooks/default-key';
import { GetLiveChannelsIn } from '@/interfaces/from-schemas/navigation/getLiveChannelsIn';

export const useGetLive = (params: GetLiveChannelsIn | false) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_EVENT_LIST,
        params,
    );
    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: new LiveAll(data),
        error,
        isFetching,
    };
};
