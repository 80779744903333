import { findScriptById } from '@/helpers/find-script-by-id.helper';
import { SCRIPT_ID } from '@/types/script-id.type';
import { useLayoutEffect, useState } from 'react';

export const useLoadScript = (
    id: SCRIPT_ID,
    src?: string,
    onLoad?: () => void,
    onError?: () => void,
) => {
    const currentScript = findScriptById(id);
    const [scriptLoaded, setScriptLoaded] = useState(!!currentScript);

    useLayoutEffect(() => {
        if (!src || scriptLoaded) return;

        const script = document.createElement('script');
        script.src = src;
        script.id = id;

        script.onload = () => {
            setScriptLoaded(true);
            onLoad?.();
        };

        document.head.appendChild(script);

        script.onerror = () => {
            document.head.removeChild(script);

            setScriptLoaded(false);
            onError?.();
        };
    }, [src, id, scriptLoaded, onLoad, onError]);

    return { scriptLoaded };
};
