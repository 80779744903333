import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { ChangeEmailOut } from '@/interfaces/from-schemas/auth/changeEmailOut';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { UserAuthData } from '@/types/user-auth-data.type';
import { DeviceIdInterface } from '@/interfaces/device-id-interface';

export interface IUseChangeEmail {
    authData?: UserAuthData & { deviceId: DeviceIdInterface };
    email: string;
}

const useChangeEmail = () => {
    const userModule = useUserModule();
    const deviceId = userModule.getDeviceIdIdent();

    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.CHANGE_EMAIL, null);

    const [mutate, state] = useGetMediaApiMutation<IUseChangeEmail, ChangeEmailOut>(keyHook, {});

    const mutateWithDeviceId: (params: IUseChangeEmail) => ReturnType<typeof mutate> = async (
        params,
    ) => {
        const { email, authData } = params;
        const mutateParams = { email };

        if (authData) {
            Object.assign(mutateParams, { authData: { ...authData, deviceId } });
        }

        const result = await mutate(mutateParams);

        return result;
    };

    return [mutateWithDeviceId, state] as [typeof mutateWithDeviceId, typeof state];
};

export default useChangeEmail;
