// Mock tłumaczeń
import locale from './routes-locale.json';

function t(sequence: string): string {
    // @ts-ignore
    return locale[sequence] || sequence;
}
// -------------

export function translateRoute(route: string): string {
    const chunkList = route.split('/');

    const translatedChunkList = chunkList.map((chunk: string) => {
        return shouldRouteChunkTranslate(chunk) ? t(chunk) : chunk;
    });

    return translatedChunkList.join('/');
}

function shouldRouteChunkTranslate(chunk: string): boolean {
    return chunk.length > 0 && chunk.charAt(0) !== ':';
}
