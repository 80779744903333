import { useEffect, useMemo } from 'react';
import { CollectionsListConfig } from '@/components/shared/CollectionsList/types';
import { GetStaffRecommendationsListsIn } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListsIn';
import useGetStaffRecommendationLists from './get-staff-recommendation-lists';
import { useFmcContext } from '@/contexts/fmc/fmc.context';
import { getFmcList } from '@/helpers/get-fmc-list.helper';
import { useError } from '@/contexts/error/error.context';

export const useGetCollectionsList = (
    config: CollectionsListConfig,
    params: GetStaffRecommendationsListsIn | false,
) => {
    const { singleCollection } = config;
    const { setError } = useError();

    const { setFmcList } = useFmcContext();
    const { data: recoLists, isFetching, error } = useGetStaffRecommendationLists(params);

    useEffect(() => {
        if (isFetching) return;

        const fmcList = getFmcList(recoLists);
        if (fmcList) setFmcList(fmcList);

        if (error) {
            setError(error);
        }
    }, [recoLists, isFetching, setFmcList, error]);

    const collectionsList = useMemo(
        () =>
            recoLists.getResults().filter((list) => (singleCollection ? !list.isPromobox() : true)),
        [recoLists, singleCollection],
    );

    return { collectionsList, isFetching, error };
};
