import { useRef, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

// zwraca wymiary referencji lub wymiary window
export const useDimensions = (windowDimensions = false) => {
    const ref = useRef<HTMLDivElement>(null);

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const updateWidthAndHeight = useCallback(() => {
        const width = windowDimensions ? window.innerWidth : ref?.current?.clientWidth || 0;
        const height = windowDimensions ? window.innerHeight : ref?.current?.clientHeight || 0;

        setDimensions((prev) =>
            prev.width !== width || prev.height !== height ? { width, height } : prev,
        );
    }, [windowDimensions]);

    useEffect(() => {
        updateWidthAndHeight();

        const debounced = debounce(updateWidthAndHeight, 200);

        window.addEventListener('resize', debounced);

        return () => {
            window.removeEventListener('resize', debounced);
        };
    }, [updateWidthAndHeight]);

    return { ...dimensions, ref };
};
