import React, { createContext, useEffect, useState } from 'react';
import { Platform, recognizePlatform } from '@/services/platform-recognition';
import { PlatformContextProps, PlatformProviderProps } from './types';
import { botDetector } from '@/helpers/seo/bot-detector.helper';

export const PlatformContext = createContext<PlatformContextProps>({
    platform: {},
    isBot: false,
});

const PlatformContextProvider = ({ children, initialPlatform }: PlatformProviderProps) => {
    const { isBot } = botDetector(initialPlatform?.browserUserAgent);
    const [platform, setPlatform] = useState<Platform | undefined>(
        isBot ? initialPlatform : undefined,
    );

    useEffect(() => {
        recognizePlatform(window.navigator.userAgent).then((recognitionResult) => {
            setPlatform(recognitionResult);
        });
    }, []);

    if (!platform) {
        return null;
    }

    return (
        <PlatformContext.Provider value={{ platform, isBot }}>{children}</PlatformContext.Provider>
    );
};

export default PlatformContextProvider;
