import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import {
    IMPERATIVE_SCROLL_DIRECTION,
    Nav,
    NAV_SCROLL_DIRECTION,
    NAV_SCROLL_MODE,
    NavAutoFocus,
    NavScrollDataInterface,
    NavScrollOptionsInterface,
} from 'nav-tree';
import { RefObject, useContext, useRef, useState } from 'react';

interface ScrollState {
    isScrollableUp: boolean;
    isScrollableDown: boolean;
    isScrollableRight: boolean;
    isScrollableLeft: boolean;
}

export const useNavScroll = (direction: NAV_SCROLL_DIRECTION, refProp?: RefObject<Nav> | null) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const [scrollState, setScrollState] = useState<ScrollState>({
        isScrollableUp: false,
        isScrollableDown: false,
        isScrollableLeft: false,
        isScrollableRight: false,
    });

    const scrollRef = useRef<Nav>(null);
    const navRef = refProp || scrollRef;

    const scrollOptions: NavScrollOptionsInterface = {
        isScrollable: true,
        direction: direction,
        mode: isMagicMouseVisible ? NAV_SCROLL_MODE.IMPERATIVE : NAV_SCROLL_MODE.CENTER,
    };

    const autofocusOn: NavAutoFocus = isMagicMouseVisible ? ['mouseenter', 'focus'] : ['focus'];

    const onScrollCallback = (data: NavScrollDataInterface) => setScrollState({ ...data });

    const imperativeScroll = (direction: IMPERATIVE_SCROLL_DIRECTION) => {
        navRef.current?.imperativeScroll(direction);
    };

    return {
        navRef,
        scrollOptions,
        autofocusOn,
        scrollState,
        onScrollCallback,
        imperativeScroll,
    };
};
