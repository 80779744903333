import React, { useEffect } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MediaDetails } from '@/components/views/MediaDetails';
import { CPID } from '@/types/media.type';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useRouter } from '@/routing';
import { useBuildVodData } from '@/hooks/media/use-build-vod-data.hook';
import { useError } from '@/contexts/error/error.context';

const LiveDetails = () => {
    const { query } = useRouter();
    const mediaId = query.eventId;

    const MediaContent = () => {
        const { media, isFetching, error } = useBuildVodData({ mediaId }, CPID.EVENT_OR_CHANNEL);
        const { setError } = useError();

        useEffect(() => {
            if (error) {
                setError(error);
            }
        }, [error]);

        if (isFetching) {
            return <ProcessingLoader isFetching={true} />;
        }

        return <MediaDetails media={media} />;
    };

    return (
        <BaseLayout fullscreen>
            <MediaContent />
        </BaseLayout>
    );
};

export default LiveDetails;
