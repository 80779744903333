import useLogout from '@/api/auth/use-logout';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { useCallback, useContext } from 'react';
import { AppContext } from '@/contexts/app/app.context';
import { userSpecificQueries } from '@/configs/react-query.config';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

type AdditionalFunctions = () => void;

export const useAppLogout = (
    route: ROUTES,
    additionalFunctions?: AdditionalFunctions,
    params?: Record<string, any>,
) => {
    const [logout, { isFetching }] = useLogout();
    const userModule = useUserModule();
    const statsModule = useStatsModule();
    const { setDisabledQueries } = useContext(AppContext);

    const logoutFunc = useCallback(async () => {
        setDisabledQueries(userSpecificQueries.map((q) => q[1]));
        userModule.isDuringLogout = true;
        const result = await logout({});

        if (result.ok) {
            statsModule.hitAppUserLoggedOut();
            userModule.clearAuthSession();
            userModule.clearCredentials();
            additionalFunctions?.();
            Router.pushRoute(route, params || {});
            setDisabledQueries([]);
        }

        userModule.isDuringLogout = false;
    }, []);

    return { logout: logoutFunc, isFetching };
};
