import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { DeleteFromFavoritesIn } from '@/interfaces/from-schemas/user-content/deleteFromFavoritesIn';
import { DeleteFromFavoritesOut } from '@/interfaces/from-schemas/user-content/deleteFromFavoritesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useDeleteFromFavorites = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.USER_CONTENT,
        GET_MEDIA_METHODS.DELETE_FROM_FAVORITES,
        null,
    );

    return useGetMediaApiMutation<DeleteFromFavoritesIn, DeleteFromFavoritesOut>(keyHook, {
        appendAuthData: true,
        invalidate: [
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.GET_FAVORITES_WITH_FLAT_NAVIGATION],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_EVENT_TV_CHANNELS],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION],
        ],
    });
};

export default useDeleteFromFavorites;
