import t from '@/lib/i18n';
import { ErrorDescriptionContainer, ErrorDescriptionMessage } from './styles';
import { Icon } from '@/components/shared/Icon';

const playerError = process.env.player?.error || {};
const helpdeskEmail = process.env.contacts?.helpdeskEmail || {};

export const ErrorDescription = () => {
    return (
        <ErrorDescriptionContainer>
            {playerError.helpPageUrlLabel && (
                <ErrorDescriptionMessage
                    dangerouslySetInnerHTML={{
                        __html: t('player.uniwersal-error-description.line-1', {
                            qrCodeText:
                                playerError.showQRCode &&
                                t('player.uniwersal-error-description.qr-code-text'),
                            helpUrl: `<span class="highlight -target-highlight">${playerError.helpPageUrlLabel}</span>`,
                        }),
                    }}
                />
            )}

            <ErrorDescriptionMessage>
                {t('player.uniwersal-error-description.line-2')}
            </ErrorDescriptionMessage>

            {playerError.showQRCode && playerError.qrCodePath && (
                <ErrorDescriptionMessage>
                    <Icon name={playerError.qrCodePath} />
                </ErrorDescriptionMessage>
            )}

            <ErrorDescriptionMessage
                dangerouslySetInnerHTML={{
                    __html: t('player.uniwersal-error-description.line-3', {
                        email: `<span class="highlight -target-highlight">${helpdeskEmail.label}</span>`,
                    }),
                }}
            />
        </ErrorDescriptionContainer>
    );
};
