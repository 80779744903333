import { useCallback, useRef, useState } from 'react';
import { Nav } from 'nav-tree';
import { handleNavFocus } from '@/helpers/nav-tree/focus-nav-id.helper';
import { SIDEBAR_TYPE } from '@/components/shared/Player/controls/shared/types';
import { BUTTON_ID } from '@/types/button-id.type';

export const useClosePlayerSideBar = () => {
    const navRef = useRef<Nav>(null);
    const [openSidebar, setOpenSidebar] = useState(SIDEBAR_TYPE.NONE);

    const handleClose = useCallback((id: BUTTON_ID) => {
        handleNavFocus(id, navRef);
        setOpenSidebar(SIDEBAR_TYPE.NONE);
    }, []);

    return { openSidebar, setOpenSidebar, handleClose, navRef };
};
