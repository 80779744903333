import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { IsTrialAvailableIn } from '@/interfaces/from-schemas/payments/isTrialAvailableIn';
import { IsTrialAvailableOut } from '@/interfaces/from-schemas/payments/isTrialAvailableOut';

export const useIsTrialAvailable = (
    params?: IsTrialAvailableIn,
): IApiResponse<IsTrialAvailableOut | undefined> => {
    const keyhook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_IS_TRIAL_AVAILABLE,
        params,
    );

    return useGetMediaApi(keyhook, { appendAuthData: true });
};
