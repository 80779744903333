import { fillIcon } from '@/helpers/fill-icon.helper';
import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const ContactLinkWrapper = styled.div`
    font-size: 2.4rem;
    display: flex;
    align-items: center;
    p {
        margin-right: 1rem;
    }
`;
export const Link = styled(Nav)<{ active: boolean }>`
    font-size: 2.6rem;
    display: flex;
    ${(props) =>
        props.active &&
        css`
            color: ${(props) => props.theme.menu.activeColor};
        `}
`;

export const IconWrapper = styled.div`
    width: 8px;
    height: 14px;
    ${({ theme }) => fillIcon(theme.menu.activeColor)};
    margin-left: 2.3rem;
`;
