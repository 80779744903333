import { useCallback, useEffect, useState } from 'react';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { formatTime } from '@/components/shared/EPGTable/helpers/epg-table.helpers';
import { DATA_ATTR } from '@/types/epg-table.type';

export const useCellHeight = (initialHeight: string, program: ChannelProgramTvModel) => {
    const [cellHeight, setCellHeight] = useState<string>(initialHeight);

    const startTimeId = `${formatTime(program.getStartTime())}`;
    const endTimeId = `${formatTime(program.getEndTime())}`;

    const handleNodeHeight = useCallback(
        (e: CustomEvent) => {
            const node = e.detail.node;

            if (
                node?.getAttribute(DATA_ATTR.START_TIME) === startTimeId &&
                node?.getAttribute(DATA_ATTR.END_TIME) === endTimeId
            ) {
                const eventHeight = `${e.detail.nodeHeight}rem`;
                setCellHeight((prev) => (prev !== eventHeight ? eventHeight : prev));
            }
        },
        [startTimeId, endTimeId],
    );

    useEffect(() => {
        document.addEventListener('nodeHeight', handleNodeHeight as EventListener);

        return () => {
            document.removeEventListener('nodeHeight', handleNodeHeight as EventListener);
        };
    }, [handleNodeHeight]);

    return cellHeight;
};
