import { ROUTES_CONFIG } from '@/routing/routes';
import { PATHS, ROUTES } from '@/routing/types';

export const findPatternByRouteName = (routeName: ROUTES) => {
    return ROUTES_CONFIG.find((r) => r.name === routeName)?.pattern;
};

export const findZoneByRouteName = (routeName: PATHS | ROUTES) => {
    return ROUTES_CONFIG.find((r) => r.page === routeName)?.zone;
};
