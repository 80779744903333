import { useEffect } from 'react';
import { isServer } from '@/helpers/environment.helper';
import { EventListenersInterface, EVENT_LISTENER_ACTIONS, UseEventListenersProps } from './types';
import { SliderRef } from '../types';

export const handleEventListeners = (
    eventTarget: SliderRef['current'] | Window,
    events: EventListenersInterface[],
    option: EVENT_LISTENER_ACTIONS,
) => {
    if (eventTarget) {
        switch (option) {
            case EVENT_LISTENER_ACTIONS.ADD:
                events.forEach((evt) => {
                    eventTarget.addEventListener(evt.name, evt.method);
                });

                break;

            case EVENT_LISTENER_ACTIONS.REMOVE:
                events.forEach((evt) => {
                    eventTarget.removeEventListener(evt.name, evt.method);
                });
        }
    }
};

export const useEventListeners = ({ ref, EVENT_LIST, enabled = true }: UseEventListenersProps) => {
    useEffect(() => {
        if (!enabled || isServer || !ref) return;
        const reference = ref instanceof Window ? ref : ref.current;

        handleEventListeners(reference, EVENT_LIST, EVENT_LISTENER_ACTIONS.ADD);
        return () => {
            handleEventListeners(reference, EVENT_LIST, EVENT_LISTENER_ACTIONS.REMOVE);
        };
    }, [ref, enabled]);
};
