import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { SelectToEdit } from '@/components/views/Profile/SelectToEdit';

const SelectProfileToEdit = () => {
    return (
        <BaseLayout fullscreen defaultBg>
            <SelectToEdit />
        </BaseLayout>
    );
};

export default SelectProfileToEdit;
