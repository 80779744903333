import { CpwalletModel } from '@/models/payments/cpwallet.model';
import { OptionDataModel } from '@/models/payments/option-data.model';

export function getCpwalletContractId(optionData: OptionDataModel): string | undefined {
    const cpwallets = optionData?.getCpwallets();
    const cpwallet = getCpwalletWithHighestCredit(cpwallets);
    return cpwallet?.getContractId();
}

export function getCpwalletWithHighestCredit(
    cpwallets?: CpwalletModel[],
): CpwalletModel | undefined {
    let cpwalletWithHighestCredit: CpwalletModel | undefined;

    cpwallets?.forEach((cpwallet) => {
        const highestCredit = cpwalletWithHighestCredit?.getCredit();

        if (typeof highestCredit === 'undefined' || highestCredit < cpwallet.getCredit()) {
            cpwalletWithHighestCredit = cpwallet;
        }
    });

    return cpwalletWithHighestCredit;
}
