import { useMemo } from 'react';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { updateQuery } from '@/helpers/query-string/update-queries.helper';
import { Modes, ModeType, QUERY_PARAM } from '@/types/query-string.type';
import { parseParam, serializeParam } from '@/helpers/query-string/serialize.helper';

export type QueryParamConfigType = {
    replaceHistory?: boolean;
    scrollToTop?: boolean;
};

type QueryParamType<M> = {
    param: QUERY_PARAM;
    mode: M;
} & QueryParamConfigType;

export const useQueryParam = <M extends Modes>({
    param,
    mode,
    replaceHistory = false,
    scrollToTop = true,
}: QueryParamType<M>) => {
    const { query } = useRouter();
    const queryParam = useMemo(() => parseParam(query[param], mode), [query, param, mode]);
    const pageUrl = useMemo(() => updateQuery(param, queryParam), [param, queryParam]);

    const setQueryParam = (
        valueUpdater: (prev: ModeType<M> | undefined) => ModeType<M> | undefined,
    ) => {
        const updatedParamValue = valueUpdater(queryParam as ModeType<M>);
        const serializedParamValue = serializeParam(updatedParamValue);
        const updatedUrl = updateQuery(param, serializedParamValue);

        if (replaceHistory) {
            return Router.replaceRoute(updatedUrl as ROUTES, { scroll: scrollToTop });
        }

        Router.pushRoute(updatedUrl as ROUTES, { scroll: scrollToTop });
    };

    const newRouteUrl = (
        valueUpdater: (prev: ModeType<M> | undefined) => ModeType<M> | undefined,
    ) => {
        const updatedParamValue = valueUpdater(queryParam as ModeType<M>);
        const serializedParamValue = serializeParam(updatedParamValue);
        const updatedUrl = updateQuery(param, serializedParamValue);

        if (replaceHistory) {
            return updatedUrl as ROUTES;
        }

        return updatedUrl as ROUTES;
    };

    return [queryParam, setQueryParam, pageUrl, newRouteUrl] as const;
};
