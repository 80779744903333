import { useIdle } from '@/hooks/use-idle.hook';
import { ReactNode, useEffect, useState } from 'react';
import { ControlsBase } from '../ControlsBase';
import { TVTopBar } from './TVTopBar';
import { TVBottomBar } from './TVBottomBar';
import { FullscreenModal } from '../shared/FullscreenModal';
import { ChannelModel } from '@/models/channels/channel.model';
import t from '@/lib/i18n';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { useClosePlayerSideBar } from '@/hooks/player/use-close-player-sidebar';
import { usePlayerChannelSwitchPhase } from '@/hooks/player/use-player-channel-switch-phase';
import { ChannelSwitchPhase } from '@/interfaces/player.interface';
import { SettingsPanel } from '../shared/SettingsPanel';
import { SIDEBAR_TYPE } from '../shared/types';

interface ITVControlsProps {
    programTitle?: string;
    channelTitle?: string;
    channelId: string;
    startTime?: number;
}

export const TVControls = ({ channelId, channelTitle, startTime }: ITVControlsProps) => {
    const [showEPG, setShowEPG] = useState(false);
    const [showChannels, setShowChannels] = useState(false);
    const [channelToBuy, setChannelToBuy] = useState<ChannelModel | null>(null);
    const idle = useIdle(showEPG || showChannels || channelToBuy ? 8000 : 3000);
    const player = usePlayerInstance();

    const switchPhase = usePlayerChannelSwitchPhase(channelId);

    const isSwitchingChannels = switchPhase !== ChannelSwitchPhase.DONE;

    const { openSidebar, setOpenSidebar, handleClose, navRef } = useClosePlayerSideBar();

    let sidebar: ReactNode;
    switch (openSidebar) {
        case SIDEBAR_TYPE.SETTINGS:
            sidebar = <SettingsPanel onClose={handleClose} />;
            break;
        default:
            sidebar = null;
            break;
    }

    useDebouncedEffect(
        () => {
            if (!isSwitchingChannels && startTime) {
                player.skip(startTime);
            }
        },
        { timeout: 500 },
        [isSwitchingChannels],
    );

    useEffect(() => {
        if (channelToBuy) {
            player.pause();
        } else if (!channelToBuy) {
            player.play();
        }
    }, [player, channelToBuy]);

    return (
        <ControlsBase
            onDestroy={() => {
                setShowEPG(false);
                setShowChannels(false);
                setChannelToBuy(null);
                setOpenSidebar(SIDEBAR_TYPE.NONE);
            }}
            visible={!idle || !!channelToBuy}
            topBar={<TVTopBar setOpenSidebar={setOpenSidebar} />}
            fixedOverlay={
                !!channelToBuy && (
                    <FullscreenModal
                        backgroundUrl={channelToBuy.getBackgroundSrc()}
                        title={t('player.epg.buy-channel-header')}
                        onCancel={() => setChannelToBuy(null)}
                        onConfirm={() => channelToBuy.goToPaymentsPath()}
                    />
                )
            }
            bottomBar={
                <TVBottomBar
                    awaitingChange={switchPhase !== ChannelSwitchPhase.DONE}
                    nowPlayingChannelId={channelId}
                    channelTitle={channelTitle}
                    showEPG={showEPG}
                    showChannels={showChannels}
                    onChangeShowEPG={setShowEPG}
                    onChangeShowChannels={setShowChannels}
                    onChangeChannelToBuy={setChannelToBuy}
                />
            }
            bottomGradientPosition={showChannels ? 0.6 : showEPG ? 0.4 : 0}
            sideBar={sidebar}
            navRef={navRef}
        />
    );
};
