import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { CELL_PADDING, COL_HEIGHT, HOUR_RANGE } from '@/constants/epg-table';

export const Column = styled(Nav)(({ theme }) => {
    const { mainBorderColor } = theme.epgTable;

    return css`
        border-bottom: 0.1rem solid ${mainBorderColor};
        height: ${HOUR_RANGE * COL_HEIGHT}rem;
        max-height: ${HOUR_RANGE * COL_HEIGHT}rem;
        width: 100%;
    `;
});

export const ChannelFallback = styled(Nav)<{ $isActive: boolean }>(({ $isActive, theme }) => {
    const { mainBorderColor, activeCellColor } = theme.epgTable;

    const activeStyles =
        $isActive &&
        css`
            background-color: ${activeCellColor};
            border-radius: 0.5rem;
            border: 0.4rem solid ${theme.mainColor};
            padding: ${CELL_PADDING.activeVertical}rem ${CELL_PADDING.activeHorizontal}rem;
        `;

    return css`
        border-bottom-color: ${mainBorderColor};
        border-color: transparent;
        border-style: solid;
        border-width: 0.1rem;
        font-size: 2rem;
        height: ${COL_HEIGHT * 2}rem;
        line-height: 1.4;
        padding: ${CELL_PADDING.vertical}rem ${CELL_PADDING.horizontal}rem;
        text-align: center;
        ${activeStyles};
    `;
});

export const Text = styled.p`
    margin-top: 1.2rem;
    padding: 0 2rem;
    position: relative;
    z-index: 1;
`;
