import {
    CategorySchema,
    GetFavoritesOut,
    MediaListItemSchema,
} from '@/interfaces/from-schemas/navigation/getFavoritesOut';
import { CategoryModel } from '../category/category.model';
import { MediaListItemModel } from '../media-list-item.model';

type SingleFavoritesResultType = GetFavoritesOut['results'][number];

export class FavoriteModel {
    private type: SingleFavoritesResultType['type'];
    private value: string;
    private object: CategoryModel | MediaListItemModel;

    constructor(definition: SingleFavoritesResultType) {
        if (definition.type === 'vod' || definition.type === 'channel') {
            this.object = new MediaListItemModel(definition.object as MediaListItemSchema);
        } else {
            this.object = new CategoryModel(definition.object as CategorySchema);
        }

        this.type = definition.type;
        this.value = definition.value;
    }

    public getObject(): CategoryModel | MediaListItemModel {
        return this.object;
    }

    public getType(): SingleFavoritesResultType['type'] {
        return this.type;
    }

    public getValue(): string {
        return this.value;
    }
}
