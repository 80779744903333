import React, { useEffect, useState } from 'react';
import { PlayableButton } from '@/components/shared/Buttons/PlayableButton/PlayableButton';
import { ProgressOverlay } from './styles';
import { ITimerButtonsProps } from '@/components/browser/shared/TimerButton/types';
import { BUTTON_TYPE } from '@/components/browser/shared/Button/types';

export const TimerButton = (props: ITimerButtonsProps) => {
    const {
        btnType = BUTTON_TYPE.TIMER,
        text,
        time,
        timerPrefix,
        actionOnTimeout,
        timerUnit = 's.',
        startDelay = 0,
        autoStart = true,
        icon,
        permamentText,
        onClick,
    } = props;
    const [timeLeft, setTimeLeft] = useState(time);
    const [canceled, setCanceled] = useState(true);
    let intervalId: NodeJS.Timeout;

    useEffect(() => {
        if (autoStart) {
            const timeoutId = setTimeout(() => setCanceled(false), startDelay * 1000);
            return () => clearTimeout(timeoutId);
        }
    }, []);

    useEffect(() => {
        if (!canceled && timeLeft > 0) {
            startTimer();
        } else if (!canceled) {
            actionOnTimeout?.();
        }
        return () => stopTimer();
    });

    const startTimer = () => {
        intervalId = setTimeout(() => {
            if (timeLeft <= 0) {
                stopTimer();
            }
            setTimeLeft(timeLeft - 1);
        }, 1000);
    };

    const stopTimer = () => {
        if (intervalId) {
            clearTimeout(intervalId);
        }
    };

    const cancelTimer = () => {
        if (canceled) {
            actionOnTimeout?.();
        } else {
            setCanceled(true);
        }
    };

    const buttonText = permamentText || (canceled ? text : `${timerPrefix}${timeLeft}${timerUnit}`);

    const Overlay = !canceled && (
        <ProgressOverlay
            progressInPercent={((time - timeLeft) / (time - 1)) * 100}
            btnType={btnType}
        />
    );

    return (
        <PlayableButton
            btnType={btnType}
            fullWidth
            {...props}
            text={buttonText}
            onButtonAction={onClick || cancelTimer}
            overlay={Overlay}
        />
    );
};
