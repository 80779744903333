import BaseLayout from '@/components/shared/layouts/BaseLayout';
import React from 'react';
import { Goodbye } from '@/components/views/Goodbye';

const GoodbyePage = () => {
    return (
        <BaseLayout fullscreen>
            <Goodbye />
        </BaseLayout>
    );
};

export default GoodbyePage;
