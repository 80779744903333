import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { FmcContext } from '@/contexts/fmc/fmc.context';
import { updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { buildCategoryCatalogCollection } from '@/helpers/pages/build-category-catalog-collection.helper';
import { getCategoryData } from '@/helpers/pages/get-category-data.helper';
import useGetCategoryContentWithFlatNavigation from './get-category-content-with-flat-navigation';
import useGetCategoryWithFlatNavigation from './get-category-with-flat-navigation';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import { CategoryFetcherConfig } from '@/types/page-catalog.type';
import { usePaginatedGrid } from '@/hooks/page-catalog/use-paginated-grid.hook';
import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { ListElementModel } from '@/models/list-element/list-element.model';
import useGetCategoryContent from './get-category-content';
import { QUERY_PARAM, QUERY_PARAM_VALUE } from '@/types/query-string.type';
import { useQueryParam } from '@/hooks/use-query-param.hook';

const {
    subCategoryCatalog: subCategoryDataPosition,
    channelCatalog: channelCatalogDataPosition,
} = process.env.metadataSettings.position;
const { col, postersCol } = process.env.grid;
const FETCHED_DATA_EFFICIENCY_TRESHOLD = 0.5;

export const useGetCategoryCatalogData = ({
    catalogParams,
    withFilters,
    withPagination,
    fallbackMetadataPosition,
}: CategoryFetcherConfig) => {
    const configuration = useConfiguration();
    const movieCategoryId = configuration?.navigation?.movieCategoryId;

    const { data } = useGetCategoryWithFlatNavigation(catalogParams);
    const filterLists = useMemo(() => data.getFlatNavigation().filterLists || [], [data]);
    const [sortNewest] = useQueryParam({ param: QUERY_PARAM.SORT, mode: 'string' });
    const collection = data
        .getFlatNavigation()
        .collections?.find((el) =>
            sortNewest === QUERY_PARAM_VALUE.NEWEST ? el.name === 'Ostatnio dodane' : el.default,
        );

    const { followMeContent } = useContext(FmcContext);
    const { route } = useRouter();

    const { params, setParams, clearGrid, setClearGrid } = useCatalogParams({
        filterLists,
        withPagination,
    });
    const [gridElements, setGridElements] = useState<ListElementModel[]>([]);
    const [columns, setColumns] = useState<number>(movieCategoryId ? postersCol : col);

    const isSubCategory = route === ROUTES.SUBCATEGORY;

    const categoryParams = !!catalogParams &&
        !!collection && { ...catalogParams, ...params, collection };
    const paginationParams = { ...catalogParams, filters: params.filters };

    const fetcher = withFilters ? useGetCategoryContentWithFlatNavigation : useGetCategoryContent;
    const { data: gridData, isFetching, error } = fetcher(
        withPagination ? paginationParams : categoryParams,
    );

    const total = useMemo(() => gridData.getTotal(), [gridData]);
    const fetchedAll = useMemo(() => params.offset + params.limit >= total, [params, total]);
    const categoryData = useMemo(() => getCategoryData(gridData), [gridData]);
    const shouldIncreaseLimit =
        !fetchedAll && categoryData.length / params.limit < FETCHED_DATA_EFFICIENCY_TRESHOLD;

    useEffect(() => {
        if (!isFetching) {
            const dataPosition = isSubCategory
                ? subCategoryDataPosition
                : fallbackMetadataPosition ?? undefined;

            const { newCollection, columns } = buildCategoryCatalogCollection(
                gridData,
                dataPosition,
                followMeContent,
            );
            setColumns(columns);

            if (newCollection?.length) {
                const clear = clearGrid || withPagination;
                setGridElements(clear ? newCollection : (prev) => [...prev, ...newCollection]);
            }

            setClearGrid(false);
        }
    }, [isFetching, gridData]);

    useMemo(() => {
        // odśwież grida pomiędzy widokami subkategorii
        setGridElements([]);
    }, [catalogParams]);

    const onFetchMore = useCallback(
        () => setParams(updateCatalogParams({ params, total, shouldIncreaseLimit })),
        [catalogParams, params, total, shouldIncreaseLimit],
    );

    const paginatedData = usePaginatedGrid({ data: categoryData });

    return {
        gridElements: withPagination ? paginatedData : gridElements,
        columns,
        filterLists,
        isFetching,
        fetchedAll,
        onFetchMore,
        error,
    };
};
