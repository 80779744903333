import { ICONS } from '@/components/shared/Icon';
import { formatDate } from '@/helpers/date.helper';
import { ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getMultipleProductsOut';
import { GetProductOut } from '@/interfaces/from-schemas/navigation/getProductOut';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { coverUtil } from '@/utils/cover.util';
import { ProductOfferModel } from './product-offer.model';
import { RulesModel } from './rules.model';
import t from '@/lib/i18n';
import { goToDetailsOptionsType } from '@/interfaces/asset.interface';

export class ProductModel {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;

    private readonly ageGroup: number = 0;
    private readonly description: string = '';
    private readonly purchaseDescription: string = '';
    private readonly id: string = '';
    private readonly licenseEndDate: string = '';
    private readonly licenseLocation: string = '';
    private readonly licenseLocationText: string = '';
    private readonly name: string = '';
    private readonly offers: ProductOfferModel[] = [];
    private readonly partnerOffers: ProductOfferModel[] = [];
    private readonly recommendedOffers: ProductOfferModel[] = [];
    private readonly platforms: string[] = [];
    private readonly posters: ImageSourceSchema[] = [];
    private readonly subType: PRODUCT_SUBTYPES | '' = '';
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly type: PRODUCT_TYPES | '' = '';
    private rules: RulesModel[] = [];

    constructor(definition: GetProductOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                switch (name) {
                    case 'offers': {
                        const allOffers =
                            definition[name]?.map((offer) => new ProductOfferModel(offer)) || [];

                        allOffers.forEach((offer) => {
                            if (offer.isPartnerOffer()) {
                                this.partnerOffers.push(offer);
                            }

                            if (offer.isRecommended()) {
                                this.recommendedOffers.push(offer);
                            }

                            if (offer.getOptions().length) {
                                this.offers.push(offer);
                            }
                        });

                        break;
                    }

                    case 'rules': {
                        definition[name]?.map((rules) => this.rules.push(new RulesModel(rules)));
                        break;
                    }

                    default:
                        // @ts-ignore
                        this[name] = definition[name];
                }
            });
        }

        if (this.subType === PRODUCT_SUBTYPES.MOVIE) {
            this.imageDisplayMode = IMAGE_DISPLAY_MODE.POSTERS;
        }
    }

    public getAgeGroup(): number {
        return this.ageGroup;
    }

    public getProductId(): ProductIdInterface {
        return {
            id: this.id,
            type: this.type as PRODUCT_TYPES,
            subType: this.subType as PRODUCT_SUBTYPES,
        };
    }

    public getId(): string {
        return this.id;
    }

    public getType(): string {
        return this.type;
    }

    public getSubType(): PRODUCT_SUBTYPES {
        return this.subType as PRODUCT_SUBTYPES;
    }

    public getTitle(): string {
        return this.name;
    }

    public getDescription(): string {
        return this.description;
    }
    public getPurchaseDescription(): string {
        return this.purchaseDescription;
    }
    public getOffers(): ProductOfferModel[] {
        return this.offers;
    }

    public getPartnerOffers(): ProductOfferModel[] {
        return this.partnerOffers;
    }

    public getRecommendedOffers(): ProductOfferModel[] {
        return this.recommendedOffers;
    }

    public findOffer(offerId?: string): ProductOfferModel | undefined {
        return this.offers.find((offer) => offer.getId() === offerId);
    }

    public getPlatforms(): string[] {
        return this.platforms;
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getPosters(): ImageSourceSchema[] {
        return this.posters;
    }

    public getLicenseEndDate(): string {
        return formatDate(this.licenseEndDate, FORMAT_DATE_MODE.DATE_WITH_YEAR);
    }

    public getLicenseLocation(): string {
        return this.licenseLocation;
    }

    public getLicenseLocationText(): string {
        return this.licenseLocationText;
    }

    public getImage(): ListElementImageInterface {
        const images =
            this.imageDisplayMode === IMAGE_DISPLAY_MODE.THUMBNAILS
                ? this.getThumbnails()
                : this.getPosters();
        const image = coverUtil(images, this.imageDisplayMode);

        return {
            src: image ? image.src : '',
            displayMode: this.imageDisplayMode,
        };
    }
    public getPacketBgImage(): string {
        const image = this.getPosters()[0];

        return image?.src || '';
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }

    public getMetadata(): string {
        return this.type === PRODUCT_TYPES.MULTIPLE ? 'Pakiet' : '';
    }

    public getDetailsRoute(): ROUTES {
        return ROUTES.PACKET_DETAILS;
    }

    public getRouteParams(): { [key: string]: any } {
        return {
            packetId: this.id,
        };
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { trimPurchaseHistory } = options || {};
        const route = this.getDetailsRoute();
        const params = this.getRouteParams();

        if (trimPurchaseHistory) {
            Object.assign(params, { trimPurchaseHistory });
        }

        Router.pushRoute(route, params);
    }

    public get platformsIcons(): ICONS[] {
        const icons = this.platforms.map((platform: string) => {
            switch (platform) {
                case 'mobile':
                    return ICONS.MOBILE;

                case 'pc':
                    return ICONS.PC;

                case 'tv':
                    return ICONS.TV;

                case 'stb':
                    return ICONS.STB;

                default:
                    return null;
            }
        });

        return <ICONS[]>icons.filter((icon) => icon !== null);
    }

    public get subTypeIcon(): ICONS | null {
        switch (this.subType) {
            case PRODUCT_SUBTYPES.LIVE:
                return ICONS.MEDIA_TYPE_LIVE;
            case PRODUCT_SUBTYPES.VOD:
            case PRODUCT_SUBTYPES.MOVIE:
                return ICONS.MEDIA_TYPE_VOD;
            default:
                return null;
        }
    }

    public get metadataIcons(): ICONS[] {
        const icons = [this.subTypeIcon];

        return <ICONS[]>icons.filter((icon) => icon !== null);
    }

    private seoTitle(): string {
        return t('seo.packet_details.title', {
            packet_name: this.getTitle(),
            portal_domain: t('domain-url'),
        });
    }

    private seoDescription(): string {
        return this.getDescription();
    }

    public get getSeoAttributes(): { description: string; title: string } {
        return { title: this.seoTitle(), description: this.seoDescription() };
    }
}
