import { useConfiguration } from '@/contexts/configuration/configuration.hooks';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useAppSession } from '@/hooks/stats/use-app-session-hook';
import {
    getYagServiceUrl,
    mapDeviceTypeForStats,
    mapPlatformForStats,
} from '@/modules/stats/utils';
import { getBuildInfo } from '@/services/build-info.service';
import { useUserAgentData } from '@/services/user-agent-data.service';
import { useUserAgent } from '@/services/user-agent.service';
import { StatsConfig } from '@/stats';
import { useEffect } from 'react';

const activityEventsEnabled = process.env.stats?.enableActivityEvents || false;

export const useStatsConfiguration = (onConfigure: (config: StatsConfig) => void) => {
    const userModule = useUserModule();
    const buildInfo = getBuildInfo();
    const { platform } = usePlatform();
    const userAgentData = useUserAgentData();
    const ua = useUserAgent();

    const configuration = useConfiguration();
    const { sessionId } = useAppSession();

    const userId = userModule.getUserId() || process.env.defaultUserData.clientId;
    const profileId = userModule.getAuthSessionProfilId();
    const clientId = userModule.getClientId();
    const deviceId = userModule.getDeviceIdIdent();

    useEffect(() => {
        if (!sessionId) {
            return;
        }

        const statsConfig: StatsConfig = {
            appSessionId: sessionId as string,
            userId: `${userId}`,
            appVersion: buildInfo.APP_VERSION,
            deviceType: mapDeviceTypeForStats(platform.deviceType),
            model: platform.model || 'unknown',
            platform: mapPlatformForStats(platform),
            portal: process.env.portal,
            ua,
        };

        if (configuration && configuration.reporting) {
            const { reporting } = configuration;

            if (!reporting) {
                return;
            }

            if (activityEventsEnabled && reporting.activityEvents) {
                statsConfig.activityevents = {
                    service: getYagServiceUrl(reporting.activityEvents.service),
                    jwt: reporting.activityEvents.authToken,
                    originator: reporting.activityEvents.originator,
                    clientId,
                    profileId: '',
                    appVersion: buildInfo.APP_VERSION,
                    portalId: process.env.portal,
                    deviceId,
                    userAgentData,
                };

                if (profileId) {
                    statsConfig.activityevents = {
                        ...statsConfig.activityevents,
                        profileId,
                    };
                }

                if (configuration.ipData) {
                    statsConfig.activityevents = {
                        ...statsConfig.activityevents,
                        ipData: configuration.ipData,
                    };
                }
            }
        }

        onConfigure(statsConfig);
    }, [sessionId, userId, deviceId.value]);
};
