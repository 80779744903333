import useGetProfile from '@/api/auth/use-get-profile';
import { ProfileInterface } from '@/interfaces/profile-interface';
import differenceInHours from 'date-fns/differenceInHours';
import React, { createContext, useCallback, useMemo } from 'react';
import { useCookie } from '@/contexts/cookies/cookies.hooks';
import { ProfileContextProps, ProfileContextProviderProps, PROFILE_COOKIES } from './types';
import { useGetSession } from '@/api/auth/use-get-session';

export const ProfileContext = createContext<ProfileContextProps>({
    sessionProfile: undefined,
    setSessionProfile: () => null,
    profileUpdateHours: undefined,
    profileIdCookie: undefined,
});

const ProfileContextProvider = ({ children }: ProfileContextProviderProps) => {
    const [profileDateCookie, setProfileDateCookie, removeProfileDateCookie] = useCookie(
        PROFILE_COOKIES.PROFILE_SELECTED_AT,
    );
    const [profileIdCookie, setProfileIdCookie, removeProfileIdCookie] = useCookie(
        PROFILE_COOKIES.PROFILE_ID,
    );
    const profileDate = profileDateCookie && new Date(profileDateCookie);

    const { data: session } = useGetSession();
    const { data: sessionProfile } = useGetProfile({ id: session?.profileId || profileIdCookie });

    useMemo(() => {
        if (!profileIdCookie && sessionProfile) {
            setProfileIdCookie(sessionProfile?.profileId);
        }
    }, [profileIdCookie, sessionProfile, setProfileIdCookie]);

    const profileUpdateHours = profileDate
        ? differenceInHours(new Date(), new Date(profileDate))
        : undefined;

    const updateSessionProfile = useCallback((profile: ProfileInterface) => {
        if (profile) {
            setProfileDateCookie(new Date().toISOString());
            setProfileIdCookie(profile.profileId);
        } else {
            removeProfileDateCookie();
            removeProfileIdCookie();
        }
    }, []);

    const value = useMemo(
        () => ({
            sessionProfile,
            setSessionProfile: updateSessionProfile,
            profileUpdateHours,
            profileIdCookie,
        }),
        [sessionProfile, profileUpdateHours, profileIdCookie, updateSessionProfile],
    );

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default ProfileContextProvider;
