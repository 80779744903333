import { LoginOut } from '@/interfaces/from-schemas/auth/loginOut';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { ByCodeAuthData, UserAuthData } from '@/types/user-auth-data.type';
import { useCallback } from 'react';
import { IGetMediaMutateFn } from '../use-get-media-api.type';
import { useLoginUser } from './use-login-user.hook';
import { useRouter } from '@/routing';

export const useByCodeLogin = (login: IGetMediaMutateFn<UserAuthData, LoginOut>) => {
    const authProvider = AUTH_PROVIDERS.NATIVE;
    const { query } = useRouter();
    const handleLoginUser = useLoginUser({ login, authProvider, query });

    const byCodeLogin = useCallback(
        async (authToken: string) => {
            const params: ByCodeAuthData = { authToken, authProvider };
            handleLoginUser(params);
        },
        [handleLoginUser, authProvider],
    );

    return byCodeLogin;
};
