import { GetMultipleProductsOut } from '@/interfaces/from-schemas/navigation/getMultipleProductsOut';
import { PRODUCT_SUBTYPES } from '@/types/media.type';
import { MultipleTypeProductModel } from './multiple-type-product.model';

class MultipleTypeProductsModel {
    private readonly packets: MultipleTypeProductModel[] = [];

    constructor(definition: GetMultipleProductsOut) {
        if (definition) {
            definition.forEach((value: GetMultipleProductsOut[number]) => {
                if (value.subType === PRODUCT_SUBTYPES.PACKET) {
                    this.packets.push(new MultipleTypeProductModel(value));
                }
            });
        }
    }

    public getPackets(): MultipleTypeProductModel[] {
        return this.packets;
    }
}

export default MultipleTypeProductsModel;
