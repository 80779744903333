import styled from 'styled-components';
import { StyledPositionedTextProps } from './types';

const buildPosition = ({ position }: StyledPositionedTextProps) => {
    switch (position) {
        case 'left':
            return 'left';
        case 'center':
            return 'center';
        case 'right':
            return 'flex-end';

        default:
            return 'left';
    }
};

export const StyledPositionedText = styled.div<StyledPositionedTextProps>`
    display: flex;
    justify-content: ${(props) => buildPosition(props)};
`;

export const StyledLinkText = styled.div`
    margin-top: auto;
    margin-bottom: 0.2rem;
    margin-left: 0.5rem;
    font-size: 1.4rem;
    visibility: hidden;
    width: 0;
`;

export const StyledShowMoreText = styled.a`
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    :hover {
        ${StyledLinkText} {
            transition: all 0.1s linear;
            visibility: visible;
            width: auto;
        }

        > * {
            transition: color 0.1s linear;
            color: ${({ theme }) => theme.text.color.highlight};
        }

        svg {
            fill: ${({ theme }) => theme.text.color.highlight};
            width: 1rem;
            height: 1rem;
            margin-bottom: 0.7rem;
        }
    }

    svg {
        fill: ${({ theme }) => theme.text.color.highlight};
        width: 1.6rem;
        height: 1.6rem;
        margin-bottom: 0.5rem;
        margin-top: auto;
        margin-left: 0.5rem;
    }
`;
