import { PlayableButton } from '@/components/shared/Buttons';
import { ContactLink } from '@/components/shared/customer-care';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import { PaymentSteps } from '@/components/shared/PaymentSteps';
import { ProductCard } from '@/components/shared/ProductCard';
import { redirectToPaymentStep } from '@/helpers/payment.helper';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { GUTTER } from '@/types/gutter.type';
import { navVertical } from 'nav-tree';
import React, { useState } from 'react';
import {
    ButtonContainer,
    ContentContainer,
    PaymentsLayoutCenterContainer,
    PaymentsLayoutContainer,
    ProductContainer,
    StepsContainer,
} from './styles';
import { PaymentsLayoutProps } from './types';

export const PaymentsLayout = ({
    currentStep,
    product,
    hasError,
    errorDescription,
    isStepsVisible,
    isProductCardVisible,
    children,
    onExitPaymentPath,
}: PaymentsLayoutProps) => {
    const [isExitMessageVisible, setExitMessageVisibility] = useState(false);

    const onChangeStep = (step: PAYMENT_STEPS) => {
        if (currentStep !== step) {
            const productId = product.getProductId();
            redirectToPaymentStep({ step, productId });
        }
    };

    if (isExitMessageVisible) {
        return (
            <PaymentsLayoutCenterContainer>
                <Message icon={ICONS.INFO_CIRCLE} title={t('payment-steps.exit-message-title')} />
                <ButtonContainer func={navVertical}>
                    <PlayableButton
                        text={t('payment-steps.cancel-button-label').toUpperCase()}
                        onButtonAction={() => setExitMessageVisibility(false)}
                        defaultFocused
                    />
                    <PlayableButton
                        text={t('payment-steps.ok-button-label').toUpperCase()}
                        onButtonAction={onExitPaymentPath}
                    />
                </ButtonContainer>
            </PaymentsLayoutCenterContainer>
        );
    }

    if (hasError) {
        //do obecnej karty błedu dodałam stopke ContactLink customer care error page
        // karta błędu płatnosći ma być widoczna na warstwie +1 i ma wyglądać jak karta błedu Customer care
        //wystarczyło dodać do istnejącego rozwiązanią komponent ContactLink
        const title = errorDescription || t('payment-steps.payment-failed');
        const description = errorDescription ? '' : t('payment-steps.try-again');

        return (
            <PaymentsLayoutCenterContainer>
                <Message
                    icon={ICONS.WARNING_CIRCLE}
                    title={title}
                    gutter={GUTTER.BIG}
                    description={description}
                />
                <ButtonContainer>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={Router.back}
                        defaultFocused
                        gutter={GUTTER.NONE}
                    />
                </ButtonContainer>
                <ContactLink />
            </PaymentsLayoutCenterContainer>
        );
    }

    return (
        <PaymentsLayoutContainer isAlignCenter={!isProductCardVisible}>
            {isProductCardVisible && (
                <ProductContainer>
                    <ProductCard product={product} />
                </ProductContainer>
            )}

            <ContentContainer restoreLastFocus>
                {isStepsVisible && (
                    <StepsContainer>
                        <PaymentSteps
                            currentStep={currentStep}
                            onChangeStep={onChangeStep}
                            onExitAction={() => setExitMessageVisibility(true)}
                        />
                    </StepsContainer>
                )}

                {children}
            </ContentContainer>
        </PaymentsLayoutContainer>
    );
};
