import { Wrapper, Header, CategoryWrapper } from './styles';
import { NavScrollDataInterface, NAV_SCROLL_DIRECTION } from 'nav-tree';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { CloseButton } from '@/components/shared/Buttons';
import { ContactLink } from '@/components/shared/customer-care';
import { HelpItems } from '@/components/shared/customer-care/HelpItems/HelpItems';
import t from '@/lib/i18n';
import { CategoryTitle } from '@/components/shared/customer-care/CategoryTitle/CategoryTitle';
import { GUTTER } from '@/types/gutter.type';

export const CustomerCareHelp = ({
    onFilterScroll,
    onMore,
    data,
}: {
    onFilterScroll: (data: NavScrollDataInterface) => void;
    onMore: (categoryId: string) => void;
    data: any;
}) => {
    return (
        <Wrapper
            scrollOptions={{
                isScrollable: true,
                direction: NAV_SCROLL_DIRECTION.VERTICAL,
            }}
            onNavScroll={onFilterScroll}
            restoreLastFocus
        >
            <Header>
                <MainText
                    text={t('pages.customer-care.help')}
                    mode={MAINTEXT_KIND.PAGE_CUSTOMER_CARE_TITLE}
                    gutter={GUTTER.LARGE}
                />
            </Header>
            <CloseButton defaultFocused />
            {data &&
                data.map((el: any, index: number) => {
                    const firstList = index === 0;
                    return (
                        <CategoryWrapper key={index}>
                            <CategoryTitle title={el.category.title} />

                            <HelpItems
                                id={el.category.id}
                                onMore={onMore}
                                firstElement={firstList}
                            />
                            {el.children.map((child: any, i: number) => {
                                const first = i === 0;
                                return (
                                    <HelpItems
                                        key={i}
                                        withSubcategory
                                        id={child.category.id}
                                        onMore={onMore}
                                        firstElement={first && index === 0}
                                        subCategoryTitle={child.category.title}
                                    />
                                );
                            })}
                        </CategoryWrapper>
                    );
                })}
            <ContactLink />
        </Wrapper>
    );
};
