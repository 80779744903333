import { Z_INDEX } from '@/targets/default/theme';
import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

export const VirtualScrollContainer = styled.div`
    position: relative;
    width: 100%;
`;

const VirtualScrollContainerStyles = css<{ height: string }>`
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    padding: 1rem 0;
    height: ${({ height }) => height};
`;

export const VirtualScrollHorizontalSliderContainer = styled(Nav)<{ height: string }>`
    ${VirtualScrollContainerStyles}
`;

export const VirtualScrollHorizontalSliderLoaderContainer = styled.div<{ height: string }>`
    ${VirtualScrollContainerStyles}
`;

export const ArrowContainer = styled.div<{ right?: boolean }>`
    position: absolute;
    top: 50%;
    left: ${({ right }) => (right ? 'auto' : '5rem')};
    right: ${({ right }) => (right ? '5rem' : 'auto')};
    z-index: ${Z_INDEX.ICON_WRAPPER};
    transform: translateY(-50%);
    width: 8rem;
    height: 8rem;
    padding: 2rem;
    border-radius: 50%;
    fill: ${({ theme }) => theme.button.secondaryFontColor};
    background-color: ${({ theme }) => theme.button.secondaryBgColor};

    &:hover {
        background-color: ${({ theme }) => theme.button.hoverBgColor};
        fill: ${({ theme }) => theme.button.hoverFontColor};
    }
`;
