import React, { createContext, useMemo, useState } from 'react';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { BACKGROUND_MARGIN } from '@/components/shared/BackgroundImage/types';
import { BackgroundContextProps, BackgroundContextProviderProps } from './types';

export const BackgroundContext = createContext<BackgroundContextProps>({
    bcgSrc: '',
    setBcgSrc: () => null,
    bcgMargin: undefined,
    setBcgMargin: () => null,
    shadeMode: undefined,
    setShadeMode: () => null,
});

const BackgroundContextProvider = ({ children }: BackgroundContextProviderProps) => {
    const [bcgSrc, setBcgSrc] = useState<string>('');
    const [bcgMargin, setBcgMargin] = useState<BACKGROUND_MARGIN>(BACKGROUND_MARGIN.NONE);
    const [shadeMode, setShadeMode] = useState<SHADE_MODE>(SHADE_MODE.CUSTOM);

    return useMemo(
        () => (
            <BackgroundContext.Provider
                value={{ bcgSrc, setBcgSrc, bcgMargin, setBcgMargin, shadeMode, setShadeMode }}
            >
                {children}
            </BackgroundContext.Provider>
        ),
        [bcgSrc, bcgMargin, shadeMode, children],
    );
};

export default BackgroundContextProvider;
