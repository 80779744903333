import { device } from '@/targets/default/mediaQuery';
import { css } from 'styled-components';

export const buildMediaQuery = (
    cssProperty: string,
    arrayOfSizes: Array<number | string | null>,
    unit: 'rem' | '%' | 'vh' | '' = 'rem',
    prefix = '',
    postfix = '',
    additionalBreakpoints: Array<{ breakpoint: string; size: number | string | null }> = [],
) => {
    const standardMediaQueries = arrayOfSizes.map((size, index) => ({
        breakpoint: Object.values(device)[index],
        size,
    }));

    const allMediaQueries = [...standardMediaQueries, ...additionalBreakpoints];

    const sortedMediaQueries = allMediaQueries.sort((a, b) => {
        const widthA = parseInt((a.breakpoint.match(/\d+/g) || ['0'])[0], 10);
        const widthB = parseInt((b.breakpoint.match(/\d+/g) || ['0'])[0], 10);
        return widthA - widthB;
    });

    const mediaQueryCss = sortedMediaQueries.map(({ breakpoint, size }) => {
        if (size === null) return;

        return css`
            @media ${breakpoint} {
                ${cssProperty}: ${prefix}${size}${unit}${postfix};
            }
        `;
    });

    return css`
        ${mediaQueryCss}
    `;
};
