import { PlayableButton } from '@/components/shared/Buttons';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import HelpNoteText from '@/components/shared/HelpNoteText';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';
import t from '@/lib/i18n';
import { useRouter } from '@/routing';
import { GUTTER } from '@/types/gutter.type';
import { redirectToLogin } from '@/helpers/redirect-to-login.helper';

export const AccountExistsMessage = () => {
    const { query } = useRouter();
    const { email } = query;

    return (
        <>
            <Message
                icon={ICONS.INFO_CIRCLE}
                innerHtmlTitle={t('pages.login.account-exists.header', {
                    email,
                    ...buildHighlightSpan('highlight -target-highlight'),
                })}
                innerHtmlDescription={t('pages.login.account-exists.description-tv', {
                    domain: t('domain-url'),
                    ...buildHighlightSpan('highlight -target-highlight'),
                    email,
                })}
            />

            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={redirectToLogin}
                    gutter={GUTTER.NORMAL}
                    defaultFocused
                />
            </FormContainer>

            <HelpNoteText />
        </>
    );
};
