import FormRow from '@/components/shared/form/commons/form-row/form-row.common';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { PlayableButton } from '@/components/shared/Buttons';
import { NavWrapper } from '@/components/views/Account/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@/lib/yupResolver';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { EnterCodeFromProps } from '@/components/views/Account/Code/types';

interface FormData {
    purchaseCode: string;
}

const schema = Yup.object().shape({
    purchaseCode: Yup.string().required(t('form.validate.required')),
});

const MAX_CODE_LENGTH = process.env.enterCode.maxCodeLength;

const EnterCodeForm = ({
    defaultCode,
    handleCheckCode,
    handleActivateCode,
}: EnterCodeFromProps) => {
    const { register, handleSubmit, errors } = useForm<FormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            purchaseCode: defaultCode,
        },
    });

    const checkCode = handleSubmit((data) => {
        handleCheckCode(data.purchaseCode);
    });

    const activateCode = handleSubmit((data) => {
        handleActivateCode(data.purchaseCode);
    });

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <NavWrapper>
            <form>
                <FormRow>
                    <TextInput
                        name="purchaseCode"
                        icon={ICONS.KEY}
                        ref={register()}
                        placeholder={t('pages.have-code.enter-code')}
                        defaultFocused
                        error={!!errors.purchaseCode}
                        data-testing="pages.have-code.enter-code"
                        maxLength={MAX_CODE_LENGTH}
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={checkCode}
                        text={t('pages.have-code.check')}
                        data-testing="pages.have-code.check"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={activateCode}
                        text={t('pages.have-code.activate')}
                        data-testing="pages.have-code.activate"
                    />
                </FormRow>
            </form>
            {renderFormErrors()}
        </NavWrapper>
    );
};

export default EnterCodeForm;
