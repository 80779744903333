import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import { NAVIGATION_KEYS } from 'nav-tree';
import { useColumnContext } from './column.context';
import { useTablePageContext } from './table-page.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { FOCUSED_COLUMN_INDEX } from '@/constants/epg-table';

interface IncrementColumnContextProviderProps {
    children: React.ReactNode;
}

interface IncrementColumnContext {
    focusedColIndex: number;
    handleDayChangeReset: () => void;
    handleFilterChangeReset: () => void;
    handlePageChange: (navKeys?: NAVIGATION_KEYS) => void;
    handleStateReset: (focusedColIndexResetValue?: number) => void;
    isLastPage: boolean;
    isVeryLastElementFullyVisible: boolean;
    loadedAllPages: boolean;
    setFocusedColIndex: Dispatch<SetStateAction<number>>;
    setIsLastPage: Dispatch<SetStateAction<boolean>>;
    setIsVeryLastElementFullyVisible: Dispatch<SetStateAction<boolean>>;
    setLoadedAllPages: Dispatch<SetStateAction<boolean>>;
}

const initialContextValue = {
    focusedColIndex: -1,
    handleDayChangeReset: () => null,
    handleFilterChangeReset: () => null,
    handlePageChange: () => null,
    handleStateReset: () => null,
    isLastPage: false,
    isVeryLastElementFullyVisible: false,
    loadedAllPages: false,
    setFocusedColIndex: () => null,
    setIsLastPage: () => false,
    setIsVeryLastElementFullyVisible: () => false,
    setLoadedAllPages: () => false,
};

const IncrementColumnContext = createContext<IncrementColumnContext>(initialContextValue);

export const IncrementColumnContextProvider = ({
    children,
}: IncrementColumnContextProviderProps) => {
    const [focusedColIndex, setFocusedColIndex] = useState(0);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isVeryLastElementFullyVisible, setIsVeryLastElementFullyVisible] = useState(false);
    const [loadedAllPages, setLoadedAllPages] = useState(false);
    const { onPageChange, setCurrentPage } = useTablePageContext();
    const { resetContext: resetColumnContext } = useColumnContext();

    const handleStateReset = (
        focusedColIndexResetValue: number = FOCUSED_COLUMN_INDEX.pageChanged,
    ) => {
        batchedUpdates(() => {
            setFocusedColIndex(focusedColIndexResetValue);
            setLoadedAllPages(false);

            resetColumnContext();
        });
    };

    const handlePageChange = useCallback(
        (navKeys?: NAVIGATION_KEYS) => {
            handleStateReset(FOCUSED_COLUMN_INDEX.pageChanged);
            navKeys && onPageChange(navKeys);
        },
        [onPageChange],
    );

    const handleFilterChangeReset = useCallback(() => {
        batchedUpdates(() => {
            handleStateReset(FOCUSED_COLUMN_INDEX.filtersChanged);
            setCurrentPage(1);
        });
    }, [setCurrentPage]);

    const handleDayChangeReset = useCallback(() => {
        batchedUpdates(() => {
            setLoadedAllPages(false);
        });
    }, []);

    return (
        <IncrementColumnContext.Provider
            value={{
                setIsLastPage,
                focusedColIndex,
                handleDayChangeReset,
                handleFilterChangeReset,
                handlePageChange,
                handleStateReset,
                isLastPage,
                isVeryLastElementFullyVisible,
                loadedAllPages,
                setFocusedColIndex,
                setIsVeryLastElementFullyVisible,
                setLoadedAllPages,
            }}
        >
            {children}
        </IncrementColumnContext.Provider>
    );
};

export function useIncrementColumnContext() {
    const context = useContext(IncrementColumnContext);
    if (context === undefined) {
        throw new Error(
            'useIncrementColumnContext must be used within a IncrementColumnContextProvider',
        );
    }
    return context;
}
