import { DEFAULT_PLAYER_STATUS, DEFAULT_PLAYER_TIME_MARKERS } from '@/configs/player.config';
import { IPlayerStatus, IPlayerTimeMarkers } from '@/interfaces/player.interface';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { createContext } from 'react';
import { PlayerContextProviderProps } from './types';

export const PlayerTimeMarkersContext = createContext<IPlayerTimeMarkers>(
    DEFAULT_PLAYER_TIME_MARKERS,
);

export const PlayerStatusContext = createContext<IPlayerStatus>(DEFAULT_PLAYER_STATUS);

export const PlayerInstanceContext = createContext<IPlayer.PlayerAPI | null>(null);

export const PlayerMediaDetailsContext = createContext<MediaDetailsModel | null>(null);

export const PlayerWrapperContext = createContext<HTMLDivElement | null>(null);

const PlayerContextProvider = ({
    playerWrapper,
    instance,
    status,
    timeMarkers,
    mediaDetails,
    children,
}: PlayerContextProviderProps) => {
    return (
        <PlayerWrapperContext.Provider value={playerWrapper}>
            <PlayerMediaDetailsContext.Provider value={mediaDetails}>
                <PlayerInstanceContext.Provider value={instance}>
                    <PlayerStatusContext.Provider value={status}>
                        <PlayerTimeMarkersContext.Provider value={timeMarkers}>
                            {children}
                        </PlayerTimeMarkersContext.Provider>
                    </PlayerStatusContext.Provider>
                </PlayerInstanceContext.Provider>
            </PlayerMediaDetailsContext.Provider>
        </PlayerWrapperContext.Provider>
    );
};

export default PlayerContextProvider;
