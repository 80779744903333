import React from 'react';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { RelatedProductContainer } from './styles';
import { RelatedProductProps } from './types';
import { PurchasePacketButton } from '@/components/shared/PurchasePacketButton/PurchasePacketButton';
import useGetProduct from '@/api/navigation/use-get-product';

export const RelatedProduct = ({
    relatedProduct,
    defaultFocused,
    onSelectRelatedProduct,
}: RelatedProductProps) => {
    const productId = relatedProduct.getProductId();
    const name = relatedProduct.getName();
    const priceLabel = relatedProduct.getPriceLabel();
    const oldPriceLabel = relatedProduct.getOldPriceLabel();
    const accessText = relatedProduct.getAccessText();

    const { data: relatedProducttDetails } = useGetProduct({
        product: productId,
    });
    return (
        <RelatedProductContainer>
            {priceLabel && (
                <PurchasePacketButton
                    label={name}
                    priceLabel={priceLabel}
                    oldPriceLabel={oldPriceLabel}
                    durationLabel={accessText}
                    rightSideLabel={t('payment-steps.browse-content')}
                    defaultFocused={defaultFocused}
                    onButtonAction={() => onSelectRelatedProduct(productId)}
                    onRightSideAction={() =>
                        Router.pushRoute(ROUTES.PACKET_DETAILS, {
                            packetId: productId.id,
                        })
                    }
                    isPromotion={relatedProduct.getRegularPriceText() != null}
                    packetImage={relatedProducttDetails?.getImage().src}
                    packetBgImage={relatedProducttDetails?.getPacketBgImage()}
                    purchaseDescription={relatedProducttDetails?.getPurchaseDescription()}
                    checkHigherPricesExist={relatedProduct.getHigherPricesExist()}
                />
            )}
        </RelatedProductContainer>
    );
};
