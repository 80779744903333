import { ListElementInterface } from '@/interfaces/list-element.interface';
import { Nav } from 'nav-tree';
import { createContext, RefObject } from 'react';

interface GridContextProps {
    loadRow: boolean;
    activeIndex: number;
    colIndex: number;
    rowIndex: number;
}

export const GridContext = createContext<GridContextProps>({
    loadRow: false,
    activeIndex: 0,
    colIndex: 0,
    rowIndex: 0,
});

export interface GridProps {
    data: ListElementInterface[];
    col: number;
    defaultFocused?: boolean;
    lockNav?: boolean;
    onActiveElement?: (itemData: ListElementInterface) => void;
    onFetchMoreElements?: (activeItemId?: number) => void;
    focusRef: RefObject<Nav>;
    isSearch?: boolean;
    isChronological?: boolean;
    rowCount?: number;
}

export interface GridElementsProps {
    data: ListElementInterface[];
    onActiveElement: (itemData: ListElementInterface, index: number) => void;
    cols: number;
    rowCount: number;
    defaultFocusedList?: boolean;
    isSearch?: boolean;
    isChronological?: boolean;
}

export interface HorizontalRowProps {
    dataList: ListElementInterface[];
    rowIndex: number;
    rowCount: number;
    onActiveElement: (itemData: ListElementInterface, index: number) => void;
    cols: number;
    defaultFocusedList?: boolean;
    isSearch?: boolean;
    isChronological?: boolean;
}

export interface GridRootInterface {
    $hide?: boolean;
}

export interface ElementsContainerInterface {
    height?: number;
}
