import { RefObject } from 'react';

export const lazyLoadImages = (
    target: RefObject<HTMLDivElement>,
    visibleElements: number,
    actualIndex: number,
): void => {
    const images = target.current ? Array.from(target.current.querySelectorAll('img')) : [];

    const changeImgAttributes = (img: HTMLImageElement) => {
        img.src = img.dataset.src || '';
        img.classList.add('lazy-done');
    };

    images.forEach((_img, index) => {
        if (!_img.classList.contains('lazy-done')) {
            if (index < visibleElements + actualIndex) {
                changeImgAttributes(images[index]);
            }
        }
    });
};

export const clearLazyClasses = (target: RefObject<HTMLDivElement>) => {
    const images = target.current ? Array.from(target.current.querySelectorAll('img')) : [];

    images.forEach((img) => {
        if (img.classList.contains('lazy-done')) {
            img.classList.remove('lazy-done');
        }
    });
};
