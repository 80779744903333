import React, { useCallback, useMemo, useState } from 'react';
import t from '@/lib/i18n';
import { VirtualScrollHorizontalSlider } from '@/components/shared/VirtualScrollHorizontalSlider';
import { CHANNELS_SLIDER_WINDOW_WIDTH } from '@/components/shared/Player/controls/EPG/consts';
import { MediaRelatedTile } from '@/components/shared/MediaRelatedList/MediaRelatedTile';
import {
    MediaRelatedSlider,
    MediaRelatedSliderTitle,
} from '@/components/shared/MediaRelatedList/styles';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { MediaRelatedListSliderProps, RenderItemProps } from './types';

const MediaRelatedListSliderRaw = ({ mediaRelatedList }: MediaRelatedListSliderProps) => {
    const navId = 'mediaRelatedListSlider';
    const [internalFocused, setInternalFocused] = useState(mediaRelatedList[0].getId());

    const getItemToNavId = useCallback((media: ListElementModel) => media?.getId(), []);
    const isDefault = useCallback((media) => internalFocused === media.getId(), [internalFocused]);

    const renderItem = useCallback(
        (item: ListElementModel, { onNav, ...props }: RenderItemProps) => (
            <MediaRelatedTile
                {...props}
                onAction={() => {
                    item.goToDetails({ replaceRoute: true });
                }}
                onNav={(focused) => {
                    onNav(focused);
                    if (focused) {
                        setInternalFocused(item.getId());
                    }
                }}
                media={item}
            />
        ),
        [],
    );

    const renderMediaRelatedList = useMemo(() => {
        return (
            <MediaRelatedSlider>
                <MediaRelatedSliderTitle>{t('see-also')}</MediaRelatedSliderTitle>

                <VirtualScrollHorizontalSlider<ListElementModel>
                    height={'100%'}
                    itemToNavId={getItemToNavId}
                    navId={navId}
                    isDefault={isDefault}
                    items={mediaRelatedList}
                    windowWidth={CHANNELS_SLIDER_WINDOW_WIDTH}
                    renderItem={renderItem}
                />
            </MediaRelatedSlider>
        );
    }, [mediaRelatedList, isDefault, getItemToNavId, renderItem]);

    return renderMediaRelatedList;
};

export const MediaRelatedListSlider = React.memo(MediaRelatedListSliderRaw);
