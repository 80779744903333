import { FULL_HEIGHT_CELL, HALF_HEIGHT_CELL } from '@/constants/epg-table';
import { EPGTableProgram } from '@/types/epg-table.type';

import { getMinutesBetween } from './epg-table.helpers';

export const getFirstProgramStartTime = (programs: EPGTableProgram[]) => {
    return programs[0].program.getStartTime();
};

export const getLastProgramEndTime = (programs: EPGTableProgram[]) => {
    return programs[programs.length - 1].program.getEndTime();
};

export const getLastProgramEndTimestamp = (programs: EPGTableProgram[]) => {
    return programs[programs.length - 1].program.getEndTimeTimestamp();
};

const addOneHourToDate = (date: Date): Date => {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 1);
    return newDate;
};

const add30minToDate = (date: Date): Date => {
    const newDate = new Date(date.getTime());
    newDate.setMinutes(date.getMinutes() + 30);
    return newDate;
};

export const getCombinedCellHeight = (
    programs: EPGTableProgram[],
    fullHour: Date,
    oneHourFromNow: Date,
    twoHoursFromNow: Date,
    isFirstRow: boolean,
    isLastRow: boolean,
) => {
    const firstProgramStartTime = getFirstProgramStartTime(programs);
    const defaultEndTime = addOneHourToDate(twoHoursFromNow);
    const lastProgramEndTime = getLastProgramEndTime(programs) || defaultEndTime.getTime();

    const FromNow1h30m = add30minToDate(oneHourFromNow);
    const startedInPast = programs[0].program.getStartTimeTimestamp() < fullHour.getTime();
    const lastEndTimestamp = getLastProgramEndTimestamp(programs) || twoHoursFromNow.getTime();

    const endsInFuture = lastEndTimestamp > twoHoursFromNow.getTime();
    const endsAtLeast_1h_FromNow = lastEndTimestamp >= oneHourFromNow.getTime();
    const ends_1h30m_FromNow = getMinutesBetween(FromNow1h30m.getTime(), lastProgramEndTime) >= 0;

    const minutesBetween = getMinutesBetween(
        startedInPast ? fullHour.getTime() : firstProgramStartTime,
        endsInFuture ? twoHoursFromNow.getTime() : lastProgramEndTime,
    );

    const getCombinedHeight = () => {
        if (isFirstRow && (ends_1h30m_FromNow || endsAtLeast_1h_FromNow)) {
            return FULL_HEIGHT_CELL;
        }
        if (minutesBetween >= 30 && (isLastRow || isFirstRow)) {
            return FULL_HEIGHT_CELL;
        }
        if (minutesBetween < 30) {
            return FULL_HEIGHT_CELL;
        }
        return HALF_HEIGHT_CELL;
    };

    const combinedHeight = getCombinedHeight();

    return {
        combinedHeight: `${combinedHeight}rem`,
        showIndicator: combinedHeight / programs.length < HALF_HEIGHT_CELL,
    };
};
