import { ICONS } from '@/components/shared/Icon';
import { fillIcon } from '@/helpers/fill-icon.helper';
import styled, { css } from 'styled-components';

const buildBackground = (icon: ICONS) => {
    switch (icon) {
        case ICONS.PARENT_CONTROL_0:
            return css`
                background-color: ${({ theme }) => theme.ageRestriction.noAgeBgColor};
            `;
        case ICONS.PARENT_CONTROL_7:
            return css`
                background-color: ${({ theme }) => theme.ageRestriction.age7BgColor};
            `;
        case ICONS.PARENT_CONTROL_12:
            return css`
                background-color: ${({ theme }) => theme.ageRestriction.age12BgColor};
            `;
        case ICONS.PARENT_CONTROL_16:
            return css`
                background-color: ${({ theme }) => theme.ageRestriction.age16BgColor};
            `;
        case ICONS.PARENT_CONTROL_18:
            return css`
                background-color: ${({ theme }) => theme.ageRestriction.age18BgColor};
            `;
    }
};

export const AgeRestrictionGridElementContainer = styled.div<{ active: boolean }>`
    display: inline-block;
    margin: 2rem;

    ${(props) =>
        props.active &&
        css`
            transform: scale(1.2);
        `}
    &:hover {
        transform: scale(1.2);
    }
`;

export const AgeRestrictionIconWrapper = styled.div<{
    active: boolean;
    size: number;
    icon: ICONS;
}>`
    ${({ icon }) => buildBackground(icon)}

    ${(props) =>
        props.active &&
        css`
            border-width: 0.5rem;
            border-style: solid;
            border-color: ${({ theme }) => theme.ageRestriction.borderColor};
        `}
  
    width: ${({ size }) => size}rem;
    height: ${({ size }) => size}rem;
    border-radius: 50%;
    display: block;
    ${({ theme }) => fillIcon(theme.text.color.primary)};
    padding: 5rem;
    box-sizing: border-box;
`;

export const AgeRestrictionGridElementWrapper = styled.div<{
    size: number;
}>`
    width: ${({ size }) => size}rem;
`;

export const AgeRestrictionName = styled.div`
    font-size: ${({ theme }) => theme.text.size.ageRestriction};
    color: ${({ theme }) => theme.text.color.ageRestriction};
    padding: 1.5rem 0 0 0;
    text-align: center;
`;

export const IconWrapper = styled.div`
    padding: 5rem;
`;
