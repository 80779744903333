import { Z_INDEX } from '@/targets/default/theme';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const SearchContainer = styled(Nav)`
    position: relative;
    height: 100%;
`;

export const InputsContainer = styled(Nav)`
    position: absolute;
    top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${Z_INDEX.SEARCH_INPUTS_CONTAINER};
`;

export const TextInputContainer = styled.div`
    max-width: 78rem;
    width: 100%;
    margin: 0 1rem;
`;

export const ContentContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
`;

export const ResultsContainer = styled.div`
    height: 100%;
    margin-left: 15rem;
    box-sizing: border-box;
    position: relative;
`;

export const ResultsTitleContainer = styled.div`
    position: absolute;
    top: 19rem;
    left: 0;
`;

export const ResultsGridContainer = styled.div`
    position: absolute;
    top: 24rem;
    bottom: 0;
    left: 0;
    right: 0;
`;
