import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { navHorizontal } from 'nav-tree';
import React, { useState } from 'react';
import {
    ButtonContent,
    PurchaseButtonContainer,
    ButtonRightSideContent,
    RightSideContentBorder,
    Border,
    BorderWithTextLabel,
    LabelContainer,
} from '../PurchaseButton/styles';
import {
    Description,
    Duration,
    Label,
    Price,
    PriceContainer,
    OldPrice,
    Header,
    LineSeparator,
    PacketImage,
} from './styles';
import { PurchaseButtonProps } from './types';

export const PurchasePacketButton = ({
    label,
    priceLabel,
    oldPriceLabel,
    durationLabel,
    rightSideLabel,
    isPromotion,
    gutter = GUTTER.NORMAL,
    defaultFocused,
    onButtonAction,
    onRightSideAction,
    packetImage,
    purchaseDescription,
    packetBgImage,
    checkHigherPricesExist,
}: PurchaseButtonProps) => {
    const [activeContent, setActiveContent] = useState(false);
    const [activeRightSideContent, setActiveRightSideContent] = useState(false);

    const ContentBorder = () => {
        return (
            <Border
                active={activeContent}
                isRightSideLabel={Boolean(rightSideLabel)}
                isRightSideLabelActive={activeRightSideContent}
            />
        );
    };
    return (
        <PurchaseButtonContainer func={navHorizontal} $gutter={gutter}>
            <ButtonContent
                img={packetBgImage}
                active={activeContent}
                onNav={setActiveContent}
                onEnterDown={onButtonAction}
                onMouseDown={onButtonAction}
                defaultFocused={defaultFocused}
                autofocusOn={['mouseenter', 'focus']}
            >
                <ContentBorder />
                <LabelContainer>
                    <Header>Pakiet</Header>
                    <Label>{label}</Label>
                    <LineSeparator />
                    {purchaseDescription && (
                        <Description>
                            {purchaseDescription
                                .split('<br>\n')
                                .map((desc: string, index: number) => {
                                    return (
                                        <li key={index}>
                                            <span>{desc}</span>
                                        </li>
                                    );
                                })}
                        </Description>
                    )}
                </LabelContainer>

                <PacketImage>
                    <img src={packetImage} alt={`${packetImage}`}></img>
                </PacketImage>
                <PriceContainer>
                    {isPromotion && (
                        <BorderWithTextLabel>{t('payment-steps.promotion')}</BorderWithTextLabel>
                    )}

                    {oldPriceLabel && (
                        <div>
                            <OldPrice>{oldPriceLabel}</OldPrice>
                        </div>
                    )}
                    <Price checkHigherPricesExist={checkHigherPricesExist}>{priceLabel}</Price>
                    {durationLabel && <Duration>{durationLabel}</Duration>}
                </PriceContainer>
            </ButtonContent>

            {rightSideLabel && (
                <ButtonRightSideContent
                    $active={activeRightSideContent}
                    onNav={setActiveRightSideContent}
                    onEnterDown={onRightSideAction}
                    onMouseDown={onRightSideAction}
                    autofocusOn={['mouseenter', 'focus']}
                >
                    <RightSideContentBorder active={activeRightSideContent} />
                    {rightSideLabel}
                </ButtonRightSideContent>
            )}
        </PurchaseButtonContainer>
    );
};
