import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';

export const useGetIpData = () => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.SYSTEM, GET_MEDIA_METHODS.GET_IP_DATA, {});
    const { data, isFetching, error } = useGetMediaApi(keyHook);

    return { data, isFetching, error };
};
