import { Nav, navHorizontal, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { CollectionsList } from '@/components/shared/CollectionsList';
import { PacketCategoriesMenu } from '@/components/shared/Filter/SubcategoriesMenu';
import { BackgroundContext } from '@/contexts/background/background.context';
import { PacketContext } from '@/contexts/packet/packet.context';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { usePacketCategories } from '@/hooks/packets/use-packet-categories.hook';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { LIST_TYPE } from '@/types/recommendation-list.type';

import { PageContent } from '../common/PageContent';
import { PacketPageContent } from './PacketPageContent';
import { CollectionListWrapper, PacketDetailsContainer, StyledCollectionList } from './styles';
import { PacketDetailsProps } from './types';

export const PacketDetails = ({ packet }: PacketDetailsProps) => {
    const focusRef = useRef<Nav>(null);
    const [activeItem, setActiveItem] = useState<ListElementInterface>();
    const { activeRow } = useContext(PacketContext);
    const { setShadeMode, setBcgSrc } = useContext(BackgroundContext);
    const { data, rightPanelOpen, setRightPanelOpen } = usePacketCategories(packet, focusRef);

    const params = useMemo(() => ({ place: { type: 'packet', value: packet.id } }), [packet]);
    const defaultFocused = useMemo(() => activeRow !== null && activeRow > -1, [activeRow]);
    const config = useMemo(() => ({ defaultFocused, withPackets: true }), [defaultFocused]);

    useEffect(() => {
        if (!defaultFocused) {
            batchedUpdates(() => {
                setShadeMode(SHADE_MODE.BIG);
                setBcgSrc(packet.getBackgroundSrc());
            });
        }
    }, [defaultFocused]);

    const func = useCallback((navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
        if (
            navKeys === NAVIGATION_KEYS.UP ||
            (navKeys === NAVIGATION_KEYS.DOWN && navTree.parent)
        ) {
            return navVertical(navKeys, navTree);
        }

        return navHorizontal(navKeys, navTree);
    }, []);

    return (
        <>
            <PacketDetailsContainer func={func}>
                <BackButton />

                <Nav restoreLastFocus>
                    <CollectionListWrapper showActiveItem={defaultFocused}>
                        <PacketPageContent
                            packet={packet}
                            focusRef={focusRef}
                            hide={defaultFocused}
                        />

                        {defaultFocused && activeItem && (
                            <PageContent actualVod={activeItem} view={LIST_TYPE.SIMPLE} />
                        )}

                        <StyledCollectionList func={navVertical}>
                            <CollectionsList
                                params={params}
                                config={config}
                                onActiveItem={setActiveItem}
                            />
                        </StyledCollectionList>
                    </CollectionListWrapper>
                </Nav>
            </PacketDetailsContainer>

            {data && (
                <PacketCategoriesMenu
                    categories={data}
                    isVisible={rightPanelOpen}
                    onAction={() => setRightPanelOpen(false)}
                    onShowMenu={setRightPanelOpen}
                    selectedCategoryId={packet.id}
                />
            )}
        </>
    );
};
