import { AsyncEvent } from 'ts-events';
import HandlersManager from '../../modules/handlers-manager';
import ActivityEventsWrapper from './activity-events-wrapper';
import { ActivityEventsConfig, ActivityEventsUpdateHandlerParams } from '../../types';

export function initActivityEvents(
    handlersManager: HandlersManager,
    config: ActivityEventsConfig,
    onParamsUpdate: AsyncEvent<ActivityEventsUpdateHandlerParams>,
): ActivityEventsWrapper {
    return new ActivityEventsWrapper(
        handlersManager,
        {
            service: config.activityevents.service,
            jwt: config.activityevents.jwt,
            originator: config.activityevents.originator,
            clientId: config.activityevents.clientId,
            profileId: config.activityevents.profileId,
            appVersion: config.activityevents.appVersion,
            portalId: config.activityevents.portalId,
            userAgentData: config.activityevents.userAgentData,
            deviceId: config.activityevents.deviceId,
            ipData: config.activityevents.ipData,
            deviceExtraData: config.activityevents.deviceExtraData,
        },
        onParamsUpdate,
    );
}
