import { createContext, useState } from 'react';
import { PlayerLoaderContextInterface, PlayerLoaderContextProviderProps } from './types';

export const PlayerLoaderContext = createContext<PlayerLoaderContextInterface>({
    playerScriptLoading: false,
    setPlayerScriptLoading: () => null,
    playerScriptLoaded: false,
    setPlayerScriptLoaded: () => null,
});

export const PlayerLoaderContextProvider = ({ children }: PlayerLoaderContextProviderProps) => {
    const [playerScriptLoading, setPlayerScriptLoading] = useState(false);
    const [playerScriptLoaded, setPlayerScriptLoaded] = useState(false);

    return (
        <PlayerLoaderContext.Provider
            value={{
                playerScriptLoading,
                setPlayerScriptLoading,
                playerScriptLoaded,
                setPlayerScriptLoaded,
            }}
        >
            {children}
        </PlayerLoaderContext.Provider>
    );
};
