import BaseLayout from '@/components/shared/layouts/BaseLayout';
import SubcategoryGridView from '@/components/views/vod/subcategory';
import React from 'react';
import { useSubcategoryInitialProps } from '@/helpers/router/use-subcategory-initial-props';

const SubcategoryIndex = () => {
    const props = useSubcategoryInitialProps();
    return (
        <BaseLayout fullscreen>
            <SubcategoryGridView {...props} />
        </BaseLayout>
    );
};

export default SubcategoryIndex;
