import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { UserAuthData } from '@/types/user-auth-data.type';
import { useCallback } from 'react';
import { loginUser } from '@/helpers/auth/login-user.helper';
import { IGetMediaMutateFn } from '../use-get-media-api.type';
import useLogin from '@/api/auth/use-login';
import useRegister from '@/api/auth/register';
import { LoginOut } from '@/interfaces/from-schemas/auth/loginOut';
import { RegisterOut } from '@/interfaces/from-schemas/auth/registerOut';
import { RegisterIn } from '@/interfaces/from-schemas/auth/registerIn';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { IParams } from '@/routing/types';

interface IUseLoginUser {
    login?: IGetMediaMutateFn<UserAuthData, LoginOut>;
    register?: IGetMediaMutateFn<RegisterIn, RegisterOut>;
    authProvider?: AUTH_PROVIDERS;
    query?: IParams;
}

export const useLoginUser = ({ login, register, authProvider, query }: IUseLoginUser) => {
    const [_loginFn] = useLogin();
    const [_registerFn] = useRegister();

    const userModule = useUserModule();

    const loginFn = login || _loginFn;
    const registerFn = register || _registerFn;

    const handleLoginUser = useCallback(
        (params: UserAuthData) =>
            loginUser(params, userModule, loginFn, registerFn, authProvider, query),
        [userModule, loginFn, registerFn, authProvider, query],
    );

    return handleLoginUser;
};
