import { UserAccountsInterface } from '@/interfaces/user-accounts-interface';

export class UserModel {
    private readonly accounts?: UserAccountsInterface;
    private readonly birthYear: number;
    private readonly email?: string;
    private readonly firstName: string;
    private readonly hasPin: boolean;
    private readonly id: string;
    private readonly lastName: string;
    private readonly location: string;
    private readonly nick: string;
    private readonly pin: string;
    private readonly sex: string;
    private readonly verifiedMsisdn: string;

    constructor(definition: any) {
        this.accounts = definition['accounts'];
        this.birthYear = definition['birthYear'];
        this.email = definition['email'];
        this.firstName = definition['firstName'];
        this.hasPin = definition['hasPin'];
        this.id = definition['id'];
        this.lastName = definition['lastName'];
        this.location = definition['location'];
        this.nick = definition['nick'];
        this.pin = definition['pin'];
        this.sex = definition['sex'];
        this.verifiedMsisdn = definition['verifiedMsisdn'];
    }

    public hasEmail(): boolean {
        return !!this.email;
    }

    public getId(): string {
        return this.id;
    }

    public getLogin(): string {
        const accounts = Object.values(this.accounts || {});
        if (accounts[0]) {
            return accounts[0].login;
        }
        return '';
    }

    public getDisplayName(): string {
        if (this.nick) {
            return this.nick;
        }

        if (this.email) {
            return this.email;
        }

        const login = this.getLogin();

        if (login) {
            return login;
        }

        return 'Zalogowany';
    }

    public getEmail(): string {
        return this.email || '';
    }

    public hasVerifiedMsisdn(): boolean {
        return !!this.verifiedMsisdn;
    }

    public hasAccountTypes(): boolean {
        return this.getAccountTypes().length > 0;
    }

    public getAccountTypes(): Array<string> {
        return Object.keys(this.accounts || {});
    }

    public getPlusZoneMsisdn(): string | undefined {
        return this.accounts?.plus?.msisdn;
    }

    public hasNativePassword(): boolean | undefined {
        const accountNative = this.accounts?.native;
        return accountNative && !accountNative.passwordGenerated;
    }

    public getSSOId(): string | undefined {
        const ssoAccount = this.accounts?.['sso'];

        if (ssoAccount) {
            return ssoAccount.externalAccountId;
        }
    }

    public getIPolsatBoxId(): string | undefined {
        // iPolsatBox widnieje pod starym kluczem ICOK
        const icokAccount = this.accounts?.['icok'];

        if (icokAccount) {
            return icokAccount.userIdCP;
        }
    }

    public getFbId(): number | undefined {
        const fbAccount = this.accounts?.['facebook'];

        if (fbAccount) {
            return +fbAccount.fbId;
        }
    }
}
