import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { PlayableButton } from '@/components/shared/Buttons';
import { withProviders } from '@/helpers/auth/auth-providers.helper';
import React, { useContext, useMemo } from 'react';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import {
    WelcomeActionsSection,
    WelcomeBottomSection,
    WelcomeFullscreenContainer,
    WelcomeHeaderText,
    WelcomeInfoSection,
    WelcomeNoAccountText,
    WelcomeTextSection,
} from './styles';
import FullscreenNavContainer, { FormContainer } from '../FullscreenNavContainer';
import { ProcessingLoader } from '@/components/shared/Loader';
import { GET_MEDIA_PLACE_TYPE } from '@/types/get-media-params.type';
import useGetStaffRecommendationLists from '@/api/navigation/recommendations/get-staff-recommendation-lists';
import { useGetRecommendationListElements } from '@/api/navigation/recommendations/use-get-recommendation-list-elements';
import { useDimensions } from '@/hooks/use-dimensions';
import { BackgroundContext } from '@/contexts/background/background.context';
import { WelcomeLogo } from '@/components/shared/WelcomeLogo';

export const WelcomeBody = () => {
    const { width } = useDimensions(true);
    const { setBcgSrc } = useContext(BackgroundContext);

    const params = {
        place: { type: GET_MEDIA_PLACE_TYPE.WELCOME_SCREEN, value: 'promobox' },
    };
    const { data: list, isFetching: isFetchingLists } = useGetStaffRecommendationLists(params);
    const firstRecommendationList = list.getResults?.()[0];

    const { collection, isFetching: isFetchingElements } = useGetRecommendationListElements(
        firstRecommendationList,
        {
            ...params,
            limit: 1,
        },
    );

    const body = useMemo(() => {
        setBcgSrc('');
        const redirectToLogin = () => {
            Router.pushRoute(ROUTES.LOGIN_BY_CODE);
        };
        const promobox = collection.length > 0 ? collection[0] : null;
        const backgroundSrc =
            promobox?.getRecommendationItem?.().getBackgroundSrcBrowser(width) || '';

        if (!backgroundSrc) {
            const body = (
                <>
                    <WelcomeLogo />
                    <WelcomeHeaderText
                        text={t('pages.welcome.header')}
                        mode={MAINTEXT_KIND.HEADER}
                        gutter={GUTTER.BIG}
                    />
                    <FormContainer>
                        <PlayableButton
                            defaultFocused
                            text={t('pages.welcome.sign-in')}
                            onButtonAction={redirectToLogin}
                            gutter={withProviders ? GUTTER.NORMAL : GUTTER.BIG}
                            data-testing="login-standard"
                        />
                    </FormContainer>
                    <WelcomeNoAccountText
                        text={t('pages.welcome.no-account-instruction')}
                        mode={MAINTEXT_KIND.DESCRIPTION}
                    />
                </>
            );
            return <FullscreenNavContainer body={body} bodyWidth={100} />;
        }

        return (
            <WelcomeFullscreenContainer backgroundSrc={backgroundSrc}>
                <WelcomeInfoSection>
                    <WelcomeBottomSection>
                        <WelcomeTextSection>
                            <MainText
                                text={t('pages.welcome.header')}
                                mode={MAINTEXT_KIND.PAGE_TITLE}
                                gutter={GUTTER.SMALL}
                            />
                            <MainText
                                text={t('pages.welcome.no-account-instruction')}
                                mode={MAINTEXT_KIND.DESCRIPTION}
                            />
                        </WelcomeTextSection>
                        <WelcomeActionsSection>
                            <PlayableButton
                                defaultFocused
                                text={t('pages.welcome.sign-in')}
                                onButtonAction={redirectToLogin}
                                gutter={withProviders ? GUTTER.NORMAL : GUTTER.BIG}
                                data-testing="login-standard"
                            />
                        </WelcomeActionsSection>
                    </WelcomeBottomSection>
                </WelcomeInfoSection>
            </WelcomeFullscreenContainer>
        );
    }, [collection]);

    if (isFetchingLists || isFetchingElements) {
        return <ProcessingLoader isFetching />;
    }

    return body;
};
