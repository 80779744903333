import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetUnacceptedRulesIn } from '@/interfaces/from-schemas/auth/getUnacceptedRulesIn';
import { RulesSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import { RulesModel } from '@/models/products/rules.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { UserAuthData } from '@/types/user-auth-data.type';
import { useMemo } from 'react';

export type IUseGetUnacceptedRules =
    | {
          rulesType?: GetUnacceptedRulesIn['rulesType'];
          context?: string;
      }
    | { rulesType?: GetUnacceptedRulesIn['rulesType']; authData: UserAuthData };

export const useGetUnacceptedRules = (
    params?: IUseGetUnacceptedRules,
): IApiResponse<RulesModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.FETCH_UNACCEPTED_RULES,
        params,
    );

    const { data, error, isFetching, refetch } = useGetMediaApi(keyHook);

    const rules = useMemo(() => {
        const rules = data ? data.map((rules: RulesSchema) => new RulesModel(rules)) : [];

        return rules
            ? [
                  ...rules.filter((r: RulesModel) => r.isRequired() && r.isRequiredAnswer()),
                  ...rules.filter((r: RulesModel) => !r.isRequired() && r.isRequiredAnswer()),
                  ...rules.filter((r: RulesModel) => !r.isRequired() && !r.isRequiredAnswer()),
              ]
            : [];
    }, [data]);

    return {
        data: rules,
        error,
        isFetching,
        refetch,
    };
};

export default useGetUnacceptedRules;
