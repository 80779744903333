import isEqual from 'lodash/isEqual';
import { IRouter, RouteInterface } from '@/routing/types';
import { getLastStringFromUrl } from './get-last-string-from-url.helper';

export const checkItemActive = (router: IRouter, label: string, routeData?: RouteInterface) => {
    const menuItem = getLastStringFromUrl(router);
    const focusLabel = menuItem === label;
    const rightParam = isEqual(router?.query, routeData?.params);
    return routeData?.params ? rightParam : focusLabel;
};
