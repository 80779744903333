import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

export const userSpecificQueries: Array<[GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS]> = [
    [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS],
    [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS],
    [
        GET_MEDIA_NAMESPACES.USER_CONTENT,
        GET_MEDIA_METHODS.FETCH_USER_LATELY_WATCHED_CONTENT_DATA_LIST,
    ],
    [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.GET_FAVORITES_WITH_FLAT_NAVIGATION],
    [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST],
    [GET_MEDIA_NAMESPACES.DRM, GET_MEDIA_METHODS.CHECK_PRODUCT_ACCESS],
];
