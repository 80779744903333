import { HmacSHA256 } from 'jscrypto/HmacSHA256';
import { Base64 } from 'jscrypto/Base64';
import { MD5 } from 'jscrypto/MD5';
import { Word32Array } from 'jscrypto/lib/Word32Array';

class CryptoUtil {
    public base64 = Base64;

    public hmacSHA256(message: any, key: any) {
        return HmacSHA256(message, key);
    }

    public md5(message: string | Word32Array): string {
        return MD5.hash(message).toString();
    }

    public universalToBase64(value: string): string {
        try {
            return btoa(value);
        } catch (err) {
            return Buffer.from(value).toString('base64');
        }
    }
    // INFO: skad takie zmiany: https://jira.polsatc/browse/GM-7537?focusedCommentId=5308995&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-5308995
    public replacePolishCharsWithUnicode(input: string): string {
        const polishCharMap: { [char: string]: string } = {
            ą: '\\u0105',
            ć: '\\u0107',
            ę: '\\u0119',
            ł: '\\u0142',
            ń: '\\u0144',
            ó: '\\u00f3',
            ś: '\\u015b',
            ż: '\\u017c',
            ź: '\\u017a',
            Ą: '\\u0104',
            Ć: '\\u0106',
            Ę: '\\u0118',
            Ł: '\\u0141',
            Ń: '\\u0143',
            Ó: '\\u00d3',
            Ś: '\\u015a',
            Ż: '\\u017b',
            Ź: '\\u0179',
        };

        return input.replace(/[ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]/g, (char) => polishCharMap[char]);
    }
}

export default CryptoUtil;
