import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { CatalogView } from '@/components/views/common';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { EmptyFavoriteListMessage } from '@/components/views/Favorites';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';

const MenuMyList = () => {
    const { placeType } = process.env.pages.favorites;
    const catalogConfig = useMemo(() => buildCatalogConfig(placeType, {}), []);

    const placeholder = <EmptyFavoriteListMessage />;

    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <CatalogView config={catalogConfig} placeholder={placeholder} />
        </BaseLayout>
    );
};

export default MenuMyList;
