import { StaffRecommendationListsItemModel } from './staff-recommendation-lists-item.model';
import { GetStaffRecommendationsListItemsOut } from '../../interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';

export class StaffRecommendationListsItemsModel {
    private readonly count: number = 0;
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private readonly results: StaffRecommendationListsItemModel[] = [];
    private readonly total: number = 0;

    constructor(definition: GetStaffRecommendationsListItemsOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.results = [];
            definition.results.forEach((value, index) => {
                this.results.push(new StaffRecommendationListsItemModel(value, index));
            });
        }
    }

    public getCount(): number {
        return this.count;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getResults(): StaffRecommendationListsItemModel[] {
        return this.results;
    }

    public getTotal(): number {
        return this.total;
    }
}
