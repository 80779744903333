import React, { FC, SVGProps, useCallback, useEffect, useRef, useState } from 'react';
import { IconCast } from './IconCast';
import { IconProps, ICONS } from './types';

const IconRaw = ({ name, mode }: IconProps) => {
    const [loading, setLoading] = useState(false);
    const iconRef = useRef<FC<SVGProps<SVGSVGElement>>>();
    const SvgIcon = iconRef.current;

    const iconProps = {
        height: '100%',
        width: '100%',
    };

    const importSvg = useCallback(async (iconName: ICONS): Promise<void> => {
        return import(`../../../public/assets/shared/icons/${iconName}.svg`)
            .then((res) => {
                iconRef.current = res.default;
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        let isCancelled = false;

        setLoading(true);
        importSvg(name).finally(() => {
            if (!isCancelled) {
                setLoading(false);
            }
        });

        return () => {
            isCancelled = true;
        };
    }, [importSvg, name]);

    if (loading || !SvgIcon) {
        return null;
    }

    switch (name) {
        case ICONS.CAST_BTN:
            return (
                <IconCast iconMode={mode}>
                    <SvgIcon {...iconProps} />
                </IconCast>
            );
        default:
            return <SvgIcon {...iconProps} />;
    }
};

export const Icon = React.memo(IconRaw);
