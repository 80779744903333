import { Nav } from 'nav-tree';
import styled from 'styled-components';
import { LabelProps, SingleRuleContainerProps } from './types';

export const SingleRuleContainer = styled(Nav)<SingleRuleContainerProps>`
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
    width: 100%;

    &:hover {
        cursor: pointer;
    }

    &:before {
        content: '';
        position: absolute;
        top: -1rem;
        bottom: -1rem;
        left: -2.5rem;
        right: -1rem;
        border: 0.3rem solid transparent;
        border-radius: 3.2rem;

        ${({ $error, theme }) =>
            $error &&
            `
                border-color: ${theme.paymentCheckoutStep.errorRuleColor};
            `}

        ${({ $active, theme }) =>
            $active &&
            `
                border-color: ${theme.paymentCheckoutStep.activeRuleColor};
            `}
    }
`;

export const Label = styled.div<LabelProps>`
    font-size: 2rem;
    color: ${({ highlight, theme }) =>
        highlight
            ? theme.paymentCheckoutStep.labelColor
            : theme.paymentCheckoutStep.defaultTextColor};
    padding-right: 10rem;
    flex: 1;
    align-self: center;
`;
