import styled from 'styled-components';

export const InstructionStepWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
`;
export const InstructionStepLabel = styled.div`
    font-size: ${({ theme }) => theme.tokenInfo.instructionLabelSize};
    color: ${({ theme }) => theme.tokenInfo.instructionLabelColor};
    text-align: left;
    width: 45rem;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    flex: 1;
    p {
        margin: 0 0 1rem 0;
    }
    b,
    em,
    strong {
        font-weight: bold;
        color: ${({ theme }) => theme.text.color.highlight};
    }
`;
export const InstructionStepValue = styled.span`
    border-radius: 50%;
    text-align: center;
    background-color: ${({ theme }) => theme.tokenInfo.instructionStepBackground};
    color: ${({ theme }) => theme.tokenInfo.instructionStepColor};
    font-weight: bold;
    font-size: 2rem;
    margin-top: 1rem;
    margin-right: 3rem;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
`;
