import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

export const shouldIncreaseLimit = (
    activeItem: number,
    visibleElements: number,
    collectionLength: number,
) => {
    if (
        visibleElements &&
        collectionLength > 0 &&
        activeItem > collectionLength - visibleElements
    ) {
        return true;
    }
    return false;
};

export const getVisibleElements = (list?: StaffRecommendationListModel) => {
    const { posters, thumbnails } = process.env.lazyLoadingImages.initialLoaded;

    if (list) {
        const displayMode = list.getImageDisplayMode();
        if (displayMode === IMAGE_DISPLAY_MODE.POSTERS) {
            return posters;
        }
    }

    return thumbnails;
};
