import { fillIcon } from '@/helpers/fill-icon.helper';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 4.4rem;
    align-items: center;
`;
export const Title = styled.div`
    font-size: 3.2rem;
`;
export const IconWrapper = styled.div`
    width: 18px;
    height: 12px;
    ${({ theme }) => fillIcon(theme.customerCare.iconColor)};
    margin-right: 1.6rem;
`;
