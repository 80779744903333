import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import ProgressBarBase from '@/components/shared/ProgressBar';
import { CELL_PADDING, COL_WIDTH } from '@/constants/epg-table';

export const Cell = styled(Nav)<{
    $isActive: boolean;
    height: string;
    $isOneOfManyCurrentlyPlaying: boolean;
    $hasPassed: boolean;
    $isPlayable: boolean;
}>(({ height, $isActive, theme, $isOneOfManyCurrentlyPlaying, $hasPassed, $isPlayable }) => {
    const { mainBorderColor, activeCellColor } = theme.epgTable;

    const activeStyles = () => {
        const verticalPadding = $isOneOfManyCurrentlyPlaying ? 0.4 : CELL_PADDING.activeVertical;

        if ($isActive) {
            return css`
                border: 0.4rem solid ${theme.mainColor};
                border-radius: 0.5rem;
                background-color: ${activeCellColor};
                padding: ${verticalPadding}rem ${CELL_PADDING.activeHorizontal}rem;
            `;
        }
    };

    const verticalPadding = $isOneOfManyCurrentlyPlaying ? 0.7 : CELL_PADDING.vertical;

    const hasPassedStyles =
        $hasPassed &&
        !$isPlayable &&
        css`
            opacity: 0.25 !important;
        `;

    return css`
        height: ${height};
        width: ${COL_WIDTH}rem;
        font-size: 2rem;
        line-height: 2.7rem;
        border-width: 0.1rem;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: ${mainBorderColor};
        padding: ${verticalPadding}rem ${CELL_PADDING.horizontal}rem;
        ${activeStyles()};
        ${hasPassedStyles};
    `;
});

export const CellMetadata = styled.div`
    color: ${({ theme }) => theme.text.color.metadata};
    margin-bottom: 0.1rem;
    font-weight: 500;
`;

export const LiveText = styled.span`
    margin-left: 0.5rem;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    line-height: initial;
    margin-bottom: 1.1rem;

    svg {
        margin-top: 0.3rem;
    }

    & > span:not(:last-child) {
        margin-right: 1rem;
    }
`;

export const ProgressBar = styled(ProgressBarBase)`
    width: 9.5rem;
`;
