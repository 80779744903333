import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ROUTES } from '@/routing/types';
import { navVertical } from 'nav-tree';
import React from 'react';
import { PageContainer } from '@/components/views/Account/styles';
import { useGetCurrentAuthSession } from '@/hooks/use-get-current-auth-session';
import OptionsPage from '@/components/views/Account/PlusZone/options-page';
import TitlePage from '@/components/views/Account/PlusZone/title-page';

const PlusZone = () => {
    const { data } = useGetCurrentAuthSession();
    const plusZoneMsisdn = data?.getUser().getPlusZoneMsisdn();

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.PLUS_ZONE} />
            <PageContainer func={navVertical}>
                {plusZoneMsisdn ? <OptionsPage phoneNo={plusZoneMsisdn} /> : <TitlePage />}
            </PageContainer>
        </BaseLayout>
    );
};
export default PlusZone;
