import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

import { Message } from '@/components/shared/Message';
import { GUTTER } from '@/types/gutter.type';
import { buildHighlightSpan } from '@/helpers/highlight-translation.helper';

const ResetPasswordRequestForm = () => {
    const body = (
        <>
            <Message
                innerHtmlDescription={t('pages.reset-password.request.instruction', {
                    domainUrl: t('domain-url'),
                    ...buildHighlightSpan('highlight -target-highlight'),
                })}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={() => Router.pushRoute(ROUTES.WELCOME)}
                />
            </FormContainer>
        </>
    );
    return <FullscreenNavContainer header={t('pages.reset-password.request.header')} body={body} />;
};

export default ResetPasswordRequestForm;
