import { RulesSourceSchema } from '@/interfaces/from-schemas/auth/getRulesOut';
import { protectURL } from '@/helpers/rewrite-protocol.helper';

export const getRuleSourceUrl = (sources?: RulesSourceSchema[]): string | undefined => {
    const source = sources?.find(({ type }) => type === 'html' || type === 'txt');
    const url = source?.url;

    if (!url) {
        return;
    }

    return protectURL(url);
};
