import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { LoginRules } from '@/components/views/Rules/LoginRules';
import React from 'react';

const RulesPage = () => {
    return (
        <BaseLayout fullscreen bottomShade>
            <LoginRules />
        </BaseLayout>
    );
};

export default RulesPage;
