import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetOrderStatusIn } from '@/interfaces/from-schemas/payments/getOrderStatusIn';
import { GetOrderStatusOut } from '@/interfaces/from-schemas/payments/getOrderStatusOut';

export const useGetOrderStatus = (
    params?: GetOrderStatusIn,
): IApiResponse<GetOrderStatusOut | undefined> => {
    const keyhook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_ORDER_STATUS,
        params,
    );

    return useGetMediaApi(keyhook, { appendAuthData: true });
};
