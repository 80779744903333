import { ReactNode } from 'react';

export interface HelpConfigurationContextProps {
    helpConfiguration?: {
        availableArticleHelpContexts: [{ type: string; value: string }];
        availableArticleErrorCodes: string[];
    }; //TODO:schema
    isFetching?: boolean;
    paymentType?: string;
    setPaymentType: (type: string) => void;
}

export interface BackgroundContextProviderProps {
    children: ReactNode;
}

export enum CUSTOMER_CARE_NAVIGATION {
    BACK_REDIRECT_PLACE = 'back_redirect_place',
    BACK_REDIRECT_PARAMS = 'back_redirect_params',
    REDIRECT_TO_ERROR_PAGE = 'redirect_to_error_page',
    ERROR_SHOW_OVERLAY = 'error_show_overlay',
}

export enum BACK_REDIRECT_PLACE {
    HELP = 'help',
    ERROR = 'error',
}
