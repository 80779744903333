import styled, { css } from 'styled-components';
import { ActiveFilterStyledProps } from './FilterListItem/types';

export const Circle = styled.div<{ selected: boolean }>(({ selected, theme }) => {
    const selectedFilterCircleStyles =
        selected &&
        css`
            border-width: 0;
            svg {
                fill: ${theme.text.color.highlight};
            }
        `;

    return css`
        border-radius: 50%;
        border: 2px solid ${theme.button.primaryBorderColor};
        height: 4.2rem;
        width: 4.2rem;
        position: relative;
        ${selectedFilterCircleStyles};

        & > svg {
            position: absolute;
            left: 0;
            top: 0;
        }
    `;
});

export const FilterMenuItem = styled.div<ActiveFilterStyledProps>(({ active, selected, theme }) => {
    let backgroundColor;

    if (selected) {
        backgroundColor = theme.filerListItem.selectedItem;
    } else if (active) {
        backgroundColor = theme.filerListItem.activeItem;
    }

    return css`
        ${backgroundColor ? `background-color: ${backgroundColor}` : ''};
        border-radius: 3.9rem;
        color: ${theme.filerListItem.color};
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: ${theme.filerListItem.fontSize};
        height: 7.8rem;
        justify-content: space-between;
        line-height: 7.8rem;
        padding-left: 2.4rem;
        padding-right: 3.7rem;
        white-space: nowrap;
        width: 100%;
    `;
});
