import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import { RecommendationListElementModel } from '@/models/list-element/recommendation-list-element.model';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { StaffRecommendationListsItemModel } from '@/models/recommendations/staff-recommendation-lists-item.model';
import { StaffRecommendationListsItemsModel } from '@/models/recommendations/staff-recommendation-lists-items.model';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { DISPLAY_DATA, TITLE_TYPE } from '@/types/list-element-metadata.type';
import { GM_DEVICE_TYPE } from '@/types/platform.type';
import { MEDIA_TYPES } from '@/types/media.type';

const { categoryCatalog: dataType } = process.env.metadataSettings.display;

export const deviceGuard = (collection: StaffRecommendationListsItemModel) => {
    const { deviceType } = process.env.defaultUserData.userAgentData;

    return (
        deviceType === GM_DEVICE_TYPE.PC ||
        deviceType === GM_DEVICE_TYPE.Mobile ||
        !collection.isTypeUrl()
    );
};

export const buildRecommendationListCollection = (
    list: StaffRecommendationListModel | null,
    collection: StaffRecommendationListsItemsModel,
    followMeContent: WatchedContentDataSchema[],
) => {
    const dataPosition = list?.getDataPosition();
    const displayMode = list?.getImageDisplayMode() || IMAGE_DISPLAY_MODE.THUMBNAILS;
    const titleType = list?.getTitleType() || TITLE_TYPE.FULL;
    const titleMaxLines = list?.getTitleMaxLines();
    const hasScroll = list?.hasScroll();

    return (
        collection
            ?.getResults()
            .filter(deviceGuard)
            .map((result) => {
                result.setImageDisplayMode(displayMode);
                const recoElement = new RecommendationListElementModel(result);
                const isTvProgram = result.getType() === MEDIA_TYPES.TV_PROGRAM;
                const isChannelMediaType = result.getType() === MEDIA_TYPES.CHANNEL;

                if (isTvProgram || isChannelMediaType) {
                    recoElement.setDisplayDataType(DISPLAY_DATA.BOTH);
                    recoElement.setShowMetadata(true);
                } else {
                    recoElement.setDisplayDataType(hasScroll ? dataType : DISPLAY_DATA.BOTH);
                }

                dataPosition && recoElement.setDataPosition(dataPosition);
                recoElement.setTitleType(titleType);
                titleMaxLines && recoElement.setTitleMaxLines(titleMaxLines);

                if (hasScroll) {
                    recoElement.updateFromFollowMeContent(result.getValue(), followMeContent);
                }

                return recoElement;
            }) || []
    );
};
