export enum SHADE_MODE {
    BIG = 'big',
    CUSTOM = 'custom',
    FULL = 'full',
    MEDIA = 'media',
    NONE = 'none',
    OVERLAY = 'overlay',
    SMALL = 'small',
}

export enum BACKGROUND_MARGIN {
    NONE = 'none',
    PROMOBOX = 'promobox',
}

export enum IMAGE_MODE {
    PREV = 'prev',
    NEW = 'new',
}

export interface BackgroundImageProps {
    defaultBg?: boolean;
    bgShade?: SHADE_MODE;
}

export interface ShadeProps {
    mode?: SHADE_MODE;
}

export interface ImgElementProps {
    showImg?: boolean;
    mode?: IMAGE_MODE;
    margin?: BACKGROUND_MARGIN;
}
