import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { yupResolver } from '@/lib/yupResolver';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { ParentControlPasswordFormData, ParentControlPasswordFormProps } from './types';

const schema = Yup.object().shape({
    password: Yup.string().required(t('form.validate.required')),
});

export const ParentControlPasswordForm = ({ profileId }: ParentControlPasswordFormProps) => {
    const { register, handleSubmit, errors } = useForm<ParentControlPasswordFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit(async (formData) => {
        // TODO: usługi get-media nie są jeszcze gotowe wiec obecnie jest passthrough
        console.log('Submitted password:', formData.password);

        return Router.pushRoute(ROUTES.PROFILE_PARENT_CONTROL_AGE, {
            profileId,
        });
    });

    const errorToDisplay = Object.values(errors).shift()?.message;

    return (
        <>
            <FormContainer onSubmit={onSubmit}>
                <TextInput
                    icon={ICONS.PASSWORD}
                    placeholder={t('form.placeholder.password')}
                    defaultFocused
                    error={!!errors.password}
                    name="password"
                    ref={register()}
                />
                <PlayableButton text={t('ok-button-label')} onButtonAction={onSubmit} />
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={() => Router.pushRoute(ROUTES.PROFILE, { profileId })}
                />
            </FormContainer>
            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );
};
