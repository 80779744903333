import { KEYS } from './keys';

let tizen: any;

if (process.browser) {
    tizen = (window as any)['tizen'];
}

const TizenKeysAndAppKeys: [string, KEYS][] = [
    ['0', KEYS.KEY_0],
    ['1', KEYS.KEY_1],
    ['2', KEYS.KEY_2],
    ['3', KEYS.KEY_3],
    ['4', KEYS.KEY_4],
    ['5', KEYS.KEY_5],
    ['6', KEYS.KEY_6],
    ['7', KEYS.KEY_7],
    ['8', KEYS.KEY_8],
    ['9', KEYS.KEY_9],
    // ['VolumeUp', KEYS.VOLUME_UP], // jeżeli zaczniemy nasłuchiwać tych eventów to przestanie działać kontrola dźwięku
    // ['VolumeDown', KEYS.VOLUME_DOWN], // jeżeli zaczniemy nasłuchiwać tych eventów to przestanie działać kontrola dźwięku
    // ['VolumeMute', KEYS.VOLUME_MUTE], // jeżeli zaczniemy nasłuchiwać tych eventów to przestanie działać kontrola dźwięku
    // ['ChannelUp', KEYS.UNHANDLED],
    // ['ChannelDown', KEYS.UNHANDLED],
    ['ColorF0Red', KEYS.RED],
    ['ColorF1Green', KEYS.GREEN],
    ['ColorF2Yellow', KEYS.YELLOW],
    ['ColorF3Blue', KEYS.BLUE],
    // ['Menu', KEYS.UNHANDLED],
    // ['Tools', KEYS.UNHANDLED],
    // ['Info', KEYS.UNHANDLED],
    // ['Exit', KEYS.UNHANDLED],
    // ['Search', KEYS.UNHANDLED],
    // ['Guide', KEYS.UNHANDLED],
    ['MediaRewind', KEYS.REWIND],
    ['MediaPause', KEYS.PAUSE],
    ['MediaFastForward', KEYS.FORWARD],
    // ['MediaRecord', KEYS.UNHANDLED],
    ['MediaPlay', KEYS.PLAY],
    ['MediaStop', KEYS.STOP],
    // ['MediaPlayPause', KEYS.PLAY_PAUSE], // nie używamy tego przycisku żeby nie blokować menu mediaControll w nowych pilotach
    // ['MediaTrackPrevious', KEYS.UNHANDLED],
    // ['MediaTrackNext', KEYS.UNHANDLED],
    // ['Source', KEYS.UNHANDLED],
    // ['PictureSize', KEYS.UNHANDLED],
    // ['PreviousChannel', KEYS.UNHANDLED],
    // ['ChannelList', KEYS.UNHANDLED],
    // ['E-Manual', KEYS.UNHANDLED],
    // ['MTS', KEYS.UNHANDLED],
    // ['3D', KEYS.UNHANDLED],
    // ['Soccer', KEYS.UNHANDLED],
    // ['Caption', KEYS.UNHANDLED],
    // ['Teletext', KEYS.UNHANDLED],
    // ['Extra', KEYS.UNHANDLED],
    // ['Minus', KEYS.UNHANDLED],
];

// Lista przycisków których my używamy, a urzadzenie wspiera
const supportedKeys: TizenKey[] = !tizen
    ? []
    : (tizen.tvinputdevice.getSupportedKeys() as TizenKey[]).filter((key) =>
          TizenKeysAndAppKeys.find((k) => key.name === k[0] && k[1] !== KEYS.UNHANDLED),
      );

// Lista zafixowanych kodów klawiszy
const SPECIAL_KEY_CODES = {
    [KEYS.BACK]: 10009,
    [KEYS.DONE]: 65376,
    [KEYS.CANCEL]: 65385,
};

// Na podstawie supportedKeys i SPECIAL_KEY_CODES budujemy słownik z kodami przycisków
export function buildTizenKeysList(): { [key in KEYS]?: number } {
    const result: any = {};

    if (!tizen) return result;

    supportedKeys.forEach((key) => {
        const appKey = TizenKeysAndAppKeys.find((appk) => key.name === appk[0]);
        if (appKey) {
            result[appKey[1]] = key.code;
        }
    });

    return {
        ...result,
        ...SPECIAL_KEY_CODES,
    };
}

// Każdy przycisk tizena musi zostać zarejestrowany, aby można było go obsłużyć
export function initTizenKeys() {
    supportedKeys.forEach((key) => tizen.tvinputdevice.registerKey(key.name));
}

type TizenKey = {
    name: string;
    code: number;
};
