import { PlayableButton } from '@/components/shared//Buttons';
import { ICONS } from '@/components/shared//Icon';
import { Message } from '@/components/shared//Message';
import {
    ExitAppConfirmationContainer,
    ButtonsContainer,
} from '@/components/views/ExitAppConfirmation/styles';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { exitStbApp, exitTizenApp, exitWebOSApp, exitXboxApp } from '@/helpers/exit-app.helper';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import { navVertical } from 'nav-tree';
import { useCallback } from 'react';
import { isStbTargets } from '@/constants/portal-recognition';

export const ExitAppConfirmation = () => {
    const { platform } = usePlatform();
    const { os, deviceType } = platform;

    const exitAppAction = useCallback(() => {
        if (isStbTargets) {
            exitStbApp();
            return;
        }

        if (deviceType == GM_DEVICE_TYPE.XBOX) {
            exitXboxApp();
            return;
        }

        switch (os) {
            case GM_OS.Tizen:
                exitTizenApp();
                break;

            case GM_OS.WebOS:
                exitWebOSApp();
                break;

            default:
                window.close();
                break;
        }
    }, [os, deviceType]);

    return (
        <ExitAppConfirmationContainer func={navVertical}>
            <Message
                icon={ICONS.WARNING_CIRCLE}
                title={t('exit-app-confirmation.exit-message-title')}
            />

            <ButtonsContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={Router.back}
                    defaultFocused
                />

                <PlayableButton text={t('ok-button-label')} onButtonAction={exitAppAction} />
            </ButtonsContainer>
        </ExitAppConfirmationContainer>
    );
};
