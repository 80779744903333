import { useMutation } from 'react-query';
import { FACEBOOK_ENDPOINT } from '@/types/facebook-endpoint.type';
import { fbAxiosInstance } from './fb-axios-instance';
import { IFacebookDeviceLoginOut } from './types';

export const useFacebookGetDeviceCode = (params: {
    appId: string;
    clientToken: string;
    permissions: string[];
}) => {
    const access_token = `${params.appId}|${params.clientToken}`;

    const scope = params.permissions.join(',');

    return useMutation<IFacebookDeviceLoginOut>(() =>
        fbAxiosInstance
            .post<IFacebookDeviceLoginOut>(FACEBOOK_ENDPOINT.DEVICE_LOGIN, {
                access_token,
                scope,
            })
            .then(({ data }) => data),
    );
};
