import { useGetHelpConfiguration } from '@/api/help/use-get-help-configuration';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ConfigurationProviderProps } from '../configuration/types';
import { HelpConfigurationContextProps } from './types';

export const HelpConfigurationContext = createContext<HelpConfigurationContextProps>({
    helpConfiguration: undefined,
    isFetching: false,
    paymentType: undefined,
    setPaymentType: () => null,
});

export const HelpConfigurationContextProvider = ({ children }: ConfigurationProviderProps) => {
    const [paymentType, setPaymentType] = useState<string>();
    const { data: helpConfiguration, isFetching } = useGetHelpConfiguration();

    return useMemo(
        () => (
            <HelpConfigurationContext.Provider
                value={{ helpConfiguration, isFetching, paymentType, setPaymentType }}
            >
                {children}
            </HelpConfigurationContext.Provider>
        ),
        [children, helpConfiguration, isFetching, paymentType, setPaymentType],
    );
};

export const useHelpConfigurationContext = () => useContext(HelpConfigurationContext);
