import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@/lib/yupResolver';
import useDeleteUser from '@/api/auth/use-delete-user';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import FormRow from '../../../shared/form/commons/form-row/form-row.common';
import { TextInput } from '../../../shared/FormElement/BaseElements/TextInput';
import { INPUT_TYPE } from '../../../shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '../../../shared/Icon';
import { PlayableButton } from '../../../shared/Buttons';
import t from '../../../../lib/i18n';
import { AlertMessage, MESSAGE_TYPE } from '../../../shared/AlertMessage';
import React, { useEffect } from 'react';
import { NavWrapper } from '../styles';
import { useError } from '@/contexts/error/error.context';

interface FormData {
    password: string;
}

const schema = Yup.object().shape({
    password: Yup.string().required(t('form.validate.password-required')),
});

export const DeleteUserForm = () => {
    const { setError } = useError();
    const { register, handleSubmit, errors } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [mutate, { error }] = useDeleteUser();

    const onSubmit = handleSubmit(async (data) => {
        const result = await mutate({
            password: data.password,
        });
        if (result.ok) {
            Router.pushRoute(ROUTES.ACCOUNT_DELETE_SUCCESS);
        }
    });

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <NavWrapper>
            <form>
                <FormRow>
                    <TextInput
                        name="password"
                        placeholder={t('form.fields.password')}
                        type={INPUT_TYPE.PASSWORD}
                        icon={ICONS.PASSWORD}
                        error={!!errors.password}
                        ref={register()}
                        defaultFocused
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton onButtonAction={() => onSubmit()} text={t('ok-button-label')} />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT)}
                        text={t('cancel-button-label')}
                    />
                </FormRow>
            </form>
            {renderFormErrors()}
        </NavWrapper>
    );
};
