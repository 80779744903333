import { Icon } from '@/components/shared/Icon';
import { Nav } from 'nav-tree';
import React, { useState } from 'react';
import {
    AgeRestrictionGridElementContainer,
    AgeRestrictionGridElementWrapper,
    AgeRestrictionIconWrapper,
    AgeRestrictionName,
} from './styles';
import { AgeRestrictionGridElementProps } from './types';

export const AgeRestrictionGridElement = ({
    icon,
    text,
    size,
    func,
    onNav,
    onSelectAction,
    defaultFocused,
}: AgeRestrictionGridElementProps) => {
    const [active, setActive] = useState<boolean>(false);

    const onAvatarNav = (isActive: boolean) => {
        setActive(isActive);

        if (onNav) {
            onNav(isActive);
        }
    };

    const onEnter = () => {
        onSelectAction();
    };

    return (
        <AgeRestrictionGridElementContainer active={active}>
            <Nav
                func={func}
                onNav={onAvatarNav}
                onEnterDown={onEnter}
                onClick={onEnter}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
                defaultFocused={defaultFocused}
            >
                <AgeRestrictionGridElementWrapper size={size}>
                    <AgeRestrictionIconWrapper size={size} icon={icon} active={active}>
                        <Icon name={icon} />
                    </AgeRestrictionIconWrapper>
                    <AgeRestrictionName>{text}</AgeRestrictionName>
                </AgeRestrictionGridElementWrapper>
            </Nav>
        </AgeRestrictionGridElementContainer>
    );
};
