import { LoginOut } from '@/interfaces/from-schemas/auth/loginOut';
import ResponseErrorModel from '@/models/response-error.model';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { EVENT, EventParams } from '@/stats';

export type IActivityEventsUserLogged =
    | {
          ok: false;
          error: ResponseErrorModel;
      }
    | {
          ok: true;
          data: LoginOut;
      };

export type IActivityEventsUserItemClicked = Omit<
    EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED>,
    'place'
>;

export enum STATS_LIST_TYPES {
    COLLECTION_FACTORY = 'collection_factory',
    CONTENT = 'content',
    SEARCH_RESULTS = 'search_results',
}

export interface IListTypeParamsHelper {
    listModel?: StaffRecommendationListModel;
    routerPath?: string;
}
