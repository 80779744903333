import styled from 'styled-components';

export const QR_CODE_SIZE = 280;
export const QR_CODE_PADDING = 10;

export const GoodbyeFullscreenContainer = styled.div<{ backgroundSrc?: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: ${(props) => `url(${props.backgroundSrc})` || 'none'};
    background-repeat: no-repeat;
    background-position: center center;
`;

export const CenteredMessage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 91rem;
`;
export const QRCodeContainer = styled.div`
    position: absolute;
    right: 60px;
    bottom: 60px;
    padding: 10px;
    width: 300px;
    max-width: 300px;
    height: 300px;
    background-color: #fff;
`;
export const Logo = styled.img`
    display: block;
    max-width: 219px;
    margin: 0 auto;
`;
