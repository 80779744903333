import { DeepPartial } from '../../types/deep-partial.type';
import defaultTheme from '../default/theme';
import merge from 'lodash/merge';

const main = {
    400: '#00eef0',
    500: '#00b7c7',
    600: '#008587',
};

const overrides: DeepPartial<typeof defaultTheme> = {
    dark: {
        text: {
            color: {
                highlight: main[500],
                whiteTone0: '#ffffff',
            },
        },

        progressBarColor: main[500],
        mainColor: main[500],
        backgroundImage: {
            customBackgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
        button: {
            activeBgColor: main[500],
            activeBorderColor: main[500],
            hoverBgColor: main[400],
            hoverBorderColor: main[400],
            transparentHoverBorderColor: main[500],
            transparentBgHoverFontColor: main[500],
            loginBorderColor: main[500],
            loginBgColor: main[500],
            progressOverlayColor: main[400],
            paymentPrimaryHoverBgColor: main[400],
            playable: {
                primary: {
                    activeBgColor: main[500],
                },
            },
        },
        progressBar: {
            contentBgColor: main[500],
        },
        switchCheckbox: {
            dotBgColorAfterChecked: main[500],
            switchLabelBgColorAfterChecked: main[600],
        },
        menu: {
            activeColor: main[500],
            focusedIconColor: main[500],
        },
        filterDropdown: {
            clearButtonHoverColor: main[500],
        },
        message: {
            iconColor: main[500],
        },
        input: {
            activeFocusBgColor: main[500],
            activeFocusIconWrapperColor: '#232222',
            nonActiveFocusBgColor: '#1b1b1c',
            textColor: '#a9aeb4',
        },

        player: {
            progressBar: {
                fill: main[500],
            },
        },
        listElement: {
            activeBorder: `.5rem solid ${main[500]}`,
        },
        iconButton: {
            fillHover: main[500],
        },
        dropDownList: {
            iconHoverColor: main[500],
            listItemActiveColor: main[500],
        },
        purchaseButton: {
            promotionColor: main[500],
            activeTextColor: main[500],
            activeBorderColor: main[500],
            //TODO; sprawdzic traila kolor
            trialColor: '#e29811',
        },
        paymentRulesDropdown: {
            activeColor: main[500],
        },
        paymentSteps: {
            focusedBgColor: main[500],
        },
        paymentCheckoutStep: {
            activeRuleColor: main[500],
        },
        paymentTransactionStep: {
            highlight: main[500],
        },
        mediaDetails: {
            iconColor: main[500],
        },
        myPurchases: {
            boxFontColorActive: main[500],
            boxButtonFontColorActive: main[500],
            activeBorderColor: main[500],
        },
        virtualKeyboard: {
            submitButton: {
                //TODO- sprawdzic color
                color: '#30c6b4',
            },
        },
    },
    light: {},
};

const customTheme = {
    dark: {
        button: {
            borderColor: main[500],
        },
        input: {
            notFocusTextColor: '#ffffff',
        },
    },
    light: {},
};

const theme = merge(defaultTheme, overrides, customTheme);

export default theme;
