//! "thumbails" and "posters" will be deprecated in the future. "gallery" will be used instead.

import { ImageInterface, Size } from '@/types/cover-utils.type';

const { width } = process.env.imageSize.background;
const tolerance = 0.05;
const ratio = 0.5625;
const minRatio = ratio * (1 - tolerance);
const maxRatio = ratio * (1 + tolerance);
const aspect = ({ size }: Size) =>
    size.height / size.width >= minRatio && size.height / size.width <= maxRatio;
const sort = (a: ImageInterface, b: ImageInterface) => b.size.width - a.size.width;
const deviceGuard = (el: ImageInterface) => el.size.width <= width;

export const backgroundUtil = (source: ImageInterface[]) => {
    const found = source.filter(deviceGuard).sort(sort).find(aspect);

    if (found) {
        return found;
    } else {
        // wyślij raport do firebase/app events
        return undefined;
    }
};
