import React, { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@/lib/yupResolver';
import { NavWrapper } from '../Account/styles';
import FormRow from '@/components/shared/form/commons/form-row/form-row.common';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared/Buttons';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import {
    EnterPhoneFormData,
    EnterPhoneNumberFormProps,
} from '@/components/views/VerifyPhoneNumber/types';
import t from '@/lib/i18n';
import { formatPhoneNumber, normalizePhoneNumber } from './helpers';
import { sanitizePhoneNo } from '@/helpers/phone-number.helper';

const schema = Yup.object().shape({
    phoneNo: Yup.string()
        .matches(/^\+48(\s\d{3}){3}/, t('form.phone-verification.wrong-phone-format'))
        .required(t('form.validate.required')),
});

export const EnterPhoneNumberForm = ({
    handlePhoneNumber,
    handleCancel,
}: EnterPhoneNumberFormProps) => {
    const { register, handleSubmit, errors } = useForm<EnterPhoneFormData>({
        resolver: yupResolver(schema),
    });
    const [phoneNumber, setPhoneNumber] = useState('+48');

    const onSubmit = handleSubmit(async (data) => {
        const currentValue = sanitizePhoneNo(data.phoneNo);
        handlePhoneNumber(currentValue);
    });

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <NavWrapper>
            <form onSubmit={onSubmit}>
                <FormRow>
                    <TextInput
                        format={formatPhoneNumber}
                        normalize={normalizePhoneNumber}
                        withFormat
                        name="phoneNo"
                        placeholder="+48"
                        error={!!errors.phoneNo}
                        type={INPUT_TYPE.TEL}
                        icon={ICONS.PHONE}
                        ref={register()}
                        defaultFocused
                        data-testing="phoneNo"
                        value={phoneNumber}
                        onChange={(value) => {
                            setPhoneNumber(value);
                            return value;
                        }}
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={onSubmit}
                        text={t('next-button-label')}
                        data-testing="next-button-label"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={handleCancel}
                        text={t('cancel-button-label')}
                        data-testing="cancel-button-label"
                    />
                </FormRow>
            </form>
            {renderFormErrors()}
        </NavWrapper>
    );
};
