import { useGetRulesSource } from '@/api/rules-source/use-get-rules-source';
import { Icon, ICONS } from '@/components/shared/Icon';
import { ProcessingLoader } from '@/components/shared/Loader';
import ScrollableContainer from '@/components/shared/ScrollableContainer';
import t from '@/lib/i18n';
import React from 'react';
import { RulesContainerStyled, RulesHeader, RulesHighlight, IconWrapper } from './styles';
import { RulesScrollableContainerProps } from './types';

export const RulesScrollableContainer = ({
    sourceUrl,
    onBack,
    showBack = false,
}: RulesScrollableContainerProps) => {
    const { data, isFetching } = useGetRulesSource(sourceUrl ? { sourceUrl } : undefined);

    const ruleDisplayData = data?.data || t('no-rule-content');

    if (isFetching) {
        return <ProcessingLoader isFetching={isFetching} />;
    }

    return (
        <ScrollableContainer onBack={onBack} showBack={showBack}>
            <RulesHeader>
                <IconWrapper>
                    <Icon name={ICONS.INFO_CIRCLE_FILLED} />
                </IconWrapper>
                {t('rulesHeader')} <RulesHighlight>{process.env.rules.displayUrl}</RulesHighlight>
            </RulesHeader>
            <RulesContainerStyled>
                <div dangerouslySetInnerHTML={{ __html: ruleDisplayData }} />
            </RulesContainerStyled>
        </ScrollableContainer>
    );
};
