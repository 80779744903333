import t from '@/lib/i18n';
import { TimeOfDay } from './types';
import { EPG_CONSTANTS } from '@/constants/epg-ssr';

const { START_HOUR_MORNING, START_HOUR_AFTERNOON, START_HOUR_EVENING } = EPG_CONSTANTS;

type TimesOfDay = {
    label: string;
    value: TimeOfDay;
};

//TODO: Server time
export const getCurrentHours = () => new Date().getHours();

export const timesOfDay: TimesOfDay[] = [
    {
        label: t('pages.epg.now'),
        value: 'now',
    },
    {
        label: t('pages.epg.morning'),
        value: 'morning',
    },
    {
        label: t('pages.epg.afternoon'),
        value: 'afternoon',
    },
    {
        label: t('pages.epg.evening'),
        value: 'evening',
    },
];

export const timeOfDayToHour = (timeOfDay: TimeOfDay | null): number => {
    const currentHour = getCurrentHours();

    switch (timeOfDay) {
        case 'now':
            return currentHour;
        case 'morning':
            return START_HOUR_MORNING;
        case 'afternoon':
            return START_HOUR_AFTERNOON;
        case 'evening':
            return START_HOUR_EVENING;
        default:
            return currentHour;
    }
};

export const hourToTimeOfDay = (hour: number): TimeOfDay => {
    const currentHour = getCurrentHours();

    if (hour === currentHour) {
        return 'now';
    }

    if (hour === START_HOUR_MORNING) {
        return 'morning';
    } else if (hour === START_HOUR_AFTERNOON) {
        return 'afternoon';
    } else if (hour === START_HOUR_EVENING) {
        return 'evening';
    }

    return 'now';
};
