import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import {
    ACCESSIBILITY_FEATURES,
    ADDITIONAL_SIGNS_TYPES,
    UNDERAGE_CLASSIFICATION,
} from '@/types/additional-signs.type';

export const getAdditionalSignsIconName = (
    type: ADDITIONAL_SIGNS_TYPES,
    additionalSign: string,
): ICONS | undefined => {
    if (type === ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION) {
        switch (additionalSign) {
            case UNDERAGE_CLASSIFICATION.VIOLENCE:
                return ICONS.UNDERAGE_CLASSIFICATION_VIOLENCE;
            case UNDERAGE_CLASSIFICATION.SEX:
                return ICONS.UNDERAGE_CLASSIFICATION_SEX;
            case UNDERAGE_CLASSIFICATION.VULGARISMS:
                return ICONS.UNDERAGE_CLASSIFICATION_VULGARISMS;
            case UNDERAGE_CLASSIFICATION.DRUGS:
                return ICONS.UNDERAGE_CLASSIFICATION_DRUGS;
            default:
                return undefined;
        }
    } else if (type === ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES) {
        switch (additionalSign) {
            case ACCESSIBILITY_FEATURES.AUDIO_DESCRIPTION:
                return ICONS.ACCESSIBILITY_FEATURES_AUDIO_DESCRIPTION;
            case ACCESSIBILITY_FEATURES.SUBTITLES:
                return ICONS.ACCESSIBILITY_FEATURES_SUBTITLES;
            case ACCESSIBILITY_FEATURES.SIGN_LANGUAGE:
                return ICONS.ACCESSIBILITY_FEATURES_SIGN_LANGUAGE;
            default:
                return undefined;
        }
    }
};
export const getAdditionalSignsName = (type: ADDITIONAL_SIGNS_TYPES, additionalSign: string) => {
    if (type === ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION) {
        switch (additionalSign) {
            case UNDERAGE_CLASSIFICATION.VIOLENCE:
                return t('underage-classification.violence');
            case UNDERAGE_CLASSIFICATION.SEX:
                return t('underage-classification.sex');
            case UNDERAGE_CLASSIFICATION.VULGARISMS:
                return t('underage-classification.vulgarisms');
            case UNDERAGE_CLASSIFICATION.DRUGS:
                return t('underage-classification.drugs');
            default:
                return undefined;
        }
    } else if (type === ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES) {
        switch (additionalSign) {
            case ACCESSIBILITY_FEATURES.AUDIO_DESCRIPTION:
                return t('accessibility-features.audio-description');
            case ACCESSIBILITY_FEATURES.SUBTITLES:
                return t('accessibility-features.subtitles');
            case ACCESSIBILITY_FEATURES.SIGN_LANGUAGE:
                return t('accessibility-features.sign-language');
            default:
                return undefined;
        }
    }
};
