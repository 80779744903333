import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { SubmitPurchaseCodeOut } from '@/interfaces/from-schemas/payments/submitPurchaseCodeOut';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { ISubmitPurchaseCode } from '@/api/payments/types';

const useSubmitPurchaseCode = (
    params?: ISubmitPurchaseCode,
): IApiResponse<SubmitPurchaseCodeOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.SUBMIT_PURCHASE_CODE,
        params,
    );

    return useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
};

export default useSubmitPurchaseCode;
