import { useAuth } from '@/contexts/auth/auth.hooks';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { RecommendationListElementModel } from '@/models/list-element/recommendation-list-element.model';
import { StaffRecommendationListsItemModel } from '@/models/recommendations/staff-recommendation-lists-item.model';
import { getTizenPayloadData, launchTizenPreviewService } from '@/helpers/preview.helper';
import { buildGetMediaRequestParams } from '@/services/get-media/get-media.service';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { GM_OS } from '@/types/platform.type';
import { useCallback, useEffect, useState } from 'react';
import { usePlatform } from '@/contexts/platform/platform.hooks';

const usePreviewData = () => {
    const auth = useAuth();
    const clientId = useUserModule().getClientId();

    return useCallback(() => {
        const options = { appendAuthData: true, appendClientId: true };
        const authParams = { ...auth, clientId };

        const recommendationsListsParams = buildGetMediaRequestParams(
            {
                namespace: GET_MEDIA_NAMESPACES.NAVIGATION,
                method: GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS,
                params: {},
            },
            options,
            authParams,
        );
        const recommendationsListItemsParams = buildGetMediaRequestParams(
            {
                namespace: GET_MEDIA_NAMESPACES.NAVIGATION,
                method: GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS,
                params: {},
            },
            options,
            authParams,
        );

        return recommendationsListsParams.authData && recommendationsListItemsParams.authData
            ? { recommendationsListsParams, recommendationsListItemsParams }
            : null;
    }, [auth, clientId]);
};

export const useLaunchPreviewService = () => {
    const [isLaunched, setIsLaunched] = useState(false);
    const buildPreviewData = usePreviewData();
    const { platform } = usePlatform();

    useEffect(() => {
        if (!isLaunched && platform.os === GM_OS.Tizen) {
            const previewData = buildPreviewData();

            if (previewData) {
                setIsLaunched(true);
                launchTizenPreviewService(previewData);
            }
        }
    }, [buildPreviewData, isLaunched, platform]);
};

export const usePreviewLaunched = () => {
    const { platform } = usePlatform();

    useEffect(() => {
        if (platform.os === GM_OS.Tizen) {
            const payloadData = getTizenPayloadData();

            if (payloadData) {
                const item = new StaffRecommendationListsItemModel(payloadData);
                const listElement = new RecommendationListElementModel(item);

                listElement.goToDetails();
            }
        }
    }, [platform]);
};
