import { useKeyboardEvents } from '@/hooks/use-keyboard-events.hook';
import { KEYS } from '@/input/keys';
import { usePausablePlayable } from './use-pausable-playable';
import { usePlayerInstance } from './use-player-instance';
import { Router } from '@/routing';
import { isStbTargets } from '@/constants/portal-recognition';

export const usePlayerRemoteControl = ({
    isSideBarOpen,
    onDestroy,
}: {
    isSideBarOpen: boolean;
    onDestroy?: () => void;
}) => {
    const { playable, pausable } = usePausablePlayable();
    const instance = usePlayerInstance();

    useKeyboardEvents(
        () => [
            {
                keys: [KEYS.PLAY_PAUSE],
                keydownHandler: () => {
                    if (playable) {
                        instance.play();
                    }
                    if (pausable) {
                        instance.pause();
                    }
                },
            },
            {
                keys: [KEYS.PLAY],
                keydownHandler: () => instance.user.play(),
            },
            {
                keys: [KEYS.PAUSE],
                keydownHandler: () => instance.user.pause(),
            },
            {
                keys: [KEYS.STOP],
                keydownHandler: async () => {
                    if (isStbTargets) {
                        await instance.pause();
                        instance.skip(0);
                        return;
                    }

                    Router.back();
                },
            },
            {
                keys: [KEYS.BACK],
                keydownHandler: (event) => {
                    if (isSideBarOpen) {
                        event.preventDefault();
                        onDestroy?.();
                        return;
                    }
                },
            },
        ],
        [instance, playable, pausable, isSideBarOpen, onDestroy],
    );
};
