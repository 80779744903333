import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useLocalStorage } from '@/hooks/use-local-storage.hook';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';
import Stats from '@/stats';
import { useCallback, useEffect, useState } from 'react';
import { OneTrustService } from '@/services/one-trust.servive';

const sessionExpirationTime = process.env.stats.sessionIdExpiration;

export const useAppSession = () => {
    const [sessionId, setSessionId] = useLocalStorage(LOCAL_STORAGE_KEYS.APP_SESSION_ID);
    const [sessionExpiration, setSessionExpiration] = useLocalStorage(
        LOCAL_STORAGE_KEYS.APP_SESSION_EXPIRATION,
    );

    const userModule = useUserModule();
    const [userId, setUserId] = useState(userModule.getUserId());

    useEffect(() => {
        const unlisten = userModule.listen(() => {
            setUserId(userModule.getUserId());
            return unlisten;
        });
    }, [userModule]);

    useEffect(() => {
        const token = userModule.getJWTToken();
        const uid = userId ? String(userId) : '';
        const oneTrustService = new OneTrustService();
        oneTrustService.update(uid, token);
    }, [userModule, userId]);

    const generateAppSession = useCallback(() => {
        batchedUpdates(() => {
            const sessionId = Stats.generateSessionAppId(`${userId || ''}`);
            const sessionExpiration = Date.now() + sessionExpirationTime;

            setSessionId(sessionId);
            setSessionExpiration(sessionExpiration);
        });
    }, [setSessionId, setSessionExpiration]);

    useEffect(() => {
        if (!sessionId) {
            generateAppSession();
        }
    }, [sessionId, generateAppSession]);

    useEffect(() => {
        if (sessionId && sessionExpiration && sessionExpiration < Date.now()) {
            generateAppSession();
        }
    });

    return {
        sessionId,
        sessionExpiration,
    };
};
