import { GetUnacceptedRulesIn } from '@/interfaces/from-schemas/auth/getUnacceptedRulesIn';
import UserModule from '@/modules/user/user.module';

export const buildUnacceptedRulesParams = (
    userModule: UserModule,
    obligatory?: boolean,
    context?: GetUnacceptedRulesIn['context'],
) => {
    const deviceId = userModule.getDeviceIdIdent();
    const isLogged = userModule.isLogged();
    const authData = userModule.getAuthData();

    let params;

    if (context) {
        params = { context, authData: { ...authData, deviceId } };
    } else {
        params = !obligatory
            ? isLogged
                ? { context: 'start' }
                : { context: 'login' }
            : { context: 'login', authData: { ...authData, deviceId } };
    }

    return params;
};
