import { Nav, NavTree } from 'nav-tree';
import { RefObject, useLayoutEffect, useMemo, useState } from 'react';
import { navHorizontalArrayGuided } from './helpers';

export function useVirtualScrollSlider<T>({
    isDefault,
    items,
    sliderNavId,
    navRef,
    itemToNavId,
    onNav,
}: {
    isDefault: (item: T, index: number) => boolean;
    items: T[];
    sliderNavId: string;
    navRef: RefObject<Nav>;
    itemToNavId: (item: T) => string;
    onNav?: (focused: boolean) => void;
}) {
    const [isSliderFocused, setIsSliderFocused] = useState(false);

    const defaultIndex = useMemo(() => items.findIndex(isDefault), [items, isDefault]);

    const [focusIndex, setFocusIndex] = useState(defaultIndex ?? 0);

    const defaultItem = items[defaultIndex];

    const focusedItem = items[focusIndex];

    const onPrev = () => {
        const prevItem = items[focusIndex - 1];
        focusItem(prevItem);
    };

    const onNext = () => {
        const nextItem = items[focusIndex + 1];
        focusItem(nextItem);
    };

    const focusItem = (item: T) => {
        if (item) {
            const navId = itemToNavId(item);
            navRef?.current?.imperativeFocus([navId]);
        }
    };

    useLayoutEffect(() => {
        if (!isSliderFocused) {
            setFocusIndex(defaultIndex);
        }
    }, [isSliderFocused, defaultIndex]);

    useLayoutEffect(() => {
        const sliderTree: NavTree | undefined = navRef?.current?.getChildContext().tree;
        if (!sliderTree) {
            return;
        }
        const slider = sliderTree.el;
        const tile = sliderTree.nodes[itemToNavId(focusedItem)]?.el;

        if (!slider || !tile) {
            return;
        }

        const containerWidth = slider.parentElement?.offsetWidth;
        const offsetLeft = tile.offsetLeft;
        const offsetWidth = tile.offsetWidth;
        const scrollPos = offsetLeft - (containerWidth || 0) / 2 + offsetWidth / 2;
        slider.scroll(scrollPos, 0);
    }, [focusedItem]);

    return {
        focusIndex,
        setFocusIndex,
        sliderNavProps: {
            func: navHorizontalArrayGuided<T>({
                focusIndex,
                items,
                getItemNavId: itemToNavId,
                navId: sliderNavId,
                onEntering: () => itemToNavId(defaultItem) ?? false,
            }),
            onNav: (focused: boolean) => {
                onNav?.(focused);
                setIsSliderFocused(focused);
            },
            ref: navRef,
            navId: sliderNavId,
        },
        onPrev,
        onNext,
    };
}
