import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { finalFilterList } from './final-filter-list.helper';

export const buildFiltersForApi = (data: FlatFilterListSchema[], checkedFilters: string[]) => {
    const filters = data.map((list) => list.filters);
    const flatFilters = filters.reduce((acc, val) => acc.concat(val), []);
    const result = finalFilterList(flatFilters, checkedFilters);

    return result;
};
