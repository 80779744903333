import { LoaderContextProps, LoaderContextProviderProps } from './types';
import { createContext, useContext, useState } from 'react';

export const LoaderContext = createContext<LoaderContextProps>({
    showLoader: false,
    setShowLoader: () => null,
});

const LoaderContextProvider = ({ children }: LoaderContextProviderProps) => {
    const [showLoader, setShowLoader] = useState<boolean>();

    return (
        <LoaderContext.Provider value={{ showLoader, setShowLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoaderContext = () => useContext(LoaderContext);

export default LoaderContextProvider;
