import React, { createContext, useCallback, useContext, useState } from 'react';
import { NAVIGATION_KEYS } from 'nav-tree';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { PageDirectionKeys, TablePageContextProps } from './types';

const initialContextValue = {
    currentPage: 0,
    setCurrentPage: () => null,
    pageDirection: undefined,
    setPageDirection: () => null,
    onPageChange: () => null,
    resetContext: () => null,
    isDueToPageChange: false,
    setIsDueToPageChange: () => null,
};

const TablePageContext = createContext<TablePageContextProps>(initialContextValue);

export const TablePageContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageDirection, setPageDirection] = useState<PageDirectionKeys>();
    const [isDueToPageChange, setIsDueToPageChange] = useState(false);

    const onPageChange = useCallback((navKeys: NAVIGATION_KEYS) => {
        if (navKeys === NAVIGATION_KEYS.LEFT || navKeys === NAVIGATION_KEYS.RIGHT) {
            setIsDueToPageChange(true);
        }

        if (navKeys === NAVIGATION_KEYS.LEFT) {
            batchedUpdates(() => {
                setPageDirection('left');
                setCurrentPage((prev) => prev - 1);
            });
        }
        if (navKeys === NAVIGATION_KEYS.RIGHT) {
            batchedUpdates(() => {
                setPageDirection('right');
                setCurrentPage((prev) => prev + 1);
            });
        }
    }, []);

    const resetContext = useCallback(() => {
        batchedUpdates(() => {
            setCurrentPage(1);
            setPageDirection(undefined);
        });
    }, []);

    return (
        <TablePageContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                pageDirection,
                setPageDirection,
                onPageChange,
                resetContext,
                setIsDueToPageChange,
                isDueToPageChange,
            }}
        >
            {children}
        </TablePageContext.Provider>
    );
};

export function useTablePageContext() {
    const context = useContext(TablePageContext);
    if (context === undefined) {
        throw new Error('useTablePageContext must be used within a TablePageContextProvider');
    }
    return context;
}
