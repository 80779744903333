import { useSingleRef } from '@/hooks/use-single-ref.hook';
import UserRecognitionModel from '@/modules/user/models/user-recognition.model';
import SessionService from '@/modules/user/services/session/session.service';
import UserRecognitionService from '@/modules/user/services/session/user-recognition.service';
import UserModule from '@/modules/user/user.module';
import CryptoUtil from '@/utils/crypto.utils';
import React, { createContext } from 'react';
import { useCookieService } from '@/contexts/cookies/cookies.hooks';
import { UserModuleProviderProps } from './types';

export const UserModuleContext = createContext<UserModule>({} as UserModule);

const UserModuleProvider = ({ children, initialSession }: UserModuleProviderProps) => {
    const cookieService = useCookieService();
    const userModule = useSingleRef(() => {
        const userModule = new UserModule(
            new UserRecognitionService(cookieService, new UserRecognitionModel()),
            new SessionService(cookieService, new CryptoUtil()),
        );
        if (initialSession) {
            userModule.setAuthSession(initialSession);
        }
        return userModule;
    });

    return <UserModuleContext.Provider value={userModule}>{children}</UserModuleContext.Provider>;
};

export default UserModuleProvider;
