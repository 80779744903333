import styled from 'styled-components';

export const StyledHelpContainer = styled.div`
    height: 100vh;
    width: 100%;
    padding: 12rem 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const StyledEnvironmentInfoContainer = styled.div`
    max-width: 100%;
    padding-top: 3rem;
    width: calc(100vw - 70.5rem);
    overflow: hidden;
`;

export const StyledHeaderText = styled.div`
    font-size: 4.6rem;
    margin: 2rem 0 0 0;
`;

export const StyledEnvironmentRow = styled.div`
    display: block;
    padding: 1rem 0;
`;

export const StyledEnvironmentTitle = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 2rem;
    text-align: right;
    padding-right: 2rem;
`;

export const StyledEnvironmentValue = styled.div`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-size: 2rem;
    text-align: left;
    color: ${({ theme }) => theme.text.color.secondary};
`;
