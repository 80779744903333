import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '@/components/shared/Icon';
import React, { useState } from 'react';
import { BlikFormContainer } from './styles';
import { BlikFormProps } from './types';
import * as yup from 'yup';
import t from '@/lib/i18n';

const BLIK_LENGTH = 6;
const schema = yup.string().required().length(BLIK_LENGTH);

export const BlikForm = ({ onSubmit, onError }: BlikFormProps) => {
    const [blikCode, setBlikCode] = useState<string>();
    const [hasError, setError] = useState(false);

    const onChange = (value: string) => {
        setBlikCode(value);
        return value;
    };

    const onFormSubmit = async () => {
        const isValid = await schema.isValid(blikCode);
        setError(!isValid);

        if (blikCode && isValid) {
            onSubmit(blikCode);
        } else {
            onError();
        }
    };

    return (
        <BlikFormContainer>
            <TextInput
                placeholder={t('payment-steps.blik.enter-code')}
                icon={ICONS.KEY}
                type={INPUT_TYPE.TEL}
                error={hasError}
                defaultFocused
                onChange={onChange}
            />
            <PlayableButton text={t('payment-steps.blik.next')} onButtonAction={onFormSubmit} />
        </BlikFormContainer>
    );
};
