import { BUTTON_ID } from '@/types/button-id.type';
import { NAVIGATION_KEYS, NavTree } from 'nav-tree';

export function createOrderedNav<ID extends string>(
    order: ID[],
    missingFallback: (key: NAVIGATION_KEYS, existingNodeIds: ID[]) => ID | undefined,
) {
    return (key: NAVIGATION_KEYS, tree: NavTree) => {
        const existingNodesIds = order.filter((id) => tree.nodesId.includes(id));
        const previousId = tree.focusedNode;
        const hasIdInTree = (id: BUTTON_ID) => existingNodesIds.indexOf(id as ID) !== -1;

        let nextFocusedNode: ID | undefined;
        if (previousId) {
            const previousPosition = existingNodesIds.indexOf(previousId as ID);

            let nextCandidate: number | undefined;
            switch (key) {
                case NAVIGATION_KEYS.DOWN:
                    nextCandidate = previousPosition + 1;
                    break;
                case NAVIGATION_KEYS.UP:
                    nextCandidate = previousPosition - 1;
                    break;
                case NAVIGATION_KEYS.LEFT:
                    if (hasIdInTree(BUTTON_ID.CC_BACK)) {
                        nextFocusedNode = BUTTON_ID.CC_BACK as ID;
                        break;
                    }

                    return false;
                case NAVIGATION_KEYS.RIGHT:
                    if (hasIdInTree(BUTTON_ID.CC_EXIT)) {
                        nextFocusedNode = BUTTON_ID.CC_EXIT as ID;
                        break;
                    }

                    return false;
                default:
                    return false;
            }

            const nextFocusedPosition = Math.min(
                Math.max(nextCandidate ?? previousPosition, 0),
                existingNodesIds.length - 1,
            );
            if (!nextFocusedNode) {
                nextFocusedNode = existingNodesIds[nextFocusedPosition];
            }
        } else {
            nextFocusedNode = missingFallback(key, existingNodesIds);
        }

        if (nextFocusedNode !== undefined) {
            tree.focus([nextFocusedNode]);
        }
        return null;
    };
}
