import { useCallback, useEffect, useMemo, useState } from 'react';
import clamp from 'lodash/clamp';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { usePlayerInstance } from './use-player-instance';
import { usePlayerTimeMarkers } from './use-player-time-markers';
import { useKeyboardEvents } from '@/hooks/use-keyboard-events.hook';
import { KEYS } from '@/input/keys';
import { IPlayerSeekingParams, SEEKING_DIR } from '@/interfaces/player.interface';
import { useTimeTargets } from '../use-time-targets';
import { useInterval } from '../use-interval.hook';
import { Debug } from '@/utils/debug.util';
import { usePlayerGuiStateContext } from './use-player-gui-state';
import { useIsLive } from './use-is-live';
import { usePlayerSkipRange } from './use-player-skip-range';

export const useAdaptiveSeek = () => {
    const debug = new Debug('useAdaptiveSeek');
    const {
        step,
        singleClickModeStep,
        resetTimeout,
        timeTargetsInterval,
        emitDataIntervalRatio,
        resetDataTimeout,
        speedLevelsLive,
        speedLevelsVod,
        startSeekingInterval,
        minSeekingIntervalVod,
        minSeekingIntervalLive,
    } = process.env.player.seek;
    const { live } = useIsLive();
    const player = usePlayerInstance();
    const { currentTime } = usePlayerTimeMarkers();
    const { setTmpPlaybackCurrentTime } = usePlayerGuiStateContext();

    const [pendingSeek, setPendingSeek] = useState<IPlayerSeekingParams | null>(null);

    const time = pendingSeek?.desiredTime ?? currentTime;
    const { start: minTime, end: maxTime } = usePlayerSkipRange();
    const [seekingSpeedLevels, setSeekingSpeedLevels] = useState<number[]>([]);
    const [seekingInterval, setSeekingInterval] = useState<number | null>(null);
    const [seekingDesiredTime, setSeekingDesiredTime] = useState<number | null>(null);
    const [seekingDir, setSeekingDir] = useState<SEEKING_DIR>(SEEKING_DIR.FORWARD);
    let seekStep = step;

    const minSeekingInterval = useMemo(() => {
        return live ? minSeekingIntervalLive : minSeekingIntervalVod;
    }, [live, minSeekingIntervalLive, minSeekingIntervalVod]);

    useEffect(() => {
        if (pendingSeek && typeof pendingSeek.desiredTime === 'number') {
            setTmpPlaybackCurrentTime(pendingSeek.desiredTime);
        }
    }, [pendingSeek]);

    useEffect(() => {
        resetSeekingData();
    }, [currentTime]);

    useDebouncedEffect(
        () => {
            if (pendingSeek) {
                try {
                    const playerCurrentTime = player.getCurrentTime();
                    const timeToSeek = Math.floor(pendingSeek.desiredTime - playerCurrentTime);
                    player.user.seek(timeToSeek);
                    player.play();
                } catch (e) {
                    debug.error('seeking', e);
                }
            }
        },
        { timeout: resetTimeout },
        [pendingSeek, player],
    );

    useDebouncedEffect(
        () => {
            setSeekingSpeedLevels([]);
            setSeekingInterval(null);
            setSeekingDesiredTime(null);
        },
        {
            timeout: resetDataTimeout,
        },
        [seekingDesiredTime],
    );

    useTimeTargets(
        () => {
            if (seekingInterval === null) {
                return;
            }

            const interval = Math.round(seekingInterval / emitDataIntervalRatio);

            if (interval < minSeekingInterval) {
                setSeekingInterval(minSeekingInterval);
                return;
            }

            setSeekingInterval(interval);
        },
        {
            interval: timeTargetsInterval,
            timeTargets: seekingSpeedLevels,
        },
        [seekingInterval, seekingSpeedLevels, timeTargetsInterval],
    );

    const getPendingSeekData = (
        oldState: IPlayerSeekingParams | null,
        seekDirection = seekingDir,
    ) => {
        const seekingTime = oldState ? oldState.desiredTime : player.getCurrentTime();

        const duration = maxTime - minTime;
        const dir = seekDirection === SEEKING_DIR.FORWARD ? +1 : -1;
        const rawDesiredTime = Math.round(seekingTime + seekStep * dir);
        const desiredTime = clamp(rawDesiredTime, minTime, maxTime);

        if (seekingDesiredTime === null) {
            setSeekingDesiredTime(desiredTime);
        }

        return {
            step: seekStep,
            desiredTime,
            dir: seekDirection,
            refTime: {
                minTime,
                maxTime,
                duration,
            },
        };
    };

    useInterval(() => {
        setPendingSeek(getPendingSeekData);
    }, seekingInterval);

    const percentagePosition = useCallback(
        (newTime: number): number => {
            const duration = pendingSeek?.refTime.duration ?? maxTime - minTime;

            const position = parseFloat(
                (((newTime - (pendingSeek?.refTime.minTime ?? minTime)) / duration) * 100).toFixed(
                    2,
                ),
            );

            return position;
        },
        [pendingSeek, minTime, maxTime],
    );

    const resetSeekingData = () => {
        setSeekingSpeedLevels([]);
        setSeekingInterval(null);
        setPendingSeek(null);
        setSeekingDesiredTime(null);
    };

    const adaptiveSeekSingleClick = (dir: SEEKING_DIR) => {
        seekStep = singleClickModeStep;

        if (seekingDesiredTime === null) {
            player.pause();
        }
        setPendingSeek((oldState) => getPendingSeekData(oldState, dir));
        pendingSeek?.desiredTime && setSeekingDesiredTime(pendingSeek.desiredTime);
    };

    const adaptiveSeek = (dir: SEEKING_DIR) => {
        if (seekingDir !== dir) {
            setSeekingSpeedLevels([]);
            setSeekingInterval(null);
        }

        if (seekingInterval === null) {
            player.pause().then(() => {
                const speedLevels = live ? speedLevelsLive : speedLevelsVod;
                setSeekingInterval(startSeekingInterval);
                setSeekingSpeedLevels(speedLevels);
            });
        }

        setSeekingDir(dir);
        pendingSeek?.desiredTime && setSeekingDesiredTime(pendingSeek.desiredTime);
    };

    return {
        time,
        adaptiveSeek,
        adaptiveSeekSingleClick,
        percentagePosition,
        resetSeekingData,
    };
};

export const useAdaptiveSeekWithRemote = () => {
    const adaptiveSeekObj = useAdaptiveSeek();
    const { adaptiveSeek } = adaptiveSeekObj;

    useKeyboardEvents(
        () => [
            {
                keys: [KEYS.REWIND],
                keydownHandler: () => adaptiveSeek(SEEKING_DIR.BACK),
            },
            {
                keys: [KEYS.FORWARD],
                keydownHandler: () => adaptiveSeek(SEEKING_DIR.FORWARD),
            },
        ],
        [adaptiveSeek],
    );

    return adaptiveSeekObj;
};
