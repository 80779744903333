import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { AcceptRulesOut } from '@/interfaces/from-schemas/auth/acceptRulesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { UserAuthData } from '@/types/user-auth-data.type';

export interface IUseAcceptRules {
    rulesIds: number[];
    authData?: UserAuthData;
}

const useAcceptRules = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.SEND_ACCEPTED_RULES,
        null,
    );

    return useGetMediaApiMutation<IUseAcceptRules, AcceptRulesOut>(keyHook, {
        invalidate: [[GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.FETCH_RULES_STATUS]],
    });
};

export default useAcceptRules;
