import { useRouter } from '@/routing';

interface IConsentDetailsInitialProps {
    ruleId?: number;
}

export const useConsentDetailsInitialProps = (): IConsentDetailsInitialProps => {
    const { query } = useRouter();
    return { ruleId: +`${query.ruleId}` };
};
