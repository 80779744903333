import { useEffect } from 'react';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';

export const useIsLastPage = (channelsCount: number, endIndex: number) => {
    const { setIsLastPage } = useIncrementColumnContext();
    const isLastPage = endIndex >= channelsCount;

    useEffect(() => {
        setIsLastPage(isLastPage);
    }, [setIsLastPage, isLastPage]);

    return { isLastPage: endIndex >= channelsCount };
};
