import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import { ROUTES } from '@/routing/types';

interface IConfig {
    route: ROUTES;
    contextType: string;
    contextValue: string;
}
//sprawdza czy ten routes jest na liscie i mapuje na prawidłowy route
const checkRoutes = (type: string, value: string): IConfig => {
    switch (true) {
        // homepage
        case type === 'homepage' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'homepage',
                contextValue: '',
            };

        // category
        //TODO co tutaj bo jest <categoryID>
        //co to u nas sa kategorie
        case type === 'category' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'category',
                contextValue: '',
            };
        // category_recommendations
        // TODO: value:<categoryID>
        case type === 'category_recommendations' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'category_recommendations',
                contextValue: '',
            };
        // tv_channels
        case type === 'tv_channels' && value === '':
            return {
                route: ROUTES.CHANNELS_TV,
                contextType: 'tv_channels',
                contextValue: '',
            };
        // live_list
        case type === 'live_list' && value === '':
            return {
                route: ROUTES.LIVE,
                contextType: 'live_list',
                contextValue: '',
            };
        //search
        case type === 'search' && value === '':
            return {
                route: ROUTES.SEARCH,
                contextType: 'search',
                contextValue: '',
            };
        // packets_recommendations
        //Widok pakietów - listy rekomendacyjne
        case type === 'packets_recommendations' && value === '':
            return {
                route: ROUTES.PACKETS,
                contextType: 'packets_recommendations',
                contextValue: '',
            };
        //packets
        //Widok listy pakietów - katalog
        case type === 'packets' && value === '':
            return {
                route: ROUTES.PACKETS,
                contextType: 'packets',
                contextValue: '',
            };
        //vod
        case type === 'vod' && value !== '':
            //rozszerzyć usługęo sprawdzanie patha, chodzi o to ze mamy rozne route dla vod, dla epizodow
            //jesli dany material to np vod to kierowac na VOD_MOVIE_DETAILS, jak episode to VOD_EPISODE_DETAILS
            return {
                route: ROUTES.VOD_MOVIE_DETAILS,
                contextType: 'vod',
                contextValue: value,
            };
        //vod_info
        case type === 'vod_info' && value !== '':
            return {
                route: ROUTES.VOD_MOVIE_FULL_DETAILS,
                contextType: 'vod_info',
                contextValue: value,
            };
        //key_category- Karta kategorii kluczowej
        //TODO:gdzie ma kierowac
        //TODO:value <keyCategoryID>
        case type === 'key_category' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'key_category',
                contextValue: '',
            };
        //key_category_info
        //TODO:value <keyCategoryID>
        case type === 'key_category_info' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'key_category_info',
                contextValue: '',
            };
        //channel
        case type === 'channel' && value !== '':
            return {
                route: ROUTES.CHANNEL_TV_DETAILS,
                contextType: 'channel',
                contextValue: value,
            };
        //channel_info
        case type === 'channel_info' && value !== '':
            return {
                route: ROUTES.CHANNEL_TV_FULL_DETAILS,
                contextType: 'channel_info',
                contextValue: value,
            };
        //TODO:tv_program Widok programu tv (EPG)
        //TODO: tv_program_info -Widok "więcej" z informacjami o programie tv
        //TODO epg -Widok pozycji EPG
        //TODO: epg_details -Widok "więcej" z informacjami o pozycji EPG

        //live
        //TODO: value<liveID>
        case type === 'live' && value !== '':
            return {
                route: ROUTES.LIVE_WATCH,
                contextType: 'live',
                contextValue: value,
            };
        //live_info
        //TODO: value<liveID>
        case type === 'live_info' && value !== '':
            return {
                route: ROUTES.LIVE_DETAILS,
                contextType: 'live_info',
                contextValue: value,
            };

        //packet
        case type === 'packet' && value === '':
            return {
                route: ROUTES.PACKET_DETAILS,
                contextType: 'packet',
                contextValue: value,
            };
        //packet_info
        case type === 'packet_info' && value === '':
            return {
                route: ROUTES.PACKET_FULL_DETAILS,
                contextType: 'packet_info',
                contextValue: value,
            };

        //offer
        case type === 'offer' && value !== '':
            return {
                route: ROUTES.PACKET_DETAILS,
                contextType: 'offer',
                contextValue: value,
            };
        //offer_info
        case type === 'offer_info' && value !== '':
            return {
                route: ROUTES.PACKET_FULL_DETAILS,
                contextType: 'offer_info',
                contextValue: value,
            };
        //my_list
        case type === 'my_list' && value === '':
            return {
                route: ROUTES.MY_ACCESS,
                contextType: 'my_list',
                contextValue: '',
            };
        //  PLAYER
        //stop_playback
        //TODO na co ma kierowac ??
        case type === 'stop_playback' && value === '':
            return {
                route: ROUTES.HOME,
                contextType: 'stop_playback',
                contextValue: '',
            };
        //Płatności

        //payments_access_option - Widok z listą opcji dostępu (np.: 30 dni/48h/30 dni cykliczny)
        case type === 'payments_access_option' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_access_option',
                contextValue: '',
            };
        //payments_payment_method - Widok dostępnych opcji płatności dla wybranej opcji dostępu
        case type === 'payments_payment_method' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_payment_method',
                contextValue: '',
            };
        //payments_order_summary- Widok podsumowania płatności
        case type === 'payments_order_summary' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_order_summary',
                contextValue: '',
            };

        //payments_dotpay_blik - Widok płatności BLIK (z opcją wpisania kodu)
        case type === 'payments_dotpay_blik' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_dotpay_blik',
                contextValue: '',
            };

        //payments_dotpay_card- Widok płatności kartą
        case type === 'payments_dotpay_card' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_dotpay_card',
                contextValue: '',
            };
        //payments_cp_wallet- Widok płatności CPWallet
        case type === 'payments_cp_wallet' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_cp_wallet',
                contextValue: '',
            };

        //payments_dotpay- Widok płatności DOTPAY (SDK)
        case type === 'payments_dotpay' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_dotpay',
                contextValue: '',
            };

        //payments_plusbill-Widok płatności Plusbillem

        case type === 'payments_plusbill' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_plusbill',
                contextValue: '',
            };
        // payments_sms-Widok płatności SMS
        case type === 'payments_sms' && value === '':
            return {
                route: ROUTES.PURCHASE,
                contextType: 'payments_sms',
                contextValue: '',
            };
        //payments_change_card-Widok zmiany karty płatności
        case type === 'payments_change_card' && value === '':
            return {
                route: ROUTES.MY_PAYMENTS,
                contextType: 'payments_change_card',
                contextValue: '',
            };
        //payments_cancel_subscription- Widok anulowania subskrypcji
        case type === 'payments_cancel_subscription' && value === '':
            return {
                route: ROUTES.ACCOUNT_MY_PURCHASES,
                contextType: 'payments_cancel_subscription',
                contextValue: '',
            };
        //payments_resume_subscription-Widok wznawiania subskrypcji
        case type === 'payments_resume_subscription' && value === '':
            return {
                route: ROUTES.ACCOUNT_MY_PURCHASES,
                contextType: 'payments_resume_subscription',
                contextValue: '',
            };
        //rule-Pełny widok wybranego regulaminu
        case type === 'rule' && value === '':
            return {
                route: ROUTES.RULES,
                contextType: 'rule',
                contextValue: '',
            };
        //Konto/Ustawienia
        //select_login_type-Widok wyboru typu logowania Polsat Box/Inne rodzaje logowania - widok dostępny w Polsat Box Go
        case type === 'select_login_type' && value === '':
            return {
                route: ROUTES.LOGIN_BY_REMOTE,
                contextType: 'select_login_type',
                contextValue: '',
            };
        //login
        case type === 'login' && value === '':
            return {
                route: ROUTES.LOGIN,
                contextType: 'login',
                contextValue: '',
            };
        case type === 'login' && value === 'native':
            return {
                route: ROUTES.LOGIN_NATIVE,
                contextType: 'login',
                contextValue: 'native',
            };
        case type === 'login' && value === 'facebook':
            return {
                route: ROUTES.LOGIN_FACEBOOK,
                contextType: 'login',
                contextValue: 'facebook',
            };
        case type === 'login' && value === 'sso':
            return {
                route: ROUTES.LOGIN_IPOLSATBOX,
                contextType: 'login',
                contextValue: 'sso',
            };
        //registration
        case type === 'registration' && value === 'native':
            return {
                route: ROUTES.REGISTRATION,
                contextType: 'registration',
                contextValue: 'native',
            };
        case type === 'registration' && value === 'code':
            return {
                route: ROUTES.REGISTRATION,
                contextType: 'registration',
                contextValue: 'code',
            };
        //account_settings- Widok konta (widok uruchamiany po wybraniu ikony profilu/konta z menu)
        case type === 'account_settings' && value === '':
            return {
                route: ROUTES.ACCOUNT,
                contextType: 'account_settings',
                contextValue: '',
            };
        //edit_profile-Widok edycji profilu
        case type === 'edit_profile' && value === '':
            return {
                route: ROUTES.PROFILE,
                contextType: 'edit_profile',
                contextValue: '',
            };
        //select_profile-Widok wyboru profilu
        case type === 'select_profile' && value === '':
            return {
                route: ROUTES.ACCOUNT_CHANGE_PROFILE,
                contextType: 'select_profile',
                contextValue: '',
            };
        //manage_profiles-Widok zarządzania profilami (wybór profilu do edycji)

        case type === 'manage_profiles' && value === '':
            return {
                route: ROUTES.PROFILE_SELECT_TO_EDIT,
                contextType: 'manage_profiles',
                contextValue: '',
            };
        //edit_account - Widok edycji konta
        case type === 'edit_account' && value === '':
            return {
                route: ROUTES.ACCOUNT,
                contextType: 'edit_account',
                contextValue: '',
            };
        // change_email - Widok zmiany email
        case type === 'change_email' && value === '':
            return {
                route: ROUTES.ACCOUNT_CHANGE_EMAIL,
                contextType: 'change_email',
                contextValue: '',
            };

        // change_password - Widok zmiany hasla
        case type === 'change_password' && value === '':
            return {
                route: ROUTES.ACCOUNT_CHANGE_PASSWORD,
                contextType: 'change_password',
                contextValue: '',
            };
        //set_password-Widok ustawiania hasła dla kont z wygenerowanym hasłem (np. Facebook)
        case type === 'set_password' && value === '':
            return {
                route: ROUTES.ACCOUNT_CHANGE_PASSWORD,
                contextType: 'set_password',
                contextValue: '',
            };
        //reset_password
        case type === 'reset_password' && value === '':
            return {
                route: ROUTES.RESET_PASSWORD,
                contextType: 'reset_password',
                contextValue: '',
            };
        //delete_account - Widok usuwania konta

        case type === 'delete_account' && value === '':
            return {
                route: ROUTES.ACCOUNT_DELETE,
                contextType: 'delete_account',
                contextValue: '',
            };
        //enter_code
        case type === 'enter_code' && value === '':
            return {
                route: ROUTES.HAVE_CODE,
                contextType: 'enter_code',
                contextValue: '',
            };
        // my_purchases - 	Widok dostępów użytkownika
        case type === 'my_purchases' && value === '':
            return {
                route: ROUTES.ACCOUNT_MY_PURCHASES,
                contextType: 'my_purchases',
                contextValue: '',
            };
        //newsletter -Widok opcji Newslettera (zapisanie/wypisanie)
        //TODO czy  mamy widok newsletter
        // reset_settings
        case type === 'reset_settings' && value === '':
            return {
                route: ROUTES.ACCOUNT_RESTORE_SETTINGS,
                contextType: 'reset_settings',
                contextValue: '',
            };
        //plus_connect- Widok wiązania konta z kontem Plus
        case type === 'plus_connect' && value === '':
            return {
                route: ROUTES.PLUS_ZONE_ADD_PHONE,
                contextType: 'plus_connect',
                contextValue: '',
            };
        //plus_disconnect-Widok odwiązywania konta Plus
        case type === 'plus_disconnect' && value === '':
            return {
                route: ROUTES.PLUS_ZONE_REMOVE_PHONE,
                contextType: 'plus_disconnect',
                contextValue: '',
            };
        //sso_connect
        case type === 'sso_connect' && value === '':
            return {
                route: ROUTES.IPOLSATBOX_CONNECT,
                contextType: 'sso_connect',
                contextValue: '',
            };
        //sso_disconnect
        case type === 'sso_disconnect' && value === '':
            return {
                route: ROUTES.IPOLSATBOX_DISCONNECT,
                contextType: 'sso_disconnect',
                contextValue: '',
            };

        //facebook_connect
        case type === 'facebook_connect' && value === '':
            return {
                route: ROUTES.FACEBOOK_CONNECT,
                contextType: 'facebook_connect',
                contextValue: '',
            };
        //facebook_disconnect
        case type === 'facebook_disconnect' && value === '':
            return {
                route: ROUTES.FACEBOOK_DISCONNECT,
                contextType: 'facebook_disconnect',
                contextValue: '',
            };
        //regulations- Widok regulaminów
        case type === 'regulations' && value === '':
            return {
                route: ROUTES.RULES,
                contextType: 'regulations',
                contextValue: '',
            };
        //unaccepted_rules

        case type === 'unaccepted_rules' && value === '':
            return {
                route: ROUTES.LOGIN_RULES_OBLIGATORY,
                contextType: 'unaccepted_rules',
                contextValue: '',
            };
        // user_rules
        case type === 'user_rules' && value === '':
            return {
                route: ROUTES.CONSENTS_LIST,
                contextType: 'user_rules',
                contextValue: '',
            };
        //transboundary - Widok weryfikacji numeru telefonu - transgraniczność
        case type === 'transboundary' && value === '':
            return {
                route: ROUTES.EU_ACCESS,
                contextType: 'transboundary',
                contextValue: '',
            };
        //logout-Widok potwierdzający wylogowanie
        case type === 'logout' && value === '':
            return {
                route: ROUTES.ACCOUNT_LOGOUT,
                contextType: 'logout',
                contextValue: '',
            };
        //help-	Widok pomocy
        case type === 'help' && value === '':
            return {
                route: ROUTES.CUSTOMER_CARE_HELP,
                contextType: 'help',
                contextValue: '',
            };
        //start
        case type === 'start' && value === '':
            return {
                route: ROUTES.WELCOME,
                contextType: 'start',
                contextValue: '',
            };
        default:
            return { route: ROUTES.CUSTOMER_CARE_HELP, contextType: '', contextValue: '' };
    }
};

export const findHelpConfigParamsByRoute = (
    route: ROUTES,
    data?: { availableArticleHelpContexts: [{ type: string; value: string }] },
    id?: string,
    paymentStep?: string,
    paymentType?: string,
) => {
    let configParams = data?.availableArticleHelpContexts
        .map((el: { type: string; value: string }) => {
            return checkRoutes(el.type, el.value);
        })
        .find((el: IConfig) => el?.route === route);

    let showButton = configParams?.contextType !== undefined && route !== ROUTES.CUSTOMER_CARE_HELP;

    if (id) {
        configParams = data?.availableArticleHelpContexts
            .map((el: { type: string; value: string }) => {
                return checkRoutes(el.type, el.value);
            })
            .find((el: any) => el?.contextValue === id);

        showButton = configParams?.contextType !== undefined && route !== ROUTES.CUSTOMER_CARE_HELP;
    }

    if (paymentStep === PAYMENT_STEPS.STEP_1) {
        return {
            contextType: 'payments_access_option',
            contextValue: '',
            showButton,
        };
    }
    if (paymentStep === PAYMENT_STEPS.STEP_2) {
        return {
            contextType: 'payments_payment_method',
            contextValue: '',
            showButton,
        };
    }
    if (paymentStep === PAYMENT_STEPS.STEP_3) {
        return {
            contextType: 'payments_order_summary',
            contextValue: '',
            showButton,
        };
    }
    if (paymentStep === PAYMENT_STEPS.STEP_4 && paymentType !== undefined) {
        const updatedData = buildContextFromPaymentType(paymentType);

        return {
            contextType: updatedData?.contextType || '',
            contextValue: updatedData?.contextValue || '',
            showButton,
        };
    }

    return {
        contextType: configParams?.contextType || '',
        contextValue: configParams?.contextValue || '',
        showButton,
    };
};

const buildContextFromPaymentType = (paymentType: string) => {
    switch (paymentType) {
        case 'dotpay-blik':
            return { contextType: 'payments_dotpay_blik', contextValue: '' };

        case 'sms':
            return { contextType: 'payments_sms', contextValue: '' };

        case 'plusbill':
            return { contextType: 'payments_plusbill', contextValue: '' };

        case 'cpwallet':
            return { contextType: 'payments_cp_wallet', contextValue: '' };

        case 'dotpay-card':
            return { contextType: 'payments_dotpay_card', contextValue: '' };

        case 'dotpay':
            return { contextType: 'payments_dotpay', contextValue: '' };
        default:
            return { contextType: 'payments_payment_method', contextValue: '' };
    }
};
