import styled, { css } from 'styled-components';
import ProgressBar from '@/components/shared/ProgressBar';
import { Z_INDEX } from '@/targets/default/theme';
import { device } from '@/targets/default/mediaQuery';

export const MetaDataInsideTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.META_DATA};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.9) 13%,
        rgba(0, 0, 0, 0.9) 100%
    );
`;

export const MetaDataInside = styled.div<{ active?: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.META_DATA};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 0%,
        rgba(0, 0, 0, 0.9) 13%,
        rgba(0, 0, 0, 0) 100%
    );

    ${({ active }) => {
        return (
            active &&
            css`
                border: ${({ theme }) => theme.listElement.activeBorder};
                border-radius: 0.5rem;
            `
        );
    }}

    > div {
        margin-left: 1.1rem;
        margin-bottom: 0.8rem;

        @media ${device.mobileM} {
            margin-bottom: 0.7rem;
        }

        @media ${device.tablet} {
            margin-left: 1.5rem;
            margin-bottom: 1.1rem;
        }
    }
`;

export const MetaDataInner = styled.div`
    margin-top: 0.8rem;

    :last-child > div {
        margin-top: 0.4rem;

        @media ${device.tablet} {
            margin-top: 0.3rem;
        }

        @media ${device.desktop} {
            margin-top: 0.4rem;
        }
    }
`;

export const MetaDataInnerTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
`;

export const MetadataWithBadge = styled.div`
    align-items: center;
    color: ${({ theme }) => theme.text.color.metadata};
    display: flex;
    white-space: nowrap;
    @media (max-width: 339px) {
        letter-spacing: -0.5px;
    }
`;

export const ListElementProgressBarWrapper = styled(ProgressBar)`
    ${({ progress }) =>
        progress &&
        css`
            margin-bottom: -0.3rem;
        `}
`;
