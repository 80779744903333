import { useBuy } from '@/api/payments/use-buy';
import { useGetOptionData } from '@/api/payments/use-get-option-data';
import { ICONS } from '@/components/shared/Icon';
import { LoadingWrapper } from '@/components/shared/Loader';
import { Message } from '@/components/shared/Message';
import { PAYMENT_STATUS } from '@/interfaces/payments.interface';
import t from '@/lib/i18n';
import React, { useEffect } from 'react';
import { useCheckOrderStatus } from '@/hooks/use-check-order-status';
import { SmsContainer, SmsContent, SmsFooter } from './styles';
import { SmsProps } from './types';
import {
    buildBuyParams,
    buildOptionDataParams,
} from '@/helpers/payments/request-params-builder.helper';

export const Sms = ({
    product,
    offer,
    option,
    orderId,
    onPaymentSuccess,
    onPaymentError,
}: SmsProps) => {
    const optionDataParams = buildOptionDataParams(product, offer, option, orderId);
    const { data: optionData, isFetching: isOptionDataFetching } = useGetOptionData(
        optionDataParams,
    );

    const buyParams = optionData && buildBuyParams(product, offer, option, orderId);
    const { data: buy, isFetching: isBuyFetching } = useBuy(buyParams);
    const isBuySuccess = buy?.status === 1;

    const orderStatusParams = isBuySuccess ? orderId : undefined;
    const { paymentStatus, possessionId } = useCheckOrderStatus(orderStatusParams);

    const smsData = optionData?.getSmsData();
    const smsText = smsData?.smsText;
    const smsNumber = smsData?.smsNumber;

    const isFetching = isOptionDataFetching || isBuyFetching || false;
    const isPaymentNotAvailable = !isFetching && (!isBuySuccess || !smsText || !smsNumber);

    useEffect(() => {
        if (isPaymentNotAvailable) {
            onPaymentError();
        }
    }, [isPaymentNotAvailable]);

    useEffect(() => {
        if (paymentStatus === PAYMENT_STATUS.SUCCESS && possessionId) {
            onPaymentSuccess(possessionId);
        }

        if (paymentStatus === PAYMENT_STATUS.ERROR) {
            onPaymentError();
        }
    }, [paymentStatus, possessionId]);

    return (
        <LoadingWrapper isFetching={isFetching} calculateBottom={false}>
            <SmsContainer>
                <SmsContent>
                    <Message
                        icon={ICONS.INFO_CIRCLE}
                        innerHtmlTitle={t('payment-steps.sms-content', {
                            smsText: `<span>${smsText}</span>`,
                            smsNumber: `<span>${smsNumber}</span>`,
                        })}
                    />
                </SmsContent>

                <SmsFooter>{t('payment-steps.sms-footer')}</SmsFooter>
            </SmsContainer>
        </LoadingWrapper>
    );
};
