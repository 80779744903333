import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { VerifyOut } from '@/interfaces/from-schemas/auth/verifyOut';

interface IVerifyUser {
    msisdn: string;
}

const useVerify = () => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.VERIFY_USER, null);

    return useGetMediaApiMutation<IVerifyUser, VerifyOut>(keyHook, {
        appendAuthData: true,
    });
};

export default useVerify;
