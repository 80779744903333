import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { ADDITIONAL_TYPES } from '@/types/page-catalog.type';
import { CatalogView } from '@/components/views/common';
import useGetPacketTreeNavigation from '@/api/packets/use-get-packet-tree-navigation';
import { FilterWithTreeNavigationSchema } from '@/interfaces/from-schemas/navigation/searchContentWithTreeNavigationOut';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useError } from '@/contexts/error/error.context';

interface IRouteParams {
    packetId: string;
    categoryId: string;
}

const PacketCatalog = ({ filter }: { filter: FilterWithTreeNavigationSchema }) => {
    const { packetId } = useParams<IRouteParams>();
    const { name, value, type, treeNavigation } = filter;

    const catalogConfig = useMemo(
        () =>
            buildCatalogConfig(ADDITIONAL_TYPES.PACKET, {
                activeCategory: { name, value, type },
                packetId,
                collection: treeNavigation?.collections?.find((nav) => nav.default),
            }),
        [packetId, name, value, type],
    );

    return <CatalogView config={catalogConfig} />;
};

const PacketContentPage = () => {
    const { packetId, categoryId } = useParams<IRouteParams>();

    const packetTreeParams = { packetCode: packetId, query: '' };
    const {
        data: packetFilters,
        isFetching: isFetchingFilters,
        error,
    } = useGetPacketTreeNavigation(packetTreeParams);
    const { setError } = useError();

    const filter = packetFilters?.find((f) => f.value === categoryId);

    if (error) {
        setError(error);
    }
    return (
        <BaseLayout fullscreen>
            {isFetchingFilters && <ProcessingLoader isFetching={true} />}
            {filter && <PacketCatalog filter={filter} />}
        </BaseLayout>
    );
};

export default PacketContentPage;
