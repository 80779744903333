import { GM_OS } from '@/types/platform.type';
import { MatchMap } from './types';

export const getMatch = <T>(map: MatchMap<T>, userAgent: string, os?: GM_OS): T | undefined => {
    const [, result] =
        map.find(([tester]) =>
            tester instanceof RegExp ? userAgent.match(tester) : tester === os,
        ) || [];
    return result;
};
