import { useInterval } from '@/hooks/use-interval.hook';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { IconCastContainer } from './styles';
import { ICON_MODE, IconCastProps } from './types';

const WAITING_MODE_ANIMATION_INTERVAL = 200; //ms

export const IconCast = ({ children, iconMode = ICON_MODE.IDLE }: IconCastProps) => {
    const [activeBowIndex, setActiveBowIndex] = useState<number>(0);

    const runWaitingModeHandler = useCallback(() => {
        let bowIndex = activeBowIndex + 1;

        if (bowIndex > 3) {
            bowIndex = 1;
        }

        setActiveBowIndex(bowIndex);
    }, [activeBowIndex]);

    useInterval(
        runWaitingModeHandler,
        iconMode === ICON_MODE.WAITING ? WAITING_MODE_ANIMATION_INTERVAL : null,
    );

    return (
        <IconCastContainer activeBowIndex={activeBowIndex} mode={iconMode}>
            {children}
        </IconCastContainer>
    );
};
