import { useRouter } from '@/routing';
import { PATHS, ROUTES } from '@/routing/types';
import { size } from '@/targets/default/mediaQuery';
import { useEffect, useState } from 'react';
import config from 'target/config';

const ChatRoutesWhiteList = [ROUTES.PURCHASE, ROUTES.PACKETS, PATHS.PACKET_DETAILS];

export const useChat = () => {
    const router = useRouter();
    const [isVisible, setIsVisible] = useState(false);
    const [isActiveSession, setIsActiveSession] = useState(false);
    const routeName = router.route.slice(1) as ROUTES;
    const isMobile = window.innerWidth <= size.tablet;
    const hasConfig = config.chat;

    const setVisible = (value: boolean) => {
        const visible = (value && !isMobile) || isActiveSession;

        setIsVisible(hasConfig && visible);
    };

    useEffect(() => {
        setVisible(ChatRoutesWhiteList.includes(routeName));
    }, [router.route, isActiveSession]);

    return { isVisible, setVisible, isActiveSession, setIsActiveSession };
};
