import { isServer } from '@/helpers/environment.helper';
import { useSingleRef } from '@/hooks/use-single-ref.hook';
import { RefObject, useEffect, useState } from 'react';

export const useIsOutOfViewport = (elem: RefObject<HTMLElement | undefined>) => {
    const [isOutOfViewport, setIsOutOfViewport] = useState<boolean>(false);

    const observer = useSingleRef(() =>
        !isServer
            ? new IntersectionObserver(([entry]) => setIsOutOfViewport(!entry.isIntersecting))
            : undefined,
    );

    useEffect(() => {
        if (!isServer) {
            const el = elem && elem.current;

            if (!el) return;

            observer?.observe(el);

            return () => {
                observer?.unobserve(el);
            };
        }
    }, [elem.current, observer]);

    return { isOutOfViewport };
};
