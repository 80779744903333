import { DATA_POSITION, DISPLAY_DATA, TITLE_TYPE } from '../../types/list-element-metadata.type';
import { TARGET_LOCALE } from '../../types/date.type';
import { GET_MEDIA_PLACE_TYPE } from '../../types/get-media-params.type';
import { HOME_MENU_STATIC_ICONS } from '../../types/home-menu-static-icons.type';
import { MULTIPLE_PRODUCTS_SUBTYPE } from '../../types/media.type';
import { GM_APPLICATION, GM_DEVICE_TYPE, GM_OS, GM_PLAYER } from '../../types/platform.type';
import { TargetConfig } from '../../types/target-config.type';
import { THEME_TYPE } from '../../types/theme.type';
import { ROUTES, ZONES } from '../../routing/types';

const defaultConfig: TargetConfig = {
    portal: 'pbg',
    locale: TARGET_LOCALE.PL,
    // Adres do GetMedia
    getMediaRpcUrl: 'https://b2c-www.redefine.pl/rpc',
    // Wewnętrzy adres do GetMedia wykonywany wyłącznie po stronie serwera
    getMediaServerRpcUrl: 'http://b2c-internal.redefine.pl/rpc',
    features: {
        consoleBuildInfo: true,
        related_vods: false,
        uadPlatformDetection: true,
        voiceSearch: false,
    },
    user: {
        profilesEnabled: true,
        maxProfiles: 5,
        maxProfileNameLength: 20,
        maxProfileShortNameLength: 12,
        askProfileInterval: 24,
    },
    loginByCode: {
        maxCodeLength: 50,
    },
    analytics: {
        gtmId: 'GTM-W82VVXR',
    },
    rules: {
        displayUrl: 'polsatboxgo.pl/panel/regulaminy',
    },
    defaultUserData: {
        // ten UAD jest wysyłany podczas SSR niezależnie od detekcji
        userAgentData: {
            portal: 'pbg',
            deviceType: GM_DEVICE_TYPE.PC,
            application: GM_APPLICATION.Browser,
            player: GM_PLAYER.HTML5,
            build: 1,
            os: GM_OS.All,
            osInfo: '',
        },
        ua: 'www_iplatv/12345',
        clientId: 'd5efb74e-37dc-4378-90cb-a9582c14fc3d',
    },
    stats: {
        enableActivityEvents: true,
        sessionIdExpiration: 7200000,
        debug: true,
    },
    authProviders: [],
    redirectRouteZones: [ZONES.LOGIN_TO_CONTINUE],
    slowDevice: false,
    pages: {
        timeout: 400,
        collections: {
            initialLimit: 2,
            increaseBy: 2,
        },
        catalog: {
            initialLimit: 40,
            increaseBy: 20,
        },
        epg: {
            initialLimit: 40,
            mobileInitialLimit: 10,
        },
        home: {
            placeType: GET_MEDIA_PLACE_TYPE.HOMEPAGE,
        },
        category: {
            placeType: GET_MEDIA_PLACE_TYPE.CATEGORY,
        },
        live: {
            placeType: GET_MEDIA_PLACE_TYPE.LIVE_CHANNELS,
        },
        packets: {
            placeType: GET_MEDIA_PLACE_TYPE.PACKETS,
            subType: MULTIPLE_PRODUCTS_SUBTYPE.PACKET,
        },
        packet: {
            placeType: GET_MEDIA_PLACE_TYPE.PACKETS,
            subType: MULTIPLE_PRODUCTS_SUBTYPE.PACKET,
            singlePacket: true,
        },
        channels: {
            placeType: GET_MEDIA_PLACE_TYPE.CHANNELS_TV,
        },
        favorites: {
            placeType: GET_MEDIA_PLACE_TYPE.FAVORITES,
        },
        search: {
            placeType: GET_MEDIA_PLACE_TYPE.SEARCH,
            minQueryLength: 2,
            defaultGridCols: 4,
            resultsLimit: 50,
            resultsTimeout: 800,
        },
        events: {
            placeType: GET_MEDIA_PLACE_TYPE.CATEGORY,
        },
        offer: {
            placeType: GET_MEDIA_PLACE_TYPE.OFFER,
        },
        minPasswordLength: 6,
    },
    playableButton: {
        isTransparentOnInactive: false,
    },
    theme: {
        defaultTheme: THEME_TYPE.DARK,
        showButtons: true,
    },
    lazyLoadingImages: {
        enabled: true,
        initialLoaded: {
            posters: 8,
            thumbnails: 5,
        },
    },
    slider: {
        initialLimit: 14,
    },
    grid: {
        col: 4,
        postersCol: 7,
    },
    loader: {
        main: {
            delay: 2000,
            dots: 5,
        },
        wrapper: {
            delay: 200,
            dots: 5,
        },
        processing: {
            delay: 500,
            dots: 5,
        },
    },
    imageSize: {
        background: {
            width: 1920,
            height: 1080,
        },
        thumbnails: {
            width: 282,
            height: 154,
        },
        posters: {
            width: 282,
            height: 397,
        },
    },
    mediaDetails: {
        shortDescriptionLines: 2,
        fullDescriptionLines: 99,
        isFavoriteButtonOnTvChannelsVisible: true,
        fullDetailsTitleLines: 4,
    },
    packetDetails: {
        fullDescriptionLines: 99,
        fullDetailsTitleLines: 4,
    },
    player: {
        script: 'https://redirector.redefine.pl/versions/cp-player-2.js',
        seek: {
            resetTimeout: 1000,
            step: 6,
            singleClickModeStep: 10,
            timeTargetsInterval: 500,
            emitDataIntervalRatio: 2.9,
            resetDataTimeout: 500,
            speedLevelsLive: [100, 120, 180, 200, 400, 600, 800, 1000, 1200, 1400, 1600],
            speedLevelsVod: [500, 1000, 1500],
            startSeekingInterval: 180,
            minSeekingIntervalLive: 1, //ms
            minSeekingIntervalVod: 2, //ms
            isLiveDelta: 20, //sec
        },
        transition: {
            idleDelay: 3000,
            visibilityDelay: 5000,
        },
        updatePlaybackTimeDelay: 2000,
        error: {
            helpPageUrl: 'https://pomoc.polsatboxgo.pl/www',
            helpPageUrlLabel: 'pomoc.polsatboxgo.pl/www',
            showQRCode: false,
        },
    },
    timeshifting: {
        excludedOS: [GM_OS.iOS],
        excluededBrowsers: [GM_APPLICATION.Safari],
        minLengthSupported: 300,
    },
    menu: {
        pre: [HOME_MENU_STATIC_ICONS.HOME],
        post: [],
    },
    paymentsPath: {
        supportedPaymentMethods: ['dotpay-blik', 'dotpay-card', 'payu-blik', 'sms', 'plusbill'],
    },
    contacts: {
        helpdeskPage: {
            label: 'pomoc.polsatboxgo.pl',
            url: 'https://pomoc.polsatboxgo.pl/',
        },
        helpdeskEmail: {
            label: 'pomoc@polsatboxgo.pl',
            url: 'pomoc@polsatboxgo.pl',
        },
        companyName: 'Cyfrowy Polsat S.A.',
        addressFirstLine: 'ul. Łubinowa 4a',
        addressSecondLine: '03-878 Warszawa',
        krs: 'KRS 0000010078',
        nip: 'NIP 796-18-10-732',
    },
    metadataSettings: {
        position: {
            channelCatalog: DATA_POSITION.UNDER,
            favoritesCatalog: DATA_POSITION.UNDER,
            liveCatalog: DATA_POSITION.UNDER,
            searchResultCatalog: DATA_POSITION.UNDER,
            seasonsList: DATA_POSITION.UNDER,
            subCategoryCatalog: DATA_POSITION.INSIDE,
        },
        display: {
            categoryCatalog: DISPLAY_DATA.ONLY_TITLE,
        },
        titleType: {
            seasonsList: TITLE_TYPE.FULL,
        },
    },
    userSessionCookieExpirationTime: 1000 * 60 * 60 * 24 * 30, // 30 dni
    checkCode: {
        codeStatusExpired: -3,
    },
    enterCode: {
        maxCodeLength: 50,
    },
    iod: {
        email: 'iod@cyfrowypolsat.pl',
    },
    isChangeEmailAvailable: true,
    isChangePasswordAvailable: true,
    isDeleteAccountAvailable: true,
    loginRoute: ROUTES.LOGIN_NATIVE,
    isCancelLoginButtonAvailable: true,
    isEuVerificationAvailable: true,
    showWelcomeLogo: false,
    logoAltText: 'Polsat Box GO',
    magicLink: {
        backgroundColor: '#00283C',
    },
    offerCategory: {
        redirect: ROUTES.PACKETS,
    },
    isTargetClosed: false,
    isFavoritesButton: true,
    isCustomerCare: true,
    onetrust: {
        webos: {
            key: '4f9f5e0a-48e6-4cae-9041-d67dfe15885c',
            languageCode: 'en',
            storageLocation: 'cdn.cookielaw.org',
            countryCodeOverride: '',
            regionCodeOverride: '',
            identifier: '',
            version: '202403.2.0',
            syncProfileAuth: '',
            sdkUrl: 'https://redirector.redefine.pl/versions/onetrust-webos.js',
        },
        tizen: {
            key: 'a963eee9-ec17-4540-a96b-711fd0e9f1d6',
            languageCode: 'en',
            storageLocation: 'cdn.cookielaw.org',
            countryCodeOverride: '',
            regionCodeOverride: '',
            identifier: '',
            version: '202403.2.0',
            syncProfileAuth: '',
            sdkUrl: 'https://redirector.redefine.pl/versions/onetrust-tizen.js',
        },
    },
};

export default defaultConfig;
