import { Nav } from 'nav-tree';
import styled from 'styled-components';

import { fillIcon } from '@/helpers/fill-icon.helper';

import { InfoTextContainerProps, InfoTextProps } from './types';

export const MediaDetailsContainer = styled(Nav)`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const ContentContainer = styled.div`
    position: absolute;
    bottom: 14rem;
    left: 15rem;
    right: 45rem;
`;

export const MetadataContainer = styled.div`
    align-items: center;
    display: flex;
    margin-top: 2rem;
`;

export const MetaDataIconsWrapper = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 0.7rem;
    }
`;

export const MetadataWrapper = styled.div``;

export const MetadataIconWrapper = styled.div`
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 0.7rem;
    }

    &:not(:empty) + ${MetadataWrapper} {
        margin-left: 1.8rem;
    }
`;

export const LicenseAccessDurationWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-left: 1.8rem;

    ${MetadataWrapper} {
        margin-left: unset;
    }
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    margin-top: 2.5rem;
`;

export const ProgressContainer = styled.div`
    display: flex;
    align-items: center;
    width: 50rem;
    margin: 2rem 0;
`;

export const ProgressBarFmcWrapper = styled.div`
    display: inline-block;
    width: 29rem;
    margin-left: 1.5rem;
`;

export const ProgressBarContainer = styled.div`
    flex: 1;
    margin-left: 1.5rem;
`;

export const InfoTextContainer = styled.div<InfoTextContainerProps>`
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
    margin-right: ${({ largeMargin }) => (largeMargin ? 6 : 1)}rem;
`;

export const InfoText = styled.div<InfoTextProps>`
    color: ${({ highlight, theme }) =>
        highlight ? theme.text.color.primary : theme.text.color.secondary};
    font-size: 2rem;
    font-weight: 400;
    margin-left: 0.5rem;
    white-space: nowrap;
`;

export const IconWrapper = styled.div`
    ${({ theme }) => fillIcon(theme.mediaDetails.iconColor)};
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
`;

export const TableWrapper = styled.div`
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.1;
    margin-bottom: -1rem;
    margin-left: 8rem;
`;

export const TableDetailsTitle = styled.td`
    color: ${(props) => props.theme.text.color.metadataSmall};
    text-transform: uppercase;
    min-width: 26rem;
    display: flex;
    padding-bottom: 1rem;
`;

export const TableDetailsInfo = styled.td`
    color: ${(props) => props.theme.text.color.primary};
    padding-bottom: 1rem;
`;
