import { calculateProgress } from '@/helpers/calculate-progressbar-for-channel.helper';
import { formatDate } from '@/helpers/date.helper';
import { DISPLAY_DATA } from '@/types/list-element-metadata.type';
import { ChannelModel } from '@/models/channels/channel.model';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { SearchContentModel } from '@/models/search/search-content.model';
import ChannelListModel from '@/models/channels/channel-list.model';
import PacketContentWithFlatNavigationModel from '@/models/packets/packet-content-with-flat-navigation.model';

const { searchResultCatalog: dataPosition } = process.env.metadataSettings.position;

interface ISettings {
    showDetailedMetadata?: boolean;
    showMetadata?: boolean;
}

export const buildElementsList = (
    results: ChannelListModel | PacketContentWithFlatNavigationModel | SearchContentModel,
    currentProgramsList?: { [key: string]: ChannelProgramTvModel[] },
    settings: ISettings = {},
) => {
    const { showDetailedMetadata, showMetadata } = settings;
    const formatTime = (time: string) => formatDate(time, FORMAT_DATE_MODE.HOURS);

    if (results?.getResults().length) {
        return results.getResults().map((resultsElement) => {
            if (
                resultsElement instanceof ChannelModel &&
                currentProgramsList &&
                Object.keys(currentProgramsList).length
            ) {
                const id = Number(resultsElement.getId());
                const programs = currentProgramsList[id];

                if (programs) {
                    const firstProgram = programs[0];
                    const secondProgram = programs[1];
                    let endTime = '';

                    if (secondProgram) {
                        endTime = secondProgram.getStartTime();
                    }

                    if (firstProgram) {
                        const startTime = firstProgram.getStartTime();
                        const metaData = `${formatTime(startTime)} - ${formatTime(endTime)}`;

                        resultsElement.setMetaData(metaData);
                        resultsElement.setProgress(calculateProgress({ startTime, endTime }));
                        resultsElement.setIsLive(firstProgram.getIsLive());
                        resultsElement.setDescription(firstProgram.getDescription());
                        resultsElement.setGenre(firstProgram.getGenres());
                        resultsElement.setTitle(firstProgram.getTitle());
                    }
                }
            }

            const listElement = new ListElementModel(resultsElement);
            listElement.setDataPosition(dataPosition);
            showDetailedMetadata && listElement.setShowDetailedMetadata(true);
            showMetadata && listElement.setShowMetadata(true);
            listElement.getProgress() && listElement.setVisibilityProgressBar(true);
            listElement.setDisplayDataType(DISPLAY_DATA.BOTH);

            return listElement;
        });
    }

    return [];
};
