import { GetMediaPromiseFunc } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';

const namespace = GET_MEDIA_NAMESPACES.SYSTEM;
const method = GET_MEDIA_METHODS.GET_CLIENT_ID;

export const getClientIdPromise = (getMediaApiPromise: GetMediaPromiseFunc): Promise<string> => {
    const keyHook = defaultKeyHook(namespace, method, null);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export const useGetClientId = (enabled = false) => {
    const keyHook = defaultKeyHook(namespace, method, enabled ? {} : null);

    const options = {
        appendAuthData: true,
    };

    return useGetMediaApi(keyHook, options);
};
