import useGetMediaRelatedContent from '@/api/navigation/use-get-media-related-content';
import { usePlayerMediaDetails } from '@/hooks/player/use-player-media-details';
import React from 'react';
import { MediaRelatedListWrapper, MediaRelatedNav } from './styles';
import { navHorizontal } from 'nav-tree';
import { ProcessingLoader } from '../Loader/ProcessingLoader';
import { MediaRelatedListSlider } from './MediaRelatedListSlider';

const MediaRelatedListRaw = () => {
    const mediaModel = usePlayerMediaDetails();
    const mediaRelatedParams = mediaModel?.id
        ? {
              mediaId: mediaModel.id,
              cpid: mediaModel.cpid as 0 | 1,
          }
        : undefined;

    const { data: mediaRelatedList, isFetching } = useGetMediaRelatedContent(mediaRelatedParams);

    return (
        <MediaRelatedListWrapper>
            {isFetching && <ProcessingLoader isFetching />}

            {mediaRelatedList.length > 0 && (
                <MediaRelatedNav func={navHorizontal}>
                    <MediaRelatedListSlider mediaRelatedList={mediaRelatedList} />
                </MediaRelatedNav>
            )}
        </MediaRelatedListWrapper>
    );
};

export const MediaRelatedList = React.memo(MediaRelatedListRaw);
