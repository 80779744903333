import React, { useState } from 'react';
import { Column } from './styles';
import { FilterMenuItem } from '../styles';
import { FilterListItemOwnProps } from './types';
import { SwitchCheckbox } from '@/components/shared/SwitchCheckbox';
import { Nav } from 'nav-tree';

const FilterListItemMemo = ({
    name,
    value,
    checked,
    defaultFocused,
    onAction,
}: FilterListItemOwnProps) => {
    const [active, setActive] = useState(false);

    return (
        <Nav
            onNav={(isActive: boolean) => setActive(isActive)}
            onEnterDown={() => onAction(value)}
            onClick={() => onAction(value)}
            defaultFocused={defaultFocused}
            autofocusOn={['mouseenter', 'focus']}
        >
            <FilterMenuItem active={active}>
                <Column>{name}</Column>
                <Column>
                    <SwitchCheckbox id={name} checked={checked} />
                </Column>
            </FilterMenuItem>
        </Nav>
    );
};

export const FilterListItem = React.memo(FilterListItemMemo);
