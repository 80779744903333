import { LoaderContext } from '@/contexts/loader/loader.context';
import { useState, useEffect, useContext } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';
import { StyledLoader } from './style';
import { ActualQueryInterface } from './types';
import { Dots } from './Dots';
import { getQueryKeyAndStatus, checkIfImportantQueryIsFetching } from './helpers';

export const MainLoader = (): JSX.Element | null => {
    const { dots, delay } = process.env.loader.main;

    const [waited, setWaited] = useState<boolean>(false);
    const [canLoadApp, setCanLoadApp] = useState<boolean>(true);
    const [actualQueries, setActualQueries] = useState<ActualQueryInterface[]>([]);

    const isFetching = useIsFetching();
    const queryClient = useQueryClient();
    const queries = queryClient.getQueryCache().findAll();

    const { showLoader } = useContext(LoaderContext);

    useEffect(() => {
        const canLoad = !checkIfImportantQueryIsFetching(actualQueries);
        setCanLoadApp(canLoad);
    }, [actualQueries]);

    useEffect(() => {
        const appQueries = getQueryKeyAndStatus(queries);
        setActualQueries(appQueries);
    }, [isFetching]);

    useEffect(() => {
        if (isFetching) {
            const timer = setTimeout(() => {
                setWaited(true);
            }, delay);

            return () => {
                setWaited(false);
                clearTimeout(timer);
            };
        }
    }, [isFetching]);

    if ((waited && !canLoadApp) || showLoader) {
        return (
            <StyledLoader visible data-testing="main-loader">
                <Dots dots={dots} />
            </StyledLoader>
        );
    }

    return null;
};
