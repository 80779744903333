import React from 'react';
import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import t from '@/lib/i18n';
import { AdTimeContainer, AdTimeMarker, AdType } from './styles';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { usePlayerTimeMarkers } from '@/hooks/player/use-player-time-markers';

export const AdTime = () => {
    const { ad } = usePlayerStatus();
    const { adTimeLeft } = usePlayerTimeMarkers();
    if (!ad) {
        return null;
    }
    return (
        <AdTimeContainer>
            <AdType>
                {t('player.ads.ad')} {ad.index} {t('player.ads.of')} {ad.pod.adsCount}
            </AdType>
            <AdTimeMarker>
                {formatDate(
                    new Date(0, 0, 0, 0, 0, adTimeLeft).toISOString(),
                    FORMAT_DATE_MODE.MINUTES_SECONDS,
                )}
            </AdTimeMarker>
        </AdTimeContainer>
    );
};
