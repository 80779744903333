import { clearLazyClasses, lazyLoadImages } from '@/utils/lazy-load-images.util';
import { RefObject, useEffect } from 'react';

export const useLazyImages = ({
    ref,
    visibleElements,
    actualIndex,
    enabled,
    deps,
}: {
    ref: RefObject<HTMLDivElement>;
    visibleElements: number;
    actualIndex: number;
    enabled: boolean;
    deps: any;
}) => {
    const { spatialNavigation } = process.env;

    useEffect(() => {
        if (!spatialNavigation) {
            clearLazyClasses(ref);
        }
    }, deps);

    useEffect(() => {
        if (enabled) {
            lazyLoadImages(ref, visibleElements, actualIndex);
        }
    }, [actualIndex, enabled, ...deps]);
};
