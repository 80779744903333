import { layouts } from '@/components/shared/VirtualKeyboard/layout';
import { KeyboardLayout, LAYOUTS } from '@/components/shared/VirtualKeyboard/types';
import { batchedUpdates } from '@/helpers/batched-updates.helper';
import { useCallback, useState } from 'react';

const parseLayout = (layoutMode: LAYOUTS): KeyboardLayout => {
    const layout = layouts[layoutMode] || layouts.default;
    return layout.map((singleRow) => singleRow.split(' '));
};

export const useKeyboardLayout = () => {
    const [layoutName, setLayoutName] = useState<LAYOUTS>(LAYOUTS.DEFAULT);
    const [keyboardLayout, setKeyboardLayout] = useState<string[][]>(parseLayout(layoutName));

    const setLayout = (name: LAYOUTS) => {
        batchedUpdates(() => {
            setLayoutName(name);
            setKeyboardLayout(parseLayout(name));
        });
    };

    const shiftLayoutChangeHandler = useCallback(() => {
        switch (layoutName) {
            case LAYOUTS.DEFAULT:
                return setLayout(LAYOUTS.DEFAULT_SHIFT);
            case LAYOUTS.SPECIAL:
                return setLayout(LAYOUTS.SPECIAL_SHIFT);
            case LAYOUTS.DEFAULT_SHIFT:
                return setLayout(LAYOUTS.DEFAULT);
            case LAYOUTS.SPECIAL_SHIFT:
                return setLayout(LAYOUTS.SPECIAL);
        }
    }, [layoutName]);

    const specialLayoutChangeHandler = useCallback(() => {
        switch (layoutName) {
            case LAYOUTS.DEFAULT:
                return setLayout(LAYOUTS.SPECIAL);
            case LAYOUTS.SPECIAL:
                return setLayout(LAYOUTS.DEFAULT);
            case LAYOUTS.DEFAULT_SHIFT:
                return setLayout(LAYOUTS.SPECIAL_SHIFT);
            case LAYOUTS.SPECIAL_SHIFT:
                return setLayout(LAYOUTS.DEFAULT_SHIFT);
        }
    }, [layoutName]);

    return {
        layoutName,
        keyboardLayout,
        shiftLayoutChangeHandler,
        specialLayoutChangeHandler,
    };
};
