import {
    AcquireOptionSchema,
    MinPriceSchema,
} from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { IsTrialAvailableIn } from '@/interfaces/from-schemas/payments/isTrialAvailableIn';
import { OptionIdInterface } from '@/interfaces/product-id-interface';
import { RulesModel } from '../products/rules.model';
import { CallToActionModel, RequiredActionModel } from './call-to-action-model';
import { OptionUpgradeModel } from './option-upgrade.model';
import { PaymentCardModel } from './payment-card.model';

export class PrePurchaseOptionModel {
    private id: AcquireOptionSchema['id'];
    private rules: RulesModel[] = [];
    private type: AcquireOptionSchema['type'];
    private name: AcquireOptionSchema['name'];
    private minPrice: AcquireOptionSchema['minPrice'];
    private minPriceIncludingPricingPlans: AcquireOptionSchema['minPriceIncludingPricingPlans'];
    private upgrade?: OptionUpgradeModel;
    readonly callToActions?: CallToActionModel[] = [];

    constructor(definition: AcquireOptionSchema) {
        this.id = definition.id;
        this.type = definition.type;
        this.name = definition.name;
        this.minPrice = definition.minPrice;
        this.minPriceIncludingPricingPlans = definition.minPriceIncludingPricingPlans;

        if (definition.rules) {
            this.rules = definition.rules.map((rules) => new RulesModel(rules));
        }

        if (definition.upgrade) {
            this.upgrade = new OptionUpgradeModel(definition.upgrade);
        }
        if (definition.callToActions) {
            this.callToActions = definition.callToActions.map(
                (callToAction) => new CallToActionModel(callToAction),
            );
        }
    }

    public getId(): string {
        return this.id;
    }

    public getType(): AcquireOptionSchema['type'] {
        return this.type;
    }

    public getOptionId(): OptionIdInterface {
        return {
            id: this.id,
            type: this.type,
        };
    }

    public getPaymentCardOptionType(paymentCard?: PaymentCardModel): IsTrialAvailableIn['option'] {
        if (paymentCard) {
            return {
                type: this.type,
                paymentCard: {
                    operator: paymentCard.getOperator(),
                    id: paymentCard.getId(),
                },
            };
        }

        return {
            type: this.type,
        };
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }

    public isRulesExists(): boolean {
        return this.rules.length > 0;
    }

    public getName(): string | undefined {
        return this.name;
    }

    public getMinPrice(): MinPriceSchema | undefined {
        return this.minPrice;
    }

    public getMinPriceText(): string | undefined {
        return this.minPrice?.valueText;
    }

    public getMinPriceTextForPricingPlan(): string | undefined {
        return this.minPriceIncludingPricingPlans?.valueTextIncludingPricingPlans;
    }

    public isSupported(): boolean {
        return process.env.paymentsPath.supportedPaymentMethods.includes(this.type);
    }

    public isPlusbill(): boolean {
        return this.type === 'plusbill';
    }

    public isDotpayCard(): boolean {
        return this.type === 'dotpay-card';
    }

    public isUpgradeAvailable(): boolean {
        return Boolean(this.upgrade);
    }

    public getOptionUpgrade(): OptionUpgradeModel | undefined {
        return this.upgrade;
    }
    public getCallToActions(): CallToActionModel[] | undefined {
        return this.callToActions;
    }
    public getCallToActionsDescriptions(): string | undefined {
        const callToActions = this.callToActions
            ?.map((el) => el.getDescription())
            .filter((item) => item)
            .join(' ');

        return callToActions;
    }

    public isPlusAction(): boolean {
        return this.callToActions?.some((callToAction) => callToAction.isPlusAction()) || false;
    }
}
