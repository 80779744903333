import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { CenteredMessage } from './styles';

export const NoDataMessage = () => {
    return (
        <CenteredMessage>
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={t('pages.vod.no-data.title')}
                description={t('pages.vod.no-data.description')}
            />
        </CenteredMessage>
    );
};
