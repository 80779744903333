import {
    AspectRatio,
    GalleryImageResolutions,
} from '@/interfaces/from-schemas/navigation/getTvChannelsOut';

export const ASPECT_RATIO = {
    SQUARE: '1:1',
    RECTANGLE: '16:9',
} as const;

type AspectRatioType = typeof ASPECT_RATIO[keyof typeof ASPECT_RATIO];

export type CustomParams = {
    display?: 'standard' | 'background' | 'rollOver' | 'hover';
    rollOverEffect?: 'standard' | 'fade' | 'slide';
    backgroundEffect?: 'not_transparent' | 'transparent';
    appTheme?: 'dark' | 'light';
    displayPlace?: 'standard' | 'background';
    keyFrame?: boolean;
};

export type FindGalleryOptions = {
    ratio: AspectRatioType;
    customParams: Partial<CustomParams>;
};

export interface GalleryImageInterface {
    aspect: AspectRatio;
    customParams?: CustomParams;
    resolutions?: GalleryImageResolutions[];
    src: string;
    [k: string]: unknown;
}
