import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton } from '@/components/shared/Buttons';
import { ProcessingLoader } from '@/components/shared/Loader';
import { RuleActionButtonWrapper, RuleButtons } from '@/components/views/Rules/LoginRules/styles';
import { RuleActionsProps } from '@/components/views/Rules/LoginRules/types';
import { useLoginRulesActions } from '@/hooks/auth/use-login-rules-actions.hook';
import t from '@/lib/i18n';
import React, { useMemo } from 'react';

export const RuleActions = ({ rule, onNextRule, onProcessingAction }: RuleActionsProps) => {
    const ruleId = rule.getId();
    const { isLoading, errorMessage, setRuleStatus } = useLoginRulesActions(
        ruleId,
        onProcessingAction,
        onNextRule,
    );

    return useMemo(() => {
        const acceptButton = () => {
            return (
                <RuleActionButtonWrapper>
                    <PlayableButton
                        key={`accept_${rule.getId()}`}
                        text={t('accept-button-label')}
                        onButtonAction={() => setRuleStatus(true)}
                        transparentOnInactive
                    />
                </RuleActionButtonWrapper>
            );
        };

        const notAcceptButton = () => {
            return (
                <RuleActionButtonWrapper>
                    <PlayableButton
                        key={`unaccept_${rule.getId()}`}
                        text={t('unaccept-button-label')}
                        onButtonAction={() => setRuleStatus(false)}
                        transparentOnInactive
                    />
                </RuleActionButtonWrapper>
            );
        };

        const nextButton = () => {
            return (
                <RuleActionButtonWrapper>
                    <PlayableButton
                        key={`next_${rule.getId()}`}
                        text={t('next-button-label')}
                        onButtonAction={onNextRule}
                        transparentOnInactive
                    />
                </RuleActionButtonWrapper>
            );
        };

        if (isLoading) {
            return <ProcessingLoader isFetching={isLoading} />;
        }

        return (
            <>
                <RuleButtons>
                    {!rule.isRequired()
                        ? rule.isRequiredAnswer()
                            ? notAcceptButton()
                            : nextButton()
                        : null}
                    {acceptButton()}
                </RuleButtons>
                {errorMessage && (
                    <AlertMessage userMessage={errorMessage} type={MESSAGE_TYPE.ERROR} />
                )}
            </>
        );
    }, [rule, setRuleStatus, onNextRule, isLoading]);
};
