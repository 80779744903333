import React, { createContext, useContext, useMemo, useState } from 'react';
import { FmcContextProps, FmcContextProviderProps } from './types';
import { useGetLatelyWatchedContentDataList } from '@/api/user-content/use-get-lately-watched-content-data-list';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';

export const FmcContext = createContext<FmcContextProps>({
    followMeContent: [],
    isFetching: false,
    fmcList: undefined,
    setFmcList: () => null,
});

const FmcContextProvider = ({ children }: FmcContextProviderProps) => {
    const { data, isFetching } = useGetLatelyWatchedContentDataList();
    const [fmcList, setFmcList] = useState<StaffRecommendationListModel>();

    return useMemo(
        () => (
            <FmcContext.Provider value={{ followMeContent: data, isFetching, fmcList, setFmcList }}>
                {children}
            </FmcContext.Provider>
        ),
        [data, isFetching, fmcList, children],
    );
};

export const useFmcContext = () => useContext(FmcContext);

export default FmcContextProvider;
