import { Icon, ICONS } from '@/components/shared/Icon';
import { GUTTER } from '@/types/gutter.type';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Error, ErrorWrapper, IconWrapper, Input, InputWrapper, Label, Suffix } from './styles';
import { INPUT_TYPE, PLACEHOLDER_DISPLAY, TextInputOwnProps } from './types';
import { useVirtualKeyboard } from './use-virtual-keyboard.hook';
const portal = process.env.portal;

const TextInput = forwardRef<HTMLInputElement, TextInputOwnProps>(
    (
        {
            name,
            type = INPUT_TYPE.TEXT,
            defaultFocused = false,
            gutter = GUTTER.NORMAL,
            icon,
            value,
            error,
            onChange,
            onFocus,
            func,
            onNav,
            placeholder = '',
            placeholderDisplay = PLACEHOLDER_DISPLAY.LEFT,
            maxLength,
            disabled,
            'data-testing': dataTesting,
            format = (value, cursor) => [value, cursor],
            normalize = (value, cursor) => [value, cursor],
            withFormat = false,
            disableVirtualKeyboard = false,
        }: TextInputOwnProps,
        ref,
    ) => {
        const inputRef = useRef<HTMLInputElement | null>(null);
        const [focus, setFocus] = useState(false);
        const [iconPosition, setIconPosition] = useState(false);
        const [internalValue, setInternalValue] = useState(value);

        useEffect(() => {
            onFocus?.(focus);
        }, [focus, onFocus]);

        const updateInputValue = (value: string) => {
            setInternalValue(onChange ? onChange(value) : value);
        };

        const { enabled: virtualKeyboardEnabled, showKeyboard } = useVirtualKeyboard(
            inputRef,
            updateInputValue,
            format,
            normalize,
        );

        const onInputNav = (isActive: boolean) => {
            if (!isActive) {
                setFocus(false);
            }

            if (onNav) {
                onNav(isActive);
            }
        };
        const renderError = () => {
            return (
                <Error>
                    <ErrorWrapper>
                        <Icon name={ICONS.ALERT} />
                    </ErrorWrapper>
                </Error>
            );
        };
        const renderIcon = (icon: ICONS) => {
            return (
                <Suffix iconPosition={iconPosition} portal={portal}>
                    <IconWrapper focus={focus}>
                        <Icon name={icon} />
                    </IconWrapper>
                </Suffix>
            );
        };

        useEffect(() => {
            if (focus) {
                inputRef?.current?.focus();
            } else {
                inputRef?.current?.blur();
            }

            setIconPosition(value === '' && placeholder !== '' && placeholderDisplay !== 'center');
        }, [focus, value, placeholder, placeholderDisplay]);

        const internalOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;

            // Natywne ograniczenie ilości wpisanych znaków nie działa na HiSense
            // poniżej alternatywne rozwiązanie
            if (maxLength && inputRef.current) {
                const limitedValue = value.substring(0, maxLength);

                value = inputRef.current.value = limitedValue;
            }

            // Formatowanie i normalizacja dla klawiatury ekranowej odbywa się wewnątrz wewnątrz virtual keyboard
            // Na tym poziomie obsługiwany jest tylko natywny input
            if (!virtualKeyboardEnabled && withFormat) {
                const [formatted, cursorPos] = format(
                    value,
                    inputRef.current?.selectionStart ?? value.length,
                );
                onChange?.(formatted);
                setTimeout(() => inputRef.current?.setSelectionRange(cursorPos, cursorPos), 0);
            } else {
                onChange?.(value);
            }
        };

        useEffect(() => {
            setInternalValue(value);
        }, [value]);

        return (
            <Label
                defaultFocused={defaultFocused}
                autofocusOn={['focus', 'mouseenter']}
                func={func}
                onNav={onInputNav}
                onEnterDown={() => setFocus(true)}
                onClick={() => setFocus(true)}
                gutter={gutter}
                data-testing={`label.${dataTesting}`}
            >
                <InputWrapper focus={focus}>
                    {icon && renderIcon(icon)}
                    <Input
                        focus={focus}
                        placeholder={placeholder}
                        placeholderDisplay={placeholderDisplay}
                        type={type}
                        name={name}
                        value={internalValue}
                        onChange={internalOnChange}
                        disabled={disabled}
                        ref={(e) => {
                            if (typeof ref === 'function') {
                                ref(e);
                            }
                            inputRef.current = e;
                        }}
                        onFocus={() => {
                            setFocus(true);

                            if (!disableVirtualKeyboard) {
                                showKeyboard();
                            }
                        }}
                        onBlur={() => {
                            setFocus(false);
                        }}
                        data-testing={`input.${dataTesting}`}
                    />
                    {error && renderError()}
                </InputWrapper>
            </Label>
        );
    },
);

TextInput.displayName = 'TextInput';

export { TextInput };
