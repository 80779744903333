import { GetCategoryContentWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationIn';
import { GetMultipleProductsIn } from '@/interfaces/from-schemas/navigation/getMultipleProductsIn';
import { GetTvChannelsIn } from '@/interfaces/from-schemas/navigation/getTvChannelsIn';
import { GetPacketContentIn } from '@/interfaces/from-schemas/navigation/getPacketContentIn';
import { IPacketCollectionGridProps } from '@/components/browser/shared/PacketDetails/types';
import { DATA_POSITION } from './list-element-metadata.type';

export enum ADDITIONAL_TYPES {
    PACKET = 'packet',
}

export type CatalogParamsType =
    | GetCategoryContentWithFlatNavigationIn
    | GetMultipleProductsIn
    | GetTvChannelsIn
    | GetPacketContentIn;

export interface CatalogFetcherConfig {
    withPagination: boolean;
    withFilters: boolean;
    fallbackMetadataPosition?: DATA_POSITION;
}

export interface CategoryFetcherConfig extends CatalogFetcherConfig {
    catalogParams: GetCategoryContentWithFlatNavigationIn;
}

export interface PacketFetcherConfig extends CatalogFetcherConfig {
    catalogParams: IPacketCollectionGridProps;
}
