import { VirtualKeyboard } from '@/components/shared/VirtualKeyboard/VirtualKeyboard';
import {
    FormatNormalizeFunc,
    InputRefObject,
    KeyboardState,
    SetInputValueCallback,
    VirtualKeyboardContextProps,
    VirtualKeyboardContextProviderProps,
} from '@/contexts/keyboard/types';
import React, { createContext, useEffect, useState } from 'react';
import { usePlatform } from '../platform/platform.hooks';

export const VirtualKeyboardContextContext = createContext<VirtualKeyboardContextProps>({
    enabled: false,
    isVisible: false,
    showKeyboard: () => null,
});

export const VirtualKeyboardContextProvider = ({
    children,
}: VirtualKeyboardContextProviderProps) => {
    const [enabled, setEnabled] = useState(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [keyboardState, setKeyboardState] = useState<KeyboardState>({
        inputRef: null,
        setInputValue: () => null,
    });
    const { platform } = usePlatform();
    const { deviceType } = platform;

    useEffect(() => {
        const { supportedVirtualKeyboardDeviceType } = process.env;

        if (deviceType && supportedVirtualKeyboardDeviceType) {
            setEnabled(supportedVirtualKeyboardDeviceType.includes(deviceType));
        }
    }, [deviceType]);

    const keyboardSetVisible = (value: boolean) => {
        if (!value) {
            setKeyboardState({ ...keyboardState, inputRef: null });
        }

        setIsVisible(value);
    };

    const renderKeyboard = enabled && isVisible;

    const showKeyboard = (
        inputRef: InputRefObject,
        setInputValue: SetInputValueCallback,
        format?: FormatNormalizeFunc,
        normalize?: FormatNormalizeFunc,
    ) => {
        if (enabled) {
            setKeyboardState({
                inputRef,
                setInputValue,
                format,
                normalize,
            });
            keyboardSetVisible(true);
        }
    };

    return (
        <VirtualKeyboardContextContext.Provider value={{ enabled, isVisible, showKeyboard }}>
            {children}
            {renderKeyboard && (
                <VirtualKeyboard setVisible={keyboardSetVisible} {...keyboardState} />
            )}
        </VirtualKeyboardContextContext.Provider>
    );
};
