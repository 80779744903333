import {
    PricingPlanItemSchema,
    PricingPlanSchema,
} from '@/interfaces/from-schemas/navigation/getProductOut';
import { RulesModel } from './rules.model';

export class PricingPlanModel {
    private id: PricingPlanSchema['id'];
    private name: PricingPlanSchema['name'];
    private description: PricingPlanSchema['description'];
    private items: PricingPlanSchema['items'];
    private rules: RulesModel[] = [];

    constructor(definition: PricingPlanSchema) {
        this.id = definition.id;
        this.name = definition.name;
        this.description = definition.description;
        this.items = definition.items;

        if (definition.rules) {
            this.rules = definition.rules.map((rules) => new RulesModel(rules));
        }
    }

    public getId(): string {
        return this.id;
    }

    public getName(): string {
        return this.name;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public getOptionTypes(): PricingPlanItemSchema['optionType'][] {
        return this.items?.map((item) => item.optionType) || [];
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }

    public isRulesExists(): boolean {
        return this.rules.length > 0;
    }

    public isSupported(): boolean {
        const { supportedPaymentMethods } = process.env.paymentsPath;

        return this.getOptionTypes().some(
            (optionType) => optionType && supportedPaymentMethods.includes(optionType),
        );
    }
}
