import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetAllRulesOut } from '@/interfaces/from-schemas/auth/getAllRulesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseGetAllRules {
    rulesType?: GetAllRulesOut[number]['type'];
    context?: string;
}

export const useGetAllRules = (params?: IUseGetAllRules): IApiResponse<GetAllRulesOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.FETCH_ALL_RULES,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook);

    return {
        data: data as GetAllRulesOut,
        error,
        isFetching,
    };
};

export default useGetAllRules;
