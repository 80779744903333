import { useCallback, useState } from 'react';
import { Nav } from 'nav-tree';
import { SCROLL_POSITION } from '@/types/epg-table.type';

const handleCellOpacity = (htmlRef: HTMLElement) => {
    requestAnimationFrame(() => {
        const parentRect = htmlRef.getBoundingClientRect();
        const children = Array.from(htmlRef.children) as HTMLElement[];

        children.forEach((child) => {
            const rect = child.getBoundingClientRect();
            const isVisible = rect.top >= parentRect.top && rect.bottom <= parentRect.bottom;

            if (isVisible) {
                child.style.opacity = '1';
            } else {
                child.style.opacity = '.3';
            }
        });
    });
};

export const useScrollPosition = (
    ref: React.MutableRefObject<Nav | null>,
    isAvailable: boolean,
) => {
    const [scrollPosition, setScrollPosition] = useState<SCROLL_POSITION>(SCROLL_POSITION.TOP);

    const handleNav = useCallback(() => {
        if (!isAvailable) return;
        const tree = ref.current?.getChildContext().tree;
        const htmlRef = tree?.el;

        if (htmlRef) {
            handleCellOpacity(htmlRef);

            const { scrollTop, scrollHeight, clientHeight } = htmlRef;

            if (scrollTop === 0) {
                setScrollPosition(SCROLL_POSITION.TOP);
            } else if (scrollHeight - scrollTop - clientHeight <= 1) {
                setScrollPosition(SCROLL_POSITION.BOTTOM);
            } else {
                setScrollPosition(SCROLL_POSITION.BETWEEN);
            }
        }
    }, [isAvailable, ref]);

    return {
        scrollPosition,
        handleNav,
    };
};
