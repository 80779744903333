import { MessageProps } from './types';
import {
    MessageContainer,
    IconContainer,
    TitleContainer,
    DescriptionContainer,
    SubtitleContainer,
    MESSAGE_SIZE,
    Logo,
} from './styles';
import { Icon } from '../Icon';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useAppContext } from '@/contexts/app/app.context';

const MessageRaw = ({
    icon,
    messageSize = MESSAGE_SIZE.DEFAULT,
    title,
    subtitle,
    description,
    descriptionJSX,
    style,
    gutter = GUTTER.NORMAL,
    innerHtmlTitle,
    innerHtmlSubtitle,
    innerHtmlDescription,
    errorTextColor,
    logo,
    alt,
    className,
}: MessageProps) => {
    const descriptions = !description
        ? []
        : Array.isArray(description)
        ? description
        : [description];
    const innerHtmlDescriptions =
        typeof innerHtmlDescription === 'string' ? [innerHtmlDescription] : innerHtmlDescription;

    const { themeOption } = useAppContext();

    return (
        <MessageContainer gutter={gutter} style={style} className={className}>
            {icon && (
                <IconContainer size={messageSize}>
                    <Icon name={icon} />
                </IconContainer>
            )}

            {title && (
                <TitleContainer size={messageSize} themeOption={themeOption}>
                    {title}
                </TitleContainer>
            )}
            {innerHtmlTitle && (
                <TitleContainer
                    size={messageSize}
                    themeOption={themeOption}
                    dangerouslySetInnerHTML={{ __html: innerHtmlTitle }}
                />
            )}

            {subtitle && <SubtitleContainer size={messageSize}>{subtitle}</SubtitleContainer>}
            {innerHtmlSubtitle && (
                <SubtitleContainer
                    dangerouslySetInnerHTML={{ __html: innerHtmlSubtitle }}
                    size={messageSize}
                />
            )}

            {descriptionJSX}

            {descriptions.map((text) => (
                <DescriptionContainer
                    key={text}
                    errorTextColor={errorTextColor}
                    themeOption={themeOption}
                    size={messageSize}
                >
                    {text}
                </DescriptionContainer>
            ))}
            {innerHtmlDescriptions?.map((innerHtmlDescription, index) => (
                <DescriptionContainer
                    errorTextColor={errorTextColor}
                    key={index}
                    dangerouslySetInnerHTML={{ __html: innerHtmlDescription }}
                    themeOption={themeOption}
                    size={messageSize}
                />
            ))}
            {logo && <Logo alt={alt} src={logo} />}
        </MessageContainer>
    );
};

export const Message = React.memo(MessageRaw);
