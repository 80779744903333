import { GM_DEVICE_TYPE } from '@/types/platform.type';

export function disableNativeKeyboard(deviceType: GM_DEVICE_TYPE): void {
    if (deviceType === GM_DEVICE_TYPE.TV_Hisense) {
        disableHisenseNativeKeyboard();
    }
}

function disableHisenseNativeKeyboard(): void {
    try {
        (window as any).Hisense_disableVKB();
    } catch (e) {
        console.error('DisableHisenseNativeKeyboardError', e);
    }
}
