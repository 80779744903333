import styled, { css } from 'styled-components';
import { Z_INDEX } from '@/targets/default/theme';

interface IShadeProps {
    top?: boolean;
    light?: boolean;
}

export const EdgeShade = styled.div<IShadeProps>`
    z-index: ${Z_INDEX.EDGE_SHADE};
    width: 100vw;
    height: ${({ theme, light }) => (light ? theme.edgeShadeLight.height : theme.edgeShade.height)};
    position: fixed;
    left: 0;
    top: ${({ top }) => (top ? 0 : 'auto')};
    bottom: ${({ top }) => (top ? 'auto' : 0)};
    background: transparent;
    background: ${({ light, top, theme }) => {
        const shade = light ? 'edgeShadeLight' : 'edgeShade';

        return css`
            linear-gradient(
                ${top ? '180deg' : '0deg'},
                ${theme.edgeShade.startColor} ${theme[shade].startStep},
                ${theme.edgeShade.endColor} ${theme[shade].endStep}
            );
        `;
    }};
`;
