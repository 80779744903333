import React, { useRef } from 'react';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { EdgeShade } from '@/components/shared/EdgeShade';
import { FullDetailsWrapperProps } from './types';
import {
    FullDetailsContainer,
    FullDetailsContentContainer,
    MainMetadataContainer,
    StyledBottomMargin,
} from './styles';
import ScrollableContainer from '@/components/shared/ScrollableContainer';

const MIN_FULL_DETAILS_CONTENT_HEIGHT = 700;

export const FullDetailsWrapper = ({ children, table }: FullDetailsWrapperProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const contentHeight = contentRef.current?.clientHeight || 0;
    const withScroll = contentHeight > MIN_FULL_DETAILS_CONTENT_HEIGHT;

    return (
        <FullDetailsContainer>
            <BackButton defaultFocused />

            <FullDetailsContentContainer>
                <MainMetadataContainer ref={contentRef} withScroll={withScroll}>
                    {withScroll ? (
                        <ScrollableContainer fullWidth>
                            <EdgeShade top light />
                            {children}
                            <StyledBottomMargin />
                            <EdgeShade light />
                        </ScrollableContainer>
                    ) : (
                        children
                    )}
                </MainMetadataContainer>
                {table}
            </FullDetailsContentContainer>
        </FullDetailsContainer>
    );
};
