import { KEYS } from './keys';

export const WebOSKeysAndAppKeys = {
    //[KEYS.BACK]: 8,
    //[KEYS.BACK]: 1537,
    [KEYS.BACK]: 461,
    [KEYS.REWIND]: 412,
    [KEYS.PAUSE]: 19,
    [KEYS.FORWARD]: 417,
    [KEYS.PLAY]: 415,
    [KEYS.STOP]: 413,
    [KEYS.CURSOR_SHOW]: 1536,
    [KEYS.CURSOR_HIDE]: 1537,
};
