import { navHorizontal, NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';

/**
 * Vertical row resolve function ('up' and 'down' keys).
 * It will restore focus  for `focusNode` when users returns to parent component.
 * @param key
 * @param navTree
 * @param focusNode Node which should be focused, when user returns to parent component.
 */
export const restoreFocusVertical = (
    key: NAVIGATION_KEYS,
    navTree: NavTree,
    focusNode?: string,
): string | null | false => {
    if (!navTree.focusedNode && focusNode) {
        return focusNode;
    }
    return navVertical(key, navTree);
};

export const restoreFocusHorizontal = (
    key: NAVIGATION_KEYS,
    navTree: NavTree,
    focusNode?: string,
): string | null | false => {
    if (!navTree.focusedNode && focusNode) {
        return focusNode;
    }
    return navHorizontal(key, navTree);
};

export const navVerticalTrap = (key: NAVIGATION_KEYS, current: NavTree) => {
    if (current.focusedNode) {
        const isFirst = current.nodesId[0] === current.focusedNode;
        const isLast = current.nodesId[current.nodesId.length - 1] === current.focusedNode;
        switch (key) {
            case NAVIGATION_KEYS.LEFT:
            case NAVIGATION_KEYS.RIGHT:
                return current.focusedNode;
            case NAVIGATION_KEYS.UP:
                if (isFirst) {
                    return current.focusedNode;
                }
                break;
            case NAVIGATION_KEYS.DOWN:
                if (isLast) {
                    return current.focusedNode;
                }
                break;
        }
    }
    return navVertical(key, current);
};
