import React from 'react';
import { ProgressBarContainer, ProgramProgressContent } from './styles';
import { ProgresBarProps } from './types';

export const ProgressBar = ({ progress, className }: ProgresBarProps) => {
    return (
        <ProgressBarContainer className={className}>
            <ProgramProgressContent progress={progress} />
        </ProgressBarContainer>
    );
};
