import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { AgeRestrictionGridElementInterface } from './types';

export const ageRestrictions: AgeRestrictionGridElementInterface[] = [
    {
        id: 0,
        icon: ICONS.PARENT_CONTROL_0,
        name: t('pages.profile.age-restriction.no-age'),
    },
    {
        id: 7,
        icon: ICONS.PARENT_CONTROL_7,
        name: t('pages.profile.age-restriction.age-7'),
    },
    {
        id: 12,
        icon: ICONS.PARENT_CONTROL_12,
        name: t('pages.profile.age-restriction.age-12'),
    },
    {
        id: 16,
        icon: ICONS.PARENT_CONTROL_16,
        name: t('pages.profile.age-restriction.age-16'),
    },
    {
        id: 18,
        icon: ICONS.PARENT_CONTROL_18,
        name: t('pages.profile.age-restriction.age-18'),
    },
];
