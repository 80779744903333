import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { GetMultipleProductsIn } from '@/interfaces/from-schemas/navigation/getMultipleProductsIn';
import { GetMultipleProductsOut } from '@/interfaces/from-schemas/navigation/getMultipleProductsOut';
import MultipleTypeProductsModel from '@/models/products/multiple-type-products.model';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_MULTIPLE_TYPE_PRODUCTS;

const getMultpleProductsPromise = (
    params: GetMultipleProductsIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetMultipleProductsOut | undefined> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

const useGetMultpleProducts = (
    params: GetMultipleProductsIn | false,
): IApiResponse<MultipleTypeProductsModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);
    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: useMemo(() => new MultipleTypeProductsModel(data), [data]),
        error,
        isFetching,
    };
};

export { getMultpleProductsPromise, useGetMultpleProducts };
