import { useCallback, useEffect, useMemo } from 'react';
import { isServer } from '@/helpers/environment.helper';
import { InputManager } from '@/input';
import { KEYS } from '@/input/keys';

interface ConfigKeyboardEventInterface {
    keys: KEYS[];
    keydownHandler?: (event: KeyboardEvent) => void;
    keyupHandler?: (event: KeyboardEvent) => void;
}

export const useKeyboardEvents = (
    getConfigList: () => ConfigKeyboardEventInterface[],
    deps: any[],
) => {
    const configList = useMemo(getConfigList, deps);

    const findConfig = useCallback(
        (keyCode: number) => {
            return configList.find((config) => {
                const configKeyCode = config.keys.map((key) => InputManager.KEY[key]);
                return configKeyCode.includes(keyCode);
            });
        },
        [configList],
    );

    const keydownListener = useCallback(
        (event: KeyboardEvent) => {
            const config = findConfig(event.keyCode);
            config?.keydownHandler?.(event);
        },
        [findConfig],
    );

    const keyupListener = useCallback(
        (event: KeyboardEvent) => {
            const config = findConfig(event.keyCode);
            config?.keyupHandler?.(event);
        },
        [findConfig],
    );

    useEffect(() => {
        if (!isServer) {
            window.addEventListener('keydown', keydownListener);
            window.addEventListener('keyup', keyupListener);
            return () => {
                window.removeEventListener('keydown', keydownListener);
                window.removeEventListener('keyup', keyupListener);
            };
        }
    }, [keydownListener, keyupListener]);
};
