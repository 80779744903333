import { InstructionStepLabel, InstructionStepValue, InstructionStepWrapper } from './styles';

interface Props {
    step: { value: number; text: JSX.Element };
}
export const CircleStepItem = ({ step }: Props) => {
    return (
        <InstructionStepWrapper>
            <InstructionStepValue>{step.value}</InstructionStepValue>
            <InstructionStepLabel>{step.text}</InstructionStepLabel>
        </InstructionStepWrapper>
    );
};
