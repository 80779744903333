import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import t from '@/lib/i18n';
import React from 'react';
import { FavoritesAlertProps } from './types';

const FavoritesAlertRaw = ({
    showInfo,
    favoritesType = 'favorites',
    isInFavorites,
    responsive,
}: FavoritesAlertProps) => {
    if (!showInfo) {
        return null;
    }

    const userMessage = isInFavorites
        ? t(`${favoritesType}.add-material`)
        : t(`${favoritesType}.delete-material`);

    return (
        <AlertMessage type={MESSAGE_TYPE.INFO} userMessage={userMessage} responsive={responsive} />
    );
};

export const FavoritesAlert = React.memo(FavoritesAlertRaw);
