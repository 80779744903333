import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';

export interface LoadingWrapperProps {
    children?: React.ReactNode;
    isFetching: boolean;
    text?: string;
    highlight?: boolean;
    keepChildrenVisible?: boolean;
    calculateBottom?: boolean;
}

export interface StyledTextProps {
    highlight?: boolean;
}

export interface IProcessingLoaderProps {
    isFetching: boolean;
    text?: string;
    highlight?: boolean;
    description?: string;
    noDelay?: boolean;
    gutter?: GUTTER;
    inline?: boolean;
    fullScreen?: boolean;
}

export interface CacheValue {
    queryKey: any[];
    queryHash: string;
    config: object;
    observers: object;
    state: {
        isFetching: boolean;
    };
    cacheTime: number;
}

export interface StyledLoaderChildrenProps {
    isLoaderVisible?: boolean;
    keepChildrenVisible?: boolean;
}

export interface StyledLoadingWrapperProps {
    isLoaderVisible?: boolean;
    height: number;
    windowsHeight?: number;
    calculateBottom?: boolean;
}

export interface StyledLoaderProps {
    visible?: boolean;
    gutter?: GUTTER;
    noDelay?: boolean;
    inline?: boolean;
}

export interface DotsProps {
    dots: number;
}

export interface ActualQueryInterface {
    getMediaMethod: GET_MEDIA_METHODS;
    isFetching: boolean;
}

export const LOADER_QUERIES = [
    GET_MEDIA_METHODS.GET_SESSION,
    GET_MEDIA_METHODS.GET_HOME_MENU,
    GET_MEDIA_METHODS.GET_CONFIGURATION,
    GET_MEDIA_METHODS.GET_PROFILES,
    GET_MEDIA_METHODS.FETCH_AVATARS,
    GET_MEDIA_METHODS.FETCH_USER_LATELY_WATCHED_CONTENT_DATA_LIST,
    GET_MEDIA_METHODS.FETCH_PLATFORMS,
    GET_MEDIA_METHODS.GET_MEDIA,
    GET_MEDIA_METHODS.FETCH_MEDIA_LIST,
    GET_MEDIA_METHODS.CHECK_PRODUCT_ACCESS,
    GET_MEDIA_METHODS.CHECK_PRODUCTS_ACCESS,
    GET_MEDIA_METHODS.FETCH_PRE_PURCHASE_DATA,
    GET_MEDIA_METHODS.FETCH_EVENT_CHANNEL_WITH_FLAT_NAVIGATION,
    GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_CURRENT_PROGRAM,
    GET_MEDIA_METHODS.FETCH_CHANNEL_LIST,
    GET_MEDIA_METHODS.FETCH_CATEGORY,
    GET_MEDIA_METHODS.FETCH_CATEGORY_WITH_FLAT_NAVIGATION,
    GET_MEDIA_METHODS.FETCH_MEDIA_RELATED_CONTENT,
    GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION,
    GET_MEDIA_METHODS.FETCH_CHANNELS_FULL_PROGRAM,
    GET_MEDIA_METHODS.FETCH_SUBCATEGORIES,
    GET_MEDIA_METHODS.FETCH_SUBCATEGORIES_WITH_FLAT_NAVIGATION,
    GET_MEDIA_METHODS.FETCH_POSSESSIONS,
    GET_MEDIA_METHODS.FETCH_PRODUCT_SUBSCRIPTIONS,
    GET_MEDIA_METHODS.CANCEL_PRODUCT_SUBSCRIPTION,
    GET_MEDIA_METHODS.FETCH_PRODUCT,
    GET_MEDIA_METHODS.FETCH_PRODUCTS,
    GET_MEDIA_METHODS.FETCH_MULTIPLE_TYPE_PRODUCTS,
    GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS,
    GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS,
    GET_MEDIA_METHODS.GET_FAVORITES,
    GET_MEDIA_METHODS.PACKET_TREE_NAVIGATION,
];
