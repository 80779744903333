import useGetProduct from '@/api/navigation/use-get-product';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { ProductCard } from '@/components/shared/ProductCard';
import {
    ContentContainer,
    PaymentsLayoutContainer,
    ProductContainer,
} from '@/components/views/PaymentsLayout/styles';
import { PurchaseOptionRuleContainer } from '@/components/views/Purchase/Rules/PurchaseOptionRuleContainer';
import { extractRulesWithAllOptions } from '@/helpers/rules.helper';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import React from 'react';
import { useParams } from 'react-router';

interface RouteParams {
    id: string;
    type: PRODUCT_TYPES;
    subType: PRODUCT_SUBTYPES;
    offerId: string;
    offerPricingPlanId?: string;
}

const PurchaseRules = () => {
    const { id, type, subType, offerId, offerPricingPlanId } = useParams<RouteParams>();

    const { data: product, isFetching } = useGetProduct({
        product: {
            id,
            type,
            subType,
        },
    });

    const offer = product?.findOffer(offerId);
    const offerPricingPlan = offerPricingPlanId
        ? offer?.findOfferPricingPlan(offerPricingPlanId)
        : undefined;
    const rules =
        product && offer && extractRulesWithAllOptions({ product, offer, offerPricingPlan });

    return (
        <BaseLayout defaultBg fullscreen topShade={true} bottomShade={true}>
            <PaymentsLayoutContainer isAlignCenter={!product}>
                {product && (
                    <ProductContainer>
                        <ProductCard product={product} />
                    </ProductContainer>
                )}

                <ContentContainer>
                    <PurchaseOptionRuleContainer rules={rules} />
                    <ProcessingLoader isFetching={isFetching || false} />
                </ContentContainer>
            </PaymentsLayoutContainer>
        </BaseLayout>
    );
};

export default PurchaseRules;
