import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetPurchaseCodeProductOut } from '@/interfaces/from-schemas/navigation/getPurchaseCodeProductOut';

interface IPurchaseCode {
    purchaseCode: string;
}

const useGetPurchaseCodeProduct = (
    params?: IPurchaseCode,
): IApiResponse<GetPurchaseCodeProductOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.GET_PURCHASE_CODE_PRODUCT,
        params,
    );

    return useGetMediaApi(keyHook, { appendAuthData: true });
};

export default useGetPurchaseCodeProduct;
