import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import httpRequest, { IHttpResponse } from '../http-request.service';
import { QueryClient } from 'react-query';
import {
    IApiServerResponse,
    IKeyHook,
    IUseGetMediaApiOptions,
} from '@/hooks/use-get-media-api.type';
import { GetConfigurationOut } from '@/interfaces/from-schemas/system/getConfigurationOut';
import { AuthContainer } from './types';
import { createSessionToken } from '@/modules/user/services/session/session.service';
import { botDetector } from '@/helpers/seo/bot-detector.helper';
import { buildRPCRequestParams } from '@/utils/build-rpc-request-params';
import {
    buildUrlManually,
    getServiceMethod,
    parseVersionedNamespace,
    sortParams,
} from '@/services/get-media/helpers';
import CryptoUtil from '@/utils/crypto.utils';

const crypto = new CryptoUtil();

interface IRequestParamsOptions {
    skipDefaultProps?: boolean;
    appendAuthData?: boolean;
    appendClientId?: boolean;
}

export const buildGetMediaRequestParams = (
    keyHook: IKeyHook,
    { appendAuthData = true, appendClientId = true }: IRequestParamsOptions,
    auth: AuthContainer,
): Record<string, unknown> => {
    const { namespace, method, params } = keyHook;
    const requestParams = {
        ...params,
        ua: auth.ua,
        deviceId: auth.deviceId,
        userAgentData: auth.userAgentData,
    };

    if (appendAuthData) {
        const [unversionedNamespace] = parseVersionedNamespace(namespace);
        const sessionToken = createSessionToken(method, unversionedNamespace, auth.rpcSessionData);
        if (sessionToken) {
            requestParams.authData = { sessionToken };
        }
    }

    if (appendClientId) {
        requestParams.clientId = auth.clientId;
    }

    return requestParams;
};

// https://cyfropedia.polsatc/pages/viewpage.action?pageId=363306587
const fetchByGet = async (
    requestUrl: string,
    keyHook: IKeyHook,
    { appendAuthData = true, appendClientId = true }: IUseGetMediaApiOptions,
    auth: AuthContainer,
    headers?: Record<string, string>,
    clientContextToken?: string,
): Promise<IHttpResponse> => {
    const { method, params, namespace } = keyHook;
    const preparedHeaders = { ...headers };

    if (clientContextToken) {
        preparedHeaders['X-Client-Context-Token'] = clientContextToken;
    }

    if (auth && auth.userAgentData) {
        const uad = auth.userAgentData;
        preparedHeaders['X-User-Agent-Data-Portal'] = uad.portal;
        preparedHeaders['X-User-Agent-Data-Device-Type'] = uad.deviceType;
        preparedHeaders['X-User-Agent-Data-Application'] = uad.application;
        preparedHeaders['X-User-Agent-Data-Player'] = uad.player;
        preparedHeaders['X-User-Agent-Data-Build'] = String(uad.build);
        preparedHeaders['X-User-Agent-Data-Os'] = uad.os;
        preparedHeaders['X-User-Agent-Data-Os-Info'] = uad.osInfo;
    }

    if (auth && auth.rpcSessionData) {
        const [unversionedNamespace] = parseVersionedNamespace(namespace);
        const sessionToken = createSessionToken(method, unversionedNamespace, auth.rpcSessionData);
        if (sessionToken) {
            preparedHeaders['X-Auth-Data-Session-Id'] = auth.rpcSessionData.id;
            preparedHeaders['X-Auth-Data-Session-Token'] = sessionToken;
        }
    }

    if (appendAuthData && auth && auth.deviceId) {
        preparedHeaders['X-Device-Id-Type'] = auth.deviceId.type;
        preparedHeaders['X-Device-Id-Value'] = auth.deviceId.value;
    }

    if (appendClientId && auth && auth.clientId) {
        preparedHeaders['X-Client-Id'] = auth.clientId;
    }

    const sortedParams = sortParams(params);
    const paramsAsString = JSON.stringify(sortedParams, null, 0);
    const paramsAsStringWithoutPolishChars = crypto.replacePolishCharsWithUnicode(paramsAsString);
    const preparedData = {
        // INFO(ksyrytczyk): Za dokumentacją: "(...) b2c będzie rzucać błędem jeżeli przesłany parametr id będzie inny niż 1"
        id: 1,
        method: method,
        params: crypto.universalToBase64(paramsAsStringWithoutPolishChars),
    };
    const response = await httpRequest(requestUrl, preparedData, 'GET', preparedHeaders);
    return {
        result: response.result ?? null,
        error: response.error ?? null,
        headers: response?.headers,
    };
};

const fetchByPost = async (
    requestUrl: string,
    keyHook: IKeyHook,
    customOptions: IUseGetMediaApiOptions,
    auth: AuthContainer,
    headers?: Record<string, string>,
    clientContextToken?: string,
): Promise<IHttpResponse> => {
    const params = buildGetMediaRequestParams(keyHook, customOptions, auth);
    const rpcRequestParams = buildRPCRequestParams(keyHook.method, params);
    const response = await httpRequest(requestUrl, rpcRequestParams, 'POST', headers);

    return {
        result: response.result ?? null,
        error: response.error ?? null,
        headers: response?.headers,
    };
};

export const getMediaFetcher = async (
    keyHook: IKeyHook,
    customOptions: IUseGetMediaApiOptions,
    auth: AuthContainer,
    configuration?: GetConfigurationOut,
    headers?: Record<string, string>,
    clientContextToken?: string,
): Promise<IHttpResponse> => {
    const service = getServiceMethod(keyHook, configuration);

    if (service) {
        // @ts-ignore TODO(ksyrytczyk): Trzeba zaktualizować schemy!
        const { url, getUrl } = service;
        return clientContextToken && getUrl
            ? fetchByGet(getUrl, keyHook, customOptions, auth, headers, clientContextToken)
            : fetchByPost(url, keyHook, customOptions, auth, headers, clientContextToken);
    } else {
        const url = buildUrlManually(keyHook.namespace);
        return fetchByPost(url, keyHook, customOptions, auth, headers, clientContextToken);
    }
};

export const createCacheKey = (keyHook: IKeyHook, auth: AuthContainer) => {
    return [keyHook.namespace, keyHook.method, keyHook.params, auth.rpcSessionData?.id ?? null];
};

export const createGetMediaApiPromise = (
    queryClient: QueryClient,
    {
        auth,
        configuration,
        defaultHeaders,
    }: {
        auth: AuthContainer;
        configuration?: GetConfigurationOut;
        defaultHeaders?: Record<string, string>;
    },
) => async <T = any>(
    keyHook: IKeyHook,
    customOptions: IUseGetMediaApiOptions,
): Promise<IApiServerResponse<T>> => {
    const isBot = botDetector(auth.userAgentData.osInfo || auth.ua);

    const options = {
        appendAuthData: true,
        ...customOptions,
    };

    if (isBot) {
        Object.assign(options, { queryOptions: { cacheTime: Infinity } });
    }

    const { result, error } = await queryClient.fetchQuery(
        createCacheKey(keyHook, auth),
        () =>
            getMediaFetcher(
                keyHook,
                {
                    appendAuthData: options.appendAuthData,
                },
                auth,
                configuration,
                defaultHeaders,
            ),
        options.queryOptions,
    );

    if (error) {
        queryClient.removeQueries({
            predicate: (query) => query.queryKey[1] === GET_MEDIA_METHODS.FETCH_CHANNEL_LIST,
        });
    }

    return {
        data: result,
        error,
    };
};
