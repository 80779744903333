import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { Z_INDEX } from '@/targets/default/theme';
import styled from 'styled-components';
import { BACKGROUND_MARGIN, IMAGE_MODE, ImgElementProps, SHADE_MODE, ShadeProps } from './types';

// TODO: theme powinien być lepiej otypowany
function buildShadeBackgroundImage(theme: any, mode?: SHADE_MODE): string {
    switch (mode) {
        case SHADE_MODE.OVERLAY:
            return 'rgba(0, 0, 0, 0.6)';

        case SHADE_MODE.CUSTOM:
            return theme.backgroundImage.customBackgroundColor;

        case SHADE_MODE.SMALL:
            return 'linear-gradient(to bottom, rgba(0,0,0,0.0) 0%,rgba(0,0,0,1) 85%,rgba(0,0,0,1) 100%);';

        case SHADE_MODE.FULL:
            return 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0.7) 100%)';

        case SHADE_MODE.MEDIA:
            return 'linear-gradient(to top, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 19%,rgba(0,0,0,0) 100%);';

        case SHADE_MODE.BIG:
        default:
            return 'linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,1) 60%,rgba(0,0,0,1) 100%);';
    }
}

function buildBackgroundMargin(margin?: BACKGROUND_MARGIN): string {
    switch (margin) {
        case BACKGROUND_MARGIN.PROMOBOX:
            return '-16rem';
        case BACKGROUND_MARGIN.NONE:
            return '0';
        default:
            return '0';
    }
}

export const StyledBcgImage = styled.img<ImgElementProps>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: ${(props) => buildBackgroundMargin(props.margin)};
    left: 0;
    z-index: ${Z_INDEX.BCG_IMAGE};
    transition: ${(props) => CheckSlowDevice(props.theme.backgroundImage.transitionValue)};
    ${({ mode, showImg }) =>
        mode === IMAGE_MODE.NEW &&
        `
            opacity: ${showImg ? 0 : 1};
        `}

    /**
     * Na Tizenie AVplay ignoruje warstwy w DOM, podczas odtwarzania tło musi być transparentne,
     * W przeciwnym wypadku odtwarzany materiał jest przysłonięty
     */
    body.transparent & {
        display: none;
    }
`;

export const ShadeStyled = styled.div<ShadeProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => buildShadeBackgroundImage(props.theme, props.mode)};
    z-index: ${Z_INDEX.BCG_IMAGE};
`;
