import React, { useCallback, useState } from 'react';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { useChannelsAccessMap } from '@/hooks/player/use-channels-access-map';
import { ChannelModel } from '@/models/channels/channel.model';
import { EPGChannelsSliderPresentation } from './EPGChannelsSliderPresentation';
import { EPGChannelsSliderProps } from './types';

const EPGChannelsSliderRaw = ({
    nowPlayingChannelId,
    focusedChannelId,
    navId = 'epgChannelsSlider',
    channels,
    onNav,
    onBuyChannel,
    onFocusChannel,
}: EPGChannelsSliderProps) => {
    const { channelsAccessMap } = useChannelsAccessMap(channels);
    const [internalFocusedChannelId, setInternalFocusedChannelId] = useState(focusedChannelId);
    useDebouncedEffect(
        () => {
            const channel = channels.find(
                (channel) => channel.getId() === internalFocusedChannelId,
            );
            if (channel) {
                channel.setIsLocked(!channelsAccessMap[channel.getId()]);
                onFocusChannel(channel);
            }
        },
        { timeout: 500, omitFirst: true },
        [internalFocusedChannelId],
    );

    const handleIsDefault = useCallback((channel) => focusedChannelId === channel.getId(), [
        focusedChannelId,
    ]);

    const handleAccess = useCallback(
        (channel: ChannelModel) => channelsAccessMap[channel.getId()],
        [channelsAccessMap],
    );

    return (
        <EPGChannelsSliderPresentation
            nowPlayingChannelId={nowPlayingChannelId}
            navId={navId}
            channels={channels}
            onIsDefault={handleIsDefault}
            onBuyChannel={onBuyChannel}
            onInternalFocusedChannelId={setInternalFocusedChannelId}
            onHasAccess={handleAccess}
            onNav={onNav}
        />
    );
};

export const EPGChannelsSlider = React.memo(EPGChannelsSliderRaw);
