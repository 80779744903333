import { TitleContainer } from '@/components/shared/Message/styles';
import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const StyledSliderTitle = styled.div`
    position: relative;
    text-align: left;
`;

export const StyledSliderTitleInner = styled.div`
    position: absolute;
    width: 100%;
    margin-left: 2rem;
`;

export const StyledMyPurchasesContainer = styled.div`
    position: absolute;
    max-width: 100%;
    padding-top: 7rem;
    width: calc(100vw - 70.5rem);
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
`;

export const StyledMyPurchasesWrapper = styled.div`
    width: 100%;

    ${StyledMyPurchasesContainer} {
        width: calc(100% - 70.5rem);
    }
`;

export const StyledMyPurchasesInnerWrapper = styled(Nav)`
    height: calc(100vh - 7rem);
    overflow: hidden;
`;

export const StyledMyPurchasesCollectionsList = styled.div`
    > *:last-child {
        margin-bottom: 100%;
    }
`;

export const StyledMyPurchasesList = styled.div`
    white-space: nowrap;
    text-align: left;
    > * {
        width: 41rem;
        height: 23rem;
        display: inline-block;
        margin-right: 2rem;
    }
`;

export const StyledSlider = styled(Nav)`
    padding: 5rem 0 10rem 2rem;
    overflow: hidden;
`;

export const StyledImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const StyledMyPurchasesMessage = styled.div`
    padding-right: 14rem;
    ${TitleContainer} {
        > span {
            color: ${({ theme }) => theme.text.color.highlight};
        }
    }
`;

export const StyledCenteredContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(calc(-50% - 14rem), calc(-50% - 7rem));
`;
