import { ChannelModel } from './channel.model';
import {
    GetTvChannelsOut,
    TvChannelListItemSchema,
} from '@/interfaces/from-schemas/navigation/getTvChannelsOut';

class ChannelListModel {
    private readonly count: number = 0;
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private readonly results: ChannelModel[] = [];
    private readonly total: number = 0;

    constructor(definition: GetTvChannelsOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
            this.results = [];
            definition.results.forEach((value: TvChannelListItemSchema) => {
                this.results.push(new ChannelModel(value));
            });
        }
    }
    public getCount(): number {
        return this.count;
    }
    public getLimit(): number {
        return this.limit;
    }
    public getOffset(): number {
        return this.offset;
    }
    public getResults(): ChannelModel[] {
        return this.results;
    }
    public getTotal(): number {
        return this.total;
    }
}

export default ChannelListModel;
