import { isServer } from '@/helpers/environment.helper';
import { QUERY_PARAM } from '@/types/query-string.type';

export const updateQuery = <T>(param: QUERY_PARAM, value: T, urlProp?: string) => {
    const url = urlProp || (!isServer ? window.location.href : '');
    const newParam = `${param}=${value}`;

    if (param === QUERY_PARAM.FILTERS) {
        const filtersIndex = url.indexOf(QUERY_PARAM.FILTERS);
        const totalIndex = url.indexOf(QUERY_PARAM.TOTAL);
        const index = filtersIndex > -1 ? filtersIndex : totalIndex;

        if (index > -1) {
            return value ? url.slice(0, index).concat(newParam) : url.slice(0, index - 1);
        }
    }

    if (url.includes(param)) {
        const params = url.slice(url.indexOf('?') + 1).split('&');
        const newParams = params
            .map((p) => {
                const [key] = p.split('=');
                if (key === param) {
                    return value !== undefined ? newParam : '';
                }
                return p;
            })
            .filter(Boolean);

        return url.slice(0, url.indexOf('?') + 1) + newParams.join('&');
    }

    return url.includes('?') ? `${url}&${newParam}` : `${url}?${newParam}`;
};
