import { IParams, NavigationParams } from '@/routing/types';
import { isWeb } from '@/constants/portal-recognition';
import { buildNextUrl } from '../url.helper';
import { isAuthorizedOnlyPaymentStep } from './is-authorized-only-payment-step.helper';

export const buildRedirectParams = ({ next, target }: IParams) => {
    const shouldBuildNext = isWeb && !next;

    const params: NavigationParams = {};

    if (isAuthorizedOnlyPaymentStep(window.location.href)) {
        const href = window.location.href.split('step')[0];
        const next = `${href}step-1`;
        const target = `${href}step-2`;
        Object.assign(params, { next: encodeURIComponent(next) });
        Object.assign(params, { target: encodeURIComponent(target) });

        return params;
    }

    // next
    if (next) Object.assign(params, { next });
    if (shouldBuildNext) Object.assign(params, { next: buildNextUrl() });

    // target
    if (target) Object.assign(params, { target });

    return params;
};
