import { Text } from './styles';

interface Props {
    text: string;
    boldText: string;
}
export const TextWithBold = ({ text, boldText }: Props) => {
    return (
        <>
            <Text type="normal">{text}</Text>
            <Text type="bold">{boldText}</Text>
        </>
    );
};
