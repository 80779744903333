import React, { useState } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useRouter } from '@/routing';
import ErrorArticles from '@/components/views/CustomerCare/ErrorArticles/ErrorArticles';
import useGetArticleList from '@/api/help/use-get-article-list';
import { useHelpConfigurationContext } from '@/contexts/customer-care/helpConfiguration.context';

const ErrorPage = () => {
    const { query } = useRouter();
    const { errorCode, contextValue, contextType } = query;
    const [shade, setShade] = useState({ top: false, bottom: true });
    const { helpConfiguration } = useHelpConfigurationContext();

    const { data: articles, isFetching } = useGetArticleList({ errorCode });
    const isAvailabeErrorCode =
        helpConfiguration?.availableArticleErrorCodes.some((el) => el === errorCode) || false;

    return (
        <BaseLayout fullscreen defaultBg topShade={shade.top} bottomShade={shade.bottom}>
            {isFetching ? (
                <ProcessingLoader isFetching={true} />
            ) : (
                <ErrorArticles
                    articles={articles}
                    errorCode={errorCode}
                    contextValue={contextValue}
                    contextType={contextType}
                    isAvailabeErrorCode={isAvailabeErrorCode}
                    errorCodeMessageToDisplay={query.errorCodeMessageToDisplay}
                />
            )}
        </BaseLayout>
    );
};
export default ErrorPage;
