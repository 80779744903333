import { IMPERATIVE_SCROLL_DIRECTION, NAV_DIRECTION, NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE, NAVIGATION_KEYS, } from "./types";
// Dynamic navigation
// (elements' position evaluated at run-time)
//
/**
 * Get element position
 */
var getElRect = function (el) {
    var rect = el.getBoundingClientRect();
    if (rect.width === 0 && rect.height === 0)
        return null; // element is not visible
    return rect;
};
/**
 * Get center point of a rectangle. The points are used to compare distance between elements.
 *          up
 *      ┌────*────┐
 *      │         │
 * left *         * right
 *      │         │
 *      └────*────┘
 *          down
 *
 */
var getCenterPoint = function (side, rect) {
    if (side === NAVIGATION_KEYS.LEFT)
        return {
            x: rect.left,
            y: rect.top + (rect.bottom - rect.top) / 2
        };
    else if (side === NAVIGATION_KEYS.RIGHT)
        return {
            x: rect.right,
            y: rect.top + (rect.bottom - rect.top) / 2
        };
    else if (side === NAVIGATION_KEYS.UP)
        return {
            x: rect.left + (rect.right - rect.left) / 2,
            y: rect.top
        };
    else if (side === NAVIGATION_KEYS.DOWN)
        return {
            x: rect.left + (rect.right - rect.left) / 2,
            y: rect.bottom
        };
};
/**
 * Calculate distance between two points
 */
var getDistance = function (pos1, pos2) {
    var a = pos1.x - pos2.x;
    var b = pos1.y - pos2.y;
    return Math.sqrt(a * a + b * b);
};
var invertKey = {
    left: NAVIGATION_KEYS.RIGHT,
    right: NAVIGATION_KEYS.LEFT,
    up: NAVIGATION_KEYS.DOWN,
    down: NAVIGATION_KEYS.UP
};
export function navDynamic(key, navTree, deepestFocusedNode) {
    if (key !== NAVIGATION_KEYS.LEFT && key !== NAVIGATION_KEYS.RIGHT && key !== NAVIGATION_KEYS.UP && key !== NAVIGATION_KEYS.DOWN)
        return false;
    var srcEl;
    if (navTree.focusedNode !== null) {
        srcEl = navTree.nodes[navTree.focusedNode].el;
    }
    else if (deepestFocusedNode) {
        srcEl = deepestFocusedNode.el;
    }
    // get src element position
    var srcRect;
    var srcPoint;
    if (srcEl && (srcRect = getElRect(srcEl))) {
        srcPoint = getCenterPoint(key, srcRect);
    }
    else {
        // no element, or not visible
        srcPoint = { x: 0, y: 0 };
    }
    var minDistance = null;
    var minDistanceNode;
    // Loop over child nodes to find a node that is at minimum distance from previously focused element
    navTree.nodesId.forEach(function (id) {
        var dstNode = navTree.nodes[id];
        var dstEl = dstNode.el;
        if (dstEl === srcEl)
            return;
        // get dst element position
        var dstRect = getElRect(dstEl);
        if (!dstRect)
            return; // element is not visible, skip
        var dstPoint = getCenterPoint(invertKey[key], dstRect);
        // skip if element is beyond the scope
        if ((key === NAVIGATION_KEYS.LEFT && dstPoint.x > srcPoint.x) ||
            (key === NAVIGATION_KEYS.RIGHT && dstPoint.x < srcPoint.x) ||
            (key === NAVIGATION_KEYS.UP && dstPoint.y > srcPoint.y) ||
            (key === NAVIGATION_KEYS.DOWN && dstPoint.y < srcPoint.y))
            return;
        var distance = getDistance(srcPoint, dstPoint);
        if (minDistance === null || minDistance > distance) {
            minDistance = distance;
            minDistanceNode = dstNode;
        }
    });
    return minDistanceNode ? minDistanceNode.id : false;
}
//
// Fixed (static) navigation
//
var getValue = function (val, values, dir) {
    var pos = values.indexOf(val);
    var shiftPos = dir === NAV_DIRECTION.NEXT ? 1 : -1;
    var newPos;
    if (pos === -1) {
        newPos = shiftPos > 0 ? 0 : values.length - 1;
    }
    else {
        newPos = pos + shiftPos;
    }
    if (newPos >= 0 && newPos < values.length) {
        return values[newPos];
    }
    else {
        return false;
    }
};
/**
 * Vertical row resolve function ('up' and 'down' keys)
 */
export var navVertical = function (key, navTree) {
    var focusedNode = navTree.focusedNode, nodesId = navTree.nodesId;
    if (key === NAVIGATION_KEYS.UP || key === NAVIGATION_KEYS.DOWN) {
        return getValue(focusedNode, nodesId, key === NAVIGATION_KEYS.UP ? NAV_DIRECTION.PREV : NAV_DIRECTION.NEXT);
    }
    else {
        return focusedNode !== null ? false : nodesId[0];
    }
};
/**
 * Horizontal row resolve function ('left' and 'right' keys)
 */
export var navHorizontal = function (key, navTree) {
    var focusedNode = navTree.focusedNode, nodesId = navTree.nodesId;
    if (key === NAVIGATION_KEYS.LEFT || key === NAVIGATION_KEYS.RIGHT) {
        return getValue(focusedNode, nodesId, key === NAVIGATION_KEYS.LEFT ? NAV_DIRECTION.PREV : NAV_DIRECTION.NEXT);
    }
    else {
        return focusedNode !== null ? false : nodesId[0];
    }
};
/**
 * Table (fixed columns size) resolve function ('left' 'right', 'up', 'down' keys)
 * @param cols
 * @returns {Function}
 */
export function navTable(_a) {
    var cols = _a.cols;
    return function (key, navTree) {
        var focusedNode = navTree.focusedNode, nodesId = navTree.nodesId;
        var pos = focusedNode !== null ? nodesId.indexOf(focusedNode) : -1;
        if (key === NAVIGATION_KEYS.LEFT || key === NAVIGATION_KEYS.RIGHT) {
            var rowNum = pos !== -1 ? Math.floor(pos / cols) : 0;
            var rowStartIndex = rowNum * cols;
            var rowEndIndex = rowStartIndex + cols;
            var rowNodes = nodesId.slice(rowStartIndex, rowEndIndex);
            return getValue(focusedNode, rowNodes, key === NAVIGATION_KEYS.LEFT ? NAV_DIRECTION.PREV : NAV_DIRECTION.NEXT);
        }
        else if (key === NAVIGATION_KEYS.UP || key === NAVIGATION_KEYS.DOWN) {
            var colNum = pos !== -1 ? pos % cols : 0;
            var colNodes = [];
            for (var i = colNum; i < nodesId.length; i += cols) {
                colNodes.push(nodesId[i]);
            }
            return getValue(focusedNode, colNodes, key === NAVIGATION_KEYS.UP ? NAV_DIRECTION.PREV : NAV_DIRECTION.NEXT);
        }
    };
}
export function getScrollPosition(scrollOptions, navNode, focusedNode) {
    var HORIZONTAL = NAV_SCROLL_DIRECTION.HORIZONTAL, VERTICAL = NAV_SCROLL_DIRECTION.VERTICAL;
    var direction = scrollOptions.direction, mode = scrollOptions.mode;
    var top = null;
    var left = null;
    switch (direction) {
        case VERTICAL:
            top = getValueInScrollMode({
                navSize: navNode.offsetHeight,
                navContentSize: navNode.scrollHeight,
                focusedStart: focusedNode.offsetTop,
                focusedSize: focusedNode.offsetHeight,
            }, mode);
            break;
        case HORIZONTAL:
            left = getValueInScrollMode({
                navSize: navNode.offsetWidth,
                navContentSize: navNode.scrollWidth,
                focusedStart: focusedNode.offsetLeft,
                focusedSize: focusedNode.offsetWidth,
            }, mode);
            break;
        default:
    }
    return { top: top, left: left };
}
export function getScrollPositionToOffscreenFocusedNone(scrollOptions, navNode, focusedNode) {
    var HORIZONTAL = NAV_SCROLL_DIRECTION.HORIZONTAL, VERTICAL = NAV_SCROLL_DIRECTION.VERTICAL;
    var direction = scrollOptions.direction;
    var top = null;
    var left = null;
    switch (direction) {
        case VERTICAL:
            top = offScreenScroll({
                navSize: navNode.offsetHeight,
                navContentSize: navNode.scrollHeight,
                clientSize: navNode.clientHeight,
                scrollPosition: navNode.scrollTop,
                focusedStart: focusedNode.offsetTop,
                focusedSize: focusedNode.offsetHeight,
            });
            break;
        case HORIZONTAL:
            left = offScreenScroll({
                navSize: navNode.offsetWidth,
                navContentSize: navNode.scrollWidth,
                clientSize: navNode.clientWidth,
                scrollPosition: navNode.scrollLeft,
                focusedStart: focusedNode.offsetLeft,
                focusedSize: focusedNode.offsetWidth,
            });
            break;
        default:
    }
    return { top: top, left: left };
}
export function getImperativeScrollPosition(direction, scrollOptions, navNode, focusedNode) {
    var top = 0;
    var left = 0;
    switch (scrollOptions.direction) {
        case NAV_SCROLL_DIRECTION.HORIZONTAL:
            left = getValueInImperativeScrollMode({
                clientSize: navNode.clientWidth,
                scrollPosition: navNode.scrollLeft,
                focusedStart: focusedNode.offsetLeft,
                focusedSize: focusedNode.offsetWidth,
            }, direction);
            break;
        case NAV_SCROLL_DIRECTION.VERTICAL:
            top = getValueInImperativeScrollMode({
                clientSize: navNode.clientHeight,
                scrollPosition: navNode.scrollTop,
                focusedStart: focusedNode.offsetTop,
                focusedSize: focusedNode.offsetHeight,
            }, direction);
            break;
    }
    return { top: top, left: left };
}
export function offScreenScroll(offsetData) {
    var navSize = offsetData.navSize, navContentSize = offsetData.navContentSize, clientSize = offsetData.clientSize, scrollPosition = offsetData.scrollPosition, focusedStart = offsetData.focusedStart, focusedSize = offsetData.focusedSize;
    // check focused node if off screen
    if (focusedStart < (scrollPosition - focusedSize) || scrollPosition + clientSize + focusedSize < focusedStart) {
        // center off screen focused node
        var navCenterSize = navSize / 2;
        var focusedCenterSize = focusedSize / 2;
        var scrollCenterPosition = focusedStart + focusedCenterSize - navCenterSize;
        return scrollCenterPosition > 0
            ? navContentSize - navCenterSize >= focusedStart + focusedCenterSize
                ? scrollCenterPosition
                : navContentSize - navSize
            : 0;
    }
    return null;
}
export function getValueInScrollMode(offsetData, scrollMode) {
    var START = NAV_SCROLL_MODE.START, END = NAV_SCROLL_MODE.END, CENTER = NAV_SCROLL_MODE.CENTER;
    var navSize = offsetData.navSize, navContentSize = offsetData.navContentSize, focusedStart = offsetData.focusedStart, focusedSize = offsetData.focusedSize;
    if (navSize > navContentSize) {
        return 0;
    }
    switch (scrollMode) {
        case START:
            return navContentSize - navSize >= focusedStart ? focusedStart : navContentSize - navSize;
        case END:
            var scrollEndPosition = focusedStart + focusedSize - navSize;
            return scrollEndPosition > 0 ? scrollEndPosition : 0;
        case CENTER:
        default:
            // TODO: Zrobić tryb auto
            var navCenterSize = navSize / 2;
            var focusedCenterSize = focusedSize / 2;
            var scrollCenterPosition = focusedStart + focusedCenterSize - navCenterSize;
            return scrollCenterPosition > 0
                ? navContentSize - navCenterSize >= focusedStart + focusedCenterSize
                    ? scrollCenterPosition
                    : navContentSize - navSize
                : 0;
    }
}
export function getValueInImperativeScrollMode(offsetData, direction) {
    var clientSize = offsetData.clientSize, scrollPosition = offsetData.scrollPosition, focusedSize = offsetData.focusedSize;
    var numElements = Math.floor(clientSize / focusedSize);
    var elementsToScroll = numElements - 1;
    var scrollPageSize = focusedSize * elementsToScroll;
    switch (direction) {
        case IMPERATIVE_SCROLL_DIRECTION.RIGHT:
        case IMPERATIVE_SCROLL_DIRECTION.DOWN:
            return scrollPosition + scrollPageSize;
        case IMPERATIVE_SCROLL_DIRECTION.LEFT:
        case IMPERATIVE_SCROLL_DIRECTION.UP:
            return Math.max(scrollPosition - scrollPageSize, 0);
    }
}
export function isRestoreFocusAvailable(node, focusId) {
    return node.focusedNode === null && node.getNode([focusId]) !== null;
}
export function isScrollableUp(node) {
    return node.scrollTop > 0;
}
export function isScrollableDown(node) {
    return node.scrollHeight - node.scrollTop > node.offsetHeight;
}
export function isScrollableLeft(node) {
    return node.scrollLeft > 0;
}
export function isScrollableRight(node) {
    var distanceToRight = node.scrollWidth - node.scrollLeft - node.clientWidth;
    return distanceToRight > 0;
}
