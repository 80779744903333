import { ListElementModel } from '@/models/list-element/list-element.model';
import { LiveModel } from '@/models/live/live.model';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import parseISO from 'date-fns/parseISO';
import { CollectionLivesInterface, CollectionLivesRawInterface } from './types';

const { liveCatalog: dataPosition } = process.env.metadataSettings.position;

export const prepareLives = (lives: LiveModel[]) => {
    const singleLive = lives[0];

    if (!singleLive) {
        return null;
    }

    return {
        date: format(new Date(singleLive.getPublicationDate()), 'dd.MM', {}),
        fullDate: singleLive.getPublicationDate(),
        prefixDate: singleLive.getPublicationDatewithDays(),
        isToday: isToday(parseISO(singleLive.getPublicationDate())),
        isTomorrow: isTomorrow(parseISO(singleLive.getPublicationDate())),
        lives,
    };
};

export const buildCollectionLives = (el: CollectionLivesRawInterface): CollectionLivesInterface => {
    return {
        ...el,
        lives: el.lives.map((live: LiveModel) => {
            const liveElement = new ListElementModel(live);
            liveElement.setVisibilityProgressBar(false);
            liveElement.setDataPosition(dataPosition);
            liveElement.setShowMetadata(true);
            liveElement.setShowDetailedMetadata(true);
            return liveElement;
        }),
    };
};
