import ResponseErrorModel from '@/models/response-error.model';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AdditionalData, ErrorContextProps, Props } from './types';
import { ArticleModel } from '@/models/help/article.model';
import { CUSTOMER_CARE_NAVIGATION } from '../customer-care/types';

export const ErrorContext = createContext<ErrorContextProps>({
    error: undefined,
    setError: () => null,
    showErrorComponent: false,
    setShowErrorComponent: () => null,
    currentArticleId: undefined,
    setCurrentArticleId: () => null,
    currentArticle: undefined,
    setCurrentArticle: () => null,
    additionalData: undefined,
    setAdditionalData: () => null,
    customerCareOverlay: false,
    setCustomerCareOverlay: () => null,
});

const ErrorContextProvider = ({ children }: Props) => {
    const [error, setError] = useState<ResponseErrorModel>();
    const [showErrorComponent, setShowErrorComponent] = useState<boolean>(false);

    const [currentArticleId, setCurrentArticleId] = useState<string | undefined>(undefined);
    const [currentArticle, setCurrentArticle] = useState<ArticleModel | undefined>(undefined);

    const [customerCareOverlay, setCustomerCareOverlay] = useState(false);

    const [additionalData, setAdditionalData] = useState<AdditionalData | undefined>(undefined);

    useEffect(() => {
        if (showErrorComponent) {
            sessionStorage.setItem(
                CUSTOMER_CARE_NAVIGATION.REDIRECT_TO_ERROR_PAGE,
                location.pathname + location.search,
            );
        }
    }, [showErrorComponent]);

    useEffect(() => {
        if (!error && !showErrorComponent) {
            sessionStorage.removeItem('lastErrorPage');
        }
    }, []);

    return (
        <ErrorContext.Provider
            value={{
                error,
                setError,
                showErrorComponent,
                setShowErrorComponent,
                currentArticleId,
                setCurrentArticleId,
                currentArticle,
                setCurrentArticle,
                additionalData,
                setAdditionalData,
                customerCareOverlay,
                setCustomerCareOverlay,
            }}
        >
            {children}
        </ErrorContext.Provider>
    );
};

export const useError = () => useContext(ErrorContext);

export default ErrorContextProvider;
