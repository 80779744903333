import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { ContentContainerProps } from './types';

export const BackButtonContainer = styled.div`
    left: 15rem;
    position: absolute;
    top: 2rem;
`;

export const ButtonGroupContainer = styled.div`
    display: flex;
    margin-top: 2.5rem;
`;

export const ContentContainer = styled.div<ContentContainerProps>`
    min-height: 100%;
    margin-top: -108rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${({ hide }) =>
        hide &&
        css`
            display: none;
        `};
`;

export const InfoText = styled.span`
    color: ${({ theme }) => theme.text.color.primary};
    font-size: 2rem;
    font-weight: 400;
    margin-left: 1.5rem;
`;

export const InfoTextContainer = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 3rem;
    margin-right: 6rem;
`;

export const MainTextContainer = styled.div`
    max-width: 108rem;
`;

export const MetadataContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;

export const PacketDetailsContainer = styled(Nav)`
    height: 100%;
    position: relative;
    width: 100%;
    padding-left: 15rem;
    overflow: hidden;
`;

export const PlatformsContainer = styled.span`
    margin-right: 1.8rem;
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
        margin-right: 0.7rem;
    }
`;

export const PlatformsMetadataContainer = styled.div`
    align-items: center;
    display: flex;
`;

export const VerticalLine = styled.div`
    border-left: 1px solid #373a3e;
    height: 2.4rem;
    margin-left: 1.5rem;
    padding-left: 1.5rem;
`;

export const CollectionListWrapper = styled.div<{ showActiveItem: boolean }>`
    position: absolute;
    top: ${({ showActiveItem }) => (showActiveItem ? '56.5rem' : '94rem')};
    width: 100%;
    height: 100%;
    transition: top 0.4s;
`;

export const StyledCollectionList = styled(Nav)`
    position: relative;
    left: -15rem;
    height: 51.8rem;
`;
