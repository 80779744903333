import { ROUTES } from '@/routing/types';
import { MenuItemData } from '@/types/account-menu-items.type';

export const filterExcludedItems = (item: MenuItemData) =>
    !(process.env.excludedAccountMenuItems || []).includes(item.url);

export const filterPlusZone = (item: MenuItemData) => item.url !== ROUTES.PLUS_ZONE;

export const filterOnetrust = (item: MenuItemData) => item.url !== ROUTES.COOKIE_PREFERENCES;

//?? Function to filter out items not available for BMW users
// TODO: Remove this function after implementing a dedicated portal for BMW
export const filterNotAvailableForBmw = (isBmw: boolean, item: MenuItemData) => {
    if (!isBmw) return true;

    return (
        item.url !== ROUTES.ACCOUNT &&
        item.url !== ROUTES.MY_PAYMENTS &&
        item.url !== ROUTES.TV_CODE_LOGGED_USER
    );
};
