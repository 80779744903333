import { RedirectParamsInterface } from '@/interfaces/redirect.interface';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { MEDIA_TYPES } from '@/types/media.type';
import slug from 'slug';
import { findPatternByRouteName } from './router/find-pattern-by-route-name.helper';
import { CategoryModel } from '@/models/category/category.model';
import { isServer } from '@/helpers/environment.helper';
import { ProductModel } from '@/models/products/product.model';
import { generatePath } from 'react-router';

export const NEXT_PARAMS_REGEXP = /next=([^#&]+)/i;

export const buildTargetUrl = (route: ROUTES, params?: Record<string, any>) => {
    const destination = findPatternByRouteName(route);

    let target;
    if (destination) {
        const path = generatePath(destination, params);
        target = encodeURIComponent(window.location.origin + path);
    }
    return target;
};

export function buildNextUrl(): string {
    if (isServer) return '';

    return encodeURIComponent(window.location.href);
}

export function getNextUrl(): string {
    const matched = NEXT_PARAMS_REGEXP.exec(window.location.href);
    const nextUrl = matched && matched[1] && decodeURIComponent(matched[1]);
    return nextUrl || '';
}

function buildMovieRouteParams(media: MediaListItemModel) {
    return {
        title: slug(media.getTitle()),
        id: media.getId(),
    };
}

function buildVodRouteParams(media: MediaListItemModel) {
    const category = media.getCategory();

    if (media.hasFullCategory()) {
        const mainCategory = category?.categoryNamesPath?.[1];
        const vodCategoryTitle = category?.categoryNamesPath?.[2];
        return {
            mainCategory: mainCategory ? slug(mainCategory) : undefined,
            vodCategoryTitle: vodCategoryTitle ? slug(vodCategoryTitle) : undefined,
            vodCategoryId: category?.categoryPath[2] ?? '',
            vodTitle: slug(media.getTitle()),
            vodId: media.getId(),
        };
    }

    return {
        vodTitle: slug(media.getTitle()),
        vodId: media.getId(),
    };
}

function buildVodWithSeasonRouteParams(media: MediaListItemModel) {
    const category = media.getCategory();
    const seasonName = category?.categoryNamesPath[3];

    return {
        ...buildVodRouteParams(media),
        seasonName: seasonName ? slug(seasonName) : undefined,
        seasonId: category?.categoryPath[3] ?? '',
    };
}

function buildEventRouteParams(media: MediaListItemModel) {
    return {
        eventTitle: slug(media.getTitle()),
        eventId: media.getId(),
    };
}

function buildChannelRouteParams(media: MediaListItemModel) {
    return {
        channelTitle: slug(media.getTitle()),
        channelId: media.getId(),
    };
}

export function redirectToWatch(
    media: MediaListItemModel,
    { withNext, replaceRoute }: RedirectParamsInterface = { withNext: false, replaceRoute: false },
): void {
    let route: ROUTES;
    let params: Object;

    switch (media.getType()) {
        case MEDIA_TYPES.MOVIE:
            route = ROUTES.VOD_MOVIE_WATCH;
            params = buildMovieRouteParams(media);
            break;

        case MEDIA_TYPES.VOD:
            if (media.hasSeason()) {
                route = ROUTES.VOD_EPISODE_WITH_SEASON_WATCH;
                params = buildVodWithSeasonRouteParams(media);
            } else {
                route = media.hasFullCategory() ? ROUTES.VOD_EPISODE_WATCH : ROUTES.VOD_WATCH;
                params = buildVodRouteParams(media);
            }
            break;

        case MEDIA_TYPES.LIVE:
            route = ROUTES.LIVE_WATCH;
            params = buildEventRouteParams(media);
            break;

        case MEDIA_TYPES.TV:
            route = ROUTES.CHANNEL_TV_WATCH;
            params = buildChannelRouteParams(media);
            break;

        default:
            route = ROUTES.NOT_FOUND;
            params = {};
            break;
    }

    if (withNext) {
        params = {
            ...params,
            next: getNextUrl() || buildNextUrl(),
        };
    }

    const redirect = replaceRoute ? Router.replaceRoute : Router.pushRoute;
    redirect(route, params);
}

const isDynamicChunk = (chunk: string): boolean => chunk.charAt(0) === ':';

export const generateRoute = (routePattern: string, params: Record<string, any>) => {
    const path = routePattern
        .split('/')
        .map((chunk) => {
            if (isDynamicChunk(chunk)) {
                const newChunk = params[chunk.slice(1)];
                return newChunk;
            }
            return chunk;
        })
        .join('/');

    return path;
};

export function returnMediaUrl(media: MediaListItemModel): string {
    let route: ROUTES;
    let params: { [key: string]: any };

    switch (media.getType()) {
        case MEDIA_TYPES.MOVIE:
            route = ROUTES.VOD_MOVIE_DETAILS;
            params = buildMovieRouteParams(media);
            break;

        case MEDIA_TYPES.VOD:
            if (media.hasSeason()) {
                route = ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS;
                params = buildVodWithSeasonRouteParams(media);
            } else {
                route = ROUTES.VOD_EPISODE_DETAILS;
                params = buildVodRouteParams(media);
            }
            break;

        case MEDIA_TYPES.LIVE:
            route = ROUTES.LIVE_DETAILS;
            params = buildEventRouteParams(media);
            break;

        case MEDIA_TYPES.TV:
            route = ROUTES.CHANNEL_TV_DETAILS;
            params = buildChannelRouteParams(media);
            break;

        default:
            route = ROUTES.NOT_FOUND;
            params = {};
            break;
    }

    const routePattern = findPatternByRouteName(route);

    if (!routePattern) return '/404';

    return generateRoute(routePattern, params);
}

export function generateVodMovieRedirectPath(mediaId: string, mediaTitle: string) {
    const routePattern = findPatternByRouteName(ROUTES.VOD_MOVIE_DETAILS);
    if (routePattern) {
        const params = { id: mediaId, title: slug(mediaTitle) };
        return generateRoute(routePattern, params);
    }
}

export function buildCategoryRouteParams(category: CategoryModel) {
    return {
        vodCategoryTitle: slug(category.getTitle()),
        categoryId: category.getId(),
    };
}

export function returnCategoryUrl(category: CategoryModel): string {
    const route = ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY;
    const params = buildCategoryRouteParams(category);

    const routePattern = findPatternByRouteName(route);

    if (!routePattern) return '/404';

    return generateRoute(routePattern, params);
}

export function buildPacketRouteParams(packet: ProductModel) {
    return {
        packetId: slug(packet?.getId()),
    };
}

export function returnPacketUrl(packet: ProductModel): string {
    const route = ROUTES.PACKET_DETAILS;
    const params = buildPacketRouteParams(packet);

    const routePattern = findPatternByRouteName(route);

    if (!routePattern) return '/404';

    return generateRoute(routePattern, params);
}
