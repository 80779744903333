import { getChannelsIds } from '@/components/views/Channels/helpers';
import { CHANNELS_PROGRAM_LIMIT } from '@/configs/channels.config';
import PacketContentWithFlatNavigationModel from '@/models/packets/packet-content-with-flat-navigation.model';
import { SearchContentModel } from '@/models/search/search-content.model';

export const getProgramParams = (
    results: SearchContentModel | PacketContentWithFlatNavigationModel,
) => {
    const channelIds = getChannelsIds(results);

    return channelIds.length ? { channelIds, limit: CHANNELS_PROGRAM_LIMIT } : false;
};
