import { GetConfigurationOut } from '@/interfaces/from-schemas/system/getConfigurationOut';
import { PaymentsDataInterface } from '@/interfaces/payments.interface';
import { PartnerModel } from '@/models/system/partner.model';

export enum LOCAL_STORAGE_KEYS {
    PROFILE_SELECTED_AT = 'profile-seleted-at',
    CONFIGURATION = 'configuration',
    APP_SESSION_ID = 'app-session-id',
    APP_SESSION_EXPIRATION = 'app-session-expiration',
    OFFSET_TOP = 'offset-top',
    LAST_SESSION_UPDATE_TIME = 'last-session-update-time',
}

export enum SESSION_STORAGE_KEYS {
    PAYMENTS_DATA = 'payments-data',
    PARTNERSHIP_DATA = 'partnership-data',
}

export interface LocalStorageTypes {
    [LOCAL_STORAGE_KEYS.PROFILE_SELECTED_AT]: Date;
    [LOCAL_STORAGE_KEYS.CONFIGURATION]?: GetConfigurationOut;
    [LOCAL_STORAGE_KEYS.APP_SESSION_ID]: string;
    [LOCAL_STORAGE_KEYS.APP_SESSION_EXPIRATION]: number;
    [LOCAL_STORAGE_KEYS.OFFSET_TOP]: number;
    [LOCAL_STORAGE_KEYS.LAST_SESSION_UPDATE_TIME]: number;
}

export interface SessionStorageTypes {
    [SESSION_STORAGE_KEYS.PAYMENTS_DATA]: PaymentsDataInterface;
    [SESSION_STORAGE_KEYS.PARTNERSHIP_DATA]: PartnerModel | null;
}
