import { useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useState } from 'react';

const routesWhiteList = [
    ROUTES.PURCHASE,
    ROUTES.PURCHASE_STATUS,
    ROUTES.SUBCATEGORY,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS,
    ROUTES.VOD_EPISODE_WATCH,
    ROUTES.VOD_EPISODE_WITH_SEASON_WATCH,
    ROUTES.VOD_MOVIE_FULL_DETAILS,
    ROUTES.VOD_MOVIE_WATCH,
    ROUTES.VOD_WATCH,
];

export const useAgreeToContent = (isForAdults: boolean) => {
    const { prevRoute } = useRouter();
    const isFromIgnoredRoutes = prevRoute && routesWhiteList.includes(prevRoute);
    const showModal = isForAdults && !isFromIgnoredRoutes;

    const [showAgreeToContentModal, setShowAgreeToContentModal] = useState<boolean>(showModal);

    return { showAgreeToContentModal, setShowAgreeToContentModal };
};
