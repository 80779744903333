import { Router } from '@/routing';
import { ROUTES_CONFIG } from '@/routing/routes';
import { IRouter, RouteItemConfigInterface, ROUTES } from '@/routing/types';
import { matchPath } from 'react-router';

const matchRoute = (pathname: string, route: RouteItemConfigInterface) =>
    matchPath(pathname, {
        path: route.pattern,
        exact: true,
    });

export const createRouteProperties = (
    pathname: string,
    searchString = '',
    prevRoute: ROUTES,
    stack: ROUTES[],
): IRouter => {
    const routeConfig = ROUTES_CONFIG.find((route) => matchRoute(pathname, route));
    const match = routeConfig && matchRoute(pathname, routeConfig);
    const route = routeConfig?.name ?? ROUTES.NOT_FOUND;
    const query: IRouter['query'] = match?.params ?? {};
    const searchParams = new URLSearchParams(searchString);
    searchParams.forEach((value, key) => {
        query[key] = value;
    });

    if (!match || !routeConfig) {
        console.error('Route not matched for pathname', pathname);
        Router.pushRoute(ROUTES.NOT_FOUND);
    }
    return {
        asPath: pathname,
        query,
        route,
        prevRoute,
        stack,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const importAllPages = (requireContext: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const routesCache: any = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    requireContext.keys().forEach((path: any) => {
        const key = path.match(/^.\/(.*?)(?:\/index)?(.tsx|.ts)$/)[1];
        routesCache[key] = requireContext(path);
    });
    return routesCache;
};
