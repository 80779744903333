import React, { useEffect } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useRouter } from '@/routing';
import { MediaFullDetails } from '@/components/views/MediaDetails';
import { useBuildVodData } from '@/hooks/media/use-build-vod-data.hook';
import { useError } from '@/contexts/error/error.context';

const VodEpisodeFullDetails = () => {
    const { query } = useRouter();
    const { categoryId } = query;

    const MediaContent = () => {
        const { media, isFetching, fmc, cnc, error } = useBuildVodData();

        const { setError } = useError();

        useEffect(() => {
            if (error) {
                setError(error);
            }
        }, [error]);

        if (isFetching || !cnc) {
            return <ProcessingLoader isFetching={true} />;
        }

        return (
            <MediaFullDetails media={media} cnc={cnc} isMainCategory={!!categoryId} fmc={fmc[0]} />
        );
    };

    return (
        <BaseLayout fullscreen>
            <MediaContent />
        </BaseLayout>
    );
};

export default VodEpisodeFullDetails;
