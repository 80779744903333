import React, { createContext, useContext, useMemo } from 'react';
import { FavoritesContextProps, FavoritesContextProviderProps } from './types';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import { useGetFavoritesWithFlatNavigation } from '@/api/navigation/favorites/get-favorites-with-flat-navigation';
import { GetFavoritesWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getFavoritesWithFlatNavigationIn';

export const FavoritesContext = createContext<FavoritesContextProps>({
    favorites: undefined,
    isFetching: false,
});

const FavoritesContextProvider = ({ children }: FavoritesContextProviderProps) => {
    const { initialLimit } = process.env.pages.catalog;

    const { params } = useCatalogParams({ filterLists: [] });

    const favoritesParams =
        params.limit === initialLimit ? (params as GetFavoritesWithFlatNavigationIn) : null;

    const { data: favorites, isFetching } = useGetFavoritesWithFlatNavigation(favoritesParams);

    return useMemo(
        () => (
            <FavoritesContext.Provider value={{ favorites, isFetching }}>
                {children}
            </FavoritesContext.Provider>
        ),
        [favorites, isFetching, children],
    );
};

export const useFavoritesContext = () => useContext(FavoritesContext);

export default FavoritesContextProvider;
