import styled, { css, ThemeProps } from 'styled-components';

import { fillIcon } from '@/helpers/fill-icon.helper';
import { buildMediaQuery } from '@/helpers/media-query.helper';
import { responsiveFonts, responsiveSizes } from '@/targets/default/mediaQuery';

import {
    BadgeOwnProps,
    DotProps,
    METADATA_RESPONSIVE_KIND,
    METADATA_RESPONSIVE_TYPES,
    METADATA_TYPES,
    StyledIconWrapperProps,
    StyledSingleDataWrapperProps,
    StyledVerticalLineProps,
} from './types';

const buildFontSize = ($type: METADATA_TYPES) => {
    switch ($type) {
        case METADATA_TYPES.METADATA_ACCESS:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.xxsmall};
                color: ${({ theme }) => theme.text.color.secondary};
            `;
        case METADATA_TYPES.PRIMARY:
            return css`
                font-size: ${({ theme }) => theme.text.size.description};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case METADATA_TYPES.SECONDARY:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.medium};
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 400;
            `;
        case METADATA_TYPES.TERTIARY:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.small};
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 400;
            `;
        case METADATA_TYPES.TERTIARY_CONTRAST:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.small};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case METADATA_TYPES.COPYRIGHT:
            return css`
                font-size: ${({ theme }) => theme.text.size.copyright};
                color: ${({ theme }) => theme.text.color.copyright};
                font-weight: 400;
            `;
        case METADATA_TYPES.PACKET:
            return css`
                font-size: ${({ theme }) => theme.text.size.packetMetadata};
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 400;
            `;
        case METADATA_TYPES.EPG_OVERLAY:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.xsmall};
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case METADATA_TYPES.EPG_PREVIEW:
            return css`
                font-size: ${({ theme }) => theme.text.size.epgPreview.metadata};
            `;
        default:
            return css`
                font-size: ${({ theme }) => theme.text.size.metadata.small};
                color: ${({ theme }) => theme.text.color.metadataSmall};
                font-weight: 400;
            `;
    }
};
const buildResponsiveStyle = ($type?: METADATA_RESPONSIVE_TYPES) => {
    switch ($type) {
        case METADATA_RESPONSIVE_TYPES.PRIMARY:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.metadata)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
            `;
        case METADATA_RESPONSIVE_TYPES.TERTIARY:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.tertiary)}
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 400;
            `;
        case METADATA_RESPONSIVE_TYPES.DATE:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.payments.tertiary)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
                margin-right: 0;
            `;
        case METADATA_RESPONSIVE_TYPES.SECONDARY:
            return css`
                font-size: 1.3rem;
                color: ${({ theme }) => theme.text.color.metadata};
                font-weight: 400;
            `;
        case METADATA_RESPONSIVE_TYPES.METADATA_PRIMARY:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.metadata)}
                color: ${({ theme }) => theme.text.color.primary};
                font-weight: 400;
                ${buildMediaQuery('margin-bottom', responsiveSizes.mediaDetails.primaryMargin)}
            `;
        case METADATA_RESPONSIVE_TYPES.FULL_DETAILS_METADATA_PRIMARY:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.metadata)}
                color: ${({ theme }) => theme.text.color.primary};
                ${buildMediaQuery(
                    'margin-bottom',
                    responsiveSizes.mediaDetails.fullDetails.primaryMargin,
                )}
            `;
    }
};

const buildResponsiveFont = (mode?: METADATA_RESPONSIVE_KIND) => {
    switch (mode) {
        case METADATA_RESPONSIVE_KIND.LIST_ELEMENT_METADATA:
            return css`
                ${buildMediaQuery('font-size', responsiveFonts.listElementMetadata)}
                font-weight: 400;
            `;
    }
};

const StyledMetadataWrapper = styled.span`
    display: flex;
`;

const StyledSingleDataWrapper = styled.span<StyledSingleDataWrapperProps & ThemeProps<any>>`
    display: inline-block;
    vertical-align: middle;

    ${({ hasSeparator }) =>
        !hasSeparator &&
        css`
            &:not(:last-of-type) {
                &::after {
                    content: '. ';
                    white-space: pre;
                }
            }

            &:not(:first-of-type) {
                margin-left: -0.8rem;
            }
        `}

    ${({ $type, responsive }) =>
        responsive
            ? buildResponsiveStyle($type as METADATA_RESPONSIVE_TYPES)
            : buildFontSize($type as METADATA_TYPES)}
`;

const StyledVerticalLine = styled.span<StyledVerticalLineProps>`
    width: 0.2rem;
    border-right: 0.2rem solid ${({ theme }) => theme.text.color.separator};
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: inline-block;
    vertical-align: middle;
    ${({ responsive }) => (responsive ? 'padding: 0' : ' padding: 0.7rem 0')};
`;

const StyledDot = styled.div<ThemeProps<any>>`
    background-color: currentColor;
    border-radius: 50%;
    display: inline-block;
    height: 0.3rem;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.3rem;
`;

const StyledDotWrapper = styled.span<DotProps>(({ small }) => {
    const defaultDotStyles =
        !small &&
        css`
            @media (min-width: 360px) {
                margin-right: 1rem;
                margin-left: 1rem;
            }
        `;

    const smallDotStyles =
        small &&
        css`
            ${StyledDot} {
                width: 0.2rem;
                height: 0.2rem;
            }
        `;

    return css`
        position: relative;
        height: 100%;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        ${defaultDotStyles};
        ${smallDotStyles};
    `;
});

const StyledIconGroup = styled.span`
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
`;

const StyledIconWrapper = styled.span<StyledIconWrapperProps & ThemeProps<any>>`
    display: inline-block;
    vertical-align: middle;
    ${({ noMargin }) => (noMargin ? `margin: 0` : 'margin: 0 0.4rem')};
    font-size: 0;
    ${({ mainColor, theme }) =>
        mainColor ? `fill: ${theme.mainColor}` : fillIcon(theme.text.color.metadata)};
    transition: 0.2s;
    ${({ gutter }) =>
        !!gutter &&
        css`
            margin-bottom: ${gutter}rem;
        `}

    svg {
        height: ${({ $size }) => $size && `${$size}rem`};
        width: ${({ $width }) => ($width ? `${$width}rem` : null)};
        fill: ${({ targetColor, theme }) => targetColor && theme.mainColor};
    }
`;

const StyledBadgeDot = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const StyledBadge = styled.span<BadgeOwnProps>`
    display: inline-flex;
    vertical-align: middle;
    ${({ mode, responsive }) =>
        responsive
            ? buildResponsiveFont(mode as METADATA_RESPONSIVE_KIND)
            : buildFontSize(mode as METADATA_TYPES)};
    color: ${({ theme }) => theme.text.color.onAir};
`;

export {
    StyledMetadataWrapper,
    StyledSingleDataWrapper,
    StyledVerticalLine,
    StyledDotWrapper,
    StyledDot,
    StyledIconGroup,
    StyledIconWrapper,
    StyledBadgeDot,
    StyledBadge,
};
