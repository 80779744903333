import React from 'react';
import { PlayableButton } from '@/components/shared/Buttons';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGrid } from '@/components/views/Profile/IconGrid';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import { IChangeProfileContainer } from './types';

const MAX_PROFILES = process.env.user.maxProfiles;

export const ChangeProfileContainer = ({
    profiles,
    selectProfileById,
    isAccountPage = false,
}: IChangeProfileContainer) => {
    const allowAddNewProfile = profiles.length < process.env.user.maxProfiles;
    const headerText = isAccountPage
        ? t('account-menu.change-profile')
        : t('pages.profile.select-to-edit.header');

    return (
        <>
            <MainText text={headerText} mode={MAINTEXT_KIND.PAGE_TITLE} gutter={GUTTER.BIG} />

            <IconGrid
                data={profiles.map((profileData) => {
                    return {
                        iconId: profileData.profileId,
                        name: profileData.name,
                        iconUrl: profileData.avatarUrl,
                    };
                })}
                col={MAX_PROFILES}
                selectProfileById={selectProfileById}
                onSelectAction={(id) => {
                    Router.pushRoute(ROUTES.PROFILE, { profileId: id });
                }}
                isAccountPage={isAccountPage}
                showAddProfileCircle={isAccountPage && allowAddNewProfile}
            />

            {!isAccountPage && (
                <FormContainer>
                    {allowAddNewProfile && (
                        <PlayableButton
                            text={t('button.profile.add-profile')}
                            onButtonAction={() => {
                                Router.pushRoute(ROUTES.PROFILE_ADD);
                            }}
                            data-testing="button.profile.add-profile"
                        />
                    )}

                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={() => Router.back()}
                        data-testing="cancel-button-label"
                    />
                </FormContainer>
            )}
        </>
    );
};
