import { yupResolver } from '@/lib/yupResolver';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useChangePassword from '../../../../api/auth/use-change-password';
import t from '../../../../lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { AlertMessage, MESSAGE_TYPE } from '../../../shared/AlertMessage';
import { PlayableButton } from '../../../shared/Buttons';
import FormRow from '../../../shared/form/commons/form-row/form-row.common';
import { TextInput } from '../../../shared/FormElement/BaseElements/TextInput';
import { INPUT_TYPE } from '../../../shared/FormElement/BaseElements/TextInput/types';
import { ICONS } from '../../../shared/Icon';
import { NavWrapper } from '../styles';
import { useError } from '@/contexts/error/error.context';

interface FormData {
    password: string;
    newPassword: string;
    newPasswordCheck: string;
}

const schema = Yup.object().shape({
    password: Yup.string().required(t('form.validate.password-required')),
    newPassword: Yup.string()
        .required(t('form.validate.new-password-required'))
        .min(6, t('form.validate.password-min')),
    newPasswordCheck: Yup.string().when('newPassword', (newPassword: string, schema: any) => {
        return newPassword
            ? schema.oneOf([Yup.ref('newPassword')], t('form.validate.new-password-mismatch'))
            : schema;
    }),
});

export const ChangePasswordForm = () => {
    const { setError } = useError();
    const { register, handleSubmit, errors } = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const [mutate, { error }] = useChangePassword();

    const onSubmit = handleSubmit(async (data) => {
        const result = await mutate({
            oldPassword: data.password,
            newPassword: data.newPassword,
        });
        if (result.ok) {
            Router.pushRoute(ROUTES.ACCOUNT_CHANGE_PASSWORD_SUCCESS);
        }
    });

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <NavWrapper>
            <form onSubmit={onSubmit}>
                <FormRow>
                    <TextInput
                        name="password"
                        placeholder={t('form.placeholder.current-password')}
                        type={INPUT_TYPE.PASSWORD}
                        icon={ICONS.PASSWORD}
                        error={!!errors.password}
                        ref={register()}
                        defaultFocused
                        data-testing="form.placeholder.current-password"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="newPassword"
                        placeholder={t('form.placeholder.new-password')}
                        type={INPUT_TYPE.PASSWORD}
                        icon={ICONS.PASSWORD}
                        error={!!errors.newPassword}
                        ref={register()}
                        data-testing="form.placeholder.new-password"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="newPasswordCheck"
                        placeholder={t('form.placeholder.new-password-confirm')}
                        type={INPUT_TYPE.PASSWORD}
                        icon={ICONS.PASSWORD}
                        error={!!errors.newPasswordCheck}
                        ref={register()}
                        data-testing="form.placeholder.new-password-confirm"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        text={t('save-button-label')}
                        onButtonAction={() => onSubmit()}
                        data-testing="save-button-label"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        text={t('cancel-button-label')}
                        onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT)}
                        data-testing="cancel-button-label"
                    />
                </FormRow>
            </form>
            {renderFormErrors()}
        </NavWrapper>
    );
};
