import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AddAccountEmailForm } from '@/components/views/Account/AddEmail';
import React from 'react';

const EmailActivationCheckEmail = () => {
    return (
        <BaseLayout fullscreen defaultBg>
            <AddAccountEmailForm />
        </BaseLayout>
    );
};

export default EmailActivationCheckEmail;
