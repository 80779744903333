import { isServer } from '@/helpers/environment.helper';
import { useEffect, useState } from 'react';

type PageVisibility = 'visibilitychange' | 'msvisibilitychange' | 'webkitvisibilitychange';

export const usePageVisibility = () => {
    const [isVisible, setIsVisible] = useState(() => {
        return isServer ? false : document.visibilityState === 'visible';
    });

    const onVisibilityChange = () => setIsVisible(document.visibilityState === 'visible');

    useEffect(() => {
        if (!isServer) {
            const visibilityChange = getBrowserVisibilityProp();
            document.addEventListener(visibilityChange, onVisibilityChange, false);

            return () => {
                document.removeEventListener(visibilityChange, onVisibilityChange);
            };
        }
    });

    return isVisible;
};

const getBrowserVisibilityProp = (): PageVisibility => {
    // @ts-ignore
    if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitvisibilitychange';
    }

    return 'visibilitychange';
};
