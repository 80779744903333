import { StyledSingleDataWrapper } from './style';
import { MetadataComponentProps, METADATA_SEPARATION_TYPES } from './types';
import { Dot, VerticalLine } from './Separators';

function separator(separationType: METADATA_SEPARATION_TYPES) {
    switch (separationType) {
        case METADATA_SEPARATION_TYPES.DOT:
            return <Dot />;
        case METADATA_SEPARATION_TYPES.DOT_SMALL:
            return <Dot small />;
        case METADATA_SEPARATION_TYPES.LINE:
            return <VerticalLine responsive />;
        case METADATA_SEPARATION_TYPES.NONE:
            return null;
        default:
            return <Dot />;
    }
}

export const Metadata = ({
    data,
    type,
    isSafeHTML,
    responsive,
    separationType = METADATA_SEPARATION_TYPES.DOT,
}: MetadataComponentProps) => {
    const arrayData = typeof data === 'string' ? [data] : data;

    const separation = separator(separationType);

    const filtered = arrayData.filter((item) => item);

    return (
        <>
            {filtered.map(
                (singleData, index: number) =>
                    singleData && (
                        <StyledSingleDataWrapper
                            $type={type}
                            key={index}
                            responsive={responsive}
                            hasSeparator={!!separation}
                        >
                            {index !== 0 && data.length > 1 && separation}
                            {displayData(singleData, isSafeHTML)}
                        </StyledSingleDataWrapper>
                    ),
            )}
        </>
    );
};

const displayData = (singleData: string, isSafeHTML: boolean | undefined) => {
    if (isSafeHTML) {
        return <span dangerouslySetInnerHTML={{ __html: singleData }} />;
    }

    return singleData;
};
