import { ICONS } from '../../../Icon';
import { ThemeProps } from 'styled-components';
import { NavFuncInterface } from 'nav-tree';
import { GUTTER } from '../../../../../types/gutter.type';
import { CyProps } from '@/types/cypress.type';

export type TextInputOwnProps = {
    type?: INPUT_TYPE;
    placeholderDisplay?: PLACEHOLDER_DISPLAY;
    placeholder?: string;
    maxLength?: number;
    icon?: ICONS;
    error?: boolean;
    name?: string;
    gutter?: GUTTER;
    defaultFocused?: boolean;
    onChange?: (value: string) => string;
    onFocus?: (focus: boolean) => void;
    value?: string;
    onNav?: (isActive: boolean) => void;
    func?: NavFuncInterface;
    disabled?: boolean;
    format?: (value: string, cursor: number) => [string, number];
    normalize?: (value: string, cursor: number) => [string, number];
    withFormat?: boolean;
    disableVirtualKeyboard?: boolean;
} & CyProps;

export enum INPUT_TYPE {
    PASSWORD = 'password',
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    TEL = 'tel',
}
export enum PLACEHOLDER_DISPLAY {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}

export type InputFocusProps = {
    focus: boolean;
    placeholderDisplay?: PLACEHOLDER_DISPLAY;
};

export type InputFocusStyledProps = InputFocusProps & ThemeProps<any>;

export interface SuffixProps {
    iconPosition: boolean;
    portal: string;
}
