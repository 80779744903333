export enum IMAGE_DISPLAY_MODE {
    THUMBNAILS = 'thumbnails',
    POSTERS = 'posters',
}

export interface IDimensions {
    readonly thumbnails: {
        readonly width: number;
        readonly height: number;
    };
    readonly posters: {
        readonly width: number;
        readonly height: number;
    };
}

export type Size = {
    size: {
        width: number;
        height: number;
    };
};

export interface ImageInterface {
    type: 'jpg' | 'jpeg' | 'png' | 'svg' | 'jfif';
    src: string;
    size: Size['size'];
}
