export enum UNDERAGE_CLASSIFICATION {
    VULGARISMS = 'W',
    SEX = 'S',
    DRUGS = 'N',
    VIOLENCE = 'P',
}

export enum ACCESSIBILITY_FEATURES {
    AUDIO_DESCRIPTION = 'AD',
    SUBTITLES = 'N',
    SIGN_LANGUAGE = 'JM',
}

export enum ADDITIONAL_SIGNS_TYPES {
    UNDERAGE_CLASSIFICATION = 'underageClassification',
    ACCESSIBILITY_FEATURES = 'accessibilityFeatures',
}
