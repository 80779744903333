import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import t from '@/lib/i18n';
import { ICONS } from '@/components/shared/Icon';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { useGetCategory } from '@/api/navigation/category/get-category';
import useGetSubcategories from '@/api/navigation/category/get-subcategories';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { useBackAction } from '../use-back-action';
import { Nav } from 'nav-tree';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { handleNavFocus } from '@/helpers/nav-tree/focus-nav-id.helper';
import { isFavoritesButton } from '@/helpers/is-favorites-button.helper';
import { useFocusButton } from './use-focus-button.hook';
import { BUTTON_ID } from '@/types/button-id.type';

export const useMediaDetailsSubcategories = (
    media: MediaDetailsModel,
    focusRef?: RefObject<Nav>,
    cncMediaButtons?: false | MediaButtonInterface[],
    isEpg?: boolean,
) => {
    const { rightPanelOpen, setRightPanelOpen } = useRightPanel();
    const categoryId = media.keyCategoryId;
    const initialButtons = useMemo(
        () =>
            cncMediaButtons
                ? [...(media.playOrBuyButton ? [media.playOrBuyButton] : []), ...cncMediaButtons]
                : media.buttons,
        [JSON.stringify(cncMediaButtons), JSON.stringify(media.buttons)],
    );
    const favBtn = initialButtons.find(isFavoritesButton);

    const [mediaButtons, setMediaButtons] = useState<MediaButtonInterface[]>(() => initialButtons);

    const requestParams = categoryId ? { catid: categoryId } : undefined;
    const { data, isFetching } = useGetSubcategories(requestParams);
    const { data: categoryData } = useGetCategory(requestParams);
    const subcategoriesMenuTitle = categoryData?.getSubCategoriesLabel() ?? t('series');

    const handler = () => {
        setRightPanelOpen(false);

        handleNavFocus(BUTTON_ID.CATALOG_BTN, focusRef);
    };

    useBackAction(handler, rightPanelOpen);

    useFocusButton(focusRef);

    const handleSubcategoriesButtonClick = () => {
        if (categoryId && data.getResults().length === 1) {
            const subcategoryId = data.getResults()[0].getId();
            media.goToSubcategory(subcategoryId);
        } else {
            setRightPanelOpen(true);
        }
    };

    const handleRightPanel = (isActive: boolean) => {
        if (isActive && rightPanelOpen) {
            setRightPanelOpen(false);
        }
    };

    const seriesButton = {
        id: BUTTON_ID.CATALOG_BTN,
        icon: ICONS.CATALOG_BTN,
        text: subcategoriesMenuTitle,
        onButtonAction: handleSubcategoriesButtonClick,
    };

    const catalogButton = {
        id: BUTTON_ID.CATALOG_BTN,
        icon: ICONS.CATALOG_BTN,
        text: t('all'),
        onButtonAction: () => {
            if (categoryId) {
                media.goToSubcategory(categoryId);
            }
        },
    };

    const injectButton = useCallback(
        (mediaButtons: MediaButtonInterface[], button: MediaButtonInterface) => {
            const tempBtns = [...mediaButtons];
            tempBtns.splice(mediaButtons.length - 1, 0, button);
            return tempBtns;
        },
        [],
    );

    useEffect(() => {
        if (isFetching) return;

        if (data.getResults().length !== 0) {
            setMediaButtons(injectButton(initialButtons, seriesButton));
        }

        if (data.getResults().length === 0 && categoryId) {
            setMediaButtons(injectButton(initialButtons, catalogButton));
        }
    }, [isFetching, data, categoryId, initialButtons]);

    useEffect(() => {
        if (!favBtn) return;

        const favBtnExists = mediaButtons.some((btn) => btn === favBtn);
        if (favBtnExists) return;

        setMediaButtons((prev) => {
            const idx = prev.findIndex(isFavoritesButton);
            const tempArr = [...prev];
            tempArr.splice(idx, 1, favBtn);
            return tempArr;
        });
    }, [favBtn, mediaButtons]);

    return {
        data,
        mediaButtons,
        subcategoriesMenuTitle,
        rightPanelOpen,
        isFetching,
        setRightPanelOpen,
        handleRightPanel,
    };
};
