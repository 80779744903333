import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ClientContextTokenProps } from '@/contexts/client-context-token/types';
import { useCookieService } from '@/contexts/cookies/cookies.hooks';
import {
    COOKIE_CLIENT_CONTEXT_TOKEN_EXPIRE_MINUTES,
    COOKIE_CLIENT_CONTEXT_TOKEN_NAME,
} from '@/contexts/cookies/cookies.context';
import { useGetClientContextToken } from '@/api/system/use-get-client-context-token';
import { add } from 'date-fns';
import { useLocalStorage } from '@/hooks/use-local-storage.hook';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';

export const ClientContextTokenContext = createContext<{
    token: string | undefined;
    lastUpdateTime: number | undefined;
    setUpdateTime: (updateTime: number) => void;
}>({
    token: undefined,
    lastUpdateTime: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpdateTime: () => {},
});

const ClientContextTokenProvider = ({ children, initialToken }: ClientContextTokenProps) => {
    const cookieService = useCookieService();
    const clientContextTokenCookie = cookieService.get(COOKIE_CLIENT_CONTEXT_TOKEN_NAME);
    const [token, setToken] = useState<string>(clientContextTokenCookie);
    const [lastUpdateTime, setLastUpdateTime] = useLocalStorage(
        LOCAL_STORAGE_KEYS.LAST_SESSION_UPDATE_TIME,
    );
    const { data: clientContextToken, refetch, isFetching } = useGetClientContextToken(!token);

    const setUpdateTime = useCallback(
        (updateTime: number) => {
            const currentTime = new Date().getTime();
            const sinceLastUpdate = !lastUpdateTime || lastUpdateTime < updateTime;
            const isAfterUpdate = updateTime < currentTime;

            if (!isFetching && sinceLastUpdate && isAfterUpdate) {
                refetch?.();
                setLastUpdateTime(updateTime);
            }
        },
        [lastUpdateTime, refetch, isFetching],
    );

    const providerValue = useMemo(
        () => ({
            token: token ?? initialToken,
            lastUpdateTime,
            setUpdateTime,
        }),
        [token, initialToken, lastUpdateTime],
    );

    useEffect(() => {
        if (!clientContextToken) {
            return;
        }

        setToken(clientContextToken);
    }, [clientContextToken, setToken]);

    useEffect(() => {
        if (token) {
            cookieService.set(COOKIE_CLIENT_CONTEXT_TOKEN_NAME, token, {
                expires: add(new Date(), { minutes: COOKIE_CLIENT_CONTEXT_TOKEN_EXPIRE_MINUTES }),
            });
        }
    }, [token]);

    return (
        <ClientContextTokenContext.Provider value={providerValue}>
            {children}
        </ClientContextTokenContext.Provider>
    );
};

export default ClientContextTokenProvider;
