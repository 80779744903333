import { PlayableButton } from '@/components/shared/Buttons';
import { CircleStepItem } from '@/components/shared/CircleStepList/CircleStepItem';
import { VerticalSeparatorWithText } from '@/components/shared/VerticalSeparatorWithText';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import QRCode from 'react-qr-code';
import { TextWithBold } from '../TextWithBold';
import {
    FormSection,
    FormWrapper,
    Title,
    ButtonWrapper,
    Wrapper,
    CircleStepWrapper,
    QRCodeContainer,
    Text,
    QR_CODE_SIZE,
    QRWrapper,
} from './styles';

export const FormWithQr = () => {
    const urlWithCode = `https://${t(
        'domain-url',
    )}/pomoc/kontakt-formularz?contextType="Zgłoszenie z telewizora Smart TV"`;

    const { helpdeskEmail } = process.env.contacts;
    const steps = [
        {
            value: 1,
            text: (
                <TextWithBold
                    text={t('pages.customer-care.step-1')}
                    boldText={helpdeskEmail.label}
                />
            ),
        },
        {
            value: 2,
            text: <p>{t('pages.customer-care.step-2')}</p>,
        },
        {
            value: 3,
            text: (
                <TextWithBold
                    text={t('pages.customer-care.step-3')}
                    boldText={t('pages.customer-care.about-device')}
                />
            ),
        },
    ];

    return (
        <FormWrapper>
            <FormSection>
                <Title>{t('pages.customer-care.header')}</Title>
            </FormSection>
            <FormSection>
                <Wrapper>
                    <QRWrapper>
                        <QRCodeContainer>
                            <QRCode value={urlWithCode} size={QR_CODE_SIZE} />
                        </QRCodeContainer>
                        <Text>{t('pages.customer-care.qr-image-description')}</Text>
                    </QRWrapper>
                    <VerticalSeparatorWithText text="LUB" />
                    <CircleStepWrapper>
                        {steps.map((step, index) => {
                            return <CircleStepItem step={step} key={index} />;
                        })}
                    </CircleStepWrapper>
                </Wrapper>
            </FormSection>
            <FormSection separator={true}>
                <ButtonWrapper>
                    <PlayableButton
                        defaultFocused
                        text={t('pages.customer-care.about-device')}
                        onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT_HELP)}
                        gutter={GUTTER.NONE}
                    />
                </ButtonWrapper>
            </FormSection>
        </FormWrapper>
    );
};
