import { getProductPromise } from '@/api/navigation/get-product';
import { PacketFilterCategory } from '@/components/browser/shared/PacketDetails/types';
import { GetMediaPromiseFunc } from '@/hooks/use-get-media-api.type';
import { GetAllPacketsOut } from '@/interfaces/from-schemas/navigation/getAllPacketsOut';
import { GetProductOut, ProductIdSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import { FilterWithTreeNavigationSchema } from '@/interfaces/from-schemas/navigation/searchContentWithTreeNavigationOut';
import { PacketDetailsPageProps } from '@/interfaces/packet-details.interface';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { LiveModel } from '@/models/live/live.model';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { PacketContentList } from '@/models/packets/packet-content-list.model';
import PacketContentWithFlatNavigationModel from '@/models/packets/packet-content-with-flat-navigation.model';
import { PacketDetailsModel } from '@/models/packets/packet-details.model';
import { ProductModel } from '@/models/products/product.model';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { DATA_POSITION } from '@/types/list-element-metadata.type';

export const buildPacketProps = async (
    packetIdStr: string,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<{ packetOut: GetProductOut } | { redirect: true }> => {
    const product: ProductIdSchema = { id: packetIdStr, type: 'multiple', subType: 'packet' };

    const packetOut = await getProductPromise({ product }, getMediaApiPromise);

    if (!packetOut) {
        return {
            redirect: true,
        };
    }

    return { packetOut };
};

export function buildPacketDetailsModel(
    {
        packet,
        productAccess,
        prePurchaseData,
        possessions,
        productSubscriptions,
        authSession,
    }: PacketDetailsPageProps,
    additionalParams?: { isSsr?: boolean; isLogged?: boolean },
): PacketDetailsModel | null {
    if (!packet || !productAccess) return null;

    const { isSsr, isLogged } = { ...additionalParams };

    return new PacketDetailsModel(
        packet,
        productAccess,
        prePurchaseData,
        possessions,
        productSubscriptions,
        authSession,
        isSsr,
        isLogged,
    );
}

export function doesPacketExist(allPackets: GetAllPacketsOut, packetId: string): boolean {
    return allPackets?.some(({ id }: { id: string }) => id === packetId);
}

export function returnNewCollection(
    packetData: PacketContentList | PacketContentWithFlatNavigationModel,
    metaDataPosition?: DATA_POSITION,
) {
    const { col, postersCol } = process.env.grid;

    let columns;

    const newCollection = packetData.getResults().map((packet) => {
        const isMovie = packet instanceof MediaListItemModel && packet.getIsMovie();
        const isLive = packet instanceof LiveModel;

        if (isMovie) {
            packet.imageDisplayMode = IMAGE_DISPLAY_MODE.POSTERS;
            columns = postersCol;
        }

        const packetElement = new ListElementModel(packet);

        if (isMovie) {
            packetElement.setDataPosition(DATA_POSITION.UNDER);
        } else {
            metaDataPosition && packetElement.setDataPosition(metaDataPosition);
        }

        isLive && packetElement.setShowMetadata(true);

        return packetElement;
    });

    return { newCollection, columns: columns || col };
}

export const currCategoryIsCatalog = (packetCategories: PacketFilterCategory[], index: number) => {
    // Jeśli są tylko dwie kategorie, to ta druga ma mieć nazwę "Katalog"
    return packetCategories.length === 2 && index === 1;
};

export const buildPacketFilterCategories = (
    allCategories: FilterWithTreeNavigationSchema[],
    detailsData: ProductModel,
) => {
    const categoriesArray =
        allCategories?.map((cat) => ({ name: cat.name, id: cat.name.toLowerCase() })) || [];
    const offers = detailsData
        .getRecommendedOffers()
        .map((off) => ({ name: off.getName(), id: off.getId() }));
    const packetCategories = [...categoriesArray, ...offers];
    return packetCategories;
};

export const getCurrentOffer = (detailsData: ProductModel, activeCategory: string) => {
    return detailsData.getOffers().find((offer) => offer.getId() === activeCategory);
};
