import { useIdle } from '@/hooks/use-idle.hook';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { PlayableDynamicButton } from '../../../Buttons';
import { ICONS } from '../../../Icon';
import { AdTime } from './AdTime';
import { ControlsBase } from '../ControlsBase';
import { GetRidOfAdsButton } from './GetRidOfAdsButton';
import { PlayPauseButton } from '../shared/PlayPauseButton';
import { FlexItem, FlexBox } from '../shared/styles';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { MediaTitle } from '../shared/MediaTitle';

const AdTopBar = () => (
    <PlayableDynamicButton
        icon={ICONS.ARROW_LEFT}
        text={t('back-button-label')}
        onButtonAction={() => Router.back()}
        style={{ display: 'inline-block' }}
        data-testing="back-button-label"
    />
);

const AdBottomBar = () => {
    const { ad } = usePlayerStatus();
    const isPreroll = ad?.pod.type === 'preroll';
    const label = isPreroll ? `${t('player.next')}:` : `${t('player.watch')}:`;

    return (
        <FlexBox gutter="7rem" horizontalMargin="11rem">
            <FlexItem allowOverflow>
                <PlayPauseButton defaultFocused />
            </FlexItem>
            <FlexItem grow shrink paddingLeft="6rem" paddingRight="60rem">
                <MediaTitle label={label} />
            </FlexItem>
        </FlexBox>
    );
};

const AdFixedOverlay = () => {
    const { ridOfAdsButtonVisible } = process.env.player;

    return (
        <>
            <AdTime />
            {ridOfAdsButtonVisible && <GetRidOfAdsButton />}
        </>
    );
};

export const AdControls = () => {
    const {
        player: {
            transition: { idleDelay },
        },
    } = process.env;

    const idle = useIdle(idleDelay);
    return (
        <ControlsBase
            visible={!idle}
            topBar={<AdTopBar />}
            bottomBar={<AdBottomBar />}
            fixedOverlay={<AdFixedOverlay />}
        />
    );
};
