import { RuleDataInterface } from '@/interfaces/rule-data.interface';
import { PrePurchaseOfferModel } from '@/models/payments/pre-purchase-offer.model';
import { PrePurchaseOptionModel } from '@/models/payments/pre-purchase-option.model';
import { PrePurchaseProductModel } from '@/models/payments/pre-purchase-product.model';
import { OfferOptionModel } from '@/models/products/offer-option.model';
import { OfferPricingPlanModel } from '@/models/products/offer-pricing-plan.model';
import { ProductOfferModel } from '@/models/products/product-offer.model';
import { ProductModel } from '@/models/products/product.model';
import { RulesModel } from '@/models/products/rules.model';

export function extractRules({
    product,
    offer,
    option,
    offerPricingPlan,
}: {
    product: ProductModel | PrePurchaseProductModel;
    offer: ProductOfferModel | PrePurchaseOfferModel;
    option: OfferOptionModel | PrePurchaseOptionModel;
    offerPricingPlan?: OfferPricingPlanModel;
}): RulesModel[] {
    const productRules = product.getRules();
    const offerRules = offer.getRules();
    const optionRules = option.getRules();

    if (offerPricingPlan) {
        const pricingPlanRules = offerPricingPlan.getPricingPlan().getRules();

        return removeDuplicateRules([
            ...productRules,
            ...offerRules,
            ...pricingPlanRules,
            ...optionRules,
        ]);
    }

    return removeDuplicateRules([...productRules, ...offerRules, ...optionRules]);
}

export function extractRulesWithAllOptions({
    product,
    offer,
    offerPricingPlan,
}: {
    product: ProductModel | PrePurchaseProductModel;
    offer: ProductOfferModel | PrePurchaseOfferModel;
    offerPricingPlan?: OfferPricingPlanModel;
}): RulesModel[] {
    const productRules = product.getRules();
    const offerRules = offer.getRules();
    const optionsRules: RulesModel[] = [];

    if (offerPricingPlan) {
        const pricingPlan = offerPricingPlan.getPricingPlan();
        const pricingPlanRules = pricingPlan.getRules();
        const optionTypes = pricingPlan.getOptionTypes();
        const emptyOptionTypes = optionTypes.length === 0;

        offer.getOptions().forEach((option: OfferOptionModel | PrePurchaseOptionModel) => {
            if (emptyOptionTypes || optionTypes.includes(option.getType())) {
                optionsRules.push(...option.getRules());
            }
        });

        return removeDuplicateRules([
            ...productRules,
            ...offerRules,
            ...pricingPlanRules,
            ...optionsRules,
        ]);
    }

    offer.getOptions().forEach((option: OfferOptionModel | PrePurchaseOptionModel) => {
        optionsRules.push(...option.getRules());
    });

    return removeDuplicateRules([...productRules, ...offerRules, ...optionsRules]);
}

export function removeDuplicateRules(rules: RulesModel[]): RulesModel[] {
    const uniqueRules: RulesModel[] = [];

    rules.forEach((rule) => {
        const id = rule.getId();
        const isRuleExists = uniqueRules.some((uniqueRule) => uniqueRule.getId() === id);

        if (!isRuleExists) {
            uniqueRules.push(rule);
        }
    });

    return uniqueRules;
}

export function isRuleNotValid({ required, checked }: RuleDataInterface): boolean {
    return required && !checked;
}
