import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import React from 'react';
import { PacketCategoriesMenuItem } from './PacketCategoriesMenuItem';
import { RightPanel } from './RightPanel';
import { PacketCategoriesMenuProps } from './types';

const PacketCategoriesMenuRaw = ({
    name,
    categories,
    isVisible,
    onAction,
    selectedCategoryId,
    onShowMenu,
}: PacketCategoriesMenuProps) => {
    const { query } = useRouter();

    const handleAction = (categoryId: string) => {
        Router.pushRoute(ROUTES.PACKET_CONTENT, { packetId: selectedId, categoryId });
        onAction?.();
    };

    const menuItems = categories?.map((category) => {
        return (
            <PacketCategoriesMenuItem
                key={category.value}
                subcategory={category}
                onAction={handleAction}
                selected={category.value === query.categoryId}
            />
        );
    });

    const selectedId = query?.packetId || selectedCategoryId;

    if (isVisible) {
        const focusedElement =
            categories?.find((c) => c.value === query.categoryId)?.value || categories[0]?.value;

        return (
            <RightPanel
                selectedId={selectedId}
                focused={focusedElement}
                menuItems={menuItems}
                onShowMenu={onShowMenu}
                name={name}
            />
        );
    }

    return null;
};

export const PacketCategoriesMenu = React.memo(PacketCategoriesMenuRaw);
