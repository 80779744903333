import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetProductIn } from '@/interfaces/from-schemas/navigation/getProductIn';
import { ProductModel } from '@/models/products/product.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useGetProduct = (params?: GetProductIn): IApiResponse<ProductModel | undefined> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_PRODUCT,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: data && new ProductModel(data),
        isFetching,
        error,
    };
};

export default useGetProduct;
