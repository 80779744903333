import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';
import { useChat } from '@/components/browser/shared/Chat/useChat.hook';

export interface IChatContextProvider {
    children: ReactNode;
}

export interface IChatContext {
    isVisible: boolean;
    setChatVisible: (value: boolean) => void;
    setIsActiveSession: Dispatch<SetStateAction<boolean>>;
}

export const ChatContext = createContext<IChatContext>({
    isVisible: false,
    setChatVisible: (value: boolean) => value,
    setIsActiveSession: () => false,
});

export const ChatContextProvider = ({ children }: IChatContextProvider) => {
    const { isVisible, setVisible, setIsActiveSession } = useChat();

    return (
        <ChatContext.Provider
            value={{
                isVisible,
                setChatVisible: setVisible,
                setIsActiveSession,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = () => useContext(ChatContext);
