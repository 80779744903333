import { LiveModel } from './live.model';
import {
    GetLiveChannelsOut,
    LiveChannelListItemSchema,
} from '@/interfaces/from-schemas/navigation/getLiveChannelsOut';

class LiveAllModel {
    private readonly offset: number = 0;
    private readonly count: number = 0;
    private readonly limit: number = 0;
    private readonly total: number = 0;
    private readonly results: LiveModel[] = [];
    constructor(definition: GetLiveChannelsOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
            this.results = [];
            definition.results.forEach((value: LiveChannelListItemSchema) => {
                this.results.push(new LiveModel(value));
            });
        }
    }
    public getOffset(): number {
        return this.offset;
    }
    public getCount(): number {
        return this.count;
    }
    public getLimit(): number {
        return this.limit;
    }
    public getTotal(): number {
        return this.total;
    }
    public getResults(): LiveModel[] {
        return this.results;
    }
}

export default LiveAllModel;
