import { useGetScrollSliderConfig } from '@/components/browser/shared/Slider/use-get-scroll-slider-config';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

export const useGetRecomendationListInitialLimit = (
    imageDisplayMode?: IMAGE_DISPLAY_MODE | null,
) => {
    const { spatialNavigation } = process.env;

    const scrollConfig = useGetScrollSliderConfig(imageDisplayMode);

    if (spatialNavigation) {
        return process.env.slider.initialLimit;
    }

    return scrollConfig.initialLimit;
};
