import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, StrippedInParams } from '@/hooks/use-get-media-api.type';
import { BuyIn } from '@/interfaces/from-schemas/payments/buyIn';
import { BuyOut } from '@/interfaces/from-schemas/payments/buyOut';

export const useBuy = (params?: StrippedInParams<BuyIn>): IApiResponse<BuyOut | undefined> => {
    const keyhook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_BUY,
        params,
    );

    const { data, isFetching, error } = useGetMediaApi(keyhook, {
        appendAuthData: true,
    });

    return { data, isFetching, error };
};
