import { HomeMenuContext } from '@/contexts/menu/home.menu.context';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { getLastStringFromUrl } from '@/helpers/home-menu/get-last-string-from-url.helper';
import { handleNavFocus } from '@/helpers/nav-tree/focus-nav-id.helper';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { BUTTON_ID } from '@/types/button-id.type';
import { useCallback, useContext, useMemo } from 'react';
import { useBackAction } from './use-back-action';

export const useExitAppConfirmation = (includeMenu = false, enabled = true) => {
    const { menuFocused, menuRef } = useContext(HomeMenuContext);
    const { rightPanelOpen } = useRightPanel();
    const { platform } = usePlatform();
    const { os } = platform;
    const router = useRouter();

    const { supportedExitAppConfirmationOS } = process.env;
    const isSupportedOS = os && supportedExitAppConfirmationOS?.includes(os);
    const enable = enabled && !rightPanelOpen && isSupportedOS;
    const menuItem = useMemo(() => getLastStringFromUrl(router), [router]) as BUTTON_ID;

    const handler = useCallback(() => {
        if (!enable) return;

        if (includeMenu && !menuFocused && menuRef) {
            handleNavFocus(menuItem, menuRef);
            return;
        }

        Router.pushRoute(ROUTES.EXIT_APP_CONFIRMATION);
    }, [includeMenu, menuFocused, enable]);

    useBackAction(handler, enable);
};
