import { Message } from '@/components/shared//Message';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { DeleteUserForm } from '@/components/views/Account/DeleteUser';
import { PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { navVertical } from 'nav-tree';
import React from 'react';

const DeleteUser = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer func={navVertical}>
                <MainText
                    text={t('pages.account.delete-user.header')}
                    mode={MAINTEXT_KIND.PAGE_TITLE}
                />
                <Message description={t('pages.account.delete-user.description-1')} />
                <DeleteUserForm />
            </PageContainer>
        </BaseLayout>
    );
};

export default DeleteUser;
