import { PlayableButton } from '@/components/shared//Buttons';
import { Message } from '@/components/shared//Message';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import { PageContext } from '@/contexts/page/page.context';
import { ReferrerContext } from '@/contexts/referrer/referrer.context';
import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import { navHorizontal, navVertical } from 'nav-tree';
import React, { useContext } from 'react';
import { useAppLogout } from '@/hooks/user/use-app-logout.hook';

const AccountLogout = () => {
    const { clearContext } = useContext(PageContext);
    const { setReferrer } = useContext(ReferrerContext);

    const { logout } = useAppLogout(ROUTES.WELCOME, () => {
        clearContext();
        setReferrer(ROUTES.WELCOME);
    });

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT_LOGOUT} />
            <PageContainer func={navVertical}>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('pages.account.logout.header')}
                    gutter={GUTTER.NONE}
                />
                <NavWrapper func={navHorizontal}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={logout}
                        defaultFocused
                        data-testing="ok-button-label"
                    />
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default AccountLogout;
