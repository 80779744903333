import { EVENT, EventParams, EventsWithoutParams } from '../types';
import reject from 'lodash/reject';
import { IHttpResponse } from '@/services/http-request.service';

export type EventHandler<E extends EVENT> = (params: EventParams<E>) => Promise<IHttpResponse>;

export default class HandlersManager {
    private handlers: {
        [E in EVENT]?: EventHandler<E>[];
    } = {};

    public addListener<E extends EVENT>(event: E, handler: EventHandler<E>): void {
        const eventHandlers = this.handlers[event];

        if (eventHandlers) {
            eventHandlers.push(handler);
        } else {
            (this.handlers[event] as EventHandler<E>[]) = [handler];
        }
    }

    public removeListener<E extends EVENT>(event: E, handler: EventHandler<E>): void {
        (this.handlers[event] as EventHandler<E>[]) = reject(
            this.handlers[event],
            (h: EventHandler<E>) => {
                return h === handler;
            },
        );
    }

    dispach(event: EventsWithoutParams): Promise<unknown>;
    dispach<E extends EVENT>(event: E, params?: EventParams<E>): Promise<unknown>;
    public dispach<E extends EVENT>(event: E, params?: EventParams<E>): Promise<unknown> {
        const validationResult = validateDispatch(event, params);

        if (!validationResult.status) {
            console.error(validationResult.message);
            return Promise.reject(validationResult.message);
        }

        const handlers = (this.handlers[event] || []) as EventHandler<E>[];

        return Promise.all(
            handlers.map((handler) => {
                return handler(params || ({} as EventParams<E>));
            }),
        );
    }
}

function validateDispatch<E extends EVENT>(event: E, _params?: EventParams<E>) {
    switch (event) {
        case EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED:
            {
                const params = _params as
                    | EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED>
                    | undefined;
                if (!params || !params.contentItem || !params.list) {
                    return {
                        status: false,
                        message: 'contentItem and list are mandatory',
                    };
                }
            }
            break;
    }

    return {
        status: true,
    };
}
