import { fillIcon } from '@/helpers/fill-icon.helper';
import styled, { css, keyframes } from 'styled-components';
import { ICON_MODE, IconCastContainerProps } from './types';

const waitingModeAnimation = (theme: any) => keyframes`
    0% {
        ${fillIcon(theme.text.color.whiteTone0)}
    }
    25% {
        ${fillIcon(theme.mainColor)}
    }
    50% {
        ${fillIcon(theme.text.color.whiteTone0)}
    }
    75% {
        ${fillIcon(theme.mainColor)}
    }
    100% {
        ${fillIcon(theme.text.color.whiteTone0)}
    }
`;

export const IconCastContainer = styled.div<IconCastContainerProps>`
    ${({ activeBowIndex, mode, theme }) =>
        mode === ICON_MODE.WAITING &&
        css`
            path:nth-child(${activeBowIndex}) {
                ${fillIcon(theme.mainColor)}
            }
        `}
    ${({ mode, theme }) =>
        mode === ICON_MODE.ACTIVE &&
        css`
            path {
                ${fillIcon(theme.mainColor)}
            }

            path:nth-child(4) {
                ${({ theme }) =>
                    css`
                        animation: ${waitingModeAnimation(theme)} 1s linear !important;
                    `}
            }
        `}
`;
