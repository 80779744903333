import React from 'react';
import { PacketDetailsProps } from './types';
import { PacketFullDetailsContent } from './PacketFullDetailsContent';
import { FullDetailsWrapper } from '@/components/shared/FullDetailsWrapper';
import { useMediaBackground } from '@/hooks/media/use-media-background.hook';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';

export const PacketFullDetails = ({ packet }: PacketDetailsProps) => {
    useMediaBackground(packet, SHADE_MODE.FULL);

    return (
        <FullDetailsWrapper>
            <PacketFullDetailsContent packet={packet} />
        </FullDetailsWrapper>
    );
};
