import { InputManager } from '@/input';
import { useCallback, useEffect, useState } from 'react';

export const useMagicMouseState = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const cursorVisibilityChangeListener = useCallback((event) => {
        setIsVisible(event.detail.visibility);
    }, []);

    const keyDownListener = useCallback((event: KeyboardEvent) => {
        const { CURSOR_SHOW, CURSOR_HIDE } = InputManager.KEY;

        switch (event.keyCode) {
            case CURSOR_SHOW:
                event.preventDefault();
                setIsVisible(true);
                break;
            case CURSOR_HIDE:
                event.preventDefault();
                setIsVisible(false);
                break;
        }
    }, []);

    useEffect(() => {
        // webOS TV 2.x or later
        window.addEventListener('cursorStateChange', cursorVisibilityChangeListener);

        // webOS TV 1.x
        window.addEventListener('keydown', keyDownListener);

        return () => {
            window.removeEventListener('cursorStateChange', cursorVisibilityChangeListener);
            window.removeEventListener('keydown', keyDownListener);
        };
    }, [cursorVisibilityChangeListener, keyDownListener]);

    return { isVisible };
};
