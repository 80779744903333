import { ChannelModel } from '@/models/channels/channel.model';
import ChannelListModel from '@/models/channels/channel-list.model';
import PacketContentWithFlatNavigationModel from '@/models/packets/packet-content-with-flat-navigation.model';
import { SearchContentModel } from '@/models/search/search-content.model';

export const getChannelsIds = (
    elements: ChannelListModel | PacketContentWithFlatNavigationModel | SearchContentModel,
): string[] => {
    if (elements instanceof SearchContentModel) {
        const searchContentChannelsIds = elements
            .getResults()
            .reduce<string[]>((filtered, element) => {
                if (element instanceof ChannelModel) {
                    filtered.push(element.getId());
                }

                return filtered;
            }, []);

        return searchContentChannelsIds;
    }

    return (elements?.getResults() as ChannelModel[]).map((channel) => channel.getId());
};
