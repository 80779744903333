import styled from 'styled-components';

import { device } from '@/targets/default/mediaQuery';

import { IAdditionalSignsIconWrapper } from './types';

export const AdditionalSignsIconWrapper = styled.span<IAdditionalSignsIconWrapper>`
    font-size: 0;
    svg {
        height: ${({ $size }) => ($size ? `${$size}rem` : '1.8rem')};
        width: auto;
    }
`;

export const PlayerAdditionalSignsIconWrapper = styled.span<IAdditionalSignsIconWrapper>`
    margin-left: 0.4rem;

    svg {
        height: 1.1rem;
        width: 1.1rem;

        @media ${device.tablet} {
            height: 1.4rem;
            width: 1.4rem;
        }
    }
`;
