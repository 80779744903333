import { CollectionsListConfig } from '@/components/shared/CollectionsList/types';
import { CatalogConfig } from '@/components/views/common/types';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { LoaderContext } from '@/contexts/loader/loader.context';
import { PageContext } from '@/contexts/page/page.context';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { batchedUpdates } from '@/helpers/batched-updates.helper';

export const useCombinedView = (
    collectionsConfig: CollectionsListConfig,
    catalogConfig: CatalogConfig,
) => {
    const { setShowLoader } = useContext(LoaderContext);
    const {
        isCatalog,
        setIsCatalog,
        noLists,
        setNoLists,
        isRecommended,
        setIsRecommended,
    } = useContext(PageContext);
    const { setGrid } = useContext(CatalogContext);

    const catId = catalogConfig.catalogParams.catid;

    useEffect(() => {
        setShowLoader(true);
    }, []);

    useEffect(() => {
        return () => setNoLists(false);
    }, [catId]);

    const onCategoryView = useCallback(() => {
        batchedUpdates(() => {
            setShowLoader(true);
            setIsCatalog((prev) => !prev);
            setGrid((prev) => ({ ...prev, filters: [], rowIndex: 0, colIndex: 0 }));
        });
    }, [isCatalog]);

    const onRecoView = useCallback(() => {
        batchedUpdates(() => {
            setShowLoader(true);
            setIsCatalog(false);
            setIsRecommended(true);
            setGrid((prev) => ({ ...prev, filters: [], rowIndex: 0, colIndex: 0 }));
        });
    }, [isRecommended]);

    const handleNoLists = useCallback(() => {
        setIsCatalog(true);
        setIsRecommended(false);
        setNoLists(true);
    }, []);

    const updatedCatalogConfig = useMemo(
        () => (noLists ? { ...catalogConfig, withLists: false } : catalogConfig),
        [noLists, collectionsConfig],
    );

    return {
        isCatalog,
        updatedCatalogConfig,
        onCategoryView,
        handleNoLists,
        isRecommended,
        onRecoView,
    };
};
