import { isServer } from '@/helpers/environment.helper';
import { LOCAL_STORAGE_KEYS } from '@/types/storage.type';

export const clearLocalStorage = (key?: LOCAL_STORAGE_KEYS) => {
    if (isServer) return;

    if (key) {
        return window.localStorage.removeItem(key);
    }

    window.localStorage.clear();
};
