import { usePlayerInstance } from '@/hooks/player/use-player-instance';
import { useTimeshiftingDelta } from '@/hooks/player/use-timeshifting-delta';
import t from '@/lib/i18n';
import { Debug } from '@/utils/debug.util';
import { useCallback } from 'react';
import { GoToLiveButtonContainer } from './styles';

export const GoToLiveButton = () => {
    const { calculateDeltaTime } = useTimeshiftingDelta();

    const debug = new Debug('GoToLiveButton');
    const instance = usePlayerInstance();

    const onButtonActionHandler = useCallback(() => {
        try {
            instance.user.seek(calculateDeltaTime());
        } catch (e) {
            debug.error('seeking', e);
        }
    }, [calculateDeltaTime, instance]);

    return (
        <GoToLiveButtonContainer
            onClick={onButtonActionHandler}
            onEnterDown={onButtonActionHandler}
            autofocusOn={['focus', 'mouseenter']}
        >
            {t('player.watch-on-live')}
        </GoToLiveButtonContainer>
    );
};
