import { useMagicMouseState } from '@/hooks/use-magic-mouse-state.hook';
import React, { createContext } from 'react';
import { MagicMouseContextProps, MagicMouseContextProviderProps } from './types';

export const MagicMouseContext = createContext<MagicMouseContextProps>({
    isVisible: false,
});

const MagicMouseContextProvider = ({ children }: MagicMouseContextProviderProps) => {
    const { isVisible } = useMagicMouseState();

    return (
        <MagicMouseContext.Provider value={{ isVisible }}>{children}</MagicMouseContext.Provider>
    );
};

export default MagicMouseContextProvider;
