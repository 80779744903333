import { useMemo } from 'react';

import useGetChannelsProgram from '@/api/navigation/channels/use-get-channel-list-program';

// TODO odświeżanie kiedy podczas oglądania nadejdzie kolejny dzień
export const useWalkingEPG = ({
    channelId,
    refTime,
    offset = 0,
    count = Infinity,
    date,
    enabled = true,
}: {
    refTime?: number;
    channelId: string;
    offset?: number;
    count?: number;
    date?: string;
    enabled?: boolean;
}) => {
    const params = enabled ? { channelIds: [channelId], date } : null;

    const { data, isFetching, error } = useGetChannelsProgram(params);

    const programData = useMemo(
        () =>
            (data.getResults()[channelId] || []).sort(
                (a, b) => a.getStartTimeTimestamp() - b.getStartTimeTimestamp(),
            ),
        [data],
    );

    const normalizedRefTime = Math.round((refTime ?? new Date().getTime()) / 1000) * 1000;

    const onAirIndex = useMemo(
        () =>
            Math.max(
                0,
                programData.findIndex((a) => a.isTimeIn(normalizedRefTime)),
            ),
        [programData, normalizedRefTime],
    );

    const programs = useMemo(() => [...programData].splice(onAirIndex + offset, count), [
        programData,
        onAirIndex,
        offset,
        count,
    ]);

    return {
        liveProgram: programData[onAirIndex],
        programs,
        epgPrograms: programData,
        isFetching,
        error,
    };
};
