import differenceInMinutes from 'date-fns/differenceInMinutes';

export const calculateProgress = ({ progress, startTime, endTime }: any): number => {
    if (progress >= 0) {
        return progress;
    } else if (startTime && endTime) {
        return Math.round(
            (differenceInMinutes(new Date(), new Date(startTime)) /
                differenceInMinutes(new Date(endTime), new Date(startTime))) *
                100,
        );
    }
    return 0;
};
