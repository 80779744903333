import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { ConfirmVerificationOut } from '@/interfaces/from-schemas/auth/confirmVerificationOut';

interface IConfirmVerification {
    verificationCode: string;
}

const useConfirmVerification = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.CONFIRM_USER_VERIFICATION,
        null,
    );

    return useGetMediaApiMutation<IConfirmVerification, ConfirmVerificationOut>(keyHook, {
        appendAuthData: true,
        updateAuthSession: true,
    });
};

export default useConfirmVerification;
