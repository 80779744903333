import { useDebouncedState } from '../use-debounced-state.hook';
import { SUBMIT_TIMEOUT } from '@/constants/timeouts';

export const useAllowSubmit = (pending: boolean) => {
    const idle = !pending;

    const allowSubmit = useDebouncedState(idle, (idle) => (idle ? SUBMIT_TIMEOUT : 0));

    return allowSubmit;
};
