import React, { useMemo, useState } from 'react';
import { ImageStyled } from './styles';
import { ImageProps } from './types';
import { Placeholder } from '@/components/shared/Image/index';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { isServer } from '@/helpers/environment.helper';

const Image = ({ src, width, height, alt, lazy, withPlaceholder, responsive }: ImageProps) => {
    const { isBot } = usePlatform();
    const [loaded, setLoaded] = useState(false);
    const [loadError, setLoadError] = useState(false);
    const placeholderVisible = isBot ? false : !!withPlaceholder && (!loaded || loadError);

    const onLoad = () => {
        if (withPlaceholder) {
            setLoaded(true);
        }
    };

    const onLoadError = () => {
        if (withPlaceholder) {
            setLoadError(true);
        }
    };

    const buildImage = useMemo(() => {
        if (lazy) {
            if (isServer) {
                return <ImageStyled src={src} $width={width} $height={height} />;
            }
            return (
                <ImageStyled
                    data-src={src}
                    onLoad={onLoad}
                    onError={onLoadError}
                    $width={width}
                    $height={placeholderVisible ? '0' : height}
                    alt={alt}
                    responsive={responsive}
                />
            );
        }

        return (
            <ImageStyled
                src={src}
                onLoad={onLoad}
                onError={onLoadError}
                $width={width}
                $height={placeholderVisible ? '0' : height}
                alt={alt}
                responsive={responsive}
            />
        );
    }, [alt, height, lazy, src, width, placeholderVisible, responsive]);

    return (
        <>
            {placeholderVisible && (
                <Placeholder
                    imageDisplayMode={withPlaceholder}
                    isLoading={!loaded && !loadError}
                    isBot={isBot}
                />
            )}
            {buildImage}
        </>
    );
};

export const ImageMemo = React.memo(Image);
