import { ListElement } from '@/components/shared/ListElement';
import { SliderTitle } from '@/components/shared/Slider';
import { navHorizontal, NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE } from 'nav-tree';
import React from 'react';
import {
    StyledSlider,
    StyledMyPurchasesList,
    StyledSliderTitle,
    StyledSliderTitleInner,
} from './styles';
import { MyPurchasesSliderProps } from './types';
import { useListElementClicked } from '@/hooks/use-list-element-clicked';

const MyPurchasesSliderRaw = ({
    defaultFocused,
    sliderElements,
    title,
}: MyPurchasesSliderProps) => {
    const { onListElementClickedHandler } = useListElementClicked();

    const buildSlider = () => (
        <StyledSlider
            func={navHorizontal}
            scrollOptions={{
                isScrollable: true,
                direction: NAV_SCROLL_DIRECTION.HORIZONTAL,
                mode: NAV_SCROLL_MODE.CENTER,
            }}
            restoreLastFocus
            defaultFocused={defaultFocused}
            autofocusOn={['mouseenter']}
        >
            <StyledMyPurchasesList>
                {sliderElements.map((el, index) => {
                    const isFocused = defaultFocused && index === 0;
                    return (
                        <ListElement
                            defaultFocused={isFocused}
                            key={index}
                            itemData={el}
                            data-testing={`list.item.${index}`}
                            focusedRow={true}
                            onItemClickedHandler={onListElementClickedHandler}
                        />
                    );
                })}
            </StyledMyPurchasesList>
        </StyledSlider>
    );

    if (sliderElements.length === 0) {
        return null;
    }

    return (
        <>
            <StyledSliderTitle>
                <StyledSliderTitleInner>
                    <SliderTitle text={title} />
                </StyledSliderTitleInner>
            </StyledSliderTitle>
            {buildSlider()}
        </>
    );
};

export const MyPurchasesSlider = React.memo(MyPurchasesSliderRaw);
