var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { instanceOf } from 'prop-types';
import { NAV_SCROLL_MODE, NAVIGATION_KEYS } from './types';
import { getImperativeScrollPosition, getScrollPosition, getScrollPositionToOffscreenFocusedNone, isRestoreFocusAvailable, isScrollableDown, isScrollableLeft, isScrollableRight, isScrollableUp, navDynamic } from './helpers';
import NavTree from './NavTree';
if (typeof window !== 'undefined') {
    require('element-scroll-polyfill');
}
var DEFAULT_FOCUSED_CLASS = 'nav-focused';
var Nav = /** @class */ (function (_super) {
    __extends(Nav, _super);
    function Nav(props) {
        var _this = _super.call(this, props) || this;
        _this.focusCallback = _this.onFocusEvent.bind(_this);
        _this.mouseEnterCallback = _this.onMouseEnterEvent.bind(_this);
        _this.state = {
            focused: false,
        };
        return _this;
    }
    Nav.prototype.UNSAFE_componentWillMount = function () {
        if (this.props.tree) {
            // root tree
            this.tree = this.props.tree;
        }
        else if (this.context.tree) {
            // child tree
            this.tree = this.context.tree.addNode(this.props.navId);
        }
        else {
            if (process.env.NODE_ENV !== 'production') {
                console.warn('No navigation tree provided. `NavTree` instance should be passed as a `tree` prop to the root (top level) `Nav` component');
            }
            return;
        }
        this.tree.resolveFunc = this.navFunc.bind(this);
        this.tree.onNavCallback = this.onNav.bind(this);
        if (this.props.defaultFocused) {
            this.tree.focus();
        }
    };
    Nav.prototype.componentWillUnmount = function () {
        if (this.tree && this.tree.parent) {
            this.tree.parent.removeNode(this.tree.id);
        }
    };
    Nav.prototype.getChildContext = function () {
        return {
            tree: this.tree,
        };
    };
    Nav.prototype.imperativeFocus = function (path) {
        var node = this.tree.getNode(path);
        node === null || node === void 0 ? void 0 : node.focus();
    };
    Nav.prototype.imperativeScroll = function (direction) {
        var _a = this.props, scrollOptions = _a.scrollOptions, onNavScroll = _a.onNavScroll;
        var navNode = this.tree && this.tree.el;
        var focusedNode = null;
        // Select first node since we need only child size
        if (this.tree) {
            var index = Object.keys(this.tree.nodes)[0];
            focusedNode = this.tree.nodes[index] && this.tree.nodes[index].el;
        }
        if (!scrollOptions || !navNode || !focusedNode) {
            return;
        }
        var _b = getImperativeScrollPosition(direction, scrollOptions, navNode, focusedNode), top = _b.top, left = _b.left;
        navNode.scroll(left, top);
        if (onNavScroll) {
            onNavScroll({
                isScrollableUp: isScrollableUp(navNode),
                isScrollableDown: isScrollableDown(navNode),
                isScrollableLeft: isScrollableLeft(navNode),
                isScrollableRight: isScrollableRight(navNode)
            });
        }
    };
    Nav.prototype.scrollToOffScreenFocusedElement = function () {
        var _a = this.props, scrollOptions = _a.scrollOptions, onNavScroll = _a.onNavScroll;
        var navNode = this.tree && this.tree.el;
        var focusedNode = this.tree
            && this.tree.nodes[this.lastFocusId]
            && this.tree.nodes[this.lastFocusId].el;
        if (!scrollOptions || !navNode || !focusedNode) {
            return;
        }
        if (navNode.style.overflow !== 'hidden') {
            navNode.style.overflow = 'hidden';
        }
        var _b = getScrollPositionToOffscreenFocusedNone(scrollOptions, navNode, focusedNode), top = _b.top, left = _b.left;
        if (top !== null || left !== null) {
            navNode.scroll(left || 0, top || 0);
        }
        if (onNavScroll) {
            onNavScroll({
                isScrollableUp: isScrollableUp(navNode),
                isScrollableDown: isScrollableDown(navNode),
                isScrollableLeft: isScrollableLeft(navNode),
                isScrollableRight: isScrollableRight(navNode)
            });
        }
    };
    Nav.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, className = _a.className, func = _a.func, onEnterDown = _a.onEnterDown, onNav = _a.onNav, onNavScroll = _a.onNavScroll, navId = _a.navId, tree = _a.tree, defaultFocused = _a.defaultFocused, restoreLastFocus = _a.restoreLastFocus, lockNavInside = _a.lockNavInside, scrollOptions = _a.scrollOptions, autofocusOn = _a.autofocusOn, onMouseEnter = _a.onMouseEnter, onFocus = _a.onFocus, htmlElementProps = __rest(_a, ["children", "className", "func", "onEnterDown", "onNav", "onNavScroll", "navId", "tree", "defaultFocused", "restoreLastFocus", "lockNavInside", "scrollOptions", "autofocusOn", "onMouseEnter", "onFocus"]);
        return (React.createElement("div", __assign({ onMouseEnter: this.mouseEnterCallback, onFocus: this.focusCallback, ref: function (ref) {
                if (_this.tree)
                    _this.tree.el = ref;
            }, className: this.className }, htmlElementProps), children));
    };
    Object.defineProperty(Nav.prototype, "className", {
        get: function () {
            var className = this.props.className;
            var focused = this.state.focused;
            var foucsedClassName = className
                ? className + " " + DEFAULT_FOCUSED_CLASS
                : DEFAULT_FOCUSED_CLASS;
            return focused
                ? foucsedClassName
                : className;
        },
        enumerable: false,
        configurable: true
    });
    Nav.prototype.navFunc = function (key, node, prevFocusedNode) {
        var _a = this.props, func = _a.func, onEnterDown = _a.onEnterDown, restoreLastFocus = _a.restoreLastFocus, lockNavInside = _a.lockNavInside;
        if (onEnterDown && key === NAVIGATION_KEYS.ENTER) {
            onEnterDown();
            return null;
        }
        if (restoreLastFocus && this.lastFocusId && isRestoreFocusAvailable(node, this.lastFocusId)) {
            return this.lastFocusId;
        }
        if (lockNavInside && node.focusedNode !== null) {
            node.focus(node.getFocusedPath());
            return null;
        }
        return (func || navDynamic)(key, node, prevFocusedNode);
    };
    Nav.prototype.onNav = function (path) {
        var _a = this.props, scrollOptions = _a.scrollOptions, onNav = _a.onNav, restoreLastFocus = _a.restoreLastFocus;
        var focusedId = path && path[0];
        this.setState({
            focused: path !== false,
        });
        if (onNav) {
            onNav(path !== false);
        }
        if (focusedId) {
            if (scrollOptions && scrollOptions.isScrollable) {
                this.onScrollable(focusedId);
            }
            if (restoreLastFocus) {
                this.lastFocusId = focusedId;
            }
        }
    };
    Nav.prototype.onFocusEvent = function (event) {
        var _a;
        var _b = this.props, autofocusOn = _b.autofocusOn, onFocus = _b.onFocus;
        if (autofocusOn === null || autofocusOn === void 0 ? void 0 : autofocusOn.includes("focus")) {
            (_a = this.tree) === null || _a === void 0 ? void 0 : _a.focus();
        }
        return onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
    };
    Nav.prototype.onMouseEnterEvent = function (event) {
        var _a;
        var _b = this.props, autofocusOn = _b.autofocusOn, onMouseEnter = _b.onMouseEnter;
        var focusedElements = document.querySelectorAll(':focus');
        if ((autofocusOn === null || autofocusOn === void 0 ? void 0 : autofocusOn.includes("mouseenter")) && !focusedElements.length) {
            (_a = this.tree) === null || _a === void 0 ? void 0 : _a.focus();
        }
        return onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter(event);
    };
    Nav.prototype.onScrollable = function (focusedId) {
        var _a = this.props, scrollOptions = _a.scrollOptions, onNavScroll = _a.onNavScroll;
        var navNode = this.tree && this.tree.el;
        var focusedNode = this.tree
            && this.tree.nodes[focusedId]
            && this.tree.nodes[focusedId].el;
        if (!scrollOptions || !navNode || !focusedNode) {
            return;
        }
        var mode = scrollOptions.mode;
        if (navNode.style.overflow !== 'hidden') {
            navNode.style.overflow = 'hidden';
        }
        if (mode !== NAV_SCROLL_MODE.IMPERATIVE) {
            var _b = getScrollPosition(scrollOptions, navNode, focusedNode), top_1 = _b.top, left = _b.left;
            navNode.scroll(left || 0, top_1 || 0);
        }
        if (onNavScroll) {
            onNavScroll({
                isScrollableUp: isScrollableUp(navNode),
                isScrollableDown: isScrollableDown(navNode),
                isScrollableLeft: isScrollableLeft(navNode),
                isScrollableRight: isScrollableRight(navNode)
            });
        }
    };
    Nav.contextTypes = {
        tree: instanceOf(NavTree),
    };
    Nav.childContextTypes = {
        tree: instanceOf(NavTree),
    };
    return Nav;
}(React.PureComponent));
export default Nav;
