export enum PACKET_ACCESS_STATUS {
    HAS_ACCESS = 'HAS_ACCESS',
    HAS_ACCESS_UNAVAILABLE = 'HAS_ACCESS_UNAVAILABLE',
    HAS_ACCESS_CYCLIC = 'HAS_ACCESS_CYCLIC',
    HAS_ACCESS_CYCLIC_CANCELLED = 'HAS_ACCESS_CYCLIC_CANCELLED',
    HAS_ACCESS_PLUS = 'HAS_ACCESS_PLUS',
    HAS_ACCESS_WITH_UPGRADE = 'HAS_ACCESS_WITH_UPGRADE',
    NO_ACCESS = 'NO_ACCESS',
    NO_ACCESS_PLUS = 'NO_ACCESS_PLUS',
    NO_ACCESS_SSR = 'NO_ACCESS_SSR',
    NO_ACCESS_SSR_LOGGED = 'NO_ACCESS_SSR_LOGGED',
    NO_ACCESS_UNPAID = 'NO_ACCESS_UNPAID',
    NO_ACCESS_UNAVAILABLE = 'NO_ACCESS_UNAVAILABLE',
}
