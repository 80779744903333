import { buildEmptyListsSafeBufor } from '@/helpers/pages/empty-arrays/build-empty-lists-safe-bufor.helper';
import { checkIfCollectionIsInView } from '@/helpers/pages/empty-arrays/check-if-collection-is-in-view.helper';
import { checkIfEmptyArrIsNear } from '@/helpers/pages/empty-arrays/check-if-empty-arr-is-near.helper';
import { useMemo } from 'react';

export const useLoadCollection = ({
    emptyListsArr,
    activeRowCtx,
    rowIndex,
}: {
    emptyListsArr: number[];
    activeRowCtx: number | null;
    rowIndex: number;
}) => {
    return useMemo(() => {
        if (activeRowCtx === null) {
            return false;
        }

        const isInView = checkIfCollectionIsInView(activeRowCtx, rowIndex);

        if (isInView) {
            return true;
        }

        const safeBufor = buildEmptyListsSafeBufor(emptyListsArr, activeRowCtx);

        const isEmptyNear = checkIfEmptyArrIsNear(emptyListsArr, safeBufor, activeRowCtx, rowIndex);

        return isEmptyNear;
    }, [emptyListsArr, activeRowCtx, rowIndex]);
};
