import styled from 'styled-components';
export const QR_CODE_SIZE = 224;
export const QR_CODE_PADDING = 32;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 15rem;
`;
export const FormSection = styled.div<{ separator?: boolean }>`
    margin: 0 0 5rem 0;
    display: flex;
    justify-content: center;
    border-top: ${({ separator, theme }) =>
        separator ? `1px solid ${theme.tokenInfo.separatorColor}` : 'none'};
`;
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding-left: 16rem;
`;

export const Title = styled.div`
    font-size: 4.6rem;
    color: ${({ theme }) => theme.text.color.primary};
`;
export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6.2rem;
`;
export const CircleStepWrapper = styled.div`
    margin-left: 10rem;
`;
export const QRCodeContainer = styled.div`
    margin-left: 27rem;
    margin: auto;
    padding: ${QR_CODE_PADDING}px;
    width: ${QR_CODE_SIZE + 2 * QR_CODE_PADDING}px;
    height: ${QR_CODE_SIZE + 2 * QR_CODE_PADDING}px;
    background-color: #fff;
`;
export const QRWrapper = styled.div`
    margin-right: 10rem;
`;
export const Text = styled.div`
    margin-top: 3.4rem;
    width: 36.6rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    color: ${({ theme }) => theme.text.color.secondary};
`;
