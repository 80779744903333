import { useClearContext } from '@/hooks/use-clear-context.hook';
import { useRouter } from '@/routing';
import { RouteParams, ROUTES } from '@/routing/types';
import React, { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { MenuItemMemo } from './MenuItem';
import { MenuProfileItemMemo } from './MenuProfileItem';
import { FinalArrayType, MenuItemsProps } from './types';
import { Router } from '@/routing';
import { buildNextUrl } from '@/helpers/url.helper';

const MenuItemsRaw = ({ menuActive, onFocusPath, menuItemsCombined }: MenuItemsProps) => {
    const clearContext = useClearContext();
    const router = useRouter();

    const handleRedirect = useCallback(
        (route: ROUTES, params?: RouteParams, query?: RouteParams) => {
            const isTheSamePage = route === router.route && isEqual(params, router.query);

            /*
            Każdorazowe czyszczenie kontekstu podczas kliknięcia w element menu jest tymczasowym rozwiązaniem
            problemów z odświeżaniem stanu i ustawianiem fokusa nawigacyjnego podczas klikania w dynamiczne ścieżki menu, np:
            serial => film => serial.
            */

            if (!isTheSamePage || router.route === ROUTES.NOT_FOUND) {
                if (route === ROUTES.CUSTOMER_CARE_HELP) {
                    const next = buildNextUrl();

                    return Router.pushRoute(route, params, { next: next });
                }
                Router.pushRoute(route, params, query);
            }

            clearContext(route);
        },
        [clearContext, router],
    );

    const buildMenuItems = useCallback(
        (array: FinalArrayType) => {
            return (
                <>
                    {array.map((item, index) => {
                        if (!item) return;

                        return (
                            <MenuItemMemo
                                key={index}
                                item={item}
                                isMenuActive={menuActive}
                                onFocusPath={onFocusPath}
                                onRedirect={handleRedirect}
                                data-testing={item?.getDataTesting?.()}
                            />
                        );
                    })}

                    <MenuProfileItemMemo
                        key={`menu-profile-item-${array.length}`}
                        isMenuActive={menuActive}
                        onFocusPath={onFocusPath}
                    />
                </>
            );
        },
        [menuActive, onFocusPath, handleRedirect],
    );

    return buildMenuItems(menuItemsCombined);
};

export const MenuItems = React.memo(MenuItemsRaw);
