import React from 'react';
import { PageContainer } from '@/components/views/Account/styles';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ROUTES } from '@/routing/types';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Router } from '@/routing';
import useConnect from '@/api/auth/use-connect';
import useConfirmConnection from '@/api/auth/use-confirm-connection';
import VerifyPhoneNoForm from '@/components/views/VerifyPhoneNumber/VerifyPhoneNoForm';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import t from '@/lib/i18n';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';

const ModifyPhonePage = () => {
    const [mutateConnect, { error: connectError, isFetching: connectIsFetching }] = useConnect();
    const [
        mutateConfirmConnection,
        { error: confirmError, isFetching: confirmIsFetching },
    ] = useConfirmConnection();

    const connectPhoneNumber = async (phoneNo: string) => {
        const connectionData = { authProvider: AUTH_PROVIDERS.PLUS, msisdn: phoneNo };
        return await mutateConnect({ connectionData });
    };

    const confirmConnection = async (verificationCode: string) => {
        return await mutateConfirmConnection({ verificationCode });
    };

    const handleSuccess = async () => {
        await Router.pushRoute(ROUTES.PLUS_ZONE_ADD_PHONE_SUCCESS);
    };

    const handleCancel = () => {
        Router.back();
    };

    const renderFormErrors = () => {
        const errorToDisplay = connectError?.getUserMessage() || confirmError?.getUserMessage();

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.PLUS_ZONE} />
            <PageContainer>
                <VerifyPhoneNoForm
                    pageTitle={t('pages.plus-zone.add.page-title')}
                    isLoading={connectIsFetching || confirmIsFetching}
                    handleVerifyPhoneNo={connectPhoneNumber}
                    handleConfirmPhoneNo={confirmConnection}
                    handleSuccess={handleSuccess}
                    handleCancel={handleCancel}
                />
                {renderFormErrors()}
            </PageContainer>
        </BaseLayout>
    );
};

export default ModifyPhonePage;
