import {
    AcquireOptionProductSubscriptionDataSchema,
    DotpayCardAcquireOptionProductSubscriptionDataSchema,
    GetProductSubscriptionsOut,
    PlusbillAcquireOptionProductSubscriptionDataSchema,
} from '@/interfaces/from-schemas/payments/getProductSubscriptionsOut';
import { ProductIdSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { ProductSubscriptionsStatus } from '@/interfaces/product-subscriptions.interface';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';

export class ProductSubscriptionModel {
    private readonly id: string;
    private readonly product: ProductIdSchema;
    private readonly status: ProductSubscriptionsStatus;
    private readonly offer;
    private readonly lastPaymentDate?: string;
    private readonly nextPaymentDate?: string;
    private readonly option?:
        | AcquireOptionProductSubscriptionDataSchema
        | DotpayCardAcquireOptionProductSubscriptionDataSchema
        | PlusbillAcquireOptionProductSubscriptionDataSchema;

    constructor(definition: GetProductSubscriptionsOut[number]) {
        this.id = definition.id;
        this.product = definition.product;
        this.status = definition.status;
        this.offer = definition.offer;
        this.lastPaymentDate = definition.lastPaymentDate;
        this.nextPaymentDate = definition.nextPaymentDate;
        this.option = definition.option;
    }

    public getId(): string {
        return this.id;
    }

    public getProduct(): ProductIdSchema {
        return this.product;
    }

    public getProductId(): ProductIdInterface {
        const { id, type, subType } = this.product;

        return {
            id,
            type: type as PRODUCT_TYPES,
            subType: subType as PRODUCT_SUBTYPES,
        };
    }

    public getOffer() {
        return this.offer;
    }

    public getStatus(): ProductSubscriptionsStatus {
        return this.status;
    }

    public getLastPaymentDate(): string | undefined {
        return this.lastPaymentDate;
    }

    public getNextPaymentDate(): string | undefined {
        return this.nextPaymentDate;
    }

    public getOption():
        | AcquireOptionProductSubscriptionDataSchema
        | DotpayCardAcquireOptionProductSubscriptionDataSchema
        | PlusbillAcquireOptionProductSubscriptionDataSchema
        | undefined {
        return this.option;
    }
}
