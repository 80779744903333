import { isServer } from './environment.helper';

export const isTouchDevice = () => {
    return (
        !!(
            !isServer &&
            ('ontouchstart' in window ||
                (typeof document !== 'undefined' && 'touchstart' in document))
        ) || !!(typeof navigator !== 'undefined' && navigator.maxTouchPoints)
    );
};
