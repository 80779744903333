import t from '@/lib/i18n';
import { translateRoute } from '@/routing/helpers';
import { PATHS, ROUTES } from '@/routing/types';
import config from 'target/config';
import { buildNextUrl } from '@/helpers/url.helper';

const route = translateRoute('/cookies-preferences');
const next = buildNextUrl();
const url = `${route}?next=${next}`;

export const rodoConfig: IRodoConfig = {
    content: `<h2>Drogi Użytkowniku</h2>
            <h3>Czy chcesz otrzymywać dopasowane do Ciebie treści naszych partnerów?</h3>
            <p>
                Za Twoją zgodą dostęp do informacji o korzystaniu przez Ciebie z naszego serwisu
                mogą mieć nasi Zaufani Partnerzy, którzy wykorzystują pliki cookie lub podobne
                technologie do zbierania i przetwarzania danych osobowych w celu personalizowania
                treści. Jeśli chcesz otrzymywać dopasowane do Ciebie treści, zaznacz poniższą zgodę.
                Uwaga: Zgoda jest dobrowolna, nawet jeśli jej nie wyrazisz, możesz
                nadal korzystać z serwisu.
            </p>
            <h3>Twoje prawa</h3>
            <p>
                Przysługuje Ci prawo do dostępu do danych, ich usunięcia, ograniczenia
                przetwarzania, przenoszenia, sprzeciwu, sprostowania oraz cofnięcia zgód w każdym
                czasie.
            </p>
            <h3>Sprawdź szczegóły</h3>
            <p>
                Szczegółowe informacje dotyczące przetwarzania danych osobowych oraz przysługujących
                Ci uprawnień, informacje dotyczące plików cookie lub podobnych technologii, w tym
                dotyczące możliwości zarządzania ustawieniami prywatności, znajdują się w Polityce
                Prywatności.
            </p>
            <p>
                <strong>Przypominamy:</strong> W każdej chwili możesz zmienić zdanie i wycofać
                wyrażoną wcześniej zgodę w sekcji Zarządzanie zgodami. Pamiętaj jednak, że wycofanie
                zgody nie działa wstecz i obowiązuje od momentu jej wycofania.
            </p>
            <p>
                <strong>Szczegóły:</strong> Więcej na temat zasad przetwarzania Twoich danych, w tym
                Zaufanych Partnerów i zarządzania ustawieniami prywatności, znajdziesz w Polityce
                Prywatności
            </p>`,
    consent: `<p>
                Wyrażam zgodę na przechowywanie lub uzyskiwanie przez ${t('rodo-administrator-e')} 
                dostępu do informacji przechowywanej w moim urządzeniu przez 
                ${t('rodo-administrator-e-short')} w celach reklamowych, 
                w tym profilowania reklam oraz wyświetlanych mi treści.
              </p>
              <p>
                Wyrażam zgodę na przechowywanie lub uzyskiwanie przez 
                Zaufanych Partnerów ${t('rodo-administrator-i')} dostępu do plików 
                cookie przechowywanych w moim urządzeniu przez 
                ${t('rodo-administrator-e-short')} w celach reklamowych, w tym ich profilowania.
            </p>`,
    notes: `<p>
                <strong>Przypominamy:</strong> W każdej chwili możesz zmienić zdanie i wycofać
                wyrażoną wcześniej zgodę w sekcji Moje Zgody. Pamiętaj jednak, że wycofanie
                zgody nie działa wstecz i obowiązuje od momentu jej wycofania.
            </p>
            <p>
                <strong>Szczegóły:</strong> Więcej na temat zasad przetwarzania Twoich danych, w tym
                Zaufanych Partnerów i zarządzania ustawieniami prywatności, znajdziesz w 
                <a target="_blank" rel="noreferrer noopener" 
                href="${config.privacyLink}">
                    Polityce Prywatności
                </a>
            </p>`,
    reminder: `<h2>Drogi Użytkowniku</h2>
            <p>Potrzebujemy Twojej zgody, aby polecać treści lepiej dopasowane do Ciebie</p>`,
    cookies: `<p>
                Dbamy o Twoją prywatność. Twoje dane są u nas bezpieczne. W każdej chwili
                możesz zmienić zdanie i wycofać wyrażoną wcześniej zgodę, klikając 
                <a href='${url}'>Preferencje cookies</a> w stopce serwisu.
            </p>`,
};

interface IRodoConfig {
    consent: string;
    content: string;
    notes: string;
    reminder: string;
    cookies: string;
}

export const reminderWhiteList = [
    ROUTES.HOME,
    ROUTES.VOD,
    ROUTES.VOD_MOVIE_DETAILS,
    ROUTES.VOD_MOVIE_FULL_DETAILS,
    ROUTES.VOD_EPISODE_DETAILS,
    ROUTES.VOD_EPISODE_FULL_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS,
    ROUTES.VOD_EPISODE_WITH_SEASON_FULL_DETAILS,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY,
    ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS,
    ROUTES.LIVE,
    ROUTES.LIVE_DETAILS,
    ROUTES.LIVE_FULL_DETAILS,
    ROUTES.CHANNELS_TV,
    ROUTES.CHANNEL_TV_DETAILS,
    ROUTES.CHANNEL_TV_FULL_DETAILS,
    ROUTES.PACKETS,
    ROUTES.PACKET_CONTENT,
    ROUTES.PACKET_DETAILS,
    ROUTES.PACKET_FULL_DETAILS,
    ROUTES.MENU_MY_LIST,
];

export const modalBlackList = [
    ROUTES.COOKIES_PREFERENCES,
    ROUTES.ACCOUNT_ACTIVATION,
    ROUTES.REGISTRATION_QUICK_MAGIC_LINK,
    PATHS.PROMOTION,
    PATHS.PROMOTION_STATUS,
    ROUTES.GOODBYE,
];
