import { useCallback, useMemo } from 'react';
import { useWalkingEPG } from '@/hooks/player/use-walking-epg';
import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';

export const useWalkingEPGData = (channelId: string) => {
    const { date, twoHoursFromNow, canScrollForward } = useHourRangeContext();

    const currentDate = useMemo(() => new Date(), []);
    const maxDate = useMemo(() => {
        const tempDate = new Date(currentDate);
        tempDate.setDate(tempDate.getDate() + 6);
        return tempDate;
    }, [currentDate]);

    const nextDate = useMemo(() => {
        const selectedDate = new Date(date);
        const nextDate = new Date(selectedDate);
        nextDate.setDate(selectedDate.getDate() + 1);

        if (nextDate > maxDate || nextDate.getDate() === selectedDate.getDate()) {
            return selectedDate;
        }
        return nextDate;
    }, [date, maxDate]);

    const nextDateString = formatDate(nextDate.toISOString(), FORMAT_DATE_MODE.ISO_8601);

    const { epgPrograms, isFetching: isFetchingEPGPrograms } = useWalkingEPG({
        channelId,
        date: formatDate(date.toISOString(), FORMAT_DATE_MODE.ISO_8601),
    });

    const shouldFetchNextDay = useCallback(() => {
        if (!canScrollForward || epgPrograms.length === 0) {
            return false;
        }

        const lastProgram = epgPrograms[epgPrograms.length - 1];
        const lastProgramEndTimestamp = lastProgram.getEndTimeTimestamp();

        if (lastProgramEndTimestamp) {
            return twoHoursFromNow.getTime() >= lastProgramEndTimestamp;
        }

        const lastProgramStartTime = lastProgram.getStartTimeTimestamp();

        return date.getTime() >= lastProgramStartTime;
    }, [epgPrograms, twoHoursFromNow, date, canScrollForward]);

    const {
        epgPrograms: epgProgramsNextDay,
        isFetching: isFetchingEPGProgramsNextDay,
    } = useWalkingEPG({
        enabled: shouldFetchNextDay(),
        channelId,
        date: nextDateString,
    });

    const allEpgPrograms = useMemo(() => {
        return [...epgPrograms, ...epgProgramsNextDay];
    }, [epgPrograms, epgProgramsNextDay]);

    const isNewDay = useMemo(() => {
        if (epgPrograms.length === 0) {
            return false;
        }

        const lastProgram = epgPrograms[epgPrograms.length - 1];
        const firstProgramNextDay = epgProgramsNextDay[0];

        if (!firstProgramNextDay) {
            return false;
        }

        const lastProgramDate = new Date(lastProgram.getStartTime()).getDate();
        const firstProgramDate = new Date(firstProgramNextDay.getStartTime()).getDate();

        return lastProgramDate !== firstProgramDate;
    }, [epgPrograms, epgProgramsNextDay]);

    const isFetching = isFetchingEPGPrograms || isFetchingEPGProgramsNextDay;

    return { epgPrograms: allEpgPrograms, isFetching, isNewDay };
};
