import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { COL_WIDTH } from '@/constants/epg-table';

export const Table = styled.div`
    position: relative;
`;

export const MainNavigation = styled(Nav)`
    height: 100%;
    width: 100%;
    position: relative;
    top: 22.3rem;
`;

export const TableCellGroup = styled(Nav)`
    min-height: 18rem;
    width: ${COL_WIDTH}rem;
`;

export const ScrollIndicator = styled.div`
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    background-color: green;
    border-radius: 50%;
    padding: 1rem;
`;

export const Text = styled.span`
    &::after {
        content: '';
        border-bottom: 0.1rem solid ${({ theme }) => theme.epgTable.dateSeparator.lineColor};
        margin-left: 0.9rem;
        margin-top: 2px;
        position: absolute;
        top: 1rem;
        width: ${COL_WIDTH * 3}rem;
    }
`;

export const DateSeparator = styled.div(({ theme }) => {
    const {
        mainColor,
        epgTable: { dateSeparator },
    } = theme;

    return css`
        align-items: center;
        color: ${mainColor};
        display: flex;
        font-size: 2rem;
        font-weight: 500;
        position: absolute;
        text-align: center;
        text-shadow: ${dateSeparator.textShadow};
        top: 32.1rem;
        width: ${COL_WIDTH * 4}rem;

        ${Text} {
            margin-left: 0.9rem;
            margin-right: 0.9rem;
        }

        ${Text}, svg {
            position: relative;
            z-index: 2;
        }

        svg {
            filter: ${dateSeparator.iconShadow};
        }

        &::before,
        &::after {
            content: '';
            flex-grow: 1;
        }

        &::before {
            border-bottom: 0.1rem solid ${dateSeparator.lineColor};
            position: relative;
            z-index: 1;
            margin-right: 1.2rem;
        }
    `;
});

export const EPGTable = styled(Nav)`
    display: inline-flex;
    position: relative;
    max-width: 100%;
    overflow: hidden;
`;
