import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { SetSessionProfileOut } from '@/interfaces/from-schemas/auth/setSessionProfileOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { userSpecificQueries } from '@/configs/react-query.config';

interface IUseSetSessionProfile {
    profileId: string;
}

const useSetSessionProfile = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.SET_SESSION_PROFILE,
        null,
    );

    return useGetMediaApiMutation<IUseSetSessionProfile, SetSessionProfileOut>(keyHook, {
        appendAuthData: true,
        updateAuthSession: true,
        invalidate: userSpecificQueries,
    });
};

export default useSetSessionProfile;
