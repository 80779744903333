import styled, { css } from 'styled-components';

export const IconGridElementContainer = styled.div<{ active: boolean; isAccountPage?: boolean }>`
    display: inline-block;
    margin: 2rem;
    position: relative;

    ${(props) =>
        props.isAccountPage &&
        css`
            height: 100%;
        `}

    > div:first-child {
        cursor: pointer;
    }

    ${(props) =>
        props.active &&
        css`
            > div img {
                transform: scale(1.2);
                & + div {
                    top: 1.7rem;
                }
            }
        `}
`;
