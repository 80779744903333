import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import React, { RefObject } from 'react';
import { PlayableButton } from '@/components/shared/Buttons';
import { Message } from '@/components/shared/Message';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { Nav } from 'nav-tree';
import { useInitialRefocus } from '@/hooks/nav-tree/use-initial-refocus.hook';
import { useSetNewEmail } from '@/hooks/auth/use-set-new-email.hook';

export const SetNewEmail = ({ focusRef }: { focusRef: RefObject<Nav> }) => {
    const { onSubmit, register, userMessage, userError, isSuccess } = useSetNewEmail();

    useInitialRefocus({ focusRef, enabled: isSuccess, focusIndex: ['1', '1'] });

    const buildSpan = () => {
        return {
            span: (chunks: string) => {
                return `<span id="portal-span">${chunks}</span>`;
            },
        };
    };

    if (userMessage) {
        return <AlertMessage type={MESSAGE_TYPE.INFO} userMessage={userMessage} />;
    }

    return (
        <>
            <Message
                innerHtmlDescription={t('pages.add-account-email.check-email.change-email', {
                    portal: t('domain'),
                    ...buildSpan(),
                })}
            />
            <FormContainer onSubmit={onSubmit}>
                <TextInput
                    icon={ICONS.PERSON}
                    placeholder={t('form.placeholder.e-mail')}
                    error={!!userError}
                    name="email"
                    ref={register}
                />
                <PlayableButton text={t('change-button-label')} onButtonAction={onSubmit} />
            </FormContainer>

            {userError && <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={userError} />}
        </>
    );
};
