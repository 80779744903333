import { css } from 'styled-components';

export const highlightTranslationStyles = css`
    .highlight {
        color: ${(props) => props.theme.text.color.translationHighlight};
        &.-red {
            color: ${(props) => props.theme.text.color.onAir};
        }
        &.-target-highlight {
            color: ${(props) => props.theme.text.color.highlight};
        }
    }
`;
