import t from '@/lib/i18n';
import { EnhancedProps } from '@/modules/enhance-context';
import { MEDIA_TYPES } from '@/types/media.type';

export const getPageCategory = (pageProps?: EnhancedProps) => {
    const mediaType = pageProps?.media?.mediaType || pageProps?.packetOut?.subType;

    let currentCategory;

    switch (mediaType) {
        case MEDIA_TYPES.TV:
            currentCategory = t('menu.channels-tv');
            break;

        case MEDIA_TYPES.PACKET:
            currentCategory = t('menu.packets');
            break;

        case MEDIA_TYPES.LIVE:
            currentCategory = t('menu.live');
            break;

        default:
            currentCategory = pageProps?.media?.category?.categoryNamesPath[1];
    }

    return currentCategory;
};
