import t from '@/lib/i18n';
import React from 'react';
import {
    BorderWithText,
    BorderWithTextInside,
    BorderWithTextLabel,
    CheckoutConteinr,
    CheckoutItem,
    CheckoutItemContent,
    Description,
    Label,
} from './styles';
import { CheckoutProps } from './types';

export const Checkout = ({ accessLabel, accessDescription, paymentMethodLabel }: CheckoutProps) => {
    return (
        <CheckoutConteinr>
            <CheckoutItem>
                <BorderWithText isFirst>
                    <BorderWithTextInside>
                        <BorderWithTextLabel>{t('payment-steps.access')}</BorderWithTextLabel>
                    </BorderWithTextInside>
                </BorderWithText>

                <CheckoutItemContent>
                    {accessDescription && <Description>{accessDescription}</Description>}
                    <Label>{accessLabel}</Label>
                </CheckoutItemContent>
            </CheckoutItem>

            <CheckoutItem>
                <BorderWithText>
                    <BorderWithTextInside>
                        <BorderWithTextLabel>
                            {t('payment-steps.payment-method')}
                        </BorderWithTextLabel>
                    </BorderWithTextInside>
                </BorderWithText>

                <CheckoutItemContent>
                    <Label>{paymentMethodLabel}</Label>
                </CheckoutItemContent>
            </CheckoutItem>
        </CheckoutConteinr>
    );
};
