import defaultKeyHook from '../../hooks/default-key';
import { useGetMediaApi } from '../../hooks/use-get-media-api.hook';
import { IApiResponse } from '../../hooks/use-get-media-api.type';
import { SearchContentIn } from '../../interfaces/from-schemas/navigation/searchContentIn';
import { SearchContentModel } from '../../models/search/search-content.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '../../types/get-media-method.type';

const useSearchContent = (
    params?: SearchContentIn,
): IApiResponse<SearchContentModel | undefined> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.SEARCH_CONTENT,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: data && new SearchContentModel(data),
        isFetching,
        error,
    };
};

export default useSearchContent;
