import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetAllRulesOut } from '@/interfaces/from-schemas/auth/getAllRulesOut';
import { GetRulesIn } from '@/interfaces/from-schemas/auth/getRulesIn';
import { GetRulesOut } from '@/interfaces/from-schemas/auth/getRulesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface IUseGetRules {
    rulesIds?: GetRulesIn['rulesIds'];
}

export const useGetRules = (params: IUseGetRules): IApiResponse<GetRulesOut> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.FETCH_RULES,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook);

    return {
        data: data as GetAllRulesOut,
        error,
        isFetching,
    };
};

export default useGetRules;
