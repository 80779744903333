export function exitTizenApp() {
    try {
        (window as any).tizen.application.getCurrentApplication().exit();
    } catch (e) {
        console.error('ExitTizenAppError', e);
    }
}

export function exitWebOSApp() {
    try {
        webOS.platformBack();
    } catch (e) {
        console.error('ExitWebOSAppError', e);
    }
}

export function exitStbApp() {
    try {
        //@ts-ignore
        const port = chrome.runtime.connectNative('com.adb.graphyne');
        port.postMessage({ function: 'CLOSE' });
    } catch (e) {
        console.error('ExitStbAppError', e);
    }
}

export function exitXboxApp() {
    try {
        //@ts-ignore
        window.external.notify('close');
    } catch (e) {
        console.error('ExitXboxAppError', e);
    }
}
