import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useRouter } from '@/routing';

export const useForeignUserInvalidation = (isPl: boolean) => {
    const { asPath } = useRouter();
    const invalidatedQueries = useRef<Record<string, string[]>>({});

    const queryClient = useQueryClient();
    const cachedData = queryClient.getQueryCache().getAll();

    const hasCachedData = useCallback(
        (method: GET_MEDIA_METHODS) => cachedData.some((q) => q.queryKey[1] === method),
        [cachedData],
    );

    const invalidateRequest = useCallback(
        (method: GET_MEDIA_METHODS) => {
            const invalidatedArray = invalidatedQueries.current;
            if (!invalidatedArray[method]) {
                Object.assign(invalidatedArray, { [method]: [] });
            }

            if (!invalidatedArray[method].includes(asPath)) {
                if (hasCachedData(method)) {
                    queryClient.invalidateQueries({
                        predicate: ({ queryKey }) => {
                            return queryKey[1] === method;
                        },
                    });

                    invalidatedArray[method].push(asPath);
                }
            }
        },
        [hasCachedData, asPath, queryClient],
    );

    const whiteList = useMemo(
        () => [
            GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS,
            GET_MEDIA_METHODS.FETCH_PRE_PURCHASE_DATA,
        ],
        [],
    );

    useEffect(() => {
        if (isPl) return;

        whiteList.forEach(invalidateRequest);
    }, [isPl, invalidateRequest, whiteList]);
};
