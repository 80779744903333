import useGetChannelList from '@/api/navigation/channels/use-get-channel-list';
import useGetChannelListFlatNavigation from '@/api/navigation/channels/use-get-channel-list-flat-navigation';
import { GetTvChannelsIn } from '@/interfaces/from-schemas/navigation/getTvChannelsIn';
import { ChannelModel } from '@/models/channels/channel.model';
import { useState, useEffect } from 'react';
import { useEPGParams } from '../use-epg-params.hook';

export const useFetchEPGChannels = () => {
    const { data: filterLists = [] } = useGetChannelListFlatNavigation({});
    const { params, clearGrid, setClearGrid } = useEPGParams({
        filterLists,
    });
    const [gridElements, setGridElements] = useState<ChannelModel[] | null>(null);

    const { data: fetchedChannels, isFetching: isFetchingChannels } = useGetChannelList(
        params as GetTvChannelsIn,
    );

    useEffect(() => {
        if (isFetchingChannels) return;

        const channels = fetchedChannels.getResults();

        if (!channels.length) {
            setGridElements([]);
        } else {
            setGridElements(clearGrid ? channels : (prev) => [...(prev || []), ...channels]);
        }

        setClearGrid(false);
    }, [fetchedChannels, isFetchingChannels]);

    return {
        allChannels: gridElements,
        filterLists,
        isFetching: isFetchingChannels,
    };
};
