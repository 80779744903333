import t from '@/lib/i18n';
import React from 'react';
import { HelpNoteLinkWrapper, HelpNoteWrapper } from './styles';

const { helpdeskPage, helpdeskEmail } = process.env.contacts;

const HelpNoteText = () => {
    return (
        <HelpNoteWrapper>
            {t('pages.reset-password.help-note.note-1')}
            <HelpNoteLinkWrapper href={helpdeskPage.url}>
                {t('pages.reset-password.help-note.note-2')}
            </HelpNoteLinkWrapper>
            {t('pages.reset-password.help-note.note-3')}
            <HelpNoteLinkWrapper href={'mailto:' + helpdeskEmail.url}>
                {helpdeskEmail.label}
            </HelpNoteLinkWrapper>
        </HelpNoteWrapper>
    );
};

export default HelpNoteText;
