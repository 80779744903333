import AdbToken from '@/types/stb/AdbToken';

export function checkIsPinValid(pin: string): Promise<{ isValid: boolean; isLocked: boolean }> {
    return new Promise((resolve, reject) => {
        const listenerPINVerification = {
            onInfoSvcActionResult: (result: any) => {
                if (result) {
                    const isValid = result.is_same;
                    const isLocked = result.result_data?.includes('blocked') ?? false;

                    resolve({ isValid, isLocked });
                } else {
                    reject();
                }
            },
        };

        try {
            window.stb.infoSvc?.verifyTokenValue(
                AdbToken.CFG_SYSTEM_PIN,
                pin,
                listenerPINVerification,
            );
        } catch (e) {
            reject();
        }
    });
}
