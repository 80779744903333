export function launchTizenPreviewService(previewData: any): void {
    const tizen = (window as any).tizen;
    const app = tizen.application.getCurrentApplication();
    const packageId = app.appInfo.packageId;
    const serviceId = `${packageId}.PreviewService`;
    const appControlData = new tizen.ApplicationControlData('previewData', [
        JSON.stringify(previewData),
    ]);
    const appControl = new tizen.ApplicationControl(
        'http://tizen.org/appcontrol/operation/pick',
        null,
        null,
        null,
        [appControlData],
    );

    tizen.application.launchAppControl(appControl, serviceId);
}

export function getTizenPayloadData(): any {
    try {
        const app = (window as any).tizen.application.getCurrentApplication();
        const requestedAppControl = app.getRequestedAppControl();
        const appControlData: any[] = requestedAppControl.appControl.data;
        const payloadString = appControlData.filter(({ key }) => key === 'PAYLOAD')[0].value[0];
        const payloadData = JSON.parse(payloadString).values;
        const decodedPayloadData = decodeURIComponent(payloadData);

        return JSON.parse(decodedPayloadData);
    } catch (e) {
        return null;
    }
}
