import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const ConsentsListNav = styled(Nav)`
    height: 100vh;
    width: 100%;
    padding: 12rem 0;
`;

export const ConsentsListItemStyled = styled(Nav)`
    width: 100%;
    padding: 3rem 4rem;
    margin: 2rem 0;
    border-radius: 4rem;
    text-align: left;

    &.nav-focused {
        background-color: ${({ theme }) => theme.consentsList.activeItem};
    }

    & h2 {
        margin: 0;
        font-size: ${({ theme }) => theme.text.size.pageSubtitle};
        font-weight: 400;
    }

    & p {
        color: ${({ theme }) => theme.text.color.secondary};
        font-size: ${({ theme }) => theme.text.size.pageDescription};
        font-weight: 400;
        margin: 0;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    & h2 {
        flex-grow: 1;
    }
`;
