import t from '@/lib/i18n';
import styled from 'styled-components';

export const Label = styled.div`
    width: 31rem;
    font-size: 2.6rem;
    color: ${({ theme }) => theme.text.color.whiteTone0};
    font-weight: 400;
`;
export const Header = styled.div`
    opacity: 0.4;
    font-size: 2rem;
    line-height: 2.1rem;
    font-weight: 400;
    margin-bottom: 1.9rem;
`;
export const Description = styled.div`
    font-size: 1.9rem;
    opacity: 0.8;
    width: 31rem;
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 400;
    li {
        list-style-type: disc;
        color: ${({ theme }) => theme.text.color.highlight};
    }

    li > span {
        color: ${({ theme }) => theme.purchasePacketButton.priceDetailColor};
    }
`;
export const LineSeparator = styled.div`
    opacity: 0.2;
    width: 6rem;
    height: 0.1rem;
    background-color: #ffffff;
    margin-bottom: 1.9rem;
    margin-top: 1.9rem;
`;

export const PacketImage = styled.div`
    width: 20.2rem;
    height: 11.4rem;
    img {
        border-radius: 0.8rem;
        width: 100%;
        height: 100%;
    }
`;
export const PriceContainer = styled.div`
    display flex;
    flex-direction:column;
    text-align: center;
`;

export const Price = styled.span<{ checkHigherPricesExist?: boolean }>`
    white-space: nowrap;
    font-size: 4.6rem;
    color: ${({ theme }) => theme.purchasePacketButton.priceDetailColor};
    font-weight: 400;

    ${({ checkHigherPricesExist }) =>
        checkHigherPricesExist &&
        `&:before {
        content: ${t('payment-steps.from')};
        opacity: 0.702;
        text-transform: lowercase;
    }

`}
`;

export const OldPrice = styled.span`
    position: relative;
    display: inline;
    font-size: 1.8rem;
    white-space: nowrap;
    opacity: 0.702;
    color: ${({ theme }) => theme.purchasePacketButton.priceDetailColor};
    font-weight: 400;

    &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;

        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }
`;

export const Duration = styled.span`
    opacity: 0.702;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.purchasePacketButton.priceDetailColor};
    font-weight: 400;
    text-align: center;
    padding-top: 0.6rem;
`;
