import { TIME_TO_HOUR } from '@/constants/epg-table';
import { formatDate } from '@/helpers/date.helper';
import { FORMAT_DATE_MODE } from '@/types/date.type';

export const getMinutesBetween = (d1: string | number, d2: string | number) => {
    const t1 = new Date(d1);
    const t2 = new Date(d2);
    const diff = t2.getTime() - t1.getTime();
    return Math.floor(diff / 1000 / 60);
};

export const isTimeToHourKey = (key: string): key is keyof typeof TIME_TO_HOUR => {
    return key in TIME_TO_HOUR;
};

export const formatTime = (time: string) => formatDate(time, FORMAT_DATE_MODE.HOURS);
