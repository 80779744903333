import { Nav } from 'nav-tree';
import styled from 'styled-components';
import {
    BorderProps,
    ButtonRightSideContentProps,
    RightSideContentBorderProps,
    PurchaseButtonContainerProps,
    BorderWithTextInsideProps,
    BorderWithTextProps,
} from './types';

export const PurchaseButtonContainer = styled(Nav)<PurchaseButtonContainerProps>`
    display: flex;
    width: 100%;
    min-height: 11rem;
    font-size: 2.6rem;
    color: #ccd0d5;
    margin: ${({ $gutter }) => $gutter}rem 0;
`;

export const ButtonContent = styled(Nav)<{ img?: string; active?: boolean }>`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 3rem;
    border-radius: 3.2rem;
    &:hover {
        cursor: pointer;
    }
    ${({ img }) =>
        img &&
        `
        background-image: linear-gradient(181deg, rgba(89, 131, 252, 0) 0%, rgba(2, 2, 2, 1) 100%), url(${img});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top;
    border-radius: 3.2rem 0 0 3.2rem;
    
    
`}
    ${({ active }) =>
        active &&
        `
    border-radius: 3.2rem;
`}
`;

export const Border = styled.div<BorderProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.15rem solid ${({ theme }) => theme.purchaseButton.borderColor};
    border-radius: 3.2rem;

    ${({ isRightSideLabel }) =>
        isRightSideLabel &&
        `
            border-radius: 3.2rem 0 0 3.2rem;
        `}

    ${({ isRightSideLabelActive }) =>
        isRightSideLabelActive &&
        `
            border-right: none;
        `}

    ${({ active, theme }) =>
        active &&
        `
            top: -0.8rem;
            bottom: -0.8rem;
            left: -0.8rem;
            right: -0.8rem;
            border: 0.3rem solid ${theme.purchaseButton.activeBorderColor};
            border-radius: 3.2rem;
            
        `}
`;

export const BorderWithText = styled.div<BorderWithTextProps>`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:before,
    &:after {
        content: '';
        width: 50%;
        height: 100%;
        border: 0.15rem solid ${({ theme }) => theme.purchaseButton.borderColor};
    }

    &:before {
        border-right: none;
        border-radius: 3.2rem 0 0 3.2rem;
    }

    &:after {
        border-left: none;
        border-radius: 0 3.2rem 3.2rem 0;
    }

    ${({ isRightSideLabel }) =>
        isRightSideLabel &&
        `
            &:after {
                border-radius: 0;
            }
        `}

    ${({ isRightSideLabelActive }) =>
        isRightSideLabelActive &&
        `
            &:after {
                border-right: none;
            }
        `}

    ${({ active, theme }) =>
        active &&
        `
            top: -0.8rem;
            bottom: -0.8rem;
            left: -0.8rem;
            right: -0.8rem;

            &:before,
            &:after {
                border-width: 0.3rem;
                border-color: ${theme.purchaseButton.activeBorderColor};
            }

            &:after {
                border-radius: 0 3.2rem 3.2rem 0;
            }
        `}
`;

export const BorderWithTextInside = styled.div<BorderWithTextInsideProps>`
    height: 100%;
    border-bottom: 0.15rem solid ${({ theme }) => theme.purchaseButton.borderColor};
    padding: 0 15px;

    ${({ active, theme }) =>
        active &&
        `
            border-width: 0.3rem;
            border-color: ${theme.purchaseButton.activeBorderColor};
        `}
`;

export const BorderWithTextLabel = styled.div`
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    color: ${({ theme }) => theme.purchaseButton.promotionColor};
`;

export const ButtonRightSideContent = styled(Nav)<ButtonRightSideContentProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 20rem;
    font-size: 1.9rem;
    color: ${({ $active }) => ($active ? '#ccd0d5' : '#7f8387')};

    &:hover {
        cursor: pointer;
    }
`;

export const RightSideContentBorder = styled.div<RightSideContentBorderProps>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 0.15rem solid ${({ theme }) => theme.purchaseButton.borderColor};
    border-left: none;
    border-radius: 0 3.2rem 3.2rem 0;

    ${({ active, theme }) =>
        active &&
        `
            top: -0.8rem;
            bottom: -0.8rem;
            right: -0.8rem;
            border: 0.3rem solid ${theme.purchaseButton.activeBorderColor};
            border-radius: 3.2rem;
            
        `}
`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled.div``;

export const Description = styled.div`
    font-size: 1.9rem;
    color: #7f8387;
`;

export const PriceContainer = styled.div``;

export const Price = styled.span`
    white-space: nowrap;
`;

export const OldPrice = styled.span`
    text-decoration: line-through;
    margin-right: 1rem;
    font-size: 1.8rem;
    white-space: nowrap;
`;

export const Duration = styled.span`
    color: #7f8387;

    &:before {
        content: ' / ';
    }
`;
