import { DATA_POSITION } from '@/types/list-element-metadata.type';
import React from 'react';
import { ListElementMetadata } from './ListElementMetadata';
import { MetaDataInner, MetaDataInside, MetaDataInsideTop, MetaDataInnerTop } from './styles';
import { PositionedMetadataInterface } from '../types';

const PositionedMetadataRaw = ({ itemData, responsive, active }: PositionedMetadataInterface) => {
    const dataPosition = itemData.getDataPosition();

    const metadata = <ListElementMetadata itemData={itemData} responsive={responsive} />;

    switch (dataPosition) {
        case DATA_POSITION.HIDDEN:
            return null;
        case DATA_POSITION.TOP_CENTER:
            return (
                <MetaDataInsideTop>
                    <MetaDataInnerTop>{metadata}</MetaDataInnerTop>
                </MetaDataInsideTop>
            );
        case DATA_POSITION.INSIDE:
            return (
                <MetaDataInside active={active}>
                    <MetaDataInner>{metadata}</MetaDataInner>
                </MetaDataInside>
            );
        case DATA_POSITION.UNDER:
            return <MetaDataInner>{metadata}</MetaDataInner>;
        default:
            return null;
    }
};

export const PositionedMetadata = React.memo(PositionedMetadataRaw);
