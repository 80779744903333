// @ts-ignore
import preval from 'babel-plugin-preval/macro';

const GIT_SHA = preval`
let GIT_SHA;
try {
    GIT_SHA = require('child_process').execSync('git rev-parse HEAD').toString().trim();
} catch (e) {
    console.error(e);
}
module.exports = GIT_SHA
`;

const BUILD_TIME = preval`module.exports = new Date().toISOString();`;

const APP_VERSION = preval`module.exports = require('../package.json').version;`;

const [major, minor, patch] = APP_VERSION.split('.').map(Number);

const APP_VERSION_NUMERICAL = major * 10000 + minor * 100 + patch;

const VARIANT = preval`module.exports = process.env.VARIANT;`;

const TARGET = preval`module.exports = process.env.TARGET;`;

export const getBuildInfo = () => ({
    GIT_SHA,
    BUILD_TIME,
    APP_VERSION,
    APP_VERSION_NUMERICAL,
    VARIANT,
    TARGET,
});
