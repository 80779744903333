import { SubcategoryIndexInitialProps } from '@/components/views/vod/subcategory/types';
import { useNotFound } from '@/hooks/use-not-found.hook';
import { useRouter } from '@/routing';

export const useSubcategoryInitialProps = (): SubcategoryIndexInitialProps => {
    const { query } = useRouter();
    const { categoryId, subcategoryId } = query;
    const catId = typeof categoryId === 'string' ? +categoryId : 0;
    const subCatId = typeof subcategoryId === 'string' ? +subcategoryId : 0;

    useNotFound({ notFound: isNaN(categoryId) || isNaN(subcategoryId) });

    return {
        categoryId: catId,
        subcategoryId: subCatId,
    };
};
