import styled, { css, keyframes } from 'styled-components';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import {
    StyledLoaderChildrenProps,
    StyledLoaderProps,
    StyledLoadingWrapperProps,
    StyledTextProps,
} from './types';
import { Z_INDEX } from '@/targets/default/theme';

const pulse = keyframes`
    0% {
      transform: scale(0);
    }
    
    50% {
      transform: scale(1);
    }
    
    100% {
      transform: scale(0);
    }
`;

const buildLoaderChildOpacity = (isLoaderVisible?: boolean, keepChildrenVisible?: boolean) => {
    if (isLoaderVisible) {
        return keepChildrenVisible ? 0.5 : 0;
    } else {
        return 1;
    }
};

const StyledChild = styled.div<StyledLoaderChildrenProps>`
    transition: ${CheckSlowDevice(`opacity 0.3s linear`)};
    opacity: ${({ isLoaderVisible, keepChildrenVisible }) =>
        buildLoaderChildOpacity(isLoaderVisible, keepChildrenVisible)};
`;

export const StyledLoadingWrapper = styled.div<StyledLoadingWrapperProps>`
    position: relative;
    z-index: ${Z_INDEX.LOADER_WRAPPER};
    height: 0;

    ${({ calculateBottom, height, windowsHeight }) =>
        calculateBottom &&
        `
        bottom: ${calcBottom(height, windowsHeight)}px;
    `}
`;

const calcBottom = (height: number, windowHeight?: number) => {
    if (!windowHeight || windowHeight > height) {
        return height / 2;
    } else {
        return windowHeight / 2;
    }
};

const StyledLoader = styled.div<StyledLoaderProps>`
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    ${({ gutter }) => `padding-bottom: ${gutter}rem`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: ${({ noDelay }) => CheckSlowDevice(noDelay ? 'none' : 'opacity 0.3s linear')};
    overflow: hidden;
    z-index: ${Z_INDEX.LOADER_WRAPPER};
    width: 100%;

    ${({ inline }) =>
        inline &&
        css`
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0, 0);
        `}
`;

const StyledText = styled.div<StyledTextProps>`
    font-size: ${({ theme }) => theme.text.size.loader};
    color: ${({ theme, highlight }) =>
        highlight ? theme.text.color.loaderHighlight : theme.text.color.loader};
    font-weight: 400;
    margin-top: 1.2rem;
    text-align: center;
`;

export const StyledDescription = styled.div`
    font-size: ${({ theme }) => theme.text.size.loaderDescription};
    color: ${({ theme }) => theme.text.color.loader};
    font-weight: 400;
    margin-top: 1.2rem;
    text-align: center;
`;

const StyledDotsGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    backface-visibility: hidden;

    div {
        width: 2.82rem;
        height: 2.82rem;
        border-radius: 50%;
        top: 10.34rem;
        animation: ${pulse} 1s cubic-bezier(0.3, 0, 0.7, 1) infinite;
    }

    div:nth-of-type(4n + 1) {
        background: ${({ theme }) => theme.mainColor};
    }

    div:nth-of-type(4n + 2) {
        background: ${({ theme }) => theme.mainColor};
    }

    div:nth-of-type(4n + 3) {
        background: ${({ theme }) => theme.mainColor};
    }

    div:nth-of-type(4n) {
        background: ${({ theme }) => theme.mainColor};
    }

    div:nth-of-type(4n + 1) {
        left: 2.507rem;
        animation-delay: -0.4s;
    }

    div:nth-of-type(4n + 2) {
        left: 6.423rem;
        animation-delay: -0.3s;
    }

    div:nth-of-type(4n + 3) {
        left: 10.34rem;
        animation-delay: -0.2s;
    }

    div:nth-of-type(4n + 4) {
        left: 14.257rem;
        animation-delay: -0.1s;
    }

    div:nth-of-type(4n + 5) {
        left: 18.173rem;
        animation-delay: 0s;
    }
`;

const ContentLayoutShiftPreventer = styled.div`
    min-height: 100vh;
`;

export { StyledLoader, StyledText, StyledDotsGroup, StyledChild, ContentLayoutShiftPreventer };
