import { PlayableButton } from '@/components/shared//Buttons';
import { ICONS } from '@/components/shared//Icon';
import { Message } from '@/components/shared//Message';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { clearLocalStorage } from '@/helpers/clear-local-storage.helper';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import React from 'react';

const DeleteUserSuccess = () => {
    const userModule = useUserModule();
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer lockNavInside>
                <Message
                    icon={ICONS.SUCCESS_CIRCLE}
                    title={t('pages.account.delete-user.success')}
                />
                <NavWrapper defaultFocused>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            clearLocalStorage();
                            userModule.reset();
                            Router.pushRoute(ROUTES.HOME);
                        }}
                        defaultFocused
                    />
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default DeleteUserSuccess;
