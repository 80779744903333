export enum QUERY_PARAM {
    CATALOG = 'catalog',
    CATEGORY = 'category',
    EPG = 'epg',
    FILTERS = 'filters',
    PAGE = 'page',
    SEARCH = 'q',
    SEASON = 'season',
    SORT = 'sort',
    TAB = 'tab',
    TOTAL = 'total',
}

export enum QUERY_PARAM_VALUE {
    NEWEST = 'newest',
}

export type Modes = 'string' | 'stringArray' | 'number' | 'numberArray' | 'boolean';

export type ModeType<mode extends Modes> = mode extends 'string'
    ? string
    : mode extends 'stringArray'
    ? string[]
    : mode extends 'number'
    ? number
    : mode extends 'numberArray'
    ? number[]
    : mode extends 'boolean'
    ? boolean
    : never;
