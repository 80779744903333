export enum GET_MEDIA_PLACE_TYPE {
    HOMEPAGE = 'homepage',
    CATEGORY = 'category',
    PACKETS = 'packets',
    OFFER = 'offer',
    LIVE_CHANNELS = 'live_channels',
    CHANNELS_TV = 'tv_channels',
    SEARCH = 'search',
    FAVORITES = 'favorites',
    WELCOME_SCREEN = 'welcome_screen',
}
