import { useEffect, useMemo, useState } from 'react';
import { useTableMemoizedDataContext } from '@/contexts/epg-table/table-memoized-data.context';
import { useTablePageContext } from '@/contexts/epg-table/table-page.context';
import { PageBoundary } from '@/types/epg-table.type';
import { COLUMNS_PER_PAGE } from '@/constants/epg-table';

const START_INDEX = 0;

export const usePageBoundary = (channelsLength: number, hasFiltersChanged: boolean) => {
    const { dictionary, setDictionary } = useTableMemoizedDataContext();
    const { currentPage, pageDirection, setPageDirection } = useTablePageContext();

    const initialPageBoundary = useMemo<PageBoundary>(() => {
        if (dictionary[currentPage]) {
            return {
                startIndex: dictionary[currentPage].startIndex,
                endIndex: dictionary[currentPage].endIndex,
            };
        } else {
            return {
                startIndex: START_INDEX,
                endIndex: START_INDEX + COLUMNS_PER_PAGE,
            };
        }
    }, []);

    const [pageBoundary, setPageBoundary] = useState<PageBoundary>(initialPageBoundary);

    useEffect(() => {
        if (hasFiltersChanged) {
            setPageBoundary({
                startIndex: START_INDEX,
                endIndex: START_INDEX + COLUMNS_PER_PAGE,
            });
        }

        return () => {
            setPageDirection('stay');
        };
    }, [hasFiltersChanged, setPageDirection]);

    useEffect(() => {
        if (hasFiltersChanged) return;

        if (pageDirection === 'right') {
            setPageBoundary((prev) => {
                return {
                    startIndex: prev.startIndex + 1,
                    endIndex: Math.min(prev.endIndex + 1, channelsLength),
                };
            });
        }

        if (pageDirection === 'left') {
            setPageBoundary(dictionary[currentPage]);
        }
    }, [currentPage]);

    useEffect(() => {
        if (pageDirection === 'left') return;

        const currPageBoundary = {
            startIndex: pageBoundary.startIndex,
            endIndex: pageBoundary.endIndex,
        };

        setDictionary((prev) => {
            return {
                ...prev,
                [currentPage]: { ...currPageBoundary },
            };
        });
    }, [pageBoundary, currentPage, pageDirection, setDictionary]);

    return {
        pageBoundary,
    };
};
