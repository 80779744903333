import t from '@/lib/i18n';
import { IRouter, ROUTES } from '@/routing/types';

export const getLastStringFromUrl = (router: IRouter) => {
    const isCodeRoute = router.route === ROUTES.HAVE_CODE;
    const isHelpRoute = router.asPath === '/obsluga-klienta/pomoc';
    const isTVProgramRoute = router.route === ROUTES.CHANNELS_TV && router.query.epg;
    const isAccountRoute = router.route === ROUTES.ACCOUNT;
    const isLiveRoute = router.route === ROUTES.LIVE;

    if (isHelpRoute) {
        return t(`home-menu.url.help`);
    }
    if (isCodeRoute) {
        return t(`home-menu.url.code`);
    }

    if (isTVProgramRoute) {
        return t(`home-menu.url.program-tv`);
    }

    if (isAccountRoute) {
        return t(`home-menu.url.panel`);
    }

    if (isLiveRoute) {
        return t(`home-menu.url.live`);
    }

    const lastSegment = router.asPath.split('/').pop();
    const translationKey = t(`home-menu.url.${lastSegment}`) || t(`home-menu.${lastSegment}`);

    return translationKey || t(`home-menu.start`);
};
