import styled from 'styled-components';

export const Copyright = styled.span`
    color: ${({ theme }) => theme.text.color.copyright};
    font-size: ${({ theme }) => theme.text.size.copyright};
    font-weight: 400;
`;

export const StyledPromoboxElement = styled.div`
    width: 100%;
`;
