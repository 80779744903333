import { ThemeProps } from 'styled-components';

export type AlertMessageOwnProps = {
    userMessage: string;
    type: MESSAGE_TYPE;
    responsive?: boolean;
};

export enum MESSAGE_TYPE {
    ERROR = 'error',
    INFO = 'info',
}
export type AlertMessageStyledProps = AlertMessageOwnProps & ThemeProps<any>;
