import React from 'react';

import { ICONS } from '@/components/shared/Icon';
import { IconGroup } from '@/components/shared/Metadata';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { usePaginationNav } from '@/hooks/epg-table/use-pagination-nav.hook';
import { useSetTableColumns } from '@/hooks/epg-table/use-set-table-columns.hook';
import { ChannelModel } from '@/models/channels/channel.model';

import { ChannelColumn } from './ChannelColumn';
import * as Styled from './styles';
import { RenderChannelsProps } from './types';

const RenderChannelsRaw = ({ channels, channelsAccessMap, focusRef }: RenderChannelsProps) => {
    const { dateBarContent, displayDateBar } = useHourRangeContext();

    // Ustawianie pierwszej i ostatniej kolumny w widoku
    useSetTableColumns(channels);

    const firstChannel = channels.length > 0 ? channels[0]?.getId() : null;

    const { handleColumnNavigation, setActiveColumn } = usePaginationNav();

    return (
        <Styled.EPGTable func={handleColumnNavigation} ref={focusRef} restoreLastFocus>
            {displayDateBar && (
                <Styled.DateSeparator>
                    <IconGroup icon={ICONS.CALENDAR} iconSize={2.3} noMargin targetColor />
                    <Styled.Text>{dateBarContent}</Styled.Text>
                </Styled.DateSeparator>
            )}

            {channels.map((channel: ChannelModel, colIndex: number) => {
                const channelId = channel.getId();
                const hasAccess = channelsAccessMap[channelId];

                return (
                    <ChannelColumn
                        channel={channel}
                        colIndex={colIndex}
                        hasAccess={hasAccess}
                        key={`${channelId}-${firstChannel}`}
                        setActiveColumn={setActiveColumn}
                    />
                );
            })}
        </Styled.EPGTable>
    );
};

export const RenderChannels = React.memo(RenderChannelsRaw);
