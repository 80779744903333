import { GetCategoryTreeListIn } from '@/interfaces/from-schemas/help/getCategoryTreeListIn';
import { IParams } from '@/routing/types';

export const buildCategoryTreeListParams = (query: IParams) => {
    let params: GetCategoryTreeListIn = {};
    if (query.contextType) {
        params = { contextType: query.contextType };
    }
    if (query.contextType && query.contextValue) {
        params = { contextType: query.contextType, contextValue: query.contextValue };
    }

    return params;
};
