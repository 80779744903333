import styled from 'styled-components';

export const OptionLayoutStyled = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4rem 1rem 4rem 0;
    border-bottom: 0.1rem solid;
    border-color: ${(props) => props.theme.button.primaryBorderColor};

    &:first-child {
        padding: 2.5rem 1rem 3rem 0;
    }

    &:last-child {
        border: none;
    }
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;

    &:first-child {
        width: 65vh;
    }

    &:last-child {
        min-width: 30rem;
    }
`;
