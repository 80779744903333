import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

export const useGetLoginRequestStatus = (loginRequestId?: string) => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.LOGIN_REQUEST_STATUS,
        loginRequestId ? { loginRequestId } : undefined,
    );
    return useGetMediaApi(keyHook);
};
