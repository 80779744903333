import { SliderRef } from '../types';

export enum EVENT_LISTENER_ACTIONS {
    ADD = 'add',
    REMOVE = 'remove',
}

export enum SLIDER_EVENTS {
    MOUSE_DOWN = 'mousedown',
    MOUSE_LEAVE = 'mouseleave',
    MOUSE_UP = 'mouseup',
    MOUSE_MOVE = 'mousemove',
    TOUCH_START = 'touchstart',
    TOUCH_END = 'touchend',
    TOUCH_CANCEL = 'touchcancel',
    TOUCH_MOVE = 'touchmove',
    SCROLL = 'scroll',
    RESIZE = 'resize',
}

export interface SliderEventsInterface {
    ref: SliderRef;
    onScrollEvent?: (e: Event) => void;
    withScrollEvents?: boolean;
    withMouseEvents?: boolean;
    withTouchEvents?: boolean;
    enabled?: boolean;
}

export interface ResizeEventInterface {
    onResizeEvent?: (e: Event) => void;
    withResizeEvents?: boolean;
}

export type EventListenersInterface = {
    name: SLIDER_EVENTS;
    method: (e: Event) => void;
};

export interface UseEventListenersProps {
    ref?: SliderRef | Window;
    EVENT_LIST: EventListenersInterface[];
    enabled?: boolean;
}
