import styled from 'styled-components';

export const AdTimeContainer = styled.span`
    position: absolute;
    top: 8rem;
    right: 11rem;
    display: inline-block;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 5rem;
    padding: 1.5rem 1.5rem;
`;

export const AdType = styled.span`
    white-space: nowrap;
    font-size: 2rem;
    padding: 1.5rem;
    color: ${({ theme }) => theme.text.color.primary};
    border-color: ${({ theme }) => theme.text.color.primary};
    border-width: 0 0.1rem 0 0;
    border-style: solid;
`;

export const AdTimeMarker = styled.span`
    white-space: nowrap;
    font-size: 2rem;
    border-radius: 0 5rem 5rem 0;
    padding: 1.5rem;
    color: ${({ theme }) => theme.text.color.secondary};
`;

export const GetRidOfAdsButtonContainer = styled.div`
    position: absolute;
    bottom: 8.5rem;
    right: 11rem;
`;
