import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import defualtKeyHook from '@/hooks/default-key';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { GetCategoryIn } from '@/interfaces/from-schemas/navigation/getCategoryIn';
import { CategoryModel } from '@/models/category/category.model';
import { useMemo } from 'react';
import { GetCategoryOut } from '@/interfaces/from-schemas/navigation/getCategoryOut';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CATEGORY;

const useGetCategory = (params?: GetCategoryIn): IApiResponse<CategoryModel> => {
    const keyHook = defualtKeyHook(namespace, method, params);

    const options = {
        appendAuthData: true,
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, isFetching, error } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new CategoryModel(data), [data]),
        isFetching,
        error,
    };
};

export { useGetCategory };

const getCategoryPromise = async (
    params: GetCategoryIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetCategoryOut> => {
    const keyHook = defualtKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getCategoryPromise };
