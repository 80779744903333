import t from '@/lib/i18n';
import { useEffect, useState } from 'react';
import { usePlayerTimeMarkers } from './use-player-time-markers';

const MIN_BANDWIDTH = 2000; // kbps
const ONE_MBPS_IN_KBPS = 1000;

export const useBandwidth = () => {
    const [bandwidthText, setBandwidthText] = useState('');
    const { bandwidth } = usePlayerTimeMarkers();

    useEffect(() => {
        if (typeof bandwidth !== 'undefined' && bandwidth <= MIN_BANDWIDTH) {
            const bandwidthInMbps = (bandwidth / ONE_MBPS_IN_KBPS).toFixed(1);
            setBandwidthText(t('player.slow-internet-connection', { bandwidthInMbps }));
            return;
        }

        setBandwidthText('');
    }, [bandwidth]);

    return { bandwidthText };
};
