import { useState } from 'react';
import { Icon, ICONS } from '../../Icon';
import { ItemWrapper, IconWrapper, Item, IconDotWrapper } from './styles';
import { HelpItemProps } from './types';

export const HelpItem = ({
    defaultFocused,
    id,
    onMore,
    title,
    icon,
    withSubcategory,
}: HelpItemProps) => {
    const [active, setActive] = useState<boolean>(false);

    return (
        <>
            <ItemWrapper
                withSubcategory={withSubcategory}
                onNav={(isActive) => setActive(isActive)}
                autofocusOn={['focus', 'mouseenter']}
                defaultFocused={defaultFocused}
                onEnterDown={() => onMore(id)}
                onClick={() => onMore(id)}
            >
                {icon && (
                    <IconDotWrapper>
                        <Icon name={ICONS.DOT} />
                    </IconDotWrapper>
                )}
                <Item active={active}>{title}</Item>
                <IconWrapper>
                    <Icon name={ICONS.ARROW_RIGHT} />
                </IconWrapper>
            </ItemWrapper>
        </>
    );
};
