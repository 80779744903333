export const layouts: { [key: string]: string[] } = {
    default: [
        '1 2 3 4 5 6 7 8 9 0 {backspace}',
        'q w e r t y u i o p @',
        'a s d f g h j k l _ &',
        'z x c v b n m , . - ?',
        '{=/#} {shift} {space} {arrowleft} {arrowright}',
    ],
    default_shift: [
        '1 2 3 4 5 6 7 8 9 0 {backspace}',
        'Q W E R T Y U I O P @',
        'A S D F G H J K L _ &',
        'Z X C V B N M , . - ?',
        '{=/#} {shift} {space} {arrowleft} {arrowright}',
    ],
    special: [
        '1 2 3 4 5 6 7 8 9 0 {backspace}',
        "ę ó ą ś ł ż ź ć ń \\ '",
        '! @ # $ % ^ & * ( ) |',
        '- _ = + ; : , < > / "',
        '{=/#} {shift} {space} {arrowleft} {arrowright}',
    ],
    special_shift: [
        '1 2 3 4 5 6 7 8 9 0 {backspace}',
        "Ę Ó Ą Ś Ł Ż Ź Ć Ń \\ '",
        '! @ # $ % ^ & * ( ) |',
        '- _ = + ; : , < > / "',
        '{=/#} {shift} {space} {arrowleft} {arrowright}',
    ],
};
