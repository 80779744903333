import { useIdle } from '@/hooks/use-idle.hook';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { PlayableDynamicButton } from '../../Buttons';
import { ICONS } from '../../Icon';
import { usePlayerStatus } from '@/hooks/player/use-player-status';
import { AdControls } from './AdControls/AdControls';
import { ControlsBase } from './ControlsBase';
import { PlaybackTime } from './shared/PlaybackTime';
import { PlayerProgressBar } from './shared/PlayerProgressBar';
import { PlayPauseButton } from './shared/PlayPauseButton';
import { FlexBox, FlexItem } from './shared/styles';
import { usePlaybackOptions } from '@/hooks/player/use-playback-options';
import React, { ReactNode } from 'react';
import { SettingsPanel } from './shared/SettingsPanel';
import { SIDEBAR_TYPE } from './shared/types';
import { MediaTitle } from './shared/MediaTitle';
import { useTimeshiftingSupported } from '@/hooks/player/use-timeshifting-supported';
import { BUTTON_ID } from '@/types/button-id.type';
import { useIsLive } from '@/hooks/player/use-is-live';
import { GoToPositionStartButton } from './shared/GoToPositionStartButton';
import { GoToLiveButton } from './shared/GoToLiveButton';
import { isAdsVisible } from '@/helpers/player.helper';
import { useClosePlayerSideBar } from '@/hooks/player/use-close-player-sidebar';
import { ROUTES } from '@/routing/types';

const LiveContentControls = () => {
    const { live } = useIsLive();
    const { hasVideoOptions } = usePlaybackOptions();
    const isThimeshiftingSupport = useTimeshiftingSupported();
    const {
        player: {
            transition: { idleDelay },
        },
    } = process.env;

    const idle = useIdle(idleDelay);

    const { openSidebar, setOpenSidebar, handleClose, navRef } = useClosePlayerSideBar();
    const isCustomerCareButton = process.env.isCustomerCare;

    let sidebar: ReactNode;
    switch (openSidebar) {
        case SIDEBAR_TYPE.SETTINGS:
            sidebar = <SettingsPanel onClose={handleClose} />;
            break;
        default:
            sidebar = null;
            break;
    }

    const topBar = (
        <FlexBox>
            <FlexItem allowOverflow>
                <PlayableDynamicButton
                    defaultFocused
                    icon={ICONS.ARROW_LEFT}
                    text={t('back-button-label')}
                    onButtonAction={() => Router.back()}
                    data-testing="back-button-label"
                />
            </FlexItem>
            {hasVideoOptions && (
                <FlexItem paddingLeft="1rem" allowOverflow>
                    <PlayableDynamicButton
                        icon={ICONS.SETTINGS}
                        navId={BUTTON_ID.PLAYER_SIDEBAR_PANEL_SETTINGS_BTN}
                        text={t('settings-button-label')}
                        onButtonAction={() =>
                            setOpenSidebar((visible) =>
                                visible === SIDEBAR_TYPE.SETTINGS
                                    ? SIDEBAR_TYPE.NONE
                                    : SIDEBAR_TYPE.SETTINGS,
                            )
                        }
                    />
                </FlexItem>
            )}

            {isCustomerCareButton && (
                <FlexItem allowOverflow paddingLeft="1rem">
                    <PlayableDynamicButton
                        icon={ICONS.QUESTION_MARK}
                        text={t('home-menu.url.help')}
                        onButtonAction={() =>
                            Router.pushRoute(
                                ROUTES.CUSTOMER_CARE_HELP,
                                {},
                                {
                                    contextType: 'player_offline',
                                    contextValue: '',
                                },
                            )
                        }
                        data-testing="help-button-label"
                    />
                </FlexItem>
            )}
        </FlexBox>
    );

    const bottomBar = (
        <div>
            <FlexBox gutter="7rem" horizontalMargin="11rem">
                <FlexItem allowOverflow>
                    <PlayPauseButton defaultFocused />
                </FlexItem>
                {isThimeshiftingSupport && (
                    <FlexItem allowOverflow paddingLeft="3rem">
                        <GoToPositionStartButton />
                    </FlexItem>
                )}
                <FlexItem paddingLeft="6rem" grow shrink>
                    <MediaTitle />
                </FlexItem>
                {isThimeshiftingSupport && !live && (
                    <FlexItem allowOverflow paddingLeft="3rem">
                        <GoToLiveButton />
                    </FlexItem>
                )}
            </FlexBox>
            <FlexBox gutter="7rem" horizontalMargin="11rem">
                <FlexItem grow shrink allowOverflow>
                    <PlayerProgressBar seekable={isThimeshiftingSupport} />
                </FlexItem>
                <FlexItem paddingLeft="5rem">
                    <PlaybackTime relative />
                </FlexItem>
            </FlexBox>
        </div>
    );

    return (
        <ControlsBase
            visible={!idle}
            topBar={topBar}
            bottomBar={bottomBar}
            sideBar={sidebar}
            navRef={navRef}
        />
    );
};

export const LiveControls = () => {
    const { state } = usePlayerStatus();

    if (isAdsVisible(state)) {
        return <AdControls />;
    }

    return <LiveContentControls />;
};
