import { BUTTON_ID } from '@/types/button-id.type';
import { ReactNode } from 'react';

export interface IOverlayContainerProps {
    passThrough?: boolean;
}

export interface IControlsContainerProps {
    visible?: boolean;
}

export interface IFlexItemProps {
    paddingLeft?: string;
    paddingRight?: string;
    shrink?: boolean;
    grow?: boolean;
    allowOverflow?: boolean;
}

export interface IFlexBoxProps {
    horizontalMargin?: string;
    gutter?: string;
}

export interface IMediaSubtitleProps {
    secondary?: boolean;
}

export interface IPlaybackTimeContainerProps {
    fixedWidth?: boolean;
    live?: boolean;
}

export interface IPlaybackTimeProps {
    live?: boolean;
    relative?: boolean;
}

export interface IPlaybackOptionProps {
    active: boolean;
    onSelect: () => void;
    label: string;
    defaultFocused?: boolean;
}

export interface IPlayerProgressBarProps {
    seekable?: boolean;
}

export interface FullscreenModalProps {
    children?: ReactNode;
    title: string;
    onConfirm: () => void;
    onCancel: () => void;
    backgroundUrl?: string;
}

export enum SIDEBAR_TYPE {
    NONE = 'none',
    TEXT_AUDIO = 'text_audio',
    SETTINGS = 'settings',
}

export interface ISettingsPanelProps {
    onClose: (id: BUTTON_ID) => void;
}

export interface MediaTitleProps {
    label?: string;
}

export interface CloseButtonProps {
    onButtonAction: () => void;
}

export interface PlayNextButtonProps {
    time?: number;
    isBottomBarVisible?: boolean;
}

export interface StartPlayFMCButtonsProps {
    actionKeepWatching: () => void;
    actionBeginWatching: () => void;
    isVisible: boolean;
}
