import t from '@/lib/i18n';
import { Router } from '@/routing';
import { BUTTON_ID } from '@/types/button-id.type';
import { ICONS } from '../../Icon';
import { CloseDynamicButton } from './styles';

interface ICloseButton {
    defaultFocused?: boolean;
}
export const CloseButton = ({ defaultFocused }: ICloseButton) => {
    return (
        <CloseDynamicButton
            navId={BUTTON_ID.BACK}
            onButtonAction={() => Router.back()}
            icon={ICONS.CLOSE}
            text={t('pages.customer-care.close-button-label')}
            data-testing="close-button-label"
            defaultFocused={defaultFocused}
        />
    );
};
