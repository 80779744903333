import { Icon, ICONS } from '@/components/shared/Icon';
import { PlaybackOptionContainer } from './styles';
import { IPlaybackOptionProps } from './types';

export const PlaybackOption = ({
    active,
    onSelect,
    label,
    defaultFocused,
}: IPlaybackOptionProps) => (
    <PlaybackOptionContainer
        defaultFocused={defaultFocused}
        autofocusOn={['mouseenter', 'focus']}
        onEnterDown={onSelect}
        onClick={onSelect}
    >
        <label>{label}</label>
        {active && <Icon name={ICONS.CHECK_MARK} />}
    </PlaybackOptionContainer>
);
