import React from 'react';

import { Icon, ICONS } from '@/components/shared/Icon';

import { getAdditionalSignsIconName, getAdditionalSignsName } from './helper';
import { AdditionalSignsIconWrapper, PlayerAdditionalSignsIconWrapper } from './styles';
import { IAdditionalSigns } from './types';
import { ACCESSIBILITY_FEATURES, UNDERAGE_CLASSIFICATION } from '@/types/additional-signs.type';

const AdditionalSignsRaw = ({ type, signs, $iconSize, player }: IAdditionalSigns) => {
    if (player) {
        return (
            <>
                {signs?.map((item) => {
                    const name = getAdditionalSignsIconName(type, item);
                    const tooltipName = getAdditionalSignsName(type, item);
                    if (name) {
                        return (
                            <PlayerAdditionalSignsIconWrapper
                                $size={$iconSize}
                                key={item}
                                title={tooltipName}
                            >
                                <Icon name={name as ICONS} />
                            </PlayerAdditionalSignsIconWrapper>
                        );
                    }
                })}
            </>
        );
    }

    return (
        <>
            {signs?.map((item) => {
                const name = getAdditionalSignsIconName(type, item);
                const tooltipName = getAdditionalSignsName(type, item);
                if (name) {
                    return (
                        <AdditionalSignsIconWrapper
                            $size={$iconSize}
                            key={item}
                            title={tooltipName}
                        >
                            <Icon name={name as ICONS} />
                        </AdditionalSignsIconWrapper>
                    );
                }
            })}
        </>
    );
};

export const AdditionalSigns = React.memo(AdditionalSignsRaw);
