import { useMemo } from 'react';

import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetChannelsProgramIn } from '@/interfaces/from-schemas/navigation/getChannelsProgramIn';
import { ChannelsCurrentProgramModel } from '@/models/channels/channel-list-current-program.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useGetChannelsProgram = (
    params?: GetChannelsProgramIn | null,
): IApiResponse<ChannelsCurrentProgramModel> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_CHANNELS_FULL_PROGRAM,
        params,
    );

    const { data, error, isFetching, refetch } = useGetMediaApi(keyHook, {
        appendAuthData: true,
        queryOptions: {
            staleTime: Infinity,
        },
    });

    const memoizedData = useMemo(() => new ChannelsCurrentProgramModel(data), [data]);

    return {
        data: memoizedData,
        error,
        isFetching,
        refetch,
    };
};

export default useGetChannelsProgram;
