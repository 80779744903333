import { isWeb } from '@/constants/portal-recognition';
import ResponseErrorModel from '@/models/response-error.model';
import { useCallback, useEffect } from 'react';
import { isSessionExpired } from '@/helpers/session-expired.helper';
import { IApiServerResponse } from '../use-get-media-api.type';
import { DehydratedState } from 'react-query/hydration';
import { findPatternByRouteName } from '@/helpers/router/find-pattern-by-route-name.helper';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { useTriggerAutoLogin } from '../user/use-auto-login.hook';

export const useSessionExpired = ({
    error,
    dehydratedState,
}: {
    error?: ResponseErrorModel;
    dehydratedState?: DehydratedState;
}) => {
    const userModule = useUserModule();

    const handleSessionExpiration = useCallback(() => {
        const loginRoute = process.env.loginRoute;
        const destination = findPatternByRouteName(loginRoute);

        // konieczne jest odświeżenie strony aby odświeżyły się dane w dehydratedState
        window.location.href = `${destination}?sessionExpired=true`;
    }, []);

    useEffect(() => {
        if (dehydratedState) {
            const sessionExpired = dehydratedState?.queries.some((q) => {
                const statusCode = (q.state.data as IApiServerResponse<unknown>).error?.code;
                return isSessionExpired(statusCode);
            });

            if (sessionExpired) {
                handleSessionExpiration();
            }
        }
    }, [dehydratedState, handleSessionExpiration]);

    useTriggerAutoLogin();

    useEffect(() => {
        if (error && isSessionExpired(error?.getCode())) {
            userModule.clearAuthSession();

            if (isWeb) {
                handleSessionExpiration();
            }
        }
    }, [error, handleSessionExpiration]);
};
