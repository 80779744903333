import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import t from '@/lib/i18n';
import { PaymentStepsDataInterface } from './types';

export const paymentStepsData: PaymentStepsDataInterface[] = [
    {
        label: t('payment-steps.access'),
        step: PAYMENT_STEPS.STEP_1,
    },
    {
        label: t('payment-steps.payment-method'),
        step: PAYMENT_STEPS.STEP_2,
    },
    {
        label: t('payment-steps.checkout'),
        step: PAYMENT_STEPS.STEP_3,
    },
    {
        label: t('payment-steps.payment'),
        step: PAYMENT_STEPS.STEP_4,
    },
];
