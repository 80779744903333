import { OfferIdInterface, ProductIdInterface } from '@/interfaces/product-id-interface';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { CPID, PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';

export function isLive(productId: ProductIdInterface): boolean {
    return productId.type === PRODUCT_TYPES.MEDIA && productId.subType === PRODUCT_SUBTYPES.LIVE;
}

export function isChannelTv(productId: ProductIdInterface): boolean {
    return productId.type === PRODUCT_TYPES.MEDIA && productId.subType === PRODUCT_SUBTYPES.TV;
}

export function isVodOrMovie(productId: ProductIdInterface): boolean {
    return (
        productId.type === PRODUCT_TYPES.MEDIA &&
        (productId.subType === PRODUCT_SUBTYPES.VOD || productId.subType === PRODUCT_SUBTYPES.MOVIE)
    );
}

export function isPacket(productId: ProductIdInterface): boolean {
    return (
        productId.type === PRODUCT_TYPES.MULTIPLE && productId.subType === PRODUCT_SUBTYPES.PACKET
    );
}

export function compareProducts(
    product1: ProductIdInterface,
    product2: ProductIdInterface,
): boolean {
    return (
        product1.id === product2.id &&
        product1.type === product2.type &&
        product1.subType === product2.subType
    );
}

export function compareOffers(offer1: OfferIdInterface, offer2: OfferIdInterface): boolean {
    return offer1.id === offer2.id && offer1.type === offer2.type;
}

export function getMediaCpidFromProductId(
    productId: ProductIdInterface,
): CPID.VOD_OR_MOVIE | CPID.EVENT_OR_CHANNEL | undefined {
    switch (true) {
        case isVodOrMovie(productId):
            return CPID.VOD_OR_MOVIE;

        case isLive(productId) || isChannelTv(productId):
            return CPID.EVENT_OR_CHANNEL;

        default:
            return undefined;
    }
}

export function filterMediaCpid(
    cpid: number | CPID,
): CPID.VOD_OR_MOVIE | CPID.EVENT_OR_CHANNEL | undefined {
    return cpid === CPID.VOD_OR_MOVIE || cpid === CPID.EVENT_OR_CHANNEL ? cpid : undefined;
}

export function redirectToProductDetails(
    productId: ProductIdInterface,
    media?: MediaListItemModel,
    trimPurchaseHistory = false,
): void {
    if (isPacket(productId)) {
        Router.pushRoute(ROUTES.PACKET_DETAILS, {
            packetId: productId.id,
            trimPurchaseHistory,
        });
        return;
    }

    if (media) {
        media.goToDetails({ trimPurchaseHistory });
        return;
    }

    Router.pushRoute(ROUTES.HOME);
}
