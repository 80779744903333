import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const NavButtons = styled(Nav)`
    display: flex;
    flex-wrap: wrap;
    width: max-content;
`;

export const ButtonContainer = styled.div`
    padding: 0 0.8rem;
    white-space: nowrap;
`;
export const ButtonsWrapper = styled.div`
    flex: 1 0 100%;
    div {
        display: flex;
        min-height: 6rem;
    }
`;
export const BuyChannelTextWrapper = styled.div`
    flex: 1 0 100%;
`;
export const BuyChannelText = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: 2.5rem;
`;
