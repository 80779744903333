import { useMemo } from 'react';

export function useListWindow<T>(params: {
    list: T[];
    width: number;
    offset: number;
}): [T[], number, number] {
    return useMemo(() => {
        const windowCenter = Math.ceil(params.width / 2);
        const windowEnd = Math.min(params.offset + windowCenter, params.list.length);
        const windowStart = Math.max(windowEnd - params.width, 0);
        return [[...params.list].splice(windowStart, params.width), windowStart, windowEnd];
    }, [params.list, params.width, params.offset]);
}
