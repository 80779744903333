import { AUTH_PROVIDERS } from '@/types/auth-providers.type';

export const withIpolsatbox = process.env.authProviders.includes(AUTH_PROVIDERS.IPOLSATBOX);
export const withApple = process.env.authProviders.includes(AUTH_PROVIDERS.APPLE);
export const withFacebook = process.env.authProviders.includes(AUTH_PROVIDERS.FACEBOOK);
export const withNetia = process.env.authProviders.includes(AUTH_PROVIDERS.NETIA);

export const withSocialMediaProviders = withApple || withFacebook;
export const withProviders = withSocialMediaProviders || withIpolsatbox;

export const isExternalAuthProvider = (authProvider?: AUTH_PROVIDERS) =>
    authProvider === AUTH_PROVIDERS.FACEBOOK ||
    authProvider === AUTH_PROVIDERS.APPLE ||
    authProvider === AUTH_PROVIDERS.IPOLSATBOX;
