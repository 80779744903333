import React from 'react';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { CenteredMessage } from './styles';
import { ICONS } from '@/components/shared/Icon';

export const EmptyFavoriteListMessage = () => {
    return (
        <CenteredMessage>
            <Message icon={ICONS.HEART_CIRCLE} title={t('pages.favorites.no-data-message')} />
        </CenteredMessage>
    );
};
