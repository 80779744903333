import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetMultipleProductsOut } from '@/interfaces/from-schemas/navigation/getMultipleProductsOut';
import { GetProductsIn } from '@/interfaces/from-schemas/navigation/getProductsIn';
import { MultipleTypeProductModel } from '@/models/products/multiple-type-product.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useMemo } from 'react';

export const useGetProducts = (
    params: GetProductsIn | false,
): IApiResponse<MultipleTypeProductModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_PRODUCTS,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    const productsData = useMemo(
        () => data?.map((el: GetMultipleProductsOut[number]) => new MultipleTypeProductModel(el)),
        [data],
    );

    return {
        data: productsData,
        error,
        isFetching,
    };
};
