import { GM_OS } from '@/types/platform.type';
import { usePlatform } from '../platform/platform.hooks';
import { OnetrustContextProps, OnetrustContextProviderProps } from './types';
import { createContext, useContext, useEffect, useState } from 'react';
import defaultConfig from '@/targets/default/config';
import { useLoadScript } from '@/hooks/use-load-script.hook';
import { SCRIPT_ID } from '@/types/script-id.type';
import { showOneTrust } from '@/helpers/onetrust.helper';
import { ONE_TRUST_MODE } from '@/types/onetrust.type';

export const OnetrustContext = createContext<OnetrustContextProps>({
    onetrustLoaded: false,
    setOnetrustLoaded: () => null,
});

const OnetrustContextProvider = ({ children }: OnetrustContextProviderProps) => {
    const [onetrustLoaded, setOnetrustLoaded] = useState<boolean>();
    const { platform } = usePlatform();
    const { os } = platform;

    let onetrustUrl = '';
    if (os === GM_OS.Tizen) {
        onetrustUrl = defaultConfig.onetrust.tizen.sdkUrl;
    } else if (os === GM_OS.WebOS) {
        onetrustUrl = defaultConfig.onetrust.webos.sdkUrl;
    }
    const { scriptLoaded } = useLoadScript(SCRIPT_ID.ONETRUST, onetrustUrl as string);

    useEffect(() => {
        if (scriptLoaded) {
            showOneTrust(ONE_TRUST_MODE.BANNER);
            setOnetrustLoaded(true);
        }
    }, [scriptLoaded]);

    return (
        <OnetrustContext.Provider value={{ onetrustLoaded, setOnetrustLoaded }}>
            {children}
        </OnetrustContext.Provider>
    );
};

export const useOnetrustContext = () => useContext(OnetrustContext);

export default OnetrustContextProvider;
