import { Modes, ModeType } from '@/types/query-string.type';

export const serializeParam = (
    param: string | string[] | number | number[] | boolean | undefined,
): string | undefined => {
    if (typeof param !== 'undefined') {
        if (typeof param === 'boolean') {
            return param ? 'true' : 'false';
        }
        const paramAsArray = Array.isArray(param) ? param : [param];
        return paramAsArray.map((item) => `${item}`).join(',');
    }

    return undefined;
};

export const parseParam = <M extends Modes>(
    param: string | undefined,
    mode: M,
): ModeType<M> | undefined => {
    if (typeof param !== 'undefined') {
        switch (mode) {
            case 'boolean':
                return (param === 'true') as ModeType<M>;
            case 'number':
                return Number.parseInt(param) as ModeType<M>;
            case 'string':
                return param as ModeType<M>;
            case 'stringArray':
                return (param ?? '').split(',') as ModeType<M>;
            case 'numberArray':
                return (param ?? '')
                    .split(',')
                    .map((strNumber) => Number.parseInt(strNumber)) as ModeType<M>;
        }
    }

    return undefined;
};
