import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@/lib/yupResolver';
import * as Yup from 'yup';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { ICONS } from '@/components/shared/Icon';
import {
    VerificationCodeFormData,
    VerificationCodeFormProps,
} from '@/components/views/VerifyPhoneNumber/types';
import t from '@/lib/i18n';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import FormRow from '@/components/shared/form/commons/form-row/form-row.common';
import { Message } from '@/components/shared/Message';
import { PlayableButton } from '@/components/shared/Buttons';
import { NavWrapper } from '../Account/styles';

const schema = Yup.object().shape({
    verificationCode: Yup.string().required(t('form.validate.required')),
});

export const VerificationCodeForm = ({
    phoneNo,
    changePhoneNumberCallback,
    sendNewCodeCallback,
    handleVerificationCode,
}: VerificationCodeFormProps) => {
    const { register, handleSubmit, errors } = useForm<VerificationCodeFormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit(async (data) => {
        handleVerificationCode(data.verificationCode);
    });

    const sendNewCode = async () => {
        sendNewCodeCallback();
    };

    const renderFormErrors = () => {
        const errorToDisplay = Object.values(errors).shift()?.message;

        if (errorToDisplay) {
            return <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />;
        }
    };

    const formatPhoneNo = (phoneNo: string) =>
        `+${phoneNo.slice(0, 2)} ${phoneNo.slice(2, 5)} ${phoneNo.slice(5, 8)} ${phoneNo.slice(
            8,
            11,
        )}`;

    return (
        <NavWrapper>
            <TextInput
                name="phoneNumber"
                icon={ICONS.PHONE}
                disabled
                value={formatPhoneNo(phoneNo)}
                type={INPUT_TYPE.TEXT}
                ref={register()}
            />
            <Message description={t('form.phone-verification.description')} />
            <form onSubmit={onSubmit}>
                <FormRow>
                    <TextInput
                        name="verificationCode"
                        placeholder={t('pages.account.eu-access.input-placeholder-code')}
                        type={INPUT_TYPE.TEXT}
                        error={!!errors.verificationCode}
                        ref={register()}
                        defaultFocused
                        data-testing="verificationCode"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={() => onSubmit()}
                        text={t('confirm-button-label')}
                        data-testing="confirm-button-label"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={() => Router.pushRoute(ROUTES.ACCOUNT)}
                        text={t('cancel-button-label')}
                        data-testing="cancel-button-label"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={() => sendNewCode()}
                        text={t('form.phone-verification.new-code')}
                        data-testing="form.phone-verification.new-code"
                    />
                </FormRow>
                <FormRow>
                    <PlayableButton
                        onButtonAction={() => changePhoneNumberCallback()}
                        text={t('form.phone-verification.change-number')}
                        data-testing="form.phone-verification.change-number"
                    />
                </FormRow>
            </form>
            {renderFormErrors()}
        </NavWrapper>
    );
};
