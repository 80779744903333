import useGetArticle from '@/api/help/use-get-article';
import React, { useState } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useRouter } from '@/routing';
import { ScrollPosition } from '@/components/shared/ScrollableContainer/types';
import FullMessage from '@/components/views/CustomerCare/FullArticleErrorText/FullMessage';
import { createHashHistory } from 'history';

const FullErrorPage = () => {
    const { query } = useRouter();
    const errorCode = Number(query.errorCode);
    const articleId = query.articleId;
    const { contextValue, contextType } = query;

    const [scrollPosition, setScrollPosition] = useState(ScrollPosition.TOP);
    const { data, isFetching } = useGetArticle({ articleId, errorCode });

    if (isFetching) {
        return <ProcessingLoader isFetching={true} />;
    }
    const history = createHashHistory();

    return (
        <BaseLayout
            fullscreen
            defaultBg
            topShade={scrollPosition !== ScrollPosition.TOP}
            bottomShade={scrollPosition !== ScrollPosition.BOTTOM}
        >
            <FullMessage
                data={data}
                setScrollPosition={setScrollPosition}
                scrollPosition={scrollPosition}
                showExitCC={true}
                onExitCC={() => history.go(-2)}
                errorCode={String(query.errorCode)}
                contextValue={contextValue}
                contextType={contextType}
            />
        </BaseLayout>
    );
};
export default FullErrorPage;
