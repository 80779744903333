import styled from 'styled-components';

export const QR_CODE_SIZE = 224;
export const QR_CODE_PADDING = 32;

export const TokenInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 15rem;
`;

export const TokenInfoSection = styled.div<{ separator?: boolean }>`
    position: relative;
    margin: 0 0 5rem 0;
    padding: 0 ${QR_CODE_SIZE}px;
    border-top: ${({ separator, theme }) =>
        separator ? `1px solid ${theme.tokenInfo.separatorColor}` : 'none'};
`;
export const TokenWrapper = styled.div`
    display: flex;
`;

export const TokenInfoSectionTitle = styled.div`
    font-size: 4.6rem;
    color: ${({ theme }) => theme.text.color.primary};
`;

export const NativeLoginButtonWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-self: center;
    padding: 5rem 0 0 0;
`;

export const NativeLoginButtonLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.tokenInfo.nativeLoginButtonSize};
    color: ${({ theme }) => theme.tokenInfo.nativeLoginButtonColor}; ;
`;

export const InstructionStepList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const InstructionStepWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5rem;
`;

export const InstructionStepLabel = styled.div`
    font-size: ${({ theme }) => theme.tokenInfo.instructionLabelSize};
    color: ${({ theme }) => theme.tokenInfo.instructionLabelColor};
    text-align: left;
    width: 45rem;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
        margin: 0 0 1rem 0;
    }

    b,
    em,
    strong {
        font-weight: bold;
        color: ${({ theme }) => theme.text.color.highlight};
    }
`;

export const InstructionStepValue = styled.span`
    border-radius: 50%;
    text-align: center;
    background-color: ${({ theme }) => theme.tokenInfo.instructionStepBackground};
    color: ${({ theme }) => theme.tokenInfo.instructionStepColor};
    font-weight: bold;
    font-size: 2rem;
    margin-top: 1rem;
    margin-right: 3rem;
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
`;

export const QRCodeContainer = styled.div`
    margin-left: 27rem;
    margin: auto;
    padding: ${QR_CODE_PADDING}px;
    width: ${QR_CODE_SIZE + 2 * QR_CODE_PADDING}px;
    height: ${QR_CODE_SIZE + 2 * QR_CODE_PADDING}px;
    background-color: #fff;
`;
export const Text = styled.div`
    margin-top: 3.4rem;
    width: 36.6rem;
    font-size: 2.2rem;
    line-height: 3.5rem;
    color: ${({ theme }) => theme.text.color.secondary};
`;
export const ButtonEnlargeWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
    width: 500px;
`;
export const Separator = styled.div`
    background-color: ${({ theme }) => theme.text.color.metadata};
    width: 1px;
    height: 12%;
`;

export const SeparatorText = styled.div`
    color: ${({ theme }) => theme.text.color.metadata};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-indent: 1rem;
    letter-spacing: 1rem;
    width: 9rem;
    height: 12rem;
`;

export const SeparatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2rem;
    align-items: center;
    margin-right: 10rem;
    margin-left: 10rem;
`;
