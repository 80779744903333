import { useStatsModule } from '@/contexts/stats/stats.context';
import { usePageVisibility } from '@/hooks/use-page-visibility.hook';
import { useEffect, useRef } from 'react';

export const AppPauseResume = () => {
    const isFirstVisibleState = useRef<boolean>(true);
    const isVisible = usePageVisibility();
    const statsModule = useStatsModule();

    useEffect(() => {
        if (!isVisible) {
            statsModule.hitAppPaused();
        }

        if (isVisible) {
            if (!isFirstVisibleState.current) {
                statsModule.hitAppResumed();
            }

            isFirstVisibleState.current = false;
        }
    }, [isVisible]);

    return null;
};
