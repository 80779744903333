import React, { useMemo } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { CombinedView } from '@/components/views/common';
import { buildCollectionsListConfig } from '@/helpers/pages/build-collections-list-config.helper';
import { buildCatalogConfig } from '@/helpers/pages/build-catalog-config.helper';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';

const Packets = () => {
    const packets = process.env.pages.packets;
    const { placeType, subType } = packets;

    const listsParams = { place: { type: placeType, value: '' } };

    const collectionsConfig = useMemo(() => buildCollectionsListConfig(packets), [packets]);
    const catalogConfig = useMemo(() => buildCatalogConfig(placeType, { subType }), []);

    useExitAppConfirmation(true);

    return (
        <BaseLayout>
            <CombinedView
                collectionsConfig={collectionsConfig}
                catalogConfig={catalogConfig}
                collectionsParams={listsParams}
            />
        </BaseLayout>
    );
};

export default Packets;
