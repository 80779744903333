import { RouteParams, ROUTES } from '@/routing/types';
import { ICONS } from '../Icon';
import { ThemeProps } from 'styled-components';
import { MutableRefObject } from 'react';
import { Nav } from 'nav-tree';
import { PlaceSchema } from '@/interfaces/from-schemas/navigation/getHomeMenuOut';
import { HomeMenuItemModel } from '@/models/home-menu-item.model';
import { CyProps } from '@/types/cypress.type';

export enum POSITION {
    TOP = 'top',
    BOTTOM = 'bottom',
}

export interface StaticMenuItemInterface {
    getName: () => string;
    getPlace?: () => PlaceSchema | undefined;
    getIconUrl?: () => string | undefined;
    getIcon?: () => ICONS | undefined;
    getUrl?: () => ROUTES | undefined;
    getDataTesting?: () => string | undefined;
}

export type FinalArrayType = (HomeMenuItemModel | StaticMenuItemInterface | undefined)[];

export type FocusRefType = MutableRefObject<Nav> & MutableRefObject<HTMLDivElement>;

export interface MenuItemsProps {
    menuActive: boolean;
    onFocusPath: (path: string[]) => void;
    menuItemsCombined: FinalArrayType;
}
export interface MenuItemProps extends CyProps {
    item: HomeMenuItemModel | StaticMenuItemInterface;
    isMenuActive: boolean;
    onFocusPath: (path: string[]) => void;
    onRedirect: (route: ROUTES, params?: RouteParams, query?: RouteParams) => void;
}

export interface MenuProfileItemProps {
    isMenuActive: boolean;
    onFocusPath: (path: string[]) => void;
}

export interface ActivityProps extends ThemeProps<any> {
    active?: boolean;
    focused?: boolean;
    icon?: string;
}

export interface StyledIconProps {
    active: boolean;
    focused: boolean;
    icon: string;
}

export interface MenuContainerIconProps {
    position: POSITION;
}

export interface DynamicIconProps {
    focused: boolean;
    size: { width: number; height: number };
}
