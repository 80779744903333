export const sortByConsecutiveNumbers = (array: number[]) => {
    const result = array.reduce((acc: number[][], val: number) => {
        const lastSubArray = acc[acc.length - 1];

        if (!lastSubArray || lastSubArray[lastSubArray.length - 1] !== val - 1) {
            acc.push([]);
        }

        acc[acc.length - 1].push(val);

        return acc;
    }, []);

    return result;
};
