import { useGetRulesSource } from '@/api/rules-source/use-get-rules-source';
import { rodoConfig } from '@/configs/rodo.config';
import { getRuleSourceUrl } from '@/helpers/get-rule-source-url';
import t from '@/lib/i18n';
import { RulesModel } from '@/models/products/rules.model';

export const usePrepareRulesToDisplay = (rule: RulesModel) => {
    const sourceUrl = getRuleSourceUrl(rule.getSources());
    const { data, isFetching } = useGetRulesSource(sourceUrl ? { sourceUrl } : undefined);

    const rodoHtml = Object.values(rodoConfig).join('');
    const rodoData = rule.isRodoRule() && rodoHtml;
    const noSourceData = rule.getDescription();

    const ruleDisplayData = rodoData || data?.data || noSourceData || t('no-rule-content');

    return { ruleDisplayData, isFetching };
};
