import { InputFilterSchema } from '@/interfaces/filters-interface';

export interface ParamsInterface {
    limit: number;
    offset: number;
    filters: InputFilterSchema[];
}

const LARGE_LIMIT = 100;

export const updateCatalogParams = ({
    withPagination = false,
    params,
    total,
    page,
    shouldIncreaseLimit,
}: {
    withPagination?: boolean;
    params: ParamsInterface;
    total: number;
    page?: number;
    shouldIncreaseLimit?: boolean;
}) => {
    const { increaseBy, initialLimit } = process.env.pages.catalog;
    const increaseLimit = shouldIncreaseLimit ? LARGE_LIMIT : increaseBy;
    const { offset, limit } = params;

    const canFetchMore = limit > 0 && limit < total;
    const hasMoreElements = withPagination || (offset + increaseLimit < total && canFetchMore);

    let newOffset = 0;
    let newLimit = 0;

    if (withPagination) {
        if (!page || page === 1) {
            newLimit = initialLimit;
            newOffset = 0;
        } else {
            newLimit = increaseLimit;
            newOffset = (page - 2) * increaseLimit + initialLimit;
        }
    }

    if (!withPagination) {
        newLimit = increaseLimit;
        newOffset = offset + limit;
    }

    if (hasMoreElements) {
        return {
            ...params,
            limit: newLimit,
            offset: newOffset,
        };
    }

    return params;
};
