import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const ButtonContainer = styled(Nav)`
    width: 40rem;
`;

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;
