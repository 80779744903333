export const checkIfEmptyArrIsNear = (
    emptyListsArr: number[],
    safeBufor: number,
    activeRowCtx: number,
    rowIndex: number,
) => {
    const isEmptyNear = emptyListsArr.some((row) => {
        const activeDistance = Math.abs(activeRowCtx - row);
        const indexDistance = Math.abs(rowIndex - row);
        return activeDistance > 0 && activeDistance <= safeBufor && indexDistance === 1;
    });

    return isEmptyNear;
};
