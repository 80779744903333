import CryptoUtil from '@/utils/crypto.utils';
import { useLoginUser } from '@/hooks/auth/use-login-user.hook';
import useRegister from './register';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import useLogin from '@/api/auth/use-login';
import { isServer } from '@/helpers/environment.helper';
import { useState } from 'react';
import { isStbTargets } from '@/constants/portal-recognition';
import { withNetia } from '@/helpers/auth/auth-providers.helper';

// -- STATIC
const AUTH_PROTOCOL_VERSION = '1.1';

export const useLoginWithDecoder = () => {
    const { ipolsatbox } = process.env;
    const [initialized, setInitialized] = useState(!isStbTargets);

    const removeInitializeLoader = () => {
        const initialLoader = document.getElementById('initial-loader');
        initialLoader?.remove();
        setInitialized(true);
    };

    const [login, loginData] = useLogin();
    const [registerUser, registerData] = useRegister();
    const authProvider = withNetia ? AUTH_PROVIDERS.NETIA : AUTH_PROVIDERS.IPOLSATBOX;
    const handleLoginUser = useLoginUser({ login, register: registerUser, authProvider });

    if (isServer || !ipolsatbox?.SECRET_KEY) {
        return {
            loginWithDecoder: () => null,
        };
    }

    const { status: loginStatus, error: loginError, isFetching: isFetchingLogin } = loginData;
    const { status: registerStatus, isFetching: isFetchingRegister } = registerData;

    const cryptoUtil = new CryptoUtil();
    const deviceId = window.stb?.deviceId;
    const timestamp = Date.now();

    if (!deviceId) {
        return {
            loginWithDecoder: () => null,
        };
    }

    const deviceString = `module|${deviceId}|${AUTH_PROTOCOL_VERSION}|${timestamp}`;
    const bytes = cryptoUtil.hmacSHA256(deviceString, ipolsatbox.SECRET_KEY);
    const hmac = cryptoUtil.base64.stringify(bytes);

    const loginParams = {
        authProvider: authProvider as AUTH_PROVIDERS.IPOLSATBOX | AUTH_PROVIDERS.NETIA,
        authProtocolVersion: '1.1',
        hmac,
        timestamp,
    };

    const loginWithDecoder = async () => {
        await handleLoginUser(loginParams);
        removeInitializeLoader();
    };

    return {
        loginWithDecoder,
        initializedStb: initialized,
        isFetching: isFetchingLogin || isFetchingRegister,
        loginError,
        isSuccess: loginStatus === 'success' && registerStatus === 'success',
    };
};
