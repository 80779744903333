import { useState, useEffect, useCallback, useMemo } from 'react';
import useGetPacketContentWithFlatNavigation from '@/api/packets/get-packet-content-with-flat-navigation';
import { returnNewCollection } from '@/helpers/packet.helper';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { useCatalogParams } from '@/hooks/use-catalog-params.hook';
import { updateCatalogParams } from '@/helpers/pages/update-catalog-params.helper';
import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { usePaginatedGrid } from '@/hooks/page-catalog/use-paginated-grid.hook';
import { PacketFetcherConfig } from '@/types/page-catalog.type';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { useGetChannelsFromPacketContent } from './use-get-channels-from-packet-content.hook';

const { liveCatalog } = process.env.metadataSettings.position;
const { col } = process.env.grid;

const useFetchPacketCollection = ({
    catalogParams,
    withPagination,
    fallbackMetadataPosition,
}: PacketFetcherConfig) => {
    const [filterLists, setFilterLists] = useState<FlatFilterListSchema[]>([]);
    const [gridElements, setGridElements] = useState<ListElementInterface[]>([]);
    const [columns, setColumns] = useState<number>(col);

    const { activeCategory, packetId, collection } = catalogParams;

    const { params, setParams, clearGrid, setClearGrid } = useCatalogParams({
        filterLists,
        resetParams: activeCategory,
        withPagination,
    });

    const packetParams = !!packetId && {
        packetCode: packetId,
        collection,
        ...params,
        filters: [activeCategory, ...params.filters],
    };

    const {
        data: packetContent,
        isFetching: isFetchingPacketContent,
        error: packetContentError,
    } = useGetPacketContentWithFlatNavigation(packetParams);

    useEffect(() => {
        if (!isFetchingPacketContent && !filterLists.length) {
            setFilterLists(packetCategoryFilters);
        }
    }, [isFetchingPacketContent, filterLists]);

    const total = useMemo(() => packetContent?.getTotal(), [packetContent]);

    const packetCategoryFilters = useMemo(
        () => packetContent?.getFlatNavigation()?.filterLists || [],
        [packetContent],
    );

    const {
        channelList,
        isFetchingProgram,
        error: channelListError,
    } = useGetChannelsFromPacketContent({
        activeCategory: activeCategory.value,
        packetContent,
    });

    const isFetching = useMemo(() => isFetchingPacketContent || isFetchingProgram, [
        isFetchingPacketContent,
        isFetchingProgram,
    ]);
    const error = packetContentError || channelListError;

    useEffect(() => {
        if (!isFetching && packetContent?.getResults().length) {
            let newCollection: ListElementModel[];

            if (channelList.length) {
                newCollection = channelList;
            } else {
                const isLiveCategory = activeCategory.value === 'live';

                const dataPosition = isLiveCategory
                    ? liveCatalog
                    : fallbackMetadataPosition ?? undefined;

                const { newCollection: collection, columns } = returnNewCollection(
                    packetContent,
                    dataPosition,
                );
                newCollection = collection;
                setColumns(columns);
            }

            if (newCollection.length) {
                const clear = clearGrid || withPagination;
                setGridElements(clear ? newCollection : (prev) => [...prev, ...newCollection]);
            } else {
                setGridElements([]);
            }

            setClearGrid(false);
        }
    }, [isFetching, channelList, packetContent]);

    useMemo(() => {
        // odśwież grida podczas zmiany kategorii pakietów
        setGridElements([]);
        setFilterLists([]);
    }, [activeCategory.value]);

    const fetchedAll = useMemo(() => params.offset + params.limit >= total, [params, total]);

    const onFetchMore = useCallback(() => setParams(updateCatalogParams({ params, total })), [
        params,
        total,
    ]);

    const paginatedData = usePaginatedGrid({ data: packetContent?.getResults() || [] });

    return {
        gridElements: withPagination ? paginatedData : gridElements,
        columns,
        filterLists,
        isFetching,
        fetchedAll,
        onFetchMore,
        error,
    };
};

export default useFetchPacketCollection;
