import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { AgeRestrictionGrid } from '@/components/views/Profile/AgeRestrictionGrid';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';

const ParentControlAge = () => {
    const { profileId } = useProfilePageInitialProps();
    const body = (
        <>
            <Message
                title={t('pages.profile.parent-control.header')}
                description={t('pages.profile.parent-control.description-age')}
                gutter={GUTTER.BIG}
            />

            <AgeRestrictionGrid
                onSelectAction={(id) => {
                    // TODO: usługi get-media nie są jeszcze gotowe wiec obecnie jest passthrough
                    console.log('Selected age control:', id);

                    return Router.pushRoute(ROUTES.PROFILE, { profileId });
                }}
            />

            <FormContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={() => Router.pushRoute(ROUTES.PROFILE, { profileId })}
                />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} fullBody={true} />
        </BaseLayout>
    );
};

export default ParentControlAge;
