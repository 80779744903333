import { useGetMediaApi, useGetMediaApiMutation } from '../../hooks/use-get-media-api.hook';
import { IApiResponse } from '../../hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '../../types/get-media-method.type';
import { MediaListItemModel } from '../../models/media-list-item.model';
import defaultKeyHook from '../../hooks/default-key';
import { GetMediaIn } from '../../interfaces/from-schemas/navigation/getMediaIn';
import { GetMediaOut } from '../../interfaces/from-schemas/navigation/getMediaOut';

const useGetMedia = (params?: GetMediaIn): IApiResponse<MediaListItemModel> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.GET_MEDIA,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: new MediaListItemModel(data),
        isFetching,
        error,
    };
};

export const useGetMediaMutation = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.GET_MEDIA,
        null,
    );

    return useGetMediaApiMutation<GetMediaIn, GetMediaOut>(keyHook, {
        appendAuthData: true,
    });
};

export default useGetMedia;
