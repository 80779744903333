import { useGetMultpleProducts } from '@/api/packets/get-multiple-type-products';
import { usePaginatedGrid } from '@/hooks/page-catalog/use-paginated-grid.hook';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { CategoryFetcherConfig } from '@/types/page-catalog.type';
import { useMemo } from 'react';

const { col } = process.env.grid;

export const useGetProductsCatalogData = ({
    catalogParams,
    withPagination,
}: CategoryFetcherConfig) => {
    const { data, isFetching, error } = useGetMultpleProducts(catalogParams);

    const gridElements = useMemo(() => {
        if (!isFetching && data.getPackets().length) {
            return data.getPackets().map((packet) => {
                const listElement = new ListElementModel(packet);

                return listElement;
            });
        }

        return [];
    }, [isFetching, data]);

    const paginatedData = usePaginatedGrid({ data: data.getPackets() });

    return {
        gridElements: withPagination ? paginatedData : gridElements,
        columns: col,
        filterLists: [],
        isFetching,
        onFilters: () => null,
        onFetchMore: () => null,
        error,
    };
};
