import { PlayableButton } from '@/components/shared/Buttons';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import FullscreenNavContainer, {
    FormContainer,
    ProcessingLoaderWrapper,
} from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';

import { ProcessingLoader } from '@/components/shared/Loader';

import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import React, { useEffect, useMemo } from 'react';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { useNativeLoginForm } from '@/hooks/auth/use-native-login-form.hook';
import { useError } from '@/contexts/error/error.context';
import { checkIsErrorArticleExcluded } from '@/contexts/error/CustomerCareErrorPageProvider';

export const LoginNativeBody = () => {
    const { registerForm, onSubmit, loginError, formErrors, isFetching } = useNativeLoginForm();
    const { setError } = useError();
    const errorToDisplay =
        Object.values(formErrors).shift()?.message || loginError?.getUserMessage();

    const showError = useMemo(() => !isFetching, [isFetching]);
    const isCustomerCare = process.env.isCustomerCare;
    useEffect(() => {
        if (loginError) {
            setError(loginError);
        }
    }, [loginError]);

    const body = (
        <>
            <FormContainer onSubmit={onSubmit}>
                <BackButton />

                <TextInput
                    icon={ICONS.PERSON}
                    placeholder={t('form.placeholder.native-e-mail')}
                    defaultFocused
                    error={showError && !!loginError}
                    name="email"
                    ref={registerForm()}
                />
                <TextInput
                    type={INPUT_TYPE.PASSWORD}
                    icon={ICONS.PASSWORD}
                    error={showError && !!loginError}
                    placeholder={t('form.placeholder.native-password')}
                    name="password"
                    ref={registerForm}
                />
                <PlayableButton
                    text={t('pages.login.native.sign-in')}
                    onButtonAction={onSubmit}
                    data-testing="pages.login.native.sign-in"
                />
                <PlayableButton
                    text={t('pages.login.native.forgot-password')}
                    data-testing="pages.login.native.forgot-password"
                    onButtonAction={() => Router.pushRoute(ROUTES.RESET_PASSWORD_REQUEST)}
                />
                <ProcessingLoaderWrapper>
                    <ProcessingLoader isFetching={isFetching} noDelay />
                </ProcessingLoaderWrapper>
            </FormContainer>
        </>
    );

    return (
        <FullscreenNavContainer
            error={errorToDisplay}
            header={t('pages.login.native.header')}
            body={body}
            isErrorToDisplay={
                isCustomerCare ? checkIsErrorArticleExcluded(loginError?.getCode()) : true
            }
        />
    );
};
