import { createContext, useCallback, useMemo, useState } from 'react';
import { RecoContextProps, RecoContextProviderProps } from './types';

export const RecoContext = createContext<RecoContextProps>({
    activeRow: null,
    setActiveRow: () => null,
    collectionActiveIndex: 0,
    setCollectionActiveIndex: () => null,
    emptyListsArr: [],
    setEmptyListsArr: () => null,
    clearContext: () => null,
});

const RecoContextProvider = ({ children }: RecoContextProviderProps) => {
    const [activeRow, setActiveRow] = useState<number | null>(null);
    const [collectionActiveIndex, setCollectionActiveIndex] = useState<number>(0);
    const [emptyListsArr, setEmptyListsArr] = useState<number[]>([]);

    const clearContext = useCallback(() => {
        setActiveRow(null);
        setCollectionActiveIndex(0);
        setEmptyListsArr([]);
    }, []);

    return useMemo(
        () => (
            <RecoContext.Provider
                value={{
                    activeRow,
                    setActiveRow,
                    collectionActiveIndex,
                    setCollectionActiveIndex,
                    emptyListsArr,
                    setEmptyListsArr,
                    clearContext,
                }}
            >
                {children}
            </RecoContext.Provider>
        ),
        [activeRow, collectionActiveIndex, emptyListsArr],
    );
};

export default RecoContextProvider;
