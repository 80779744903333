import React, { createContext, useContext, useState } from 'react';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';

type FocusedEPGType = {
    program: ChannelProgramTvModel | undefined;
    channelName: string | undefined;
};

const initialContextValue = {
    focusedEPG: undefined,
    channelName: undefined,
    setFocusedEPG: () => null,
};

const FocusedEPGContext = createContext<{
    focusedEPG: ChannelProgramTvModel | undefined;
    channelName: string | undefined;
    setFocusedEPG: (value: FocusedEPGType) => void;
}>(initialContextValue);

export const FocusedEPGContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [focusedEPG, setFocusedEPGState] = useState<FocusedEPGType>({
        program: undefined,
        channelName: undefined,
    });

    const setFocusedEPG = (value: {
        program: ChannelProgramTvModel | undefined;
        channelName: string | undefined;
    }) => {
        setFocusedEPGState(value);
    };

    return (
        <FocusedEPGContext.Provider
            value={{
                focusedEPG: focusedEPG.program,
                channelName: focusedEPG.channelName,
                setFocusedEPG,
            }}
        >
            {children}
        </FocusedEPGContext.Provider>
    );
};

export function useFocusedEPGContext() {
    const context = useContext(FocusedEPGContext);
    if (context === undefined) {
        throw new Error('useFocusedEPGContext must be used within a FocusedEPGContextProvider');
    }
    return context;
}
