import { usePlayerInstance } from './use-player-instance';
import { usePlayerSkipRange } from './use-player-skip-range';

export const useTimeshiftingDelta = () => {
    const player = usePlayerInstance();
    const { end } = usePlayerSkipRange();

    const calculateDeltaTime = () => {
        const currentTime = player.getCurrentTime();
        return end - currentTime;
    };

    return {
        calculateDeltaTime,
    };
};
