import styled from 'styled-components';

import { MainText } from '@/components/shared/MainText';

export const EPGPreview = styled.div`
    position: absolute;
    left: 0;
    top: 5.2rem;
    width: 60%;
    display: flex;
`;

export const PreviewImageWrapper = styled.div`
    flex: 1 0 auto;
    max-width: 21.1rem;
    height: 11.9rem;
    margin-right: 2.7rem;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.3rem;
    margin-bottom: 0.4rem;

    & > span {
        flex-shrink: 0;
        &:not(:last-child) {
            margin-right: 1.1rem;
        }
    }
`;

export const Title = styled(MainText)`
    font-size: 3.4rem;
    font-weight: 900;
`;

export const Description = styled(MainText)`
    font-size: 2rem;
    color: ${({ theme }) => theme.text.color.secondary};
`;
