import { useGetAvatars } from '@/api/system/use-get-avatars';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetProfilesOut } from '@/interfaces/from-schemas/auth/getProfilesOut';
import { ProfileInterface } from '@/interfaces/profile-interface';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useMemo } from 'react';

export const useGetProfiles = (): IApiResponse<ProfileInterface[]> => {
    const userModule = useUserModule();

    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.GET_PROFILES,
        userModule.isLogged() ? {} : undefined,
    );

    const {
        data: profilesData,
        error: profilesError,
        isFetching: isFetchingProfiles,
        refetch,
    } = useGetMediaApi(keyHook);

    const {
        data: avatarsData,
        error: avatarsError,
        isFetching: isFetchingAvatars,
    } = useGetAvatars();

    const profiles = useMemo(
        () =>
            !avatarsData || !profilesData
                ? []
                : profilesData.map((profile: GetProfilesOut[0]) => ({
                      profileId: `${profile.id}`,
                      name: profile.name,
                      avatarId: profile.avatarId,
                      avatarUrl: avatarsData
                          .find((avatar) => avatar.getId() === profile.avatarId)
                          ?.getSrc(),
                  })),
        [avatarsData, profilesData],
    );

    return {
        data: profiles,
        error: avatarsError || profilesError,
        isFetching: isFetchingAvatars || isFetchingProfiles,
        refetch,
    };
};

export const getProfilesPromise = (getMediaApiPromise: GetMediaPromiseFunc) => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.GET_PROFILES, {});
    return getMediaApiPromise(keyHook, {});
};

export default useGetProfiles;
