import { useGetSession } from '@/api/auth/use-get-session';
import useGetUnacceptedRules from '@/api/auth/use-get-unaccepted-rules';
import useLogin from '@/api/auth/use-login';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { buildUnacceptedRulesParams } from '@/helpers/auth/unnacepted-rules.helper';
import { redirectToTargetRoute } from '@/helpers/router/redirect-to-target-route.helper';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { UserAuthData } from '@/types/user-auth-data.type';
import { useMemo, useState, useCallback, useEffect } from 'react';
import ResponseErrorModel from '@/models/response-error.model';

const profilesEnabled = process.env.user.profilesEnabled;

export const useLoginRulesLoader = (obligatory?: boolean) => {
    const [index, setIndex] = useState<number>(0);
    const [error, setError] = useState<ResponseErrorModel>();
    const { query } = useRouter();
    const { rulesType } = query;
    const userModule = useUserModule();
    const isLogged = userModule.isLogged();
    const authData = userModule.getAuthData();

    const [login] = useLogin();
    const { refetch: refetchSession } = useGetSession();

    const context = 'login';
    const params = buildUnacceptedRulesParams(userModule, obligatory, context);
    const { data: unacceptedRules, isFetching, refetch } = useGetUnacceptedRules(params);

    const rulesExists = unacceptedRules?.length > 0;
    const haveRuleToAccept = unacceptedRules?.length > index;

    const handleLogin = useCallback(
        async (authData: UserAuthData) => {
            const resultLogin = await login(authData);

            if (!resultLogin.ok) {
                setError(resultLogin.error);
            }
        },
        [login],
    );

    const redirectRoute = useMemo(
        () => (profilesEnabled ? ROUTES.PROFILE_WATCH_AS : ROUTES.HOME),
        [],
    );

    const redirect = useCallback(() => {
        const { next, target } = query;

        if (target) {
            return redirectToTargetRoute(query);
        }

        const params = {};
        if (next) Object.assign(params, { next });

        return Router.pushRoute(redirectRoute, params);
    }, [redirectRoute, query]);

    useEffect(() => {
        if (!isFetching && !haveRuleToAccept && obligatory) {
            redirect();
        }
    }, [isFetching, haveRuleToAccept, obligatory, redirect]);

    useEffect(() => {
        if (!isFetching && authData && !isLogged && !obligatory && !haveRuleToAccept) {
            handleLogin(authData);
        }
    }, [isFetching, authData, isLogged, obligatory, handleLogin, haveRuleToAccept]);

    useEffect(() => {
        if (!haveRuleToAccept && isLogged && !isFetching) {
            userModule.setAllRulesAccepted(true);
            refetchSession?.();
            redirect();
        }
    }, [userModule, haveRuleToAccept, isLogged, isFetching, redirect, refetchSession]);

    useEffect(() => {
        if (!isLogged && !authData) {
            Router.back();
        }
    }, [isLogged, authData]);

    const onNextRule = async () => {
        if (haveRuleToAccept) {
            if (authData && index === 0) {
                await handleLogin(authData);
            } else {
                setIndex((prevState) => prevState + 1);
            }
        } else {
            refetch?.();
        }
    };

    const currentRule = haveRuleToAccept ? unacceptedRules[index] : undefined;

    return {
        rulesExists,
        currentRule,
        isFetching,
        onNextRule,
        error,
    };
};
