import { DATA_ATTR } from '@/types/epg-table.type';
import { NavTree } from 'nav-tree';

export const focusNodeInFocusedColumnById = (
    node: NavTree,
    focusedColIndex: number,
    currentHour: Date,
) => {
    Object.values(node.nodes).forEach((node) => {
        const id = node.el?.id;

        if (id === `channel_column_${focusedColIndex}`) {
            Object.values(node.nodes).forEach((innerNode) => {
                focusNodeById(innerNode, currentHour);
            });
        }
    });
};

export const focusNodeById = (node: NavTree, currentHour: Date) => {
    let resultNode: NavTree | undefined;

    Object.values(node.nodes).forEach((innerNode) => {
        if (innerNode.nodesId.length > 0) {
            const foundInnerNode = Object.values(innerNode.nodes).find((innerInnerNode) => {
                return findNodeByTime(innerInnerNode, currentHour);
            });
            if (foundInnerNode) {
                resultNode = foundInnerNode;
                foundInnerNode.focus();
                return;
            }
        }

        if (!resultNode) {
            const foundNode = findNodeByTime(innerNode, currentHour);
            if (foundNode) {
                resultNode = foundNode;
            }
        }
    });

    if (!resultNode) {
        if (node.nodesId.length > 0) {
            let innernode = Object.values(node.nodes)[0];

            if (innernode.nodesId.length > 0) {
                innernode = Object.values(innernode.nodes)[0];
            }

            innernode.focus();
            return;
        }
    }

    if (resultNode) {
        resultNode.focus();
        return;
    }
};

const buildTimestampFromAttr = (attribute: string, currentHour: Date) => {
    const timeFromAttr = attribute.split(':');

    const time = new Date(currentHour);
    time.setHours(+timeFromAttr[0]);
    time.setMinutes(+timeFromAttr[1]);
    time.setSeconds(0);
    time.setMilliseconds(0);
    const timestamp = time.getTime();

    return timestamp;
};

const findNodeByTime = (node: NavTree, currentHour: Date) => {
    const nodeStartTime = node.el?.getAttribute(DATA_ATTR.START_TIME);
    const nodeEndTime = node.el?.getAttribute(DATA_ATTR.END_TIME);

    if (!nodeStartTime) return null;
    if (!nodeEndTime) return node;

    const startTime = buildTimestampFromAttr(nodeStartTime, currentHour);
    const endTime = buildTimestampFromAttr(nodeEndTime, currentHour);

    const currentTime = currentHour.getTime();

    if (currentTime >= startTime && currentTime < endTime) {
        return node;
    }

    return null;
};
