import { isStandalone } from '@/constants/portal-recognition';
import { HomeMenuContext } from '@/contexts/menu/home.menu.context';
import { prepareMenuUrl } from '@/helpers/home-menu/prepare-menu-url.helper';
import { HomeMenuItemModel } from '@/models/home-menu-item.model';
import { ROUTES_CONFIG_SSR } from '@/routing/routes-ssr';
import { ROUTES_CONFIG_STANDALONE } from '@/standalone/src/routing/routes-standalone';
import { useContext, useMemo } from 'react';

export const useAppMainRoutes = () => {
    const { menuItemsCombined, subcategories } = useContext(HomeMenuContext);

    const APP_ROUTES = isStandalone ? ROUTES_CONFIG_STANDALONE : ROUTES_CONFIG_SSR;

    const routeNames = useMemo(() => {
        return menuItemsCombined.map((item) => {
            return item instanceof HomeMenuItemModel
                ? {
                      url: prepareMenuUrl(item.getPlace(), subcategories)?.route,
                      param: prepareMenuUrl(item.getPlace(), subcategories)?.params,
                  }
                : { url: item?.getUrl?.() };
        });
    }, [menuItemsCombined, subcategories]);

    const appMainRoutes = routeNames.map((route) =>
        APP_ROUTES.find(({ name }) => name === route.url),
    );

    return appMainRoutes;
};
