import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared/Buttons/';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { navVerticalTrap } from '@/helpers/nav-tree/helpers';
import { FullscreenModalBackgroundContainer, FullscreenModalButtonsNavContainer } from './styles';
import { FullscreenModalProps } from './types';

export const FullscreenModal = ({
    title,
    backgroundUrl,
    onConfirm,
    onCancel,
}: FullscreenModalProps) => {
    return (
        <FullscreenModalBackgroundContainer backgroundUrl={backgroundUrl}>
            <Message icon={ICONS.INFO_CIRCLE} title={title} gutter={GUTTER.BIG} />
            <FullscreenModalButtonsNavContainer func={navVerticalTrap}>
                <PlayableButton
                    navId="confirm"
                    text={t('ok-button-label')}
                    onButtonAction={onConfirm}
                    transparentOnInactive
                    defaultFocused
                />
                <PlayableButton
                    navId="cancel"
                    text={t('cancel-button-label')}
                    transparentOnInactive
                    onButtonAction={onCancel}
                />
            </FullscreenModalButtonsNavContainer>
        </FullscreenModalBackgroundContainer>
    );
};
