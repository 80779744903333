import styled, { css } from 'styled-components';

export const EditProfileButtonWrapper = styled.div<{ active: boolean }>`
    bottom: 0;
    left: 50%;
    cursor: default;
    position: absolute;
    transform: translate(-50%, 100%);

    svg {
        fill: ${({ theme }) => theme.iconFill.primary};
    }

    ${({ active }) =>
        active &&
        css`
            color: initial;
            svg {
                fill: ${({ theme }) => theme.iconFill.primaryHover};
            }
        `}
`;
