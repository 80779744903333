import React from 'react';

import { Icon, ICONS } from '@/components/shared/Icon';

import * as Styled from './styles';
import { ScrollIndicatorProps } from './types';
import { INDICATOR_VARIANT, SCROLL_POSITION } from '@/types/epg-table.type';

const ScrollIndicatorRaw = ({ variant, scrollPosition }: ScrollIndicatorProps) => {
    const isTop = variant === INDICATOR_VARIANT.TOP;
    const icon = isTop ? ICONS.ARROW_UP : ICONS.ARROW_DOWN;
    const show = isTop
        ? scrollPosition !== SCROLL_POSITION.TOP
        : scrollPosition !== SCROLL_POSITION.BOTTOM;

    if (!show) return null;

    return (
        <Styled.Indicator variant={variant}>
            <Styled.IconWrapper>
                <Icon name={icon} />
            </Styled.IconWrapper>
        </Styled.Indicator>
    );
};

export const ScrollIndicator = React.memo(ScrollIndicatorRaw);
