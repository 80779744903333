import { useEffect, useState } from 'react';

export const useWidevineConfiguration = () => {
    const [data, setData] = useState<MediaKeySystemConfiguration | null>(null);

    useEffect(() => {
        try {
            window.navigator
                .requestMediaKeySystemAccess('com.widevine.alpha', [
                    {
                        videoCapabilities: [
                            { contentType: 'video/mp4; codecs="avc1.42E01E"' },
                            { contentType: 'video/webm; codecs="vp8"' },
                        ],
                    },
                ])
                .then((data) => {
                    setData(data.getConfiguration());
                })
                .catch(() => {
                    setData(null);
                });
        } catch (err) {
            setData(null);
        }
    }, []);

    return { data };
};
