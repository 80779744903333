export const SUBMIT_TIMEOUT = 500;

// Opóźnienie dla załadowania skryptu captcha enterprise na konkretnej stronie
export const AWAIT_CAPTCHA_SCRIPT_TIMEOUT = 500;

//autoplay time
export const AUTOPLAY_COUNTDOWN_TIME = 10;
export const AUTOPLAY_START_TIME = 1;

// Automatyczne wylogowanie po potwierdzeniu ustawienia meila
export const AUTO_LOGOUT = 5000;
