import { useGetLatelyWatchedContentDataList } from '@/api/user-content/use-get-lately-watched-content-data-list';
import { useFmcContext } from '@/contexts/fmc/fmc.context';
import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

export const useRefetchFmc = (catid?: number) => {
    const { refetch } = useGetLatelyWatchedContentDataList(catid);
    const { refetch: refetchAll } = useGetLatelyWatchedContentDataList();

    const { fmcList } = useFmcContext();
    const queryClient = useQueryClient();

    const refetchFmc = useCallback(() => {
        queryClient.invalidateQueries({
            predicate: ({ queryKey }) => {
                const queryParams = queryKey[2] as Record<string, unknown>;
                return (
                    queryKey[1] === GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS &&
                    queryParams.staffRecommendationsListId == fmcList?.getId()
                );
            },
        });

        refetch?.();
        refetchAll?.();
    }, [fmcList, queryClient, refetch, refetchAll]);

    return { refetchFmc };
};
