import { preparePaymentsData } from '@/helpers/payment.helper';
import { PaymentsDataInterface, PAYMENT_STEPS } from '@/interfaces/payments.interface';
import { useContext } from 'react';
import { PaymentsDataContext } from '@/contexts/payments/payments.context';

export const usePaymentsData = () => {
    const { paymentsData, setPaymentsData } = useContext(PaymentsDataContext);

    const updatePaymentsData = (data: PaymentsDataInterface) => {
        setPaymentsData((prevPaymentsData) => ({ ...prevPaymentsData, ...data }));
    };

    const clearPaymentsData = (step?: PAYMENT_STEPS) => {
        setPaymentsData((prevPaymentsData) => preparePaymentsData(prevPaymentsData, step));
    };

    return {
        ...paymentsData,
        updatePaymentsData,
        clearPaymentsData,
    };
};
