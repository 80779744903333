import { useEffect, useState } from 'react';
import { isServer } from '@/helpers/environment.helper';
import { SESSION_STORAGE_KEYS, SessionStorageTypes } from '@/types/storage.type';

export const useSessionStorage = <T extends SESSION_STORAGE_KEYS>(key: T) => {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = !isServer && window.sessionStorage.getItem(key);
            return item ? (JSON.parse(item) as SessionStorageTypes[T]) : undefined;
        } catch (e) {
            return undefined;
        }
    });

    useEffect(() => {
        if (typeof storedValue !== 'undefined' && !isServer) {
            window.sessionStorage.setItem(key, JSON.stringify(storedValue));
        }
    }, [storedValue]);

    return [storedValue, setStoredValue] as const;
};
