import { isAuthorizedOnlyPaymentStep } from '../helpers/router/is-authorized-only-payment-step.helper';
import { ZONES_FALLBACK } from './routes';
import { ConditionalRedirectParamInterface, ROUTES, RoutesConfigInterface, ZONES } from './types';

const matchRouteByUrl = (url: string, routes: RoutesConfigInterface) =>
    routes.find(({ pattern }) =>
        new RegExp('^' + pattern.replace(/:[^/]+/g, '[^/]+') + '([#?][^/]*)?$').test(url),
    );

const matchRouteByRouteName = (name: ROUTES, routes: RoutesConfigInterface) =>
    routes.find((route) => route.name === name);

const paymentsPathRedirect = (url: string, isLogged: boolean) => {
    const zone = ZONES.LOGIN_WITHOUT_PROFILE;

    if (isAuthorizedOnlyPaymentStep(url)) {
        const fallback = ZONES_FALLBACK[zone];
        return isLogged ? fallback.logged : fallback.notLogged;
    }
};

export const shouldRedirectToRoute = (params: ConditionalRedirectParamInterface) => {
    const { url, isLogged, routeName, routes, redirectRouteZones } = params;

    let route;
    if (url) {
        route = matchRouteByUrl(url, routes);
    } else if (routeName) {
        route = matchRouteByRouteName(routeName, routes);
    } else {
        return undefined;
    }
    const zone = route?.zone;

    if (zone === ZONES.LOGIN_WITHOUT_PROFILE && url) {
        return paymentsPathRedirect(url, isLogged);
    }

    if (!zone || zone === ZONES.NOT_FOUND || !redirectRouteZones.includes(zone)) {
        return undefined;
    }

    return isLogged ? ZONES_FALLBACK[zone].logged : ZONES_FALLBACK[zone].notLogged;
};

export const shouldRedirectToUrl = (params: ConditionalRedirectParamInterface) => {
    const route = shouldRedirectToRoute(params);
    return route && matchRouteByRouteName(route, params.routes)?.pattern;
};
