import { Z_INDEX } from '@/targets/default/theme';
import styled from 'styled-components';

export const VirtualKeyboardContainer = styled.div`
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: ${Z_INDEX.VIRTUAL_KEYBOARD};
    height: ${({ theme }) => theme.virtualKeyboard.height};
    background-color: ${({ theme }) => theme.virtualKeyboard.backgroundColor};
`;

export const KeypadContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    &&& {
        .react-simple-keyboard {
            position: relative;
            padding-right: 5rem;
            margin-right: 5rem;

            &::before {
                content: '';
                position: absolute;
                right: 0rem;
                top: 5%;
                height: 90%;
                width: 0.3rem;
                background-color: ${({ theme }) =>
                    theme.virtualKeyboard?.submitButton?.dividerColor ||
                    theme.virtualKeyboard.hoverColor};
            }
        }

        .hg-theme-default {
            background-color: transparent;
        }

        .hg-standardBtn,
        .hg-button-shift,
        .hg-button-backspace {
        }

        .hg-button {
            background-color: ${({ theme }) =>
                theme.virtualKeyboard.buttonBackground || 'transparent'};
            box-shadow: none;
            padding: 0;
            margin-right: 0;
            border: 0;
            width: ${({ theme }) => theme.virtualKeyboard.buttonSize};
            max-width: ${({ theme }) => theme.virtualKeyboard.buttonSize};
            height: ${({ theme }) => theme.virtualKeyboard.buttonSize};
            color: ${({ theme }) => theme.virtualKeyboard.buttonColor};
            font-size: ${({ theme }) => theme.virtualKeyboard.buttonFontSize};
            border-radius: ${({ theme }) => theme.virtualKeyboard.buttonRadius || 'none'};
            margin: ${({ theme }) => theme.virtualKeyboard.buttonMargin || 'none'};
            font-family: ${({ theme }) => theme.virtualKeyboard.fontFamily || 'inherit'};
        }

        .hovered-button {
            background-color: ${({ theme }) => theme.virtualKeyboard.hoverColor};
            border-radius: 50%;
            color: ${({ theme }) =>
                theme.virtualKeyboard.hoveredButtonColor || theme.virtualKeyboard.buttonColor};

            svg {
                fill: ${({ theme }) =>
                    theme.virtualKeyboard.hoveredButtonColor || theme.virtualKeyboard.buttonColor};
            }
        }

        .hovered-button.hg-button-space {
            border-radius: 2rem;

            span {
                background-color: ${({ theme }) =>
                    theme.virtualKeyboard.hoveredButtonColor || theme.virtualKeyboard.buttonColor};
            }
        }

        .hg-button-space {
            width: ${({ theme }) => theme.virtualKeyboard.spaceButtonWidth};
            max-width: ${({ theme }) => theme.virtualKeyboard.spaceButtonWidth};
            border-radius: ${({ theme }) => theme.virtualKeyboard.spaceButtonRadius || 'none'};
            margin: ${({ theme }) => theme.virtualKeyboard.spaceButtonMargin || 'none'};

            span {
                background-color: ${({ theme }) => theme.virtualKeyboard.buttonColor};
                width: 90%;
                height: 2rem;
                border-radius: 5rem;
                display: block;
                margin: 0 auto;
            }
        }
    }

    .hg-button {
        svg {
            padding-top: 0.5rem;
        }
    }

    svg {
        max-width: ${({ theme }) => theme.virtualKeyboard.iconSize};
        height: ${({ theme }) => theme.virtualKeyboard.iconSize};
        fill: ${({ theme }) => theme.virtualKeyboard.buttonColor};
    }

    .hg-row {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
    }
`;

export const SubmitButton = styled.button<{ hovered?: boolean }>`
    background: transparent;
    border: none;
    padding: 0;
    font-size: ${({ theme }) => theme.virtualKeyboard.submitButton.fontSize};
    color: ${({ theme }) => theme.virtualKeyboard.submitButton.color};
    background-color: ${({ theme }) =>
        theme.virtualKeyboard.submitButton.buttonBackground || 'transparent'};
    border-radius: ${({ theme }) => theme.virtualKeyboard.submitButton.borderRadius || 'unset'};
    font-family: ${({ theme }) => theme.virtualKeyboard.fontFamily || 'inherit'};

    min-width: 10rem;
    min-height: 10rem;

    span {
        margin: 0 auto;
    }

    &&& {
        ${({ theme, hovered }) =>
            hovered &&
            `
            background-color: ${theme.virtualKeyboard.hoverColor};
            border-radius: 50%;
            color: ${
                theme.virtualKeyboard?.submitButton?.hoveredButtonColor ||
                theme.virtualKeyboard.submitButton.color
            }
      `}
    }
`;
