import { useMemo } from 'react';
import { CheckProductsAccessIn } from '@/interfaces/from-schemas/drm/checkProductsAccessIn';
import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { CheckProductAccessModel } from '@/models/payments/check-product-access.model';
import { CheckProductsAccessOut } from '@/interfaces/from-schemas/drm/checkProductsAccessOut';

const useCheckProductsAccess = (
    params?: CheckProductsAccessIn | false,
): IApiResponse<CheckProductAccessModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.DRM,
        GET_MEDIA_METHODS.CHECK_PRODUCTS_ACCESS,
        params,
    );

    const { data, isFetching, error } = useGetMediaApi(keyHook, { appendAuthData: true });

    const memoizedData = useMemo(
        () =>
            data &&
            data.map(
                ({ access, product }: CheckProductsAccessOut[number]) =>
                    new CheckProductAccessModel(access, product),
            ),
        [data],
    );

    return {
        data: memoizedData,
        isFetching,
        error,
    };
};

export default useCheckProductsAccess;
