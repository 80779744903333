import { GET_MEDIA_PLACE_TYPE } from '@/types/get-media-params.type';

interface ConfigInterface {
    placeType: GET_MEDIA_PLACE_TYPE;
    singlePacket?: boolean;
    categoryName?: string;
}

export const buildCollectionsListConfig = (config: ConfigInterface) => {
    const initialCollectionsNumber = process.env.pages.collections.initialLimit;
    const increaseCollectionsNumber = process.env.pages.collections.increaseBy;
    const disableBg = process.env.slider.disableBg;

    const collectionsConfig = {
        initialCollectionsNumber,
        increaseCollectionsNumber,
        liveList: config.placeType === GET_MEDIA_PLACE_TYPE.LIVE_CHANNELS,
        withPackets: Boolean(config.singlePacket),
        defaultFocused: true,
        disableBg,
        categoryName: config.categoryName,
    };

    return collectionsConfig;
};
