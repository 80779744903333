export enum CPID {
    EVENT_OR_CHANNEL = 0,
    VOD_OR_MOVIE = 1,
    CATEGORY_OR_PACK = 7,
    PACKET = 9,
}

export enum FILTER_TYPE {
    CATEGORY = 'category',
}

export enum MEDIA_ACCESS_STATUS {
    HAS_ACCESS = 'HAS_ACCESS',
    NO_ACCESS = 'NO_ACCESS',
}

export enum MEDIA_TYPES {
    VOD = 'vod',
    MOVIE = 'movie',
    LIVE = 'live',
    TV = 'tv',
    CHANNEL = 'channel',
    CATEGORY = 'category',
    PACK = 'pack',
    PACKET = 'packet',
    TRAILER = 'trailer',
    URL = 'url',
    TV_PROGRAM = 'tv_program',
}

export enum METADATA_TYPE {
    MAIN = 'main',
    LIST_ELEMENT = 'list-element',
}

export enum MULTIPLE_PRODUCTS_SUBTYPE {
    PACKET = 'packet',
    SEASON = 'season',
    CATALOGUE = 'catalogue',
    BUNDLE = 'bundle',
}

export enum PRODUCT_SUBTYPES {
    VOD = 'vod',
    MOVIE = 'movie',
    LIVE = 'live',
    TV = 'tv',
    PACKET = 'packet',
    SEASON = 'season',
    CATALOGUE = 'catalogue',
    BUNDLE = 'bundle',
    ADVERTISEMENT = 'advertisement',
    QUALITY = 'quality',
    DEVICE = 'device',
}

export enum PRODUCT_TYPES {
    MEDIA = 'media',
    MULTIPLE = 'multiple',
    VAS = 'vas',
}
