import styled from 'styled-components';

export const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Status = styled.div`
    margin-left: 1rem;
`;
