import React from 'react';

import { ICONS } from '@/components/shared/Icon/types';
import { Image } from '@/components/shared/Image';
import { IconGroup, Metadata, METADATA_TYPES } from '@/components/shared/Metadata';
import { useFocusedEPGContext } from '@/contexts/epg-table/focused-epg.context';
import { GUTTER } from '@/types/gutter.type';

import * as Styled from './styles';

export const EPGPreview = () => {
    const { focusedEPG, channelName = '' } = useFocusedEPGContext();

    if (!focusedEPG) return null;

    const img = focusedEPG.getImage();
    const metadata = [channelName, ...focusedEPG.epgMetaData];
    const isOnAir = focusedEPG.getIsOnAir();

    return (
        <>
            <Styled.EPGPreview>
                {img.src && (
                    <Styled.PreviewImageWrapper>
                        <Image
                            src={img.src}
                            withPlaceholder={img.displayMode}
                            width="100%"
                            height="100%"
                        />
                    </Styled.PreviewImageWrapper>
                )}
                <div>
                    <Metadata data={metadata} type={METADATA_TYPES.EPG_PREVIEW} isSafeHTML />
                    <Styled.TitleWrapper>
                        {isOnAir && (
                            <IconGroup
                                icon={ICONS.SIMPLE_PLAY}
                                iconSize={2.7}
                                targetColor
                                noMargin
                            />
                        )}
                        <Styled.Title text={focusedEPG.getTitle()} gutter={GUTTER.NONE} lines={1} />
                    </Styled.TitleWrapper>

                    <Styled.Description text={focusedEPG.getDescription()} lines={2} />
                </div>
            </Styled.EPGPreview>
        </>
    );
};
