import useCreateProfile from '@/api/auth/use-create-profile';
import useGetProfiles from '@/api/auth/use-get-profiles';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import { PlayableButton, PlayableDynamicButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { ProfileIconGrid } from '@/components/views/Profile/ProfileIconGrid';
import { ProfileNameForm } from '@/components/views/Profile/ProfileNameForm';
import { ProfileNameFormData } from '@/components/views/Profile/ProfileNameForm/types';
import { ProfileBackButtonWrapper } from '@/components/views/Profile/styles';
import { ReferrerContext } from '@/contexts/referrer/referrer.context';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import { navHorizontal } from 'nav-tree';
import React, { useContext, useMemo, useRef, useState } from 'react';

const AddProfileForm = () => {
    const { referrer } = useContext(ReferrerContext);
    const [selectedIcon, setSelectedIcon] = useState<string>();
    const [selectedName, setSelectedName] = useState<string>();
    const [isProfileCreated, setIsProfileCreated] = useState<boolean>();

    const [addProfile, { error: createError }] = useCreateProfile();
    const { data: profiles, error: profilesError } = useGetProfiles();

    const createdProfile = profiles?.find(({ name }) => name === selectedName);
    const errorToDisplay = profilesError?.getUserMessage() || createError?.getUserMessage();
    const omitRef = useRef<boolean>(false);

    useMemo(() => {
        if (isProfileCreated) {
            if (omitRef.current) return;

            omitRef.current = true;
            setTimeout(() => (omitRef.current = false), 0);

            referrer
                ? Router.back()
                : Router.pushRoute(ROUTES.PROFILE, { profileId: createdProfile!.profileId });
        }
    }, [isProfileCreated, referrer]);

    const onFormSubmit = async (data: ProfileNameFormData) => {
        if (profiles?.length >= process.env.user.maxProfiles) {
            return Router.replaceRoute(ROUTES.PROFILE_SELECT_TO_EDIT);
        }

        setSelectedName(data.name);

        const result = await addProfile({
            name: data.name,
            avatarId: selectedIcon as string,
        });

        if (result.ok) {
            setIsProfileCreated(true);
        }
    };

    const setProfileName = (
        <>
            <ProfileBackButtonWrapper func={navHorizontal}>
                <PlayableDynamicButton
                    onButtonAction={() => setSelectedIcon(undefined)}
                    text={t('back-button-label')}
                    icon={ICONS.ARROW_LEFT}
                    data-testing="back-button-label"
                />
            </ProfileBackButtonWrapper>
            <MainText
                text={t('pages.profile.change-name.header')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />

            <ProfileNameForm onFormSubmit={onFormSubmit} onCancel={() => Router.back()} />
        </>
    );

    const selectProfileIcon = (
        <>
            <MainText
                text={t('pages.profile.change-icon.header')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />
            <ProfileIconGrid onSelect={(profileId) => setSelectedIcon(profileId)} />
            <FormContainer>
                <PlayableButton
                    text={t('cancel-button-label')}
                    onButtonAction={() => Router.back()}
                    data-testing="cancel-button-label"
                />
            </FormContainer>
        </>
    );

    return (
        <>
            {selectedIcon ? setProfileName : selectProfileIcon}
            {errorToDisplay && (
                <AlertMessage type={MESSAGE_TYPE.ERROR} userMessage={errorToDisplay} />
            )}
        </>
    );
};

const AddProfile = () => {
    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={<AddProfileForm />} fullBody={true} />
        </BaseLayout>
    );
};

export default AddProfile;
