import useGetProfile from '@/api/auth/use-get-profile';
import { Avatar, AVATAR_SIZE } from '@/components/shared/Avatar';
import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { ProfileAvatarContainer } from '@/components/views/Profile/styles';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';

const Profile = () => {
    const { profileId } = useProfilePageInitialProps();
    const { data: profile, error } = useGetProfile({ id: profileId });
    const errorToDisplay = error?.getUserMessage();

    const renderProfile = () => {
        if (profile) {
            return (
                <>
                    <ProfileAvatarContainer>
                        <Avatar
                            $size={AVATAR_SIZE.BIG}
                            url={profile.avatarUrl}
                            text={profile.name}
                        />
                    </ProfileAvatarContainer>

                    <FormContainer>
                        <PlayableButton
                            text={t('button.profile.change-icon')}
                            onButtonAction={() =>
                                Router.pushRoute(ROUTES.PROFILE_CHANGE_ICON, { profileId })
                            }
                            defaultFocused
                        />
                        <PlayableButton
                            text={t('button.profile.change-name')}
                            onButtonAction={() =>
                                Router.pushRoute(ROUTES.PROFILE_CHANGE_NAME, { profileId })
                            }
                        />

                        {/* Funkcjonalność nie będzie dostępna w pierwszej wersji */}

                        {/*<PlayableButton*/}
                        {/*    text={t('button.profile.set-parent-control')}*/}
                        {/*    onButtonAction={() =>*/}
                        {/*        Router.pushRoute(ROUTES.PROFILE_PARENT_CONTROL_PASSWORD, {*/}
                        {/*            profileId,*/}
                        {/*        })*/}
                        {/*    }*/}
                        {/*/>*/}

                        <PlayableButton
                            text={t('button.profile.remove-profile')}
                            onButtonAction={() =>
                                Router.pushRoute(ROUTES.PROFILE_REMOVE, { profileId })
                            }
                        />
                        <PlayableButton
                            text={t('ok-button-label')}
                            onButtonAction={() => Router.back()}
                        />
                    </FormContainer>
                </>
            );
        }
    };

    const body = (
        <>
            <MainText
                text={t('pages.profile.header')}
                mode={MAINTEXT_KIND.PAGE_TITLE}
                gutter={GUTTER.BIG}
            />
            {renderProfile()}
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} fullBody={true} error={errorToDisplay} />
        </BaseLayout>
    );
};

export default Profile;
