import { createContext, Dispatch, SetStateAction } from 'react';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { CyProps } from '@/types/cypress.type';

interface SliderContextProps {
    loadRow: boolean;
    activeRow: number | null;
    setActiveRow: Dispatch<SetStateAction<number | null>>;
    collectionActiveIndex: number;
    setCollectionActiveIndex: Dispatch<SetStateAction<number>>;
}

export const SliderContext = createContext<SliderContextProps>({
    loadRow: false,
    activeRow: null,
    setActiveRow: () => null,
    collectionActiveIndex: 0,
    setCollectionActiveIndex: () => null,
});

export interface SliderOwnProps extends CyProps {
    collectionElements: ListElementInterface[];
    onFetchMore?: (limit: number) => void;
    config: SliderConfigInterface;
    onSliderNav?: (isActive: boolean) => void;
    lockNav?: boolean;
    onActiveItem?: (activeItem: ListElementInterface) => void;
    showPlaceholders?: boolean;
    loadNextSlicePage?: (focusedIndex: number) => void;
}

export interface SliderElementsOwnProps {
    collectionElements: ListElementInterface[];
    onActiveElement: (itemData: ListElementInterface, index?: number) => void;
    config: SliderConfigInterface;
}

export interface SliderConfigInterface {
    rowIndex: number;
    list?: StaffRecommendationListModel;
    defaultFocused?: boolean;
    fetchedAll?: boolean;
    collectionLength: number;
    withPackets?: boolean;
}

export interface SliderInterface {
    height?: number;
}

export interface SliderButtonsInterface {
    sliderHeight?: number;
}

export interface SliderElementsInterface {
    hide?: boolean;
    imageDisplayMode?: IMAGE_DISPLAY_MODE;
    activeItemId?: number;
    width?: number;
    minHeight?: number;
}

export interface SliderElementsIncreaseInterface {
    sliceCount?: number;
    trigger: number;
}

export interface SliderButtonsProps {
    isVisible: boolean;
    sliderHeight: number | undefined;
    isScrollableRight: boolean;
    isScrollableLeft: boolean;
    onScrollLeft: () => void;
    onScrollRight: () => void;
}
