export class GoogleTagManagerService {
    public updateDataLayer = (item: Record<string, unknown>) => {
        let triesCounter = 0;
        if (window.dataLayer && typeof window.dataLayer.push === 'function') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(item);
        } else {
            if (triesCounter < 10) {
                setTimeout(() => this.updateDataLayer(item), 300);
            }
            triesCounter++;
            return;
        }
    };
}
