import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ChangePasswordForm } from '@/components/views/Account/PasswordChangeForm';
import { PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { navVertical } from 'nav-tree';
import React from 'react';

const AccountChangePassword = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer func={navVertical}>
                <MainText
                    text={t('pages.account.change-password.header')}
                    mode={MAINTEXT_KIND.PAGE_TITLE}
                />
                <ChangePasswordForm />
            </PageContainer>
        </BaseLayout>
    );
};

export default AccountChangePassword;
