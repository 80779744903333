import GetLatelyWatchedContentDataModel from './lately-watched-content-data.model';
import {
    GetLatelyWatchedContentDataListOut,
    WatchedContentDataSchema,
} from '../../interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';

class LatelyWatchedContentDataListModel {
    private readonly count: number = 0;
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private readonly results: WatchedContentDataSchema[] = [];
    private readonly total: number = 0;

    constructor(definition: GetLatelyWatchedContentDataListOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.results = [];
            definition.results.forEach((result: WatchedContentDataSchema) => {
                this.results.push(new GetLatelyWatchedContentDataModel(result));
            });
        }
    }

    public getCount(): number {
        return this.count;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getResults(): WatchedContentDataSchema[] {
        return this.results;
    }

    public getTotal(): number {
        return this.total;
    }
}

export default LatelyWatchedContentDataListModel;
