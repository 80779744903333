import { CacTableRow, CacTableHeader, TableInlineLoader } from './styles';
import { ProcessingLoader } from '@/components/shared/Loader';
import { GUTTER } from '@/types/gutter.type';
import { Nav, navVertical } from 'nav-tree';
import { Message } from '@/components/shared/Message';
import { PlayableButton } from '@/components/shared/Buttons';
import t from '@/lib/i18n';

interface IConcurrentPlaybackErrorTable {
    playbacks: IPlayer.DrmUserPlayback[];
    isPlaybackPending: (connectionId: string) => boolean;
    onStopPlayback: (connection: IPlayer.PlaybackConnection) => void;
}

export const ConcurrentPlaybackErrorTable = ({
    playbacks,
    isPlaybackPending,
    onStopPlayback,
}: IConcurrentPlaybackErrorTable) => (
    <Nav func={navVertical} style={{ width: '100%' }}>
        <CacTableHeader>{t('player.cac.limit-exceeded.header-device')}</CacTableHeader>
        {playbacks.map(({ connection, device }, i) => (
            <CacTableRow key={connection?.id}>
                <Message description={device?.name} gutter={GUTTER.NONE} />

                {connection && (
                    <>
                        {isPlaybackPending(connection.id) ? (
                            <TableInlineLoader>
                                <ProcessingLoader isFetching inline />
                            </TableInlineLoader>
                        ) : (
                            <PlayableButton
                                text={t('player.cac.limit-exceeded.stop')}
                                gutter={GUTTER.NONE}
                                transparentOnInactive
                                defaultFocused={i === 0}
                                onButtonAction={() => onStopPlayback(connection)}
                            />
                        )}
                    </>
                )}
            </CacTableRow>
        ))}
    </Nav>
);
