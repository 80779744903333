import { createContext, useContext, useMemo, useState } from 'react';
import { RightPanelContextProps, RightPanelContextProviderProps, RightPanelMode } from './types';

export const RightPanelContext = createContext<RightPanelContextProps>({
    rightPanelOpen: false,
    setRightPanelOpen: () => null,
    mode: 'filters',
    setMode: () => null,
});

const RightPanelContextProvider = ({ children }: RightPanelContextProviderProps) => {
    const [rightPanelOpen, setRightPanelOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<RightPanelMode>('filters');

    return useMemo(
        () => (
            <RightPanelContext.Provider
                value={{ rightPanelOpen, setRightPanelOpen, mode, setMode }}
            >
                {children}
            </RightPanelContext.Provider>
        ),
        [rightPanelOpen, mode, children],
    );
};

export const useRightPanel = () => useContext(RightPanelContext);

export default RightPanelContextProvider;
