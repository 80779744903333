import { createContext, useCallback, useMemo, useState } from 'react';
import { SearchContextProps, SearchContextProviderProps } from './types';

export const SearchContext = createContext<SearchContextProps>({
    inputValue: '',
    setInputValue: () => null,
    clearContext: () => null,
});

const SearchContextProvider = ({ children }: SearchContextProviderProps) => {
    const [inputValue, setInputValue] = useState<string>('');

    const clearContext = useCallback(() => setInputValue(''), []);

    return useMemo(
        () => (
            <SearchContext.Provider value={{ inputValue, setInputValue, clearContext }}>
                {children}
            </SearchContext.Provider>
        ),
        [inputValue],
    );
};

export default SearchContextProvider;
