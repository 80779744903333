import { SubcategoryParams } from '@/components/views/common/types';
import { useGetCategoryCatalogData } from '@/api/navigation/category/use-get-category-catalog-data';

export const buildSubcategoryCatalogConfig = (params: SubcategoryParams) => {
    return {
        fetcher: useGetCategoryCatalogData,
        catalogParams: { catid: params.subcategoryId },
        withSubcategory: params,
    };
};
