import * as Yup from 'yup';
import t from '@/lib/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@/lib/yupResolver';
import { useRouter } from '@/routing';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { emailAutocomplete } from '@/helpers/auth/email-autocomplete.helper';

export interface AddEmailFormData {
    email: string;
    newsletter?: boolean;
}

const schema = Yup.object().shape({
    email: Yup.string()
        .email(t('form.validate.email-valid'))
        .required(t('form.validate.new-email-required')),
});

export const useChangeEmailOrRegisterForm = () => {
    const { query } = useRouter();
    const userModule = useUserModule();
    const email = emailAutocomplete(query, userModule);

    return useForm<AddEmailFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            email,
        },
    });
};
