import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import { PageContextProps, PageContextProviderProps } from './types';
import { IncrementContext } from '../increment/increment.context';
import { RecoContext } from '../reco/reco.context';

export const PageContext = createContext<PageContextProps>({
    isCatalog: false,
    setIsCatalog: () => false,
    listType: LIST_TYPE.PROMOBOX,
    setListType: () => null,
    refocusPage: false,
    setRefocusPage: () => null,
    noLists: false,
    setNoLists: () => null,
    clearContext: () => null,
    isRecommended: false,
    setIsRecommended: () => false,
});

const PageContextProvider = ({ children }: PageContextProviderProps) => {
    const incrementContext = useContext(IncrementContext);
    const recoContext = useContext(RecoContext);

    const [isCatalog, setIsCatalog] = useState<boolean>(false);
    const [isRecommended, setIsRecommended] = useState<boolean>(false);
    const [listType, setListType] = useState<LIST_TYPE>(LIST_TYPE.PROMOBOX);
    const [noLists, setNoLists] = useState<boolean>(false);
    const [refocusPage, setRefocusPage] = useState<boolean>(false);

    const clearContext = useCallback(() => {
        setIsCatalog(false);
        setIsRecommended(false);
        setRefocusPage(true);

        incrementContext.clearContext();
        recoContext.clearContext();
    }, []);

    return useMemo(
        () => (
            <PageContext.Provider
                value={{
                    isCatalog,
                    setIsCatalog,
                    isRecommended,
                    setIsRecommended,
                    listType,
                    setListType,
                    refocusPage,
                    setRefocusPage,
                    noLists,
                    setNoLists,
                    clearContext,
                }}
            >
                {children}
            </PageContext.Provider>
        ),
        [isCatalog, listType, refocusPage, noLists, isRecommended],
    );
};

export default PageContextProvider;
