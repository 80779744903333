import { THEME_TYPE } from '@/types/theme.type';
import theme from 'target/theme';

export const getDefaultTheme = () => {
    const defaultTheme = process.env.theme?.defaultTheme;

    return defaultTheme ? defaultTheme : THEME_TYPE.DARK;
};

export const switchTheme = (contextTheme: THEME_TYPE | null) => {
    switch (contextTheme) {
        case THEME_TYPE.DARK:
            return theme.dark;
        case THEME_TYPE.LIGHT:
            return theme.light;

        default:
            return theme.dark;
    }
};
