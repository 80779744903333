import { useIsOutOfViewport } from '@/hooks/use-is-out-of-viewport.hook';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import React, { useRef } from 'react';
import { PlaceholderStyled, RadialGradient, TriangleStyled } from './styles';

interface IPlaceholderProps {
    isLoading?: boolean;
    imageDisplayMode?: IMAGE_DISPLAY_MODE;
    isBot?: boolean;
}

const Placeholder = ({
    isLoading,
    imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS,
    isBot,
}: IPlaceholderProps) => {
    const { slowDevice } = process.env;

    const ref = useRef<HTMLDivElement>(null);
    const { isOutOfViewport } = useIsOutOfViewport(ref);

    return (
        <PlaceholderStyled imageDisplayMode={imageDisplayMode} isBot={isBot}>
            <RadialGradient
                ref={ref}
                withAnimation={!isOutOfViewport && !slowDevice && isLoading}
            />
            <TriangleStyled />
        </PlaceholderStyled>
    );
};

export const PlaceholderMemo = React.memo(Placeholder);
