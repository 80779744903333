import { ImageSourceSchema } from '@/interfaces/from-schemas/navigation/getSubCategoriesOut';
import { CategorySchema } from '@/interfaces/from-schemas/navigation/getSubCategoriesOut';
import { CPID } from '@/types/media.type';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

export class SubcategoryModel {
    private readonly cpid: CPID = CPID.CATEGORY_OR_PACK;
    private readonly id: number = 0;
    private readonly name: string = '';
    private readonly description: string = '';
    private readonly genres: string[] = [];
    private readonly categoryPath: number[] = [];
    private readonly categoryNamesPath: string[] = [];
    private readonly keyCategoryId: number = 0;
    private readonly subCategoriesLabel: string = '';
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly reporting: CategorySchema['reporting'] = {};

    constructor(definition: CategorySchema) {
        Object.keys(definition).forEach((name: string) => {
            // @ts-ignore
            this[name] = definition[name];
        });
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }

    public getDescription(): string {
        return this.description;
    }

    public isThumbnailsExists(): boolean {
        return this.getThumbnails().length > 0;
    }

    public getMediaKey(): string {
        return `${this.id}|${this.cpid}`;
    }

    public getId(): number {
        return this.id;
    }

    public getKeyCategoryId(): number {
        return this.keyCategoryId;
    }

    public getCategoryNamesPath(): string[] {
        return this.categoryNamesPath;
    }

    public getCategoryPath(): number[] {
        return this.categoryPath;
    }

    public getTitle(): string {
        return this.name;
    }

    private buildSubcategoryRouteParams() {
        return {
            categoryId: this.keyCategoryId,
            subcategoryId: this.id,
        };
    }

    public goToSubcategory() {
        Router.pushRoute(ROUTES.SUBCATEGORY, this.buildSubcategoryRouteParams());
    }
}
