import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { PacketContext } from '@/contexts/packet/packet.context';
import { PageContext } from '@/contexts/page/page.context';
import { SearchContext } from '@/contexts/search/search.context';
import { ROUTES } from '@/routing/types';
import { useCallback, useContext } from 'react';

export const useClearContext = () => {
    const { clearContext: clearPageContext, setRefocusPage } = useContext(PageContext);
    const { clearContext: clearPacketContext } = useContext(PacketContext);
    const { clearContext: clearCatalogContext } = useContext(CatalogContext);
    const { clearContext: clearSearchContext } = useContext(SearchContext);

    const clearContext = useCallback((route: ROUTES) => {
        switch (route) {
            case ROUTES.HOME:
            case ROUTES.LIVE:
                clearPageContext();
                break;

            case ROUTES.VOD:
            case ROUTES.PACKETS:
                clearCatalogContext();
                clearPageContext();

                // Tymczasowe ponowienie refokusu strony
                setRefocusPage(true);
                break;

            case ROUTES.CHANNELS_TV:
                clearPageContext();
                clearCatalogContext();
                setRefocusPage(true);
                break;

            case ROUTES.MENU_MY_LIST:
                setRefocusPage(true);
                clearCatalogContext();
                break;

            case ROUTES.SEARCH:
                clearCatalogContext();
                clearSearchContext();
                clearPageContext();
                break;

            case ROUTES.PACKET_DETAILS:
                clearPacketContext();
                break;

            default:
            // noop
        }
    }, []);

    return clearContext;
};
