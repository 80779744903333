import React from 'react';
import {
    StyledEnvironmentInfoContainer,
    StyledHeaderText,
    StyledEnvironmentRow,
    StyledEnvironmentTitle,
    StyledEnvironmentValue,
    StyledHelpContainer,
} from './styles';
import t from '@/lib/i18n';
import { useGetEnvironmentInfo } from '@/hooks/use-get-environment-info';
import { GetEnvironmentInfoInterface } from '@/interfaces/get-environment-info-interface';
import { ProcessingLoader } from '@/components/shared/Loader';

export const HelpContainer = () => {
    const { data, isFetching } = useGetEnvironmentInfo();

    const renderEnvironmentTable = (data: GetEnvironmentInfoInterface) => {
        const isWidevineSupported = data.widevineSupported
            ? t('pages.account.help.yes')
            : t('pages.account.help.no');
        const environmentData = [
            { title: t('pages.account.help.device-name'), value: data.deviceName },
            { title: t('pages.account.help.system-version'), value: data.systemVersion },
            { title: t('pages.account.help.application-version'), value: data.appVersion },
            { title: t('pages.account.help.model'), value: data.model },
            { title: t('pages.account.help.user-agent'), value: data.userAgent },
            { title: t('pages.account.help.account'), value: data.userLogin },
            { title: t('pages.account.help.network'), value: data.ip },
            { title: t('pages.account.help.date-with-hours'), value: data.currentTime },
            { title: t('pages.account.help.screen-size'), value: data.screenSize },
            { title: t('pages.account.help.player-version'), value: data.playerVersion },
            { title: t('pages.account.help.widevine'), value: isWidevineSupported },
        ];

        return (
            <>
                {environmentData.map((item, index) => {
                    const value = item.value || t('pages.account.help.no-data');
                    return (
                        <StyledEnvironmentRow key={index}>
                            <StyledEnvironmentTitle>{item.title}</StyledEnvironmentTitle>
                            <StyledEnvironmentValue>{value}</StyledEnvironmentValue>
                        </StyledEnvironmentRow>
                    );
                })}
            </>
        );
    };

    return (
        <StyledHelpContainer>
            <StyledHeaderText>{t('pages.account.help.header-text')}</StyledHeaderText>
            <StyledEnvironmentInfoContainer>
                <ProcessingLoader isFetching={Boolean(isFetching)} />
                {!isFetching && data && renderEnvironmentTable(data)}
            </StyledEnvironmentInfoContainer>
        </StyledHelpContainer>
    );
};
