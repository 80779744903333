import React, { useEffect, useMemo, useRef } from 'react';
import { Nav } from 'nav-tree';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { Grid } from '@/components/shared/Grid';
import { ICONS } from '@/components/shared/Icon';
import { ProcessingLoader } from '@/components/shared/Loader';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { useInitialRefocus } from '@/hooks/nav-tree/use-initial-refocus.hook';
import { useIcrementalUpdate } from '@/hooks/use-incremental-update.hook';
import { useCurrentGridFocus } from '@/hooks/nav-tree/use-current-grid-focus.hook';
import { ResultsContainer, ResultsGridContainer, ResultsTitleContainer } from './styles';
import { SearchResultsProps } from './types';
import { useSearchResults } from '@/hooks/use-search-results.hook';

const { defaultGridCols } = process.env.pages.search;

const SearchResultsRaw = ({ query, onNoData }: SearchResultsProps) => {
    const focusRef = useRef<Nav>(null);

    const { gridElements, isFetching } = useSearchResults(query);
    const dataExist = gridElements.length && !isFetching;

    const fetchingHasStarted = useIcrementalUpdate(
        isFetching,
        (oldValue, newValue) => oldValue || newValue,
    );
    const enabled = fetchingHasStarted === false;

    useEffect(() => {
        if (gridElements === undefined) {
            onNoData?.();
        }
    }, [gridElements, onNoData]);

    const { currentFocus } = useCurrentGridFocus({ columns: defaultGridCols });

    useInitialRefocus({ focusRef, enabled, focusIndex: currentFocus });

    const searchResults = useMemo(() => {
        if (isFetching) {
            return <ProcessingLoader isFetching />;
        }

        if (dataExist) {
            return (
                <ResultsContainer>
                    <ResultsTitleContainer>
                        <MainText
                            text={t('components.search.search-results')}
                            mode={MAINTEXT_KIND.SUBTITLE}
                        />
                    </ResultsTitleContainer>

                    <ResultsGridContainer>
                        <Grid
                            data={gridElements}
                            col={defaultGridCols}
                            defaultFocused={enabled}
                            focusRef={focusRef}
                            isSearch
                        />
                    </ResultsGridContainer>
                </ResultsContainer>
            );
        }

        return (
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={t('components.search.no-search-results')}
                description={t('components.search.try-again')}
            />
        );
    }, [dataExist, enabled, gridElements, isFetching]);

    return searchResults;
};

export const SearchResults = React.memo(SearchResultsRaw);
