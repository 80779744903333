import defaultKeyHook from '@/hooks/default-key';
import {
    IApiResponse,
    StrippedInParams,
    GetMediaPromiseFunc,
} from '@/hooks/use-get-media-api.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { GetFavoritesWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getFavoritesWithFlatNavigationIn';
import FavoritesWithFlatNavigationModel from '@/models/favorites/favorites-with-flat-navigation.model';
import { useIsUserLogged } from '@/hooks/user/use-is-user-logged.hook';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.GET_FAVORITES_WITH_FLAT_NAVIGATION;

export const useGetFavoritesWithFlatNavigation = (
    params?: StrippedInParams<GetFavoritesWithFlatNavigationIn> | null,
): IApiResponse<FavoritesWithFlatNavigationModel> => {
    const isLogged = useIsUserLogged();
    const keyHook = defaultKeyHook(namespace, method, isLogged ? params : null);

    const options = {
        queryOptions: {},

        // disable query cache when filters are set
        // query without filters is cached and invalidated when add/delete favourite hook is used
        disableCache: !!params?.filters,
    };

    const { data, isFetching, error } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new FavoritesWithFlatNavigationModel(data), [data]),
        isFetching,
        error,
    };
};

export const getFavoritesWithFlatNavigationPromise = (
    params: StrippedInParams<GetFavoritesWithFlatNavigationIn>,
    getMediaApiPromise: GetMediaPromiseFunc,
) => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {});
};
