import { setModelByContentOutType } from '@/helpers/set-model-by-content-out-type.helper';
import { LiveChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getLiveChannelsOut';
import {
    CategorySchema,
    GetPacketContentWithFlatNavigationOut,
    MediaListItemSchema,
} from '@/interfaces/from-schemas/navigation/getPacketContentWithFlatNavigationOut';
import { TvChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getTvChannelsOut';
import { MediaListItemModel } from '@/models/media-list-item.model';
import { ContentModelType } from '@/types/content-model.type';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';

class PacketContentWithFlatNavigationModel {
    private readonly count: number = 0;
    private readonly flatNavigation: GetPacketContentWithFlatNavigationOut['flatNavigation'] = {};
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private results: ContentModelType = [];
    private readonly total: number = 0;

    constructor(definition: GetPacketContentWithFlatNavigationOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.results = [];
            definition.results.forEach(
                (
                    value:
                        | CategorySchema
                        | MediaListItemSchema
                        | TvChannelListItemSchema
                        | LiveChannelListItemSchema,
                ) => {
                    return this.results.push(setModelByContentOutType(value));
                },
            );
        }
    }

    public getCount(): number {
        return this.count;
    }

    public getFlatNavigation(): GetPacketContentWithFlatNavigationOut['flatNavigation'] {
        return this.flatNavigation;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getResults(): ContentModelType {
        return this.results;
    }

    public getTotal(): number {
        return this.total;
    }

    public getImageDisplayMode(): IMAGE_DISPLAY_MODE {
        const potstersArr = this.results.filter((item) => {
            if (item instanceof MediaListItemModel) {
                return item.getPosters().length;
            }
        });
        if (potstersArr.length) {
            return IMAGE_DISPLAY_MODE.POSTERS;
        } else {
            return IMAGE_DISPLAY_MODE.THUMBNAILS;
        }
    }
}

export default PacketContentWithFlatNavigationModel;
