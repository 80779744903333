import React from 'react';
import { PurchaseOptionRuleListItemStyled } from './styles';
import { PurchaseOptionRuleListItemProps } from './types';

export const PurchaseOptionRuleListItem = ({
    rule,
    defaultFocused,
    onSelect,
}: PurchaseOptionRuleListItemProps) => {
    return (
        <>
            <PurchaseOptionRuleListItemStyled
                autofocusOn={['focus', 'mouseenter']}
                defaultFocused={defaultFocused}
                onEnterDown={() => onSelect(rule)}
                onMouseDown={() => onSelect(rule)}
            >
                <p>{rule.getName()}</p>
            </PurchaseOptionRuleListItemStyled>
        </>
    );
};
