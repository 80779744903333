import { ProductIdSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { GetCommonlyAccessiblePacketsOut } from '@/interfaces/from-schemas/navigation/getCommonlyAccessiblePacketsOut';

type GetCommonlyAccessiblePackets = GetCommonlyAccessiblePacketsOut[number];

export class CommonlyAccessiblePacketModel {
    readonly id: string;
    private readonly product: ProductIdSchema;
    private readonly validFrom: GetCommonlyAccessiblePackets['validFrom'];
    private readonly validTo: GetCommonlyAccessiblePackets['validTo'];

    constructor(definition: GetCommonlyAccessiblePackets) {
        this.product = definition.product;
        this.id = definition.id;
        this.validFrom = definition.validFrom;
        this.validTo = definition.validTo;
    }

    public getId(): string {
        return this.id;
    }

    public getProduct(): ProductIdSchema {
        return this.product;
    }
    public getProductId(): ProductIdInterface {
        const { id, type, subType } = this.product;

        return {
            id,
            type: type as PRODUCT_TYPES,
            subType: subType as PRODUCT_SUBTYPES,
        };
    }

    public getValidFrom(): string | undefined {
        return this.validFrom;
    }

    public getValidTo(): string | undefined {
        return this.validTo;
    }
    public getStatus(): string {
        return '';
    }
    public getPartnerId(): string {
        return '';
    }
}
