import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { AddToFavoritesIn } from '@/interfaces/from-schemas/user-content/addToFavoritesIn';
import { AddToFavoritesOut } from '@/interfaces/from-schemas/user-content/addToFavoritesOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useAddToFavorites = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.USER_CONTENT,
        GET_MEDIA_METHODS.ADD_TO_FAVORITES,
        null,
    );

    return useGetMediaApiMutation<AddToFavoritesIn, AddToFavoritesOut>(keyHook, {
        appendAuthData: true,
        invalidate: [
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.GET_FAVORITES_WITH_FLAT_NAVIGATION],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_EVENT_TV_CHANNELS],
            [GET_MEDIA_NAMESPACES.NAVIGATION, GET_MEDIA_METHODS.FETCH_CHANNEL_LIST_FLAT_NAVIGATION],
        ],
    });
};

export default useAddToFavorites;
