import { getDefaultTheme } from '@/helpers/switch-theme.helper';
import { useAutoLoginRequest } from '@/hooks/user/use-auto-login.hook';
import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { THEME_OPTION, THEME_TYPE } from '@/types/theme.type';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AppContextProps, AppContextProviderProps } from './types';
import { getTimestampWithDiff } from '@/helpers/date.helper';

export const AppContext = createContext<AppContextProps>({
    theme: THEME_TYPE.DARK,
    setTheme: () => null,
    themeOption: THEME_OPTION.DEFAULT,
    setThemeOption: () => null,
    disabledQueries: [],
    setDisabledQueries: () => null,
    autoLogin: false,
    setAutoLogin: () => null,
    serverTimestamp: undefined,
});

const AppContextProvider = ({ children, serverTime }: AppContextProviderProps) => {
    const defaultTheme = getDefaultTheme();
    const [theme, setTheme] = useState(defaultTheme);
    const [themeOption, setThemeOption] = useState(THEME_OPTION.DEFAULT);
    const [disabledQueries, setDisabledQueries] = useState<GET_MEDIA_METHODS[]>([]);
    const { autoLogin, setAutoLogin } = useAutoLoginRequest();
    const [timeDiff, setTimeDiff] = useState<number>();
    const serverTimestamp = getTimestampWithDiff(timeDiff);

    useEffect(() => {
        const clientTime = new Date().getTime();
        setTimeDiff(serverTime ? serverTime - clientTime : undefined);
    }, [serverTime]);

    const value = useMemo(
        () => ({
            theme,
            setTheme,
            disabledQueries,
            setDisabledQueries,
            autoLogin,
            setAutoLogin,
            serverTimestamp,
            themeOption,
            setThemeOption,
        }),
        [
            theme,
            disabledQueries,
            autoLogin,
            setAutoLogin,
            serverTimestamp,
            themeOption,
            setThemeOption,
        ],
    );

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
