export const PROTOCOLS = {
    RELATIVE: '//',
    HTTP: 'http://',
    HTTPS: 'https://',
};

export function isHTTP(url: string): boolean {
    return new RegExp(`^${PROTOCOLS.HTTP}`, 'i').test(url);
}

export function rewriteProtocol(url: string, protocol: string): string {
    return url.replace(/^https?:\/\//i, protocol);
}

export function removeProtocol(url: string): string {
    return rewriteProtocol(url, '');
}

export function protectURL(url: string) {
    if (isHTTP(url)) {
        url = rewriteProtocol(url, PROTOCOLS.HTTPS);
    }
    return url;
}
