import {
    IApiResponse,
    GetMediaPromiseFunc,
    IApiServerResponse,
} from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetSessionOut } from '@/interfaces/from-schemas/auth/getSessionOut';
import { useIsUserLogged } from '@/hooks/user/use-is-user-logged.hook';

export const useGetSession = (enabled = true): IApiResponse<GetSessionOut> => {
    const isLogged = useIsUserLogged();
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.GET_SESSION,
        enabled && isLogged ? {} : null,
    );
    return useGetMediaApi(keyHook, {});
};

export const getSessionPromise = (
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<IApiServerResponse<GetSessionOut>> => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.GET_SESSION, {});

    return getMediaApiPromise(keyHook, {});
};
