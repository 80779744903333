import React, { useMemo } from 'react';
import { ImageContainer, PlaceholderContainer, PlaceholderWrapper } from './styles';
import { Placeholder } from '@/components/shared/Image';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { convertPxToRem } from '@/helpers/convert-px-to-rem.helper';

interface ListElementPlaceholderProps {
    imageDisplayMode?: IMAGE_DISPLAY_MODE;
}

export const ListElementPlaceholder = ({
    imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS,
}: ListElementPlaceholderProps) => {
    const placeholderSize = useMemo(
        () =>
            imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? process.env.imageSize.posters
                : process.env.imageSize.thumbnails,
        [imageDisplayMode],
    );
    const heightInRem = useMemo(() => convertPxToRem(placeholderSize.height), [placeholderSize]);
    const widthInRem = useMemo(() => convertPxToRem(placeholderSize.width), [placeholderSize]);

    return (
        <PlaceholderWrapper $width={widthInRem}>
            <ImageContainer $height={heightInRem} allowFocus={false}>
                <PlaceholderContainer>
                    <Placeholder isLoading />
                </PlaceholderContainer>
            </ImageContainer>
        </PlaceholderWrapper>
    );
};
