import React from 'react';
import { Switch, SwitchLabel, Input } from './styles';
import { SwitchCheckboxProps } from './types';

export const MemoSwitchCheckbox = ({ id, checked, onClick }: SwitchCheckboxProps) => {
    return (
        <Switch onClick={onClick} data-testing={`switch.${id}`}>
            <Input id={id} type="checkbox" checked={checked} readOnly />
            <SwitchLabel htmlFor={id} data-testing={`label.${id}`}></SwitchLabel>
        </Switch>
    );
};
export const SwitchCheckbox = React.memo(MemoSwitchCheckbox);
