import { navDynamic, NAVIGATION_KEYS, NavTree } from 'nav-tree';
import { NavHorizontalArrayGuidedConfig } from '../Player/controls/EPG/types';

export function navHorizontalArrayGuided<T>({
    items,
    focusIndex,
    onEntering,
    navId,
    getItemNavId,
}: NavHorizontalArrayGuidedConfig<T>) {
    return (key: NAVIGATION_KEYS, current: NavTree, previous?: NavTree) => {
        if (!previous) {
            return navDynamic(key, current);
        }
        if (onEntering && previous.parent?.id !== navId) {
            return onEntering();
        }
        if (key === NAVIGATION_KEYS.LEFT || key === NAVIGATION_KEYS.RIGHT) {
            const newFocusedItem = items[focusIndex + (key === NAVIGATION_KEYS.LEFT ? -1 : 1)];
            return newFocusedItem ? getItemNavId(newFocusedItem) : false;
        }
        return false;
    };
}
