import { PageContainer } from '@/components/shared/FullscreenNavContainer';
import { navVertical } from 'nav-tree';
import { LoadingWrapper } from '@/components/shared/Loader';
import React from 'react';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { NavWrapper } from '@/components/views/Account/styles';
import { PlayableButton } from '@/components/shared/Buttons';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { OfferContainer } from '@/components/views/Account/Code/styles';
import { GUTTER } from '@/types/gutter.type';
import useCheckCode from '@/api/payments/use-check-code';
import { ICONS } from '@/components/shared/Icon';
import { ICheckCodeProps, ICodeDetails } from '@/components/views/Account/Code/types';

export const CheckCode = ({ purchaseCode, goBack, activateCode }: ICheckCodeProps) => {
    const { data, isFetching, error } = useCheckCode({ purchaseCode });

    const renderCodeDetails = () => (
        <PageContainer>
            <MainText
                text={t('pages.check-code.title', { code: purchaseCode })}
                mode={MAINTEXT_KIND.PAGE_SUBTITLE}
            />
            {data && renderOffer(data)}
            {renderButtons()}
        </PageContainer>
    );

    const renderOffer = (offer: ICodeDetails) => {
        const minPriceOfferId = offer.minPriceOffers?.[0];
        const minPriceOffer =
            minPriceOfferId &&
            offer?.offers?.find(
                ({ id, type }) => id === minPriceOfferId.id && type === minPriceOfferId.type,
            );
        const accessText = minPriceOffer?.accessText;

        return (
            <OfferContainer key={offer.id}>
                <Message
                    description={t(`products.subtypes.${offer.subType}`)}
                    gutter={GUTTER.NONE}
                    style={{ textTransform: 'uppercase' }}
                />
                <MainText text={offer.name} mode={MAINTEXT_KIND.SUBTITLE} gutter={GUTTER.NONE} />
                <Message
                    description={accessText}
                    gutter={GUTTER.SMALL}
                    style={{ textTransform: 'uppercase' }}
                />
                <Message
                    description={offer.activationAvailability.statusUserMessage}
                    gutter={GUTTER.SMALL}
                    style={{ fontWeight: 'bold' }}
                />
            </OfferContainer>
        );
    };

    const renderButtons = () => {
        const backButtonTranslation =
            data?.status == 'active' ? 'cancel-button-label' : 'ok-button-label';
        return (
            <NavWrapper func={navVertical}>
                {data?.status == 'active' && (
                    <PlayableButton
                        text={t('pages.have-code.activate')}
                        onButtonAction={() => activateCode(purchaseCode)}
                        defaultFocused
                    />
                )}
                <PlayableButton
                    text={t(backButtonTranslation)}
                    onButtonAction={goBack}
                    defaultFocused={data?.status != 'active'}
                />
            </NavWrapper>
        );
    };

    const renderError = (title?: string) => (
        <PageContainer>
            <Message icon={ICONS.INFO_CIRCLE} title={title} />
            <NavWrapper func={navVertical}>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={goBack}
                    defaultFocused
                />
            </NavWrapper>
        </PageContainer>
    );

    return (
        <PageContainer func={navVertical}>
            <LoadingWrapper
                isFetching={isFetching}
                text={t('pages.have-code.verification')}
                calculateBottom={false}
            >
                {data && renderCodeDetails()}
                {error && !!error.getUserMessage() && renderError(error.getUserMessage())}
            </LoadingWrapper>
        </PageContainer>
    );
};

export default CheckCode;
