import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import { navHorizontal, navVertical } from 'nav-tree';
import React from 'react';
import { useRouter } from '@/routing';

const ChangeEmailSuccess = () => {
    const { email } = useRouter().query as { email: string };
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.ACCOUNT} />
            <PageContainer func={navVertical}>
                <Message
                    icon={ICONS.INFO_CIRCLE}
                    title={t('pages.account.change-email-success.header')}
                    description={t('pages.account.change-email-success.description', email)}
                    gutter={GUTTER.BIG}
                />
                <NavWrapper func={navHorizontal}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            Router.pushRoute(ROUTES.ACCOUNT);
                        }}
                        defaultFocused
                    />
                </NavWrapper>
                <Message
                    description={[
                        t('pages.account.change-email-success.footer-desc-1'),
                        t('pages.account.change-email-success.footer-desc-2'),
                    ]}
                />
            </PageContainer>
        </BaseLayout>
    );
};

export default ChangeEmailSuccess;
