import { PlayableButton } from '@/components/shared/Buttons';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { OptionLayout } from '@/components/views/Account/AccountOption/OptionLayout';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { EmailOptionProps } from './types';

const showChangeEmailButton = process.env.isChangeEmailAvailable;

export const EmailOption = ({ email }: EmailOptionProps) => {
    const buttonText = email ? t('button.account.email') : t('button.account.set-email');

    const buttonAction = () => {
        const route = email ? ROUTES.ACCOUNT_CHANGE_EMAIL : ROUTES.ADD_ACCOUNT_EMAIL;

        Router.pushRoute(route);
    };

    const content = (
        <React.Fragment>
            <MainText
                text={t('pages.account.option.header.email')}
                mode={MAINTEXT_KIND.PAGE_SUBTITLE}
                gutter={GUTTER.SMALL}
            />
            <MainText
                text={email}
                fullText
                mode={MAINTEXT_KIND.PAGE_DESCRIPTION}
                gutter={GUTTER.NONE}
            />
        </React.Fragment>
    );

    const button = showChangeEmailButton && (
        <PlayableButton
            text={buttonText}
            onButtonAction={buttonAction}
            transparentOnInactive
            gutter={GUTTER.NONE}
            data-testing="button.account.email"
            defaultFocused
        />
    );

    return <OptionLayout left={content} right={button} />;
};
