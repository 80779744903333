import { IScreenSize } from '@/stats/clients/activity-events/types';

export const getDeviceScreenSize = (): IScreenSize => {
    const height = window.screen.height;
    const width = window.screen.width;
    const diagonal = Math.round(Math.sqrt(height * height + width * width));

    return {
        diagonal,
        height,
        width,
    };
};
