import React, { useState } from 'react';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';
import t from '@/lib/i18n';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IVerifyPhoneNoFormProps } from '@/components/views/VerifyPhoneNumber/types';
import { EnterPhoneNumberForm, VerificationCodeForm } from '@/components/views/VerifyPhoneNumber';
import { useFadingState } from '@/hooks/use-fading-state.hook';
import { LoadingWrapper } from '@/components/shared/Loader';

const VerifyPhoneNoForm = ({
    pageTitle,
    handleVerifyPhoneNo,
    handleConfirmPhoneNo,
    handleSuccess,
    handleCancel,
    isLoading,
}: IVerifyPhoneNoFormProps) => {
    const [verificationNumber, setVerificationNumber] = useState<string>();
    const [infoMessage, setInfoMessage] = useFadingState<string>();

    const verifyPhoneNumber = async (phoneNo: string, newCode?: boolean) => {
        const result = await handleVerifyPhoneNo(phoneNo);
        if (newCode) {
            setInfoMessage(t('form.phone-verification.new-code-sent'));
        }
        if (result.ok) {
            setVerificationNumber(phoneNo);
        }
    };

    const confirmVerification = async (verificationCode: string) => {
        const result = await handleConfirmPhoneNo(verificationCode);
        if (result.ok) {
            await handleSuccess();
        }
    };

    const renderInfoMessage = (message: string) => {
        return <AlertMessage userMessage={message} type={MESSAGE_TYPE.INFO} />;
    };

    return (
        <LoadingWrapper
            isFetching={!!isLoading}
            calculateBottom={false}
            text={t('form.phone-verification.verification-in-progress')}
        >
            <MainText text={pageTitle} mode={MAINTEXT_KIND.SUBTITLE} />
            {!verificationNumber && (
                <EnterPhoneNumberForm
                    handlePhoneNumber={verifyPhoneNumber}
                    handleCancel={handleCancel}
                />
            )}
            {verificationNumber && (
                <VerificationCodeForm
                    phoneNo={verificationNumber}
                    sendNewCodeCallback={() => verifyPhoneNumber(verificationNumber, true)}
                    handleVerificationCode={confirmVerification}
                    changePhoneNumberCallback={() => setVerificationNumber(undefined)}
                />
            )}
            {infoMessage && renderInfoMessage(infoMessage)}
        </LoadingWrapper>
    );
};

export default VerifyPhoneNoForm;
