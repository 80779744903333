import React, { useContext, useRef, useMemo } from 'react';
import { Nav } from 'nav-tree';
import { useListWindow } from '@/hooks/use-list-window.hook';
import {
    ArrowContainer,
    VirtualScrollContainer,
    VirtualScrollHorizontalSliderContainer,
    VirtualScrollHorizontalSliderLoaderContainer,
} from './styles';
import { useVirtualScrollSlider } from './hooks';
import { VirtualScrollHorizontalSliderProps } from './types';
import { ProcessingLoader } from '../Loader';
import { Icon, ICONS } from '../Icon';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';

const InternalVitualScrollHorizontalSlider = <T,>({
    windowWidth,
    items,
    height,
    onNav,
    renderItem,
    itemToNavId,
    navId,
    isDefault,
}: Omit<VirtualScrollHorizontalSliderProps<T>, 'loading'>) => {
    const navRef = useRef<Nav>(null);
    const { sliderNavProps, focusIndex, setFocusIndex, onPrev, onNext } = useVirtualScrollSlider<T>(
        {
            isDefault,
            itemToNavId,
            items,
            navRef,
            sliderNavId: navId,
            onNav,
        },
    );

    const [itemsWindow, itemsWindowStart] = useListWindow({
        list: items,
        width: windowWidth,
        offset: focusIndex,
    });

    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const sliderItems = useMemo(() => {
        return itemsWindow.map((item, i) =>
            renderItem(
                item,
                {
                    onNav: (isActive) => {
                        if (isActive) {
                            setFocusIndex(itemsWindowStart + i);
                        }
                    },
                    key: itemToNavId(item),
                    navId: itemToNavId(item),
                },
                itemsWindowStart + i,
            ),
        );
    }, [itemsWindow, itemsWindowStart, itemToNavId, setFocusIndex, renderItem]);

    return (
        <VirtualScrollContainer>
            {isMagicMouseVisible && (
                <ArrowContainer onClick={onPrev}>
                    <Icon name={ICONS.ARROW_LEFT} />
                </ArrowContainer>
            )}

            <VirtualScrollHorizontalSliderContainer height={height} {...sliderNavProps}>
                {sliderItems}
            </VirtualScrollHorizontalSliderContainer>

            {isMagicMouseVisible && (
                <ArrowContainer right onClick={onNext}>
                    <Icon name={ICONS.ARROW_RIGHT} />
                </ArrowContainer>
            )}
        </VirtualScrollContainer>
    );
};

export const VirtualScrollHorizontalSlider = <T,>({
    loading,
    ...props
}: VirtualScrollHorizontalSliderProps<T>) => {
    if (loading) {
        return (
            <VirtualScrollHorizontalSliderLoaderContainer height={props.height}>
                <ProcessingLoader isFetching />
            </VirtualScrollHorizontalSliderLoaderContainer>
        );
    }

    if (!props.items.length) {
        return <VirtualScrollHorizontalSliderLoaderContainer height={props.height} />;
    }

    return <InternalVitualScrollHorizontalSlider<T> {...props} />;
};
