import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const PurchaseOptionRuleListNav = styled(Nav)`
    margin-left: 10rem;
    width: 100%;
`;

export const PurchaseOptionRuleHeader = styled.div`
    text-align: left;
    border-bottom: 0.1rem solid;
    border-color: ${({ theme }) => theme.text.color.metadata};
    margin-bottom: 5rem;
    padding-bottom: 2rem;
`;
