import {
    FULL_HEIGHT_CELL,
    HALF_HEIGHT_CELL,
    THREE_OR_MORE_CELLS_HEIGHT,
} from '@/constants/epg-table';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { CtxHours } from '@/types/epg-table.type';

const HALF_HOUR_OFFSET = 30;

const getBaseHeight = (cellsInCell: number): number => {
    if (cellsInCell >= 3) {
        return THREE_OR_MORE_CELLS_HEIGHT;
    }

    if (cellsInCell > 1) {
        return HALF_HEIGHT_CELL;
    }

    return FULL_HEIGHT_CELL;
};

const getHeightForFirstRow = (
    startedInPast: boolean,
    endTimestamp: number | undefined,
    moreThanThree: boolean,
    cellsInCell: number,
): number | null => {
    if (!startedInPast && endTimestamp) {
        return getBaseHeight(cellsInCell);
    }
    return null;
};

export const getCellHeight = (
    program: ChannelProgramTvModel,
    cellsInCell: number,
    ctxHours: CtxHours,
    isFirstRow?: boolean,
) => {
    const { fullHour, oneHourFromNow } = ctxHours;

    const startedInPast = program.getStartTimeTimestamp() < fullHour.getTime();
    const endTimestamp = program.getEndTimeTimestamp();

    const oneAndHalfHourFromNow = new Date(oneHourFromNow);
    oneAndHalfHourFromNow.setMinutes(oneHourFromNow.getMinutes() + HALF_HOUR_OFFSET);

    let height: number;

    if (isFirstRow) {
        const firstRowHeight = getHeightForFirstRow(
            startedInPast,
            endTimestamp,
            cellsInCell >= 3,
            cellsInCell,
        );

        height = firstRowHeight !== null ? firstRowHeight : getBaseHeight(cellsInCell);
    } else {
        height = getBaseHeight(cellsInCell);
    }

    return height;
};
