import { RefObject, useCallback, useContext } from 'react';
import { Nav } from 'nav-tree';
import { useRouter } from '@/routing';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useBackAction } from '../use-back-action';
import { useRightPanel } from '@/contexts/right-panel/right-panel.context';
import { CatalogContext } from '@/contexts/catalog/catalog.context';

export const useCatalogRightPanel = ({ focusRef }: { focusRef: RefObject<Nav> }) => {
    const { route, query } = useRouter();
    const { clearContext } = useContext(CatalogContext);
    const { mode, setMode, rightPanelOpen, setRightPanelOpen } = useRightPanel();

    const handler = () => {
        setRightPanelOpen(false);

        Router.pushRoute(route as ROUTES, query);
        focusRef?.current?.imperativeFocus([`1`, `1`]);
    };

    const handleSubcategoriesOpen = (open: boolean) => {
        setRightPanelOpen(open);
        setMode('seasons');
    };

    const handleSubcategoryAction = useCallback(() => {
        clearContext();
        focusRef?.current?.imperativeFocus([`1`, `1`]);
        setRightPanelOpen(false);
    }, []);

    const handleFiltersOpen = (open: boolean) => {
        setRightPanelOpen(open);
        setMode('filters');
    };

    useBackAction(handler, rightPanelOpen);

    return {
        filtersOpen: rightPanelOpen && mode === 'filters',
        handleFiltersOpen,
        showSubcategoriesMenu: rightPanelOpen && mode === 'seasons',
        handleSubcategoriesOpen,
        handleSubcategoryAction,
    };
};
