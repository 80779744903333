import { usePlayerInstance } from './use-player-instance';

export const usePlayerSkipRange = () => {
    const player = usePlayerInstance();

    let skipRange = { start: 0, end: player.getStatus().playback.duration };
    try {
        skipRange = player.getSkipRange();
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.warn(e);
        }
    }
    return skipRange && skipRange.end
        ? { start: Math.round(skipRange.start), end: Math.round(skipRange.end) }
        : { start: 0, end: player.getStatus().playback.duration - 1 };
};
