import { useEffect, useRef } from 'react';

export function useDebouncedEffect(
    effect: (() => void) | (() => () => void),
    { timeout = 5000, omitFirst = false }: { timeout?: number; omitFirst?: boolean },
    deps: any[],
) {
    const omitRef = useRef(omitFirst);

    useEffect(() => {
        if (omitRef.current) {
            omitRef.current = false;
            return;
        }
        let timeoutHandle: NodeJS.Timeout | null = setTimeout(() => {
            effect();
            timeoutHandle = null;
        }, timeout);

        return () => {
            if (timeoutHandle) {
                clearTimeout(timeoutHandle);
            }
        };
    }, [timeout, ...deps]);
}
