import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GetArticleListIn } from '@/interfaces/from-schemas/help/getArticleListIn';
import { ArticleModel } from '@/models/help/article.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

export const getArticleListPromise = (
    params: GetArticleListIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<ArticleModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.HELP,
        GET_MEDIA_METHODS.GET_ARTICLE_LIST,
        params,
    );

    return getMediaApiPromise(keyHook, { appendAuthData: true }).then((res) => res.data);
};

const useGetArticleList = (params: GetArticleListIn | false): IApiResponse<ArticleModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.HELP,
        GET_MEDIA_METHODS.GET_ARTICLE_LIST,
        params,
    );
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
    return { data, isFetching, error };
};

export default useGetArticleList;
