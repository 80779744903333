import { AsyncEvent } from 'ts-events';
import HandlersManager from '../../modules/handlers-manager';
import ActivityEventsClient from './activity-event-client';
import { ActivityEventsUpdateHandlerParams, EVENT } from '../../types';
import { ActivityEventsConfig } from './types';

export default class ActivityEventsWrapper {
    private client: ActivityEventsClient;

    constructor(
        private handlersManager: HandlersManager,
        config: ActivityEventsConfig,
        onParamsUpdate: AsyncEvent<ActivityEventsUpdateHandlerParams>,
    ) {
        this.client = new ActivityEventsClient(config, onParamsUpdate);
        this.setupHandlers();
    }

    private setupHandlers() {
        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_STARTED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_STARTED, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_PAUSED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_PAUSED, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_RESUMED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_RESUMED, params);
        });

        this.handlersManager.addListener(EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED, (params) => {
            return this.client.send(EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED, params);
        });
    }
}
