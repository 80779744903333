import { CatalogView, RecommendationView } from '@/components/views/common';
import { useCombinedView } from '@/components/views/common/use-combined-view.hook';
import React from 'react';
import { CombinedViewOwnProps } from './types';

export const CombinedView = ({
    collectionsConfig,
    catalogConfig,
    collectionsParams,
    hasParams = true,
    hideHeader = false,
}: CombinedViewOwnProps) => {
    const { isCatalog, updatedCatalogConfig, onCategoryView, handleNoLists } = useCombinedView(
        collectionsConfig,
        catalogConfig,
    );

    if (!isCatalog) {
        return (
            <RecommendationView
                config={collectionsConfig}
                params={collectionsParams}
                onCategoryView={onCategoryView}
                onNoLists={handleNoLists}
                withCatalog
            />
        );
    }

    if (hasParams) {
        return (
            <CatalogView
                config={updatedCatalogConfig}
                onCategoryView={onCategoryView}
                hideHeader={hideHeader}
            />
        );
    }

    return null;
};
