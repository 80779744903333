import { createContext, useCallback, useMemo, useState, useContext } from 'react';
import { IncrementContext } from '../increment/increment.context';
import { PacketContextProps, PacketContextProviderProps } from './types';

export const PacketContext = createContext<PacketContextProps>({
    activeRow: null,
    setActiveRow: () => null,
    collectionActiveIndex: 0,
    setCollectionActiveIndex: () => null,
    emptyListsArr: [],
    setEmptyListsArr: () => null,
    clearContext: () => null,
});

// TODO: Wstrzyknąć RecoContext po implementacji zapamiętywania focusa podczas BACK
const PacketContextProvider = ({ children }: PacketContextProviderProps) => {
    const incrementContext = useContext(IncrementContext);

    const [activeRow, setActiveRow] = useState<number | null>(null);
    const [collectionActiveIndex, setCollectionActiveIndex] = useState<number>(0);
    const [emptyListsArr, setEmptyListsArr] = useState<number[]>([]);

    const clearContext = useCallback(() => {
        incrementContext.clearContext();

        setActiveRow(null);
        setCollectionActiveIndex(0);
        setEmptyListsArr([]);
    }, []);

    return useMemo(
        () => (
            <PacketContext.Provider
                value={{
                    activeRow,
                    setActiveRow,
                    collectionActiveIndex,
                    setCollectionActiveIndex,
                    emptyListsArr,
                    setEmptyListsArr,
                    clearContext,
                }}
            >
                {children}
            </PacketContext.Provider>
        ),
        [activeRow, collectionActiveIndex, emptyListsArr],
    );
};

export default PacketContextProvider;
