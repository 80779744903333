import React, { useState } from 'react';
import t from '@/lib/i18n';
import {
    PageContainer,
    MessageContainer,
    CardIconWrapper,
    Title,
    ButtonWrapper,
    CardContainer,
    CardsWrapper,
    CardTextWrapper,
} from './styles';
import { Icon, ICONS } from '@/components/shared/Icon';
import { navVertical, navHorizontal } from 'nav-tree';
import { PlayableButton } from '@/components/shared//Buttons';
import { AddPaymentCardMessage } from './AddPaymnetCardMassage';
import { ChoosePaymentCardsProps } from './types';
import { PaymentCardModel } from '@/models/payments/payment-card.model';

export const ChoosePaymentCards = ({
    cards,
    chosenCard,
    handleCancel,
}: ChoosePaymentCardsProps) => {
    const [activeCardWrapper, setActiveCardWrapper] = useState<boolean>(false);
    const [activeCardId, setActiveCardId] = useState<number>(0);
    const [cardMessage, setCardMessage] = useState<boolean>(false);

    const handleAddCard = () => {
        setCardMessage((cardMessage) => !cardMessage);
    };

    const handleActiveCard = (isActive: boolean, index: number) => {
        if (isActive) {
            setActiveCardId(index);
        }
    };

    const isCardActive = (id: number) => activeCardWrapper && activeCardId === id;

    return !cardMessage ? (
        <PageContainer func={navVertical}>
            <MessageContainer>
                <Title>{t('components.purchases.choose-card')}</Title>
            </MessageContainer>
            <CardsWrapper
                func={navHorizontal}
                onNav={(isActive: boolean) => setActiveCardWrapper(isActive)}
            >
                {cards &&
                    cards.map((card: PaymentCardModel, index: number) => {
                        return (
                            <CardContainer
                                onNav={(isActive: boolean) => handleActiveCard(isActive, index)}
                                key={index}
                                onEnterDown={() => chosenCard(card)}
                                onClick={() => chosenCard(card)}
                            >
                                <CardIconWrapper active={isCardActive(index)}>
                                    <Icon name={ICONS.CARD}></Icon>
                                </CardIconWrapper>
                                <CardTextWrapper>
                                    <div>{card.getMaskedNumber()}</div>
                                    <div>{card.getExpirationText()}</div>
                                </CardTextWrapper>
                            </CardContainer>
                        );
                    })}
            </CardsWrapper>
            <ButtonWrapper>
                <PlayableButton
                    text={t('button.account.add-payments-card')}
                    onButtonAction={() => handleAddCard()}
                    defaultFocused
                />
            </ButtonWrapper>
            <ButtonWrapper>
                <PlayableButton text={t('cancel-button-label')} onButtonAction={handleCancel} />
            </ButtonWrapper>
        </PageContainer>
    ) : (
        <AddPaymentCardMessage onButtonAction={handleAddCard} />
    );
};
