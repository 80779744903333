import styled from 'styled-components';
import { MainText } from '../MainText';

export const WelcomeFullscreenContainer = styled.div<{ backgroundSrc?: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-image: linear-gradient(0deg, rgba(7, 7, 8, 1) 13%, rgba(7, 7, 8, 0) 73%),
        ${(props) => `url(${props.backgroundSrc})` || 'none'};
    background-repeat: no-repeat;
    background-position: center center;
`;

export const WelcomeInfoSection = styled.div`
    margin: auto 16rem 4rem 16rem;
    display: flex;
    flex-direction: row;
`;

export const WelcomeBottomSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const WelcomeTextSection = styled.div`
    justify-self: flex-start;
    flex: 1;
`;

export const WelcomeActionsSection = styled.div`
    display: flex;
    align-items: center;
    justify-self: flex-end;
    margin-left: 24rem;
`;

const WelcomeTextBase = styled(MainText)`
    margin-left: auto;
    margin-right: auto;
`;

export const WelcomeHeaderText = styled(WelcomeTextBase)`
    width: ${({ theme }) => theme.welcomeBody.headerWidth};
`;

export const WelcomeNoAccountText = styled(WelcomeTextBase)`
    width: 80rem;
`;
