import { Message } from '@/components/shared/Message';
import { ICONS } from '@/components/shared/Icon';
import t from '@/lib/i18n';
import { NavWrapper } from '@/components/views/Account/styles';
import { PlayableButton } from '@/components/shared/Buttons';
import React from 'react';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { TextInput } from '@/components/shared/FormElement/BaseElements';
import { INPUT_TYPE } from '@/components/shared/FormElement/BaseElements/TextInput/types';
import { IOptionsPageProps } from '@/components/views/Account/PlusZone/types';

const OptionsPage = ({ phoneNo }: IOptionsPageProps) => {
    return (
        <>
            <Message
                icon={ICONS.PLUS}
                title={t('pages.plus-zone.title')}
                description={t('pages.plus-zone.options.description')}
            />
            <NavWrapper>
                <TextInput
                    name="phoneNumber"
                    icon={ICONS.PHONE}
                    disabled
                    value={phoneNo}
                    type={INPUT_TYPE.TEXT}
                />
                <PlayableButton
                    icon={ICONS.PLUS}
                    onButtonAction={() => Router.pushRoute(ROUTES.PLUS_ZONE_CHANGE_PHONE)}
                    text={t('pages.plus-zone.options.change')}
                    defaultFocused
                />
                <PlayableButton
                    icon={ICONS.PLUS}
                    onButtonAction={() => Router.pushRoute(ROUTES.PLUS_ZONE_REMOVE_PHONE)}
                    text={t('pages.plus-zone.options.remove')}
                />
            </NavWrapper>
        </>
    );
};

export default OptionsPage;
