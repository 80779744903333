import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE } from 'nav-tree';
import { CatalogContext } from '@/contexts/catalog/catalog.context';
import { useGridVerticalNavigation } from '@/hooks/nav-tree/use-grid-vertical-navigation.hook';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { buildFocusArray } from '@/helpers/nav-tree/build-focus-array.helper';
import { GridElements } from './GridElements';
import { GridRoot } from './styles';
import { GridProps } from './types';
import { useRouter } from '@/routing';

const GridRaw = ({
    data,
    col,
    lockNav,
    defaultFocused,
    onActiveElement = () => null,
    onFetchMoreElements,
    focusRef,
    isSearch,
    isChronological,
    rowCount = 2,
}: GridProps) => {
    const { asPath } = useRouter();
    const { grid, setGrid } = useContext(CatalogContext);
    const { activeIndex, focusMap } = grid;

    const func = useGridVerticalNavigation(data.length, col, activeIndex);

    const handleActiveItem = useCallback(
        (item: ListElementInterface, activeIndex: number) => {
            onActiveElement(item);

            const colIndex = activeIndex % col;
            const rowIndex = Math.floor(activeIndex / col);

            const focusArr = buildFocusArray(activeIndex, col);
            const newMap = Object.assign(focusMap, { [asPath]: focusArr });

            setGrid((prev) => ({ ...prev, activeIndex, colIndex, rowIndex, focusMap: newMap }));
        },
        [col, asPath, focusMap],
    );

    useEffect(() => {
        if (activeIndex) {
            const shouldFetchMore = activeIndex >= data.length - col * 2;
            shouldFetchMore && onFetchMoreElements?.();
        }
    }, [activeIndex, data, col]);

    const gridElements = useMemo(
        () => (
            <GridElements
                data={data}
                onActiveElement={handleActiveItem}
                cols={col}
                defaultFocusedList={defaultFocused}
                isSearch={isSearch}
                rowCount={rowCount}
                isChronological={isChronological}
            />
        ),
        [data, handleActiveItem, col, defaultFocused, isSearch, isChronological],
    );

    return useMemo(
        () => (
            <GridRoot
                ref={focusRef}
                scrollOptions={{
                    isScrollable: true,
                    direction: NAV_SCROLL_DIRECTION.VERTICAL,
                    mode: NAV_SCROLL_MODE.START,
                }}
                func={func}
                lockNavInside={lockNav}
                defaultFocused={defaultFocused}
                restoreLastFocus
                $hide={lockNav}
            >
                {gridElements}
            </GridRoot>
        ),
        [data, lockNav, func, gridElements],
    );
};

export const Grid = React.memo(GridRaw);
