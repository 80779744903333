const portal = process.env.portal;
const target = process.env.TARGET;

export const isWeb = process.env.VARIANT?.toLowerCase() === 'ssr';
export const isStandalone = process.env.VARIANT?.toLowerCase() === 'standalone';

export const isPBG = portal?.toLowerCase() === 'pbg';
export const isNetiaGO = portal?.toLowerCase() === 'netiago2';
export const isEleven = portal?.toLowerCase() === 'eleven';

export const isWebPBG = isPBG && isWeb;
export const isWebEleven = isEleven && isWeb;
export const isStandalonePBG = isPBG && isStandalone;

export const isWebNetiaGO = isNetiaGO && isWeb;
export const isStandaloneNetiaGO = isNetiaGO && isStandalone;

export const isStbNetiaGO = target?.toLowerCase() === 'netia-go-stb';
export const isStbPBG = target?.toLowerCase() === 'polsat-box-go-stb';
export const isStbTargets = isStbNetiaGO || isStbPBG;
