import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { RegisterIn } from '@/interfaces/from-schemas/auth/registerIn';
import { RegisterOut } from '@/interfaces/from-schemas/auth/registerOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useRegister = () => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.REGISTER, null);

    return useGetMediaApiMutation<RegisterIn, RegisterOut>(keyHook, {
        appendAuthData: false,
    });
};

export default useRegister;
