import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { ParentControlPasswordForm } from '@/components/views/Profile/ParentControlPasswordForm';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';
import { useProfilePageInitialProps } from '@/helpers/router/use-profile-page-initial-props';

const ParentControlPassword = () => {
    const { profileId } = useProfilePageInitialProps();
    const body = (
        <>
            <Message
                title={t('pages.profile.parent-control.header')}
                description={t('pages.profile.parent-control.description-password')}
                gutter={GUTTER.BIG}
            />
            <ParentControlPasswordForm profileId={profileId} />
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default ParentControlPassword;
