import getArticleList from '@/api/help/use-get-article-list';
import { useRouter } from '@/routing';
import { ProcessingLoader } from '../../Loader';
import { HelpItem } from '../HelpItem/HelpItem';
import { HelpItemsProps } from './types';
import { SubCategoryTitle } from '../SubCategoryTitle/SubCategoryTitle';

export const HelpItems = ({
    id,
    onMore,
    firstElement,
    icon,
    withSubcategory,
    subCategoryTitle,
}: HelpItemsProps) => {
    const { query } = useRouter();

    const { data: articles, isFetching } = getArticleList({
        categoryId: id,
        contextType: query.contextType,
        contextValue: query.contextValue,
    });

    if (isFetching) {
        return <ProcessingLoader isFetching={true} />;
    }

    return (
        <>
            {articles.length > 0 && subCategoryTitle && (
                <SubCategoryTitle title={subCategoryTitle} icon />
            )}
            {articles.map((el, index) => {
                return (
                    <HelpItem
                        key={index}
                        id={el.id}
                        defaultFocused={firstElement && index === 0}
                        onMore={onMore}
                        title={el.title}
                        icon={icon}
                        withSubcategory={withSubcategory}
                    />
                );
            })}
        </>
    );
};
