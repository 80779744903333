import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { ROUTES } from '@/routing/types';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { EVENT, EventParams, ACTIVITY_EVENTS_ACCOUNT_TYPES } from '@/stats';
import {
    IActivityEventsUserLogged,
    IListTypeParamsHelper,
    STATS_LIST_TYPES,
} from '@/types/stats.type';

export const prepareParamsUserLoggedActivityEvents = (
    params: IActivityEventsUserLogged,
    authProvider?: AUTH_PROVIDERS | undefined,
): EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED> => {
    let hitParams: EventParams<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED> = {
        account: {
            provider: getMappedAuthProvider(authProvider),
        },
    };

    if (params.ok) {
        const accounts = params.data.user.accounts;

        if (accounts?.facebook?.fbId) {
            hitParams = {
                account: {
                    ...hitParams.account,
                    fbId: accounts.facebook.fbId,
                },
            };
        }

        if (accounts?.icok?.userIdCP) {
            hitParams = {
                account: {
                    ...hitParams.account,
                    userIdCP: accounts.icok.userIdCP,
                },
            };
        }

        if (accounts?.native?.login) {
            hitParams = {
                account: {
                    ...hitParams.account,
                    login: accounts.native.login,
                },
            };
        }

        if (accounts?.plus?.msisdn) {
            hitParams = {
                account: {
                    ...hitParams.account,
                    msisdn: accounts?.plus?.msisdn,
                },
            };
        }
    } else {
        hitParams = {
            ...hitParams,
            errorCode: params.error.getCode(),
        };
    }

    return hitParams;
};

const getMappedAuthProvider = (providerToMap: AUTH_PROVIDERS | undefined) => {
    switch (providerToMap) {
        case AUTH_PROVIDERS.FACEBOOK:
            return ACTIVITY_EVENTS_ACCOUNT_TYPES.FACEBOOK;
        case AUTH_PROVIDERS.IPOLSATBOX:
            return ACTIVITY_EVENTS_ACCOUNT_TYPES.ICOK;
        case AUTH_PROVIDERS.PLUS:
            return ACTIVITY_EVENTS_ACCOUNT_TYPES.PLUS;
        default:
            return ACTIVITY_EVENTS_ACCOUNT_TYPES.NATIVE;
    }
};

export const getListTypeActivityEvents = (params: IListTypeParamsHelper): STATS_LIST_TYPES => {
    if (params?.listModel instanceof StaffRecommendationListModel) {
        return STATS_LIST_TYPES.COLLECTION_FACTORY;
    }

    if (params?.routerPath == ROUTES.SEARCH) {
        return STATS_LIST_TYPES.SEARCH_RESULTS;
    }

    return STATS_LIST_TYPES.CONTENT;
};

export const gemiusHit = () => {
    const gemiusAudienceId = process.env.analytics.gemiusAudienceId;
    if (!gemiusAudienceId) return;

    window.gemius_hit(gemiusAudienceId);
};
