import { useEffect, useMemo, useState } from 'react';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { filterPrograms, groupPrograms } from '@/helpers/epg-table/group-programs.helper';
import { useHourRangeContext } from '@/contexts/epg-table/hour-range.context';
import { COLUMNS_PER_PAGE } from '@/constants/epg-table';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { GroupedPrograms } from '@/types/epg-table.type';

interface BuildColumnDataProps {
    colIndex: number;
    epgPrograms: ChannelProgramTvModel[];
    id: string;
    isFetching?: boolean;
}

export const useBuildColumnData = ({ colIndex, epgPrograms, isFetching }: BuildColumnDataProps) => {
    const [programs, setPrograms] = useState<GroupedPrograms>();

    const { date, hourRange, twoHoursFromNow, fullHour, oneHourFromNow } = useHourRangeContext();

    const { setLoadedAllPages } = useIncrementColumnContext();

    const filtered = useMemo(() => filterPrograms(epgPrograms, hourRange, twoHoursFromNow, date), [
        epgPrograms,
        hourRange,
        twoHoursFromNow,
        date,
    ]);

    useEffect(() => {
        if (isFetching) return;
        const groupedPrograms = groupPrograms(filtered, fullHour, oneHourFromNow, twoHoursFromNow);

        if (groupedPrograms.length > 0) {
            setPrograms(groupedPrograms);
        } else {
            setPrograms([]);
        }

        if (colIndex === COLUMNS_PER_PAGE - 1) {
            setLoadedAllPages(true);
        }
    }, [
        isFetching,
        filtered,
        fullHour,
        oneHourFromNow,
        twoHoursFromNow,
        colIndex,
        setLoadedAllPages,
    ]);

    return {
        programs,
    };
};
