import useRegister from '@/api/auth/register';
import useLogin from '@/api/auth/use-login';
import * as Yup from 'yup';
import { yupResolver } from '@/lib/yupResolver';
import { useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import { IPolsatBoxAuthData } from '@/types/user-auth-data.type';
import { useLoginUser } from './use-login-user.hook';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { useState } from 'react';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { useAllowSubmit } from '@/hooks/form/use-allow-submit.hook';
import { useRouter } from '@/routing';

export interface RegisterProps {
    login: string;
    password: string;
}

const schema = Yup.object().shape({
    login: Yup.string().required(t('form.validate.required')),
    password: Yup.string()
        .required(t('form.validate.required'))
        .min(6, t('pages.register.short-password')),
});

export const useIPolsatBoxLogin = () => {
    const authProvider = AUTH_PROVIDERS.IPOLSATBOX;
    const { query } = useRouter();
    const [captcha, setCaptcha] = useState<string | null>(null);
    const [login, loginData] = useLogin(captcha);
    const { status: loginStatus, error: loginError, isFetching: isFetchingLogin } = loginData;
    const [registerUser, registerData] = useRegister();
    const { status: registerStatus, isFetching: isFetchingRegister } = registerData;

    const handleLoginUser = useLoginUser({ login, register: registerUser, authProvider, query });

    const { register, handleSubmit, errors } = useForm<RegisterProps>({
        resolver: yupResolver(schema),
    });
    const captchaRequired =
        loginError?.getCode() === ERROR_CODES_GET_MEDIA.B2C_CAPTCHA_VALUE_REQUIRED;
    const isCaptchaSolved = captcha && captcha.length > 0;
    const allowSubmit = useAllowSubmit(
        isFetchingLogin || isFetchingRegister || (captchaRequired && !isCaptchaSolved),
    );

    const onSubmit = handleSubmit(async ({ login, password }) => {
        if (!allowSubmit) return;

        const ipolsatboxParams: IPolsatBoxAuthData = { authProvider, login, password };
        handleLoginUser(ipolsatboxParams);

        if (captchaRequired) {
            window.grecaptcha.reset();
            setCaptcha(null);
        }
    });

    return {
        onSubmit,
        register,
        setCaptcha,
        isFetching: isFetchingLogin || isFetchingRegister,
        loginError,
        formErrors: errors,
        isSuccess: loginStatus === 'success' && registerStatus === 'success',
        captchaRequired,
    };
};
