import { PlayableButton } from '@/components/shared/Buttons';
import { BackButtonProps } from './types';
import { ICONS } from '@/components/shared/Icon';
import { Router } from '@/routing';
import { NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';
import React, { useCallback } from 'react';
import { BackButtonWrapper } from './styles';

const BackButtonRaw = ({ defaultFocused, onButtonAction, route }: BackButtonProps) => {
    const onBack = () => {
        if (onButtonAction) {
            onButtonAction();
        } else {
            route ? Router.pushRoute(route) : Router.back();
        }
    };

    const func = useCallback((navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
        if (navKeys === NAVIGATION_KEYS.RIGHT && navTree.parent) {
            return navVertical(NAVIGATION_KEYS.DOWN, navTree);
        }
        return navVertical(navKeys, navTree);
    }, []);

    return (
        <BackButtonWrapper func={func}>
            <PlayableButton
                icon={ICONS.ARROW_LEFT}
                onButtonAction={onBack}
                defaultFocused={defaultFocused}
            />
        </BackButtonWrapper>
    );
};

export const BackButton = React.memo(BackButtonRaw);
