import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { NavScrollDataInterface, navVertical, NAV_SCROLL_DIRECTION } from 'nav-tree';
import { ConsentsListItem } from './ConsentsListItem';
import { ConsentsListNav } from './styles';
import { Rule, RuleWithStatus } from './types';

export const ConsentsList = ({
    onFilterScroll,
    rulesWithStatus,
    onToggle,
    onMore,
}: {
    rulesWithStatus?: RuleWithStatus[];
    onFilterScroll: (data: NavScrollDataInterface) => void;
    onToggle?: (rule: RuleWithStatus) => Promise<void>;
    onMore: (rule: Rule) => void;
}) => {
    return (
        <ConsentsListNav
            func={navVertical}
            scrollOptions={{
                isScrollable: true,
                direction: NAV_SCROLL_DIRECTION.VERTICAL,
            }}
            onNavScroll={onFilterScroll}
        >
            <Message title={t('pages.account.consents.my-consents')} gutter={GUTTER.BIG} />
            {rulesWithStatus?.map((rule, index) => (
                <ConsentsListItem
                    onMore={onMore}
                    key={rule.id}
                    rule={rule}
                    onToggle={onToggle}
                    defaultFocused={index === 0}
                />
            ))}
        </ConsentsListNav>
    );
};
