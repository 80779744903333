import { Nav } from 'nav-tree';
import styled, { css } from 'styled-components';

import { PlayableButton } from '@/components/shared/Buttons';

export const ArrowsWrapper = styled(Nav)<{ position: string }>`
    display: flex;
    justify-content: space-between;
    width: 12.5rem;
    position: relative;
    right: 5rem;

    ${({ position }) =>
        position !== 'top' &&
        css`
            bottom: -2.8rem;
            margin-left: auto;
        `}
`;

export const TopButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 1rem;
`;

export const TopButtons = styled.div`
    display: flex;

    div:not(:last-child) {
        margin-right: 0.7rem;
    }
`;

export const FilterButton = styled(PlayableButton)`
    border-color: transparent;
    & > .playable-button-content {
        padding-left: 1.8rem;
        padding-right: 1.8rem;
    }
`;
