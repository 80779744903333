import React from 'react';

import { AdditionalSigns } from '@/components/shared/AdditionalSigns/AdditionalSigns';
import { Icon, ICONS } from '@/components/shared/Icon';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import { IconGroup, Metadata, METADATA_TYPES, VerticalLine } from '@/components/shared/Metadata';
import ProgressBar from '@/components/shared/ProgressBar';
import { RatingIconWrapper } from '@/components/shared/RatingIconWrapper/styles';
import { getIconName } from '@/helpers/get-icon-name.helper';
import { ADDITIONAL_SIGNS_TYPES } from '@/types/additional-signs.type';
import { GUTTER } from '@/types/gutter.type';
import { PRODUCT_SUBTYPES } from '@/types/media.type';

import {
    LicenseAccessDurationWrapper,
    MetadataContainer,
    MetaDataIconsWrapper,
    MetadataWrapper,
    ProgressBarContainer,
    ProgressBarFmcWrapper,
    ProgressContainer,
} from './styles';
import { FutureEPGFullDetailsProps } from './types';

const FULL_DETAILS_TITLE_LINES = process.env.mediaDetails.fullDetailsTitleLines;
const META_DATA_ICON_SIZE = 2.9;

export const FutureEPGFullDetailsContent = ({
    media,
    cnc,
    fmc,
    isMainCategory,
}: FutureEPGFullDetailsProps) => {
    const hasAgeGroup = media.ageGroup > 0;
    const showProgressBar = (fmc && isMainCategory) || fmc?.mediaId.id === media.id;
    const underageClassification = media.underageClassification;
    const accessibilityFeatures = media.accessibilityFeatures;
    const metadata = [media.duration, ...(showProgressBar ? [media.followMeContent(fmc)] : [])];

    return (
        <>
            <Metadata data={media.subTitleData} type={METADATA_TYPES.PRIMARY} isSafeHTML />
            <MainText
                text={cnc?.getTitle() || media.title}
                mode={MAINTEXT_KIND.TITLE}
                lines={FULL_DETAILS_TITLE_LINES}
                gutter={GUTTER.SMALL}
            />

            {cnc?.getAdditionalContent() && (
                <MainText
                    text={cnc.getAdditionalContent()}
                    mode={MAINTEXT_KIND.METADATA_SEASON_EPISODE_TITLE}
                    gutter={GUTTER.SMALL}
                />
            )}

            <MainText
                text={cnc?.getDescription() || media.description}
                mode={MAINTEXT_KIND.DESCRIPTION}
                lines={media.fullDescriptionLines}
            />

            {media.subType !== PRODUCT_SUBTYPES.MOVIE && (
                <Metadata data={metadata} type={METADATA_TYPES.SECONDARY} />
            )}

            {showProgressBar && (
                <ProgressBarFmcWrapper>
                    <ProgressBar progress={fmc!.lastPercent} />
                </ProgressBarFmcWrapper>
            )}

            {media.progress !== null && (
                <ProgressContainer>
                    <Metadata data={[media.minutesLeftInfo]} type={METADATA_TYPES.SECONDARY} />
                    <ProgressBarContainer>
                        <ProgressBar progress={media.progress} />
                    </ProgressBarContainer>
                </ProgressContainer>
            )}

            <MetadataContainer>
                <MetaDataIconsWrapper>
                    {hasAgeGroup && (
                        <RatingIconWrapper ageGroup={media.ageGroup} $height={META_DATA_ICON_SIZE}>
                            <Icon name={getIconName(media.ageGroup)} />
                        </RatingIconWrapper>
                    )}

                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.UNDERAGE_CLASSIFICATION}
                        signs={underageClassification}
                        $iconSize={META_DATA_ICON_SIZE}
                    />

                    <AdditionalSigns
                        type={ADDITIONAL_SIGNS_TYPES.ACCESSIBILITY_FEATURES}
                        signs={accessibilityFeatures}
                        $iconSize={META_DATA_ICON_SIZE}
                    />

                    {media.platformsIcons.map((icon: ICONS, index: number) => {
                        const isStbIcon = icon === ICONS.STB;
                        const iconSize = isStbIcon ? 1.8 : META_DATA_ICON_SIZE;

                        return <IconGroup key={index} icon={icon} iconSize={iconSize} noMargin />;
                    })}
                </MetaDataIconsWrapper>

                <LicenseAccessDurationWrapper>
                    <Metadata data={media.licenseLocationInfo} type={METADATA_TYPES.TERTIARY} />

                    {media.showVerticalLine && <VerticalLine />}

                    <MetadataWrapper>
                        <Metadata
                            data={media.accessMetadata}
                            type={METADATA_TYPES.TERTIARY}
                            isSafeHTML
                        />
                    </MetadataWrapper>
                </LicenseAccessDurationWrapper>
            </MetadataContainer>
        </>
    );
};
