import t from '@/lib/i18n';
import React from 'react';
import { Message } from '@/components/shared/Message';
import { GUTTER } from '@/types/gutter.type';
import { MyPurchasesSlider } from './MyPurchasesSlider';
import { StyledMyPurchasesCollectionsList, StyledMyPurchasesMessage } from './styles';
import { MyPurchasesContentProps } from './types';

const MyPurchasesContentRaw = ({ slidersList }: MyPurchasesContentProps) => {
    const title =
        t('pages.account.my-purchases.header1') +
        `<span>${t('domain-url')}</span>` +
        t('pages.account.my-purchases.header2');

    return (
        <>
            <StyledMyPurchasesMessage>
                <Message innerHtmlTitle={title} gutter={GUTTER.NONE} />
            </StyledMyPurchasesMessage>
            <StyledMyPurchasesCollectionsList>
                {slidersList.map((el, index) => (
                    <MyPurchasesSlider
                        defaultFocused={index === 0}
                        sliderElements={el.purchases}
                        title={el.title}
                        key={el.title}
                    />
                ))}
            </StyledMyPurchasesCollectionsList>
        </>
    );
};

export const MyPurchasesContent = React.memo(MyPurchasesContentRaw);
