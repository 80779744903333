import { ListElementInterface } from '@/interfaces/list-element.interface';
import { StaffRecommendationListModel } from '@/models/recommendations/staff-recommendation-list.model';
import { useCallback, useMemo, useState } from 'react';
import { getVisibleElements } from './helpers';
import { SliderElementsIncreaseInterface } from './types';

export const useDynamicMountSliderElements = ({
    collectionElements,
    collectionActiveIndexCtx,
    list,
}: {
    collectionElements: ListElementInterface[];
    collectionActiveIndexCtx: number;
    list?: StaffRecommendationListModel;
}) => {
    const visibleElements = useMemo(() => getVisibleElements(list), []);
    const initSliceCount = useMemo(() => {
        const ready = collectionActiveIndexCtx > Math.floor(visibleElements / 2);
        return {
            sliceCount: ready ? collectionActiveIndexCtx + (visibleElements - 1) : visibleElements,
            trigger: ready ? collectionActiveIndexCtx : 0,
        };
    }, [collectionActiveIndexCtx]);

    const [
        { sliceCount, trigger },
        setSliderElementsIncrease,
    ] = useState<SliderElementsIncreaseInterface>(initSliceCount);

    const totalLength = collectionElements.length;

    const shouldRenderMoreElements = useCallback(
        (collectionActiveIndex: number) => {
            if (sliceCount && sliceCount < totalLength) {
                const shouldRenderSliderElement =
                    collectionActiveIndex >= sliceCount - Math.round(visibleElements / 2) &&
                    collectionActiveIndex > trigger;

                if (shouldRenderSliderElement) {
                    setSliderElementsIncrease((prevState) => ({
                        sliceCount:
                            prevState.sliceCount && prevState.sliceCount + (visibleElements - 1),
                        trigger: collectionActiveIndex,
                    }));
                }
            }
        },
        [totalLength, sliceCount, trigger, visibleElements],
    );

    const loadNextSlicePage = useCallback(
        (focusedIndex: number) => {
            const visible = visibleElements - 2;

            if (sliceCount && sliceCount < totalLength) {
                const shouldLoad = focusedIndex >= sliceCount - visible;

                if (shouldLoad) {
                    setSliderElementsIncrease((prevState) => ({
                        sliceCount: prevState.sliceCount && prevState.sliceCount + visible,
                        trigger: prevState.trigger,
                    }));
                }
            }
        },
        [totalLength, sliceCount, visibleElements],
    );

    const slicedSliderElements = useMemo(() => {
        return collectionElements.slice(0, sliceCount);
    }, [sliceCount, collectionElements]);

    return {
        slicedCollectionElements: slicedSliderElements,
        shouldRenderMoreElements,
        loadNextSlicePage,
    };
};
