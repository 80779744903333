import {
    WatchedContentDataSchema,
    MediaIdSchema,
} from '../../interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';

class LatelyWatchedContentDataModel {
    readonly lastDuration: number = 0;
    readonly lastPercent: number = 0;
    readonly lastWatchTime: string = '';
    readonly mediaId: MediaIdSchema = {
        cpid: 0,
        id: '',
    };
    readonly seenDuration: number = 0;
    readonly seenPercent: number = 0;

    constructor(definition: WatchedContentDataSchema) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });
        }
    }

    public getLastDuration(): number {
        return this.lastDuration;
    }

    public getLastPercent(): number {
        return this.lastPercent;
    }

    public getLastWatchTime(): string {
        return this.lastWatchTime;
    }

    public getMediaId(): MediaIdSchema {
        return this.mediaId;
    }

    public getSeenDuration(): number {
        return this.seenDuration;
    }

    public getSeenPercent(): number {
        return this.seenPercent;
    }
}

export default LatelyWatchedContentDataModel;
