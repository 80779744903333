import { GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { isServer } from '@/helpers/environment.helper';
import { IKeyHook } from '@/hooks/use-get-media-api.type';
import {
    GetConfigurationOut,
    ServiceMethodVersionConfigSchema,
} from '@/interfaces/from-schemas/system/getConfigurationOut';
import config from 'target/config';

export const sortParams = <T extends Record<string, unknown>>(
    params: Record<string, unknown> = {},
): T => {
    return Object.keys(params)
        .sort()
        .reduce((obj: any, key) => {
            obj[key] = params[key];
            return obj;
        }, {});
};

export const parseVersionedNamespace = (
    namespace: GET_MEDIA_NAMESPACES,
): [GET_MEDIA_NAMESPACES, string] => {
    const [unversionedNamespace, version] = namespace.split('/');
    return [unversionedNamespace as GET_MEDIA_NAMESPACES, version || '1.0'];
};

export const buildUrlManually = (namespace: string): string => {
    const getMediaRpcUrl = isServer
        ? process.env.GM_SERVER_RPC_URL || config.getMediaServerRpcUrl
        : process.env.GM_RPC_URL || config.getMediaRpcUrl;
    return `${getMediaRpcUrl}/${namespace}/`;
};

export const getServiceMethod = (
    keyhook: IKeyHook,
    configuration?: GetConfigurationOut,
): ServiceMethodVersionConfigSchema | undefined => {
    const { method, namespace } = keyhook;
    const [unversionedNamespace, version] = parseVersionedNamespace(namespace);
    return configuration?.services[unversionedNamespace]?.[method]?.[version];
};
