import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import CategoryContentWithFlatNavigationModel from '@/models/category/category-content-with-flat-navigation.model';
import { GetCategoryContentWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getCategoryContentWithFlatNavigationIn';
import defaultKeyHook from '@/hooks/default-key';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CATEGORY_CONTENT_WITH_FLAT_NAVIGATION;

const getCategoryContentWithFlatNavigationPromise = (
    params: GetCategoryContentWithFlatNavigationIn,
    getMediaApiPromise: GetMediaPromiseFunc,
) => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getCategoryContentWithFlatNavigationPromise };

const useGetCategoryContentWithFlatNavigation = (
    params: GetCategoryContentWithFlatNavigationIn | false,
): IApiResponse<CategoryContentWithFlatNavigationModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new CategoryContentWithFlatNavigationModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetCategoryContentWithFlatNavigation;
