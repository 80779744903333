import t from '@/lib/i18n';
import React from 'react';
import { SingleRule } from './SingleRule';
import { RulesContainer } from './styles';
import { RulesProps } from './types';

export const Rules = ({ rules, defaultFocused, onToggleRule, onToggleAllRules }: RulesProps) => {
    const isRulesExists = rules.length > 0;
    const isAllRulesSelected = isRulesExists && !rules.some(({ checked }) => !checked);

    return (
        <RulesContainer>
            {isRulesExists && (
                <>
                    <SingleRule
                        id={0}
                        label={t('payment-steps.check-all')}
                        checked={isAllRulesSelected}
                        onToggleRule={() => onToggleAllRules(isAllRulesSelected)}
                        defaultFocused={defaultFocused}
                        highlight
                    />

                    {rules.map(({ id, label, checked, error }) => {
                        return (
                            <SingleRule
                                key={id}
                                id={id}
                                label={label}
                                checked={checked}
                                error={error}
                                onToggleRule={onToggleRule}
                            />
                        );
                    })}
                </>
            )}
        </RulesContainer>
    );
};
