import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Search } from '@/components/views/search';
import React, { useMemo } from 'react';

const SearchPage = () => {
    const { placeType, minQueryLength, resultsTimeout } = process.env.pages.search;

    const listParams = {
        place: {
            type: placeType,
            value: '',
        },
    };

    const collectionConfig = useMemo(
        () => ({
            singleCollection: true,
            defaultFocused: false,
        }),
        [],
    );

    return (
        <BaseLayout defaultBg>
            <Search
                config={collectionConfig}
                params={listParams}
                minQueryLength={minQueryLength}
                resultsTimeout={resultsTimeout}
            />
        </BaseLayout>
    );
};

export default SearchPage;
