import { KEYS } from './keys';

export function buildUpcKeysList(): { [key in KEYS]?: number } {
    return {
        BACKSPACE: 27,
        BACK: 27,
        PLAY_PAUSE: 179,
        FORWARD: 228,
        REWIND: 227,
    };
}
