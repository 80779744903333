import { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useEventListeners } from './use-event-listeners.hook';
import { SLIDER_EVENTS, ResizeEventInterface } from './types';
import { isServer } from '@/helpers/environment.helper';

export const useResizeEvent = ({
    onResizeEvent = () => null,
    withResizeEvents = true,
}: ResizeEventInterface) => {
    const RESIZE_EVENT_LIST = useMemo(
        () => [{ name: SLIDER_EVENTS.RESIZE, method: debounce(onResizeEvent, 100) }],
        [],
    );

    const ref = useMemo(() => (!isServer ? window : undefined), [isServer]);

    useEventListeners({
        ref,
        EVENT_LIST: RESIZE_EVENT_LIST,
        enabled: withResizeEvents,
    });
};
