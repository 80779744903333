import { Nav } from 'nav-tree';
import styled from 'styled-components';

export const PaymentMethodContainer = styled(Nav)`
    padding-top: 9rem;
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ButtonContainer = styled.div`
    width: 40rem;
`;

export const AnnotationsContainer = styled.div`
    position: absolute;
    color: ${({ theme }) => theme.paymentTransactionStep.descriptionColor};
    word-wrap: break-word;
    font-size: 1.5rem;
    bottom: 2rem;
    width: 109rem;
`;
