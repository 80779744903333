import { useCallback, useMemo } from 'react';
import useGetAllRules from '@/api/auth/use-get-all-rules';
import useUnacceptRules from '@/api/auth/use-unaccept-rules';
import useAcceptRules from '@/api/auth/use-accept-rules';
import { RuleWithStatus } from '@/components/views/Account/Consents/types';
import { useGetRulesStatusQuery } from '@/api/auth/use-get-rules-status';

export const RULES_PRIO: Record<string, number> = {
    rodo: 1,
    newsletter: 2,
    registration: 3,
};

export const useAccountConsents = () => {
    const { data: allRulesData } = useGetAllRules({ context: 'my_rules_logged' });

    const [
        unacceptRules,
        { error: unacceptRulesError, isFetching: isFetchingUncceptedRules },
    ] = useUnacceptRules();
    const [
        acceptRules,
        { error: acceptRulesError, isFetching: isFetchingAcceptedRules },
    ] = useAcceptRules();

    const optionalRules = useMemo(
        () =>
            allRulesData
                ?.filter(({ required, type }) => !required)
                .sort((a, b) => RULES_PRIO[a.type] - RULES_PRIO[b.type]),
        [allRulesData],
    );

    const { data: statusData, isFetching: isFetchingRules } = useGetRulesStatusQuery({
        rulesIds: optionalRules?.map(({ id }) => id),
    });

    const isFetching = isFetchingUncceptedRules || isFetchingAcceptedRules || !!isFetchingRules;

    const handleToggleRule = useCallback(
        async (rule: RuleWithStatus) => {
            if (isFetching) return;

            if (rule.status === 'accepted') {
                await unacceptRules({ rulesIds: [rule.id] });
            } else {
                await acceptRules({ rulesIds: [rule.id] });
            }
        },
        [isFetching, unacceptRules, acceptRules],
    );

    const sortedOptionalRulesWithStatus = useMemo<RuleWithStatus[] | undefined>(() => {
        const sortedRules =
            optionalRules &&
            statusData &&
            optionalRules.map((rule) => ({
                ...rule,
                status:
                    statusData.find(({ rulesId }) => rulesId === rule.id)?.status || 'unaccepted',
            }));

        return sortedRules;
    }, [optionalRules, statusData]);

    const error = unacceptRulesError || acceptRulesError;

    return {
        sortedOptionalRulesWithStatus,
        isFetching,
        error,
        handleToggleRule,
    };
};
