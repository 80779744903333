import { DeviceIdInterface } from '@/interfaces/device-id-interface';
import { UserAgentData } from '@/types/uad.type';
import { AsyncEvent } from 'ts-events';
import {
    ActivityEventsAccountParams,
    ActivityEventsContentItemParams,
    ActivityEventsListParams,
    ActivityEventsPlaceParams,
    IDeviceExtraData,
} from './clients/activity-events';

export interface ActivityEventsRequiredHitParams {
    errorCode?: number;
}

export type ActivityEventsAppStartedParams = ActivityEventsRequiredHitParams;

export type ActivityEventsAppUserNavigatedParams = ActivityEventsRequiredHitParams & {
    place: ActivityEventsPlaceParams;
};

export type ActivityEventsAppUserLoggedParams = ActivityEventsRequiredHitParams & {
    account: ActivityEventsAccountParams;
};

export type ActivityEventsAppUserLoggedOutParams = ActivityEventsRequiredHitParams;

export type ActivityEventsAppResumedParams = ActivityEventsRequiredHitParams;

export type ActivityEventsAppPausedParams = ActivityEventsRequiredHitParams;

export type ActivityEventsAppUserItemClickedParams = ActivityEventsRequiredHitParams & {
    place: ActivityEventsPlaceParams;
    contentItem: ActivityEventsContentItemParams;
    list: ActivityEventsListParams;
};

export enum EVENT {
    // https://cyfropedia.polsatc/display/PPI/ActivityEvents+-+eventy+dla+aplikacji+klienckich
    ACTIVITY_EVENTS_APP_USER_NAVIGATED = 'AppUserNavigated',
    ACTIVITY_EVENTS_APP_USER_LOGGED = 'AppUserLogged',
    ACTIVITY_EVENTS_APP_USER_LOGGED_OUT = 'AppUserLoggedOut',
    ACTIVITY_EVENTS_APP_STARTED = 'AppStarted',
    ACTIVITY_EVENTS_APP_PAUSED = 'AppPaused',
    ACTIVITY_EVENTS_APP_RESUMED = 'AppResumed',
    ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED = 'AppUserItemClicked',
}
export enum GTM_EVENTS {
    PLUS_LOGIN = 'logowanie-plus',
    LOGIN = 'logowanie',
}
export enum ACTIVITY_EVENTS_ACCOUNT_TYPES {
    NATIVE = 'NATIVE',
    FACEBOOK = 'FACEBOOK',
    PLUS = 'PLUS',
    ICOK = 'ICOK',
}

export type StatsConfig = {
    appVersion: string;

    platform: PLATFORM | string;

    deviceType: DEVICE_TYPE;

    osVersion?: string;

    model: string;

    ua: string;

    appSessionId: string;

    userId: string;

    portal: string;

    activityevents?: {
        service: string;
        jwt: string;
        originator: string;
        clientId: string;
        profileId: string;
        appVersion: string;
        portalId: string;
        userAgentData: UserAgentData;
        deviceExtraData?: IDeviceExtraData;
        deviceId: DeviceIdInterface;
        ipData?: IIpData;
        [key: string]: unknown;
    };
};

/** Typ zmieniający określone klucze na wymagane **/
type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & Required<Pick<T, K>>;

export type ActivityEventsConfig = RequiredKeys<StatsConfig, 'activityevents'>;

export interface ActivityEventsUpdateHandlerParams {
    jwt?: string;
    profileId?: string;
}

export enum DEVICE_TYPE {
    TV = 'tv',
    STB = 'stb',
    PHONE = 'phone',
    TABLET = 'tablet',
    PC = 'PC',
    UNKNOWN = 'UNKNOWN',
}

export function eventTypeCheck(event: EVENT) {
    return !!(Object.keys(EVENT) as Array<keyof typeof EVENT>).find((key) => {
        return EVENT[key] === event;
    });
}

export function statsConfigCheck(obj: StatsConfig): boolean {
    return typeof obj.appVersion !== 'undefined';
}

export interface IIpData {
    ip: string;
    country?: string;
    continent?: string;
    isp?: string;
    isVpn?: boolean;
    isEu?: boolean;
}

export enum PLATFORM {
    ANDROID = 'Android',
    IOS = 'iOS',
    MEEGO = 'MeeGo',
    PHILIPS = 'Philips',
    PS3 = 'PS3',
    CP = 'CP',
    NETIA = 'Netia',
    SYMBIAN = 'Symbian',
    LG = 'LG',
    PANASONIC = 'Panasonic',
    SAMSUNG = 'Samsung',
    SONY = 'Sony',
    WINDOWS = 'win',
    WINDOWS_PHONE = 'win_phone',
    WWW = 'WWW',
}

/**
 * Parametry wspólne dla wszystkich eventów
 */
type CommonEventParams = {
    category?: string;
};

/**
 * Typ generyczny zwracający typ parametrów potrzebnych dla każdego eventu
 */
export type EventParams<E extends EVENT> = (E extends EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT
    ? ActivityEventsAppUserLoggedOutParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_STARTED
    ? ActivityEventsAppStartedParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED
    ? ActivityEventsAppUserLoggedParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED
    ? ActivityEventsAppUserNavigatedParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_PAUSED
    ? ActivityEventsAppPausedParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_RESUMED
    ? ActivityEventsAppResumedParams
    : E extends EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED
    ? ActivityEventsAppUserItemClickedParams
    : {}) &
    CommonEventParams;

export type EventsWithoutParams = FilterNoParamsEvents<EVENT>;

type FilterNoParamsEvents<A extends EVENT> = A extends unknown
    ? Record<string, never> extends EventParams<A>
        ? A
        : never
    : never;

/**
 * Typ pomocniczy. Przedstawia iterał który zawiera pole odpowiadającej każdej wartosci w enumie EVENT
 * Wartością każdego z tych pól jest funkcja która:
 *  - przyjmuje argument `params` o typie EventParams<E>
 *  - jeżeli wszystkie pola w EventParams<E> są opcjanalne pozwala na pominięcie argumentu `params`
 *  - zwraca Promise<void>
 */
export type AliasMethod<E extends EVENT> = {} extends EventParams<E>
    ? (params?: EventParams<E>) => Promise<void>
    : (params: EventParams<E>) => Promise<void>;

type EventsAliases = {
    [E in EVENT]: AliasMethod<E>;
};

export interface StatsAPI extends EventsAliases {
    onParamsUpdate: AsyncEvent<ActivityEventsUpdateHandlerParams>;

    // Generyczna funkcja do informowania moduł że coś się dzieje w aplikacji
    dispatch(event: EventsWithoutParams): Promise<void>;
    dispatch<E extends EVENT>(event: E, params: EventParams<E>): Promise<void>;
}
