const pattern = /^(\d{2})\s*(\d{3})\s*(\d{3})\s*(\d{3})$/i;

export function buildFormattedMsisdn(msisdn?: string) {
    if (!msisdn) {
        return '';
    }

    const matched = msisdn.trim().match(pattern);

    if (!matched) return msisdn;

    const [, prefix, g1, g2, g3] = matched;
    return `+${prefix} ${g1} ${g2} ${g3}`;
}

export const sanitizePhoneNo = (phone: string) => phone.trim().replace(/\+|[^\d]/g, '');

export const isPhoneNumber = (value: string) => {
    if (value.includes('@')) return false;

    return (
        !!sanitizePhoneNo(value).match(pattern) || !!sanitizePhoneNo(`48${value}`).match(pattern)
    );
};

export const buildMsisdnForLogin = (value: string) => {
    const phoneNo = sanitizePhoneNo(value);
    const appendPrefix = phoneNo.length === 9;

    const msisdn = appendPrefix ? `48${phoneNo}` : phoneNo;

    return msisdn;
};
