export interface IFacebookDeviceLoginStatusOut {
    access_token: string;
    data_access_expiration_time: number;
    expires_in: number;
    error?: IFacebookDeviceLoginStatusError['error'];
}

export interface IFacebookDeviceLoginStatusError {
    error: {
        message: string;
        type: string;
        code: number;
        error_subcode: number;
        is_transient: boolean;
        error_user_title: string;
        error_user_msg: string;
        fbtrace_id: string;
    };
}

export enum DeviceStatusErrorSubcode {
    NOT_AUTHORIZED_YET = 1349174,
    POLLING_TOO_FREQUENTLY = 1349172,
    TOKEN_EXPIRED = 1349152,
}

export interface IFacebookDeviceLoginOut {
    code: string;
    expires_in: number;
    interval: number;
    user_code: string;
    verification_uri?: string;
}
