import { useMemo } from 'react';
import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, IUseGetMediaApiOptions } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { GetPaymentCardsOut } from '@/interfaces/from-schemas/payments/getPaymentCardsOut';
import { PaymentCardModel } from '@/models/payments/payment-card.model';

export const useGetPaymentCards = (
    isAlwaysRefetchOnMount = false,
): IApiResponse<PaymentCardModel[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.GET_PAYMENT_CARDS,
        {},
    );

    const options: IUseGetMediaApiOptions = { appendAuthData: true };

    if (isAlwaysRefetchOnMount) {
        options.queryOptions = { refetchOnMount: 'always' };
    }

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);
    const memoizedData = useMemo(
        () => data?.map((el: GetPaymentCardsOut[number]) => new PaymentCardModel(el)) || [],
        [data],
    );
    return {
        data: memoizedData,
        error,
        isFetching,
    };
};
