import { navTable, navVertical } from 'nav-tree';
import React from 'react';
import { AVATAR_SIZE } from '@/components/shared/Avatar';
import { IconGridElement } from '@/components/views/Profile/IconGridElement';
import { IconGridElementInterface } from '@/interfaces/icon-grid-element-interface';
import { AddProfileCircle } from '../AddProfileCircle';
import { IconGridElementWrapper, IconGridRootContainer } from './styles';
import { IconGridProps } from './types';
import { calculateContainerAndColumnWidth } from './helpers';

export const IconGrid = ({
    data,
    col,
    onSelectAction,
    size = AVATAR_SIZE.BIG,
    isAccountPage = false,
    showAddProfileCircle = false,
    selectProfileById,
}: IconGridProps) => {
    const { containerWidth, columnWidth } = calculateContainerAndColumnWidth({
        col,
        itemsLength: data.length,
        showAddProfileCircle,
        size,
    });

    return (
        <IconGridRootContainer
            width={isAccountPage ? undefined : containerWidth}
            func={navTable({ cols: col })}
            $withoutMargin={isAccountPage}
            restoreLastFocus
        >
            {data.map((itemData: IconGridElementInterface, index: number) => {
                const firstList = index === 0;

                return (
                    <IconGridElementWrapper key={index} width={columnWidth}>
                        <IconGridElement
                            text={itemData.name}
                            url={itemData.iconUrl}
                            size={size}
                            defaultFocused={firstList}
                            func={navVertical}
                            onSelectAction={() => {
                                onSelectAction?.(itemData.iconId, itemData.iconUrl);
                            }}
                            onSelectProfile={
                                selectProfileById &&
                                (() => {
                                    selectProfileById?.(itemData.iconId);
                                })
                            }
                            data-testing={'profile_' + index}
                        />
                    </IconGridElementWrapper>
                );
            })}
            {showAddProfileCircle && (
                <IconGridElementWrapper width={columnWidth} func={navVertical} key={data.length}>
                    <AddProfileCircle />
                </IconGridElementWrapper>
            )}
        </IconGridRootContainer>
    );
};
