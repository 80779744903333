import styled from 'styled-components';
import { MESSAGE_TYPE } from '.';
import { AlertMessageStyledProps } from './types';
import { Z_INDEX } from '@/targets/default/theme';
import { size } from '@/targets/default/mediaQuery';
function retunBackground(props: AlertMessageStyledProps): string {
    switch (props.type) {
        case MESSAGE_TYPE.ERROR:
            return props.theme.alertMessage.bgColorError;

        case MESSAGE_TYPE.INFO:
            return props.theme.alertMessage.bgColorInfo;

        default:
            return 'transparent';
    }
}

export const MessageRoot = styled.div<AlertMessageStyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.alertMessage.color};
    background: ${(props) => retunBackground(props)};
    font-size: 2.6rem;
    font-weight: 500;
    height: 7.8rem;
    padding: 0 5rem;
    text-align: center;
    position: fixed;
    z-index: ${Z_INDEX.ALERT_MESSAGE};
    bottom: 0;
    left: 0;
    right: 0;

    ${({ responsive }) =>
        responsive &&
        `
        font-size: 1.4rem;
        height: 5.6rem;

        @media (max-width: ${size.mobileM - 1}px) {
            font-size: 1.2rem;
            height: 3.1rem;
            padding: 0;
        }
        `}
`;
