import { InputManager } from '@/input';
import { useEffect, useRef } from 'react';

export const useBackAction = (handler: () => void, enable = true) => {
    const savedHandler = useRef<typeof handler>();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        if (!enable) {
            return;
        }

        const listener = (event: KeyboardEvent) => {
            if (event.keyCode === InputManager.KEY.BACK) {
                event.preventDefault();
                event.stopImmediatePropagation();
                savedHandler.current?.();
            }
        };

        document.addEventListener('keydown', listener);

        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [enable]);
};
