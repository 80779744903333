import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { useContext, useState } from 'react';
import { Circle, FilterMenuItem } from '../styles';
import { Nav } from 'nav-tree';
import React from 'react';
import { ICONS, Icon } from '../../Icon';
import { DayPickerSideMenuItemProps } from './types';

const DayPickerSideMenuItemRaw = ({ filter, onAction, selected }: DayPickerSideMenuItemProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);

    const [active, setActive] = useState(false);

    return (
        <Nav
            defaultFocused={selected}
            onNav={setActive}
            onEnterDown={onAction}
            onClick={onAction}
            autofocusOn={isMagicMouseVisible ? ['mouseenter', 'focus'] : ['focus']}
        >
            <FilterMenuItem active={active}>
                {filter.label}
                <Circle selected={selected}>{selected && <Icon name={ICONS.CHECK_MARK} />}</Circle>
            </FilterMenuItem>
        </Nav>
    );
};

export const DayPickerSideMenuItem = React.memo(DayPickerSideMenuItemRaw);
