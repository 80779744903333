import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { SearchContentWithTreeNavigationIn } from '@/interfaces/from-schemas/navigation/searchContentWithTreeNavigationIn';
import { FilterWithTreeNavigationSchema } from '@/interfaces/from-schemas/navigation/searchContentWithTreeNavigationOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

const useGetPacketTreeNavigation = (
    params: SearchContentWithTreeNavigationIn | false,
): IApiResponse<FilterWithTreeNavigationSchema[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.PACKET_TREE_NAVIGATION,
        params,
    );
    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, isFetching, error } = useGetMediaApi(keyHook, options);

    return {
        data: data?.filters?.map((filter: FilterWithTreeNavigationSchema) => filter),
        isFetching,
        error,
    };
};

export default useGetPacketTreeNavigation;
