import React, { useEffect, useMemo } from 'react';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import { LiveCollection } from './LiveCollection';
import { LiveCollectionsOwnProps } from './types';
import { ProcessingLoader } from '@/components/shared/Loader';
import { GUTTER } from '@/types/gutter.type';
import { useGetLiveFilterList } from '@/api/live/use-get-event-channel-flat-navigation';
import { useError } from '@/contexts/error/error.context';

const LiveCollections = ({
    isFetchingLists,
    collectionsListLength,
    onActiveItem,
    onLoadedData,
    loadedRowIndex,
}: LiveCollectionsOwnProps) => {
    const { setError } = useError();
    const livesParams = !isFetchingLists && {};
    const { data: filters, isFetching, error } = useGetLiveFilterList(livesParams);

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    return useMemo(
        () => (
            <>
                <ProcessingLoader gutter={GUTTER.BIG} isFetching={Boolean(isFetching)} />
                {filters?.map((filter, rowIndex) => {
                    const liveRowIndex = rowIndex + collectionsListLength;

                    return (
                        loadedRowIndex >= liveRowIndex && (
                            <LiveCollection
                                key={filter.name}
                                rowIndex={liveRowIndex}
                                filter={filter}
                                onLoadedData={onLoadedData}
                                onActiveItem={onActiveItem(LIST_TYPE.SIMPLE)}
                            />
                        )
                    );
                })}
            </>
        ),
        [filters, collectionsListLength, isFetching, loadedRowIndex, onLoadedData, onActiveItem],
    );
};

export const LiveCollectionsMemo = React.memo(LiveCollections);
