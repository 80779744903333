import { useCallback } from 'react';
import { NAVIGATION_KEYS, NavTree, navVertical } from 'nav-tree';

export const useGridVerticalNavigation = (
    dataLength: number,
    col: number,
    collectionActiveIndex: number,
) => {
    const row = Math.ceil(dataLength / col);
    const rest = dataLength % col;
    const focusArr = [`${row}`, `${rest}`];

    const verticalLock =
        collectionActiveIndex + col >= dataLength && collectionActiveIndex < dataLength - rest;

    const func = useCallback(
        (navKeys: NAVIGATION_KEYS, navTree: NavTree) => {
            if (navKeys === NAVIGATION_KEYS.DOWN && verticalLock && navTree.parent) {
                navTree.focus(focusArr);
            }
            return navVertical(navKeys, navTree);
        },
        [verticalLock, focusArr],
    );

    return func;
};
