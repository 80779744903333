import { AVATAR_SIZE } from '@/components/shared/Avatar';
import { AgeRestrictionGridElement } from '@/components/views/Profile/AgeRestrictionGridElement';
import { roundToDigits } from '@/helpers/round-to-digits.helper';
import { navTable } from 'nav-tree';
import React from 'react';
import { ageRestrictions } from './grid-data';
import { AgeRestrictionGridElementWrapper, AgeRestrictionGridRootContainer } from './styles';
import { AgeRestrictionGridElementInterface, AgeRestrictionGridProps } from './types';

export const AgeRestrictionGrid = ({ onSelectAction }: AgeRestrictionGridProps) => {
    const iconSize = AVATAR_SIZE.BIG;
    const numCol = ageRestrictions.length;
    const iconWidth = iconSize * 10 + 70;
    const containerSize = (iconWidth * numCol) / 10;
    const calculateCol = roundToDigits(100 / numCol, 2);

    return (
        <AgeRestrictionGridRootContainer width={containerSize} func={navTable({ cols: numCol })}>
            {ageRestrictions.map((itemData: AgeRestrictionGridElementInterface, index: number) => {
                const firstList = index === 0;

                return (
                    <AgeRestrictionGridElementWrapper key={index} width={calculateCol}>
                        <AgeRestrictionGridElement
                            text={itemData.name}
                            icon={itemData.icon}
                            size={iconSize}
                            defaultFocused={firstList}
                            onSelectAction={() => {
                                if (onSelectAction) {
                                    onSelectAction(itemData.id);
                                }
                            }}
                        />
                    </AgeRestrictionGridElementWrapper>
                );
            })}
        </AgeRestrictionGridRootContainer>
    );
};
