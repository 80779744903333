import { useState } from 'react';
import { isTouchDevice } from '@/helpers/is-touch-device.helper';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { useResizeEvent } from './events/use-resize-event.hook';
import { isServer } from '@/helpers/environment.helper';

export const getScrollSliderConfig = (
    windowWidth: number,
    imageDisplayMode?: IMAGE_DISPLAY_MODE | null,
) => {
    const lazyLoadingImages = process.env.lazyLoadingImages;
    const { visibleElements, initialLimit, params } = process.env.slider;

    if (params) {
        const index = params.sizes.findIndex((size) => windowWidth >= size);
        const visibleElements =
            imageDisplayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? params.visibleElementsPosters[index]
                : params.visibleElements[index];

        return {
            visibleElements,
            initialLimit,
            lazyLoading: lazyLoadingImages.enabled,
        };
    }

    return {
        visibleElements: visibleElements || initialLimit / 2,
        initialLimit,
        lazyLoading: lazyLoadingImages.enabled,
    };
};

export const useGetScrollSliderConfig = (imageDisplayMode?: IMAGE_DISPLAY_MODE | null) => {
    const [windowWidth, setWindowWidth] = useState<number>(() =>
        isServer ? Infinity : window?.innerWidth,
    );
    const touchableDevice = isTouchDevice();

    useResizeEvent({
        onResizeEvent: () => setWindowWidth(window?.innerWidth),
    });

    const config = getScrollSliderConfig(windowWidth, imageDisplayMode);

    return {
        ...config,
        imageDisplayMode: imageDisplayMode || IMAGE_DISPLAY_MODE.THUMBNAILS,
        touchableDevice,
    };
};
