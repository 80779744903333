import { StrippedInParams } from '@/hooks/use-get-media-api.type';
import { BuyIn } from '@/interfaces/from-schemas/payments/buyIn';
import { GetOptionDataIn } from '@/interfaces/from-schemas/payments/getOptionDataIn';
import { OptionIdWithPriceInterface } from '@/interfaces/product-id-interface';
import { OptionDataModel } from '@/models/payments/option-data.model';
import { PaymentCardModel } from '@/models/payments/payment-card.model';
import { PrePurchaseOfferModel } from '@/models/payments/pre-purchase-offer.model';
import { PrePurchaseOptionModel } from '@/models/payments/pre-purchase-option.model';
import { PrePurchaseProductModel } from '@/models/payments/pre-purchase-product.model';
import { OfferOptionModel } from '@/models/products/offer-option.model';
import { ProductOfferModel } from '@/models/products/product-offer.model';
import { ProductModel } from '@/models/products/product.model';
import { getCpwalletContractId } from './cpwallet.helper';

function buildOptionIdWithPrice(
    option: PrePurchaseOptionModel | OfferOptionModel,
): OptionIdWithPriceInterface | undefined {
    const minPrice = option.getMinPrice();

    if (!minPrice) {
        return undefined;
    }

    return {
        id: option.getId(),
        type: option.getType(),
        price: {
            currency: minPrice.currency,
            value: minPrice.value,
        },
    };
}

export function buildOptionDataParams(
    product: PrePurchaseProductModel | ProductModel,
    offer: PrePurchaseOfferModel | ProductOfferModel,
    option: PrePurchaseOptionModel | OfferOptionModel,
    orderId: string,
    returnUrl?: string,
    savePaymentCard?: boolean,
): StrippedInParams<GetOptionDataIn> | undefined {
    const productId = product.getProductId();
    const offerId = offer.getOfferId();
    const optionId = buildOptionIdWithPrice(option);

    if (!optionId) {
        return undefined;
    }
    if (savePaymentCard) {
        return {
            product: productId,
            offer: offerId,
            option: {
                ...optionId,
                savePaymentCard,
            },
            orderId,
            returnUrl,
        };
    }
    return {
        product: productId,
        offer: offerId,
        option: optionId,
        orderId,
        returnUrl,
    };
}

export function buildBuyParams(
    product: PrePurchaseProductModel | ProductModel,
    offer: PrePurchaseOfferModel | ProductOfferModel,
    option: PrePurchaseOptionModel | OfferOptionModel,
    orderId: string,
): StrippedInParams<BuyIn> | undefined {
    const productId = product.getProductId();
    const offerId = offer.getOfferId();
    const optionId = buildOptionIdWithPrice(option);

    if (!optionId) {
        return undefined;
    }

    return {
        product: productId,
        offer: offerId,
        option: optionId,
        orderId,
    };
}

export function buildBlikBuyParams(
    product: PrePurchaseProductModel | ProductModel,
    offer: PrePurchaseOfferModel | ProductOfferModel,
    option: PrePurchaseOptionModel | OfferOptionModel,
    orderId: string,
    blikCode?: string,
): StrippedInParams<BuyIn> | undefined {
    const productId = product.getProductId();
    const offerId = offer.getOfferId();
    const optionId = buildOptionIdWithPrice(option);

    if (!optionId || !blikCode) {
        return undefined;
    }

    return {
        product: productId,
        offer: offerId,
        option: {
            ...optionId,
            blikCode,
        },
        orderId,
    };
}

export function buildCpwalletBuyParams(
    product: PrePurchaseProductModel | ProductModel,
    offer: PrePurchaseOfferModel | ProductOfferModel,
    option: PrePurchaseOptionModel | OfferOptionModel,
    orderId: string,
    optionData: OptionDataModel,
): StrippedInParams<BuyIn> | undefined {
    const productId = product.getProductId();
    const offerId = offer.getOfferId();
    const optionId = buildOptionIdWithPrice(option);
    const contractId = getCpwalletContractId(optionData);

    if (!optionId || !contractId) {
        return undefined;
    }

    return {
        product: productId,
        offer: offerId,
        option: {
            ...optionId,
            cpWallet: { contractId },
        },
        orderId,
    };
}

export function buildPaymentCardBuyParams(
    product: PrePurchaseProductModel | ProductModel,
    offer: PrePurchaseOfferModel | ProductOfferModel,
    option: PrePurchaseOptionModel | OfferOptionModel,
    orderId: string,
    paymentCard: PaymentCardModel,
): StrippedInParams<BuyIn> | undefined {
    const productId = product.getProductId();
    const offerId = offer.getOfferId();
    const optionId = buildOptionIdWithPrice(option);

    if (!optionId) {
        return undefined;
    }

    return {
        product: productId,
        offer: offerId,
        option: {
            ...optionId,
            paymentCard: {
                operator: paymentCard.getOperator(),
                id: paymentCard.getId(),
            },
        },
        orderId,
    };
}
