import { useTeravolt } from '@/hooks/teravolt/use-teravolt';
import { createContext, useEffect, useState } from 'react';
import { PlayerOverlaysContextInterface, PlayerOverlaysContextProviderProps } from './types';

export const PlayerOverlaysContext = createContext<PlayerOverlaysContextInterface>({
    autoStartTeravolt: false,
    setAutoStartTeravolt: () => null,
    isTeravoltEnabled: false,
    setIsTeravoltEnabled: () => null,
});

export const PlayerOverlaysContextProvider = ({ children }: PlayerOverlaysContextProviderProps) => {
    const [autoStartTeravolt, setAutoStartTeravolt] = useState(false);
    const [isTeravoltEnabled, setIsTeravoltEnabled] = useState(false);

    const { setIsTeravoltVisible } = useTeravolt();

    useEffect(() => {
        if (isTeravoltEnabled && autoStartTeravolt) {
            setIsTeravoltVisible(true);
        }
    }, [isTeravoltEnabled, autoStartTeravolt]);

    return (
        <PlayerOverlaysContext.Provider
            value={{
                autoStartTeravolt,
                setAutoStartTeravolt,
                isTeravoltEnabled,
                setIsTeravoltEnabled,
            }}
        >
            {children}
        </PlayerOverlaysContext.Provider>
    );
};
