import { OfferSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import { OfferIdInterface } from '@/interfaces/product-id-interface';
import { OfferOptionModel } from './offer-option.model';
import { OfferPricingPlanModel } from './offer-pricing-plan.model';
import { RulesModel } from './rules.model';

export class ProductOfferModel {
    private accessText: OfferSchema['accessText'];
    private cyclic: OfferSchema['cyclic'];
    private description: OfferSchema['description'];
    private id: OfferSchema['id'];
    private longAccessText: OfferSchema['longAccessText'];
    private minPrice: OfferSchema['minPrice'];
    private minPriceIncludingPricingPlans: OfferSchema['minPriceIncludingPricingPlans'];
    private name: OfferSchema['name'];
    private offerPricingPlanRequired: OfferSchema['offerPricingPlanRequired'];
    private offerPricingPlans: OfferPricingPlanModel[] = [];
    private options: OfferOptionModel[] = [];
    private partnerId: OfferSchema['partnerId'];
    private resignInfo: OfferSchema['resignInfo'];
    private rules: RulesModel[] = [];
    private trial: OfferSchema['trial'];
    private type: OfferSchema['type'];
    private recommended: OfferSchema['recommended'];

    constructor(definition: OfferSchema) {
        this.accessText = definition.accessText;
        this.cyclic = definition.cyclic;
        this.description = definition.description;
        this.id = definition.id;
        this.longAccessText = definition.longAccessText;
        this.minPrice = definition.minPrice;
        this.minPriceIncludingPricingPlans = definition.minPriceIncludingPricingPlans;
        this.name = definition.name;
        this.offerPricingPlanRequired = definition.offerPricingPlanRequired;
        this.partnerId = definition.partnerId;
        this.resignInfo = definition.resignInfo;
        this.trial = definition.trial;
        this.type = definition.type;
        this.recommended = definition.recommended;

        if (definition.offerPricingPlans) {
            const allOfferPricingPlans = definition.offerPricingPlans.map(
                (offerPricingPlan) => new OfferPricingPlanModel(offerPricingPlan),
            );
            this.offerPricingPlans = allOfferPricingPlans.filter((offerPricingPlan) =>
                offerPricingPlan.getPricingPlan().isSupported(),
            );
        }

        if (definition.options) {
            definition.options.forEach((option) => {
                if (option.type !== 'appstore' && option.type !== 'googleplay') {
                    const optionModel = new OfferOptionModel(option);

                    if (optionModel.isSupported()) {
                        this.options.push(new OfferOptionModel(option));
                    }
                }
            });
        }

        if (definition.rules) {
            this.rules = definition.rules.map((rules) => new RulesModel(rules));
        }
    }

    public getId(): string {
        return this.id;
    }

    public getOfferId(): OfferIdInterface {
        return {
            id: this.id,
            type: this.type,
        };
    }

    public getName(): string {
        return this.name || '';
    }

    public isPricingPlansExists(): boolean {
        return this.offerPricingPlans.length > 0;
    }

    public getOfferPricingPlans(): OfferPricingPlanModel[] {
        return this.offerPricingPlans;
    }

    public findOfferPricingPlan(offerPricingPlanId?: string): OfferPricingPlanModel | undefined {
        return this.offerPricingPlans.find(
            (offerPricingPlan) => offerPricingPlan.getId() === offerPricingPlanId,
        );
    }

    public getOptions(): OfferOptionModel[] {
        return this.options;
    }

    public findOption(optionId?: string): OfferOptionModel | undefined {
        return this.options.find((option) => option.getId() === optionId);
    }

    public getMinPriceText(): string | undefined {
        return this.minPrice?.valueText;
    }

    public getDataLayerPrice(): number {
        return this.minPrice?.value ? this.minPrice?.value / 100.0 : 0.01;
    }

    public getAccessText(): string | undefined {
        return this.accessText;
    }

    public getAccessDescriptionText(): string | undefined {
        return this.longAccessText;
    }

    public getRules(): RulesModel[] {
        return this.rules;
    }

    public isRulesExists(): boolean {
        return this.rules.length > 0;
    }

    public isOfferOrOptionsRulesExists(): boolean {
        let isRulesExists = this.isRulesExists();

        this.getOptions().forEach((option) => {
            if (option.isRulesExists()) {
                isRulesExists = true;
            }
        });

        return isRulesExists;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public isCyclic(): boolean {
        return Boolean(this.cyclic);
    }

    public getTrialDuration(): string | undefined {
        return this.trial?.durationText;
    }

    public isOfferPricingPlanRequired(): boolean {
        return Boolean(this.offerPricingPlanRequired);
    }

    public getResignInfo() {
        return this.resignInfo;
    }

    public isPartnerOffer(): boolean {
        return !!this.partnerId;
    }

    public isRecommended(): boolean {
        return Boolean(this.recommended);
    }
}
