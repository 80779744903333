import { CustomParams, GalleryImageInterface } from '@/types/gallery.type';

export const filterImagesByCustomParams = (
    images: GalleryImageInterface[],
    params: Partial<CustomParams>,
) => {
    return images.filter((image) => {
        if (!image.customParams) return false;
        for (const [key, value] of Object.entries(params)) {
            if (image.customParams[key as keyof CustomParams] !== value) return false;
        }

        return true;
    });
};

export const filterByAspectRatio = (
    source: GalleryImageInterface[],
    targetRatio: number,
    tolerance = 0.05,
) => {
    const minRatio = targetRatio * (1 - tolerance);
    const maxRatio = targetRatio * (1 + tolerance);

    const matchesAspectRatio = (img: GalleryImageInterface) => {
        let width: number;
        let height: number;

        if ('aspect' in img) {
            width = img.aspect.width;
            height = img.aspect.height;
        } else {
            return false;
        }

        const imageRatio = width / height;

        return imageRatio >= minRatio && imageRatio <= maxRatio;
    };

    return source.filter(matchesAspectRatio);
};
