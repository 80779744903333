import { ImageSchema } from '@/interfaces/from-schemas/system/getAvatarsOut';

export class AvatarModel {
    private readonly avatarId: string;
    private readonly images: ImageSchema[];

    constructor(definition: any) {
        this.avatarId = definition['id'];
        this.images = definition['images'];
    }
    public getId(): string {
        return this.avatarId;
    }

    public getSrc(): string {
        const sources = this.images
            ?.filter(({ type }) => type === 'square')
            .map(({ sources }) => {
                return sources.map(({ src }) => {
                    return src;
                });
            })
            .reduce((acc, val) => acc.concat(val), []);

        return sources[0];
    }
}
