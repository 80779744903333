import { usePlatform } from '@/contexts/platform/platform.hooks';
import { GM_DEVICE_TYPE } from '@/types/platform.type';
import { useEffect, useState } from 'react';
import { usePlayerSkipRange } from './use-player-skip-range';

export const useTimeshiftingSupported = () => {
    const [deviceSupported, setDeviceSupported] = useState(false);
    const [isTimeshifting, setIsTimeshifting] = useState(false);
    const [timeshiftingSupported, setTimeshiftingSupported] = useState(false);
    const { platform } = usePlatform();
    const skipRange = usePlayerSkipRange();

    useEffect(() => {
        const { excluededBrowsers, excludedOS } = process.env.timeshifting;
        const { application, os, deviceType } = platform;
        const isBrowserSupported = application && !excluededBrowsers.includes(application);
        const isOSSupported = os && !excludedOS.includes(os);
        const isSupported =
            (isBrowserSupported && isOSSupported) || deviceType === GM_DEVICE_TYPE.TV_Samsung_2018;
        setDeviceSupported(isSupported);
    }, [platform]);

    useEffect(() => {
        const { start, end } = skipRange;
        const timeshiftingLength = end - start;
        const { minLengthSupported } = process.env.timeshifting;
        setIsTimeshifting(timeshiftingLength > minLengthSupported);
    }, [skipRange]);

    useEffect(() => {
        setTimeshiftingSupported(isTimeshifting && deviceSupported);
    }, [isTimeshifting, deviceSupported]);

    return timeshiftingSupported;
};
