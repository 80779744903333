import { GetFavoritesWithFlatNavigationOut } from '@/interfaces/from-schemas/navigation/getFavoritesWithFlatNavigationOut';
import { CPID } from '@/types/media.type';
import { MediaDetailsModel } from '../media/media-details.model';
import { FavoriteModel } from './favorite.model';

class FavoritesWithFlatNavigationModel {
    private readonly count: number = 0;
    private readonly flatNavigation: GetFavoritesWithFlatNavigationOut['flatNavigation'] = {};
    private readonly limit: number = 0;
    private readonly offset: number = 0;
    private results: Array<FavoriteModel> = [];
    private readonly total: number = 0;

    constructor(definition: GetFavoritesWithFlatNavigationOut) {
        if (definition) {
            Object.keys(definition).forEach((name: string) => {
                // @ts-ignore
                this[name] = definition[name];
            });

            this.results = [];

            definition.results.forEach(
                (result) => result.object && this.results.push(new FavoriteModel(result)),
            );
        }
    }

    public getCount(): number {
        return this.count;
    }

    public getFlatNavigation(): GetFavoritesWithFlatNavigationOut['flatNavigation'] {
        return this.flatNavigation;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getResults(): Array<FavoriteModel> {
        return this.results;
    }

    public getTotal(): number {
        return this.total;
    }

    public isInFavorites(media: MediaDetailsModel): boolean {
        return !!this.results.find((result) => {
            const object = result.getObject();

            const isCategory = object?.getCpid() === CPID.CATEGORY_OR_PACK;
            const id = object?.getId();
            const keyCategoryId = object?.getKeyCategoryId();

            return isCategory ? keyCategoryId === media.keyCategoryId : id === media.id;
        });
    }
}

export default FavoritesWithFlatNavigationModel;
