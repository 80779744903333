import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { ListElementImageInterface } from '@/interfaces/list-element.interface';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { backgroundUtil } from '@/utils/background.utils';
import { coverUtil } from '@/utils/cover.util';
import { Router } from '@/routing';
import {
    TvChannelListItemSchema,
    CategorySchema,
    ImageSourceSchema,
    ProductIdSchema,
} from '@/interfaces/from-schemas/navigation/getTvChannelsOut';
import { ROUTES } from '@/routing/types';
import { CPID, METADATA_TYPE } from '@/types/media.type';
import { redirectToFirstPaymentStep } from '@/helpers/payment.helper';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { buildNextUrl, getNextUrl } from '@/helpers/url.helper';
import { ASPECT_RATIO, FindGalleryOptions, GalleryImageInterface } from '@/types/gallery.type';
import { filterByAspectRatio, filterImagesByCustomParams } from '@/utils/gallery.utils';
import { THEME_TYPE } from '@/types/theme.type';

export class ChannelModel implements AssetInterface {
    public imageDisplayMode = IMAGE_DISPLAY_MODE.THUMBNAILS;
    private readonly cpid: CPID = CPID.EVENT_OR_CHANNEL;
    private readonly id: string = '';
    private readonly title: string = '';
    private readonly genres: string[] = [];
    private readonly thumbnails: ImageSourceSchema[] = [];
    private readonly ageGroup: number = 0;
    private readonly posters: ImageSourceSchema[] = [];
    private readonly category: CategorySchema = {
        cpid: 7,
        id: 0,
        name: '',
        description: '',
        genres: [],
        categoryPath: [],
        categoryNamesPath: [],
    };
    private readonly product?: ProductIdSchema;
    private readonly backgroundSrc?: string;
    private readonly transparentLogos: {
        square?: GalleryImageInterface[];
        rectangle?: GalleryImageInterface[];
    };
    private readonly gallery: TvChannelListItemSchema['gallery'];
    private description = '';
    private genre = '';
    private metadata = '';
    private isLive = false;

    private programTitle = '';
    private progress = 0;
    private locked = false;
    private timeShiftingDuration = 0;

    constructor(definition: TvChannelListItemSchema) {
        Object.keys(definition).forEach((name: string) => {
            // @ts-ignore
            this[name] = definition[name];
        });
        //! "thumbails" and "posters" will be deprecated in the future. "gallery" will be used instead.
        this.backgroundSrc = backgroundUtil(this.thumbnails)?.src;

        const squareImage = this.findGalleryImages({
            ratio: ASPECT_RATIO.RECTANGLE,
            customParams: {
                backgroundEffect: 'transparent',
            },
        });

        const rectangleImage = this.findGalleryImages({
            ratio: ASPECT_RATIO.RECTANGLE,
            customParams: {
                backgroundEffect: 'transparent',
            },
        });

        this.transparentLogos = { square: squareImage, rectangle: rectangleImage };
    }

    private findGalleryImages(options: FindGalleryOptions): GalleryImageInterface[] | undefined {
        const [width, height] = options.ratio.split(':').map(Number);
        const ratio = width / height;

        const filteredImages = filterImagesByCustomParams(
            this.gallery as GalleryImageInterface[],
            options.customParams,
        );

        const images = filterByAspectRatio(filteredImages, ratio);

        return images;
    }

    public getAgeGroup(): number {
        return this.ageGroup;
    }
    public getCategory(): CategorySchema {
        return this.category;
    }
    public getId(): string {
        return this.id;
    }
    public getPosters(): ImageSourceSchema[] {
        return this.posters;
    }

    public getCpid(): CPID {
        return this.cpid;
    }
    public setTitle(programTitle: string): void {
        this.programTitle = programTitle;
    }
    public setIsLocked(locked: boolean): void {
        this.locked = locked;
    }
    public getTitle(): string {
        return this.programTitle;
    }
    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails;
    }
    public getProductId(): ProductIdSchema | undefined {
        return this.product;
    }

    public getDetailsRoute(): ROUTES {
        return ROUTES.CHANNEL_TV_DETAILS;
    }

    public getRouteParams(): any {
        return {
            channelTitle: this.title,
            channelId: this.id,
        };
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        const { replaceRoute } = options || {};

        const route = this.getDetailsRoute();
        const params = this.getRouteParams();
        replaceRoute ? Router.replaceRoute(route, params) : Router.pushRoute(route, params);
    }

    public goToPaymentsPath(): void {
        const productId = this.getProductId() as ProductIdInterface | undefined;

        if (productId) {
            redirectToFirstPaymentStep({ productId, withNext: true });
        }
    }

    public goToWatch(replace = false, startTime?: number, withNext?: boolean): void {
        let params = this.getRouteParams();
        let queryParams = {};
        const next = getNextUrl() || (withNext ? buildNextUrl() : undefined);

        if (next) {
            params = { ...params, next };
        }

        if (startTime) {
            params = { ...params, startTime };
            // Router standalone oczekuje query paramsów jako trzeci argument
            queryParams = { startTime };
        }

        if (replace) {
            Router.replaceRoute(ROUTES.CHANNEL_TV_WATCH, params, queryParams);
        } else {
            Router.pushRoute(ROUTES.CHANNEL_TV_WATCH, params, queryParams);
        }
    }

    public getTransparentLogoByTheme(theme: THEME_TYPE) {
        const images = this.transparentLogos?.rectangle || [];
        const logosByTheme = filterImagesByCustomParams(images, {
            appTheme: theme,
        });

        return logosByTheme[0]?.src || '';
    }

    public getImage(): ListElementImageInterface {
        const images = this.getThumbnails();
        const imageSrc = coverUtil(images, this.imageDisplayMode) || '';
        return {
            src: imageSrc && imageSrc.src,
            displayMode: this.imageDisplayMode,
        };
    }
    public getCategoryName(): string {
        return this.category.id.toString();
    }
    public setIsLive(isLive: boolean): void {
        this.isLive = isLive;
    }

    public getIsLocked(): boolean {
        return this.locked;
    }

    public setGenre(genre: string): void {
        this.genre = genre;
    }

    public getIsLive(): boolean {
        return this.isLive;
    }

    public getTimeShiftingDuration(): number {
        return this.timeShiftingDuration ?? 0;
    }

    public getGenres(): string {
        return this.genres.join(', ');
    }
    public setDescription(description: string): void {
        this.description = description;
    }
    public getDescription(): string {
        return this.description;
    }
    public setMetaData(metadata: string): void {
        this.metadata = metadata;
    }
    public getChannelTitle(): string {
        return this.title;
    }

    public getMetadata(type: METADATA_TYPE): string[] {
        const metadata = [];

        switch (type) {
            case METADATA_TYPE.MAIN:
                metadata.push(this.getChannelTitle(), this.genre, this.metadata);
                break;
            case METADATA_TYPE.LIST_ELEMENT:
                if (this.metadata) {
                    metadata.push(this.metadata);
                }

                break;
        }

        return metadata;
    }

    public getDetailedMetadata(type: METADATA_TYPE): string {
        if (type === METADATA_TYPE.LIST_ELEMENT) {
            const mainCategory = this.category?.categoryNamesPath?.[1];

            return [mainCategory, this.metadata].filter(Boolean).join(', ');
        }

        return '';
    }

    public getBackgroundSrc(): string | undefined {
        return this.backgroundSrc;
    }

    public setProgress(progress: number): void {
        this.progress = progress;
    }
    public getProgress(): number {
        return this.progress;
    }

    public getGallery() {
        return this.gallery;
    }
}
