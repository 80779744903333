import styled from 'styled-components';

export const CenterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const ButtonContainer = styled.div`
    width: 40rem;
`;

export const InputContainer = styled.div`
    width: 40rem;
`;

export const FooterTextContainer = styled.div`
    white-space: pre-line;
`;
