import { useRef } from 'react';
import { BgContainerImage, BgContainerInnerWrapper, BgContainerOuterWrapper } from './styles';
import { BgContainerProps } from './types';

export const BackgroundContainer = ({ backgroundUrl, children, className }: BgContainerProps) => {
    const imageRef = useRef<HTMLImageElement>(null);
    return (
        <BgContainerOuterWrapper>
            <BgContainerImage
                src={backgroundUrl}
                ref={imageRef}
                onLoad={() => {
                    if (imageRef.current) {
                        imageRef.current.style.opacity = '1';
                    }
                }}
            />
            <BgContainerInnerWrapper className={className}>{children}</BgContainerInnerWrapper>
        </BgContainerOuterWrapper>
    );
};
