import { useState } from 'react';
import useGetProfiles from '@/api/auth/use-get-profiles';
import useSetSessionProfile from '@/api/auth/use-set-session-profile';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';
import { ProfileInterface } from '@/interfaces/profile-interface';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useStatsModule } from '@/contexts/stats/stats.context';
import { useClearContext } from '@/hooks/use-clear-context.hook';

export const useWatchAs = () => {
    const clearContext = useClearContext();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [sessionProfile, setSessionProfile] = useSessionProfile();
    const { data: profiles, error: profilesError, isFetching } = useGetProfiles();
    const [mutateSessionProfile, { error: sessionError }] = useSetSessionProfile();
    const statsModule = useStatsModule();

    const selectProfile = async (profile: ProfileInterface) => {
        setIsProcessing(true);

        const result = await mutateSessionProfile({ profileId: profile.profileId });

        if (result.ok && profile) {
            setSessionProfile(profile);
            clearContext(ROUTES.HOME);
            Router.pushRoute(ROUTES.HOME);
        }
    };

    const selectProfileById = (selectedProfileId: string) => {
        const profile = profiles.find(({ profileId }) => profileId === selectedProfileId);

        if (profile) {
            selectProfile(profile);

            statsModule.updateParams({
                profileId: profile.profileId,
            });
        }
    };

    return {
        error: sessionError?.getUserMessage() || profilesError?.getUserMessage(),
        isLoading: isFetching || isProcessing,
        profiles,
        selectProfile,
        selectProfileById,
        sessionProfile,
    };
};
