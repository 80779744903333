import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import { Message } from '@/components/shared/Message';
import { ProductCard } from '@/components/shared/ProductCard';
import { convertSecondsToDays, formatDate } from '@/helpers/date.helper';
import {
    compareProducts,
    isLive,
    isPacket,
    isChannelTv,
    isVodOrMovie,
} from '@/helpers/product.helper';
import t from '@/lib/i18n';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { navVertical } from 'nav-tree';
import React from 'react';
import {
    ButtonsContainer,
    ContentContainer,
    MessageContainer,
    PaymentSuccessContainer,
    ProductContainer,
} from './styles';
import { ButtonInterface, PaymentSuccessProps } from './types';

export const PaymentSuccess = ({
    possession,
    possessionProduct,
    baseProductId,
    isPlusbill,
    media,
    productSubscription,
}: PaymentSuccessProps) => {
    const possessionProductId = possessionProduct.getProductId();
    const isBaseProductDifferent = !compareProducts(possessionProductId, baseProductId);

    const accessTo = possession.getValidTo();
    const accessDuration = possession.getAccessDuration();
    const trialDuration = possession.getTrialDuration();
    const isTrial = possession.isTrial();
    const isCyclic = possession.isCyclic();

    const buttons: ButtonInterface[] = [];
    const descriptions = [t('payment-steps.payment-success')];
    const activeText = t('payment-steps.active');
    const importantText = t('payment-steps.important');
    const vodButtonText = t('payment-steps.vod-button-text');
    const liveButtonText = t('payment-steps.live-button-text');
    const channelTvButtonText = t('payment-steps.channel-tv-button-text');
    const packetButtonText = t('payment-steps.packet-button-text');

    const trimPurchaseHistory = true;
    const redirectToMedia = () => media?.goToDetails({ trimPurchaseHistory });
    const redirectToPacket = () => possessionProduct.goToDetails({ trimPurchaseHistory });

    const buildVodContent = () => {
        const accessToFullDate = accessTo && formatDate(accessTo, FORMAT_DATE_MODE.FULL_DATE);

        if (accessToFullDate) {
            if (isPlusbill) {
                descriptions.push(
                    t('payment-steps.vod-plusbill-success-text', {
                        status: `<span>${importantText}</span>`,
                        accessTo: `<span>${accessToFullDate}</span>`,
                        portalName: `<span>${t('domain')}</span>`,
                    }),
                );
            } else {
                descriptions.push(
                    t('payment-steps.vod-success-text', {
                        status: `<span>${importantText}</span>`,
                        accessTo: `<span>${accessToFullDate}</span>`,
                    }),
                );
            }
        }

        if (redirectToMedia) {
            buttons.push({
                text: vodButtonText,
                defaultFocused: true,
                onAction: redirectToMedia,
            });
        }
    };

    const buildLiveContent = () => {
        if (isPlusbill) {
            descriptions.push(
                t('payment-steps.live-plusbill-success-text', {
                    status: `<span>${activeText}</span>`,
                    portalName: `<span>${t('domain')}</span>`,
                }),
            );
        } else {
            descriptions.push(
                t('payment-steps.live-success-text', {
                    status: `<span>${activeText}</span>`,
                }),
            );
        }

        if (redirectToMedia) {
            buttons.push({
                text: liveButtonText,
                defaultFocused: true,
                onAction: redirectToMedia,
            });
        }
    };

    const buildPacketDescriptions = () => {
        if (isCyclic) {
            if (isTrial) {
                const accessDurationInDays = accessDuration && convertSecondsToDays(accessDuration);
                const trialDurationInDays = trialDuration && convertSecondsToDays(trialDuration);
                if (accessDurationInDays && trialDurationInDays) {
                    if (isPlusbill) {
                        descriptions.push(
                            t('payment-steps.packet-cyclic-trial-plusbill-success-text', {
                                portalName: `<span>${t('domain')}</span>`,
                                accessDurationInDays,
                                trialDurationInDays,
                                status: `<span>${importantText}</span>`,
                            }),

                            t('payment-steps.packet-extra-succes-text', {
                                redirectToMyAccess: `<span>${t('account-menu.my-access')}</span>`,
                            }),
                        );
                    } else {
                        descriptions.push(
                            t('payment-steps.packet-cyclic-trial-success-text', {
                                status: `<span>${importantText}</span>`,
                                accessDurationInDays,
                                trialDurationInDays,
                            }),
                        );
                    }
                }
            } else {
                if (isPlusbill) {
                    descriptions.push(
                        t('payment-steps.packet-cyclic-plusbill-success-text', {
                            portalName: `<span>${t('domain')}</span>`,
                            status: `<span>${importantText}</span>`,
                        }),
                        t('payment-steps.packet-extra-succes-text', {
                            redirectToMyAccess: `<span>${t('account-menu.my-access')}</span>`,
                        }),
                    );
                } else {
                    const nextPaymentDate = productSubscription?.getNextPaymentDate();
                    const nextPaymentDateFormatted =
                        nextPaymentDate &&
                        formatDate(nextPaymentDate, FORMAT_DATE_MODE.DATE_WITH_YEAR);
                    const accessToFormatted =
                        accessTo && formatDate(accessTo, FORMAT_DATE_MODE.DATE_WITH_YEAR);
                    descriptions.push(
                        t('payment-steps.packet-cyclic-success-text', {
                            status: `<span>${importantText}</span>`,
                            accessTo: `<span>${accessToFormatted}</span>`,
                            nextPaymentDate: `<span>${nextPaymentDateFormatted}</span>`,
                        }),
                    );
                }
            }
        } else {
            const accessToFormatted =
                accessTo && formatDate(accessTo, FORMAT_DATE_MODE.DATE_WITH_YEAR);
            if (isPlusbill) {
                descriptions.push(
                    t('payment-steps.packet-plusbill-success-text', {
                        status: `<span>${importantText}</span>`,
                        accessTo: `<span>${accessToFormatted}</span>`,
                        portalName: `<span>${t('domain')}</span>`,
                    }),
                    t('payment-steps.packet-extra-succes-text', {
                        redirectToMyAccess: `<span>${t('account-menu.my-access')}</span>`,
                    }),
                );
            } else {
                if (accessTo) {
                    descriptions.push(
                        t('payment-steps.packet-success-text', {
                            status: `<span>${importantText}</span>`,
                            accessTo: `<span>${accessToFormatted}</span>`,
                        }),
                        t('payment-steps.packet-extra-succes-text', {
                            redirectToMyAccess: `<span>${t('account-menu.my-access')}</span>`,
                        }),
                    );
                }
            }
        }
    };

    const buildPacketButtons = () => {
        let packetButtonDefaultFocused = true;

        if (isBaseProductDifferent && redirectToMedia) {
            packetButtonDefaultFocused = false;

            switch (true) {
                case isVodOrMovie(baseProductId):
                    buttons.push({
                        text: vodButtonText,
                        defaultFocused: true,
                        onAction: redirectToMedia,
                    });
                    break;

                case isLive(baseProductId):
                    buttons.push({
                        text: liveButtonText,
                        defaultFocused: true,
                        onAction: redirectToMedia,
                    });
                    break;

                case isChannelTv(baseProductId):
                    buttons.push({
                        text: channelTvButtonText,
                        defaultFocused: true,
                        onAction: redirectToMedia,
                    });
                    break;

                default:
                    packetButtonDefaultFocused = true;
            }
        }

        buttons.push({
            text: packetButtonText,
            defaultFocused: packetButtonDefaultFocused,
            onAction: redirectToPacket,
        });
    };

    switch (true) {
        case isVodOrMovie(possessionProductId):
            buildVodContent();
            break;

        case isLive(possessionProductId):
            buildLiveContent();
            break;

        case isPacket(possessionProductId):
            buildPacketDescriptions();
            buildPacketButtons();
            break;

        default:
        // noop
    }

    return (
        <PaymentSuccessContainer>
            <ProductContainer>
                <ProductCard product={possessionProduct} />
            </ProductContainer>

            <ContentContainer>
                <MessageContainer>
                    <Message
                        icon={ICONS.SUCCESS_CIRCLE}
                        title={t('payment-steps.thank-you')}
                        innerHtmlDescription={descriptions}
                    />
                </MessageContainer>

                <ButtonsContainer func={navVertical}>
                    {buttons.map((button, index) => {
                        return (
                            <PlayableButton
                                key={index}
                                text={button.text}
                                defaultFocused={button.defaultFocused}
                                onButtonAction={button.onAction}
                            />
                        );
                    })}
                </ButtonsContainer>
            </ContentContainer>
        </PaymentSuccessContainer>
    );
};
