import { useCallback, useContext, useEffect, useState } from 'react';
import { CookieSetterFunc } from './types';
import { CookieContext } from './cookies.context';

export const useCookieService = () => useContext(CookieContext);

export const useCookie = (name: string): [string | undefined, CookieSetterFunc, () => void] => {
    const cookieService = useContext(CookieContext);
    const [value, setValue] = useState(cookieService.get(name));

    useEffect(() => {
        const unlisten = cookieService.listen(name, (cookieValue) => setValue(cookieValue));
        return unlisten;
    }, [cookieService, name]);

    const setCookie = useCallback<CookieSetterFunc>(
        (newValue, options) => cookieService.set(name, newValue, options),
        [cookieService, name],
    );

    const removeCookie = useCallback(() => cookieService.remove(name), [cookieService, name]);

    return [value ? `${value}` : undefined, setCookie, removeCookie];
};
