import useCheckProductAccess from '@/api/drm/use-check-product-access';
import useGetMedia from '@/api/navigation/use-get-media';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { LivePlayer } from '@/components/shared/Player';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { CPID } from '@/types/media.type';
import { useMemo } from 'react';
import { useRouter } from '@/routing';

const LiveWatch = () => {
    const { query } = useRouter();
    const { eventId: mediaId } = query;

    const { data: mediaListItem } = useGetMedia({ mediaId, cpid: CPID.EVENT_OR_CHANNEL });

    const product = mediaListItem?.getProduct();
    const { data: productAccess } = useCheckProductAccess(product ? { product } : undefined);
    const mediaDetails = useMemo(
        () => new MediaDetailsModel(mediaListItem, productAccess, undefined),
        [mediaListItem, productAccess],
    );

    return (
        <BaseLayout fullscreen>
            <LivePlayer mediaId={mediaId} mediaDetails={mediaDetails} />
        </BaseLayout>
    );
};

export default LiveWatch;
