import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';
import { StaffRecommendationListsItemsModel } from '@/models/recommendations/staff-recommendation-lists-items.model';
import { GetStaffRecommendationsListItemsIn } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsIn';
import { GetStaffRecommendationsListItemsOut } from '@/interfaces/from-schemas/navigation/getStaffRecommendationsListItemsOut';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_STAFF_RECOMMENDATIONS_LISTS_ITEMS;

export const getStaffRecommendationListsItemsPromise = (
    params: GetStaffRecommendationsListItemsIn | false,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetStaffRecommendationsListItemsOut> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

const useGetStaffRecommendationListsItems = (
    params: GetStaffRecommendationsListItemsIn | false,
): IApiResponse<StaffRecommendationListsItemsModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);
    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new StaffRecommendationListsItemsModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetStaffRecommendationListsItems;
