import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { ConnectOut } from '@/interfaces/from-schemas/auth/connectOut';
import { ConnectIn } from '@/interfaces/from-schemas/auth/connectIn';

const useConnect = () => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.CONNECT, null);

    return useGetMediaApiMutation<Omit<ConnectIn, 'authData'>, ConnectOut>(keyHook, {
        appendAuthData: true,
        updateAuthSession: true,
    });
};

export default useConnect;
