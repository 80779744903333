import { Observer } from './observers/observer';
import {
    StatsAPI,
    EVENT,
    eventTypeCheck,
    StatsConfig,
    statsConfigCheck,
    EventParams,
    EventsWithoutParams,
    AliasMethod,
    ActivityEventsAppStartedParams,
    ActivityEventsAppUserNavigatedParams,
    ActivityEventsAppUserLoggedParams,
    ActivityEventsAppUserLoggedOutParams,
    ActivityEventsUpdateHandlerParams,
    ActivityEventsAppPausedParams,
    ActivityEventsAppResumedParams,
    ActivityEventsAppUserItemClickedParams,
} from './types';
import HandlersManager, { EventHandler } from './modules/handlers-manager';
import Clients from './clients/new-clients';
import { AsyncEvent } from 'ts-events';
import CryptoUtil from '@/utils/crypto.utils';
import { IHttpResponse } from '@/services/http-request.service';
import { Debug } from '@/utils/debug.util';

const crypto = new CryptoUtil();
const debug = new Debug('stats');

export class Stats extends Observer implements StatsAPI {
    public onParamsUpdate = new AsyncEvent<ActivityEventsUpdateHandlerParams>();

    static generateSessionAppId(userId = '', ua = ''): string {
        return crypto.md5(userId + ua + Date.now());
    }

    private handlersManager = new HandlersManager();

    dispatch(event: EventsWithoutParams): Promise<void>;
    dispatch<E extends EVENT>(event: E, params?: EventParams<E>): Promise<void>;
    dispatch<E extends EVENT>(event: EVENT, params?: EventParams<E>): Promise<void | null> {
        return this.handlersManager
            .dispach(event, params)
            .then((data) => {
                debug.log('dispatch', data);
                return null;
            })
            .catch((e) => {
                debug.error('dispatch error', e);
                return null;
            });
    }

    AppUserLoggedOut: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT> = (
        params?: ActivityEventsAppUserLoggedOutParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED_OUT, params);
    };

    AppStarted: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_STARTED> = (
        params?: ActivityEventsAppStartedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_STARTED, params);
    };

    AppUserNavigated: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED> = (
        params: ActivityEventsAppUserNavigatedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_USER_NAVIGATED, params);
    };

    AppUserLogged: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED> = (
        params: ActivityEventsAppUserLoggedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_USER_LOGGED, params);
    };

    AppPaused: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_PAUSED> = (
        params?: ActivityEventsAppPausedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_PAUSED, params);
    };

    AppResumed: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_RESUMED> = (
        params?: ActivityEventsAppResumedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_RESUMED, params);
    };

    AppUserItemClicked: AliasMethod<EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED> = (
        params?: ActivityEventsAppUserItemClickedParams,
    ) => {
        return this.dispatch(EVENT.ACTIVITY_EVENTS_APP_USER_ITEM_CLICKED, params);
    };

    addListener<E extends EVENT>(
        event: EVENT,
        listener: (params: EventParams<E>) => Promise<IHttpResponse>,
    ): this;
    addListener(event: string | symbol, listener: (...args: unknown[]) => void): this;
    addListener<E extends EVENT>(
        event: EVENT,
        listener: EventHandler<E> | ((...args: unknown[]) => void),
    ): this | undefined {
        if (eventTypeCheck(event)) {
            this.handlersManager.addListener(event, listener as any);
            return this;
        }
    }

    private config: StatsConfig;

    constructor(config: StatsConfig) {
        super();
        this.config = config;

        if (this.config && statsConfigCheck(this.config)) {
            new Clients(this.handlersManager, this.config, this.onParamsUpdate);
        }
    }
}

export default Stats;

export type { EventParams, StatsConfig, ActivityEventsUpdateHandlerParams } from './types';

export { EVENT, ACTIVITY_EVENTS_ACCOUNT_TYPES, DEVICE_TYPE, PLATFORM } from './types';
