import useChangeEmail from '@/api/auth/use-change-email';
import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { yupResolver } from '@/lib/yupResolver';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import t from '@/lib/i18n';
import { Router, useRouter } from '@/routing';
import { useMemo } from 'react';

export interface RegisterProps {
    email: string;
}

const schema = Yup.object().shape({
    email: Yup.string()
        .required(t('pages.register.enter-email'))
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('pages.register.invalid-email')),
});

export const useSetNewEmail = () => {
    const { query } = useRouter();
    const { changeEmail: changeEmailQuery } = query;
    const userModule = useUserModule();
    const authData = userModule.getAuthData();

    const [changeEmail, data] = useChangeEmail();
    const { status: changeEmailStatus, data: changeEmailData, error: changeEmailError } = data;

    const { register, handleSubmit, errors } = useForm<RegisterProps>({
        resolver: yupResolver(schema),
    });

    const onSubmit = handleSubmit(async ({ email }) => {
        const params = { email };
        if (authData) {
            Object.assign(params, { authData });
        }
        await changeEmail(params);
    });

    useMemo(() => {
        if (!authData && changeEmailQuery) Router.back();
    }, [authData, changeEmailQuery]);

    const userMessage = changeEmailData?.status === 0 && changeEmailData.statusDescription;
    const userError = errors.email?.message || changeEmailError?.getUserMessage();

    return {
        onSubmit,
        register,
        userMessage,
        userError,
        isSuccess: changeEmailStatus === 'success',
    };
};
