import { FlatFilterListSchema } from '@/interfaces/filters-interface';
import { useState } from 'react';
import { useDebouncedEffect } from './use-debounced-effect.hook';
import { useDebouncedState } from './use-debounced-state.hook';

export const useEmptyCatalog = (
    haveContent: boolean,
    filterLists: FlatFilterListSchema[],
    isFetching?: boolean,
    placeholder?: React.ReactNode,
) => {
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);

    useDebouncedEffect(
        () => {
            if (!isFetching && ((!filterLists.length && filterLists === []) || !placeholder))
                setShowNoDataMessage(!haveContent);
        },
        { timeout: 0 },
        [haveContent, isFetching, filterLists],
    );

    const showPlaceholder = !isFetching && !haveContent && !!placeholder;
    const debouncedShow = useDebouncedState(showPlaceholder, (show) => (show ? 100 : 0));

    return {
        showNoDataMessage,
        showEmptyCatalogPlaceholder: debouncedShow,
    };
};
