import styled from 'styled-components';
import { ButtonStyledProps, BUTTON_TYPE, NATIVE_BUTTON_TYPE } from './types';

function buildButtonBorderColor(props: ButtonStyledProps): string {
    if (props.active) {
        return props.theme.button.activeBorderColor;
    }

    switch (props.btnType) {
        case BUTTON_TYPE.PRIMARY:
            return props.theme.button.primaryBorderColor;

        case BUTTON_TYPE.SECONDARY:
            return props.theme.button.secondaryBorderColor;

        default:
            return 'transparent';
    }
}

function buildButtonBgColor(props: ButtonStyledProps): string {
    if (props.active) {
        return props.theme.button.activeBgColor;
    }

    switch (props.btnType) {
        case BUTTON_TYPE.PRIMARY:
            return props.theme.button.primaryBgColor;

        case BUTTON_TYPE.SECONDARY:
            return props.theme.button.secondaryBgColor;

        default:
            return 'transparent';
    }
}

function buildButtonFontColor(props: ButtonStyledProps): string {
    if (props.active) {
        return props.theme.button.activeFontColor;
    }

    switch (props.btnType) {
        case BUTTON_TYPE.PRIMARY:
            return props.theme.button.primaryFontColor;

        case BUTTON_TYPE.SECONDARY:
            return props.theme.button.secondaryFontColor;

        default:
            return 'inherit';
    }
}

const Button = styled.button<ButtonStyledProps>`
    display: inline-block;
    border-radius: 2em;
    line-height: 5rem;
    text-align: center;
    user-select: none;
    cursor: pointer;
    outline: 0;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    padding: ${(props) => (props.fullWidth ? '0' : '0 5.8rem')};
    border: 1px solid ${(props) => buildButtonBorderColor(props)};
    background-color: ${(props) => buildButtonBgColor(props)};
    color: ${(props) => buildButtonFontColor(props)};
    transform: ${(props) => props.active && 'scale(1.05)'};

    svg {
        transform: ${(props) => props.active && 'scale(1.05)'};
    }
`;

export const ButtonStyled = styled(Button).attrs<ButtonStyledProps>(({ nativeType }) => ({
    type: nativeType || NATIVE_BUTTON_TYPE.BUTTON,
}))(Button);
