import { ICONS } from '@/components/shared/Icon';
import { GetPossessionsOut } from '@/interfaces/from-schemas/payments/getPossessionsOut';
import { ProductIdSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import { PossessionsStatus } from '@/interfaces/possessions-interface';
import { OfferIdInterface, ProductIdInterface } from '@/interfaces/product-id-interface';
import t from '@/lib/i18n';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';

type GetPossession = GetPossessionsOut[number];

export class PossessionModel {
    readonly id: string;
    readonly product: ProductIdSchema;
    readonly status: PossessionsStatus;
    readonly offer: GetPossession['offer'];
    private option: GetPossession['option'];
    readonly validFrom: GetPossession['validFrom'];
    readonly validTo: GetPossession['validTo'];
    readonly trial: GetPossession['trial'];
    readonly partnerId: GetPossession['partnerId'];
    private productSubscriptionId: GetPossession['productSubscriptionId'];

    constructor(definition: GetPossessionsOut[number]) {
        this.id = definition.id;
        this.product = definition.product;
        this.status = definition.status;
        this.offer = definition.offer;
        this.option = definition.option;
        this.validFrom = definition.validFrom;
        this.validTo = definition.validTo;
        this.trial = definition.trial;
        this.partnerId = definition.partnerId;
        this.productSubscriptionId = definition.productSubscriptionId;
    }

    public getId(): string {
        return this.id;
    }

    public getProduct(): ProductIdSchema {
        return this.product;
    }

    public getProductId(): ProductIdInterface {
        const { id, type, subType } = this.product;

        return {
            id,
            type: type as PRODUCT_TYPES,
            subType: subType as PRODUCT_SUBTYPES,
        };
    }

    public getOfferId(): string | undefined {
        return this.offer?.id;
    }

    public getOfferIdData(): OfferIdInterface | undefined {
        if (!this.offer) {
            return undefined;
        }

        const { id, type } = this.offer;
        return { id, type };
    }

    public getStatus(): PossessionsStatus {
        return this.status;
    }

    public getValidFrom(): string | undefined {
        return this.validFrom;
    }

    public getValidTo(): string | undefined {
        return this.validTo;
    }

    public isCyclic(): boolean {
        return this.offer?.cyclic || false;
    }

    public isPlusbill(): boolean {
        return this.option?.type === 'plusbill';
    }

    public isTrial(): boolean {
        return this.trial || false;
    }

    public getTrialDuration(): number | undefined {
        return this.offer?.trialDuration;
    }

    public getAccessDuration(): number | undefined {
        return this.offer?.accessDuration;
    }

    public getOffer(): GetPossession['offer'] {
        return this.offer;
    }
    public getPartnerId(): GetPossession['partnerId'] {
        return this.partnerId;
    }

    public getProductSubscriptionId(): string | undefined {
        return this.productSubscriptionId;
    }
}
