import { OptionLayoutProps } from './types';
import { OptionLayoutStyled, Column } from './styles';
import React from 'react';

export const OptionLayout = ({ left, right }: OptionLayoutProps) => {
    return (
        <OptionLayoutStyled>
            <Column>{left}</Column>
            <Column>{right}</Column>
        </OptionLayoutStyled>
    );
};
