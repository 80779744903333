import { useEffect, useState } from 'react';

export const useTimeout = <T>(data: T, delay?: number): T => {
    const { delay: configDelay } = process.env.loader.processing;
    const slowDevice = process.env.slowDevice;

    const [state, setState] = useState<T>(() => data);

    useEffect(() => {
        let timeoutDelay = delay || configDelay;

        if (slowDevice) {
            return setState(data);
        }

        if (!data) {
            timeoutDelay = 0;
        }

        const timeout = setTimeout(() => {
            setState(data);
        }, timeoutDelay);

        return () => clearTimeout(timeout);
    }, [data]);

    return state;
};
