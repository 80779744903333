import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { findPatternByRouteName } from '@/helpers/router/find-pattern-by-route-name.helper';
import { generateRoute } from '@/helpers/url.helper';
import { AssetInterface, goToDetailsOptionsType } from '@/interfaces/asset.interface';
import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import {
    ListElementImageInterface,
    ListElementInterface,
} from '@/interfaces/list-element.interface';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { DATA_POSITION, DISPLAY_DATA, TITLE_TYPE } from '@/types/list-element-metadata.type';
import { MEDIA_TYPES, METADATA_TYPE } from '@/types/media.type';
import { ProductIdSchema } from '@/interfaces/from-schemas/navigation/getMediaListOut';
export class ListElementModel implements ListElementInterface {
    private ageGroup?: number;
    private dataPosition?: DATA_POSITION;
    private description?: string;
    private displayDataType = DISPLAY_DATA.BOTH;
    private episodeTitle?: string;
    private id = '';
    private image: ListElementImageInterface;
    private progress?: number;
    private readonly categoryName?: string;
    private shortTitle?: string;
    // showDetailedMetadata - na podstawie tego pola wyświetlane są szczegółowe informacje (kategoria główna, kluczowa, tematyczna)
    private showDetailedMetadata = false;
    private showMetadata = false;
    private title: string;
    private titleMaxLines = 1;
    private titleMaxChars = 56;
    private titleType?: TITLE_TYPE;
    private type: MEDIA_TYPES;
    private visibilityProgressBar = false;
    private watchRoute: string;
    private product: any;

    constructor(private asset: AssetInterface) {
        this.categoryName = asset.getCategoryName && asset.getCategoryName();
        this.description = asset.getDescription && asset.getDescription();
        this.episodeTitle = asset.getEpisodeTitle?.();
        this.id = (asset.getId && asset.getId().toString()) || '';
        this.image = asset.getImage();
        this.progress = asset.getProgress && asset.getProgress();
        this.shortTitle = asset.getShortTitle?.();
        this.title = asset.getTitle();
        this.type = (asset.getMediaType && asset.getMediaType()) || MEDIA_TYPES.CATEGORY;
        this.watchRoute = (asset.getWatchRoute && asset.getWatchRoute()) || '';
        this.product = asset.getProduct && asset.getProduct();
    }

    public getImageDisplayMode(): IMAGE_DISPLAY_MODE {
        return this.type === MEDIA_TYPES.MOVIE
            ? IMAGE_DISPLAY_MODE.POSTERS
            : IMAGE_DISPLAY_MODE.THUMBNAILS;
    }

    public getAgeGroup(): number | undefined {
        return this.ageGroup;
    }

    public getAsset(): AssetInterface {
        return this.asset;
    }
    public getProduct(): ProductIdSchema | null {
        return this.product;
    }

    public getCategoryName(): string | undefined {
        return this.categoryName;
    }

    public getDataPosition(): DATA_POSITION | undefined {
        return this.dataPosition;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public getDetailsRouteData(): { [key: string]: any } {
        const route = this.asset.getDetailsRoute();
        const params = this.asset.getRouteParams();
        return { route, params };
    }
    public getWatchRoute() {
        return this.watchRoute;
    }
    public getWatchRouteData(): { [key: string]: any } {
        const route = this.getWatchRoute();
        const detailsRoute = this.asset.getDetailsRoute();
        let params = this.asset.getRouteParams();

        const routePattern = (detailsRoute && findPatternByRouteName(detailsRoute)) || '';
        const next = generateRoute(routePattern, params);
        params = { ...params, next: next };
        return { route, params };
    }

    public getDisplayDataType(): DISPLAY_DATA {
        return this.displayDataType;
    }

    public getDisplayMetadata(): boolean {
        return this.isDisplayBothDataType() || this.displayDataType === DISPLAY_DATA.ONLY_METADATA;
    }

    public getDisplayTitle(): boolean {
        return this.isDisplayBothDataType() || this.displayDataType === DISPLAY_DATA.ONLY_TITLE;
    }

    public getEpisodeTitle(): string | undefined {
        return this.episodeTitle;
    }

    public getId(): string {
        return this.id;
    }

    public getImage(): ListElementImageInterface {
        return {
            ...this.image,
            src: protectURL(this.image.src),
        };
    }

    public getMetadata(type: METADATA_TYPE): string | string[] {
        return this.asset.getMetadata(type);
    }

    public getMetadataText(type: METADATA_TYPE): string | undefined {
        const showDetailedMetadata = this.showDetailedMetadata;
        const showMetadata = this.showMetadata;

        if (showDetailedMetadata) {
            return this.asset.getDetailedMetadata?.(type);
        } else if (showMetadata) {
            const metadata = this.asset.getMetadata(type);

            return typeof metadata === 'string' ? metadata : metadata.slice().join(' • ');
        }
    }

    public getProgress(): number | undefined {
        return this.progress;
    }

    public getShortTitle(): string | undefined {
        return this.shortTitle?.slice(0, this.titleMaxChars);
    }

    public getTitle(): string {
        return this.title.slice(0, this.titleMaxChars);
    }

    public getTitleByType(): string {
        const shortTitle = this.getShortTitle();
        const title = this.getTitle();

        switch (this.titleType) {
            case TITLE_TYPE.OWN:
                return this.episodeTitle || shortTitle || title;

            case TITLE_TYPE.SHORTENED:
                return shortTitle || title;

            case TITLE_TYPE.FULL:
            default:
                return title;
        }
    }

    public getShowDetailedMetadata(): boolean {
        return this.showDetailedMetadata;
    }

    public getShowMetadata(): boolean {
        return this.showMetadata;
    }

    public setShowDetailedMetadata(value: boolean): void {
        this.showDetailedMetadata = value;
    }

    public getTitleMaxLines(): number {
        return this.titleMaxLines;
    }

    public getTitleText(): string | undefined {
        // Tylko listy rekomendacyjne mogą mieć titleType (pole customData). showMetadata jest ustawiane dla elementów list lub mediaType (np. kanałów tv)
        const hasTitleType = !!this.titleType;
        const title = this.dataPosition ? this.getTitleByType() : undefined;
        const shortTitle = this.getShortTitle();
        const showMetadata = this.getShowMetadata();

        if (hasTitleType || showMetadata) {
            return title;
        }

        return shortTitle || title;
    }

    public getTitleType(): TITLE_TYPE | undefined {
        return this.titleType;
    }

    public getType(): string {
        return this.type;
    }

    public getVisibilityProgressBar(): boolean {
        return this.visibilityProgressBar;
    }

    public goToDetails(options?: goToDetailsOptionsType): void {
        this.asset.goToDetails(options);
    }

    private isDisplayBothDataType() {
        return this.displayDataType === DISPLAY_DATA.BOTH;
    }

    public isLive(): boolean {
        return this.getAsset().getIsLive?.() || false;
    }

    public isOnAir(): boolean {
        return this.getAsset().getIsOnAir?.() || false;
    }

    public updateFromFollowMeContent(
        mediaId: string,
        followMeContent: WatchedContentDataSchema[] | undefined,
    ): void {
        const content = followMeContent?.find((item) => item.mediaId.id === mediaId);

        if (content) {
            this.setVisibilityProgressBar(true);
            this.setProgress(content.lastPercent);
        }
    }

    public setDataPosition(dataPosition: DATA_POSITION): void {
        this.dataPosition = dataPosition;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public setDisplayDataType(displayDataType: DISPLAY_DATA): void {
        this.displayDataType = displayDataType;
    }

    public setImage(image: ListElementImageInterface): void {
        this.image = image;
    }

    public setTitle(title: string): void {
        this.title = title;
    }

    public setTitleMaxLines(value: number): void {
        this.titleMaxLines = value;
    }

    public setTitleType(titleType: TITLE_TYPE): void {
        this.titleType = titleType;
    }

    public setProgress(progress: number): void {
        this.progress = progress;
    }

    public setShowMetadata(value: boolean): void {
        this.showMetadata = value;
    }

    public setVisibilityProgressBar(visibilityProgressBar: boolean): void {
        this.visibilityProgressBar = visibilityProgressBar;
    }
}
