import {
    CpWalletAcquireOptionDataSchema,
    DotpayAcquireOptionDataSchemaSchema,
    DotpayCardAcquireOptionDataSchema,
    GetOptionDataOut,
    SmsAcquireOptionDataSchema,
} from '@/interfaces/from-schemas/payments/getOptionDataOut';
import { CpwalletModel } from './cpwallet.model';

interface SmsDataInterface {
    smsNumber: string;
    smsText: string;
}

type SupportedOptionDataType =
    | DotpayAcquireOptionDataSchemaSchema
    | DotpayCardAcquireOptionDataSchema
    | SmsAcquireOptionDataSchema
    | CpWalletAcquireOptionDataSchema;

export class OptionDataModel {
    private smsData?: SmsDataInterface;
    private buyUrl?: string;
    private cpwallets?: CpwalletModel[];

    constructor(definition: GetOptionDataOut) {
        const optionData = definition as SupportedOptionDataType;

        switch (optionData.type) {
            case 'sms':
                this.smsData = {
                    smsNumber: optionData.smsNumber,
                    smsText: optionData.smsText,
                };
                break;

            case 'dotpay':
            case 'dotpay-card':
                this.buyUrl = optionData.buyUrl;
                break;

            case 'cpwallet':
                this.cpwallets = optionData.cpWallets.map(
                    (cpwallet) => new CpwalletModel(cpwallet),
                );
                break;

            default:
            // noop
        }
    }

    public getSmsData(): SmsDataInterface | undefined {
        return this.smsData;
    }

    public getBuyUrl(): string | undefined {
        return this.buyUrl;
    }

    public getCpwallets(): CpwalletModel[] {
        return this.cpwallets || [];
    }
}
