import UserModule from '@/modules/user/user.module';
import { IRouter } from '@/routing/types';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { IPolsatBoxAuthData } from '@/types/user-auth-data.type';

export const emailAutocomplete = (query: IRouter['query'], userModule: UserModule) => {
    const { authProvider } = query;
    const authData = userModule?.getAuthData();

    const IPOLSATBOX = AUTH_PROVIDERS.IPOLSATBOX;

    if (authData?.authProvider === IPOLSATBOX && authProvider === IPOLSATBOX) {
        return (authData as IPolsatBoxAuthData)?.login;
    }
};
