import { useEffect } from 'react';
import { useIncrementColumnContext } from '@/contexts/epg-table/increment-column.context';
import { COLUMNS_PER_PAGE } from '@/constants/epg-table';

export const useIsLastElementFullyVisible = (channelsCount: number, isLastPage: boolean) => {
    const { setIsVeryLastElementFullyVisible } = useIncrementColumnContext();

    useEffect(() => {
        if (isLastPage && channelsCount < COLUMNS_PER_PAGE - 1) {
            setIsVeryLastElementFullyVisible(true);

            return;
        }

        setIsVeryLastElementFullyVisible(false);
    }, [channelsCount, isLastPage, setIsVeryLastElementFullyVisible]);
};
