import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import ChannelList from '@/models/channels/channel-list.model';
import defaultKeyHook from '@/hooks/default-key';
import { GetTvChannelsIn } from '@/interfaces/from-schemas/navigation/getTvChannelsIn';
import { useMemo } from 'react';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import {
    GetMediaPromiseFunc,
    IApiResponse,
    IApiServerResponse,
} from '@/hooks/use-get-media-api.type';
import { GetTvChannelsOut } from '@/interfaces/from-schemas/navigation/getTvChannelsOut';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CHANNEL_LIST;

const getChannelListPromise = (
    params: GetTvChannelsIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<IApiServerResponse<GetTvChannelsOut>> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {});
};

export { getChannelListPromise };

const useGetChannelList = (params: GetTvChannelsIn | false): IApiResponse<ChannelList> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching, refetch } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new ChannelList(data), [data]),
        error,
        isFetching,
        refetch,
    };
};

export default useGetChannelList;
