import Queue from 'promise-queue';

export default class PromiseQueue {
    private readonly maxConcurrent: number = 1;
    private readonly maxQueue: number = Infinity;
    private readonly queue;

    constructor() {
        this.queue = new Queue(this.maxConcurrent, this.maxQueue);
    }

    public add(promiseFunc: () => Promise<any>) {
        return this.queue.add(promiseFunc);
    }
}
