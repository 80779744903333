import { PurchaseButton } from '@/components/shared/PurchaseButton';
import { buildPricingPlansData, redirectToPaymentRules } from '@/helpers/payment.helper';
import { usePaymentsData } from '@/hooks/payments/use-payments-data';
import t from '@/lib/i18n';
import { OfferPricingPlanModel } from '@/models/products/offer-pricing-plan.model';
import { ProductOfferModel } from '@/models/products/product-offer.model';
import React from 'react';
import { OfferContainer } from './styles';
import { SingleOfferProps } from './types';

export const SingleOffer = ({
    offer,
    defaultFocused,
    onSelectOffer,
    product,
}: SingleOfferProps) => {
    const { selectedOfferPricingPlanId } = usePaymentsData();

    const isPricingPlansExists = offer.isPricingPlansExists();
    const offerLabel = isPricingPlansExists
        ? t('payment-steps.regular-price')
        : offer.getName() || t('payment-steps.price');
    const offerPriceLabel = offer.getMinPriceText();
    const offerDuration = offer.getAccessText();
    const offerRightSideLabel = offer.isOfferOrOptionsRulesExists()
        ? t('payment-steps.rules')
        : undefined;
    const trialDuration = offer.getTrialDuration();
    const pricingPlansData = buildPricingPlansData(offer);

    const onSelectRules = (offer: ProductOfferModel, offerPricingPlan?: OfferPricingPlanModel) => {
        const offerId = offer.getId();
        const offerPricingPlanId = offerPricingPlan?.getId();
        redirectToPaymentRules(product.getProductId(), offerId, offerPricingPlanId);
    };
    return (
        <OfferContainer>
            {pricingPlansData.map((pricingPlan, pricingPlanIndex) => {
                const { offerPricingPlan, name, minPrice, isRulesExists, duration } = pricingPlan;
                const pricingPlanRightSideLabel = isRulesExists
                    ? t('payment-steps.promotion-rules')
                    : undefined;
                const offerPricingPlanId = offerPricingPlan.getId();
                const isOfferPricingPlanFocused =
                    defaultFocused || offerPricingPlanId === selectedOfferPricingPlanId;

                return (
                    <PurchaseButton
                        key={pricingPlanIndex}
                        label={name}
                        priceLabel={minPrice}
                        oldPriceLabel={offerPriceLabel}
                        durationLabel={duration}
                        rightSideLabel={pricingPlanRightSideLabel}
                        defaultFocused={isOfferPricingPlanFocused}
                        trialDuration={trialDuration}
                        isPromotion
                        onButtonAction={() =>
                            onSelectOffer(offer.getId(), offerPricingPlan?.getId())
                        }
                        onRightSideAction={() => onSelectRules(offer, offerPricingPlan)}
                    />
                );
            })}

            {offerPriceLabel && !offer.isOfferPricingPlanRequired() && (
                <PurchaseButton
                    label={offerLabel}
                    priceLabel={offerPriceLabel}
                    durationLabel={offerDuration}
                    rightSideLabel={offerRightSideLabel}
                    defaultFocused={defaultFocused && !isPricingPlansExists}
                    trialDuration={trialDuration}
                    onButtonAction={() => onSelectOffer(offer.getId())}
                    onRightSideAction={() => onSelectRules(offer)}
                />
            )}
        </OfferContainer>
    );
};
