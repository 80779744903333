import styled from 'styled-components';
import { Nav } from 'nav-tree';

export const StyledCollectionTitle = styled.div`
    position: relative;
    margin-left: 15rem;
`;

export const StyledTitleInner = styled.div`
    position: absolute;
    width: 100%;
`;

export const StyledCollectionWrapper = styled.div`
    margin: 20% 0;
`;

export const StyledCollectionsList = styled(Nav)`
    width: 100%;
    height: 100%;

    &:nth-child(1) > :last-child {
        margin-bottom: 100%;
    }
`;
