import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import CategoryWithFlatNavigationModel from '@/models/category/category-with-flat-navigation.model';
import defaultKeyHook from '@/hooks/default-key';
import { GetCategoryWithFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getCategoryWithFlatNavigationIn';
import { GetCategoryWithFlatNavigationOut } from '@/interfaces/from-schemas/navigation/getCategoryWithFlatNavigationOut';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CATEGORY_WITH_FLAT_NAVIGATION;

const getCategoryWithFlatNavigationPromise = (
    params: GetCategoryWithFlatNavigationIn,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<GetCategoryWithFlatNavigationOut> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getCategoryWithFlatNavigationPromise };

const useGetCategoryWithFlatNavigation = (
    params: GetCategoryWithFlatNavigationIn | false,
): IApiResponse<CategoryWithFlatNavigationModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new CategoryWithFlatNavigationModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetCategoryWithFlatNavigation;
