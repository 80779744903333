import useGetRules from '@/api/auth/use-get-rules';
import { useGetRulesSource } from '@/api/rules-source/use-get-rules-source';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import ScrollableContainer from '@/components/shared/ScrollableContainer';
import { ScrollPosition } from '@/components/shared/ScrollableContainer/types';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React, { useEffect, useState } from 'react';
import { useConsentDetailsInitialProps } from '@/helpers/router/use-consent-details-initial-props';
import { useError } from '@/contexts/error/error.context';

const ConsentDetails = () => {
    const { setError } = useError();
    const [scrollPosition, setScrollPosition] = useState(ScrollPosition.TOP);
    const { ruleId } = useConsentDetailsInitialProps();
    const { data: rulesData, error } = useGetRules({ rulesIds: [+ruleId!] });
    const rule = rulesData?.[0];
    const sourceUrl = rule?.sources.find(({ type }) => type === 'html' || type === 'txt')?.url;
    const { data } = useGetRulesSource(sourceUrl ? { sourceUrl } : undefined);

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    return (
        <BaseLayout
            defaultBg
            topShade={scrollPosition !== ScrollPosition.TOP}
            bottomShade={scrollPosition !== ScrollPosition.BOTTOM}
        >
            <AccountMenu selectedRoute={ROUTES.CONSENTS_LIST} />
            <ScrollableContainer
                onScroll={setScrollPosition}
                onBack={() => Router.pushRoute(ROUTES.CONSENTS_LIST)}
                showBack
            >
                <Message title={rule?.description} gutter={GUTTER.BIG} />
                {data?.data && <div dangerouslySetInnerHTML={{ __html: data?.data }} />}
            </ScrollableContainer>
        </BaseLayout>
    );
};

export default ConsentDetails;
