import React from 'react';
import { PlayableButton } from '../../shared/Buttons';
import { ButtonContainer, NavButtons } from './styles';
import { IMediaButtons } from './types';

const MediaButtonsRaw = ({ buttons, focusRef, onRightPanel, onButtonAction }: IMediaButtons) => {
    return (
        <NavButtons ref={focusRef} restoreLastFocus>
            {buttons.map((button, index) => {
                return (
                    <ButtonContainer key={button.id}>
                        <PlayableButton
                            navId={button.id}
                            onNav={onRightPanel}
                            icon={button.icon}
                            text={button.text}
                            onButtonAction={() => onButtonAction(button, index)}
                            defaultFocused={index === 0}
                            data-testing={button['data-testing']}
                        />
                    </ButtonContainer>
                );
            })}
        </NavButtons>
    );
};

export const MediaButtons = React.memo(MediaButtonsRaw);
