import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { PlayableButton } from '@/components/shared/Buttons';
import { ICONS } from '@/components/shared/Icon';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import t from '@/lib/i18n';
import { Message } from '@/components/shared/Message';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';

const FormSuccessPage = () => {
    const body = (
        <>
            <Message
                icon={ICONS.INFO_CIRCLE}
                title={t('pages.customer-care.thank-you-for-contact-title')}
                description={[t('pages.customer-care.thank-you-for-contact-description')]}
            />
            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={() => Router.pushRoute(ROUTES.HOME)}
                    defaultFocused
                />
            </FormContainer>
        </>
    );
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};
export default FormSuccessPage;
