import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import CategoryContentModel from '@/models/category/category-content.model';
import { GetCategoryContentIn } from '@/interfaces/from-schemas/navigation/getCategoryContentIn';
import defaultKeyHook from '@/hooks/default-key';
import { useMemo } from 'react';

const namespace = GET_MEDIA_NAMESPACES.NAVIGATION;
const method = GET_MEDIA_METHODS.FETCH_CATEGORY_CONTENT;

const getCategoryContentPromise = (
    params: GetCategoryContentIn,
    getMediaApiPromise: GetMediaPromiseFunc,
) => {
    const keyHook = defaultKeyHook(namespace, method, params);

    return getMediaApiPromise(keyHook, {}).then((res) => res.data);
};

export { getCategoryContentPromise };

const useGetCategoryContent = (
    params: GetCategoryContentIn | false,
): IApiResponse<CategoryContentModel> => {
    const keyHook = defaultKeyHook(namespace, method, params);

    const options = {
        queryOptions: {
            staleTime: Infinity,
        },
    };

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(() => new CategoryContentModel(data), [data]),
        error,
        isFetching,
    };
};

export default useGetCategoryContent;
