import { MyPurchasesListType } from '@/components/views/Account/MyPurchases/types';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { MultipleTypeProductModel } from '@/models/products/multiple-type-product.model';
import { PRODUCT_SUBTYPES } from '@/types/media.type';
import t from '@/lib/i18n';

export const buildMyPurchasesList = (products: MultipleTypeProductModel[]) => {
    const packets: ListElementInterface[] = [];
    const vods: ListElementInterface[] = [];
    const lives: ListElementInterface[] = [];

    if (products?.length > 0) {
        products.forEach((product) => {
            const newProduct = new ListElementModel(product);
            if (product.getSubType() === PRODUCT_SUBTYPES.PACKET) {
                packets.push(newProduct);
            }
            if (product.getSubType() === PRODUCT_SUBTYPES.MOVIE) {
                vods.push(newProduct);
            }
            if (product.getSubType() === PRODUCT_SUBTYPES.LIVE) {
                lives.push(newProduct);
            }
        });

        const myPurchasesList: MyPurchasesListType[] = [
            {
                title: t('pages.account.my-purchases.listTitles.packets'),
                purchases: packets,
            },
            {
                title: t('pages.account.my-purchases.listTitles.vod'),
                purchases: vods,
            },
            {
                title: t('pages.account.my-purchases.listTitles.live'),
                purchases: lives,
            },
        ];

        return myPurchasesList;
    }

    return [];
};
