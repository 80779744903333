import { ROUTES } from '@/routing/types';

export const shouldTrimAccountHistory = (params: Record<string, any>) =>
    !!params.trimAccountHistory;

export const trimHistoryStackAccount = (stack: ROUTES[], prevRoute: ROUTES) => {
    const watchAsIndex = stack.lastIndexOf(ROUTES.PROFILE_WATCH_AS);
    const changeProfileAsIndex = stack.lastIndexOf(ROUTES.ACCOUNT_CHANGE_PROFILE);

    const last = stack.length - 1;
    const checkIndex = watchAsIndex > -1 ? watchAsIndex : changeProfileAsIndex;
    const distance = last - checkIndex;

    if (checkIndex >= 0) {
        const rootIndex = stack.length - distance;

        stack.splice(rootIndex);
        // eslint-disable-next-line no-unused-vars
        prevRoute = stack.slice(-1)[0] || ROUTES.HOME;

        history.go(-distance);

        return;
    }
};

export const shouldTrimPurchaseHistory = (params: Record<string, any>) =>
    !!params.trimPurchaseHistory;

export const trimHistoryStackPurchase = (stack: ROUTES[], prevRoute: ROUTES) => {
    const distance = stack.filter(
        (route) => route === ROUTES.PURCHASE || route === ROUTES.PURCHASE_STATUS,
    ).length;

    const rootIndex = stack.length - distance;

    stack.splice(rootIndex);
    // eslint-disable-next-line no-unused-vars
    prevRoute = stack.slice(-1)[0] || ROUTES.HOME;

    history.go(-distance);

    return;
};

export const trimHistoryStack = (stack: ROUTES[], prevRoute: ROUTES) => {
    const actual = stack[stack.length - 1];
    const packetContent = ROUTES.PACKET_CONTENT;
    const packetDetails = ROUTES.PACKET_DETAILS;
    const subcategory = ROUTES.SUBCATEGORY;

    const episodeWithSeason = ROUTES.VOD_EPISODE_WITH_SEASON_DETAILS;
    const episodeCategory = ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY;
    const episodeDetails = ROUTES.VOD_EPISODE_DETAILS;
    const purchaseStatus = ROUTES.PURCHASE_STATUS;
    const purchase = ROUTES.PURCHASE;
    const isPacketList = packetContent === actual;
    const isSubcategory = subcategory === actual;
    const mediaArr = [episodeWithSeason, episodeCategory, episodeDetails];
    const isMediaDetails = mediaArr.includes(actual);
    const isPurchaseStatus = purchaseStatus === actual;

    let mode;
    switch (true) {
        case isPacketList:
            mode = 'packetList';
            break;
        case isSubcategory:
            mode = 'subcategory';
            break;
        case isMediaDetails:
            mode = 'mediaDetails';
            break;
        case isPurchaseStatus:
            mode = 'purchaseStatus';
            break;
    }

    if (!mode) return;

    const findIndexCb = (route: ROUTES) =>
        route === episodeWithSeason || route === episodeCategory || route === episodeDetails;

    const findPacketIndexCb = (route: ROUTES) => route === packetDetails;

    const findPurchaseIndexCb = (route: ROUTES) => route === purchase;

    const lastCatalogIndex = [...stack].reverse().findIndex(findIndexCb);
    const firstCatalogIndex = stack.findIndex(findIndexCb);
    const firstPacketDetailsIndex = stack.findIndex(findPacketIndexCb);
    const firstPurchaseIndex = stack.findIndex(findPurchaseIndexCb);

    let distance;
    let shouldTrimHistory;
    switch (mode) {
        case 'packetList':
            distance = stack.length - firstPacketDetailsIndex - 1;
            shouldTrimHistory = true;

            break;

        case 'subcategory':
            distance = lastCatalogIndex;
            shouldTrimHistory = true;

            break;

        case 'mediaDetails':
            distance = stack.length - firstCatalogIndex;
            shouldTrimHistory = mediaArr.includes(actual);

            break;

        case 'purchaseStatus':
            distance = stack.length - firstPurchaseIndex;
            shouldTrimHistory = true;

            break;
    }

    if (shouldTrimHistory && distance && distance > 1) {
        distance--;
        const rootIndex = stack.length - distance;

        history.go(-distance);
        stack.splice(rootIndex);
        // eslint-disable-next-line no-unused-vars
        prevRoute = stack.slice(-1)[0] || ROUTES.HOME;

        return;
    }
};
