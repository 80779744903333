import React from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { AccountMenu } from '@/components/views/Account/AccountMenu';
import { ROUTES } from '@/routing/types';
import { navHorizontal, navVertical } from 'nav-tree';
import { NavWrapper, PageContainer } from '@/components/views/Account/styles';
import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { ICONS } from '@/components/shared/Icon';
import { PlayableButton } from '@/components/shared/Buttons';
import { Router } from '@/routing';

const ChangePhoneSuccess = () => {
    return (
        <BaseLayout defaultBg>
            <AccountMenu selectedRoute={ROUTES.PLUS_ZONE} />
            <PageContainer func={navVertical}>
                <Message icon={ICONS.SUCCESS_CIRCLE} title={t('pages.plus-zone.change.success')} />
                <NavWrapper func={navHorizontal}>
                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => {
                            Router.pushRoute(ROUTES.PLUS_ZONE);
                        }}
                        defaultFocused
                    />
                </NavWrapper>
            </PageContainer>
        </BaseLayout>
    );
};

export default ChangePhoneSuccess;
