import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, IUseGetMediaApiOptions } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { PossessionModel } from '@/models/payments/possession.model';
import { GetPossessionsOut } from '@/interfaces/from-schemas/payments/getPossessionsOut';
import { useIsUserLogged } from '@/hooks/user/use-is-user-logged.hook';

export const useGetPossessions = (
    isAlwaysRefetchOnMount = true,
): IApiResponse<PossessionModel[]> => {
    const isLogged = useIsUserLogged();
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.PAYMENTS,
        GET_MEDIA_METHODS.FETCH_POSSESSIONS,
        isLogged ? {} : null,
    );
    const options: IUseGetMediaApiOptions = { appendAuthData: true, disableCache: true };

    if (isAlwaysRefetchOnMount) {
        options.queryOptions = { refetchOnMount: 'always' };
    }

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: data?.map((el: GetPossessionsOut[number]) => new PossessionModel(el)),
        error,
        isFetching,
    };
};
