import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { HOUR_RANGE } from '@/constants/epg-table';
import { FORMAT_DATE_MODE } from '@/types/date.type';
import { formatDate } from '@/helpers/date.helper';
import { getEarliestDay, getLatestDay } from '@/hooks/epg-table/use-day-picker';
import { getCurrentHours } from '@/components/browser/shared/EPG/shared/Nav/date.helper';

interface HourRangeContextProps {
    children: React.ReactNode;
}

interface HourRange {
    startTime: number;
    endTime: number;
}

interface HourRangeContext {
    canScrollBackward: boolean;
    canScrollForward: boolean;
    date: Date;
    dateBarContent: string;
    displayDateBar: boolean;
    fullHour: Date;
    hourRange: HourRange;
    initialStartFullHour: number;
    oneHourFromNow: Date;
    resetContext: () => void;
    setDate: (dateString: string) => void;
    setStartFullHour: (updateFn: (prev: number) => number) => void;
    startFullHour: number;
    twoHoursFromNow: Date;
}

type Bounds = {
    lower: Date;
    upper: Date;
};

const currentHour = getCurrentHours();

const initialContextValue = {
    canScrollBackward: true,
    canScrollForward: true,
    date: new Date(),
    dateBarContent: '',
    displayDateBar: false,
    fullHour: new Date(),
    hourRange: { startTime: 0, endTime: 0 },
    initialStartFullHour: currentHour,
    oneHourFromNow: new Date(),
    resetContext: () => null,
    setDate: () => null,
    setStartFullHour: () => null,
    startFullHour: currentHour,
    twoHoursFromNow: new Date(),
};

const HourRangeContext = createContext<HourRangeContext>(initialContextValue);

const truncateDateToHour = (date: Date) => {
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
};

export const HourRangeContextProvider = ({ children }: HourRangeContextProps) => {
    const initialDate = useMemo(() => new Date(), []);

    const [dateTime, setDateTime] = useState({ date: new Date(), hour: getCurrentHours() });
    const [dateBarContent, setDateBarContent] = useState('');

    const resetContext = useCallback(() => {
        setDateTime({ date: new Date(), hour: getCurrentHours() });
    }, []);

    const bounds: Bounds = useMemo(() => {
        const lowerBoundDate = new Date(getEarliestDay());
        const upperBoundDate = new Date(getLatestDay());

        upperBoundDate.setHours(23, 59, 0, 0);

        return { lower: lowerBoundDate, upper: upperBoundDate };
    }, []);

    const updateDateTime = useCallback(
        (updateFn: (prev: { date: Date; hour: number }) => { date: Date; hour: number }) => {
            const newDateTime = updateFn(dateTime);

            const newDate = new Date(newDateTime.date.getTime());
            newDate.setHours(newDateTime.hour, 0, 0, 0);

            const lowerBoundDate = truncateDateToHour(bounds.lower);
            const upperBoundDate = truncateDateToHour(bounds.upper);

            const newTimestamp = newDate.getTime();
            const lowerTimestamp = lowerBoundDate.getTime();
            const upperTimestamp = upperBoundDate.getTime();

            if (newTimestamp < lowerTimestamp) {
                return;
            }

            if (newDateTime.hour === 23 && newTimestamp > upperTimestamp) {
                return;
            }

            if (newTimestamp > upperTimestamp) {
                newDateTime.date.setDate(newDateTime.date.getDate() - 1);
                newDateTime.hour = 23;
            }

            setDateTime(newDateTime);
        },
        [bounds.lower, bounds.upper, dateTime],
    );

    const updateStartFullHour = useCallback(
        (updateFn: (prev: number) => number) => {
            updateDateTime((prevDateTime) => {
                let newHour = updateFn(prevDateTime.hour);
                const updatedDate = new Date(prevDateTime.date);

                if (newHour < 0) {
                    updatedDate.setDate(updatedDate.getDate() - 1);
                    newHour += 24;
                } else if (newHour >= 24) {
                    updatedDate.setDate(updatedDate.getDate() + 1);
                    newHour -= 24;
                }

                return { date: updatedDate, hour: newHour % 24 };
            });
        },
        [updateDateTime],
    );

    const setDateWithCurrentTime = useCallback(
        (dateString: string) => {
            const newDate = new Date(dateString);

            if (isNaN(newDate.getTime())) {
                throw new Error(`Invalid date string: ${dateString}`);
            }

            const currentDate = new Date();

            newDate.setHours(currentDate.getHours());
            newDate.setMinutes(currentDate.getMinutes());
            newDate.setSeconds(currentDate.getSeconds());
            newDate.setMilliseconds(currentDate.getMilliseconds());

            updateDateTime((prevDateTime) => ({ ...prevDateTime, date: newDate }));
        },
        [updateDateTime],
    );

    const hourRange = useMemo(() => {
        let endTime = dateTime.hour + HOUR_RANGE;
        if (endTime >= 24) {
            endTime -= 24;
        }
        return {
            startTime: dateTime.hour,
            endTime: endTime,
        };
    }, [dateTime.hour]);

    const twoHoursFromNow = useMemo(() => {
        const d = new Date(dateTime.date);
        d.setHours(hourRange.endTime);
        const truncatedDate = truncateDateToHour(d);
        if (hourRange.endTime <= hourRange.startTime) {
            truncatedDate.setDate(truncatedDate.getDate() + 1);
        }
        return truncatedDate;
    }, [dateTime.date, hourRange.endTime, hourRange.startTime]);

    const oneHourFromNow = useMemo(() => {
        const d = new Date(dateTime.date);
        d.setHours(hourRange.startTime + 1);
        return truncateDateToHour(d);
    }, [dateTime.date, hourRange.startTime]);

    const fullHour = useMemo(() => {
        const d = new Date(dateTime.date);
        d.setHours(hourRange.startTime);
        return truncateDateToHour(d);
    }, [dateTime.date, hourRange.startTime]);

    const canScrollBackward = useMemo(() => {
        const previousHourDateTime = new Date(dateTime.date);
        previousHourDateTime.setHours(dateTime.hour - 1);
        previousHourDateTime.setMinutes(0, 0, 0);

        const lowerBoundTruncated = new Date(bounds.lower);
        lowerBoundTruncated.setMinutes(0, 0, 0);

        return previousHourDateTime > lowerBoundTruncated;
    }, [dateTime, bounds.lower]);

    const canScrollForward = useMemo(() => {
        const nextHourDateTime = new Date(dateTime.date.getTime());
        nextHourDateTime.setHours(dateTime.hour + 1, 0, 0, 0);

        const upperBoundDate = new Date(bounds.upper.getTime());

        if (
            (dateTime.hour === 23 && nextHourDateTime.getTime() >= upperBoundDate.getTime()) ||
            nextHourDateTime.getTime() >= bounds.upper.getTime()
        ) {
            return false;
        }

        return true;
    }, [dateTime, bounds.upper]);

    const initialStartFullHour = useMemo(() => initialDate.getHours(), [initialDate]);

    const shouldDisplayDateBar =
        dateTime.date.getDate() !== twoHoursFromNow.getDate() && hourRange.endTime !== 0;

    useEffect(() => {
        if (!shouldDisplayDateBar) {
            setDateBarContent('');
            return;
        }

        const dateString = twoHoursFromNow.toISOString();
        const formattedDate = formatDate(dateString, FORMAT_DATE_MODE.LIVE_ARABIC_FORMAT);
        setDateBarContent(formattedDate);
    }, [twoHoursFromNow, shouldDisplayDateBar]);

    return (
        <HourRangeContext.Provider
            value={{
                canScrollBackward,
                canScrollForward,
                date: dateTime.date,
                dateBarContent,
                displayDateBar: shouldDisplayDateBar,
                fullHour,
                hourRange,
                initialStartFullHour,
                oneHourFromNow,
                resetContext,
                setDate: setDateWithCurrentTime,
                setStartFullHour: updateStartFullHour,
                startFullHour: dateTime.hour,
                twoHoursFromNow,
            }}
        >
            {children}
        </HourRangeContext.Provider>
    );
};

export function useHourRangeContext() {
    const context = useContext(HourRangeContext);
    if (context === undefined) {
        throw new Error('useHourRangeContext must be used within a HourRangeContextProvider');
    }
    return context;
}
