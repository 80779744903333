import { useUserModule } from '@/contexts/user-module/user-module.hooks';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { useEffect } from 'react';

export const useNotFound = ({ notFound }: { notFound: boolean }) => {
    const isDuringAutoLogin = useUserModule().isDuringAutoLogin();

    useEffect(() => {
        if (isDuringAutoLogin) return;

        if (notFound) {
            Router.replaceRoute(ROUTES.NOT_FOUND);
        }
    }, [notFound, isDuringAutoLogin]);
};
