import { appConfig } from '@/configs/app.config';
import { Platform } from '@/services/platform-recognition';
import { GM_DEVICE_TYPE, GM_OS } from '@/types/platform.type';
import { normalizeUrl, replaceHostName } from '@/utils/normalize-url.util';
import { DEVICE_TYPE, PLATFORM } from '@/stats';

export const mapDeviceTypeForStats = (deviceType: GM_DEVICE_TYPE | undefined): DEVICE_TYPE => {
    switch (deviceType) {
        case GM_DEVICE_TYPE.Phone:
            return DEVICE_TYPE.PHONE;
        case GM_DEVICE_TYPE.Mobile:
            return DEVICE_TYPE.TABLET;
        case GM_DEVICE_TYPE.PC:
            return DEVICE_TYPE.PC;
        case GM_DEVICE_TYPE.Smart_TV:
        case GM_DEVICE_TYPE.TV_Alliance:
        case GM_DEVICE_TYPE.TV_Hisense:
        case GM_DEVICE_TYPE.TV_LG:
        case GM_DEVICE_TYPE.TV_Samsung:
        case GM_DEVICE_TYPE.TV_Panasonic:
        case GM_DEVICE_TYPE.TV_Philips:
        case GM_DEVICE_TYPE.TV_Sony:
        case GM_DEVICE_TYPE.TV_Toshiba:
            return DEVICE_TYPE.TV;
        case GM_DEVICE_TYPE.STB_CP:
        case GM_DEVICE_TYPE.UPC:
            return DEVICE_TYPE.STB;
        default:
            return DEVICE_TYPE.UNKNOWN;
    }
};

export const mapPlatformForStats = (platform: Platform): PLATFORM | string => {
    switch (platform.os) {
        case GM_OS.Android:
        case GM_OS.Chromecast:
            return PLATFORM.ANDROID;
        case GM_OS.OSX:
        case GM_OS.MacOS:
        case GM_OS.iOS:
            return PLATFORM.IOS;
    }

    switch (platform.deviceType) {
        case GM_DEVICE_TYPE.PC:
            return PLATFORM.WWW;
        case GM_DEVICE_TYPE.TV_Sony:
            return PLATFORM.SONY;
        case GM_DEVICE_TYPE.TV_Samsung:
            return PLATFORM.SAMSUNG;
        case GM_DEVICE_TYPE.TV_Panasonic:
            return PLATFORM.PANASONIC;
        case GM_DEVICE_TYPE.TV_LG:
            return PLATFORM.LG;
        case GM_DEVICE_TYPE.STB_Netia:
        case GM_DEVICE_TYPE.TV_Netrange:
            return PLATFORM.NETIA;
        case GM_DEVICE_TYPE.TV_Philips:
            return PLATFORM.PHILIPS;
        default:
            return platform.deviceType || 'unknown';
    }
};

export const getYagServiceUrl = (url: string): string => {
    return process.env.NODE_ENV === 'production'
        ? normalizeUrl(url)
        : replaceHostName(url, appConfig.yagStaging);
};
