import {
    IActivationAvailability,
    IGetOrderForPurchaseCode,
    IPurchaseCode,
} from '@/api/payments/types';
import useGetPurchaseCodeData from '@/api/payments/use-get-purchase-code-data';
import useGetPurchaseCodeProduct from '@/api/navigation/use-get-purchase-code-product';
import useGetOrderId from '@/api/payments/use-get-order-id';
import { GetPurchaseCodeProductOut } from '@/interfaces/from-schemas/navigation/getPurchaseCodeProductOut';
import useSubmitPurchaseCode from '@/api/payments/use-submit-purchase-code';
import { GetOrderIdOut } from '@/interfaces/from-schemas/payments/getOrderIdOut';
import ResponseErrorModel from '@/models/response-error.model';

const prepareOrderIdParams = (
    productData?: GetPurchaseCodeProductOut,
): IGetOrderForPurchaseCode | undefined => {
    if (productData && productData.offers && productData.offers[0].options) {
        const offer = productData.offers[0];
        const option = productData.offers[0].options[0];
        return {
            product: {
                id: productData.id,
                type: productData.type,
                subType: productData.subType,
            },
            offer: {
                id: offer.id,
                type: offer.type,
            },
            option: {
                id: option.id,
                type: 'code',
            },
        };
    }
};

const prepareSubmitPurchaseParams = (
    purchaseCode: string,
    orderIdRequest?: IGetOrderForPurchaseCode,
    orderIdOut?: GetOrderIdOut,
) => {
    if (orderIdRequest && orderIdOut?.orderId) {
        const { orderId } = orderIdOut;
        return { ...orderIdRequest, orderId, purchaseCode };
    }
};

const mapToResponseErrorModel = ({
    status,
    statusDescription,
    statusUserMessage,
}: IActivationAvailability): ResponseErrorModel =>
    new ResponseErrorModel({
        code: status,
        data: {
            code: status,
            message: statusDescription,
            userMessage: statusUserMessage ?? '',
            type: '',
            messageId: `${status}`,
            existingAccounts: [],
            email: '',
            rulesType: '',
            authToken: '',
            verificationToken: '',
        },
    });

const useActivateCode = (params: IPurchaseCode) => {
    const purchaseCode = params.purchaseCode;

    //Get code data
    const getCodeDataHook = useGetPurchaseCodeData(params);

    const activationError =
        getCodeDataHook.data?.status === 'used'
            ? mapToResponseErrorModel(getCodeDataHook.data.activationAvailability)
            : undefined;
    //Get code product
    const getCodeProductHook = useGetPurchaseCodeProduct(
        getCodeDataHook.data?.status === 'used' ? undefined : params,
    );

    //Get orderID
    const orderIdParams = prepareOrderIdParams(getCodeProductHook.data);
    const getOrderIdHook = useGetOrderId(orderIdParams);

    //Submit order
    const submitParams = prepareSubmitPurchaseParams(
        purchaseCode,
        orderIdParams,
        getOrderIdHook.data,
    );
    const submitCodeHook = useSubmitPurchaseCode(submitParams);

    return {
        data: submitCodeHook.data,
        error:
            getCodeDataHook.error ??
            activationError ??
            getCodeProductHook.error ??
            getOrderIdHook.error ??
            submitCodeHook.error,
        isFetching:
            getCodeDataHook.isFetching ||
            getCodeProductHook.isFetching ||
            getOrderIdHook.isFetching ||
            submitCodeHook.isFetching,
    };
};

export default useActivateCode;
