import useGetProfiles from '@/api/auth/use-get-profiles';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import { ChangeProfileContainer } from '@/components/views/Profile/ChangeProfile';

export const SelectToEdit = () => {
    const { data: profiles, error: profilesError } = useGetProfiles();

    const errorToDisplay = profilesError?.getUserMessage();
    const body = <ChangeProfileContainer profiles={profiles} />;

    return <FullscreenNavContainer body={body} fullBody={true} error={errorToDisplay} />;
};
