import styled from 'styled-components';
import { device } from '@/targets/default/mediaQuery';

export const ErrorDescriptionContainer = styled.div<{ errorTextColor?: boolean }>`
    font-size: 1.3rem;
    line-height: ${({ theme }) => theme.message.descriptionLineHeight};
    color: ${({ theme, errorTextColor }) =>
        errorTextColor ? theme.text.color.error : theme.message.descriptionColor};
    white-space: pre-line;

    @media ${device.tablet} {
        font-size: ${({ theme }) => theme.message.descriptionSize};
    }

    svg {
        height: 200px;
        width: 200px;
        margin: 20px auto 10px;
    }
`;

export const ErrorDescriptionMessage = styled.p`
    margin: 0;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }
`;
