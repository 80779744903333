import { OfferPricingPlanSchema } from '@/interfaces/from-schemas/navigation/getProductOut';
import { PricingPlanModel } from './pricing-plan.model';

export class OfferPricingPlanModel {
    private id: OfferPricingPlanSchema['id'];
    private minPriceIncludingPricingPlans: OfferPricingPlanSchema['minPriceIncludingPricingPlans'];
    private pricingPlan: PricingPlanModel;

    constructor(definition: OfferPricingPlanSchema) {
        this.id = definition.id;
        this.minPriceIncludingPricingPlans = definition.minPriceIncludingPricingPlans;
        this.pricingPlan = new PricingPlanModel(definition.pricingPlan);
    }

    public getId(): string {
        return this.id;
    }

    public getMinPriceText(): string | undefined {
        return this.minPriceIncludingPricingPlans?.valueTextIncludingPricingPlans;
    }

    public getMinPriceValue(): number | undefined {
        return this.minPriceIncludingPricingPlans?.valueIncludingPricingPlans;
    }

    public getPricingPlan(): PricingPlanModel {
        return this.pricingPlan;
    }
}
