import { setModelByContentOutType } from '@/helpers/set-model-by-content-out-type.helper';
import { LiveChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getLiveChannelsOut';
import { TvChannelListItemSchema } from '@/interfaces/from-schemas/navigation/getTvChannelsOut';
import {
    SearchContentOut,
    CategorySchema,
    MediaListItemSchema,
    CatchUpSchema,
} from '@/interfaces/from-schemas/navigation/searchContentOut';
import { ContentModelType } from '@/types/content-model.type';

export class SearchContentModel {
    private results: ContentModelType;
    private offset: SearchContentOut['offset'];
    private count: SearchContentOut['count'];
    private limit: SearchContentOut['limit'];
    private total: SearchContentOut['total'];

    constructor(definition: SearchContentOut) {
        this.offset = definition.offset;
        this.count = definition.count;
        this.limit = definition.limit;
        this.total = definition.total;

        this.results = [];
        definition.results.forEach(
            (
                value:
                    | CategorySchema
                    | MediaListItemSchema
                    | TvChannelListItemSchema
                    | LiveChannelListItemSchema
                    | CatchUpSchema,
            ) => {
                return this.results.push(setModelByContentOutType(value));
            },
        );
    }

    public getResults(): ContentModelType {
        return this.results;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getCount(): number {
        return this.count;
    }

    public getLimit(): number | null {
        if (this.limit && this.limit >= 0) {
            return this.limit;
        }

        return null;
    }

    public getTotal(): number | null {
        if (this.total && this.total >= 0) {
            return this.total;
        }

        return null;
    }
}
