import { useEffect, useRef, useState } from 'react';

export function useFadingState<S>(incoming?: S, timeout = 5000) {
    const stateReturnValue = useState<S | undefined>(incoming);
    const [state, setState] = stateReturnValue;
    const ref = useRef<any | null>(null);

    useEffect(() => {
        setState(incoming);
    }, [incoming]);

    useEffect(() => {
        if (ref.current) {
            return () => {
                clearTimeout(ref.current);
            };
        }

        if (state !== undefined) {
            ref.current = setTimeout(() => {
                setState(undefined);
                ref.current = null;
            }, timeout);
        }
    }, [state]);
    return stateReturnValue;
}
