import React, { useCallback } from 'react';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { PlayableButton } from '../Buttons';
import { ElementProps } from '../CollectionsList/types';
import { Copyright, StyledPromoboxElement } from './styles';

const PromoboxElementRaw = ({
    itemData,
    setActiveElement,
    defaultFocused,
    index,
    customData,
    'data-testing': dataTesting,
    onItemClickedHandler,
}: ElementProps) => {
    const onNav = useCallback((isActive: boolean) => {
        if (isActive) {
            setActiveElement?.(itemData, index);
        }
    }, []);

    const onButtonAction = () => {
        onItemClickedHandler?.();
        return itemData.goToDetails();
    };

    return (
        <StyledPromoboxElement data-testing={dataTesting}>
            <PlayableButton
                defaultFocused={defaultFocused}
                onNav={onNav}
                onButtonAction={onButtonAction}
                text={customData?.ctaButtonLabel || t('show')}
                style={{
                    display: 'inline-block',
                    margin: '0rem 1.6rem .8rem 1.6rem',
                }}
                gutter={GUTTER.NONE}
                data-testing="watch.show"
            />
            {customData?.legal && <Copyright> &#169; {customData.legal}</Copyright>}
        </StyledPromoboxElement>
    );
};

export const PromoboxElement = React.memo(PromoboxElementRaw);
