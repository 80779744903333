// TODO: Error handling

import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import { RequestLoginOut } from '@/interfaces/from-schemas/auth/requestLoginOut';

export const useRequestLogin = (): IApiResponse<RequestLoginOut> => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.AUTH, GET_MEDIA_METHODS.REQUEST_LOGIN, {});

    const { data, error, isFetching } = useGetMediaApi<RequestLoginOut>(keyHook, {
        disableCache: true,
    });

    return { data, error, isFetching };
};
