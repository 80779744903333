import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse, IUseGetMediaApiOptions } from '@/hooks/use-get-media-api.type';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { ProductSubscriptionModel } from '@/models/payments/product-subscription.model';
import { GetProductSubscriptionsOut } from '@/interfaces/from-schemas/payments/getProductSubscriptionsOut';
import { useMemo } from 'react';
import { useIsUserLogged } from '@/hooks/user/use-is-user-logged.hook';

const namespace = GET_MEDIA_NAMESPACES.PAYMENTS;
const method = GET_MEDIA_METHODS.FETCH_PRODUCT_SUBSCRIPTIONS;

export const useGetProductSubscriptions = (
    isAlwaysRefetchOnMount = true,
): IApiResponse<ProductSubscriptionModel[]> => {
    const isLogged = useIsUserLogged();
    const keyHook = defaultKeyHook(namespace, method, isLogged ? {} : null);

    const options: IUseGetMediaApiOptions = { appendAuthData: true, disableCache: true };

    if (isAlwaysRefetchOnMount) {
        options.queryOptions = { refetchOnMount: 'always' };
    }

    const { data, error, isFetching } = useGetMediaApi(keyHook, options);

    return {
        data: useMemo(
            () =>
                data?.map(
                    (el: GetProductSubscriptionsOut[number]) => new ProductSubscriptionModel(el),
                ) || [],
            [data],
        ),
        error,
        isFetching,
    };
};
