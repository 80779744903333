import { useGetOrderStatus } from '@/api/payments/use-get-order-status';
import {
    calculateStatusRefetchDelay,
    INITIAL_STATUS_REFETCH_DELAY,
    isAvailableToRefetchStatus,
    statusToOrderStatus,
    statusToPaymentStatus,
} from '@/helpers/payment.helper';
import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { useState } from 'react';

export const useCheckOrderStatus = (orderId?: string) => {
    const [refetchDelay, setRefetchDelay] = useState(INITIAL_STATUS_REFETCH_DELAY);
    const params: any = orderId ? { orderId } : undefined;
    const { data, isFetching, refetch } = useGetOrderStatus(params);
    const orderStatus = statusToOrderStatus(data?.status);
    const paymentStatus = statusToPaymentStatus(data?.status);
    const possessionId = data?.possessionId;

    useDebouncedEffect(
        () => {
            if (!isFetching && isAvailableToRefetchStatus(paymentStatus)) {
                refetch?.();
                setRefetchDelay(calculateStatusRefetchDelay);
            }
        },
        { timeout: refetchDelay },
        [isFetching, paymentStatus],
    );

    return { orderStatus, paymentStatus, possessionId };
};
