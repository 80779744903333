import { useTimeshiftingDelta } from './use-timeshifting-delta';

export const useIsLive = () => {
    const { calculateDeltaTime } = useTimeshiftingDelta();
    const deltaTime = calculateDeltaTime();

    return {
        live: Math.abs(deltaTime) < process.env.player.seek.isLiveDelta,
    };
};
