import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import { useSessionProfile } from '@/contexts/profile/profile.hooks';
import { ReferrerContext } from '@/contexts/referrer/referrer.context';
import { useBackAction } from '@/hooks/use-back-action';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { GUTTER } from '@/types/gutter.type';
import React, { useContext } from 'react';

const RemoveProfileSuccess = () => {
    const { referrer } = useContext(ReferrerContext);
    const [sessionProfile] = useSessionProfile();

    useBackAction(() => null);

    const handleButtonAction = () => {
        if (sessionProfile) {
            const route = referrer || ROUTES.PROFILE_SELECT_TO_EDIT;
            return Router.replaceRoute(route, { trimAccountHistory: true });
        }

        Router.pushRoute(ROUTES.PROFILE_WATCH_AS);
    };

    const body = (
        <>
            <Message
                icon={ICONS.SUCCESS_CIRCLE}
                title={t('pages.profile.remove.description-success')}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={handleButtonAction}
                    defaultFocused
                />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default RemoveProfileSuccess;
