import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import SignInWithFacebookModal from '@/components/shared/SignInWithFacebook';
import t from '@/lib/i18n';
import { useState } from 'react';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { useFacebookLogin } from '@/hooks/auth/use-facebook-login.hook';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import { ROUTES } from '@/routing/types';
import { Router } from '@/routing';

const FB_ERROR_CODE_PENDING = 31;

const LoginFacebook = () => {
    const [fbModalOpen, setFbModalOpen] = useState(false);
    const { facebookLogin, isFetching, loginError } = useFacebookLogin();

    if (!process.env.facebook) {
        return null;
    }

    const { APP_ID, CLIENT_TOKEN, permissions } = process.env.facebook;

    const body = (
        <>
            <BackButton onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_BY_REMOTE)} />
            <SignInWithFacebookModal
                appId={APP_ID}
                clientToken={CLIENT_TOKEN}
                permissions={permissions}
                isOpen={fbModalOpen}
                onSuccess={async (data) => {
                    const isPending = data?.error?.code === FB_ERROR_CODE_PENDING;

                    if (!isPending) {
                        facebookLogin(data.access_token);
                    }
                }}
                onCancel={() => {
                    setFbModalOpen(false);
                }}
            />
            <Message
                title={t('pages.login.facebook.header')}
                description={t('pages.login.facebook.description')}
                icon={ICONS.INFO_CIRCLE}
            />
            {!fbModalOpen && (
                <FormContainer>
                    <PlayableButton
                        defaultFocused
                        text={t('ok-button-label')}
                        onButtonAction={() => setFbModalOpen(true)}
                    />
                </FormContainer>
            )}
        </>
    );
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer
                footer={t('GDPR').concat(t('GDPR-suffix'))}
                footerLines={5}
                body={body}
                error={!isFetching ? loginError?.getUserMessage() : undefined}
            />
        </BaseLayout>
    );
};

export default LoginFacebook;
