import useCheckProductAccess from '@/api/drm/use-check-product-access';
import { useGetCategory } from '@/api/navigation/category/get-category';
import useGetCategoryAutoplayMediaId from '@/api/navigation/category/get-category-content-autoplay-media-id';
import useGetChannelsCurrentProgram from '@/api/navigation/channels/use-get-channel-list-current-program';
import useGetMedia from '@/api/navigation/use-get-media';
import { useGetPrePurchaseData } from '@/api/payments/use-get-pre-purchase-data';
import { useGetLatelyWatchedContentDataList } from '@/api/user-content/use-get-lately-watched-content-data-list';
import { CHANNELS_PROGRAM_LIMIT } from '@/configs/channels.config';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { useFavoritesContext } from '@/contexts/favorites/favorites.context';
import { usePlatform } from '@/contexts/platform/platform.hooks';
import { buildMediaDetailsModel } from '@/helpers/media.helper';
import { MediaDetailsPageProps } from '@/interfaces/media-details.interface';
import { ChronologicalNonChronologicalModel } from '@/models/category/chronologicalNonChronological.model';
import { CPID } from '@/types/media.type';
import { useEffect, useState } from 'react';
import { useNotFound } from '../use-not-found.hook';
import { useRouterQuery } from '../use-router-query.hook';
import useGetTvChannelProgramItem from '@/api/navigation/use-get-tv-channel-program-item';
import { useChatContext } from '@/contexts/chat/chat.context';

export interface VodDataQueryProps {
    categoryId?: string;
    vodId?: string;
    id?: string;
    eventId?: string;
    tvProgramId?: string;
}

export const useBuildVodData = (
    props?: MediaDetailsPageProps & { categoryId?: string; mediaId?: string },
    cpid = CPID.VOD_OR_MOVIE,
    fetchProgramTv = false,
    fetchTvProgramItem = false,
) => {
    const { isBot } = usePlatform();
    const [loaded, setLoaded] = useState<boolean>(false);
    const query = useRouterQuery<VodDataQueryProps>();
    const { setChatVisible } = useChatContext();
    const categoryId = props?.categoryId || query.categoryId;

    //** getTvChannelProgramItem - Future EPG **//
    const programTvItemProps = fetchTvProgramItem
        ? { tvChannelProgramItemId: query.tvProgramId }
        : undefined;
    const {
        data: tvProgramItem,
        isFetching: isProgramTvItemFetching,
        error: tvProgramItemError,
    } = useGetTvChannelProgramItem(programTvItemProps);
    const firstChannelId = tvProgramItem?.channelIds?.[0]?.id;
    //

    //**  getCategoryContentAutoplayMediaId **//
    const categoryAutoplayParams = categoryId ? { catid: +categoryId } : undefined;
    const { data: categoryData } = useGetCategoryAutoplayMediaId(categoryAutoplayParams);
    //

    //**  getMedia **//
    const mediaId =
        props?.mediaId ||
        categoryData?.id ||
        query.vodId ||
        query.id ||
        query.eventId ||
        firstChannelId;

    const mediaParams = mediaId
        ? {
              mediaId,
              cpid: cpid as 0 | 1 | 11,
          }
        : undefined;

    const { data: media, isFetching: isFetchingMedia, error: mediaError } = useGetMedia(
        mediaParams,
    );
    //

    //**  getChannelsCurrentProgram **//
    const channelsParams = fetchProgramTv &&
        !!mediaId && {
            channelIds: [mediaId],
            limit: CHANNELS_PROGRAM_LIMIT,
        };
    const { data: channelsProgram, error: channelsProgramError } = useGetChannelsCurrentProgram(
        channelsParams,
    );
    //

    const mediaProduct = props?.media?.product || media.getProduct();

    //**  checkProductAccess **//
    const product = media.getProduct() || props?.prePurchaseData?.product;
    const productParams = product && mediaProduct ? { product } : undefined;
    const {
        data: checkProductAccess,
        isFetching: isFetchingAccess,
        error: checkProductAccessError,
    } = useCheckProductAccess(productParams);
    //

    //**  getPrePurchaseData **//
    const prePurchaseDataParams =
        productParams && mediaProduct
            ? {
                  ...productParams,
                  withoutUserPaymentsData: true,
                  withoutMinPriceProducts: true,
              }
            : undefined;
    const {
        data: prePurchaseData,
        isFetching: isFetchingPrepurchase,
        error: prePurchaseDataError,
    } = useGetPrePurchaseData(prePurchaseDataParams);
    //

    const preparedData = { ...props };

    if (media) Object.assign(preparedData, { media });
    if (fetchProgramTv) Object.assign(preparedData, { channelsProgram });
    if (fetchTvProgramItem) Object.assign(preparedData, { tvProgramItem });
    if (!!mediaProduct && !!checkProductAccess) Object.assign(preparedData, { checkProductAccess });
    if (!!mediaProduct && !!prePurchaseData) Object.assign(preparedData, { prePurchaseData });

    const mediaModel = buildMediaDetailsModel(preparedData);

    //**  getLatelyWatchedContentDataList **//
    const catid = Number(categoryId) || mediaModel?.mainCategoryId;
    const { data: fmc } = useGetLatelyWatchedContentDataList(catid);
    //

    //**  getCategory **//
    const categoryParams =
        catid && mediaProduct?.subType !== 'movie' && mediaProduct?.subType !== 'live'
            ? { catid }
            : undefined;
    const { data: category, isFetching: isFetchingCategory, error: categoryError } = useGetCategory(
        categoryParams,
    );
    //

    //** getFavoritesWithFlatNavigation **/
    const { favorites, isFetching: isFetchingFav } = useFavoritesContext();

    if (mediaModel && !isFetchingFav) {
        const isInFavorites = !!favorites?.isInFavorites(mediaModel);
        mediaModel.isInFavorites = isInFavorites;
    }
    //

    useEffect(() => {
        setLoaded(!!mediaModel);
    }, [mediaModel, fmc]);

    const mediaNotFound = mediaError?.getCode() === ERROR_CODES_GET_MEDIA.B2C_MEDIA_NOT_FOUND;
    const notFoundMediaModel = !mediaModel && loaded && !isFetchingMedia;
    const notFoundCategory = !!categoryParams && loaded && !isFetchingCategory && !category;
    // Get media może nie zwrócić channelIds bądź channelIds może być pustą tablicą, w takim
    // przypadku strona nie może zostać wygenerowana póki co ze względu na brak danych potrzebnych
    // do wygenerowania (brak odpowiedzi z zapytania getMedia o kanał), poniższy warunek w takim
    // przypadku przekieruje na 404
    const redirectTvProgramItem = fetchTvProgramItem && !mediaParams;

    useNotFound({
        notFound: mediaNotFound || notFoundMediaModel || notFoundCategory || redirectTvProgramItem,
    });

    let cnc;

    if (fmc && category && mediaModel) {
        cnc = new ChronologicalNonChronologicalModel(
            mediaModel,
            category,
            props?.showCategoryData || !!categoryId,
            !fmc[0],
        );

        mediaModel.isChronological = cnc.isChronological;
    }

    const isFetching =
        isFetchingMedia ||
        isFetchingAccess ||
        isFetchingCategory ||
        isFetchingPrepurchase ||
        isProgramTvItemFetching;
    const error =
        tvProgramItemError ||
        mediaError ||
        checkProductAccessError ||
        prePurchaseDataError ||
        categoryError ||
        channelsProgramError;

    useEffect(() => {
        setChatVisible(!checkProductAccess?.hasAccess());
    }, [checkProductAccess, setChatVisible]);

    useEffect(() => {
        setChatVisible(!checkProductAccess?.hasAccess());
    }, [checkProductAccess, setChatVisible]);

    return {
        // hook useNotFound wykona redirect jeśli mediaModel będzie falsy
        media: mediaModel!,
        category,
        fmc,
        isFetching: isBot ? false : !loaded || isFetching,
        cnc,
        error,
    };
};
