import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import FullscreenNavContainer, {
    FormContainer,
    MediaButtonsWrapper,
} from '@/components/shared/FullscreenNavContainer';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { PlayableButton } from '@/components/shared/Buttons';
import {
    withApple,
    withFacebook,
    withIpolsatbox,
    withProviders,
    withSocialMediaProviders,
} from '@/helpers/auth/auth-providers.helper';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { redirectToLogin } from '@/helpers/redirect-to-login.helper';
import { ICONS } from '@/components/shared/Icon';
import { BackButton } from '@/components/shared/Buttons/BackButton';

const LoginByRemote = () => {
    const body = (
        <>
            <BackButton onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_BY_CODE)} />
            <MainText
                text={t('pages.welcome.header')}
                mode={MAINTEXT_KIND.HEADER}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    defaultFocused
                    text={t('pages.welcome.sign-in')}
                    onButtonAction={redirectToLogin}
                    gutter={withProviders ? GUTTER.NORMAL : GUTTER.BIG}
                    data-testing="login-standard"
                />

                {withIpolsatbox && (
                    <PlayableButton
                        text={t('pages.login.ipolsatbox.button-label')}
                        onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_IPOLSATBOX)}
                        gutter={GUTTER.NORMAL}
                        data-testing="login-facebook"
                    />
                )}

                {withSocialMediaProviders && (
                    <MediaButtonsWrapper restoreLastFocus>
                        {withFacebook && (
                            <PlayableButton
                                icon={ICONS.FACEBOOK}
                                onButtonAction={() => Router.pushRoute(ROUTES.LOGIN_FACEBOOK)}
                                data-testing="login-facebook"
                            />
                        )}
                        {withApple && (
                            <PlayableButton
                                icon={ICONS.APPLE}
                                onButtonAction={() => console.log('apple login')}
                            />
                        )}
                    </MediaButtonsWrapper>
                )}
            </FormContainer>

            <MainText
                text={t('pages.welcome.no-account-instruction')}
                mode={MAINTEXT_KIND.DESCRIPTION}
            />
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <FullscreenNavContainer body={body} bodyWidth={80} />
        </BaseLayout>
    );
};

export default LoginByRemote;
