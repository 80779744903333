import { isServer } from '@/helpers/environment.helper';
import { setStbDecoderId, recognizeStbPlatform } from '@/services/stb/getStbConfig';
import { Platform } from './types';
import { recognizeOS } from './recognize-os';
import { recognizeApplication } from './recognize-application';
import { recognizeDeviceType } from './recognize-device-type';
import { isStbTargets } from '@/constants/portal-recognition';

export const recognizePlatform = async (userAgent: string): Promise<Platform> => {
    if (isStbTargets) {
        const stbPlatform = await recognizeStbPlatform();
        await setStbDecoderId(stbPlatform);

        return stbPlatform;
    }
    if (!userAgent) {
        return {
            browserUserAgent: undefined,
            os: undefined,
            application: undefined,
            deviceType: undefined,
            model: 'unknown',
        };
    }
    const os = recognizeOS(userAgent);
    return {
        browserUserAgent: userAgent,
        os,
        application: recognizeApplication(userAgent, os),
        deviceType: recognizeDeviceType(userAgent, os),
        model: getDeviceModel(),
    };
};

let lazyPlatform: Platform;

const getDeviceModel = (): string => {
    if (!isServer) {
        // @ts-ignore
        const webapis = window['webapis'];

        if (webapis && webapis.productinfo) {
            return webapis.productinfo.getRealModel();
        }
    }

    return 'unknown';
};

export const getNavigatorPlatform = async () => {
    if (!lazyPlatform) {
        lazyPlatform = await recognizePlatform(
            typeof window !== 'undefined' ? (window as any)?.navigator?.userAgent : '',
        );
    }
    return lazyPlatform;
};
