import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import React from 'react';
import { BackButton } from '@/components/shared/Buttons/BackButton';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { FormWithQr } from '@/components/views/CustomerCare/FormWithQr/FormWithQr';

const ContactWithUs = () => {
    const body = (
        <>
            <BackButton />
            <FormWithQr />
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.NONE}>
            <FullscreenNavContainer body={body} fullBody />
        </BaseLayout>
    );
};

export default ContactWithUs;
