import React, { useCallback } from 'react';
import { VirtualScrollHorizontalSlider } from '@/components/shared/VirtualScrollHorizontalSlider';
import { ChannelProgramTvModel } from '@/models/channels/channel-program-tv.model';
import { EPGTile } from './EPGTile';
import { EPGSliderPresentationProps, EPGItemProps } from './types';
import { epgPositionToNavId } from './helpers';
import { EPG_SLIDER_WINDOW_WIDTH } from './consts';
import { TileContainer } from './styles';

const EPGSliderPresentationRaw = ({
    programs,
    loading,
    navId,
    onNav,
    onJump,
    onIsPlayable,
    onIsNowPlaying,
}: EPGSliderPresentationProps) => {
    const renderItem = useCallback(
        (program: ChannelProgramTvModel, props: EPGItemProps, index: number) => {
            const startTimestamp = program.getStartTimeTimestamp();
            const dateNow = new Date().getTime();
            const isEarlierAndNotPlayable = startTimestamp < dateNow && !onIsPlayable(program);

            if (isEarlierAndNotPlayable) return;

            return (
                <TileContainer {...props} onEnterDown={onJump(program)}>
                    <EPGTile
                        program={program}
                        playable={onIsPlayable(program)}
                        nowPlayed={onIsNowPlaying(program, index)}
                    />
                </TileContainer>
            );
        },
        [onJump, onIsPlayable, onIsNowPlaying],
    );

    return (
        <VirtualScrollHorizontalSlider<ChannelProgramTvModel>
            height="44.4rem"
            isDefault={onIsNowPlaying}
            itemToNavId={epgPositionToNavId}
            navId={navId}
            onNav={onNav}
            items={programs}
            windowWidth={EPG_SLIDER_WINDOW_WIDTH}
            loading={loading}
            renderItem={renderItem}
        />
    );
};

export const EPGSliderPresentation = React.memo(EPGSliderPresentationRaw);
