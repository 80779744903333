import React from 'react';

import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { LoginNativeBody } from '@/components/shared/LoginNativeBody';

const LoginNative = () => {
    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            <LoginNativeBody />
        </BaseLayout>
    );
};

export default LoginNative;
