import { PlayableButton } from '@/components/shared/Buttons';
import { FilterListItem } from '@/components/shared/Filter/FilterListItem';
import { Icon, ICONS } from '@/components/shared/Icon';
import { useNavScroll } from '@/hooks/nav-tree/use-nav-scroll.hook';
import t from '@/lib/i18n';
import { IMPERATIVE_SCROLL_DIRECTION, NAV_SCROLL_DIRECTION, navVertical } from 'nav-tree';
import React from 'react';
import {
    ButtonNavWrapper,
    FilterListRootContainer,
    FilterListWrapperStyledComponent,
    FilterName,
    IconWrapper,
    RightOpenFilterStyled,
} from './styles';
import { Filter, FilterDataInterface, FiltersData } from './types';
import { useCheckedFilters } from './use-checked-filters.hook';

const FilterListMemo = (filtersData: FiltersData) => {
    const { data, onShowFilters } = filtersData;
    const { checkedFilters, onClearFilters, toggleFilterState } = useCheckedFilters(filtersData);

    const { navRef, scrollOptions, scrollState, onScrollCallback, imperativeScroll } = useNavScroll(
        NAV_SCROLL_DIRECTION.VERTICAL,
    );

    const renderFilterElement = (itemData: FilterDataInterface, firstElement: boolean) => {
        const isChecked = checkedFilters.indexOf(itemData.value) >= 0;

        return (
            <FilterListItem
                key={itemData.name}
                name={itemData.name}
                value={itemData.value}
                checked={isChecked}
                defaultFocused={firstElement}
                onAction={toggleFilterState}
            />
        );
    };

    const renderFilterList = (data: FilterDataInterface[], firstList: boolean) => {
        return data.map((itemData: FilterDataInterface, index: number) => {
            const firstElement = firstList && index === 0;
            return renderFilterElement(itemData, firstElement);
        });
    };

    return (
        <React.Fragment>
            <RightOpenFilterStyled />
            <FilterListRootContainer func={navVertical} onMouseLeave={() => onShowFilters?.(false)}>
                <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.UP)}>
                    {scrollState.isScrollableUp && <Icon name={ICONS.ARROW_UP} />}
                </IconWrapper>
                <FilterListWrapperStyledComponent
                    ref={navRef}
                    func={navVertical}
                    scrollOptions={scrollOptions}
                    onNavScroll={onScrollCallback}
                >
                    {data?.map((filter: Filter, index: number) => {
                        const firstList = index === 0;

                        return (
                            <div key={index}>
                                <FilterName index={index}>{filter.name}</FilterName>
                                {renderFilterList(filter.filters, firstList)}
                            </div>
                        );
                    })}
                </FilterListWrapperStyledComponent>

                <IconWrapper onClick={() => imperativeScroll(IMPERATIVE_SCROLL_DIRECTION.DOWN)}>
                    {scrollState.isScrollableDown && <Icon name={ICONS.ARROW_DOWN} />}
                </IconWrapper>

                <ButtonNavWrapper>
                    <PlayableButton
                        text={t('clear')}
                        onButtonAction={onClearFilters}
                        transparentOnInactive
                    />
                </ButtonNavWrapper>
            </FilterListRootContainer>
        </React.Fragment>
    );
};

export const FilterList = React.memo(FilterListMemo);
