import BaseLayout from '@/components/shared/layouts/BaseLayout';
import React from 'react';
import { ExitAppConfirmation as ExitAppConfirmationView } from '@/components/views/ExitAppConfirmation';

const ExitAppConfirmation = () => {
    return (
        <BaseLayout defaultBg fullscreen>
            <ExitAppConfirmationView />
        </BaseLayout>
    );
};

export default ExitAppConfirmation;
