import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { useExitAppConfirmation } from '@/hooks/use-exit-app-confirmation';
import React from 'react';
import { isStbTargets } from '@/constants/portal-recognition';
import { WelcomeBodyStb } from '@/components/stb/Welcome/WelcomeBodyStb';
import FullscreenNavContainer from '@/components/shared/FullscreenNavContainer';
import { WelcomeBody } from '@/components/shared/WelcomeBody';

const Welcome = () => {
    useExitAppConfirmation();

    return (
        <BaseLayout fullscreen defaultBg bgShade={SHADE_MODE.CUSTOM}>
            {isStbTargets && <FullscreenNavContainer body={<WelcomeBodyStb />} bodyWidth={80} />}
            {!isStbTargets && <WelcomeBody />}
        </BaseLayout>
    );
};

export default Welcome;
