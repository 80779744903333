import { ScrollContext } from '@/contexts/scroll/scroll.context';
import t from '@/lib/i18n';
import { BUTTON_ID } from '@/types/button-id.type';
import { NAVIGATION_KEYS } from 'nav-tree';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ICONS } from '../Icon';
import { createOrderedNav } from './helpers';
import {
    AbsolutePositionDynamicButton,
    ScrollableContainerWrapper,
    ScrollableContent,
    ScrollableContentWrapper,
    SCROLLABLE_WRAPPER_PADDING_PX,
} from './styles';
import { IScrollableContainerProps, ScrollPosition } from './types';

const SCROLL_STEP = 100;

const ScrollableContainer = ({
    children,
    showBack,
    showBackCC,
    showExitCC,
    onBack,
    onBackToCC,
    onExitCC,
    onScroll,
    withBottomButtons,
    fullWidth,
    shouldIncludeCCContactLink,
    containerRef,
}: IScrollableContainerProps) => {
    const { setPosition } = useContext(ScrollContext);
    const [scrollPosition, setScrollPosition] = useState<ScrollPosition>();
    const scrollableWrapperRef = useRef<HTMLDivElement>(null);
    const scrollableRef = useRef<HTMLDivElement>(null);

    const isScrollEnabled = scrollPosition !== undefined;

    const onScrollClick = useCallback((direction: 'up' | 'down') => {
        scrollableWrapperRef.current?.scrollBy({
            top: direction === 'up' ? -SCROLL_STEP : SCROLL_STEP,
        });

        setScrollPosition((previous?: ScrollPosition) => {
            if (scrollableWrapperRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = scrollableWrapperRef.current;

                if (scrollTop === 0) {
                    return ScrollPosition.TOP;
                } else if (scrollHeight - scrollTop - clientHeight < 1) {
                    return ScrollPosition.BOTTOM;
                }
                return ScrollPosition.BETWEEN;
            }
            return previous;
        });
    }, []);

    const checkForOverflow = useCallback(() => {
        if (scrollableWrapperRef.current && scrollableRef.current) {
            const { scrollHeight } = scrollableRef.current;
            const clientHeight =
                scrollableWrapperRef.current.clientHeight - SCROLLABLE_WRAPPER_PADDING_PX * 2;

            const hasScroll = scrollHeight > clientHeight;

            hasScroll && setScrollPosition(ScrollPosition.TOP);
        }
    }, []);

    useEffect(() => {
        checkForOverflow();
    }, [children, checkForOverflow]);

    useEffect(() => {
        if (scrollPosition) {
            setPosition(scrollPosition);
            onScroll?.(scrollPosition);
        }
    }, [scrollPosition]);

    const navFunc = useMemo(() => {
        const order = [];
        if (showBack) {
            order.push(BUTTON_ID.BACK);
        }
        if (showBackCC) {
            order.push(BUTTON_ID.CC_BACK);
        }
        if (showExitCC) {
            order.push(BUTTON_ID.CC_EXIT);
        }

        order.push(BUTTON_ID.UP, BUTTON_ID.DOWN);

        if (shouldIncludeCCContactLink) {
            order.push(BUTTON_ID.CC_CONTACT_LINK);
        }

        return createOrderedNav<BUTTON_ID>(order, (key, existing) => {
            if (!existing.includes(BUTTON_ID.DOWN) && key !== NAVIGATION_KEYS.ENTER) {
                if (key === NAVIGATION_KEYS.DOWN) {
                    return BUTTON_ID.CC_CONTACT_LINK;
                }

                return BUTTON_ID.UP;
            }
            if (!existing.includes(BUTTON_ID.UP)) {
                if (key === NAVIGATION_KEYS.UP) {
                    return BUTTON_ID.BACK;
                }

                if (scrollPosition === 0 && showExitCC) {
                    return BUTTON_ID.CC_EXIT;
                }

                if (key !== NAVIGATION_KEYS.ENTER) {
                    return BUTTON_ID.DOWN;
                }
            }

            return undefined;
        });
    }, [shouldIncludeCCContactLink, showBack, showBackCC, showExitCC]);

    return (
        <ScrollableContainerWrapper restoreLastFocus func={navFunc} ref={containerRef}>
            <ScrollableContentWrapper
                ref={scrollableWrapperRef}
                withBottomButtons={withBottomButtons}
                fullWidth={fullWidth}
            >
                {showBackCC && onBackToCC && (
                    <AbsolutePositionDynamicButton
                        navId={BUTTON_ID.CC_BACK}
                        onButtonAction={onBackToCC}
                        icon={ICONS.ARROW_LEFT}
                        style={{ top: '5rem', left: '5rem', right: 'auto' }}
                        text={t('pages.customer-care.back-to-main-page')}
                        data-testing="back-cc-button-label"
                    />
                )}
                {showExitCC && onExitCC && (
                    <AbsolutePositionDynamicButton
                        navId={BUTTON_ID.CC_EXIT}
                        onButtonAction={onExitCC}
                        icon={ICONS.X}
                        style={{ top: '5rem' }}
                        text={t('pages.customer-care.close-button-label')}
                        data-testing="exit-cc-button-label"
                    />
                )}
                {showBack && onBack && (
                    <AbsolutePositionDynamicButton
                        navId={BUTTON_ID.BACK}
                        onButtonAction={onBack}
                        icon={ICONS.ARROW_LEFT}
                        style={{ top: '5rem' }}
                        text={t('back-button-label')}
                        data-testing="back-button-label"
                        defaultFocused={!isScrollEnabled}
                    />
                )}
                {!(scrollPosition === ScrollPosition.TOP) && isScrollEnabled && (
                    <AbsolutePositionDynamicButton
                        navId={BUTTON_ID.UP}
                        onButtonAction={() => onScrollClick('up')}
                        icon={ICONS.ARROW_UP}
                        style={{
                            top: showBack || showExitCC ? '13rem' : '5rem',
                        }}
                        text={t('scroll-button-label')}
                        fixed={fullWidth}
                        defaultFocused={scrollPosition === ScrollPosition.BOTTOM}
                        enableLongPress
                    />
                )}
                {!(scrollPosition === ScrollPosition.BOTTOM) && isScrollEnabled && (
                    <AbsolutePositionDynamicButton
                        navId={BUTTON_ID.DOWN}
                        onButtonAction={() => onScrollClick('down')}
                        style={{ bottom: showExitCC ? '17rem' : '5rem' }}
                        icon={ICONS.ARROW_DOWN}
                        text={t('scroll-button-label')}
                        fixed={fullWidth}
                        defaultFocused={scrollPosition === ScrollPosition.TOP}
                        enableLongPress
                    />
                )}
                <ScrollableContent ref={scrollableRef}>{children}</ScrollableContent>
            </ScrollableContentWrapper>
        </ScrollableContainerWrapper>
    );
};

export default ScrollableContainer;
