const buildHighlightSpan = (className = 'highlight') => {
    return {
        span: (chunks: string) => `<span class="${className}">${chunks}</span>`,
    };
};

const buildHighlightAnchor = (url: string, className = 'highlight') => {
    return {
        a: (chunks: string) =>
            `<a href="${url}" class="${className}" target="_blank">${chunks}</a>`,
    };
};

const buildHighlightMailAnchor = (className = 'highlight') => {
    return {
        a: (chunks: string) => `<a href="mailto:${chunks}" class="${className}">${chunks}</a>`,
    };
};

export { buildHighlightSpan, buildHighlightMailAnchor, buildHighlightAnchor };
