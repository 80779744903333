import { useLoginWithDecoder } from '@/api/auth/login-with-decoder';
import AppContextProvider from '@/contexts/app/app.context';
import FavoritesContextProvider from '@/contexts/favorites/favorites.context';
import FmcContextProvider from '@/contexts/fmc/fmc.context';
import HomeMenuContextProvider from '@/contexts/menu/home.menu.context';
import ProfileContextProvider from '@/contexts/profile/profile.context';
import ReferrerContextProvider from '@/contexts/referrer/referrer.context';
import { getBuildInfo } from '@/services/build-info.service';
import React, { useEffect } from 'react';
import { EnhancedProps } from '@/modules/enhance-context';
import { getPageCategory } from '@/helpers/pages/get-page-category.helper';
import { useLaunchPreviewService, usePreviewLaunched } from '@/hooks/use-preview';
import { isStbTargets } from '@/constants/portal-recognition';

interface IBootstrapClient {
    children: React.ReactNode;
    pageProps?: EnhancedProps;
}

const BootstrapClient = ({ children, pageProps }: IBootstrapClient) => {
    const currentCategory = getPageCategory(pageProps);

    const { loginWithDecoder, initializedStb } = useLoginWithDecoder();

    useLaunchPreviewService();
    usePreviewLaunched();

    useEffect(() => {
        if (process.env.features.consoleBuildInfo) {
            console.log(JSON.stringify(getBuildInfo(), null, 2));
        }
    }, []);

    useEffect(() => {
        if (isStbTargets) {
            loginWithDecoder();
        }
    }, []);

    if (isStbTargets && !initializedStb) {
        return null;
    }

    return (
        <AppContextProvider serverTime={pageProps?.serverTime}>
            <ReferrerContextProvider>
                <ProfileContextProvider>
                    <FmcContextProvider>
                        <FavoritesContextProvider>
                            <HomeMenuContextProvider currentCategory={currentCategory}>
                                {children}
                            </HomeMenuContextProvider>
                        </FavoritesContextProvider>
                    </FmcContextProvider>
                </ProfileContextProvider>
            </ReferrerContextProvider>
        </AppContextProvider>
    );
};

export default BootstrapClient;
