import { Separator, SeparatorContainer, SeparatorText } from './styles';

interface Props {
    text: string;
}
export const VerticalSeparatorWithText = ({ text }: Props) => {
    return (
        <SeparatorContainer>
            <Separator />
            <SeparatorText>{text}</SeparatorText>
            <Separator />
        </SeparatorContainer>
    );
};
