import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { FbAuthData } from '@/types/user-auth-data.type';
import { useCallback, useState } from 'react';
import { useLoginUser } from './use-login-user.hook';
import useLogin from '@/api/auth/use-login';
import useRegister from '@/api/auth/register';
import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { useRouter } from '@/routing';

export const useFacebookLogin = () => {
    const { query } = useRouter();
    const authProvider = AUTH_PROVIDERS.FACEBOOK;
    const [captcha, setCaptcha] = useState<string | null>(null);
    const [login, loginData] = useLogin(captcha);
    const [registerUser, registerData] = useRegister();
    const { status: loginStatus, error: loginError, isFetching: isFetchingLogin } = loginData;
    const { status: registerStatus, isFetching: isFetchingRegister } = registerData;
    const captchaRequired =
        loginError?.getCode() === ERROR_CODES_GET_MEDIA.B2C_CAPTCHA_VALUE_REQUIRED;

    const handleLoginUser = useLoginUser({ login, register: registerUser, authProvider, query });
    const facebookLogin = useCallback(
        async (authToken: string) => {
            const params: FbAuthData = { authProvider, authToken };
            handleLoginUser(params);

            if (captchaRequired) {
                window.grecaptcha.reset();
                setCaptcha(null);
            }
        },
        [handleLoginUser, authProvider],
    );

    return {
        facebookLogin,
        loginData,
        registerData,
        setCaptcha,
        isFetching: isFetchingLogin || isFetchingRegister,
        loginError,
        isSuccess: loginStatus === 'success' && registerStatus === 'success',
        captchaRequired,
    };
};
