import { ROUTES } from '@/routing/types';
import React, { createContext, useState } from 'react';
import { ReferrerContextProps, ReferrerContextProviderProps } from './types';

export const ReferrerContext = createContext<ReferrerContextProps>({
    referrer: undefined,
    setReferrer: () => null,
});

const ReferrerContextProvider = ({ children }: ReferrerContextProviderProps) => {
    const [referrer, setReferrer] = useState<ROUTES>();

    return (
        <ReferrerContext.Provider value={{ referrer, setReferrer }}>
            {children}
        </ReferrerContext.Provider>
    );
};

export default ReferrerContextProvider;
