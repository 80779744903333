import React, { useContext, useMemo } from 'react';
import {
    MediaRelatedTileImage,
    MediaRelatedTileStyled,
    MediaRelatedTileContainer,
} from '@/components/shared/MediaRelatedList/styles';
import { MagicMouseContext } from '@/contexts/mouse/magic.mouse.context';
import { IMAGE_DISPLAY_MODE } from '@/types/cover-utils.type';
import { MediaRelatedTileProps } from '@/components/shared/MediaRelatedList/types';

const MediaRelatedTileRaw = ({ media, navId, onNav, onAction }: MediaRelatedTileProps) => {
    const { isVisible: isMagicMouseVisible } = useContext(MagicMouseContext);
    const image = useMemo(() => media.getImage(), [media]);
    const imageSize = useMemo(
        () =>
            image.displayMode === IMAGE_DISPLAY_MODE.POSTERS
                ? process.env.imageSize.posters
                : process.env.imageSize.thumbnails,
        [image],
    );

    return (
        <MediaRelatedTileStyled
            navId={navId}
            onNav={onNav}
            onEnterDown={onAction}
            onClick={onAction}
            autofocusOn={isMagicMouseVisible ? ['mouseenter'] : undefined}
        >
            <MediaRelatedTileContainer width={imageSize.width} height={imageSize.height}>
                <MediaRelatedTileImage src={image.src} />
            </MediaRelatedTileContainer>
        </MediaRelatedTileStyled>
    );
};

export const MediaRelatedTile = React.memo(MediaRelatedTileRaw);
