import useGetArticle from '@/api/help/use-get-article';
import React, { useState } from 'react';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { ProcessingLoader } from '@/components/shared/Loader';
import { Router, useRouter } from '@/routing';
import { ScrollPosition } from '@/components/shared/ScrollableContainer/types';
import FullMessage from '@/components/views/CustomerCare/FullArticleErrorText/FullMessage';
import { createHashHistory } from 'history';

const ArticlePage = () => {
    const { query } = useRouter();
    const articleId = query.articleId;

    const [scrollPosition, setScrollPosition] = useState(ScrollPosition.TOP);
    const { data, isFetching } = useGetArticle({ articleId });

    const history = createHashHistory();

    return (
        <BaseLayout
            fullscreen
            defaultBg
            topShade={scrollPosition !== ScrollPosition.TOP}
            bottomShade={scrollPosition !== ScrollPosition.BOTTOM}
        >
            {isFetching ? (
                <ProcessingLoader isFetching={true} />
            ) : (
                <FullMessage
                    data={data}
                    setScrollPosition={setScrollPosition}
                    scrollPosition={scrollPosition}
                    showExitCC={true}
                    onExitCC={() => history.go(-2)}
                    showBackCC={true}
                    onBackToCC={() => Router.back()}
                />
            )}
        </BaseLayout>
    );
};
export default ArticlePage;
