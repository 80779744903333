import { KEYS } from './keys';

export default function buildBrowserKeysList(): { [key in KEYS]?: number } {
    return {
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40,
        ENTER: 13,
        BACKSPACE: 8,
        BACK: 8,
        SPACE: 32,
        PLAY_PAUSE: 179,
        FORWARD: 176,
        REWIND: 177,

        F: 70,
        M: 77,
        S: 83,

        KEY_0: 48,
        KEY_1: 49,
        KEY_2: 50,
        KEY_3: 51,
        KEY_4: 52,
        KEY_5: 53,
        KEY_6: 54,
        KEY_7: 55,
        KEY_8: 56,
        KEY_9: 57,

        // RED: 120, // F9
        // GREEN: 121, // F10
        YELLOW: 122, // F11
        BLUE: 123, // F12

        CURSOR_SHOW: 120, // F9
        CURSOR_HIDE: 121, // F10
    };
}
