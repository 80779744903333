import { PlayableButton } from '@/components/shared/Buttons';
import FullscreenNavContainer, { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { ICONS } from '@/components/shared/Icon';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import { Router } from '@/routing';
import { GUTTER } from '@/types/gutter.type';
import React from 'react';

const RemoveDefaultProfile = () => {
    const body = (
        <>
            <Message
                icon={ICONS.WARNING_CIRCLE}
                title={t('pages.profile.remove.description-default')}
                gutter={GUTTER.BIG}
            />
            <FormContainer>
                <PlayableButton
                    text={t('ok-button-label')}
                    onButtonAction={() => Router.back()}
                    defaultFocused
                />
            </FormContainer>
        </>
    );

    return (
        <BaseLayout fullscreen defaultBg>
            <FullscreenNavContainer body={body} />
        </BaseLayout>
    );
};

export default RemoveDefaultProfile;
