import targetKeys from 'i18n-target/locale.json';
import keys from 'i18n/locale.json';
import IntlMessageFormat from 'intl-messageformat';
import get from 'lodash/get';
import merge from 'lodash/merge';

const dict = merge(keys, targetKeys);

const t = (key: any, format = {}): string => {
    const translatedMsg = new IntlMessageFormat(get(dict, key, ''), process.env.locale).format(
        format,
    );

    return translatedMsg && typeof translatedMsg === 'string' ? translatedMsg : '';
};

export default t;
