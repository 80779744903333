import { usePlatform } from '@/contexts/platform/platform.hooks';
import { useRedirectToNextUrl } from '@/hooks/use-redirect-to-next-url';
import { useCallback, useEffect } from 'react';
import { SCRIPT_ID } from '@/types/script-id.type';
import { findScriptById } from '@/helpers/find-script-by-id.helper';
import { DEFAULT_PLAYER_LOADER_URL } from '@/configs/player.config';

export const PlayerLoader = () => {
    const playerScript = findScriptById(SCRIPT_ID.LOADER);
    const { platform } = usePlatform();
    const redirectToNext = useRedirectToNextUrl();

    const loadScript = useCallback((script: HTMLScriptElement) => {
        script.src = process.env.PLAYER_LOADER_URL || DEFAULT_PLAYER_LOADER_URL;
        script.id = SCRIPT_ID.LOADER;
        document.head.appendChild(script);
    }, []);

    useEffect(() => {
        if (!platform || !!playerScript) return;

        const script = document.createElement('script');
        loadScript(script);

        script.onerror = () => {
            document.head.removeChild(script);
            redirectToNext();
        };
    }, [platform, playerScript, redirectToNext, loadScript]);

    return null;
};
