import defaultKeyHook from '@/hooks/default-key';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { ArticleModel } from '@/models/help/article.model';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';

interface Props {
    articleId: string;
    errorCode?: number;
    enabled?: boolean;
}

export const GetArticlePromise = (
    params: Props,
    getMediaApiPromise: GetMediaPromiseFunc,
): Promise<ArticleModel> => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.HELP, GET_MEDIA_METHODS.GET_ARTICLE, {
        articleId: params.articleId,
        errorCode: params.errorCode,
    });
    return getMediaApiPromise(keyHook, { appendAuthData: true }).then((res) => res.data);
};

const useGetArticle = (params: Props): IApiResponse<ArticleModel> => {
    const keyHook = defaultKeyHook(GET_MEDIA_NAMESPACES.HELP, GET_MEDIA_METHODS.GET_ARTICLE, {
        articleId: params.articleId,
        errorCode: params.errorCode,
        params,
    });
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });
    return { data, isFetching, error };
};

export default useGetArticle;
