import { isServer } from '../environment.helper';

const BOT_REGEXP = 'bot|google|inspectiontool|crawler|spider|robot|crawling';
const GOOGLE_BOT_REGEXP = 'googlebot';

export const botDetector = (userAgent?: string) => {
    const browserUserAgent = userAgent || (!isServer && window.navigator.userAgent);

    const botRegExp = new RegExp(BOT_REGEXP, 'i');
    const googleBotRegExp = new RegExp(GOOGLE_BOT_REGEXP, 'i');

    const isBot = !!(browserUserAgent && botRegExp.test(browserUserAgent));
    const isGoogleBot = !!(browserUserAgent && googleBotRegExp.test(browserUserAgent));

    return {
        isBot,
        isGoogleBot,
    };
};
