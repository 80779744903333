import { Z_INDEX } from '@/targets/default/theme';
import styled from 'styled-components';

export const RuleActionButtonWrapper = styled.div`
    flex: 1;
    margin: 0 2rem;
`;

export const RuleButtons = styled.div`
    position: fixed;
    left: 0;
    bottom: 2rem;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: ${Z_INDEX.MENU_CONTAINER};
    width: 100vw;
    padding: 0 35rem 0 50rem;

    ${RuleActionButtonWrapper}:only-child {
        padding: 0 25rem;
    }
`;

export const LoginRulesWrapper = styled.div`
    padding: 0 10rem 0 50rem;
`;
