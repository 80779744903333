import { LoaderContext } from '@/contexts/loader/loader.context';
import React, { useContext, useMemo } from 'react';
import { SubcategoryGridViewProps } from '@/components/views/vod/subcategory/types';
import { CatalogView } from '@/components/views/common';
import { buildSubcategoryCatalogConfig } from '@/helpers/pages/build-subcategory-catalog-config.helper';

const SubcategoryGridView = ({ categoryId, subcategoryId }: SubcategoryGridViewProps) => {
    const { setShowLoader } = useContext(LoaderContext);
    const catalogConfig = useMemo(
        () => buildSubcategoryCatalogConfig({ subcategoryId, keyCategoryId: categoryId }),
        [categoryId, subcategoryId],
    );

    useMemo(() => {
        setShowLoader(true);
    }, []);

    return <CatalogView config={catalogConfig} isChronological={true} />;
};

export default SubcategoryGridView;
