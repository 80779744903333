export var NAVIGATION_KEYS;
(function (NAVIGATION_KEYS) {
    NAVIGATION_KEYS["UP"] = "up";
    NAVIGATION_KEYS["RIGHT"] = "right";
    NAVIGATION_KEYS["DOWN"] = "down";
    NAVIGATION_KEYS["LEFT"] = "left";
    NAVIGATION_KEYS["ENTER"] = "enter";
})(NAVIGATION_KEYS || (NAVIGATION_KEYS = {}));
export var NAV_DIRECTION;
(function (NAV_DIRECTION) {
    NAV_DIRECTION["PREV"] = "prev";
    NAV_DIRECTION["NEXT"] = "next";
})(NAV_DIRECTION || (NAV_DIRECTION = {}));
export var NAV_SCROLL_DIRECTION;
(function (NAV_SCROLL_DIRECTION) {
    NAV_SCROLL_DIRECTION["HORIZONTAL"] = "horizontal";
    NAV_SCROLL_DIRECTION["VERTICAL"] = "vertical";
})(NAV_SCROLL_DIRECTION || (NAV_SCROLL_DIRECTION = {}));
export var IMPERATIVE_SCROLL_DIRECTION;
(function (IMPERATIVE_SCROLL_DIRECTION) {
    IMPERATIVE_SCROLL_DIRECTION["UP"] = "up";
    IMPERATIVE_SCROLL_DIRECTION["RIGHT"] = "right";
    IMPERATIVE_SCROLL_DIRECTION["DOWN"] = "down";
    IMPERATIVE_SCROLL_DIRECTION["LEFT"] = "left";
})(IMPERATIVE_SCROLL_DIRECTION || (IMPERATIVE_SCROLL_DIRECTION = {}));
export var NAV_SCROLL_MODE;
(function (NAV_SCROLL_MODE) {
    NAV_SCROLL_MODE["AUTO"] = "auto";
    NAV_SCROLL_MODE["START"] = "start";
    NAV_SCROLL_MODE["CENTER"] = "center";
    NAV_SCROLL_MODE["END"] = "end";
    NAV_SCROLL_MODE["IMPERATIVE"] = "imperative";
})(NAV_SCROLL_MODE || (NAV_SCROLL_MODE = {}));
