import FavoritesWithFlatNavigationModel from '@/models/favorites/favorites-with-flat-navigation.model';
import { ListElementModel } from '@/models/list-element/list-element.model';

const { favoritesCatalog: dataPosition } = process.env.metadataSettings.position;

export const buildFavoritesCollection = (fetchedFavorites: FavoritesWithFlatNavigationModel) => {
    return fetchedFavorites.getResults().map((item) => {
        const result = item.getObject();

        const gridElement = new ListElementModel(result);
        gridElement.setDataPosition(dataPosition);

        return gridElement;
    });
};
