import { OfferPricingPlanModel } from '@/models/products/offer-pricing-plan.model';
import { ProductIdInterface } from './product-id-interface';
import { PRODUCT_SUBTYPES } from '@/types/media.type';

export enum PAYMENT_STEPS {
    STEP_1 = 'step-1',
    STEP_2 = 'step-2',
    STEP_3 = 'step-3',
    STEP_4 = 'step-4',
}

export enum PAYMENT_STATUS {
    SUCCESS = 'success',
    ERROR = 'error',
    PENDING = 'pending',
}

export enum UPGRADE_STATUS {
    SUCCESS = 'success',
    ERROR = 'error',
    PENDING = 'pending',
}

export enum ORDER_STATUS {
    TIMEOUT = 'timeout',
    CANCELED = 'canceled',
    FAILURE = 'failure',
    SUCCESS = 'success',
    ORDER_CREATED = 'order-created',
    IN_PROGRESS = 'in-progress',
}

export interface PaymentsDataInterface {
    selectedOfferId?: string;
    selectedOfferPricingPlanId?: string;
    selectedOptionId?: string;
    selectedPaymentCardId?: string;
    orderId?: string;
    smsText?: string;
    smsNumber?: string;
}

export interface PricingPlanDataInterface {
    duration?: string;
    isRulesExists: boolean;
    minPrice: string;
    name: string;
    offerPricingPlan: OfferPricingPlanModel;
}

export interface SelectedOfferDataInterface {
    selectedOfferId: string;
    selectedOfferPricingPlanId?: string;
}

export interface RedirectToFirstPaymentStepInterface {
    productId: ProductIdInterface;
    selectOfferId?: string;
    selectOfferPricingPlanId?: string;
    withNext?: boolean;
}

export interface RedirectToPaymentStepInterface {
    step: PAYMENT_STEPS;
    productId: ProductIdInterface;
    selectOfferId?: string;
    selectOfferPricingPlanId?: string;
    nextUrl?: string;
    replaceRoute?: boolean;
}

export interface StatsDataToRedirect {
    orderId: string;
    category: PRODUCT_SUBTYPES;
    name: string;
    id: string;
    price: number;
}
