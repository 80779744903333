import { GET_MEDIA_METHODS } from '@/types/get-media-method.type';

export const buildRPCRequestParams = (
    method: GET_MEDIA_METHODS,
    params: Record<string, unknown>,
): Record<string, unknown> => ({
    id: 1,
    jsonrpc: '2.0',
    method,
    params,
});
