import { RulesScrollableContainer } from '@/components/shared/RulesScrollableContainer';
import { PurchaseOptionRuleList } from '@/components/views/Purchase/Rules/PurchaseOptionRuleList';
import { getRuleSourceUrl } from '@/helpers/get-rule-source-url';
import { RulesModel } from '@/models/products/rules.model';
import React, { useState } from 'react';
import { PurchaseOptionRuleContainerProps } from './types';

export const PurchaseOptionRuleContainer = ({ rules }: PurchaseOptionRuleContainerProps) => {
    const [selectedRule, setSelectedRule] = useState<RulesModel>();
    const sourceUrl = getRuleSourceUrl(selectedRule?.getSources());

    const ruleDetails = (
        <RulesScrollableContainer
            sourceUrl={sourceUrl}
            onBack={() => setSelectedRule(undefined)}
            showBack
        />
    );
    const rulesList = <PurchaseOptionRuleList rules={rules} onSelect={setSelectedRule} />;

    return selectedRule ? ruleDetails : rulesList;
};
