import React, { useEffect, useMemo } from 'react';
import { navVertical, NAV_SCROLL_DIRECTION, NAV_SCROLL_MODE } from 'nav-tree';
import { ProcessingLoader } from '@/components/shared/Loader';
import { MyPurchasesContent } from './MyPurchasesContent';
import { MyPurchasesNoContent } from './MyPurchasesNoContent';
import {
    StyledCenteredContainer,
    StyledMyPurchasesContainer,
    StyledMyPurchasesInnerWrapper,
    StyledMyPurchasesWrapper,
} from './styles';
import { useGetMyPurchases } from '@/api/payments/use-get-my-purchases';
import { isStbTargets } from '@/constants/portal-recognition';
import { useError } from '@/contexts/error/error.context';

export const MyPurchasesContainer = () => {
    const { setError } = useError();
    const { data, isFetching, error } = useGetMyPurchases();

    useEffect(() => {
        if (!isFetching && error) {
            setError(error);
        }
    }, [error]);

    const myPurchasesContent = useMemo(() => {
        if (isFetching) {
            return (
                <StyledCenteredContainer>
                    <ProcessingLoader isFetching />
                </StyledCenteredContainer>
            );
        } else {
            if (data.length > 0) {
                return (
                    <StyledMyPurchasesInnerWrapper
                        func={navVertical}
                        scrollOptions={{
                            isScrollable: true,
                            direction: NAV_SCROLL_DIRECTION.VERTICAL,
                            mode: NAV_SCROLL_MODE.END,
                        }}
                        restoreLastFocus
                        defaultFocused
                    >
                        <MyPurchasesContent slidersList={data} />
                    </StyledMyPurchasesInnerWrapper>
                );
            }

            return <MyPurchasesNoContent />;
        }
    }, [data, isFetching]);

    if (isStbTargets) {
        return (
            <StyledMyPurchasesWrapper>
                <StyledMyPurchasesContainer>{myPurchasesContent}</StyledMyPurchasesContainer>
            </StyledMyPurchasesWrapper>
        );
    }

    return <StyledMyPurchasesContainer>{myPurchasesContent}</StyledMyPurchasesContainer>;
};
