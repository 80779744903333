import React from 'react';
import { ListElementInterface } from '@/interfaces/list-element.interface';
import { ListElementProgressBarWrapper } from './styles';

const ListElementProgressBarRaw = ({ itemData }: { itemData: ListElementInterface }) => {
    const progress = itemData.getProgress();
    const isProgressBarVisible = itemData.getVisibilityProgressBar();

    if (typeof progress !== 'undefined' && isProgressBarVisible) {
        return <ListElementProgressBarWrapper progress={progress} />;
    }

    return null;
};

export const ListElementProgressBar = React.memo(ListElementProgressBarRaw);
