export const COLUMNS_PER_PAGE = 6;
export const COLUMN_INDEX_FOR_PAGE_CHANGE = 3;
export const COL_HEIGHT = 20.4;
export const COL_WIDTH = 40.8;
export const FULL_HEIGHT_CELL = COL_HEIGHT;
export const HALF_HEIGHT_CELL = FULL_HEIGHT_CELL / 2;
export const HOUR_RANGE = 2;
export const THREE_OR_MORE_CELLS_HEIGHT = 8.5;
export const START_HOUR_MORNING = 6;
export const START_HOUR_AFTERNOON = 14;
export const START_HOUR_EVENING = 20;

export const FOCUSED_COLUMN_INDEX = {
    filtersChanged: -2 as -2,
    pageChanged: -1 as -1,
} as const;

export const TIME_TO_HOUR = {
    morning: START_HOUR_MORNING,
    afternoon: START_HOUR_AFTERNOON,
    evening: START_HOUR_EVENING,
} as const;

export const CELL_PADDING = {
    activeVertical: 1.5,
    activeHorizontal: 1.7,
    vertical: 1.8,
    horizontal: 2,
};
