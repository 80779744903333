import styled, { css } from 'styled-components';
import { BorderWithTextProps } from './types';

export const CheckoutConteinr = styled.div`
    display: flex;
`;

export const CheckoutItem = styled.div`
    position: relative;
    width: 50%;
    padding: 4.5rem 0;
`;

export const BorderWithText = styled.div<BorderWithTextProps>`
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:before,
    &:after {
        content: '';
        width: 50%;
        height: 100%;
        border: 0.15rem solid ${({ theme }) => theme.paymentCheckoutStep.borderColor};
    }

    &:before {
        border-right: none;
    }

    &:after {
        border-left: none;
        border-radius: 0 3.2rem 3.2rem 0;
    }

    ${({ isFirst }) =>
        isFirst &&
        css`
            &:before {
                border-radius: 3.2rem 0 0 3.2rem;
            }

            &:after {
                border-right: none;
                border-radius: 0;
            }
        `}
`;

export const BorderWithTextInside = styled.div`
    height: 100%;
    border-bottom: 0.15rem solid ${({ theme }) => theme.paymentCheckoutStep.borderColor};
    padding: 0 15px;
`;

export const BorderWithTextLabel = styled.div`
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 1.6rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    color: ${({ theme }) => theme.paymentCheckoutStep.defaultTextColor};
`;

export const CheckoutItemContent = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const Label = styled.div`
    font-size: 3rem;
    color: ${({ theme }) => theme.paymentCheckoutStep.labelColor};
`;

export const Description = styled.div`
    font-size: 2.4rem;
    color: ${({ theme }) => theme.paymentCheckoutStep.descriptionColor};
    margin-bottom: 1.5rem;
`;
