import debug from 'debug';

/*
    Aby logi się wyświetlały, należy w Chrome ustawić wyświetlanie Verbose,
    ponieważ z defaultu nie jest to aktywne.

    https://github.com/visionmedia/debug/issues/803
 */
export class Debug {
    private debugger: debug.Debugger;

    constructor(label = '') {
        this.debugger = debug('smartweb:' + label);
    }

    log(message: string, ...args: unknown[]) {
        this.debugger(message, ...args);
    }

    warn(message: string, ...args: unknown[]) {
        this.debugger(`[WARN]: ${message}`, ...args);
    }

    error(message: string, ...args: unknown[]) {
        this.debugger(`[ERROR]: ${message}`, ...args);
    }
}
