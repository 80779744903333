import { useEffect } from 'react';

export const useTimeTargets = (
    callback: () => void,
    { timeTargets = [], interval = 500 }: { timeTargets: number[]; interval: number },
    deps: any[],
) => {
    useEffect(() => {
        if (!timeTargets.length) {
            return;
        }

        let timeElapsed = 0;

        const intervalId = setInterval(() => {
            if (!timeTargets) {
                clearInterval(intervalId);
                return;
            }
            timeTargets = timeTargets.filter((timeTarget, index) => {
                if (timeElapsed >= timeTarget) {
                    callback();
                    return false;
                }

                return true;
            });

            timeElapsed = timeElapsed + interval;
        }, interval);

        return () => {
            clearInterval(intervalId);
        };
    }, [timeTargets, interval, ...deps]);
};
