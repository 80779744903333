import { SessionChangedListener, SessionInterface } from './interfaces/session.interface';
import SessionService from './services/session/session.service';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import UserRecognitionService from './services/session/user-recognition.service';
import UserRecognitionModel from './models/user-recognition.model';
import { DeviceIdInterface } from '@/interfaces/device-id-interface';
import ResponseErrorModel from '@/models/response-error.model';
import { GetSessionOut } from '@/interfaces/from-schemas/auth/getSessionOut';
import { UserAuthData } from '@/types/user-auth-data.type';
import { AES, Utf8 } from 'jscrypto';
import { isServer } from '@/helpers/environment.helper';
import { isWeb } from '@/constants/portal-recognition';
import { isUserAuthData } from '@/helpers/auto-login.helper';

const CREDENTIALS_LOCAL_STORAGE_KEY = 'credentials';

class UserModule {
    private authSessionModel: GetSessionOut | undefined;
    private sessionChangedListeners: SessionChangedListener[] = [];
    private authSessionErrors?: ResponseErrorModel;
    private authData?: UserAuthData;
    private acceptedRules?: boolean = false;
    public isDuringLogout = false;
    constructor(
        private userRecognitionService: UserRecognitionService,
        private sessionService: SessionService,
    ) {}

    public isLogged(): boolean {
        return Boolean(this.getSession());
    }

    public isDuringAutoLogin(hasProfile?: boolean): boolean {
        if (isWeb) return false;

        return !this.isLogged() && !!this.authData && !!hasProfile;
    }

    public getSessionToken(
        method: GET_MEDIA_METHODS,
        namespace: GET_MEDIA_NAMESPACES,
        userSessionData: SessionInterface,
    ): string | null {
        return this.sessionService.getSessionToken(method, namespace, userSessionData);
    }

    public getSession(): SessionInterface | undefined {
        return this.sessionService.getSession();
    }

    public setSession(sessionData: SessionInterface): void {
        this.sessionService.setSession(sessionData);
        this.notifyListeners(sessionData);
    }

    public clearSession(): void {
        this.sessionService.clearSession(this.isDuringAutoLogin());
        this.notifyListeners();
    }

    public getAuthSession() {
        return this.authSessionModel;
    }

    public getUserId(): number | undefined {
        return this.authSessionModel?.user.id;
    }
    public getEmail(): string | undefined {
        return this.authSessionModel?.user.email;
    }

    public setUserId(userId: string): void {
        this.userRecognitionService.setUserId(userId);
    }

    public hasEmail(): boolean {
        return !!this.authSessionModel?.user.email;
    }

    public getAuthSessionProfilId(): string | undefined {
        return this.authSessionModel?.profileId;
    }

    public setAuthSession(authSession: GetSessionOut | undefined): void {
        this.authSessionModel = authSession;
        if (authSession) {
            this.setSession(authSession.session);
            this.setUserId(`${authSession.user.id}`);
        }
    }

    public getAuthSessionErrors() {
        return this.authSessionErrors;
    }

    public setAuthSessionErrors(errors: ResponseErrorModel | undefined) {
        this.authSessionErrors = errors;
    }

    public clearAuthSession(): void {
        this.setAuthData(undefined);
        this.setAuthSession(undefined);
        this.clearSession();
        this.setAllRulesAccepted(false);
        this.setUserId('');
    }

    public listen(listener: SessionChangedListener) {
        this.sessionChangedListeners = [...this.sessionChangedListeners, listener];
        return () => {
            this.sessionChangedListeners = this.sessionChangedListeners.filter(
                (callback) => callback !== listener,
            );
        };
    }

    public getJWTToken(): string {
        // @ts-ignore
        return this.authSessionModel?.oneTrustAuthToken || '';
    }

    private notifyListeners(sessionData?: SessionInterface) {
        this.sessionChangedListeners.forEach((listener) => listener(sessionData));
    }

    public getUserRecognition(): UserRecognitionModel {
        return this.userRecognitionService.getUserRecognition();
    }

    public setDeviceId(deviceId: DeviceIdInterface['value']): void {
        const deviceIdExists = !!this.getDeviceIdIdent().value;

        if (!deviceIdExists) {
            this.userRecognitionService.setDeviceId(deviceId);
        }
    }

    public getDeviceIdIdent(): DeviceIdInterface {
        return this.userRecognitionService.getDeviceIdIdent();
    }

    public setClientId(clientId: string): void {
        this.userRecognitionService.setClientId(clientId);
    }

    public getClientId(): string {
        return this.userRecognitionService.getClientId();
    }

    public clearRecognition(): void {
        this.userRecognitionService.clearRecognition();
    }

    public reset(): void {
        this.clearAuthSession();
        this.clearRecognition();
    }

    public setAuthData(data?: UserAuthData): void {
        this.authData = data;
    }

    public getAuthData(): UserAuthData | undefined {
        return this.authData;
    }

    public saveCredentials(data: UserAuthData): void {
        if (isServer) {
            return;
        }
        const deviceId = this.getDeviceIdIdent().value;
        const stringifiedData = JSON.stringify(data);

        const encryptedData = AES.encrypt(stringifiedData, deviceId).toString();
        window.localStorage.setItem(CREDENTIALS_LOCAL_STORAGE_KEY, encryptedData);
    }

    public getCredentials(): UserAuthData | undefined {
        if (isServer) {
            return undefined;
        }
        const deviceId = this.getDeviceIdIdent().value;
        const credentials = window.localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_KEY);
        const decryptedData = credentials && AES.decrypt(credentials, deviceId).toString(Utf8);

        if (decryptedData) {
            try {
                const parsedDecryptedData = JSON.parse(decryptedData);
                if (isUserAuthData(parsedDecryptedData)) {
                    return parsedDecryptedData;
                }
            } catch (e) {
                return undefined;
            }
        }

        return undefined;
    }

    public isCredentialsExists(): boolean {
        if (isServer) {
            return false;
        }

        const credentials = window.localStorage.getItem(CREDENTIALS_LOCAL_STORAGE_KEY);
        return Boolean(credentials);
    }

    public clearCredentials(): void {
        if (isServer) {
            return;
        }
        window.localStorage.removeItem(CREDENTIALS_LOCAL_STORAGE_KEY);
    }

    public hasAcceptedRules(): boolean {
        return !!this.acceptedRules;
    }

    public setAllRulesAccepted(allAccepted: boolean): void {
        this.acceptedRules = allAccepted;
    }
}

export default UserModule;
