import { Nav } from 'nav-tree';
import styled from 'styled-components';
import { ContentContainerProps } from './types';

export const PaymentSuccessContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 14rem;
    height: 100%;
`;

export const ProductContainer = styled.div`
    width: 40rem;
    padding-top: 20rem;
`;

export const ContentContainer = styled.div<ContentContainerProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '109rem')};
`;

export const MessageContainer = styled.div`
    & span {
        color: ${({ theme }) => theme.paymentSuccess.highlight};
        font-weight: bold;
    }
`;

export const ButtonsContainer = styled(Nav)``;
