import React, { useState } from 'react';
import { useParams } from 'react-router';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { PaymentsPath } from '@/components/views/PaymentsPath';
import useGetProduct from '@/api/navigation/use-get-product';
import { ProductIdInterface } from '@/interfaces/product-id-interface';
import { useGetPrePurchaseData } from '@/api/payments/use-get-pre-purchase-data';
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from '@/types/media.type';
import { PAYMENT_STEPS } from '@/interfaces/payments.interface';
import { getMediaCpidFromProductId } from '@/helpers/product.helper';
import useGetMedia from '@/api/navigation/use-get-media';

interface RouteParams {
    id: string;
    type: PRODUCT_TYPES;
    subtype: PRODUCT_SUBTYPES;
    step: PAYMENT_STEPS;
}

const Purchase = () => {
    const { id, type, subtype: subType, step: currentStep } = useParams<RouteParams>();
    const productId: ProductIdInterface = { id, type, subType };
    const { data: product } = useGetProduct({ product: productId });
    const { data: prePurchaseData } = useGetPrePurchaseData({ product: productId });
    const [baseProductId] = useState<ProductIdInterface>(productId);

    const cpid = getMediaCpidFromProductId(productId);
    const mediaParams = typeof cpid !== 'undefined' ? { mediaId: id, cpid } : undefined;
    const { data: media } = useGetMedia(mediaParams);

    return (
        <BaseLayout defaultBg fullscreen>
            {product && (
                <PaymentsPath
                    currentStep={currentStep}
                    product={product}
                    baseProductId={baseProductId}
                    prePurchaseData={prePurchaseData}
                    media={media}
                />
            )}
        </BaseLayout>
    );
};

export default Purchase;
