import BaseLayout from '@/components/shared/layouts/BaseLayout';
import React, { useCallback, useState } from 'react';

import { PageContainer } from '@/components/views/Account/styles';
import { Router, useRouter } from '@/routing';
import { ROUTES } from '@/routing/types';
import { navHorizontal, NavScrollDataInterface } from 'nav-tree';
import { LoadingWrapper } from '@/components/shared/Loader';
import useGetCategoryTreeList from '@/api/help/use-get-category-tree-list';
import { CustomerCareHelp } from '@/components/views/CustomerCare/Help/help';
import { buildCategoryTreeListParams } from '@/hooks/help/build-category-tree-list-params.hook';
import styled from 'styled-components';

const CustomerCarePageContainer = styled(PageContainer)`
    padding-left: 51rem;
`;

const Help = () => {
    const { query } = useRouter();
    const [topShade, setTopShade] = useState<boolean>(false);
    const [bottomShade, setBottomShade] = useState<boolean>(true);

    const { data, isFetching } = useGetCategoryTreeList(buildCategoryTreeListParams(query));
    const onFilterScroll = useCallback(
        ({ isScrollableUp, isScrollableDown }: NavScrollDataInterface) => {
            setTopShade(isScrollableUp);
            setBottomShade(isScrollableDown);
        },
        [setTopShade, setBottomShade],
    );

    const handleMore = useCallback((articleId: string) => {
        Router.pushRoute(ROUTES.CUSTOMER_CARE_HELP_ARTICLE, { articleId: articleId });
    }, []);

    return (
        <BaseLayout defaultBg topShade={topShade} bottomShade={bottomShade} fullscreen showLogo>
            <CustomerCarePageContainer func={navHorizontal}>
                <LoadingWrapper isFetching={isFetching ?? true} keepChildrenVisible>
                    <CustomerCareHelp
                        onFilterScroll={onFilterScroll}
                        onMore={handleMore}
                        data={data}
                    />
                </LoadingWrapper>
            </CustomerCarePageContainer>
        </BaseLayout>
    );
};

export default Help;
