import { PageContext } from '@/contexts/page/page.context';
import { Nav } from 'nav-tree';
import { RefObject, useContext, useEffect } from 'react';
import { useInitialRefocus } from './use-initial-refocus.hook';

export const usePageRefocus = ({ focusRef }: { focusRef: RefObject<Nav> }) => {
    const { refocusPage, setRefocusPage } = useContext(PageContext);

    useInitialRefocus({
        focusRef,
        enabled: refocusPage,
        focusIndex: [`1`, `1`],
    });

    useEffect(() => {
        if (refocusPage) {
            setRefocusPage(false);
        }
    }, [refocusPage]);
};
