import { WatchedContentDataSchema } from '@/interfaces/from-schemas/user-content/getLatelyWatchedContentDataListOut';
import CategoryContentModel from '@/models/category/category-content.model';
import { ListElementModel } from '@/models/list-element/list-element.model';
import { DATA_POSITION, TITLE_TYPE } from '@/types/list-element-metadata.type';

export const buildCategoryCatalogCollection = (
    data: CategoryContentModel,
    dataPosition?: DATA_POSITION,
    followMeContent?: WatchedContentDataSchema[],
    titleType?: TITLE_TYPE,
) => {
    const { col, postersCol } = process.env.grid;

    let columns;
    const collections = [];

    if (data.getCategoryList().length) {
        const newCollection = data.getCategoryList().map((result) => {
            const gridElement = new ListElementModel(result);
            dataPosition && gridElement.setDataPosition(dataPosition);
            titleType && gridElement.setTitleType(TITLE_TYPE.FULL);
            gridElement.updateFromFollowMeContent(`${result.getId()}`, followMeContent);
            return gridElement;
        });
        collections.push(newCollection);
    }

    if (data.getMediaList().length) {
        const imageDisplayMode = data.getImageDisplayMode();

        const newCollection = data.getMediaList().map((result) => {
            const isMovie = result.getIsMovie();

            if (isMovie) {
                result.imageDisplayMode = imageDisplayMode;
                columns = postersCol;
            }

            const gridElement = new ListElementModel(result);

            if (isMovie) {
                gridElement.setDataPosition(DATA_POSITION.UNDER);
            } else {
                dataPosition && gridElement.setDataPosition(dataPosition);
            }

            gridElement.updateFromFollowMeContent(result.getId(), followMeContent);
            return gridElement;
        });
        collections.push(newCollection);
    }

    return { newCollection: collections.flat(), columns: columns || col };
};
