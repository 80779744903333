import { usePlatform } from '@/contexts/platform/platform.hooks';
import { Platform } from '@/services/platform-recognition';
import { isStbTargets } from '@/constants/portal-recognition';

export const getUserAgentData = (platform?: Platform) => {
    let userAgentData = {
        ...process.env.defaultUserData.userAgentData,
    };

    if (isStbTargets) {
        userAgentData = {
            ...userAgentData,
            ...window.stb?.defaultUserData?.userAgentData,
        };
    }

    if (process.env.features.uadPlatformDetection && platform) {
        if (platform.deviceType) {
            userAgentData.deviceType = platform.deviceType;
        }
        if (platform.application) {
            userAgentData.application = platform.application;
        }
        if (platform.os) {
            userAgentData.os = platform.os;
        }
        if (platform.browserUserAgent) {
            userAgentData.osInfo = platform.browserUserAgent;
        }
    }

    if (process.env.GM_BUILD) {
        userAgentData.build = Number(process.env.GM_BUILD);
    }

    return userAgentData;
};

export const useUserAgentData = () => {
    const { platform } = usePlatform();
    return getUserAgentData(platform);
};
