import { createContext, useMemo, useRef, useState } from 'react';
import useGetSubcategories from '@/api/navigation/category/get-subcategories';
import { useGetHomeMenu } from '@/api/navigation/get-home-menu';
import { HomeMenuContextProps, HomeMenuContextProviderProps, SCROLL_DIRECTION } from './types';
import { useSticky } from '@/hooks/use-sticky.hook';
import { prepareStaticItems } from '@/helpers/home-menu/prepare-static-items.helper';
import { useConfiguration } from '../configuration/configuration.hooks';
import { Nav } from 'nav-tree';
import React from 'react';

export const HomeMenuContext = createContext<HomeMenuContextProps>({
    dynamicMenuItems: [],
    subcategories: undefined,
    isFetching: false,
    isSticky: false,
    scrollDirection: SCROLL_DIRECTION.TOP,
    menuFocused: false,
    setMenuFocused: () => null,
    menuItemsCombined: [],
    currentCategory: undefined,
    menuRef: null,
    shouldMaintainVisibility: false,
    setShouldMaintainVisibility: () => null,
});

const HomeMenuContextProvider = ({ children, currentCategory }: HomeMenuContextProviderProps) => {
    const { pre, post } = process.env.menu || { pre: [], post: [] };

    const [menuFocused, setMenuFocused] = useState(false);
    const menuRef = useRef<Nav>(null);
    const [shouldMaintainVisibility, setShouldMaintainVisibility] = useState<boolean | null>(null);

    const { data: dynamicMenuItems, isFetching } = useGetHomeMenu();
    const configuration = useConfiguration();
    const rootCategoryId = configuration?.navigation?.rootCategoryId;

    const { data: subcategories, isFetching: isFetchingSubcat } = useGetSubcategories({
        catid: rootCategoryId || 0,
    });

    const { isSticky, scrollDirection } = useSticky(
        shouldMaintainVisibility,
        setShouldMaintainVisibility,
    );

    const menuItemsCombined = useMemo(
        () => [
            ...prepareStaticItems(pre),
            ...(dynamicMenuItems || []),
            ...prepareStaticItems(post),
        ],
        [dynamicMenuItems],
    );

    return (
        <HomeMenuContext.Provider
            value={{
                dynamicMenuItems,
                subcategories,
                isFetching: isFetching || isFetchingSubcat,
                isSticky,
                scrollDirection,
                menuFocused,
                setMenuFocused,
                menuRef,
                menuItemsCombined,
                currentCategory,
                shouldMaintainVisibility,
                setShouldMaintainVisibility,
            }}
        >
            {children}
        </HomeMenuContext.Provider>
    );
};

export default HomeMenuContextProvider;
