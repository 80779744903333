import useCheckProductAccess from '@/api/drm/use-check-product-access';
import useGetMedia from '@/api/navigation/use-get-media';
import BaseLayout from '@/components/shared/layouts/BaseLayout';
import { TVPlayer } from '@/components/shared/Player';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { CPID } from '@/types/media.type';
import { useMemo } from 'react';
import { useRouter } from '@/routing';

const tryOr = <T,>(foo: () => T, fallback: T, validate?: (value: T) => boolean) => {
    try {
        const value = foo();
        if (typeof validate !== 'undefined' && !validate(value)) {
            throw new Error('validation failed');
        }
        return value;
    } catch (e) {
        return fallback;
    }
};

const ChannelsWatch = () => {
    const { query } = useRouter();
    const { channelId } = query;
    const startTime = tryOr(
        () => Number.parseInt(query.startTime),
        undefined,
        (value) => !!value && value > 0,
    );
    const { data: mediaListItem } = useGetMedia({
        mediaId: channelId,
        cpid: CPID.EVENT_OR_CHANNEL,
    });
    const product = mediaListItem?.getProduct();
    const { data: productAccess } = useCheckProductAccess(product ? { product } : undefined);
    const mediaDetails = useMemo(
        () => new MediaDetailsModel(mediaListItem, productAccess, undefined),
        [mediaListItem, productAccess],
    );

    return (
        <BaseLayout fullscreen>
            <TVPlayer
                startTime={startTime}
                mediaDetails={mediaDetails}
                channelId={channelId}
                channelTitle={mediaDetails.title}
            />
        </BaseLayout>
    );
};

export default ChannelsWatch;
