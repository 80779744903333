import t from '@/lib/i18n';
import { GUTTER } from '@/types/gutter.type';
import { navHorizontal } from 'nav-tree';
import React, { useState } from 'react';
import {
    BorderWithText,
    BorderWithTextInside,
    BorderWithTextLabel,
    ButtonContent,
    ButtonRightSideContent,
    Border,
    Description,
    Duration,
    Label,
    LabelContainer,
    Price,
    PriceContainer,
    PurchaseButtonContainer,
    RightSideContentBorder,
    OldPrice,
} from './styles';
import { PurchaseButtonProps } from './types';

export const PurchaseButton = ({
    label,
    priceLabel,
    oldPriceLabel,
    durationLabel,
    rightSideLabel,
    description,
    trialDuration,
    isPromotion,
    gutter = GUTTER.NORMAL,
    defaultFocused,
    onButtonAction,
    onRightSideAction,
}: PurchaseButtonProps) => {
    const [activeContent, setActiveContent] = useState(false);
    const [activeRightSideContent, setActiveRightSideContent] = useState(false);

    const ContentBorder = () => {
        if (isPromotion || trialDuration) {
            const label = trialDuration
                ? t('payment-steps.trial-info', { trialDuration })
                : t('payment-steps.promotion');

            return (
                <BorderWithText
                    active={activeContent}
                    isRightSideLabel={Boolean(rightSideLabel)}
                    isRightSideLabelActive={activeRightSideContent}
                >
                    <BorderWithTextInside active={activeContent}>
                        <BorderWithTextLabel>{label}</BorderWithTextLabel>
                    </BorderWithTextInside>
                </BorderWithText>
            );
        }

        return (
            <Border
                active={activeContent}
                isRightSideLabel={Boolean(rightSideLabel)}
                isRightSideLabelActive={activeRightSideContent}
            />
        );
    };

    return (
        <PurchaseButtonContainer func={navHorizontal} $gutter={gutter}>
            <ButtonContent
                onNav={setActiveContent}
                onEnterDown={onButtonAction}
                onMouseDown={onButtonAction}
                defaultFocused={defaultFocused}
                autofocusOn={['mouseenter', 'focus']}
            >
                <ContentBorder />

                <LabelContainer>
                    <Label>{label}</Label>
                    {description && <Description>{description}</Description>}
                </LabelContainer>

                <PriceContainer>
                    {oldPriceLabel && <OldPrice>{oldPriceLabel}</OldPrice>}
                    <Price>{priceLabel}</Price>
                    {durationLabel && <Duration>{durationLabel}</Duration>}
                </PriceContainer>
            </ButtonContent>

            {rightSideLabel && (
                <ButtonRightSideContent
                    $active={activeRightSideContent}
                    onNav={setActiveRightSideContent}
                    onEnterDown={onRightSideAction}
                    onMouseDown={onRightSideAction}
                    autofocusOn={['mouseenter', 'focus']}
                >
                    <RightSideContentBorder active={activeRightSideContent} />
                    {rightSideLabel}
                </ButtonRightSideContent>
            )}
        </PurchaseButtonContainer>
    );
};
