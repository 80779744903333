import styled from 'styled-components';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';

export const BgContainerOuterWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.background};
`;

export const BgContainerInnerWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const BgContainerImage = styled.img`
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.2);
    transition: ${CheckSlowDevice('opacity 0.3s ease-in-out')};
`;
