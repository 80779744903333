import { Nav, NavTree } from 'nav-tree';
import { RefObject, useEffect } from 'react';

export const useInitialRefocus = ({
    focusRef,
    enabled = true,
    focusIndex,
    onFocus,
}: {
    focusRef: RefObject<Nav>;
    enabled?: boolean;
    focusIndex?: string[];
    onFocus?: () => void;
}) => {
    useEffect(() => {
        if (!enabled) return;

        const tree: NavTree | undefined = focusRef?.current?.getChildContext().tree;
        const focusPath = tree?.getFocusedPath?.();

        if (tree && focusPath) {
            if (focusIndex !== undefined) {
                tree.focus(focusIndex);
            } else {
                tree.focus(focusPath);
            }
            onFocus?.();
        }
    }, [focusRef, enabled]);
};
