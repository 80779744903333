import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
    useState,
} from 'react';
import { batchedUpdates } from '@/helpers/batched-updates.helper';

const initialContextValue = {
    firstColumn: { index: -1, id: '' },
    lastColumn: { index: -1, id: '' },
    resetContext: () => null,
    setFirstColumn: () => null,
    setLastColumn: () => null,
};

const ColumnContext = createContext<{
    firstColumn: { index: number; id: string };
    lastColumn: { index: number; id: string };
    resetContext: () => void;
    setFirstColumn: Dispatch<SetStateAction<{ index: number; id: string }>>;
    setLastColumn: Dispatch<SetStateAction<{ index: number; id: string }>>;
}>(initialContextValue);

export const ColumnContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [lastColumn, setLastColumn] = useState({ index: -1, id: '' });
    const [firstColumn, setFirstColumn] = useState({ index: -1, id: '' });

    const resetContext = useCallback(() => {
        batchedUpdates(() => {
            setLastColumn({ index: -1, id: '' });
            setFirstColumn({ index: -1, id: '' });
        });
    }, []);

    return (
        <ColumnContext.Provider
            value={{
                lastColumn,
                setLastColumn,
                firstColumn,
                setFirstColumn,
                resetContext,
            }}
        >
            {children}
        </ColumnContext.Provider>
    );
};

export function useColumnContext() {
    const context = useContext(ColumnContext);
    if (context === undefined) {
        throw new Error('useColumnContext must be used within a ColumnContextProvider');
    }
    return context;
}
