import {
    GetStaffRecommendationsListsOut,
    StaffRecoListSchema,
} from '../../interfaces/from-schemas/navigation/getStaffRecommendationsListsOut';
import { StaffRecommendationListModel } from './staff-recommendation-list.model';

export class StaffRecommendationListsModel {
    private readonly results: StaffRecommendationListModel[] = [];

    constructor(definition: GetStaffRecommendationsListsOut) {
        if (definition) {
            this.results = [];
            definition.forEach((value: StaffRecoListSchema) => {
                this.results.push(new StaffRecommendationListModel(value));
            });
        }
    }

    public getResults(): StaffRecommendationListModel[] {
        return this.results;
    }
}
