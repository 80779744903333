import { LiveControls } from './controls';
import { ILivePlayerProps } from './types';
import { PlayerBase } from './PlayerBase';
import { CPID } from '@/types/media.type';
import { useMemo } from 'react';

export const LivePlayer = ({ mediaId, mediaDetails }: ILivePlayerProps) => {
    const load = useMemo(
        () => ({
            media: {
                gmID: {
                    cpid: CPID.EVENT_OR_CHANNEL,
                    id: mediaId,
                },
            },
        }),
        [mediaId],
    );
    const customOptions: IPlayer.PlayerConfigOptions = useMemo(
        () => ({
            forceAdaptiveStreaming: false,
        }),
        [],
    );

    return (
        <PlayerBase load={load} mediaDetails={mediaDetails} customOptions={customOptions}>
            <LiveControls />
        </PlayerBase>
    );
};
