import { fillIcon } from '@/helpers/fill-icon.helper';
import styled from 'styled-components';

export const RulesHeader = styled.div`
    font-size: ${({ theme }) => theme.text.size.rulesHeader};
    font-weight: 300;
    text-align: center;
    padding: 0 0 6rem 0;
    color: ${({ theme }) => theme.text.color.secondary};
`;

export const IconWrapper = styled.div`
    display: inline-block;
    margin-right: 1rem;
    width: ${({ theme }) => theme.text.size.rulesHeader};
    height: ${({ theme }) => theme.text.size.rulesHeader};
    ${({ theme }) => fillIcon(theme.text.color.primary)};
`;

export const RulesHighlight = styled.span`
    color: ${({ theme }) => theme.text.color.highlight};
`;

export const RulesContainerStyled = styled.div`
    font-size: ${({ theme }) => theme.text.size.rulesDescription};
    line-height: ${({ theme }) => theme.text.size.rulesLineHeight};
    text-align: left;

    hr + strong {
        text-align: left;
        display: block;
    }

    p,
    span {
        color: ${({ theme }) => theme.text.color.rulesText};
    }

    strong {
        color: ${({ theme }) => theme.text.color.rulesHeader};
    }

    hr + br + div > span {
        font-size: ${({ theme }) => theme.text.size.rulesTitle};
        color: ${({ theme }) => theme.text.color.rulesHeader};
        text-align: left;
        display: block;
    }

    br + div > span {
        font-size: ${({ theme }) => theme.text.size.rulesHeader};
        color: ${({ theme }) => theme.text.color.rulesHeader};
        text-align: left;
        display: block;
    }

    div > strong,
    p > strong {
        color: ${({ theme }) => theme.text.color.rulesHeader};
    }

    p {
        text-align: left;
    }

    hr {
        display: none;
    }
`;
