import { GetLiveChannelsFlatNavigationIn } from '@/interfaces/from-schemas/navigation/getLiveChannelsFlatNavigationIn';
import { GET_MEDIA_NAMESPACES, GET_MEDIA_METHODS } from '@/types/get-media-method.type';
import { FilterItemInterface } from '@/interfaces/filters-interface';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { IApiResponse } from '@/hooks/use-get-media-api.type';
import defaultKeyHook from '@/hooks/default-key';

export const useGetLiveFilterList = (
    params: GetLiveChannelsFlatNavigationIn,
): IApiResponse<FilterItemInterface[]> => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.NAVIGATION,
        GET_MEDIA_METHODS.FETCH_EVENT_CHANNEL_WITH_FLAT_NAVIGATION,
        params,
    );

    const { data, error, isFetching } = useGetMediaApi(keyHook, {
        appendAuthData: true,
    });

    return {
        data: data?.treeNavigation?.filters && data.treeNavigation.filters,
        error,
        isFetching,
    };
};
