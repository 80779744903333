import { ERROR_CODES_GET_MEDIA } from '@/constants/error-codes-get-media';
import { ChangeEmailOut } from '@/interfaces/from-schemas/auth/changeEmailOut';
import { RegisterOut } from '@/interfaces/from-schemas/auth/registerOut';
import ResponseErrorModel from '@/models/response-error.model';
import { Router } from '@/routing';
import { IParams, ROUTES } from '@/routing/types';
import { AUTH_PROVIDERS } from '@/types/auth-providers.type';
import { isExternalAuthProvider } from './auth-providers.helper';

export const checkAccountExistsException = (
    result:
        | { ok: true; data: RegisterOut | ChangeEmailOut }
        | { ok: false; error: ResponseErrorModel },
    authProvider?: AUTH_PROVIDERS,
    query?: IParams,
) => {
    const { B2C_USER_EXISTS_EXCEPTION } = ERROR_CODES_GET_MEDIA;

    if (!result.ok && result.error.getCode() === B2C_USER_EXISTS_EXCEPTION) {
        const email = result.error.getEmail();

        if (isExternalAuthProvider(authProvider)) {
            // Router next przekazuje queryParams jako drugi argument, router standalone jako trzeci
            const queryParams = { authProvider, email, ...query };
            return Router.pushRoute(ROUTES.ACCOUNT_EXISTS, queryParams, queryParams);
        }

        const accountType = result.error.getExistingAccounts()?.[0];

        if (accountType) {
            const queryParams = { ...query, accountType, email };

            return Router.pushRoute(ROUTES.REGISTRATION_ACCOUNT_EXISTS, queryParams);
        }
    }
};
