import styled, { css } from 'styled-components';
import { Nav } from 'nav-tree';
import { ViewInterface } from './types';
import { LIST_TYPE } from '@/types/recommendation-list.type';
import { CheckSlowDevice } from '@/helpers/check-slow-device.helper';
import { Z_INDEX } from '@/targets/default/theme';
import { PlayableDynamicButton } from '@/components/shared/Buttons';

const buildMainContainer = css`
    height: 100%;
    position: relative;
    padding: 4.9rem 0 0 15rem;
    overflow: hidden;
`;

export const StyledMainContainer = styled(Nav)`
    ${buildMainContainer}
`;

export const StyledMainNavigation = styled(Nav)`
    ${buildMainContainer}
`;

export const StyledContent = styled.div`
    position: absolute;
`;

export const StyledData = styled.div<ViewInterface>`
    position: absolute;
    bottom: ${({ view }) => (view === LIST_TYPE.PROMOBOX ? '0rem' : '4.5rem')};
    width: 130rem;
    white-space: nowrap;
`;

export const StyledMetadataWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 2rem;
`;

export const StyledDot = styled.div`
    margin-left: 0.8rem;
`;

export const StyledCollectionsList = styled.div`
    height: 51.8rem;
    position: relative;
    left: -15rem;
`;

export const StyledGrid = styled.div<{ hideHeader?: boolean }>`
    height: ${({ hideHeader }) => (hideHeader ? '91.8rem;' : '51.8rem;')}
    position: relative;
`;

export const StyledPageContent = styled.div<ViewInterface>`
    position: absolute;
    top: ${({ view, isCatalog, hideHeader, theme }) => {
        if (hideHeader) {
            return '14.5rem';
        }

        return view === LIST_TYPE.PROMOBOX ? theme.promobox.top : isCatalog ? '60.5rem' : '56.5rem';
    }};
    transition: ${CheckSlowDevice('top 0.4s')};
    width: 100%;
`;

export const CenteredMessage = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: ${Z_INDEX.FILTERS_ALERT};
`;
