import defaultKeyHook from '@/hooks/default-key';
import { GetMediaPromiseFunc, IApiResponse } from '@/hooks/use-get-media-api.type';
import { useGetMediaApi } from '@/hooks/use-get-media-api.hook';
import { GetHomeMenuOut } from '@/interfaces/from-schemas/navigation/getHomeMenuOut';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { HomeMenuItemModel } from '@/models/home-menu-item.model';
import { useMemo } from 'react';

const keyHook = defaultKeyHook(
    GET_MEDIA_NAMESPACES.NAVIGATION,
    GET_MEDIA_METHODS.GET_HOME_MENU,
    {},
);

export const useGetHomeMenu = (): IApiResponse<HomeMenuItemModel[]> => {
    const { data, isFetching, error } = useGetMediaApi(keyHook, {
        appendAuthData: true,
        queryOptions: {
            refetchOnMount: 'always',
        },
    });

    const homeMenu = useMemo(
        () => (data as GetHomeMenuOut)?.map((item) => new HomeMenuItemModel(item)),
        [data],
    );

    return {
        data: homeMenu,
        isFetching,
        error,
    };
};

export const getHomeMenuPromise = (getMediaApiPromise: GetMediaPromiseFunc<HomeMenuItemModel[]>) =>
    getMediaApiPromise(keyHook, {}).then((res) => res.data);
