import styled from 'styled-components';

import { Z_INDEX } from '@/targets/default/theme';

export const ProductCardStyled = styled.div`
    z-index: ${Z_INDEX.MENU_CONTAINER};
    text-align: center;
    justify-content: center;

    img {
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ProductDescriptionContainer = styled.div`
    margin-top: 3rem;
`;

export const ProductAccessContainer = styled.div`
    padding-bottom: 3rem;
`;

export const ProductPlatformsContainer = styled.div`
    display: inline-block;
    margin: 0 1rem;
`;

export const ProductMetadataIconsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;

    & > *:not(:last-child) {
        margin-right: 1rem;
    }
`;
