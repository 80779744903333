import { MutableRefObject, useEffect } from 'react';
import { Nav, NavTree } from 'nav-tree';

export const useFocusPath = ({
    focusRef,
    onFocusPath,
    enabled = true,
}: {
    focusRef: MutableRefObject<Nav>;
    onFocusPath: (path: string[]) => void;
    enabled?: boolean;
}) => {
    useEffect(() => {
        if (!enabled) return;

        const tree: NavTree = focusRef?.current?.getChildContext().tree;

        if (tree && tree.id) {
            onFocusPath([tree.id]);
        }
    }, [focusRef, enabled]);
};
