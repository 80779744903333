import { useGetMediaApiMutation } from '../../hooks/use-get-media-api.hook';
import defaultKeyHook from '../../hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '../../types/get-media-method.type';
import { ChangePasswordOut } from '../../interfaces/from-schemas/auth/changePasswordOut';

interface IUseChangePassword {
    oldPassword: string;
    newPassword: string;
}

const useChangePassword = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.CHANGE_PASSWORD,
        null,
    );

    return useGetMediaApiMutation<IUseChangePassword, ChangePasswordOut>(keyHook, {
        appendAuthData: true,
    });
};

export default useChangePassword;
