import { BUTTON_ID } from '@/types/button-id.type';
import t from '@/lib/i18n';
import { MediaDetailsModel } from '@/models/media/media-details.model';
import { CategoryModel } from '@/models/category/category.model';
import { MediaButtonInterface } from '@/interfaces/media-button.interface';
import { ICONS } from '@/components/shared/Icon';
import { Router } from '@/routing';
import { ROUTES } from '@/routing/types';
import { getBestImageMatch } from '@/utils/get-best-image-match';
import { protectURL } from '@/helpers/rewrite-protocol.helper';
import { ImageSourceSchema } from '@/interfaces/from-schemas/system/getAvatarsOut';
import { backgroundUtil } from '@/utils/background.utils';

export class ChronologicalNonChronologicalModel {
    private additionalContent?: string;
    private description = '';
    private thumbnails?: ImageSourceSchema[] = [];
    private title = '';

    constructor(
        private media: MediaDetailsModel,
        private category: CategoryModel,
        private isMainCategory: boolean,
        private isFirstContact: boolean,
    ) {
        this.init();
    }

    private init(): void {
        // KATEGORIA SEKWENCYJNA
        if (this.isChronological) {
            this.title = this.category.getTitle();
            this.additionalContent = this.seasonEpisodeMetadata();

            // PIERWSZY KONTAKT Z KATEGORIĄ
            if (this.isFirstContact) {
                this.thumbnails = this.media.seasonThumbnails || this.category.getThumbnails();

                // STRONA KATEGORII
                if (this.isMainCategory) {
                    this.description = this.category.getDescription();
                }

                // STRONA MATERIAŁU
                else {
                    this.description = this.media.description;
                }
            }

            // W TRAKCIE OGLĄDANIA
            else {
                // STRONA KATEGORII i STRONA MATERIAŁU

                this.thumbnails = this.media.seasonThumbnails || this.category.getThumbnails();
                this.description = this.media.description;
            }
        }

        // KATEGORIA NIESEKWENCYJNA
        else {
            this.title = this.category.getTitle();
            const categoryThumbnails = this.category.getThumbnails();
            const hasCategoryThumbnails = categoryThumbnails.length > 0;

            // PIERWSZY KONTAKT Z KATEGORIĄ
            if (this.isFirstContact) {
                // STRONA KATEGORII
                if (this.isMainCategory) {
                    this.thumbnails = this.media.seasonThumbnails || categoryThumbnails;
                    this.description = this.category.getDescription();
                    this.additionalContent = `${t(
                        'pages.chronologicalNonChronological.last-added',
                    )}: ${this.media.title}`;
                }

                // STRONA MATERIAŁU
                else {
                    this.thumbnails = hasCategoryThumbnails
                        ? categoryThumbnails
                        : this.media.thumbnails;
                    this.title = this.media.title;
                    this.description = this.media.description;
                }
            }

            // W TRAKCIE OGLĄDANIA
            else {
                this.description = this.media.description;
                // STRONA KATEGORII
                if (this.isMainCategory) {
                    this.thumbnails = this.media.seasonThumbnails || categoryThumbnails;

                    this.title = this.category.getTitle();
                    this.additionalContent = `${t(
                        'pages.chronologicalNonChronological.last-watched',
                    )}: ${this.media.title}`;
                }

                // STRONA MATERIAŁU
                else {
                    this.thumbnails = this.thumbnails = hasCategoryThumbnails
                        ? categoryThumbnails
                        : this.media.thumbnails;
                    this.title = this.media.title;
                }
            }
        }
    }

    public get isChronological(): boolean {
        return this.category.isChronological();
    }

    public getTitle(): string {
        return this.title || '';
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        if (imgWidth !== undefined && this.thumbnails) {
            return protectURL(getBestImageMatch(this.thumbnails, imgWidth, imgHeight).src || '');
        }

        if (this.thumbnails) {
            return backgroundUtil(this.thumbnails)?.src || '';
        }

        return '';
    }

    public getDescription(): string {
        return this.description;
    }

    public getAdditionalContent(): string | undefined {
        return this.additionalContent;
    }

    public get fullDetailsButton(): MediaButtonInterface {
        return {
            id: BUTTON_ID.INFO,
            icon: ICONS.INFO,
            text: t('models.media-details.more'),
            onButtonAction: () => this.goToFullDetails(),
            'data-testing': 'models.media-details.more',
        };
    }

    public get moreActionButtons(): MediaButtonInterface[] {
        return [this.fullDetailsButton, this.media.favoritesButton];
    }

    public goToFullDetails(): void {
        const route = ROUTES.VOD_EPISODE_CATEGORY_AUTOPLAY_DETAILS;
        const params = this.category.getRouteParams();

        Router.pushRoute(route, params);
    }

    public seasonEpisodeMetadata(): string | undefined {
        const seasonNo = this.media.getCategory?.seasonNumber;
        const episodeNo = this.media.episodeNo;
        const episodeTitle = this.media.episodeTitle;
        const shortTitle = this.media.shortTitle;
        const title = episodeTitle || shortTitle || this.getTitle();

        let text = '';

        if (seasonNo) {
            text = `S${seasonNo} :`;
            if (episodeNo) {
                text += ` O${episodeNo} ${episodeTitle}`;
            } else {
                text += ` ${title}`;
            }
        } else {
            if (title) {
                text += title;
            }
        }

        return text;
    }
}
