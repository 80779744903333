export enum AVATAR_SIZE {
    SMALL = 4.5,
    MEDIUM = 9.5,
    BIG = 17,
}

export interface AvatarProps {
    $size: AVATAR_SIZE;
    url?: string;
    text?: string;
    active?: boolean;
    responsive?: boolean;
    sliderStyle?: boolean;
}

export interface StyledAvatarInterface {
    $size: number;
    active?: boolean;
    responsive?: boolean;
    sliderStyle?: boolean;
}
