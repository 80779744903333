import React from 'react';
import { MainText, MAINTEXT_KIND, MAINTEXT_RESPONSIVE_KIND } from '@/components/shared/MainText';
import {
    LiveBadge,
    OnAirBadge,
    METADATA_RESPONSIVE_KIND,
    METADATA_TYPES,
} from '@/components/shared/Metadata';
import { ChannelModel } from '@/models/channels/channel.model';
import { GUTTER } from '@/types/gutter.type';
import { ListElementMetadataInterface } from '../types';
import { MetadataWithBadge } from './styles';
import { METADATA_TYPE } from '@/types/media.type';

export const ListElementMetadata = ({ itemData, responsive }: ListElementMetadataInterface) => {
    const titleText = itemData.getTitleText();
    const titleMaxLines = itemData.getTitleMaxLines();
    const displayMetadata = itemData.getDisplayMetadata();
    const displayTitle = itemData.getDisplayTitle();
    const metadataText = itemData.getMetadataText(METADATA_TYPE.LIST_ELEMENT);
    const isOnAir = itemData.isOnAir();
    const isLive = itemData.isLive() && itemData.getAsset() instanceof ChannelModel;
    const showMetadata = itemData.getShowDetailedMetadata() || itemData.getShowMetadata();

    const badgeMode = responsive
        ? METADATA_RESPONSIVE_KIND.LIST_ELEMENT_METADATA
        : METADATA_TYPES.COPYRIGHT;
    const titleMode = responsive
        ? MAINTEXT_RESPONSIVE_KIND.LIST_ELEMENT_TITLE
        : MAINTEXT_KIND.LIST_ELEMENT;
    const metadataMode = responsive
        ? MAINTEXT_RESPONSIVE_KIND.LIST_ELEMENT_METADATA
        : MAINTEXT_KIND.COPYRIGHT;

    const metadataMainText = (
        <MainText
            text={metadataText}
            mode={metadataMode}
            lines={titleMaxLines}
            gutter={GUTTER.NONE}
            responsive={responsive}
        />
    );

    const buildMetadata = () => {
        if (isOnAir || isLive) {
            return (
                <MetadataWithBadge>
                    {metadataMainText}
                    {isOnAir && <OnAirBadge mode={badgeMode} responsive={responsive} />}
                    {isLive && <LiveBadge mode={badgeMode} responsive={responsive} />}
                </MetadataWithBadge>
            );
        }

        return metadataMainText;
    };

    return (
        <>
            {showMetadata && displayMetadata && buildMetadata()}

            {displayTitle && (
                <MainText
                    lines={titleMaxLines}
                    mode={titleMode}
                    text={titleText}
                    gutter={GUTTER.NONE}
                    responsive={responsive}
                />
            )}
        </>
    );
};
