import defaultKeyHook from '@/hooks/default-key';
import { GET_MEDIA_METHODS, GET_MEDIA_NAMESPACES } from '@/types/get-media-method.type';
import { useGetMediaApiMutation } from '@/hooks/use-get-media-api.hook';
import { ConfirmConnectionIn } from '@/interfaces/from-schemas/auth/confirmConnectionIn';
import { ConfirmConnectionOut } from '@/interfaces/from-schemas/auth/confirmConnectionOut';

const useConfirmConnection = () => {
    const keyHook = defaultKeyHook(
        GET_MEDIA_NAMESPACES.AUTH,
        GET_MEDIA_METHODS.CONFIRM_CONNECTION,
        null,
    );

    return useGetMediaApiMutation<Omit<ConfirmConnectionIn, 'authData'>, ConfirmConnectionOut>(
        keyHook,
        {
            appendAuthData: true,
            updateAuthSession: true,
        },
    );
};

export default useConfirmConnection;
