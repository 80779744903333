import { useCallback, useContext, useEffect } from 'react';
import { useRouter } from '@/routing';
import { BackgroundContext } from '@/contexts/background/background.context';
import { SHADE_MODE } from '@/components/shared/BackgroundImage';
import { ROUTES } from '@/routing/types';

const clearBcgRoutes = [
    ROUTES.VOD_MOVIE_WATCH,
    ROUTES.VOD_EPISODE_WATCH,
    ROUTES.VOD_EPISODE_WITH_SEASON_WATCH,
    ROUTES.VOD_WATCH,
    ROUTES.LIVE_WATCH,
    ROUTES.CHANNEL_TV_WATCH,
    ROUTES.WELCOME,
];

export const useClearBackgroundContext = () => {
    const router = useRouter();
    const route = router.route as ROUTES;
    const { setShadeMode } = useContext(BackgroundContext);

    const isClearRoute = useCallback((route: ROUTES) => {
        return clearBcgRoutes.includes(route);
    }, []);

    useEffect(() => {
        if (isClearRoute(route)) {
            setShadeMode(SHADE_MODE.CUSTOM);
        }
    }, [route]);
};
