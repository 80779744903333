import { ErrorContainer, ErrorInnerContainer, FlexBox, TopBarContainer } from './styles';
import { Message } from '@/components/shared/Message';
import t from '@/lib/i18n';
import React from 'react';
import { PlayableButton } from '@/components/shared/Buttons';
import { Nav } from 'nav-tree';
import { Router } from '@/routing';
import { ICONS } from '@/components/shared/Icon';
import { useConcurrentPlaybackError } from '@/hooks/player/use-concurrent-playback-error';
import { ConcurrentPlaybackErrorTable } from './ConcurrentPlaybackErrorTable';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';

export const ConcurrentPlaybackErrorOverlay = () => {
    const {
        playbacks,
        showBuffering,
        error,
        isPlaybackPending,
        handleStopPlayback,
    } = useConcurrentPlaybackError();

    const {
        isCacError,
        isWebSocketError,
        isCacConnectionError,
        isCacDRMExceededError,
    } = CyfrowyPlayer.CPError;

    const WSConnectionError = isWebSocketError(error) || isCacConnectionError(error);

    // TODO: Poinformowanie użytkownika o nieudanej próbie zatrzymania zdalnego odtwarzania
    const stopPlaybackFailure = false;

    if (showBuffering) {
        return null;
    }

    if (WSConnectionError) {
        return (
            <ErrorContainer lockNavInside>
                <ErrorInnerContainer>
                    <Message
                        title={t('player.cac.ws-connection-error.title')}
                        description={t('player.cac.ws-connection-error.description', {
                            domain: process.env.domain,
                            helpdeskEmail: process.env.contacts.helpdeskEmail.label,
                        })}
                    />
                    <PlayableButton
                        text={t('player.cac.ws-connection-error.ok')}
                        onButtonAction={() => Router.back()}
                        defaultFocused
                    />
                </ErrorInnerContainer>
            </ErrorContainer>
        );
    }

    if (isCacDRMExceededError(error)) {
        return (
            <ErrorContainer lockNavInside>
                <Nav>
                    <TopBarContainer>
                        <FlexBox horizontalMargin="100%">
                            <PlayableButton
                                icon={ICONS.CLOSE}
                                onButtonAction={() => Router.back()}
                            />
                        </FlexBox>
                    </TopBarContainer>

                    <ErrorInnerContainer>
                        <Message
                            title={t('player.cac.limit-exceeded.title')}
                            description={t('player.cac.limit-exceeded.description')}
                        />

                        <ConcurrentPlaybackErrorTable
                            playbacks={playbacks}
                            isPlaybackPending={isPlaybackPending}
                            onStopPlayback={handleStopPlayback}
                        />
                    </ErrorInnerContainer>
                </Nav>
                {stopPlaybackFailure && (
                    <AlertMessage
                        type={MESSAGE_TYPE.ERROR}
                        userMessage={t('player.cac.limit-exceeded.error')}
                    />
                )}
            </ErrorContainer>
        );
    }

    if (isCacError(error)) {
        return (
            <ErrorContainer lockNavInside>
                <ErrorInnerContainer>
                    <Message title={error?.description} />

                    <PlayableButton
                        text={t('ok-button-label')}
                        onButtonAction={() => Router.back()}
                        defaultFocused
                    />
                </ErrorInnerContainer>
            </ErrorContainer>
        );
    }

    return null;
};
