import React from 'react';
import { PlayableButton } from '@/components/shared/Buttons';
import { SwitchCheckbox } from '@/components/shared/SwitchCheckbox';
import t from '@/lib/i18n';
import { useCallback } from 'react';
import { ConsentsListItemStyled, HeaderContainer } from './styles';
import { Rule, RuleWithStatus } from './types';

const ConsentsListItemRaw = ({
    rule,
    defaultFocused,
    onToggle,
    onMore,
}: {
    rule: RuleWithStatus;
    defaultFocused?: boolean;
    onToggle?: (rule: RuleWithStatus) => void;
    onMore?: (ruleId: Rule) => void;
}) => {
    const source = rule.sources.find((source) => source.type === 'html' || source.type === 'txt');
    const hasSource = !!source;
    const handleToggle = useCallback(() => {
        onToggle?.(rule);
    }, [onToggle, rule]);

    const handleMore = useCallback(() => {
        onMore?.(rule);
    }, [onMore, rule]);

    return (
        <>
            <ConsentsListItemStyled
                autofocusOn={['focus', 'mouseenter']}
                defaultFocused={defaultFocused}
                onEnterDown={handleToggle}
                style={{ marginBottom: hasSource ? '2rem' : '4rem' }}
            >
                <HeaderContainer>
                    <h2>{rule.description}</h2>
                    <SwitchCheckbox
                        id={`rule-switch-${rule.id}`}
                        checked={rule.status === 'accepted'}
                        onClick={handleToggle}
                    />
                </HeaderContainer>
                <p>{rule.agreeDescription}</p>
            </ConsentsListItemStyled>
            {hasSource && (
                <PlayableButton
                    style={{ width: '20rem', margin: '0 0 4rem 4rem' }}
                    onButtonAction={handleMore}
                    text={t('more-button-label')}
                />
            )}
        </>
    );
};

export const ConsentsListItem = React.memo(ConsentsListItemRaw);
