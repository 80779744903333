import { createContext, useCallback, useMemo, useState } from 'react';
import { GridMemoizedData, CatalogContextProps, CatalogContextProviderProps } from './types';

export const CatalogContext = createContext<CatalogContextProps>({
    grid: { activeIndex: 0, rowIndex: 0, colIndex: 0, filters: [], focusMap: { init: [] } },
    setGrid: () => null,
    clearContext: () => null,
});

const CatalogContextProvider = ({ children }: CatalogContextProviderProps) => {
    const gridInit = {
        activeIndex: 0,
        rowIndex: 0,
        colIndex: 0,
        filters: [],
        focusMap: { init: ['1', '1'] },
    };
    const [grid, setGrid] = useState<GridMemoizedData>(gridInit);

    const clearContext = useCallback(
        () => setGrid({ ...gridInit, focusMap: { init: ['1', '1'] } }),
        [],
    );

    return useMemo(
        () => (
            <CatalogContext.Provider value={{ grid, setGrid, clearContext }}>
                {children}
            </CatalogContext.Provider>
        ),
        [grid, clearContext, children],
    );
};

export default CatalogContextProvider;
