import { BufferingContainer } from './styles';
import { useShowBuffering } from '@/hooks/player/use-show-buffering';
import { ProcessingLoader } from '@/components/shared/Loader';
import { useDebouncedState } from '@/hooks/use-debounced-state.hook';
import { useBandwidth } from '@/hooks/player/use-bandwidth';
import t from '@/lib/i18n';

export const BufferingOverlay = () => {
    const showBuffering = useShowBuffering();
    const mountBuffering = useDebouncedState(showBuffering, (show) => (show ? 0 : 300));
    const visible = useDebouncedState(showBuffering, (show) => (show ? 100 : 0));
    const { bandwidthText } = useBandwidth();

    if (!mountBuffering) {
        return null;
    }

    return (
        <BufferingContainer visible={visible} data-testing="buffering.container">
            {/* TODO: Można zrobić podkradanie focusa kiedy włącza się buforowanie (skorzystać z lockNavInside) */}
            {/* {showBuffering && <Nav restoreLastFocus defaultFocused func={() => null} />} */}
            <ProcessingLoader
                isFetching
                noDelay
                highlight
                text={t('loader-text')}
                description={bandwidthText}
            />
        </BufferingContainer>
    );
};
