import { useLoginWithDecoder } from '@/api/auth/login-with-decoder';
import { PlayableButton } from '@/components/shared/Buttons';
import { FormContainer } from '@/components/shared/FullscreenNavContainer';
import { MainText, MAINTEXT_KIND } from '@/components/shared/MainText';
import React, { useState } from 'react';
import { withProviders } from '@/helpers/auth/auth-providers.helper';
import { GUTTER } from '@/types/gutter.type';
import t from '@/lib/i18n';
import { ProcessingLoader } from '@/components/shared/Loader/ProcessingLoader';
import { AlertMessage, MESSAGE_TYPE } from '@/components/shared/AlertMessage';

export const WelcomeBodyStb = () => {
    const { loginWithDecoder, loginError, isFetching } = useLoginWithDecoder();

    return (
        <>
            <MainText
                text={t('pages.welcome.no-account-instruction')}
                mode={MAINTEXT_KIND.DESCRIPTION}
            />
            <ProcessingLoader inline isFetching={!!isFetching} />
            <FormContainer>
                <PlayableButton
                    defaultFocused
                    text={t('pages.welcome.sign-in')}
                    onButtonAction={loginWithDecoder}
                    gutter={withProviders ? GUTTER.NORMAL : GUTTER.BIG}
                    data-testing="login-standard"
                />
            </FormContainer>
            {!!loginError && (
                <AlertMessage
                    userMessage={loginError.getUserMessage() || ''}
                    type={MESSAGE_TYPE.ERROR}
                    responsive
                />
            )}
        </>
    );
};
