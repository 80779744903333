import { Debug } from '@/utils/debug.util';
import { useCallback } from 'react';
import { usePlayerInstance } from './use-player-instance';
import { useSkipRange } from './use-skip-range';

export const usePlayerJump = () => {
    const debug = new Debug('usePlayerJump');
    const instance = usePlayerInstance();

    return useCallback(
        (timestamp: number) => {
            try {
                return instance.user.seek(timestamp - instance.getCurrentTime());
            } catch (e) {
                debug.error('seeking', e);
            }
        },
        [instance],
    );
};

export const usePlayerPercentageJump = () => {
    const debug = new Debug('usePlayerPercentageJump');
    const instance = usePlayerInstance();
    const { end } = useSkipRange();

    return useCallback(
        (percentValue: number) => {
            try {
                return instance.user.seek((end * percentValue) / 100 - instance.getCurrentTime());
            } catch (e) {
                debug.error('seeking', e);
            }
        },
        [instance, end],
    );
};
