class UserRecognitionModel {
    private accountTypes: string[] = [];
    private clientId = '';
    private deviceId = '';
    private userId = '';

    public setData(data: any): void {
        this.accountTypes = data['accountTypes'] || [];
        this.clientId = data['clientId'] || '';
        this.deviceId = data['deviceId'] || '';
        this.userId = data['userId'] || '';
    }

    public getAccountTypes(): string[] {
        return this.accountTypes;
    }

    public getClientId(): string {
        return this.clientId;
    }

    public setClientId(clientId: string): void {
        this.clientId = clientId;
    }

    public getDeviceId(): string {
        return this.deviceId;
    }

    public setDeviceId(deviceId: string): void {
        this.deviceId = deviceId;
    }

    public getUserId(): string {
        return this.userId;
    }

    public setUserId(userId: string): void {
        this.userId = userId;
    }

    public getAsObject(): UserRecognitionInterface {
        return {
            accountTypes: this.getAccountTypes(),
            clientId: this.getClientId(),
            deviceId: this.getDeviceId(),
            userId: this.getUserId(),
        };
    }
}

export default UserRecognitionModel;

export interface UserRecognitionInterface {
    clientId?: string;
    deviceId?: string;
    userId?: string;
    accountTypes?: Array<string>;
}
