export const ERROR_CODES_GET_MEDIA = {
    //Kod zakupu wykorzystany
    PURCHASE_CODE_USED: -2,
    // Materiał lub kategoria nie została znaleziona.
    GMO_MEDIA_OR_CATEGORY_NOT_FOUND: 10000,

    // Brak dostępu do obiektu.
    GMO_NO_ACCESS_TO_OBJECT: 10001,

    // Materiał nie został opublikowany.
    GMO_MEDIA_NOT_PUBLISHED: 10002,

    // Nieprawidłowy portal.
    GMO_INCORRECT_PORTAL: 10003,

    // Nieobsługiwany filtr.
    GMO_UNHANDLED_FILTER: 10004,

    // Nieprawidłowy argument.
    GMO_INCORRECT_ARGUMENT: 10005,

    // Wewnętrzny błąd.
    GMO_INTERNAL_ERROR: 10006,

    // Błąd uwierzytelnienia.
    GMU_AUTHENTICATION_ERROR: 12000,

    // Limit urządzeń przekroczony.
    GMU_DEVICE_LIMIT_EXCEEDED: 12001,

    // Istnieją niezaakceptowane regulaminy.
    GMU_UNACCEPTED_RULES_ERROR: 12002,

    // Nie znaleziono sesji.
    GMU_SESSION_NOT_FOUND: 12003,

    // Zła kombinacja parametrów uwierzytelnienia.
    GMU_INCORRECT_AUTHENTICATION_PARAMETERS_COMBINATION: 12004,

    // Nieznany UserAgent.
    GMU_INCORRECT_USER_AGENT: 12005,

    // Nie znaleziono regulaminu.
    GMU_RULES_NOT_FOUND: 12006,

    // Odwiązanie urządzenia niedozwolone.
    GMU_DEVICE_DISCONNECTION_NOT_ALLOWED: 12008,

    // Nie znaleziono urządzenia.
    GMU_DEVICE_NOT_FOUND: 12009,

    // Brak uprawnień do usługi.
    GMU_NO_PERMISSION_FOR_SERVICE: 12010,

    // Urządzenie nie jest przypisane do użytkownika.
    GMU_DEVICE_NOT_ASSIGNED_TO_USER: 12011,

    // Zły format kodu PIN.
    GMU_INCORRECT_PIN_FORMAT: 12012,

    // Zły format nazwy urządzenia.
    GMU_INCORRECT_DEVICE_NAME_FORMAT: 12013,

    // Zduplikowana nazwa urządzenia.
    GMU_DEVICE_NAME_DUPLICATED: 12014,

    // Pin nie został ustawiony.
    GMU_PIN_NOT_SET: 12015,

    // Zablokowane konto ICOK.
    GMU_IPOLSATBOX_ACCOUNT_BLOCKED: 12016,

    // Geoblokada.
    GMU_GEOBLOCK: 12017,

    // Błąd walidacji parametrów RPC.
    GMU_RPC_PARAMETERS_VALIDATION_ERROR: 12100,

    //Złożoność hasła nie spełnia wymagań
    GMU_PASSWORD_COMPLEXITY_ERROR: 12101,

    // Token aktywacyjny już wykorzystany.
    GMU_EMAIL_ALREADY_CONFIRMED: 12112,

    // Błąd komunikacji w systemie wewnętrznym (timeout).
    GMU_COMMUNICATION_ERROR_WITH_NAGRA: 12201,

    // Błąd komunikacji z usługą zewnętrzną.
    GMU_COMMUNICATION_ERROR_WITH_EXTERNAL_SERVICE: 12250,

    // Błąd współbieżności.
    // Próbowano zapisać dane, które się w międzyczasie zmieniły.
    GMU_CONCURRENCY_ERROR: 12300,

    // Nie znaleziono materiału.
    APCS_MEDIA_N0T_FOUND: 12500,

    // Materiał nie opublikowany.
    APCS_MEDIA_NOT_PUBLISHED: 12501,

    // Brak dostępu.
    APCS_ACCESS_DENIED: 12502,

    // Brak dostępu w trybie offline.
    APCS_NO_ACCESS_IN_OFFLINE_MODE: 12503,

    // Niepoprawny DRM.
    APCS_INCORRECT_DRM: 12504,

    // Niepoprawny adres IP.
    APCS_INCORRECT_IP_ADDRESS: 12505,

    // Niepoprawny adres systemu wewnętrznego.
    APCS_INCORRECT_INTERNAL_SYSTEM_ADDRESS: 12506,

    // Niepoprawny typ chipsetu w systemie wewnętrznym.
    APCS_INCORRECT_CHIPSET_TYPE_IN_INTERNAL_SYSTEM: 12507,

    // Błąd niepoprawnej odpowiedzi proxy w systemie wewnętrznym.
    APCS_NAGRA_PROXY_INVALID_RESPONSE_ERROR: 1250,

    // Brak danych uwierzytelniających.
    B2C_UNAUTHORIZED_ACCESS: 13401,

    // Udane uwierzytelnienie lecz brak uprawnień.
    B2C_CORRECT_AUTHENTICATION_BUT_NO_PERMISSION: 13403,

    // Nie znaleziono obiektu.
    B2C_MEDIA_NOT_FOUND: 13404,

    // Nieprawidłowe dane wejściowe.
    B2C_INCORRECT_INPUT_PARAMETERS: 13430,

    // Nie zaakceptowano regulaminów.
    B2C_UNACCEPTED_RULES_ERROR: 13431,

    // Przekroczono limit zarejestrowanych urządzeń.
    B2C_REGISTERED_DEVICES_LIMIT_EXCEEDED: 13432,

    // Błąd uzyskania licencji na materiał video.
    B2C_ERROR_GETTING_MEDIA_LICENSE: 13440,

    // Przekroczony limit wydanych licencji na materiał video.
    B2C_MEDIA_LICENSE_LIMIT_EXCEEDED: 13441,

    // Login jest zajęty
    B2C_USER_EXISTS_EXCEPTION: 13450,

    // Niepoprawna wartość Captcha
    B2C_WRONG_CAPTCHA_VALUE: 13452,

    // Kod dla tego konta nie istnieje. Wygeneruj ponownie kod
    B2C_INVALID_VERIFICATION_CODE: 13453,

    // Wymagane potwierdzenie adresy e-mail
    B2C_EMAIL_CONFIRMATION_REQUIRED: 13454,

    // Konieczność uzyskania od użytkownika adresu email.
    B2C_EMAIL_REQUIRED: 13456,

    // Wymagana Captcha
    B2C_CAPTCHA_VALUE_REQUIRED: 13458,

    // Nieprawidłowe dane wyjściowe.
    B2C_INCORRECT_INPUT_DATA: 13530,

    // Błąd uzyskania licencji na materiał video.
    B2C_LICENSE_ACCESS_ERROR_FOR_MEDIA: 13540,

    // Timeout w komunikacji wewnętrznej.
    B2C_INTERNAL_COMMUNICATION_ERROR: 13541,

    // Błąd systemowy w odpowiedzi z APCS.
    B2C_SYSTEM_ERROR_FROM_APCS_RESPONSE: 13542,

    // Błąd połączenia z GMO.
    B2C_CONNECTION_ERROR_WITH_GMO: 13550,

    // Timeout w komunikacji z GMO.
    B2C_COMMUNICATION_TIMEOUT_WITH_GMO: 13551,

    // Błąd systemowy w odpowiedzi z GMO.
    B2C_SYSTEM_ERROR_IN_GMO_RESPONSE: 13552,

    // Błąd połączenia z GMP.
    B2C_CONNECTION_ERROR_WITH_GMP: 13560,

    // Timeout w komunikacji z GMP.
    B2C_COMMUNICATION_TIMEOUT_WITH_GMP: 13561,

    // Błąd systemowy w odpowiedzi z GMP.
    B2C_SYSTEM_ERROR_IN_GMP_RESPONSE: 13562,

    // Błąd połączenia w systemie wewnętrznym.
    B2C_CONNECTION_ERROR_IN_INTERNAL_SYSTEM: 13570,

    // Timeout w komunikacji w systemie wewnętrznym.
    B2C_COMMUNICATION_TIMEOUT_IN_INTERNAL_SYSTEM: 13571,

    // Błąd systemowy.
    B2C_SYSTEM_ERROR: 13572,

    // Inny błąd GMP.
    GMP_GMP_ERROR: 13601,

    // Błąd wewnętrzny.
    GMP_INTERNAL_ERROR: 13602,

    // Oznacza, że taki email już isnieje i posiada aktywne produkty.
    B2C_CONFIRM_SET_EMAIL_ACTIVE_ERROR: 13603,

    // Oznacza, że taki email już isnieje, ale nie posiada aktywnych produktów.
    B2C_CONFIRM_SET_EMAIL_NOT_ACTIVE_ERROR: 13604,

    // Błąd wewnętrzny - nieprawidłowy portal.
    GMP_INTERNAL_ERROR_INCORRECT_PORTAL: 13609,

    // Nie znaleziono użytkownika.
    GMP_USER_NOT_FOUND: 13610,

    // Nie znaleziono powiązania użytkownika z kontem CP.
    GMP_CONNECTION_USER_WITH_CP_ACCOUNT_NOT_FOUND: 13611,

    // Brak oferty.
    GMP_OFFER_NOT_FOUND: 13612,

    // Brak opcji płatności.
    GMP_PAYMENT_OPTION_NOT_FOUND: 13613,

    // Brak produktu.
    GMP_PRODUCT_NOT_FOUND: 13614,

    // Timeout w komunikacji z wewnętrznym systemem.
    GMP_TIMEOUT_IN_COMMUNICATION_WITH_INTERNAL_SYSTEM: 13615,

    // Brak zamówienia o podanym ID.
    GMP_NO_ORDER_WITH_PROVIDED_ID: 13616,

    // Błąd komunikacji z systemem wewnętrznym.
    GMP_COMMUNICATION_ERROR_WITH_INTERNAL_SYSTEM: 13617,

    // Status zakupu - błąd zakupów.
    GMP_PURCHASE_STATUS_PURCHASE_ERROR: 13618,

    // Status zakupu - niezgodność ceny.
    GMP_PURCHASE_STATUS_PRICE_MISMATCH: 13619,

    // Status zakupu - brak środków.
    GMP_PURCHASE_STATUS_NO_FUNDS: 13620,

    // Brak dostępu.
    GMP_NO_ACCESS: 13621,

    // Oferta i produkt nie są powiązane.
    GMP_OFFER_AND_PRODUCT_NOT_LINKED: 13622,

    // Błąd współbieżności.
    GMP_CONCURRENCY_ERROR: 13623,

    // Status zakupu - BuyStatus nie został odebrany w oczekiwanym czasie.
    GMP_PURCHASE_STATUS_BUS_STATUS_WAS_NOT_RECEIVED_IN_EXPECTED_TIME: 13624,

    // Status zakupu - Nie udało sie wysłać zlecenia buy - zakup anulowany.
    GMP_PURCHASE_STATUS_BUY_ORDER_WAS_NOT_SENT_PURCHASE_CANCELLED: 13625,

    // Nie znaleziono procesu dla podanych orderId i userId.
    GMP_PROCES_NOT_FOUND_FOR_PROVIDED_ORDER_ID_AND_USER_ID: 13626,

    // Nieprawidłowe parametry zapytania.
    INCORRECT_REQUEST_PARAMS: -32602,

    // Błąd wewnętrzny, usługa niezaimplementowana.
    INTERNAL_ERROR_SERVICE_NOT_IMPLEMENTED: -32603,

    // Nie można wygenerować nowego id zamówienia z powodu trwającej transakcji zakupu na dany produkt
    GET_ORDER_ID_ONGOING_TRANSACTION: 13470,
};
