import { ICONS } from '@/components/shared/Icon';
import { StaticMenuItemInterface } from '@/components/shared/Menu/types';
import t from '@/lib/i18n';
import { ROUTES } from '@/routing/types';
import { HOME_MENU_STATIC_ICONS } from '@/types/home-menu-static-icons.type';

export const prepareStaticMenuItems = (
    menuItem: HOME_MENU_STATIC_ICONS,
): StaticMenuItemInterface | undefined => {
    switch (menuItem) {
        case HOME_MENU_STATIC_ICONS.HELP:
            return {
                getName: () => t('components.menu.help'),
                getUrl: () => ROUTES.CUSTOMER_CARE_HELP,
                getIcon: () => ICONS.QUESTION_MARK,
                getDataTesting: () => 'menu.help',
            };
        case HOME_MENU_STATIC_ICONS.HOME:
            return {
                getName: () => t('components.menu.start'),
                getUrl: () => ROUTES.HOME,
                getIcon: () => ICONS.HOME,
                getDataTesting: () => 'menu.start',
            };

        case HOME_MENU_STATIC_ICONS.SEARCH:
            return {
                getName: () => t('components.menu.search'),
                getUrl: () => ROUTES.SEARCH,
                getIcon: () => ICONS.SEARCH,
                getDataTesting: () => 'menu.search',
            };

        case HOME_MENU_STATIC_ICONS.LIVE:
            return {
                getName: () => t('components.menu.live'),
                getUrl: () => ROUTES.LIVE,
                getIcon: () => ICONS.LIVE,
                getDataTesting: () => 'menu.live',
            };

        default:
            return undefined;
    }
};
