import { protectURL } from '@/helpers/rewrite-protocol.helper';
import {
    GalleryImageItemSchema,
    ImageSchema,
    ImageSourceSchema,
} from '@/interfaces/from-schemas/system/getPartnersOut';
import { getBestImageMatch } from '@/utils/get-best-image-match';

export class PartnerModel {
    readonly name: string;
    readonly images?: ImageSchema[];
    readonly thumbnails?: ImageSourceSchema[];
    readonly gallery?: GalleryImageItemSchema[];
    readonly partnerId?: string;

    constructor(definition: any, partnerId?: string) {
        this.name = definition.name;
        this.images = definition.images;
        this.thumbnails = definition.thumbnails;
        this.gallery = definition.gallery;
        this.partnerId = partnerId;
    }

    public getPartnerId(): string | undefined {
        return this.partnerId;
    }
    public getName(): string {
        return this.name;
    }

    public getGallery(): GalleryImageItemSchema[] {
        return this.gallery || [];
    }

    public getImages(): ImageSchema[] {
        return this.images || [];
    }

    public getThumbnails(): ImageSourceSchema[] {
        return this.thumbnails || [];
    }

    public getBackgroundSrc(imgWidth?: number, imgHeight?: number): string {
        if (imgWidth !== undefined && this.thumbnails) {
            return protectURL(getBestImageMatch(this.thumbnails, imgWidth, imgHeight).src || '');
        }

        return '';
    }
}
