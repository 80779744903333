import { convertSecondsToDays, formatDate } from '@/helpers/date.helper';
import { OfferUpgradeSchema } from '@/interfaces/from-schemas/payments/getPrePurchaseDataOut';
import t from '@/lib/i18n';
import { FORMAT_DATE_MODE } from '@/types/date.type';

export class OfferUpgradeModel {
    private currentAccessTo: string;
    private nextAccessTo: string;
    private currentAccessDurationText: string;
    private currentAccessDurationInDaysText: string;
    private nextAccessDurationText: string;
    private upgradeOfferName: string | undefined;

    constructor(definition: OfferUpgradeSchema) {
        const formatMode = FORMAT_DATE_MODE.DATE_WITH_YEAR;
        this.upgradeOfferName = definition.upgradeOfferName;
        this.currentAccessTo = formatDate(definition.currentAccessTo, formatMode);
        this.nextAccessTo = formatDate(definition.nextAccessTo, formatMode);
        const currentAccessDurationInDays = OfferUpgradeModel.convertToFullDays(
            definition.currentAccessDuration,
        );
        this.currentAccessDurationText = OfferUpgradeModel.buildCurrentAccessDurationText(
            currentAccessDurationInDays,
        );
        this.currentAccessDurationInDaysText = `${currentAccessDurationInDays} dni`;
        this.nextAccessDurationText = `${OfferUpgradeModel.convertToFullDays(
            definition.nextAccessDuration,
        )} dni`;
    }

    private static convertToFullDays(accessDuration: number): number {
        return Math.ceil(convertSecondsToDays(accessDuration));
    }

    private static buildCurrentAccessDurationText(days: number): string {
        return days <= 0 ? t('time-left') : t('days-left', { days });
    }

    public getCurrentAccessTo(): string {
        return this.currentAccessTo;
    }

    public getNextAccessTo(): string {
        return this.nextAccessTo;
    }

    public getCurrentAccessDurationText(): string {
        return this.currentAccessDurationText;
    }

    public getCurrentAccessDurationInDaysText(): string {
        return this.currentAccessDurationInDaysText;
    }

    public getNextAccessDurationText(): string {
        return this.nextAccessDurationText;
    }

    public getUpgradeOfferName(): string | undefined {
        return this.upgradeOfferName;
    }
}
