import { useDebouncedEffect } from '@/hooks/use-debounced-effect.hook';
import { useGetLoginRequestStatus } from '@/api/auth/use-get-login-request-status';
import {
    isAvailableToRefetchLoginStatus,
    STATIC_LOGIN_STATUS_REFETCH_DELAY,
    statusToLoginStatus,
} from '@/helpers/login.helper';

export const useCheckLoginStatus = (loginRequestId?: string) => {
    const { data, isFetching, refetch, error } = useGetLoginRequestStatus(loginRequestId);
    const loginStatus = statusToLoginStatus(data?.status);

    useDebouncedEffect(
        () => {
            if (!isFetching && loginRequestId && isAvailableToRefetchLoginStatus(loginStatus)) {
                refetch?.();
            }
        },
        { timeout: STATIC_LOGIN_STATUS_REFETCH_DELAY },
        [isFetching, loginStatus],
    );

    return { loginStatus, authData: data?.authData, error };
};
