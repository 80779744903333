import { Icon, ICONS } from '../../Icon';
import { Wrapper, Title, IconWrapper } from './styles';
import { CategoryTitleProps } from './types';

export const CategoryTitle = ({ icon, title }: CategoryTitleProps) => {
    return (
        <Wrapper>
            {icon && (
                <IconWrapper>
                    <Icon name={ICONS.ARROW_DOWN} />
                </IconWrapper>
            )}
            <Title>{title}</Title>
        </Wrapper>
    );
};
