import { usePlayerStatus } from './use-player-status';

export const usePausablePlayable = () => {
    const { state, phase } = usePlayerStatus();
    let playable: boolean;
    let pausable: boolean;
    switch (phase) {
        case 'BEFORE_FIRST_PLAYBACK':
        case 'DURING_PLAYBACK':
        case 'AFTER_PLAYBACK':
        case 'CASTING':
            switch (state) {
                case 'PLAYING_CONTENT':
                case 'PLAYING_LINEAR_AD':
                    pausable = true;
                    playable = !pausable;
                    break;
                case 'PAUSED_CONTENT':
                case 'PAUSED_LINEAR_AD':
                case 'READY_TO_PLAY':
                case 'STOPPED':
                    playable = true;
                    pausable = !playable;
                    break;
                default:
                    playable = pausable = false;
                    break;
            }
            break;
        default:
            playable = pausable = false;
            break;
    }
    return { pausable, playable };
};
